import { FormControl, InputLabel, Select, MenuItem } from "@mui/material";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import StLabel from "./StLabel";
import { useStore } from "../../../../../stores/StoreContext";
import listsAPI from "../../../../../api/lists";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 240,
  },
}));

const StAccount = observer(
  ({
    error,
    required,
    answer,
    changeAnswer = () => {},
    setAccount = () => {},
    label,
    active,
  }) => {
    const [market, setMarket] = useState("");
    const [loadingMarkets, setLoadingMarkets] = useState(false);
    const [loadingAccounts, setLoadingAccounts] = useState(false);
    const [selectedAccount, setSelectedAccount] = useState("");
    const { markets, setMarkets, accountsList, setAccounts, userInfo } =
      useStore();

    const classes = useStyles();

    useEffect(() => {
      if (!answer?.objectValue?.account) return;
      const { account_id, list_id } = answer.objectValue.account;
      if (market !== list_id) {
        setMarket(list_id);
      }
      if (selectedAccount !== account_id) {
        setSelectedAccount(account_id);
      }
    }, [answer]);

    const dataChecks = () => {
      if (markets.length === 0) {
        const { manufacturer_id, user_id } = userInfo;
        setLoadingMarkets(true);
        listsAPI
          .getLists({
            mid: manufacturer_id,
            uid: user_id,
          })
          .then(({ results }) => {
            setMarkets(results);
          })
          .finally(() => setLoadingMarkets(false));
      }
    };

    useEffect(() => {
      dataChecks();
    }, []);

    useEffect(() => {
      if (market) {
        setLoadingAccounts(true);
        listsAPI
          .getListsLID({ lid: market })
          .then(({ results }) => {
            setAccounts(results);
          })
          .finally(() => setLoadingAccounts(false));
      }
    }, [market]);

    useEffect(() => {
      if (selectedAccount && accountsList.length > 0) {
        const matchingAccount = accountsList.find(
          (a) => a.account_id === selectedAccount
        );
        // wait til match is available
        if (!matchingAccount) return;
        changeAnswer({
          objectValue: {
            market,
            account: matchingAccount,
          },
          value: matchingAccount,
          textValue: matchingAccount.account_displayname,
        });
      }
      setAccount(selectedAccount);
    }, [selectedAccount]);

    return (
      <FormControl fullWidth>
        <StLabel
          required={required}
          text={label}
          error={error}
          active={active}
        />
        <FormControl
          fullWidth
          className={classes.select}
          style={{ marginTop: 10 }}
        >
          <InputLabel required={required} error={error} shrink>
            {loadingAccounts ? "Loading Account List..." : "Account List"}
          </InputLabel>
          <Select
            value={market ? market : ""}
            onChange={(event) => setMarket(event.target.value)}
            MenuProps={{ disableScrollLock: true }}
          >
            {markets.map((dl) => (
              <MenuItem key={dl.list_id} value={dl.list_id}>
                {dl.list_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <FormControl
          fullWidth
          className={classes.select}
          style={{ marginTop: 10 }}
        >
          <InputLabel required={required} error={error} shrink>
            {loadingAccounts ? "Loading Accounts..." : "Account"}
          </InputLabel>
          <Select
            value={selectedAccount}
            onChange={(event) => setSelectedAccount(event.target.value)}
            error={error}
            MenuProps={{ disableScrollLock: true }}
          >
            {accountsList.map((acc) => (
              <MenuItem key={acc.account_id} value={acc.account_id}>
                {acc.account_displayname} - {acc.account_address}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </FormControl>
    );
  }
);

export default StAccount;
