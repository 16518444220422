import { useEffect, useState } from "react";
import { throttle, isEqual } from "lodash";

/**
 * Hook that tracks window transitions between screen size categories
 */
export const useScreenTransition = (threshold = 900) => {
  const [windowWidth, setWindowWidth] = useState(
    typeof window !== "undefined" ? window.innerWidth : 1200
  );
  const [prevWidth, setPrevWidth] = useState(windowWidth);
  const [isTransitioning, setIsTransitioning] = useState(false);

  const isSmallScreen = windowWidth < threshold;

  useEffect(() => {
    const handleResize = () => {
      const currentWidth = window.innerWidth;
      setPrevWidth(windowWidth);
      setWindowWidth(currentWidth);

      const wasBelow = windowWidth < threshold;
      const nowBelow = currentWidth < threshold;
      if (wasBelow !== nowBelow) {
        setIsTransitioning(true);
        setTimeout(() => setIsTransitioning(false), 500);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [windowWidth, threshold]);

  const isGrowing = windowWidth > prevWidth;

  return {
    width: windowWidth,
    isSmallScreen,
    isTransitioning,
    isGrowing,
    columns: isSmallScreen ? 2 : 4,
  };
};

/**
 * If two widgets share the exact same rootCell or partially overlap,
 * forcibly push them to the next free spot using chainPushWidget().
 */
export function ensureNoRootCellDuplicates(widgets, cfg, maxIterations = 10) {
  let out = [...widgets];
  let iteration = 0;
  let changed = true;

  while (iteration < maxIterations && changed) {
    changed = false;

    // 1) Check for exact rootCell duplicates
    const map = {};
    out.forEach((widget) => {
      const { col, row } = widget.rootCell;
      const key = `${col}-${row}`;
      if (!map[key]) map[key] = [];
      map[key].push(widget);
    });

    // 2) For any rootCell with >1 widget, push extras
    for (const key of Object.keys(map)) {
      const arr = map[key];
      if (arr.length > 1) {
        // Sort by ID to ensure stable push order
        arr.sort((a, b) => a.id.localeCompare(b.id));
        for (let i = 1; i < arr.length; i++) {
          const occupant = arr[i];
          const idx = out.findIndex((w) => w.id === occupant.id);
          if (idx < 0) continue;

          // Move occupant to next cell
          const occupantWidget = out[idx];
          const nextPos = getNextCell(occupantWidget, out, cfg);
          const visitedMap = {};
          out = chainPushWidget(
            out,
            occupantWidget.id,
            nextPos,
            visitedMap,
            null,
            cfg
          );
          changed = true;
        }
      }
    }

    // 3) Check for partial overlaps (widgets partially covering each other)
    for (let i = 0; i < out.length; i++) {
      for (let j = i + 1; j < out.length; j++) {
        if (doWidgetsOverlap(out[i], out[j])) {
          // Move the second widget to next available position
          const occupant = out[j];
          const nextPos = getNextCell(occupant, out, cfg);
          const visitedMap = {};
          out = chainPushWidget(
            out,
            occupant.id,
            nextPos,
            visitedMap,
            null,
            cfg
          );
          changed = true;
          // Break early and restart checking since we modified the array
          break;
        }
      }
      if (changed) break;
    }

    // Sort for consistency
    out = sortWidgetsByGridPos(out);
    iteration++;
  }
  return out;
}

/**
 * Throws or logs a warning if two widgets physically overlap each other
 */
export function checkNoOverlaps(widgets) {
  const len = widgets.length;
  for (let i = 0; i < len; i++) {
    for (let j = i + 1; j < len; j++) {
      if (doWidgetsOverlap(widgets[i], widgets[j])) {
        console.warn("Overlapping widgets detected:", widgets[i], widgets[j]);
        return false;
      }
    }
  }
  return true;
}

/**
 * Throws or logs a warning if two widgets share the same rootCell
 */
export function ensureUniqueRootCells(widgets) {
  const seen = new Set();
  for (const w of widgets) {
    const { col, row } = w.rootCell;
    const key = `${col}-${row}`;
    if (seen.has(key)) {
      console.warn("Duplicate rootCell detected:", w);
      return false;
    }
    seen.add(key);
  }
  return true;
}

// Basic overlap check
export function doWidgetsOverlap(a, b) {
  const aLeft = a.rootCell.col;
  const aRight = aLeft + a.size.colSpan - 1;
  const aTop = a.rootCell.row;
  const aBottom = aTop + a.size.rowSpan - 1;

  const bLeft = b.rootCell.col;
  const bRight = bLeft + b.size.colSpan - 1;
  const bTop = b.rootCell.row;
  const bBottom = bTop + b.size.rowSpan - 1;

  if (aRight < bLeft || bRight < aLeft) return false;
  if (aBottom < bTop || bBottom < aTop) return false;
  return true;
}

/**
 * Place widgets in an optimized layout
 */
export function optimizeWidgetLayout(widgets, gridConfig) {
  if (!widgets || widgets.length === 0) return [];
  const sorted = [...widgets].sort((a, b) => {
    if (a.rootCell.row !== b.rootCell.row) {
      return a.rootCell.row - b.rootCell.row;
    }
    return a.rootCell.col - b.rootCell.col;
  });

  const grid = Array(gridConfig.rows)
    .fill(null)
    .map(() => Array(gridConfig.columns).fill(null));

  const out = [];
  sorted.forEach((widget) => {
    const position = findBestPosition(widget, grid, gridConfig);
    const updated = { ...widget, rootCell: { ...position } };
    markCellsAsOccupied(updated, grid);
    out.push(updated);
  });
  return out;
}

function findBestPosition(widget, grid, config) {
  const { colSpan, rowSpan = 1 } = widget.size;
  for (let row = 0; row < config.rows; row++) {
    for (let col = 0; col <= config.columns - colSpan; col++) {
      let canPlace = true;
      for (let r = 0; r < rowSpan; r++) {
        for (let c = 0; c < colSpan; c++) {
          if (
            row + r >= config.rows ||
            col + c >= config.columns ||
            grid[row + r][col + c] !== null
          ) {
            canPlace = false;
            break;
          }
        }
        if (!canPlace) break;
      }
      if (canPlace) {
        return { row, col };
      }
    }
  }
  return { ...widget.rootCell };
}

function markCellsAsOccupied(widget, grid) {
  const { row, col } = widget.rootCell;
  const { colSpan, rowSpan = 1 } = widget.size;

  for (let r = 0; r < rowSpan; r++) {
    for (let c = 0; c < colSpan; c++) {
      // Ensure the row exists
      if (!grid[row + r]) {
        grid[row + r] = [];
      }
      // Ensure the column exists
      if (grid[row + r][col + c] === undefined) {
        grid[row + r][col + c] = null;
      }
      grid[row + r][col + c] = widget.id;
    }
  }
}

/**
 * Handle responsive resizing
 */
export function handleResponsiveResize(
  widgets,
  isSmallScreen,
  originalSizes,
  gridConfig
) {
  if (!widgets || !widgets.length) return [];
  const resized = widgets.map((w) => {
    const origSize = originalSizes[w.id] || w.size.colSpan;
    if (isSmallScreen) {
      return {
        ...w,
        size: { ...w.size, colSpan: Math.min(w.size.colSpan, 2) },
      };
    } else {
      return {
        ...w,
        size: { ...w.size, colSpan: Math.min(origSize, 4) },
      };
    }
  });
  return optimizeWidgetLayout(resized, gridConfig);
}

export function encodeCell({ col, row }) {
  return `cell-${col}-${row}`;
}
export function decodeCell(cellId) {
  const [, colString, rowString] = cellId.split("-");
  return {
    col: parseInt(colString, 10),
    row: parseInt(rowString, 10),
  };
}

/**
 * Constrain a widget's col/row to the grid
 */
export function clampPosition(col, row, cSpan, rSpan, cfg) {
  const maxC = cfg.columns - cSpan;
  const maxR = cfg.rows - rSpan;
  return {
    col: Math.min(Math.max(0, col), maxC),
    row: Math.min(Math.max(0, row), maxR),
  };
}

/**
 * For collision detection: if dragging a widget into an occupied cell
 */
export function handleCollision(widgets, draggedId, targetPos, prevPos, cfg) {
  const updated = [...widgets];
  const idx = updated.findIndex((w) => w.id === draggedId);
  if (idx < 0) return updated;

  const dragged = updated[idx];

  // Handle special case: colSpan=1 dragging into a cell under a colSpan=2
  if (dragged.size.colSpan === 1 && targetPos.col > prevPos.col) {
    const potentialWidget = updated.find((w) => {
      if (w.id === draggedId) return false;
      if (w.rootCell.row !== targetPos.row) return false;
      return (
        w.rootCell.col <= targetPos.col &&
        targetPos.col < w.rootCell.col + w.size.colSpan
      );
    });
    if (potentialWidget && potentialWidget.size.colSpan === 2) {
      return updated;
    }
  }

  // Handle special case: colSpan=2 dragging over two colSpan=1 widgets
  if (dragged.size.colSpan === 2) {
    // Update dragged widget position before checking occupants
    const draggedNewPos = clampPosition(
      targetPos.col,
      targetPos.row,
      dragged.size.colSpan,
      dragged.size.rowSpan,
      cfg
    );

    // Temporarily update position to find occupants
    const originalPos = { ...dragged.rootCell };
    dragged.rootCell = draggedNewPos;

    // Find widgets that would be overlapped
    const occupants = findOccupants(updated, dragged);

    // Check if exactly two colspan=1 widgets would be overlapped
    if (
      occupants.length === 2 &&
      occupants[0].size.colSpan === 1 &&
      occupants[1].size.colSpan === 1
    ) {
      // Sort occupants by column to arrange them properly
      occupants.sort((a, b) => a.rootCell.col - b.rootCell.col);

      // Move the first occupant to the original position of the dragged widget
      occupants[0].rootCell = {
        col: originalPos.col,
        row: originalPos.row,
      };

      // Move the second occupant next to the first one
      occupants[1].rootCell = {
        col: originalPos.col + 1,
        row: originalPos.row,
      };

      // Keep the dragged widget at the new position
      return updated;
    }

    // Reset position if the special case didn't apply
    dragged.rootCell = originalPos;
  }

  // Default behavior for other cases
  dragged.rootCell = clampPosition(
    targetPos.col,
    targetPos.row,
    dragged.size.colSpan,
    dragged.size.rowSpan,
    cfg
  );
  const overlap = findOccupants(updated, dragged);
  if (overlap.length === 1) {
    overlap[0].rootCell = { ...prevPos };
  }
  return updated;
}

/**
 * Sort widgets row-major
 */
export function sortWidgetsByGridPos(widgets) {
  return [...widgets].sort((a, b) => {
    const posA = a.rootCell.row * 10000 + a.rootCell.col;
    const posB = b.rootCell.row * 10000 + b.rootCell.col;
    return posA - posB;
  });
}

/**
 * Bump occupant further if there's a collision
 */
export function getNextCell(w, widgets, cfg) {
  w.rootCell.col++;
  if (w.rootCell.col >= cfg.columns) {
    w.rootCell.col = 0;
    w.rootCell.row++;
    return w.rootCell;
  }
  const needed = w.size.colSpan || 1;
  if (w.rootCell.col + needed > cfg.columns) {
    const overshoot = w.rootCell.col + needed - cfg.columns;
    const potential = w.rootCell.col - overshoot;
    w.rootCell.col = potential >= 0 ? potential : 0;
    if (potential < 0) w.rootCell.row++;
  }
  return w.rootCell;
}

/**
 * chainPushWidget: push occupant + recursively push collisions
 */
export function chainPushWidget(
  widgets,
  occupantId,
  newPos,
  visitedMap,
  skipId,
  cfg
) {
  const idx = widgets.findIndex((w) => w.id === occupantId);
  if (idx < 0 || occupantId === skipId) return widgets;

  visitedMap[occupantId] = (visitedMap[occupantId] || 0) + 1;
  if (visitedMap[occupantId] > 3) {
    return widgets;
  }

  const occupant = widgets[idx];
  occupant.rootCell = clampPosition(
    newPos.col,
    newPos.row,
    occupant.size.colSpan,
    occupant.size.rowSpan,
    cfg
  );
  if (occupant.rootCell.col + occupant.size.colSpan > cfg.columns) {
    const overshoot =
      occupant.rootCell.col + occupant.size.colSpan - cfg.columns;
    const potential = occupant.rootCell.col - overshoot;
    occupant.rootCell.col = potential >= 0 ? potential : 0;
    if (potential < 0) occupant.rootCell.row++;
  }

  const collisions = findOccupants(widgets, occupant);
  if (!collisions.length) return widgets;

  const sorted = sortWidgetsByGridPos(collisions);
  const next = sorted[0];
  const np = getNextCell(next, widgets, cfg);
  return chainPushWidget(widgets, next.id, np, visitedMap, skipId, cfg);
}

/**
 * Return all widgets that overlap 'dragged'
 */
export function findOccupants(widgets, dragged) {
  return widgets.filter(
    (w) => w.id !== dragged.id && doWidgetsOverlap(w, dragged)
  );
}

/**
 * Recursively resolve collisions in the entire layout
 */
export function resolveOverlapsRecursively(widgets, skipId, cfg) {
  let updated = [...widgets];
  let hasChanges = true;
  let iteration = 0;
  const MAX_ITER = 50;

  while (hasChanges && iteration < MAX_ITER) {
    hasChanges = false;
    iteration++;
    updated = sortWidgetsByGridPos(updated);

    outer: for (let i = 0; i < updated.length; i++) {
      for (let j = i + 1; j < updated.length; j++) {
        if (doWidgetsOverlap(updated[i], updated[j])) {
          const occupant = updated[j];
          const nextPos = getNextCell(occupant, updated, cfg);
          const visitedMap = {};
          updated = chainPushWidget(
            updated,
            occupant.id,
            nextPos,
            visitedMap,
            skipId,
            cfg
          );
          hasChanges = true;
          break outer;
        }
      }
    }
  }
  if (iteration >= MAX_ITER) {
    console.warn("[resolveOverlapsRecursively] Reached max iterations");
  }

  return updated;
}

/**
 * Final dislodge step - enhanced to be more aggressive in fixing overlaps
 */
export function finalDislodgeStep(widgets, cfg) {
  let updated = [...widgets];
  let hasChanges = true;
  let iteration = 0;
  const MAX_ITER = 5; // Increased from 3 to 5

  function isCellFree(row, col, currentWidgetId, widgetsArray, config) {
    return !widgetsArray.some((widget) => {
      if (widget.id === currentWidgetId) return false;
      const left = widget.rootCell.col;
      const right = left + widget.size.colSpan - 1;
      const top = widget.rootCell.row;
      const bottom = top + widget.size.rowSpan - 1;
      return row >= top && row <= bottom && col >= left && col <= right;
    });
  }

  // First check for any immediate overlaps and separate them
  updated = sortWidgetsByGridPos(updated);
  for (let i = 0; i < updated.length; i++) {
    for (let j = i + 1; j < updated.length; j++) {
      if (doWidgetsOverlap(updated[i], updated[j])) {
        // Move the second widget down to the next row
        updated[j].rootCell.row = updated[j].rootCell.row + 1;
        updated[j].rootCell.col = 0;
        hasChanges = true;
      }
    }
  }

  while (hasChanges && iteration < MAX_ITER) {
    hasChanges = false;
    iteration++;
    updated = sortWidgetsByGridPos(updated);

    // Check for widgets that extend beyond grid width
    for (const w of updated) {
      const { col, row } = w.rootCell;
      const needed = w.size.colSpan;

      // If 2 columns in far right, try left col or push to next row
      if (w.size.colSpan === 2 && col === cfg.columns - 1) {
        const leftCol = cfg.columns - 2;
        if (isCellFree(row, leftCol, w.id, updated, cfg)) {
          w.rootCell.col = leftCol;
          hasChanges = true;
          continue;
        } else {
          w.rootCell.row = row + 1;
          w.rootCell.col = 0;
          hasChanges = true;
          continue;
        }
      }

      // If widget extends beyond grid width, push it down
      if (col + needed > cfg.columns) {
        const openSlot = findOpenSlotInRow(row, needed, updated, cfg);
        if (openSlot !== null && openSlot < col) {
          w.rootCell.col = openSlot;
          hasChanges = true;
        } else {
          w.rootCell.row = row + 1;
          w.rootCell.col = 0;
          hasChanges = true;
        }
        continue;
      }

      // Check for overlaps with other widgets and fix them
      for (let j = 0; j < updated.length; j++) {
        if (updated[j].id !== w.id && doWidgetsOverlap(w, updated[j])) {
          // If this is a colspan=2 widget, try to find space in current row first
          if (w.size.colSpan === 2) {
            let foundSpace = false;
            for (let testCol = 0; testCol <= cfg.columns - 2; testCol++) {
              let canFit = true;
              const testWidget = {
                ...w,
                rootCell: { col: testCol, row: w.rootCell.row },
              };

              for (let k = 0; k < updated.length; k++) {
                if (
                  updated[k].id !== w.id &&
                  doWidgetsOverlap(testWidget, updated[k])
                ) {
                  canFit = false;
                  break;
                }
              }

              if (canFit) {
                w.rootCell.col = testCol;
                foundSpace = true;
                hasChanges = true;
                break;
              }
            }

            // If can't fit in current row, move to next row, column 0
            if (!foundSpace) {
              w.rootCell.row = w.rootCell.row + 1;
              w.rootCell.col = 0;
              hasChanges = true;
            }
          } else {
            // For colspan=1 widgets, just move to next row
            w.rootCell.row = w.rootCell.row + 1;
            w.rootCell.col = 0;
            hasChanges = true;
          }
          break;
        }
      }
    }
  }

  // Final check for any remaining overlaps
  let hasOverlaps = false;
  for (let i = 0; i < updated.length; i++) {
    for (let j = i + 1; j < updated.length; j++) {
      if (doWidgetsOverlap(updated[i], updated[j])) {
        hasOverlaps = true;
        // Move the second widget to a completely new row to ensure separation
        updated[j].rootCell.row =
          Math.max(...updated.map((w) => w.rootCell.row + w.size.rowSpan)) + 1;
        updated[j].rootCell.col = 0;
      }
    }
  }

  return sortWidgetsByGridPos(updated);
}

/**
 * Try to find an open slot in the given row
 */
export function findOpenSlotInRow(rowIdx, neededCols, widgets, cfg) {
  for (let candidate = 0; candidate <= cfg.columns - neededCols; candidate++) {
    const overlap = widgets.some((w) => {
      if (w.rootCell.row !== rowIdx) return false;
      const left = w.rootCell.col;
      const right = left + w.size.colSpan - 1;
      return !(candidate + neededCols - 1 < left || candidate > right);
    });
    if (!overlap) return candidate;
  }
  return null;
}

/**
 * Comprehensive verification for no overlaps, fixing any found
 */
export function verifyNoOverlaps(widgets, cfg) {
  // First check for any kind of overlap
  let updated = [...widgets];
  let hasOverlaps = true; // Start with true to ensure at least one check
  let iterations = 0;
  const MAX_ITERATIONS = 5;

  while (hasOverlaps && iterations < MAX_ITERATIONS) {
    hasOverlaps = false;
    iterations++;

    // Check each widget pair for overlaps
    for (let i = 0; i < updated.length; i++) {
      for (let j = i + 1; j < updated.length; j++) {
        if (doWidgetsOverlap(updated[i], updated[j])) {
          console.warn(
            `Overlap detected between widgets: ${updated[i].id} and ${updated[j].id}`,
            updated[i].rootCell,
            updated[j].rootCell
          );
          hasOverlaps = true;

          // Prioritize moving the widget with smaller span or higher row
          const moveFirst =
            updated[i].size.colSpan <= updated[j].size.colSpan
              ? updated[i]
              : updated[j];
          const moveIdx = moveFirst.id === updated[i].id ? i : j;

          // Find better position - first try moving to next row
          const widget = updated[moveIdx];
          const originalRow = widget.rootCell.row;

          // Try next row, starting from column 0
          widget.rootCell = {
            col: 0,
            row: originalRow + 1,
          };

          // Find first non-overlapping position in new row
          let foundSpace = false;
          for (let col = 0; col <= cfg.columns - widget.size.colSpan; col++) {
            widget.rootCell.col = col;

            // Check if this position works
            let hasOverlap = false;
            for (let k = 0; k < updated.length; k++) {
              if (k !== moveIdx && doWidgetsOverlap(widget, updated[k])) {
                hasOverlap = true;
                break;
              }
            }

            if (!hasOverlap) {
              foundSpace = true;
              break;
            }
          }

          // If couldn't find space, keep pushing down rows
          if (!foundSpace) {
            // Find next free row
            let newRow = originalRow + 1;
            while (!foundSpace && newRow < cfg.rows + 5) {
              // Allow extending grid if needed
              widget.rootCell.row = newRow;
              widget.rootCell.col = 0;

              for (
                let col = 0;
                col <= cfg.columns - widget.size.colSpan;
                col++
              ) {
                widget.rootCell.col = col;

                // Check if this position works
                let hasOverlap = false;
                for (let k = 0; k < updated.length; k++) {
                  if (k !== moveIdx && doWidgetsOverlap(widget, updated[k])) {
                    hasOverlap = true;
                    break;
                  }
                }

                if (!hasOverlap) {
                  foundSpace = true;
                  break;
                }
              }

              newRow++;
            }
          }

          // Skip further checks in this iteration as we modified the layout
          break;
        }
      }
      if (hasOverlaps) break;
    }
  }

  if (iterations >= MAX_ITERATIONS) {
    console.warn(
      "Maximum overlap resolution iterations reached, layout may still have issues"
    );
  }

  // Apply additional resolution strategies as a final pass
  updated = resolveOverlapsRecursively(updated, null, cfg);
  updated = finalDislodgeStep(updated, cfg);
  updated = ensureNoRootCellDuplicates(updated, cfg);

  return sortWidgetsByGridPos(updated);
}

/**
 * Validate an initial widget layout and fix collisions
 */
export function validateAndFixWidgetsConfig(widgetArr, cfg) {
  const seen = new Set();
  const fixed = widgetArr.map((w, idx) => {
    let id = w.id;
    // If the same ID is repeated, make it unique
    if (seen.has(id)) {
      id = `${id}-${idx}`;
    }
    seen.add(id);

    // Force colSpan <= 2
    const limited = Math.min(w.size.colSpan, 2);
    return { ...w, id, size: { ...w.size, colSpan: limited } };
  });

  let resolved = resolveOverlapsRecursively(fixed, null, cfg);
  resolved = finalDislodgeStep(resolved, cfg);
  resolved = ensureNoRootCellDuplicates(resolved, cfg);
  // Add final verification step
  resolved = verifyNoOverlaps(resolved, cfg);
  return resolved;
}

function isSpaceFree(widgets, col, row, colSpan) {
  return !widgets.some(
    (w) =>
      w.rootCell.row === row &&
      w.rootCell.col < col + colSpan &&
      w.rootCell.col + w.size.colSpan > col
  );
}

function findNextAvailable2ColSlot(widgets, cfg = {}) {
  for (let row = 0; row < cfg.rows; row++) {
    for (let col = 0; col <= cfg.columns - 2; col++) {
      if (isSpaceFree(widgets, col, row, 2)) {
        return { col, row };
      }
    }
  }
  return null;
}

/**
 * Resize a widget's colSpan
 */
export function resizeWidgetColSpan(widgets, targetId, newColSpan, cfg = {}) {
  if (!cfg || !cfg.columns || !cfg.rows) {
    console.error("resizeWidgetColSpan: Missing grid config (cfg)", cfg);
    return widgets; // Return original widgets to prevent crashes
  }

  let updated = [...widgets];
  const idx = updated.findIndex((w) => w.id === targetId);
  if (idx < 0) return updated;

  const target = updated[idx];
  const { col, row } = target.rootCell;
  const prevColSpan = target.size.colSpan;

  // 1️⃣ Find a widget that is about to be overlapped
  const collidingWidget = updated.find(
    (w) =>
      w.rootCell.row === row &&
      w.rootCell.col >= col + prevColSpan &&
      w.rootCell.col < col + newColSpan
  );

  if (collidingWidget) {
    // 2️⃣ Find a free spot in the same row with 2 empty columns
    const newSlot = findNextAvailable2ColSlot(updated, cfg);

    if (newSlot) {
      collidingWidget.rootCell = newSlot;
    } else {
      // If no room in the row, push to the next row
      collidingWidget.rootCell.row += 1;
      collidingWidget.rootCell.col = 0;
    }
  }

  // 3️⃣ Apply the new colspan for the resized widget
  target.size.colSpan = newColSpan;

  return updated;
}

/**
 * Calculate widget width/height in pixels
 */
export function calculateWidgetDimensions(widget, cfg) {
  const { colSpan, rowSpan = 1 } = widget.size;
  const wPx = colSpan * cfg.cellWidth + (colSpan - 1) * cfg.gap;
  const hPx = rowSpan * cfg.cellHeight + (rowSpan - 1) * cfg.gap;
  return { width: `${wPx}px`, height: `${hPx}px` };
}

export function getWidgetHeight(widget, cfg) {
  return (
    widget.size.rowSpan * cfg.cellHeight + (widget.size.rowSpan - 1) * cfg.gap
  );
}
