import accounts from "./accounts";
import locations from "./locations";
import user from "./user";
import manufacturer from "./manufacturer";
import megaFilter from "./megaFilter";
import regions from "./regions";
import distributors from "./distributors";
import forms from "./forms";
import media from "./media";
import users from "./users";
import products from "./products";
// import depletions from "./depletions";
import globals from "./globalItems";
import minified from "./minified";
import premise_types from "./premiseTypes";
import loadingStore from "./loading";
import reportBuilder from "./reportBuilder";

export function createStore() {
  return {
    ...locations,
    ...user,
    ...accounts,
    ...manufacturer,
    ...distributors,
    ...forms,
    ...media,
    ...megaFilter,
    ...users,
    ...products,
    // ...depletions,
    ...globals,
    ...minified,
    ...premise_types,
    ...loadingStore,
    ...reportBuilder,
    regions,
  };
}
