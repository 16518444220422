import { Box } from "@mui/material";
import { useMemo } from "react";
import { DateTime } from "luxon";
import AnswerFileDisplay from "./AnswerFileDisplay";
import AnswerWrapper from "../form/AnswerWrapper";
import { prebakeRepeatingGroups } from "../helpers";

const noAnswerString = (question) => {
  if (
    Array.isArray(question.options) &&
    question.options.length === 1 &&
    question.element === "Checkboxes"
  ) {
    return "No";
  }
  return "No answer given";
};
const toMatch = (string, options) => {
  if (/^(\w+_)?([0-9A-Z\-]+)./.test(string)) {
    const option = options.find((o) => o.key === string);
    return option ? option.text : string;
  }
  return string;
};
const handleUSDates = (d) => {
  if (typeof d === "string") {
    // count slashes
    const m = d.match(/\//g);
    // are there 2 slashes - then treat it as US date
    if (m && m.length === 2) {
      const spl = d.split("/");
      const nd = [spl[2], spl[0], spl[1]].join("-");
      return nd;
    }
  }
  return d;
};
const answerFormat = ({ value, textValue = "", text = "" }, question) => {
  if (!value) return "";

  if (textValue || text) {
    const t = textValue ? textValue : text;
    return Array.isArray(t) ? t.join(", ") : t;
  }
  const toDate = DateTime.fromISO(handleUSDates(value));
  const { variant } = question;
  if (toDate.isValid && ["time", "date", "datetime"].includes(variant)) {
    if (variant === "time") {
      return toDate.toLocaleString(DateTime.TIME_SIMPLE);
    }
    if (variant === "date") {
      return toDate.toLocaleString(DateTime.DATE_MED);
    }
    return toDate.toLocaleString(DateTime.DATETIME_MED);
  }
  if (typeof value === "string") {
    if (/(^{"|"}$)/.test(value)) {
      const p = JSON.parse(value);
      return p.text || value;
    }
    return value;
  }
  if (question.element === "File" && Array.isArray(value)) {
    const files = value;
    return (
      <Box flex="3" flexDirection={"row"} display={"flex"}>
        {files.map((storedFile, i) => (
          <Box key={i}>
            <AnswerFileDisplay
              file={storedFile}
              editable={false}
              remove={() => {}}
            />
          </Box>
        ))}
      </Box>
    );
  }
  if (Array.isArray(value)) {
    // consider a one checkbox question with no answers to be a yes/no question
    if (
      question.options.length === 1 &&
      value.length === 0 &&
      question.element === "Checkboxes"
    ) {
      return "No";
    }
    return value.length
      ? value.map((v) => toMatch(v, question.options)).join(", ")
      : "";
  }
  return JSON.stringify(value);
};
const loopingAnswers = (group) => {
  return <Box></Box>;
};

const AnswersOnScreen = ({ questions = [], answers = [] }) => {
  const newWeird = useMemo(() => {
    const doQQs = (questions, answers, depth = 1) => {
      if (!questions.length) return [];
      let editedQuestions = [...questions];
      const qnames = editedQuestions.map((q) => q.field_name);
      const nested = questions
        .map((q) => {
          if (!q.conditionalGroups) return [];
          return q.conditionalGroups.map((g) => {
            return g.fields.map((gf) => ({
              ...gf,
              parentFieldId: g.parentFieldId,
              depth,
            }));
          });
        })
        .flat(2)
        .filter((q) => {
          const answer = answers.find((a) => q.field_name === a.name);
          return answer && typeof answer.value !== "undefined";
        });
      const toAdd = nested.filter((n) => !qnames.includes(n.field_name));
      if (!toAdd.length) {
        return questions;
      }
      const insertees = [];
      editedQuestions.forEach((element, index) => {
        const newInsertees = toAdd.filter(
          (n) => n.parentFieldId === element.id
        );
        insertees.push({
          id: element.id,
          questions: newInsertees,
        });
      });
      insertees.forEach((element) => {
        const insertInd = editedQuestions.findIndex((q) => q.id === element.id);
        if (insertInd > -1) {
          editedQuestions.splice(insertInd + 1, 0, ...element.questions);
        }
      });
      return doQQs(editedQuestions, answers, depth + 1);
    };
    return doQQs(questions, answers, 1);
  }, [questions, answers]);

  const qaList = useMemo(() => {
    const answerValues = newWeird.map((question) => {
      const answer = answers.find((a) => question.field_name === a.name);
      return {
        answer,
        question,
      };
    });
    return answerValues;
  }, [answers, newWeird]);

  const amendedFields = useMemo(() => {
    return questions.map((f) => {
      if (!f.conditionalGroups || f.conditionalGroups.length === 0) return f;
      const prebakedGroups = prebakeRepeatingGroups(f);
      return {
        ...f,
        conditionalGroups: prebakedGroups,
      };
    });
  }, [questions]);

  const getAnswer = (question) =>
    answers.find((a) => a.name === question.field_name);

  return (
    <Box alignContent={"center"}>
      {amendedFields.map((question, index) => (
        <AnswerWrapper
          key={question.field_name}
          field={question}
          answer={getAnswer(question)}
          answers={answers}
          groups={question.conditionalGroups || []}
          answerFormat={answerFormat}
          noAnswerString={noAnswerString}
        />
      ))}
    </Box>
  );
};

export default AnswersOnScreen;
