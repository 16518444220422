import React, { useEffect, useState } from "react";
import {
  Container,
  Card,
  CardContent,
  Tabs,
  Tab,
  Box,
  CardHeader,
} from "@mui/material";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import distributorsAPI from "../../../api/distributors.js";
import { useStore } from "../../../stores/StoreContext";
import { DataGridPremium, GridToolbarExport } from "@mui/x-data-grid-premium";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import TopExternalBrandsList from "../../../components/brands/TopExternalBrandsList";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TopExternalBrands = () => {
  const classes = useStyles();
  const { userInfo } = useStore();

  const [columns] = useState([
    { field: "supplier", headerName: "Supplier", width: 250 },
    { field: "brand", headerName: "Brand", flex: 1 },
    { field: "distributor", headerName: "Distributor", flex: 1 },
    {
      field: "distinctDistributors",
      headerName: "Distinct Distributors",
      type: "number",
      valueGetter: (value, row) => row?.distributor, // Extract distributor for each row
      flex: 1,
    },
    {
      field: "distinctDistributorsNames",
      headerName: "Distinct Distributors Names",
      valueGetter: (value, row) => row?.distributor, // Extract distributor for each row
      flex: 1,
    },
    {
      field: "rowCount",
      headerName: "Row Count",
      type: "number",
      valueGetter: () => 1, // Each row contributes 1
      flex: 1,
    },
  ]);

  const [rowGroupingModel, setRowGroupingModel] = useState(["supplier"]);
  const [brands, setBrands] = useState([]);
  const [oldBrands, setOldBrands] = useState([]);

  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 25,
  });
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };
  // Fetch data
  useEffect(() => {
    const fetchBrands = async () => {
      try {
        trackEvent(userInfo.uid, "PageView:Top-External-Brands");
        const { results } = await distributorsAPI.getTopExternalBrandsPivot(
          userInfo.manufacturer_id
        );
        const {
          suppliers,
          brands: brandNames,
          distributors,
          relationships,
        } = results;

        // Reconstruct the flat list from the relationships
        const reconstructedBrands = relationships.map((rel, index) => ({
          id: index, // Unique ID for DataGrid
          supplier: suppliers[rel.supplier],
          brand: brandNames[rel.brand],
          distributorId: distributors[rel.distributor].id,
          distributor: distributors[rel.distributor].displayName,
        }));

        // Update the brands state with the reconstructed flat list
        setBrands(reconstructedBrands);

        const data = await distributorsAPI
          .getTopExternalBrands(userInfo.manufacturer_id)
          .then(({ results }) => {
            setOldBrands(results.map((r, i) => ({ ...r, id: i })));
          })
          .catch((e) => console.error(e));
      } catch (error) {
        console.error("Error fetching brands:", error);
      }
    };
    fetchBrands();
  }, [userInfo.uid, userInfo.manufacturer_id]);

  // Define custom aggregation functions
  const aggregationFunctions = {
    distinctNames: {
      apply: (params) => {
        const uniqueValues = new Set(
          params.values.filter((v) => v !== null && v !== undefined)
        );
        return [...uniqueValues].join(", "); // Return distinct count
      },
      label: "Distinct Names",
    },
    distinctCount: {
      apply: (params) => {
        const uniqueValues = new Set(
          params.values.filter((v) => v !== null && v !== undefined)
        );
        return uniqueValues.size; // Return distinct count
      },
      label: "Distinct Count",
    },
    sum: {
      apply: (params) =>
        params.values.reduce((sum, value) => sum + (value || 0), 0),
      label: "Sum",
    },
  };

  return (
    <Page className={classes.root} title="Accounts Dashboard">
      <Tabs
        value={selectedTab}
        onChange={handleTabChange}
        textColor="primary" // Use secondary color for text
        indicatorColor="secondary" // Use secondary color for indicator
        variant="fullWidth"
        sx={{
          "& .MuiTab-root": {
            textTransform: "none", // Prevent text from being uppercase
            fontWeight: "bold", // Bold font for tab labels
            fontSize: "1rem", // Larger font size for better visibility
            "&:hover": {
              color: "primary.main", // Change color on hover
            },
          },
          "& .MuiTabs-indicator": {
            height: "4px", // Thicker indicator for active tab
          },
        }}
      >
        <Tab label="External Summary" />
        <Tab label="Group By External" />
      </Tabs>

      <Box sx={{ marginTop: 2 }}>
        {selectedTab === 0 && (
          <Container maxWidth>
            <Card sx={{ width: "100%", backgroundColor: "white" }}>
              <CardHeader title={"Top External Brands"} />
              <CardContent>
                <TopExternalBrandsList brands={oldBrands} />
              </CardContent>
            </Card>
          </Container>
        )}

        {selectedTab === 1 && (
          <Container maxWidth>
            <Card>
              <FormControl
                variant="standard"
                sx={{ minWidth: 120, marginBottom: 2 }}
              >
                <InputLabel>Group by</InputLabel>
                <Select
                  value={rowGroupingModel[0] || ""}
                  onChange={(e) =>
                    setRowGroupingModel(e.target.value ? [e.target.value] : [])
                  }
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="supplier">Supplier</MenuItem>
                  <MenuItem value="brand">Brand</MenuItem>
                  <MenuItem value="distributor">Distributor</MenuItem>
                </Select>
              </FormControl>

              <DataGridPremium
                rows={brands}
                columns={columns}
                rowGroupingModel={rowGroupingModel}
                onRowGroupingModelChange={(newModel) =>
                  setRowGroupingModel(newModel)
                }
                groupingColDef={{
                  headerName: "Group By",
                }}
                aggregationModel={{
                  distinctDistributorsNames: "distinctNames",
                  distinctDistributors: "distinctCount",
                  rowCount: "sum",
                }}
                aggregationFunctions={aggregationFunctions}
                initialState={{
                  sorting: {
                    sortModel: [
                      { field: "distinctDistributors", sort: "desc" },
                    ],
                  },
                  pagination: {
                    paginationModel: { page: 0, pageSize: 25 },
                  },
                }}
                pagination
                paginationMode="client"
                pageSizeOptions={[25, 50, 75, 100]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                slots={{
                  toolbar: GridToolbarExport,
                }}
                experimentalFeatures={{ rowGrouping: true }}
                getRowId={(row) => row.id}
              />
            </Card>
          </Container>
        )}
      </Box>
    </Page>
  );
};

export default TopExternalBrands;
