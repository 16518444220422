import { Avatar, Box, Button, Typography } from "@mui/material";
import { useMemo, useState } from "react";
import marketingAPI from "../../api/marketing";
import { Folder, FolderOpen } from "@mui/icons-material";

// recursively find ids of folders to "close" when an ancestor is closed
const toClose = (ids, list) => {
  const toAdd = list.filter(
    (item) =>
      ids.includes(item.parent_folder_id) && !ids.includes(item.folder_id)
  );
  if (toAdd.length === 0) return ids;
  return toClose([...ids, ...toAdd.map((t) => t.folder_id)], list);
};

// recursively figure out how deep a folder is
const depthCalc = (folders, folder, depth = 0, fids = []) => {
  if (folder.parent_folder_id) {
    const parent = folders.find(
      (f) =>
        f.folder_id === folder.parent_folder_id && !fids.includes(f.folder_id)
    );
    if (parent) {
      return depthCalc(folders, parent, depth + 1, [...fids, parent.folder_id]);
    }
    return depth;
  }
  return depth;
};

const FolderBar = ({
  folder,
  toggleFolder,
  setSelectedFolder,
  list,
  selected,
  open,
}) => {
  return (
    <Box
      key={folder.folder_id}
      pl={(1 + folder.depth) * 2}
      display="flex"
      py={1}
      pr={2}
      borderBottom={"1px solid #99d"}
      style={selected ? { backgroundColor: "rgba(0, 120, 240, 0.3)" } : {}}
      alignItems={"center"}
    >
      <Box pr={2}>
        <Avatar
          onClick={() =>
            toggleFolder({
              folder_id: folder.folder_id,
              list,
              childrenLength: folder.childrenLength,
            })
          }
        >
          {open ? <FolderOpen /> : <Folder />}
        </Avatar>
      </Box>
      <Box
        onClick={() =>
          selected ? setSelectedFolder(null) : setSelectedFolder(folder)
        }
      >
        <Typography
          variant="h6"
          style={
            selected
              ? {
                  textDecoration: "underline",
                  cursor: "pointer",
                }
              : {
                  textDecoration: "underline",
                  fontWeight: "normal",
                  cursor: "pointer",
                }
          }
        >
          {folder.name}{" "}
          {folder.childrenLength ? `(${folder.childrenLength})` : ""}
        </Typography>
      </Box>
    </Box>
  );
};

const FolderPicker = ({ folders, file, setFiles, files }) => {
  const [openList, setOpenList] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [storing, setStoring] = useState(false);

  const copy = async () => {
    setStoring(true);
    const { folder_id } = selectedFolder;
    marketingAPI
      .copyFile({ ...file, folder_id }, selectedFolder)
      .then(({ result }) => {
        setFiles([...files, result]);
      })
      .catch((error) => console.log(error))
      .finally(() => setStoring(false));
  };

  const move = () => {
    const { folder_id } = selectedFolder;
    const { file_id, file_name } = file;
    setStoring(true);
    marketingAPI
      .moveFile({ patch: { folder_id }, file_id }, selectedFolder, file_name)
      .then(() => {
        setFiles(
          files.map((f) => {
            if (f.file_id === file_id) {
              return {
                ...f,
                folder_id,
              };
            }
            return f;
          })
        );
      })
      .catch((error) => console.log(error))
      .finally(() => setStoring(false));
  };
  const toggleFolder = ({ folder_id, list, childrenLength }) => {
    // nothing else to do if no kids
    if (list.includes(folder_id)) {
      // hide
      const allToClose = toClose([folder_id], folders);
      setOpenList(list.filter((f) => !allToClose.includes(f)));
    } else {
      setOpenList([...list, folder_id]);
    }
  };
  const structuredFolders = useMemo(() => {
    const arrangefolders = (list) => {
      let struct = list.map((folder) => {
        return {
          ...folder,
          childrenLength: list.filter(
            (f) => f.parent_folder_id === folder.folder_id
          ).length,
          display:
            !folder.parent_folder_id ||
            openList.includes(folder.parent_folder_id),
        };
      });
      openList.forEach((ol) => {
        const kids = list.filter((s) => s.parent_folder_id === ol);
        struct = struct.filter(
          (s) => !kids.map((k) => k.folder_id).includes(s.folder_id)
        );
        const insertPos = struct.findIndex((s) => s.folder_id === ol);
        console.log(ol, insertPos, JSON.stringify(kids));
        struct.splice(
          insertPos + 1,
          0,
          ...kids.map((k) => ({
            ...k,
            display: true,
            childrenLength: list.filter(
              (f) => f.parent_folder_id === k.folder_id
            ).length,
          }))
        );
      });
      return struct.map((s, ind, arr) => ({ ...s, depth: depthCalc(arr, s) }));
    };
    return arrangefolders(folders);
  }, [folders, openList]);

  return (
    <Box display="flex">
      <Box>
        {structuredFolders
          .filter((f) => f.display)
          .map((f) => (
            <FolderBar
              key={f.folder_id}
              folder={f}
              toggleFolder={toggleFolder}
              list={openList}
              selected={
                selectedFolder && selectedFolder.folder_id === f.folder_id
              }
              open={openList.includes(f.folder_id)}
              setSelectedFolder={() => {
                selectedFolder === null ||
                selectedFolder.folder_id !== f.folder_id
                  ? setSelectedFolder(f)
                  : setSelectedFolder(null);
              }}
            />
          ))}
      </Box>
      <Box p={2}>
        <Typography variant={"h6"}>
          Folder: {selectedFolder ? selectedFolder.name : "(none)"}
        </Typography>

        <Box pb={1}>
          <Button
            variant="contained"
            onClick={copy}
            disabled={!selectedFolder || storing}
          >
            Copy to Folder
          </Button>
        </Box>
        <Box pb={2}>
          <Button
            variant="contained"
            onClick={move}
            disabled={!selectedFolder || storing}
          >
            Move to Folder
          </Button>
        </Box>
        <Box>
          <Typography variant="body1">
            <p>Click a folder name to select that folder.</p>
            <p>Click folder icon to expand a folder with child folders.</p>
            <p>You can then perform available actions on that folder.</p>
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default FolderPicker;
