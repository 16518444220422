import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Typography,
  Divider,
  Paper,
  Modal,
  Button,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
} from "@mui/material";
import MemoizedInput from "./MemoizedInput";

const TargetEditModal = ({
  open,
  onClose,
  onSave,
  editTargets: initialEditTargets,
  representatives = [],
  setDefaultTimePeriod,
  defaultTimePeriod,
}) => {
  const [editTargets, setEditTargets] = useState({});
  const [currentPage, setCurrentPage] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const itemsPerPage = 20;

  // Reset state when modal opens with new initial values
  useEffect(() => {
    if (open) {
      setEditTargets(initialEditTargets);
      setCurrentPage(0); // Reset to first page when opening
    }
  }, [open, initialEditTargets]);

  // Filter and get visible representatives
  const visibleReps = useMemo(() => {
    const filteredReps = representatives
      .filter((rep) =>
        rep.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
      .sort((a, b) => {
        if (a.name === null && b.name === null) return 0;
        if (a.name === null) return 1;
        if (b.name === null) return -1;
        return a.name.localeCompare(b.name);
      });

    const start = currentPage * itemsPerPage;
    const end = start + itemsPerPage;
    return filteredReps.slice(start, end);
  }, [representatives, currentPage, searchQuery]);

  const totalPages = Math.ceil(representatives.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleTargetChange = (repId, period) => (event) => {
    const value = event.target.value;

    setEditTargets((prev) => {
      return {
        ...prev,
        [repId]: {
          ...(prev[repId] || {}),
          [period]: value === "" ? "" : Number(value),
        },
      };
    });
  };

  const handleDefaultTargetChange = (period) => (event) => {
    const value = event.target.value;

    setEditTargets((prev) => {
      return {
        ...prev,
        [`default_${period}`]: value === "" ? "" : Number(value),
      };
    });
  };

  const handleDefaultTimePeriodChange = (event) => {
    setDefaultTimePeriod(event.target.value);
  };

  const cleanupTargetsBeforeSave = () => {
    const cleanedTargets = { ...editTargets };

    // Handle default targets - convert empty to 0
    ["MTD", "Q1", "Q2", "Q3", "Q4", "YTD"].forEach((period) => {
      const key = `default_${period}`;
      if (cleanedTargets[key] === "" || cleanedTargets[key] === undefined) {
        cleanedTargets[key] = 0;
      }
    });

    // Handle rep-specific targets
    Object.keys(cleanedTargets).forEach((repId) => {
      if (!repId.startsWith("default_") && cleanedTargets[repId]) {
        const repData = cleanedTargets[repId];

        // Process each period
        ["MTD", "Q1", "Q2", "Q3", "Q4", "YTD"].forEach((period) => {
          // If value is empty, delete it to use default
          if (repData[period] === "") {
            delete repData[period];
          }
          // If value equals default, remove it
          else if (repData[period] === cleanedTargets[`default_${period}`]) {
            delete repData[period];
          }
        });

        // If no overrides left, remove the rep entry completely
        if (Object.keys(repData).length === 0) {
          delete cleanedTargets[repId];
        }
      }
    });

    return cleanedTargets;
  };

  const handleSave = () => {
    // Clean up the targets before saving
    const cleanedTargets = cleanupTargetsBeforeSave();
    onSave(cleanedTargets);
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="rep-targets-config-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 700,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
          borderRadius: 2,
          maxHeight: "80vh",
          overflow: "auto",
        }}
      >
        <Typography variant="h6" component="h2" sx={{ mb: 3 }}>
          Configure Representative Targets
        </Typography>

        {/* Default Time Period Selection */}
        <FormControl component="fieldset" sx={{ mb: 4 }}>
          <FormLabel component="legend">Default Time Period</FormLabel>
          <RadioGroup
            value={defaultTimePeriod}
            onChange={handleDefaultTimePeriodChange}
          >
            <FormControlLabel value="MTD" control={<Radio />} label="MTD" />
            <FormControlLabel value="QTD" control={<Radio />} label="QTD" />
            <FormControlLabel value="YTD" control={<Radio />} label="YTD" />
          </RadioGroup>
        </FormControl>

        {/* Default Targets Section */}
        <Box sx={{ mb: 4 }}>
          <Typography variant="subtitle1" gutterBottom>
            Default Targets
          </Typography>
          <Box>
            <TextField
              label="MTD Default Target"
              type="number"
              value={
                editTargets.default_MTD === undefined
                  ? ""
                  : editTargets.default_MTD
              }
              onChange={handleDefaultTargetChange("MTD")}
              fullWidth
              margin="normal"
              sx={{ minWidth: 300 }}
              slotProps={{
                inputProps: { min: 0 },
              }}
            />
            <Divider sx={{ my: 2 }} />
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                label="Q1 Default Target"
                type="number"
                value={
                  editTargets.default_Q1 === undefined
                    ? ""
                    : editTargets.default_Q1
                }
                onChange={handleDefaultTargetChange("Q1")}
                fullWidth
                margin="normal"
                sx={{ minWidth: 300 }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
              <TextField
                label="Q2 Default Target"
                type="number"
                value={
                  editTargets.default_Q2 === undefined
                    ? ""
                    : editTargets.default_Q2
                }
                onChange={handleDefaultTargetChange("Q2")}
                fullWidth
                margin="normal"
                sx={{ minWidth: 300 }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                gap: 2,
              }}
            >
              <TextField
                label="Q3 Default Target"
                type="number"
                value={
                  editTargets.default_Q3 === undefined
                    ? ""
                    : editTargets.default_Q3
                }
                onChange={handleDefaultTargetChange("Q3")}
                fullWidth
                margin="normal"
                sx={{ minWidth: 300 }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
              <TextField
                label="Q4 Default Target"
                type="number"
                value={
                  editTargets.default_Q4 === undefined
                    ? ""
                    : editTargets.default_Q4
                }
                onChange={handleDefaultTargetChange("Q4")}
                fullWidth
                margin="normal"
                sx={{ minWidth: 300 }}
                InputProps={{
                  inputProps: { min: 0 },
                }}
              />
            </Box>
            <Divider sx={{ my: 2 }} />
            <TextField
              label="YTD Default Target"
              type="number"
              value={
                editTargets.default_YTD === undefined
                  ? ""
                  : editTargets.default_YTD
              }
              onChange={handleDefaultTargetChange("YTD")}
              fullWidth
              margin="normal"
              sx={{ minWidth: 300 }}
              InputProps={{
                inputProps: { min: 0 },
              }}
            />
          </Box>
        </Box>

        <Divider sx={{ my: 3 }} />

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="subtitle1">
            Representative Specific Targets ({representatives.length})
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Showing 20 of {representatives.length} • Sorted alphabetically
          </Typography>
        </Box>

        {/* Search Input */}
        <TextField
          label="Search Representatives"
          variant="outlined"
          fullWidth
          margin="normal"
          size="small"
          value={searchQuery}
          onChange={(e) => {
            setSearchQuery(e.target.value);
            setCurrentPage(0);
          }}
        />

        <TableContainer
          component={Paper}
          sx={{ mt: 2, maxHeight: "300px", overflowX: "auto" }}
        >
          <Table size="small" stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell>Representative</TableCell>
                <TableCell align="right">MTD Target</TableCell>
                <TableCell align="right">Q1 Target</TableCell>
                <TableCell align="right">Q2 Target</TableCell>
                <TableCell align="right">Q3 Target</TableCell>
                <TableCell align="right">Q4 Target</TableCell>
                <TableCell align="right">YTD Target</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {visibleReps.map((rep) => (
                <TableRow key={rep.id}>
                  <TableCell component="th" scope="row">
                    {rep.name}
                  </TableCell>
                  <TableCell align="right">
                    <MemoizedInput
                      value={editTargets[rep.id]?.MTD || ""}
                      onChange={handleTargetChange(rep.id, "MTD")}
                      placeholder="Default MTD"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <MemoizedInput
                      value={editTargets[rep.id]?.Q1 || ""}
                      onChange={handleTargetChange(rep.id, "Q1")}
                      placeholder="Default Q1"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <MemoizedInput
                      value={editTargets[rep.id]?.Q2 || ""}
                      onChange={handleTargetChange(rep.id, "Q2")}
                      placeholder="Default Q2"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <MemoizedInput
                      value={editTargets[rep.id]?.Q3 || ""}
                      onChange={handleTargetChange(rep.id, "Q3")}
                      placeholder="Default Q3"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <MemoizedInput
                      value={editTargets[rep.id]?.Q4 || ""}
                      onChange={handleTargetChange(rep.id, "Q4")}
                      placeholder="Default Q4"
                    />
                  </TableCell>
                  <TableCell align="right">
                    <MemoizedInput
                      value={editTargets[rep.id]?.YTD || ""}
                      onChange={handleTargetChange(rep.id, "YTD")}
                      placeholder="Default YTD"
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box
            sx={{ mt: 2, display: "flex", justifyContent: "center", gap: 2 }}
          >
            <Button
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 0}
            >
              Previous
            </Button>
            <Button
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage >= totalPages - 1}
            >
              Next
            </Button>
          </Box>
          <Typography variant="body2" color="text.secondary">
            Page {currentPage + 1} of {totalPages}
          </Typography>
        </Box>
        <Box
          sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
        >
          <Button onClick={onClose} color="inherit">
            Cancel
          </Button>
          <Button onClick={handleSave} variant="contained">
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default TargetEditModal;
