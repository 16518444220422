// appointments.js
import { API } from "aws-amplify";
import client from "./client";
import APICallWrapper from "./APICallWrappers";

const apiName = "backendGateway"; // Should be an environment variable

/**
 * Creates a new journey.
 * @param {Object} appoint - The appointment details.
 * @returns {Promise<Object>} - The created journey.
 */
export const createJourney = async (appoint) => {
  const key = `createJourney-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Creating journey...",
    "Journey created successfully.",
    "Failed to create journey.",
    () => API.post(apiName, "/journeys", { body: appoint, headers: {} })
  );
};

/**
 * Updates an existing journey.
 * @param {string} journeyId - The ID of the journey to update.
 * @param {Object} appoint - The updated appointment details.
 * @returns {Promise<Object>} - The updated journey.
 */
export const updateJourney = async (journeyId, appoint) => {
  const key = `updateJourney-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating journey...",
    "Journey updated successfully.",
    "Failed to update journey.",
    () =>
      API.put(apiName, `/journeys/${journeyId}`, { body: appoint, headers: {} })
  );
};

// Email Functions

/**
 * Sends a creation email.
 * @param {Object} emailPayload - The email details.
 * @returns {Promise<Object>} - The email sending response.
 */
export const sendCreateEmail = async (emailPayload) => {
  const key = `sendCreateEmail-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Sending creation email...",
    "Creation email sent successfully.",
    "Failed to send creation email.",
    () =>
      API.post(apiName, "/invites/appointment", {
        body: emailPayload,
        headers: {},
      })
  );
};

/**
 * Sends an edit email.
 * @param {Object} emailPayload - The email details.
 * @returns {Promise<Object>} - The email sending response.
 */
export const sendEditEmail = async (emailPayload) => {
  const key = `sendEditEmail-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Sending update email...",
    "Update email sent successfully.",
    "Failed to send update email.",
    () =>
      API.put(apiName, "/invites/appointment", {
        body: emailPayload,
        headers: {},
      })
  );
};

// Existing Functions
const getAppointments = async ({ mid, uid, limit }) => {
  const key = `getJourneys-${Date.now()}`;
  const endpoint = "/journeys";
  return await APICallWrapper(
    key,
    "Fetching journeys...",
    "Journeys fetched successfully.",
    "Failed to fetch journeys.",
    () => client.get(endpoint, { mid: mid, uid: uid, limit: limit })
  );
};

const fetchScheduleData = async ({ mid, limit }) => {
  const key = `getScheduleData-${Date.now()}`;
  const endpoint = "/schedule";
  return await APICallWrapper(
    key,
    "Fetching schedule data...",
    "Schedule data fetched successfully.",
    "Failed to fetch schedule data.",
    () => client.get(endpoint, { mid: mid, limit: limit })
  );
};

export default {
  getAppointments,
  createJourney,
  updateJourney,
  sendCreateEmail,
  sendEditEmail,
  fetchScheduleData,
};
