import React, { useState, useCallback } from "react";
import { CircularProgress, TextField, MenuItem, Paper } from "@mui/material";
import { useStore } from "../../stores/StoreContext";
import { observer } from "mobx-react";
import accountsAPI from "../../api/accounts";
import { debounce } from "lodash";

const SearchBar = observer(({ placeholder, callback = {} }) => {
  const { userInfo } = useStore();
  const [inputValue, setInputValue] = useState("");
  const [loadingAccount, setLoadingAccount] = useState(false);
  const [options, setOptions] = useState([]);

  const handleInputChange = (event) => {
    const newInputValue = event.target.value;
    setInputValue(newInputValue);
    if (newInputValue === "") {
      callback("");
      setLoadingAccount(false);
      setOptions([]);
    } else {
      setLoadingAccount(true);
      debouncedFetchAccounts(newInputValue);
    }
  };

  const handleOptionClick = (option) => {
    setInputValue(`${option.account_displayname} - ${option.account_address}`);
    callback({
      address: option.account_address,
      id: option.account_id,
      name: option.account_displayname,
    });
    setOptions([]);
  };

  const fetchAccounts = async (searchTerm) => {
    try {
      const response = await accountsAPI.searchAccounts(
        userInfo.mid,
        searchTerm
      );
      if (response.results && response.results.length > 0) {
        console.log("response.results", response.results);
        setOptions(response.results);
      } else {
        setOptions([]);
      }
    } catch (error) {
      console.error("Error fetching accounts:", error);
    } finally {
      setLoadingAccount(false);
    }
  };

  const debouncedFetchAccounts = useCallback(
    debounce((searchTerm) => fetchAccounts(searchTerm), 500),
    []
  );

  return (
    <div style={{ position: "relative", width: "100%" }}>
      <TextField
        label={placeholder}
        variant="outlined"
        fullWidth
        value={inputValue}
        onChange={handleInputChange}
      />
      {loadingAccount ? (
        <CircularProgress
          size={25}
          style={{ position: "absolute", top: "25%", right: "10px" }}
        />
      ) : null}

      {options.length > 0 && (
        <Paper
          style={{
            position: "absolute",
            top: "100%",
            width: "100%",
            maxHeight: 500,
            overflowY: "auto",
          }}
          sx={{ zIndex: 1000 }}
        >
          {options.map((option, index) => (
            <MenuItem
              sx={{ zIndex: 1000 }}
              key={index}
              onClick={() => handleOptionClick(option)}
              style={{
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "normal",
                borderBottom: "1px solid #e0e0e0",
              }}
            >
              {option.account_displayname} - {option.account_address}
            </MenuItem>
          ))}
        </Paper>
      )}
    </div>
  );
});

export default SearchBar;
