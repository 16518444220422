import APICallWrapper from "./APICallWrappers";
import client from "./client";
import { API } from "aws-amplify";
const apiName = "backendGateway";

const getStorage = async ({ mid, limit, for_marketing }) => {
  const key = `getStorage-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading storage...",
    "Storage loaded successfully.",
    "Failed to load storage.",
    () =>
      client.get("/storage", {
        mid: mid,
        limit: limit,
        for_marketing: for_marketing,
      })
  );
};

const getDistStorage = async ({ mid, limit }) => {
  const key = `getDistStorage-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading distributor storage...",
    "Distributor storage loaded successfully.",
    "Failed to load distributor storage.",
    () => client.get("/storage/distributor", { mid: mid, limit: limit })
  );
};

const getDistIds = ({ mid, limit }) => {
  const endpoint = "/storage/distributor/ids";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const putStorage = async (file) => {
  const key = `putStorage-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating storage...",
    "Storage updated successfully.",
    "Failed to update storage.",
    () => client.put("/storage", file)
  );
};

const postStorage = async (file) => {
  const key = `postStorage-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Uploading storage...",
    "Storage uploaded successfully.",
    "Failed to upload storage.",
    () => client.post("/storage", file)
  );
};

const postDistStorage = async (file) => {
  const key = `postDistStorage-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Uploading distributor storage...",
    "Distributor storage uploaded successfully.",
    "Failed to upload distributor storage.",
    () => client.post("/storage/distributor", file)
  );
};

const postSaveInFolder = async (folder_id, storage_id) => {
  const key = `postSaveInFolder-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Saving file in folder...",
    "File saved in folder successfully.",
    "Failed to save file in folder.",
    () =>
      client.post(`/storage/folders/${folder_id}?storage_id=${storage_id}`, {})
  );
};

const removeFileFromFolder = async (file_in_folder_id) => {
  const key = `removeFileFromFolder-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Removing file from folder...",
    "File removed from folder successfully.",
    "Failed to remove file from folder.",
    () => API.del(apiName, `/storage/removefile/${file_in_folder_id}`, {})
  );
};

const deleteFile = async (storage_id) => {
  const key = `deleteFile-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting file...",
    "File deleted successfully.",
    "Failed to delete file.",
    () => API.del(apiName, `/storage?storage_id=${storage_id}`, {})
  );
};

const deleteStorage = async (storageId) => {
  const key = `deleteStorage-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting storage...",
    "Storage deleted successfully.",
    "Failed to delete storage.",
    () => API.del(apiName, `/storage/distributor?storage_id=${storageId}`, {})
  );
};

export default {
  getStorage,
  getDistStorage,
  getDistIds,
  putStorage,
  postStorage,
  postSaveInFolder,
  postDistStorage,
  removeFileFromFolder,
  deleteFile,
  deleteStorage,
};
