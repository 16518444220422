import React from "react";
import Box from "@mui/material/Box";
import { keyframes } from "@mui/system";
import { useMediaQuery } from "@mui/material";

// Shimmer animation keyframes
const shimmer = keyframes`
  0% {
    background-position: -490px 0;
  }
  100% {
    background-position: 490px 0;
  }
`;

// Widget sizes based on the gridConfig
const CELL_WIDTH = 350;
const CELL_HEIGHT = 430;
const GAP = 10;

const LoadingSkeleton = () => {
  // Check if screen is small (similar to useScreenTransition in DashboardGrid)
  const isSmallScreen = useMediaQuery("(max-width:1600px)");

  // Determine number of columns based on screen size
  const columns = isSmallScreen ? 2 : 4;

  // Calculate grid dimensions
  const totalWidth = CELL_WIDTH * columns + GAP * (columns - 1) + GAP * 2;
  const totalHeight = isSmallScreen
    ? CELL_HEIGHT * 4 + GAP * 3 + GAP * 2 // 4 rows when small
    : CELL_HEIGHT * 2 + GAP * 1 + GAP * 2; // 2 rows when large

  // Common styles for shimmer boxes
  const commonStyles = {
    borderRadius: "8px",
    animation: `${shimmer} 1.5s infinite linear forwards`,
    background: "#f6f7f8",
    backgroundImage: `linear-gradient(to right, #f6f7f8 0%, #edeef1 20%, #f6f7f8 40%, #f6f7f8 100%)`,
    backgroundSize: `${totalWidth}px ${CELL_HEIGHT}px`,
    position: "relative",
  };

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: `repeat(${columns}, ${CELL_WIDTH}px)`,
        gridTemplateRows: isSmallScreen
          ? `repeat(4, ${CELL_HEIGHT}px)` // 4 rows when small
          : `repeat(2, ${CELL_HEIGHT}px)`, // 2 rows when large
        gap: `${GAP}px`,
        padding: `${GAP}px`,
        backgroundColor: "#FFF",
        borderRadius: "8px",
        width: "fit-content",
        margin: "0 auto",
        position: "relative",
      }}
    >
      {isSmallScreen ? (
        // Small screen layout - stack widgets vertically
        <>
          {/* Row 1: 2-col widget (full width) */}
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "1 / span 2",
              gridRow: "1 / span 1",
            }}
          />

          {/* Row 2: 2-col widget (full width) */}
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "1 / span 2",
              gridRow: "2 / span 1",
            }}
          />

          {/* Row 3: 2-col widget (full width) */}
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "1 / span 2",
              gridRow: "3 / span 1",
            }}
          />

          {/* Row 4: 2-col widget (full width) */}
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "1 / span 2",
              gridRow: "4 / span 1",
            }}
          />
        </>
      ) : (
        // Large screen layout
        <>
          {/* Row 1: 2-col widget + 1-col widget + 1-col widget */}
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "1 / span 2",
              gridRow: "1 / span 1",
            }}
          />
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "3 / span 1",
              gridRow: "1 / span 1",
            }}
          />
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "4 / span 1",
              gridRow: "1 / span 1",
            }}
          />

          {/* Row 2: 2-col widget + 2-col widget */}
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "1 / span 2",
              gridRow: "2 / span 1",
            }}
          />
          <Box
            sx={{
              ...commonStyles,
              gridColumn: "3 / span 2",
              gridRow: "2 / span 1",
            }}
          />
        </>
      )}
    </Box>
  );
};

export default LoadingSkeleton;
