import React, { useEffect, useState } from "react";
import {
  Checkbox,
  Grid2,
  Box,
  FormControlLabel,
  Button,
  IconButton,
  Switch,
  Typography,
  Popover,
  TextField,
  Pagination,
} from "@mui/material";
import { HelpOutline, Search } from "@mui/icons-material";

const MultipleSimpleExplanation = () => {
  return (
    <Box p={1}>
      <Typography variant="subtitle2">Multiple</Typography>
      <Typography variant="body2">
        Funnel filter by states, then distributors, then brands
      </Typography>
      <Typography variant="subtitle2">Single</Typography>
      <Typography variant="body2">
        Select any item and all matching items will be activated
      </Typography>
    </Box>
  );
};

const MegaFilterStates = ({
  allFields,
  activeFields,
  mfType,
  simpleMode,
  setSimpleMode,
  enabledList = [],
  addToFilter,
  removeFromFilter,
  listName,
  setAll,
  // toggleAll = () => {},
}) => {
  const [formattedList, setFormattedList] = useState([]);
  const [value, setValue] = useState("");
  const [unitRange, setUnitRange] = useState([0, 20]);
  const [popEl, setPopEl] = useState(null);

  const handlePageChange = (a) => {
    setUnitRange([(a - 1) * 20, a * 20]);
  };

  const handleSearchChange = (e) => {
    setValue(e.target.value);
    const filteredList = allFields.list
      .filter((item) =>
        item.name.toLowerCase().includes(e.target.value.toLowerCase())
      )
      .sort((a, b) => a.name.localeCompare(b.name));
    setFormattedList([...filteredList]);
  };

  const totalPages = Math.ceil(formattedList.length / 20);

  useEffect(() => {
    setFormattedList([...allFields.list].sort());
  }, [allFields.list]);

  const popoverSummon = (event) => setPopEl(event.currentTarget);
  const popoverDismiss = (event) => setPopEl(null);
  const popoverOpen = Boolean(popEl);
  const id = popoverOpen ? "simple-popover" : undefined;

  const changeCheckBox = (value) => {
    // if the item is active, we are turning it off
    if (activeFields.includes(value) && !simpleMode) {
      removeFromFilter({
        list: listName,
        value,
      });
    } else {
      // in simple mode, we always turn the item on
      // other changes take place in index.js
      addToFilter({
        list: listName,
        value,
      });
    }
  };

  return (
    <Grid2 style={{ width: "100%" }}>
      <Grid2
        container
        style={{ paddingBottom: "12px" }}
        justifyContent="flex-start"
        alignItems="flex-start"
        alignContent="flex-start"
      >
        {formattedList.slice(unitRange[0], unitRange[1]).map((o, i) => (
          <Grid2 size={3} key={i} style={{ flexGrow: 0 }}>
            <FormControlLabel
              classes={{
                root: "mega-filter-checkbox",
              }}
              control={
                <Checkbox
                  name={o.name}
                  checked={activeFields.includes(o.id)}
                  onChange={() => changeCheckBox(o.id)}
                  disabled={
                    !simpleMode &&
                    mfType !== "states" &&
                    !enabledList.includes(o.id)
                  }
                />
              }
              label={o.name}
            />
          </Grid2>
        ))}
      </Grid2>
      <Grid2
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
          marginTop: "auto",
        }}
      >
        <div>
          <Search style={{ marginBottom: "-6px" }} />
          <TextField
            size="small"
            id="outlined-basic"
            label="Search"
            value={value}
            variant="outlined"
            onChange={(text) => handleSearchChange(text)}
            style={{
              width: "200px",
              marginBottom: "10px",
              touchAction: "none",
            }}
          />
        </div>

        <Grid2
          component="label"
          alignItems="center"
          container
          style={{
            paddingLeft: "1rem",
            width: "14rem",
          }}
        >
          <Grid2 item>
            <Typography variant="caption">Multiple</Typography>
          </Grid2>
          <Grid2 item>
            <Switch
              checked={simpleMode}
              onChange={(event) => {
                setSimpleMode(event.target.checked);
              }}
              name="checkedA"
              inputProps={{ "aria-label": "secondary checkbox" }}
            />
          </Grid2>
          <Grid2 item>
            <Typography variant="caption">Single</Typography>
          </Grid2>
          <Grid2 item>
            <IconButton
              color="primary"
              size="small"
              variant="contained"
              aria-describedby={id}
              onClick={popoverSummon}
            >
              <HelpOutline />
            </IconButton>
            <Popover
              id={id}
              open={popoverOpen}
              anchorEl={popEl}
              onClose={popoverDismiss}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "right",
              }}
            >
              <MultipleSimpleExplanation />
            </Popover>
          </Grid2>
        </Grid2>
        <Pagination
          style={{
            visibility: totalPages < 1 ? "hidden" : "visible",
          }}
          count={totalPages}
          onChange={(e, page) => handlePageChange(page)}
        />
        <Grid2
          style={{
            display: "inline-block",
            visibility: "visible",
            pointerEvents: "auto",
          }}
          item
        >
          <Button
            onClick={() => {
              console.log(activeFields.length, allFields);
              setAll({
                list: listName,
                on: activeFields.length < formattedList.length,
              });
            }}
            variant="outlined"
            color="primary"
          >
            Toggle All
          </Button>
        </Grid2>
      </Grid2>
    </Grid2>
  );
};

export default MegaFilterStates;
