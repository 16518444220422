import React from "react";
import { bottles } from "./bottleSVGs";
import { random } from "lodash";

const colors = [
  "#FF5733",
  "#33FF57",
  "#3357FF",
  "#FF33A6",
  "#33FFF6",
  "#FFBD33",
  "#57FF33",
  "#A633FF",
  "#FF3357",
  "#33A6FF",
  "#F6FF33",
  "#33FFBD",
  "#BD33FF",
  "#FF3333",
  "#33FF33",
  "#3333FF",
  "#FF5733",
  "#A6FF33",
  "#FFBD57",
  "#5733FF",
  "#BD57FF",
  "#33FFD3",
  "#D333FF",
];

const parseInteger = (input) => {
  if (typeof input === "number" && !isNaN(input)) {
    return Math.floor(input); // Return the integer part if it's a valid number
  }
  if (typeof input === "string") {
    const match = input.match(/-?\d+/); // Extract the first valid integer from the string
    if (match) {
      return parseInt(match[0], 10);
    }
    // Handle non-numeric strings like "cheese"
    return input.split("").reduce((sum, char) => sum + char.charCodeAt(0), 0);
  }
  return random(1, 500); // Return 0 for all other cases
};

const getDefaultBottleSVG = (product_id) => {
  const id = parseInteger(product_id, 10);
  const svgIndex = id % bottles.length;
  const colorIndex = id % colors.length;

  return {
    selectedBottle: bottles[svgIndex],
    selectedColor: colors[colorIndex],
  };
};

const DefaultAccountBottleById = ({ product_id, width = 50, height = 50 }) => {
  const { selectedBottle: BottleComponent, selectedColor } =
    getDefaultBottleSVG(product_id);

  return (
    <div>
      <BottleComponent
        width={width}
        height={height}
        fillColor={selectedColor}
      />
    </div>
  );
};

export { colors, getDefaultBottleSVG, DefaultAccountBottleById };
