import React, { useMemo, useState } from "react";
import {
  Box,
  Breadcrumbs,
  Grid2,
  Card,
  CircularProgress,
  Link,
  IconButton,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useObserver } from "mobx-react-lite";
import DeleteModal from "../deleteModal/DeleteModal";
import MediaFolder from "./MediaFolder";
import MediaFile from "./MediaFile.js";

import MediaLibraryTableView from "./MediaLibraryTableView.js";
import { ChevronRight, ViewList, ViewModule } from "@mui/icons-material";

const MediaLibrary = ({
  handleDownload,
  handleViewOriginal,
  handleDeleteConfirm,
  handleCopyImageUrlToClipboard,
  handleEditFileName,
  selectionMode,
  selectedItems,
  handleSelectItem,
  handleSelectAll,
  handleDeselectAll,
  handleBulkDelete,
  handleBulkDownload,
  setFiles,
  files,
  folder_id,
  file_id,
  loading,
  manufacturer,
  setFolders,
  userInfo,
  folders,
  currentFiles,
  currentFolders,
}) => {
  const navigate = useNavigate();
  const [deleteMode, setDeleteMode] = useState(false);

  const handleCard = (item) => {
    // return console.log(item)
    if (Object.hasOwn(item, "folder_id")) {
      navigate(`/app/media/folder/${item.folder_id}`);
    }
    if (Object.hasOwn(item, "file_id")) {
      if (folder_id) {
        navigate(`/app/media/folder/${folder_id}/${item.file_id}`);
      } else {
        navigate(`/app/media/root/${item.file_id}`);
      }
    }
  };

  const crumbs = useMemo(() => {
    return manufacturer
      ? [
          {
            text: `${manufacturer.manufacturer_displayname} files`,
            link: "marketingFiles",
          },
        ]
      : [];
  }, [manufacturer]);

  const handleConfirmBulkDelete = () => {
    handleBulkDelete();
    setDeleteMode(false);
  };

  const handleCancelBulkDelete = () => {
    setDeleteMode(false);
  };

  return useObserver(() => (
    <div>
      <>
        <Box mb={1} mt={1} display="flex" justifyContent="space-between">
          {!(folder_id || file_id) && (
            <Breadcrumbs separator={<ChevronRight />}>
              {crumbs.map((c, index) => (
                <Link key={index}>{c.text}</Link>
              ))}
            </Breadcrumbs>
          )}
        </Box>
        {folder_id && !file_id && (
          <MediaFolder
            currentFolders={currentFolders}
            currentFiles={currentFiles}
            handleDownload={handleDownload}
            handleViewOriginal={handleViewOriginal}
            handleDeleteConfirm={handleDeleteConfirm}
            handleCopyImageUrlToClipboard={handleCopyImageUrlToClipboard}
            handleEditFileName={handleEditFileName}
            selectionMode={selectionMode}
            selectedItems={selectedItems}
            handleSelectItem={handleSelectItem}
            handleSelectAll={handleSelectAll}
            handleDeselectAll={handleDeselectAll}
            handleBulkDelete={handleBulkDelete}
            handleBulkDownload={handleBulkDownload}
            folder_id={folder_id}
            files={files}
            setFiles={setFiles}
            setFolders={setFolders}
            userInfo={userInfo}
            folders={folders}
            manufacturer={manufacturer}
          />
        )}
        {!file_id && (
          <>
            <Box py={2}>
              <MediaLibraryTableView
                selectionMode={selectionMode}
                selectedItems={selectedItems}
                handleSelectItem={handleSelectItem}
                handleDownload={handleDownload}
                handleDeleteConfirm={handleDeleteConfirm}
                handleEditFileName={handleEditFileName}
                handleCard={handleCard}
                currentFiles={currentFiles}
                currentFolders={currentFolders}
                {...{
                  setFolders,
                  folders,
                  setFiles,
                  files,
                }}
              />
            </Box>
          </>
        )}

        {loading && (
          <Card style={{ height: "150px", width: "150px" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              style={{ height: "100%" }}
            >
              <CircularProgress />
            </Box>
          </Card>
        )}

        <DeleteModal
          title="Delete Selected Files?"
          description="Are you sure you want to delete the selected files?"
          open={deleteMode}
          onClose={handleCancelBulkDelete}
          handleConfirmDelete={handleConfirmBulkDelete}
        />
      </>
      {file_id && (
        <MediaFile
          folders={folders}
          folder_id={folder_id}
          files={files}
          setFiles={setFiles}
          file_id={file_id}
        />
      )}
    </div>
  ));
};

export default MediaLibrary;
