import React, { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { Observer } from "mobx-react-lite";
import {
  Box,
  Card,
  Chip,
  Link,
  CardContent,
  Dialog,
  Button,
  CardHeader,
  Typography,
  Avatar,
  AvatarGroup,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { toJS } from "mobx";
import ContactListModal from "./ContactListModal";
import ContactModal from "./ContactModal";
import AccountDetailsModal from "./AccountDetailsModal";
import useApi from "../../../hooks/useApi";
import usersApi from "../../../api/users";
import ScheduleManager from "../../../components/EventManager";
import { makeStyles } from "@mui/styles";
import {
  DateRange,
  Edit,
  PermContactCalendar,
  PersonPinCircle,
} from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
  fullHeightCard: {
    height: "100%",
  },
}));

const Account = ({
  className,
  setCheckin,
  availablePremiseTypes,
  customers,
  schedules,
  userInfo,
  emitAccount,
}) => {
  const { id } = useParams();
  const { uid, mid, roles } = userInfo;
  const [checkingIn, setCheckingIn] = useState(false);
  const [open, setOpen] = useState(false);
  const [openContact, setOpenContact] = useState(false);
  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [openCreateContact, setCreateContact] = useState(false);
  const [contactList, setContactList] = useState([]);
  const [account, setAccount] = useState({});
  const getUsersApi = useApi(usersApi.getUsers);
  const [members, setMembers] = useState(false);
  const fetchUsersData = async () => {
    try {
      console.log("Fetching users data...");
      const usersResponse = await getUsersApi.request({
        mid: userInfo.mid,
        limit: 1000,
      });
      console.log("Fetched users data:", usersResponse);
      setMembers(usersResponse.results);
    } catch (error) {
      console.error("Failed to fetch users data", error);
    }
  };

  useEffect(() => {
    fetchUsersData();
  }, []);

  const isAdmin = userInfo.roles
    ? userInfo.roles.includes("ADMIN")
      ? true
      : false
    : false;

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleCreateContact = () => {
    setCreateContact(true);
  };
  const handleCreateContactClose = () => {
    setCreateContact(false);
  };
  const handleClickContact = () => {
    setOpenContact(true);
  };
  const handleClickClose = () => {
    setOpenContact(false);
  };
  const handleEditOpen = () => {
    setOpenEditAccount(true);
  };
  const handleEditClose = () => {
    setOpenEditAccount(false);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const refreshContacts = useCallback(async () => {
    try {
      const data = await API.get("backendGateway", "/accounts/contact/list", {
        queryStringParameters: {
          aid: id,
          mid: mid,
        },
      });
      //console.log("data from Lambda REST API: ", data);
      setContactList(data.results);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }, [id, mid]);

  useEffect(() => {
    refreshContacts();
  }, [refreshContacts]);

  useEffect(() => {
    async function getAccountID(id) {
      try {
        const data = await API.get("backendGateway", "/accounts", {
          queryStringParameters: {
            account_id: id,
          },
        });
        setAccount(data.results[0]);
        emitAccount(data.results[0]);
      } catch (err) {
        console.log("error fetching account data..", err);
      }
    }
    getUsersApi.request({ mid: userInfo.mid, limit: 1000 });

    let itemAccount = toJS(
      customers.filter((item) => toJS(item).account_id === id)
    );
    let scheduleAccount = toJS(
      schedules.filter((item) => toJS(item).account_id === id)
    );
    if (itemAccount.length > 0) {
      setAccount(itemAccount);
    } else if (scheduleAccount.length > 0) {
      //incase it's scheduled multiple times
      let acc = scheduleAccount[0];
      setAccount(acc);
    } else {
      getAccountID(id);
    }
  }, [customers, id, schedules]);

  function truncateString(str, num) {
    if (str.length <= num) {
      return str;
    }
    return str.slice(0, num) + "...";
  }

  async function addCheckIn() {
    setCheckingIn(true);
    try {
      const apiName = "backendGateway";
      const path = "/accounts/checkin";
      const myInit = {
        // OPTIONAL
        body: {
          user_id: uid,
          account_id: id,
          manufacturer_id: mid,
        },
        headers: {}, // OPTIONAL
      };
      await API.post(apiName, path, myInit).then((response) =>
        console.log("Check-in response:", response)
      );
      setCheckingIn(false);
      setCheckin("Today");
    } catch (err) {
      console.log("error creating check-in..", err);
      setCheckingIn(false);
    }
  }

  const classes = useStyles();
  return (
    <Observer>
      {() => (
        <Card className={classes.fullHeightCard}>
          <CardHeader
            action={
              <Box>
                <Button
                  onClick={() => {
                    handleClickOpen();
                  }}
                  className="button-two"
                  disabled={members.length === 0}
                  startIcon={<DateRange />}
                >
                  Plan Visit
                </Button>
                <Button
                  onClick={() => {
                    addCheckIn();
                  }}
                  className="button-two"
                  startIcon={<PersonPinCircle />}
                  disabled={checkingIn}
                >
                  Checkin
                </Button>
                <Button
                  onClick={() => {
                    handleCreateContact();
                  }}
                  className="button-two"
                  startIcon={<PermContactCalendar />}
                  disabled={checkingIn}
                >
                  Add Contact
                </Button>
                {roles.includes("ADMIN") && (
                  <Button
                    onClick={() => {
                      handleEditOpen();
                    }}
                    className="button-two"
                    startIcon={<Edit />}
                    disabled={checkingIn}
                  >
                    Edit
                  </Button>
                )}
              </Box>
            }
          ></CardHeader>
          <CardContent>
            <Box
              alignItems="center"
              display="flex"
              flexDirection="column"
              key={account.distributor_account_id}
            >
              <Typography
                align="center"
                color="textPrimary"
                gutterBottom
                variant="h4"
              >
                {account.account_displayname || ""}
              </Typography>
              <Typography color="textSecondary" variant="body1" align="center">
                {`${[
                  account.account_address,
                  account.account_address_2,
                  account.account_city,
                  account.account_region,
                ]
                  .filter((element) => element)
                  .join(", ")}`}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {`Zip code: ${account.account_regioncode || "N/A"}`}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {`Phone: ${account.account_phone || "N/A"}`}
              </Typography>
              <Typography color="textSecondary" variant="body1">
                {truncateString(account.account_repname || "", 30)}
              </Typography>
              <Chip
                color="primary"
                label={account.premise_type || "UNKNOWN"}
                variant="outlined"
                style={{ margin: "15px" }}
              />
              <AvatarGroup max={8} onClick={() => handleClickContact()}>
                {contactList.map((contact) => (
                  <Avatar
                    key={contact.contact_id}
                    alt={contact.name}
                    src="/static/images/avatar/1.jpg"
                  />
                ))}
              </AvatarGroup>
            </Box>
            <Dialog
              onClose={handleClickClose}
              aria-labelledby="product-dialog"
              open={openContact}
            >
              <ContactListModal
                userInfo={userInfo}
                onClose={handleClickClose}
                open={openContact}
                setContactList={setContactList}
                contactList={contactList}
              />
            </Dialog>
            <Dialog
              onClose={handleEditClose}
              aria-labelledby="account-dialog"
              open={openEditAccount}
            >
              <AccountDetailsModal
                onClose={handleEditClose}
                open={openEditAccount}
                accountDetails={account}
                saveValues={setAccount}
                availablePremiseTypes={availablePremiseTypes}
              />
            </Dialog>
            <ContactModal
              open={openCreateContact}
              contact={null}
              setContactList={setContactList}
              contactList={contactList}
              onClose={handleCreateContactClose}
              refreshContacts={refreshContacts}
              userInfo={userInfo}
            />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="form-dialog-title"
              BackdropProps={{
                style: { backgroundColor: "black", opacity: 0.1 },
              }}
            >
              <ScheduleManager
                accountDetails={{
                  id: account.account_id || null,
                  name: account.account_displayname || "",
                  address: account.account_address || "",
                  city: account.account_city || "",
                  latitude: account.account_latitude || null,
                  longitude: account.account_longitude || null,
                }}
                members={members}
                userInfo={userInfo}
                open={open}
                handleClose={handleClose}
                refresh={() => {}}
              />
            </Dialog>
          </CardContent>
          <div id="m"></div>
        </Card>
      )}
    </Observer>
  );
};

Account.propTypes = {
  className: PropTypes.string,
};

export default Account;
