import { useEffect, useState } from "react";
import { LinearProgress, Typography } from "@mui/material";

const MapToolTipGetter = ({
  account,
  measurementUnit,
  loadAccount,
  accountHash,
}) => {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);

  const { name, acc_id } = account.properties;

  useEffect(() => {
    if (!accountHash[acc_id]) {
      setLoading(true);
      loadAccount(acc_id);
    } else {
      setLoading(false);
      setProducts(accountHash[acc_id]);
    }
  }, [accountHash, account, loadAccount]);

  const unitPicker = (input) => {
    if (measurementUnit.value === "physical_sold") {
      return input.cases;
    }
    if (measurementUnit.value === "retail_unit") {
      return input.unit;
    }
    return input.cases_eqv;
  };
  return (
    <div
      style={{
        maxWidth: "300px",
        width: "300px",
        overflow: "hidden",
        wordWrap: "break-word",
        whiteSpace: "normal",
      }}
    >
      {loading && <LinearProgress />}
      <Typography
        sx={{
          wordBreak: "break-word",
          overflowWrap: "break-word",
          whiteSpace: "normal",
          width: "100%",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        {name}
      </Typography>
      {products.length > 0 && (
        <div>
          <Typography style={{ fontSize: "16px", fontWeight: "semibold" }}>
            Top Sellers
          </Typography>
          {products.slice(0, 3).map((t, ind) => {
            const { prod_name, prod_id } = t;
            return (
              <div
                key={prod_id}
                style={{
                  overflowWrap: "break-word",
                  wordWrap: "break-word",
                  wordBreak: "break-all",
                  hyphens: "auto",
                  whiteSpace: "normal",
                  width: "100%",
                }}
              >
                <span style={{ display: "inline-block", width: "100%" }}>
                  {prod_name} / <strong>{unitPicker(t)}</strong>{" "}
                  {measurementUnit.text}
                </span>
              </div>
            );
          })}
        </div>
      )}
      {products.length === 0 && !loading && (
        <Typography variant="h6">No sales in selected date range</Typography>
      )}
      {account.properties.last_sale_date && (
        <Typography
          sx={{
            fontSize: "12px",
            color: "#2B60E7",
            overflowWrap: "break-word",
            wordBreak: "break-all",
            whiteSpace: "normal",
          }}
        >
          Last Sale Date:{" "}
          {new Date(account.properties.last_sale_date).toLocaleDateString()}
        </Typography>
      )}
    </div>
  );
};

export default MapToolTipGetter;
