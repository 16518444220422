import { Box, Card } from "@mui/material";
import StText from "../builder/fields/StText";
import StTextArea from "../builder/fields/StTextArea";
import StNumber from "../builder/fields/StNumber";
import StSelect from "../builder/fields/StSelect";
import StDate from "../builder/fields/StDate";
import StCheckbox from "../builder/fields/StCheckbox";
import StRadio from "../builder/fields/StRadio";
import StDynamicOptions from "../builder/fields/StDynamicOptions";
import StAccount from "../builder/fields/StAccount";
import StContact from "../builder/fields/StContact";
import StFile from "../builder/fields/StFile";
import ConditionalGroupRender from "./ConditionalGroupRender";

const FieldWrapper = ({
  field,
  error = false,
  updateAnswer,
  answer = { value: "", textValue: "", objectValue: {} },
  helperText = "",
  answers,
  groups = [],
  parentLabel = "",
  nested = false,
}) => {
  const answerFunctions = {
    simple: (value) => {
      updateAnswer({
        field,
        value,
        parentLabel,
      });
    },
    twoValues: (value, textValue) => {
      updateAnswer({
        field,
        value,
        textValue,
        parentLabel,
      });
    },
    threeValues: ({ value, textValue, objectValue }) => {
      updateAnswer({
        field,
        value,
        textValue,
        objectValue,
        parentLabel,
      });
    },
  };
  let Comp = StText;
  let changeAnswer = answerFunctions.simple;
  let reportElem = "";
  // TODO: add all functions
  switch (field.element) {
    case "Checkboxes":
      Comp = StCheckbox;
      changeAnswer = answerFunctions.twoValues;
      break;
    case "RadioButtons":
      Comp = StRadio;
      changeAnswer = answerFunctions.twoValues;
      break;
    case "TextInput":
      Comp = StText;
      break;
    case "TextArea":
      Comp = StTextArea;
      break;
    case "NumberInput":
    case "Number":
      Comp = StNumber;
      break;
    case "Dropdown":
      Comp = StSelect;
      changeAnswer = answerFunctions.twoValues;
      break;
    case "DatePicker":
      Comp = StDate;
      break;
    case "DatabaseOption":
      Comp = StDynamicOptions;
      changeAnswer = answerFunctions.twoValues;
      break;
    case "Contact":
      Comp = StContact;
      changeAnswer = answerFunctions.threeValues;
      break;
    case "File":
      Comp = StFile;
      changeAnswer = answerFunctions.twoValues;
      break;
    case "Account":
      Comp = StAccount;
      changeAnswer = answerFunctions.threeValues;
      break;
    default:
      reportElem = field.element;
  }
  return (
    <Box width={"100%"} id={field.field_name} display="flex">
      <Card
        style={{
          width: "100%",
          padding: nested ? 5 : 20,
          marginBottom: nested ? 0 : -5,
          boxShadow: "none",
        }}
      >
        <Comp
          {...field}
          {...{
            error,
            changeAnswer,
            answer,
            helperText,
          }}
        />
        <ConditionalGroupRender
          parentFieldId={field.id}
          parent={field}
          groups={groups}
          answer={answer}
          updateAnswer={updateAnswer}
          answers={answers}
          options={field.options || []}
          parentLabel={parentLabel}
          nested={nested}
        />
        {reportElem && <p>{reportElem}</p>}
      </Card>
    </Box>
  );
};

export default FieldWrapper;
