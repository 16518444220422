import React, { useState } from "react";
import { Box, FormControl, InputLabel, Select, MenuItem } from "@mui/material";

const YearMonthFilter = ({ onDateRangeChange }) => {
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear.toString());
  const [selectedMonth, setSelectedMonth] = useState("");

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  const years = Array.from({ length: 10 }, (_, i) =>
    (currentYear - i).toString()
  );

  const handleDateRangeChange = (year, month) => {
    const startDate = new Date(`${year}-${month}-01`);
    const endDate = new Date(startDate);
    endDate.setMonth(startDate.getMonth() + 1); // Move to the next month
    endDate.setDate(0); // Set to the last day of the month

    onDateRangeChange([
      startDate.toISOString().split("T")[0], // Start date in YYYY-MM-DD format
      endDate.toISOString().split("T")[0], // End date in YYYY-MM-DD format
    ]);
  };

  const handleYearChange = (event) => {
    const year = event.target.value;
    setSelectedYear(year);

    if (selectedMonth) {
      handleDateRangeChange(year, selectedMonth); // Trigger range update if both year and month are set
    }
  };

  const handleMonthChange = (event) => {
    const month = event.target.value;
    setSelectedMonth(month);

    if (selectedYear) {
      handleDateRangeChange(selectedYear, month); // Trigger range update if both year and month are set
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        gap: 2,
        flexWrap: "wrap", // Allow wrapping for smaller screens
        alignItems: "center", // Center align items vertically
      }}
    >
      <FormControl
        sx={{
          minWidth: 0, // Minimum width for dropdowns
        }}
      >
        <InputLabel>Year</InputLabel>
        <Select value={selectedYear} onChange={handleYearChange}>
          {years.map((year) => (
            <MenuItem key={year} value={year}>
              {year}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <FormControl
        sx={{
          minWidth: 240, // Minimum width for dropdowns
        }}
      >
        <InputLabel>Month</InputLabel>
        <Select value={selectedMonth} onChange={handleMonthChange}>
          {months.map((month) => (
            <MenuItem key={month.value} value={month.value}>
              {month.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default YearMonthFilter;
