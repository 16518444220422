import { Box, Divider, Typography } from "@mui/material";
import {
  states_and_codes,
  premise_types,
} from "../../report_builder_filter_values";
import FilterDropdown from "./FilterDropdown";
import { useCallback, useEffect, useState } from "react";

export const AccountsFilter = ({ requestBody, setRequestBody }) => {
  const [selectedRegions, setSelectedRegions] = useState(
    requestBody.filters.dimensions.state_id || []
  );
  const [selectedPremiseTypes, setSelectedPremiseTypes] = useState(
    requestBody.filters.dimensions.premise_id || []
  );

  const showStuff = requestBody.select.dimensions.some(
    (s) => s.startsWith("account") || s.startsWith("prem")
  );
  const showRegionFilter = showStuff;
  const showPremiseTypeFilter = showStuff;

  const handleRegionChange = (newRegions) => {
    setSelectedRegions(newRegions);
  };

  const onChange = useCallback(() => {
    setRequestBody((prev) => {
      const newDimensions = { ...prev.filters?.dimensions };

      // Handle state_id
      if (selectedRegions.length > 0) {
        newDimensions.state_id = selectedRegions;
      } else {
        delete newDimensions.state_id;
      }

      // Handle premise_id
      if (selectedPremiseTypes.length > 0) {
        newDimensions.premise_id = selectedPremiseTypes;
      } else {
        delete newDimensions.premise_id;
      }

      return {
        ...prev,
        filters: {
          ...prev.filters,
          dimensions: newDimensions,
        },
      };
    });
  }, [selectedRegions, selectedPremiseTypes]);

  useEffect(() => {
    onChange();
  }, [onChange]);

  return (
    <Box sx={{ mt: 2 }}>
      {showRegionFilter && (
        <FilterDropdown
          title="Market"
          options={states_and_codes}
          valueKey="state"
          idKey="id"
          selectedValues={selectedRegions}
          setSelectedValues={handleRegionChange}
        />
      )}
      {showPremiseTypeFilter && (
        <FilterDropdown
          title="Premise Type"
          options={premise_types}
          valueKey="premise_name"
          idKey="premise_id"
          selectedValues={selectedPremiseTypes}
          setSelectedValues={setSelectedPremiseTypes}
        />
      )}
      {showRegionFilter || showPremiseTypeFilter ? (
        <Divider sx={{ mt: 2, mb: 2 }} />
      ) : (
        <Typography>
          No fields selected, therefore nothing to filter on.
        </Typography>
      )}
    </Box>
  );
};
