import React, { useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Grid2,
  TextField,
} from "@mui/material";
import { Storage, API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { useForm, Controller } from "react-hook-form";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {},
}));

const ListDetails = ({
  className,
  item,
  setItem,
  onClose,
  allLists,
  setData,
  adjustListsByRegion,
  ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [preview, setPreview] = useState(false);
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [previewUrl, setPreviewUrl] = useState("");
  const store = useStore();
  const { setUserInfo, userInfo } = store;

  const [values, setValues] = useState(
    item || {
      url: "",
      list_id: "",
      list_name: "",
      list_image: "",
      list_imagekey: userInfo.user_identitykey,
      description: "",
      type: "USER",
      tags: "",
      manufacturer_id: userInfo.mid,
      user_id: userInfo.uid,
      is_active: 1,
    }
  );
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const changeHandler = (event) => {
    const filename = uuid();
    setFile(event.target.files[0]);
    setFileName(filename);
    setPreview(true);
    setPreviewUrl(URL.createObjectURL(event.target.files[0]));
  };

  async function handleSubmission(file) {
    try {
      await Storage.put(fileName, file, {
        contentType: "image/*", // contentType is optional
        level: "protected",
      });
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
  }

  async function userAccessToList(listId) {
    try {
      if (userInfo.access) {
        let currentAccess = userInfo.access;
        let lists = [...currentAccess.lists, listId];
        const access = {
          lists: lists,
          screens: currentAccess.screens,
          distributors: currentAccess.distributors,
          markets: currentAccess.markets,
        };
        const apiName = "backendGateway";
        const path = "/users/access";
        const jsonBody = {
          user_id: userInfo.uid,
          access_settings: JSON.stringify(access),
        };
        const myInit = {
          // OPTIONAL
          body: jsonBody,
          headers: {}, // OPTIONAL
        };
        await API.put(apiName, path, myInit);
        setUserInfo({ ...userInfo, access: JSON.stringify(access) });
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }

  async function handleSave(payload) {
    setLoading(true);
    if (preview) {
      await handleSubmission(file);
    }
    let _values = { ...values };

    _values.list_image = fileName;
    _values.list_imagekey = userInfo.user_identitykey;
    _values.image_scr = awsS3Image(fileName, userInfo.user_identitykey, "0x0");
    const mergedPayload = {
      ..._values,
      ...payload,
    };
    try {
      const apiName = "backendGateway";
      const path = "/accounts/lists";
      const myInit = {
        body: mergedPayload,
        headers: {},
      };
      if (values.list_id === "") {
        const newList = await API.post(apiName, path, myInit);
        const new_list_id = newList.results.insertId || newList.results[0];
        console.log("List ID " + newList.results.insertId);
        adjustListsByRegion(
          {
            ...mergedPayload,
            list_id: new_list_id,
          },
          "created"
        );
        userAccessToList(new_list_id);
      } else {
        await API.put(apiName, path, myInit);
        adjustListsByRegion(mergedPayload, "updated");
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
    if (typeof setItem != "undefined") {
      setItem(mergedPayload);
    }
    onClose();
  }

  async function deleteList(listId) {
    setLoading(true);
    try {
      await API.del("backendGateway", "/accounts/lists", {
        queryStringParameters: {
          list_id: listId,
        },
      });
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setData(allLists.filter((x) => x.list_id !== listId));
    setLoading(false);
    onClose();
    adjustListsByRegion({ list_id: listId }, "deleted");
  }

  const handleAddChip = (chip) => {
    if (values.tags !== "") {
      setValues({
        ...values,
        tags: values.tags + ";" + chip.toUpperCase(),
      });
    } else {
      setValues({
        ...values,
        tags: chip.toUpperCase(),
      });
    }
  };

  const handleDeleteChip = (chip, index) => {
    let tags =
      values.tags
        .split(";")
        .filter((c, i) => i !== index)
        .join(";") !== ""
        ? values.tags
            .split(";")
            .filter((c, i) => i !== index)
            .join(";")
        : "";
    //console.log('g'+tags+'d');
    setValues({
      ...values,
      tags: tags,
    });
  };

  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      onSubmit={handleSubmit(handleSave)}
      {...rest}
    >
      <Card>
        <CardHeader title="Create List" />
        <CardContent>
          {preview && (
            <img src={previewUrl} width="150px" height="180px" alt="" />
          )}
          {!preview && values.list_image === "" && (
            <img
              src={"/static/images/placeholders/missing-image.png"}
              width="150px"
              height="180px"
              alt=""
            />
          )}

          {!preview && values.list_image !== null && (
            <img
              src={values.image_scr}
              style={{
                width: "150px",
                height: "180px",
                margin: "30px",
              }}
              alt=""
            />
          )}
          <Box display="flex" alignItems="center" p={2}>
            <Button color="primary" variant="contained" component="label">
              Upload picture
              <input
                accept="image/*"
                type="file"
                hidden
                onChange={changeHandler}
              />
            </Button>
          </Box>
          <Grid2 container spacing={3}>
            <Grid2 size={12} size={12}>
              <Controller
                name="list_name"
                defaultValue={values.list_name}
                control={control}
                rules={{
                  required: true,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="List Name"
                    required
                    variant="outlined"
                    error={errors.list_name ? true : false}
                    helperText={`${
                      errors.list_name?.type === "required"
                        ? "List name is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid2>

            <Grid2 size={12} size={12}>
              <Controller
                name="description"
                control={control}
                defaultValue={values?.description ? values.description : ""}
                rules={{
                  required: false,
                }}
                render={({ field }) => (
                  <TextField
                    {...field}
                    fullWidth
                    label="Description"
                    multiline
                    rows={4}
                    variant="outlined"
                    error={errors.description ? true : false}
                    helperText={`${
                      errors.description?.type === "required"
                        ? "Description is required"
                        : ""
                    }`}
                  />
                )}
              />
            </Grid2>
          </Grid2>
        </CardContent>
        <Box display="flex" justifyContent="flex-end" p={2}>
          <Button color="primary" variant="contained" type="submit">
            Save
          </Button>
        </Box>
        {values.list_id && (
          <Box display="flex" p={1}>
            <Button
              color="secondary"
              variant="contained"
              fullWidth
              onClick={() => deleteList(values.list_id)}
            >
              DELETE
            </Button>
          </Box>
        )}
      </Card>
    </form>
  );
};

ListDetails.propTypes = {
  className: PropTypes.string,
};

export default ListDetails;
