import {
  Box,
  Collapse,
  Checkbox,
  TextField,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { useEffect, useState, useRef } from "react";
import distributorsAPI from "../../../../api/distributors";
import { useStore } from "../../../../stores/StoreContext";
import { observer } from "mobx-react-lite";

export const DistributorsFilter = observer(
  ({ requestBody, setRequestBody, setFilteringArrays, filteringArrays }) => {
    const [distributorsData, setDistributorsData] = useState(null);
    const [isDistributorsExpanded, setIsDistributorsExpanded] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);
    const [visibleDistributorsCount, setVisibleDistributorsCount] =
      useState(20);
    const containerRef = useRef(null);
    const { reportBuilderDistributors, setReportBuilderDistributors } =
      useStore();

    useEffect(() => {
      if (reportBuilderDistributors.length > 0) {
        setDistributorsData(reportBuilderDistributors);
        setFilteringArrays({
          ...filteringArrays,
          dists: reportBuilderDistributors,
        });
        setLoading(false);
      } else {
        const fetchDistributors = async () => {
          setLoading(true);
          try {
            const data = await distributorsAPI.reportBuilderGetManufacturers();
            setDistributorsData(data.results);
            setFilteringArrays({
              ...filteringArrays,
              dists: data.results,
            });
            setReportBuilderDistributors(data.results);
          } catch (error) {
            console.error("Error fetching distributors:", error);
          }
          setLoading(false);
        };
        fetchDistributors();
      }
    }, []);

    // Get selected distributors from requestBody
    const selectedDistributors = requestBody.filters?.dimensions?.dist_id || [];

    const handleDistributorCheckboxChange = (distributorId) => {
      const newSelectedDistributors = selectedDistributors.includes(
        distributorId
      )
        ? selectedDistributors.filter((id) => id !== distributorId)
        : [...selectedDistributors, distributorId];

      setRequestBody((prev) => {
        if (newSelectedDistributors.length === 0) {
          const { dist_id, ...otherDimensions } =
            prev.filters?.dimensions || {};
          return {
            ...prev,
            filters: {
              ...prev.filters,
              dimensions: otherDimensions,
            },
          };
        }
        return {
          ...prev,
          filters: {
            ...prev.filters,
            dimensions: {
              ...prev.filters?.dimensions,
              dist_id: newSelectedDistributors,
            },
          },
        };
      });
    };

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value.toLowerCase());
      setVisibleDistributorsCount(20);
    };

    const handleDeselectAll = () => {
      setRequestBody((prev) => {
        const { dist_id, ...otherDimensions } = prev.filters?.dimensions || {};
        return {
          ...prev,
          filters: {
            ...prev.filters,
            dimensions: otherDimensions,
          },
        };
      });
    };

    const filteredDistributors = distributorsData
      ? distributorsData.filter((distributor) =>
          distributor.name.toLowerCase().includes(searchQuery)
        )
      : [];

    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop - clientHeight < 100) {
        if (visibleDistributorsCount < filteredDistributors.length) {
          setVisibleDistributorsCount((prev) =>
            Math.min(prev + 20, filteredDistributors.length)
          );
        }
      }
    };

    return (
      <Box sx={{ mt: 2 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              fullWidth
              onClick={() => setIsDistributorsExpanded(!isDistributorsExpanded)}
              sx={{
                bgcolor: "#1F449F",
                color: "white",
                justifyContent: "space-around",
                p: 1,
                "&:hover": {
                  bgcolor: "#1a3b8a",
                },
                borderRadius: 2,
                width: "75%",
                margin: "0 auto",
                display: "flex",
              }}
              endIcon={
                <ExpandMore
                  sx={{
                    transform: isDistributorsExpanded
                      ? "rotate(180deg)"
                      : "none",
                    transition: "transform 0.2s",
                  }}
                />
              }
            >
              <div />
              <Typography
                fontSize={16}
                fontWeight={"semibold"}
                sx={{ textAlign: "center" }}
              >
                Distributors
                <br />(
                {selectedDistributors.length === 0
                  ? "Show All"
                  : `Showing ${selectedDistributors.length}`}
                )
              </Typography>
            </Button>

            {selectedDistributors.length === 0 ? (
              <Typography fontSize={12} color="#999999" fontWeight={"semibold"}>
                Selecting 0 distributors will apply no distributor filters to
                your report.
              </Typography>
            ) : null}

            <Collapse in={isDistributorsExpanded} timeout="auto" unmountOnExit>
              <div
                ref={containerRef}
                onScroll={handleScroll}
                style={{
                  marginTop: 10,
                  padding: 20,
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    gap: 1,
                  }}
                >
                  <TextField
                    size="small"
                    placeholder="Search distributors..."
                    sx={{ flex: 1 }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <Button
                    size="small"
                    onClick={handleDeselectAll}
                    sx={{ color: "#1F449F", whiteSpace: "nowrap" }}
                  >
                    Deselect All
                  </Button>
                </Box>

                {filteredDistributors
                  .slice(0, visibleDistributorsCount)
                  .map((distributor) => (
                    <Box
                      key={distributor.id}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        mb: 1,
                        "&:hover": { bgcolor: "rgba(0, 0, 0, 0.04)" },
                      }}
                    >
                      <Checkbox
                        checked={selectedDistributors.includes(distributor.id)}
                        onChange={() =>
                          handleDistributorCheckboxChange(distributor.id)
                        }
                      />
                      <Typography>{distributor.name}</Typography>
                    </Box>
                  ))}
              </div>
            </Collapse>
          </>
        )}
      </Box>
    );
  }
);
