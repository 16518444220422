import React, { useEffect, useState } from "react";
import { Link as RouterLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { Observer } from "mobx-react-lite";
import {
  Avatar,
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  Button,
  ListItem,
} from "@mui/material";
import NavItem from "./NavItem";
import clsx from "clsx";
import {
  Home as HomeIcon,
  TableChart,
  AccountBalance,
  DateRange,
  FormatListBulleted,
  PermMedia,
  Face,
  Storage,
  Group,
  Public,
  AccountTree as DistributorsIcon,
  KeyboardArrowLeft,
  PermDeviceInformation as FormBuilder,
  Search,
  Settings,
  Bookmark,
  LocalShipping,
  Business,
  Map,
} from "@mui/icons-material";
import { useStore } from "../../../stores/StoreContext";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { makeStyles } from "@mui/styles";

const NavBar = ({ onMobileClose, openMobile, mini, setMini }) => {
  const store = useStore();
  const { manufacturer, userInfo } = store;

  const idn_items = [
    { href: "/app/home", icon: HomeIcon, title: "Home" },
    { href: "/app/reports", icon: TableChart, title: "Report Builder" },
    { href: "/app/lists", icon: Public, title: "Markets" },
    {
      href: "/app/distributors",
      icon: DistributorsIcon,
      title: "Contact & Brand Comparison",
    },
    {
      href: "/app/top-external-brands",
      icon: Search,
      title: "Top Ext. Brands",
    },
    { href: "/app/media", icon: PermMedia, title: "Files" },
    { href: "/app/forms", icon: FormBuilder, title: "Forms" },
    { href: "/app/settings", icon: Settings, title: "User Settings" },
    { href: "/app/finance", icon: AccountBalance, title: "Member Finance" },
    { href: "/app/profile/" + userInfo.uid, icon: Face, title: "User Profile" },
  ];

  const supplier_items = [
    { href: "/app/home", icon: HomeIcon, title: "Home" },
    { href: "/app/reports", icon: TableChart, title: "Reports" },
    { href: "/app/lists", icon: Public, title: "Markets" },
    { href: "/app/brand-finder", icon: Map, title: "Brand Finder" },
    { href: "/app/team", icon: Group, title: "Team" },
    { href: "/app/schedule", icon: DateRange, title: "Schedule" },
    { href: "/app/products", icon: FormatListBulleted, title: "Products" },
    {
      href: "/app/distributors",
      icon: DistributorsIcon,
      title: "Distributors",
    },
    { href: "/app/media", icon: PermMedia, title: "Marketing" },
    { href: "/app/log", icon: Storage, title: "Depletion Uploads" },
    { href: "/app/forms", icon: FormBuilder, title: "Forms" },
    { href: "/app/settings", icon: Settings, title: "Settings" },
    { href: "/app/profile/" + userInfo.uid, icon: Face, title: "Profile" },
  ];

  const useStyles = makeStyles((theme) => ({
    mobileDrawer: {
      width: 256,
    },
    mobileDrawerMini: {
      width: 56,
    },
    desktopDrawer: {
      width: 256,
      top: 64,
      height: "calc(100% - 64px)",
      transition: "width 0.3s",
    },
    // Instead of using "extends" in JSS, define additional styles directly.
    desktopDrawerMini: {
      width: 66,
    },
    avatar: {
      cursor: "pointer",
      width: 64,
      height: 64,
    },
    profWrapper: {
      height: "auto",
      transition: "all 0.3s",
    },
    profWrapperMini: {
      minHeight: 0,
      height: 0,
      overflow: "hidden",
    },
    item: {
      display: "flex",
      paddingTop: 0,
      paddingBottom: 0,
    },
    button: {
      color: "#596D79",
      fontWeight: theme.typography.fontWeightMedium,
      letterSpacing: 0,
      padding: "10px 8px",
      textTransform: "none",
      minWidth: 0,
      width: "100%",
      outline: "0px !important",
      transition: "color 0.3s",
      "&:hover": {
        color: "#1F449F",
      },
      "&.active": {
        color: "#1F449F",
        "& $title": {
          fontWeight: theme.typography.fontWeightMedium,
        },
        "& $icon": {
          color: "#1F449F",
          transition: "transform 0.3s",
        },
      },
    },

    title: {
      marginRight: "auto",
      marginLeft: theme.spacing(1),
    },
    miniTitle: {
      marginRight: "auto",
      marginLeft: theme.spacing(1),
      fontSize: "0.6em",
    },
    icon: {},
  }));

  const classes = useStyles();
  const location = useLocation();
  const [userImage, setUserImage] = useState(null);
  const [avatar, setAvatar] = useState("");
  const [loaded, setLoaded] = useState(false);
  const [navItems, setNavItems] = useState([]);

  useEffect(() => {
    let allowedScreens = userInfo.access?.screens;

    if (manufacturer.manufacturer_id === 39) {
      setNavItems(idn_items);
      if ([247, 275, 349, 400].includes(userInfo.user_id)) {
        setNavItems([
          ...idn_items,
          { href: "/app/log", icon: Storage, title: "Depletion Uploads" },
          { href: "/app/team", icon: Group, title: "Team" },
        ]);
      }
    } else {
      setNavItems(
        supplier_items.filter(
          (item) => !allowedScreens || allowedScreens.includes(item.title)
        )
      );
      if ([614, 629, 621].includes(manufacturer.manufacturer_id)) {
        // Insert Inventory item at the correct position
        supplier_items.splice(5, 0, {
          href: "/app/inventory",
          icon: LocalShipping,
          title: "Inventory",
        });
        setNavItems([...supplier_items]);
      }
      if ([247, 275, 349, 102, 167].includes(userInfo.user_id)) {
        setNavItems([...supplier_items]);
      }
    }

    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    setAvatar(userInfo.uimage);
    setLoaded(true);
  }, [location.pathname, userInfo.user_image]);

  useEffect(() => {
    setLoaded(false);
    setTimeout(() => {
      let _image = awsS3Image(
        userInfo.user_image,
        userInfo.user_identitykey,
        "64x64"
      );
      setUserImage(_image);
      setLoaded(true);
    }, 100);
  }, [userInfo.user_image]);

  const content = (
    <Observer>
      {() => (
        <Box height="100%" display="flex" flexDirection="column">
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            p={mini ? 0 : 2}
            className={
              mini
                ? `${classes.profWrapper} ${classes.profWrapperMini}`
                : classes.profWrapper
            }
          >
            {avatar === "gg_default_image.png" && (
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                to="/app/settings"
                src="/static/images/avatars/gg_default_image.png"
              />
            )}

            {loaded && avatar !== "gg_default_image.png" && (
              <Avatar
                className={classes.avatar}
                component={RouterLink}
                to="/app/settings"
                src={userImage}
              />
            )}

            <h3 className="heading-fieldset">{userInfo.uname}</h3>
            <Typography color="textSecondary" variant="body2">
              {userInfo.urole}
            </Typography>
          </Box>
          <Divider />
          <Box p={mini ? 0 : 2}>
            <List data-jank={store?.manufacturer?.state_codes?.join(",") || ""}>
              {navItems.map((item) => (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                  special={item.special ? item.special : ""}
                  mini={mini}
                  toggleMini={() => setMini(!mini)}
                />
              ))}
              <ListItem className={clsx(classes.item)} disableGutters>
                <Button
                  className={classes.button}
                  style={{
                    justifyContent: mini ? "space-around" : "flex-start",
                    outline: "none",
                  }}
                  onClick={() => setMini(!mini)}
                >
                  <KeyboardArrowLeft
                    className={classes.icon}
                    style={{
                      transform: `rotate(${mini ? 180 : 0}deg)`,
                    }}
                  />
                  {!mini && (
                    <span className={classes.miniTitle}>Thin Menu</span>
                  )}
                </Button>
              </ListItem>
            </List>
          </Box>
          <Box flexGrow={1} />
        </Box>
      )}
    </Observer>
  );

  return (
    <Observer>
      {() => (
        <>
          <Drawer
            sx={{ display: { xl: "none", xs: "block" } }}
            anchor="left"
            classes={{ paper: classes.mobileDrawer }}
            onClose={onMobileClose}
            open={openMobile}
            variant="temporary"
          >
            {content}
          </Drawer>
          <Drawer
            sx={{ display: { xs: "none", md: "block" } }}
            anchor="left"
            classes={{
              paper: mini
                ? clsx(classes.desktopDrawer, classes.desktopDrawerMini)
                : classes.desktopDrawer,
            }}
            open
            variant="persistent"
          >
            {content}
          </Drawer>
        </>
      )}
    </Observer>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool,
  mini: PropTypes.bool,
  setMini: PropTypes.func,
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false,
};

export default NavBar;
