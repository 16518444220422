import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Card,
  TableContainer,
  Button,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import statsAPI from "../../api/stats";
import TopProductAccounts from "../../views/reports/DataView/TopProductAccounts";
import { useStore } from "../../stores/StoreContext";
import { DateTime } from "luxon";
import useQueryParamsBuilder from "../megaFilter/useQueryParamsBuilder";
import { makeStyles } from "@mui/styles";
import { Avatar } from "@mui/material";
import { awsS3Image } from "../../helpers/awsS3Image";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";

const useStyles = makeStyles((theme) => ({
  loading: {
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: theme.spacing(4),
  },
  statbox: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
  selectableRow: {
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}));

// Utility function to format numbers
const formatNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2) + "k";
  }
  return num.toString();
};

const visibleColumnsAtEachSize = {
  productImage: 2,
};

const TopProducts = observer(({ reportLoaded, size, timeRange }) => {
  const classes = useStyles();
  const { colSpan } = size;
  const [accountHash, setAccountHash] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(false);
  const [tops, setTops] = useState([]);
  const { selectedDistributors, dateRange } = useStore();
  const { queryParams } = useQueryParamsBuilder();
  const [dateStart, dateEnd] = useMemo(() => {
    return dateRange.map((d) => DateTime.fromJSDate(d).toISODate());
  }, [dateRange]);
  const [showExportButton, setShowExportButton] = useState(false);

  const load = () => {
    setLoading(true);
    statsAPI
      .getTopProducts({
        ...queryParams,
        dists: selectedDistributors,
        timeRange: timeRange || "MTD",
        limit: 10000,
      })
      .then(({ results }) => {
        setTops(results);

        reportLoaded("top-products");
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (queryParams !== null) {
      load();
    }
  }, [queryParams]);

  useEffect(() => {
    load();
  }, [timeRange, selectedDistributors, queryParams]);

  const handleRowClick = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  const exportTable = () => {
    // Implementation of exportTable function
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        overscrollBehavior: "contain",
        p: 3,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          mb: 2,
          alignItems: "center",
        }}
      >
        {showExportButton && (
          <Button
            onClick={exportTable}
            variant="outlined"
            size="small"
            startIcon={<FileDownloadOutlinedIcon />}
          >
            EXPORT TABLE
          </Button>
        )}
      </Box>
      {loading && <LinearProgress />}
      <TableContainer
        component={Card}
        sx={{
          borderRadius: 2,
          overflow: "auto",
          width: "100%",
          flex: 1,
        }}
      >
        <Table size="small" sx={{ width: "100%", tableLayout: "fixed" }}>
          <TableHead sx={{ bgcolor: "#f5f5f5" }}>
            <TableRow>
              {colSpan >= visibleColumnsAtEachSize.productImage && (
                <TableCell
                  style={{ padding: "8px", width: "56px" }}
                ></TableCell>
              )}
              <TableCell style={{ padding: "8px", width: "60%" }}>
                Product Name
              </TableCell>
              <TableCell style={{ padding: "8px", width: "20%" }}>
                Physical Case
              </TableCell>
              <TableCell style={{ padding: "8px", width: "20%" }}>
                Case Eqv
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody
            sx={{
              overscrollBehavior: "contain",
            }}
          >
            {tops.map((product, index) => {
              const { prod_name, prod_id, cases_eqv, cases } = product;
              return (
                <TableRow
                  key={index}
                  className={classes.selectableRow}
                  onClick={() => handleRowClick(product)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                    cursor: "pointer",
                    py: 1,
                  }}
                >
                  {colSpan >= visibleColumnsAtEachSize.productImage && (
                    <TableCell style={{ padding: "8px" }}>
                      <Avatar
                        key={product.product_id}
                        alt={product.product_displayname}
                        src={awsS3Image(
                          product.product_image,
                          product.product_imagekey,
                          "40x40"
                        )}
                        sx={{
                          bgcolor: "#f6f6f6",
                          "& .MuiAvatar-fallback": {
                            display: "none",
                          },
                        }}
                      />
                    </TableCell>
                  )}
                  <TableCell style={{ padding: "8px" }}>{prod_name}</TableCell>
                  <TableCell style={{ padding: "8px" }}>
                    {formatNumber(cases)}
                  </TableCell>
                  <TableCell style={{ padding: "8px" }}>
                    {formatNumber(cases_eqv)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          Product Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedProduct && (
            <TopProductAccounts
              prod_id={selectedProduct.prod_id}
              accountHash={accountHash}
              setAccountHash={setAccountHash}
              dists={selectedDistributors}
              dateStart={dateStart}
              dateEnd={dateEnd}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
});

export default TopProducts;
