import React, { useEffect, useMemo } from "react";
import { Box, Button, Typography } from "@mui/material";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  useGridApiRef,
} from "@mui/x-data-grid-premium";
import columnDefinitions from "./columnDefinitions";
import {
  rollUpRows,
  getCellClassName,
  getRowClassName,
} from "../../../reports/ReportsView/helpers";
import PreviewTable from "./PreviewTable";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

// Custom toolbar component with density, and export
const CustomGridToolbar = ({ onExport, hash = "" }) => {
  return (
    <GridToolbarContainer>
      <GridToolbarDensitySelector />
      <Button
        color="primary"
        startIcon={<FileDownloadIcon />}
        onClick={onExport}
        size="small"
      >
        Export Excel
      </Button>
    </GridToolbarContainer>
  );
};

// Update this custom pagination component
function CustomPagination({
  onNextPage,
  onPreviousPage,
  loading,
  endReached,
  hasData,
  pageSize,
  totalRows,
  currentPage,
  safePages,
  dgPageSize,
}) {
  const totalPages = Math.ceil(totalRows / dgPageSize) || 1;

  // Handle clicking on a specific page number
  const handlePageClick = (page) => {
    // Calculate difference between current page and target page
    const diff = page - currentPage;
    if (diff !== 0) {
      // Use the existing onNextPage/onPreviousPage but with the calculated difference
      if (diff > 0) {
        onNextPage(diff);
      } else {
        onPreviousPage(Math.abs(diff));
      }
    }
  };

  const getPageNumbers = () => {
    const pages = [];
    // always show first page
    pages.push(1);
    if (totalPages <= 6) {
      // For small number of pages, show all pages
      for (let i = 2; i <= safePages; i++) {
        pages.push(i);
      }
    } else {
      // For many pages, show ellipses
      // If current page is close to beginning
      if (currentPage < 4) {
        pages.push(2, 3, 4, "...", safePages);
      }
      // If current page is close to end
      else if (currentPage > safePages - 3) {
        pages.push(
          "...",
          safePages - 3,
          safePages - 2,
          safePages - 1,
          safePages
        );
      }
      // If current page is in the middle
      else {
        pages.push(
          "...",
          currentPage - 1,
          currentPage,
          currentPage + 1,
          "...",
          safePages
        );
      }
    }

    return pages;
  };

  const positionString = useMemo(() => {
    let str = "Showing ";
    str += (currentPage - 1) * dgPageSize + 1;
    str += " - ";
    let end =
      currentPage * dgPageSize < totalRows
        ? currentPage * dgPageSize
        : totalRows;
    str += end;
    str += " of " + totalRows + " rows.";
    return str;
  }, [totalRows, currentPage, dgPageSize]);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "space-between",
        padding: 2,
        alignItems: "center",
        gap: 1,
        flexWrap: "wrap",
      }}
    >
      <Box sx={{ marginRight: 2 }}>
        <Typography variant="body2">{positionString}</Typography>
      </Box>

      <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
        <Button
          onClick={() => onPreviousPage(1)}
          disabled={loading || currentPage <= 1 || !hasData}
          sx={{ minWidth: "40px" }}
        >
          &lt;
        </Button>

        {getPageNumbers().map((page, index) =>
          page === "..." ? (
            <Typography key={`ellipsis-${index}`} sx={{ mx: 1 }}>
              ...
            </Typography>
          ) : (
            <Button
              key={page}
              onClick={() => handlePageClick(page)}
              variant={currentPage === page ? "contained" : "text"}
              sx={{
                minWidth: "40px",
                backgroundColor:
                  currentPage === page ? "primary.main" : "transparent",
                color: currentPage === page ? "white" : "inherit",
              }}
              disabled={loading}
            >
              {page}
            </Button>
          )
        )}

        <Button
          onClick={() => onNextPage(1)}
          disabled={loading || endReached || !hasData}
          sx={{ minWidth: "40px" }}
        >
          &gt;
        </Button>
      </Box>
      <Box>Total Pages: {totalPages}</Box>
    </Box>
  );
}

const ReportTable = ({
  data,
  loading,
  hideIdCols = true,
  previewColumns,
  totalRows,
  requestBody,
  onExport,
  onNextPage,
  onPreviousPage,
  endReached,
  currentPage,
  safePages,
  dgPageSize,
  generateColumns,
  hash,
}) => {
  const [visibleRows, setVisibleRows] = React.useState([]);
  const [totalRow, setTotalRow] = React.useState(null);
  const apiRef = useGridApiRef();

  // Calculate total pages
  const totalPages = Math.ceil(totalRows / dgPageSize) || 1;

  // Only allow collapsing if all data fits on a single page
  const allowCollapsing = totalPages < 2;

  // Determine if we are in preview mode (no data) or displaying actual rows
  const isPreviewMode = !loading && (!data || data.length === 0);

  // If actual data exists, generate columns/rows from data.
  const columns = useMemo(() => {
    return generateColumns({
      firstRow: data[0],
      requestBody,
      columnDefinitions,
    });
  }, [data, requestBody, generateColumns]);

  // Update visible rows whenever the base data changes or collapsed groups change
  useEffect(() => {
    if (isPreviewMode) {
      setVisibleRows([]);
      return;
    }

    const firstColumn = requestBody.select.dimensions[0];

    // Guard against data being null, undefined, or not an array
    const plainTotalRow = Array.isArray(data)
      ? data.find((row) => row?.id === "grandTotal")
      : null;

    // Only create the nicerTotalRow if plainTotalRow exists
    if (plainTotalRow) {
      const nicerTotalRow = {
        ...plainTotalRow,
        id: "grandTotal",
        [firstColumn]: "Grand Total",
      };
      setTotalRow([nicerTotalRow]);
    } else {
      setTotalRow(null);
    }

    // Only process rows if data is an array and has elements
    let processedRows = Array.isArray(data) ? rollUpRows(data) : [];

    setVisibleRows(processedRows);
  }, [data, isPreviewMode, allowCollapsing]);

  // useEffect to scroll to top when page changes
  useEffect(() => {
    if (apiRef.current && !isPreviewMode) {
      // Scroll to the top of the grid when page changes
      apiRef.current.scrollToIndexes({ rowIndex: 0, colIndex: 0 });
    }
  }, [currentPage, apiRef, isPreviewMode]);

  const hideColumns = {
    // hide groups
    // hide _id columns
    ...(hideIdCols
      ? Object.fromEntries(
          columns
            .filter(
              (c) => c.field.endsWith("_id") || ["id", "type"].includes(c.field)
            )
            .map((c) => [c.field, false])
        )
      : {}),
  };

  // Update the getCellClassName handler to combine with the original
  const combineCellClassNames = (params) => {
    // Get the original class name
    const originalClass = getCellClassName(params, requestBody);

    // Check if this is a diff column and add our class
    const field = params.field || "";
    if (
      (field.includes("Diff Pct") || field.includes("Diff Value")) &&
      typeof params.value === "number"
    ) {
      const diffClass =
        params.value > 0
          ? "positive-diff"
          : params.value < 0
          ? "negative-diff"
          : "";
      return originalClass ? `${originalClass} ${diffClass}` : diffClass;
    }

    return originalClass;
  };

  return (
    <Box
      sx={{
        height: "100%",
        width: "100%",
        backgroundColor: "#FFFFFF",
        padding: 4,
        borderRadius: 2,
      }}
    >
      {!data ||
      (Array.isArray(data) &&
        data.length > 0 &&
        data.every((item) => item === undefined)) ? (
        // No data returned from server
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "50vh",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography variant="h6">No data returned from the server</Typography>
          <Typography variant="body1">
            Please check your filters and try again.
          </Typography>
        </Box>
      ) : isPreviewMode ? (
        // Preview mode: render the header row only
        <PreviewTable
          selectedFields={previewColumns}
          requestBody={requestBody}
          disableColumnMenu
        />
      ) : (
        <>
          <Box sx={{ height: "76vh" }}>
            <DataGridPremium
              apiRef={apiRef}
              disableRowGrouping
              rows={visibleRows}
              columns={columns}
              loading={loading}
              pagination={false}
              disableColumnMenu={true}
              initialState={{
                columnVisibilityModel: hideColumns,
              }}
              disableColumnSorting={true}
              disableColumnReorder={true}
              disableColumnPinning={true}
              pinnedRows={{ top: totalRow || [] }}
              slots={{
                toolbar: (props) => (
                  <CustomGridToolbar
                    {...props}
                    onExport={onExport}
                    hash={hash}
                  />
                ),
                footer: () => (
                  <CustomPagination
                    onNextPage={onNextPage}
                    onPreviousPage={onPreviousPage}
                    loading={loading}
                    endReached={endReached}
                    hasData={data.length > 0}
                    pageSize={requestBody.pageSize}
                    totalRows={totalRows}
                    currentPage={currentPage}
                    safePages={safePages}
                    dgPageSize={dgPageSize}
                  />
                ),
              }}
              columnVisibilityModel={hideColumns}
              {...{
                getRowClassName: (params) => {
                  if (params.id !== "grandTotal") {
                    return getRowClassName(params, requestBody);
                  }
                },
                getCellClassName: combineCellClassNames,
              }}
              sx={{
                "& .positive-diff": {
                  color: "#04CE00 !important",
                  fontWeight: 700,
                },
                "& .negative-diff": {
                  color: "#ff4d4d !important",
                  fontWeight: 700,
                },
              }}
            />
            <Box sx={{ ml: "auto" }}>
              <Typography sx={{ fontSize: 14, color: "#999999", mt: 1 }}>
                Report Hash: {hash || ""}
              </Typography>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};

export default ReportTable;
