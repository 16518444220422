import { Box, Typography } from "@mui/material";
import { useEffect, useRef } from "react";
import Chart from "chart.js/auto";

const SalesTargetsBreakdown = ({
  data,
  target,
  timeRangeTitle = "",
  formatNumber,
  totalItems,
  showPagination = false,
}) => {
  const chartRef = useRef(null);
  const chartInstance = useRef(null);

  // Calculate the current total correctly based on the data format
  const current = data.reduce((acc, curr) => acc + Number(curr.Total || 0), 0);

  useEffect(() => {
    // Clean up previous chart instance if it exists
    if (chartInstance.current) {
      chartInstance.current.destroy();
    }

    // Only create chart if we have data and a canvas reference
    if (!chartRef.current || !data.length) return;

    // Create new chart
    const ctx = chartRef.current.getContext("2d");

    // Prepare data for Chart.js
    const chartData = {
      labels: data.map((item) => item.label),
      datasets: [
        {
          label: "On-Premise",
          data: data.map((item) => Number(item.OnPrem || 0)),
          backgroundColor: "#5B76E8",
          stack: "Stack 0",
        },
        {
          label: "Off-Premise",
          data: data.map((item) => Number(item.OffPrem || 0)),
          backgroundColor: "#81A0F4",
          stack: "Stack 0",
        },
      ],
    };

    // Calculate bar width based on number of bars
    const barThickness = 50; // Thicker bars for better visibility

    chartInstance.current = new Chart(ctx, {
      type: "bar",
      data: chartData,
      options: {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: false,
          },
          legend: {
            position: "top",
          },
          tooltip: {
            callbacks: {
              label: function (context) {
                let label = context.dataset.label || "";
                if (label) {
                  label += ": ";
                }
                label += formatNumber(context.parsed.y);
                return label;
              },
            },
          },
        },
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              maxRotation: 45, // Rotate labels for better fit
              minRotation: 45,
              font: {
                size: 11, // Slightly smaller font
              },
              padding: 10, // Add padding to prevent cut-off
              // Callback to truncate long labels
              callback: function (val, index) {
                const label = this.getLabelForValue(val);
                // Truncate long labels with ellipsis
                if (label.length > 20) {
                  return label.substring(0, 18) + "...";
                }
                return label;
              },
            },
          },
          y: {
            stacked: true,
            grid: {
              color: "#f0f0f0",
            },
            ticks: {
              callback: (value) => formatNumber(value),
            },
          },
        },
        layout: {
          padding: {
            bottom: 25, // Padding for labels
          },
        },
        barPercentage: 0.9,
        categoryPercentage: 0.9,
        barThickness: barThickness,
      },
    });

    return () => {
      if (chartInstance.current) {
        chartInstance.current.destroy();
      }
    };
  }, [data, target, timeRangeTitle]);

  // Show a message if there's no data
  if (!data || data.length === 0) {
    return (
      <Box
        sx={{
          height: 300,
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography variant="body1">
          No data available for the selected time period.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ height: 300, position: "relative" }}>
      <Typography sx={{ fontSize: 10 }}>
        Units are in Cases Equivalence
      </Typography>
      <canvas ref={chartRef} />
    </Box>
  );
};

export default SalesTargetsBreakdown;
