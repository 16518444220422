import React, { useState, useEffect } from "react";
import { DataGridPremium as DataGrid } from "@mui/x-data-grid-premium";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import Button from "@mui/material/Button";
import { Parser } from "json2csv";
import { Paper } from "@mui/material";
import teamAPI from "../../../api/team";
import ExcelJS from "exceljs";
import logo from "../../../components/logo.png";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "500px",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

function camelCaseToTitleCase(str) {
  // Check if the string contains spaces or is not camelCase (no capital letters)
  if (str.includes(" ") || !/[A-Z]/.test(str)) {
    return str; // Return the string as is if it doesn't need conversion
  }

  // Add a space before each uppercase letter and convert to title case
  const result = str
    .replace(/([A-Z])/g, " $1") // Insert space before uppercase letters
    .replace(/^./, (firstChar) => firstChar.toUpperCase()); // Capitalize the first letter

  return result;
}

const TeamScorecardView = ({ users }) => {
  const getCurrentYear = () => new Date().getFullYear();

  const generateMonths = () => {
    return [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
  };

  const getCurrentMonthString = () => {
    const currentDate = new Date();
    return currentDate.toLocaleString("default", { month: "long" });
  };

  function getPastYears() {
    const currentYear = new Date().getFullYear();
    const pastYears = [];

    for (let year = currentYear - 2; year <= currentYear; year++) {
      pastYears.push(year);
    }

    return pastYears;
  }

  /**
   * Helper to convert a "week number" (1-52 or 1-53) and a base year
   * to a month name, e.g., "January", "February", etc.
   * This is approximate and may need adjustment for edge cases.
   */
  function weekToMonth(week, year) {
    // Start from Jan 1
    const firstDayOfYear = new Date(year, 0, 1);

    // Simply add (week-1) * 7 days (no shift)
    const date = new Date(
      firstDayOfYear.getTime() + (week - 1) * 7 * 24 * 60 * 60 * 1000
    );

    // Convert to month name
    return date.toLocaleString("default", { month: "long" });
  }

  const years = getPastYears();
  const classes = useStyles();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState("");
  const [selectedMonth, setSelectedMonth] = useState(getCurrentMonthString());
  const [selectedYear, setSelectedYear] = useState(getCurrentYear());
  const [filteredData, setFilteredData] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [columns, setColumns] = useState([]);

  const months = generateMonths();

  const mergeObjects = (array) => {
    const merged = {};

    array.forEach((obj) => {
      const key = `${obj.userId}-${obj.month}`;
      if (!merged[key]) {
        merged[key] = { ...obj };
      } else {
        Object.keys(obj).forEach((field) => {
          if (typeof obj[field] === "number") {
            if (merged[key][field] === 0) {
              merged[key][field] = obj[field];
            }
          } else {
            merged[key][field] = obj[field];
          }
        });
      }
    });

    return Object.values(merged);
  };

  const handleExportCSV = () => {
    const exportData = (
      selectedRows.length
        ? filteredData.filter((row) => selectedRows.includes(row.id))
        : filteredData
    ).map((row) => {
      const { avatar, ...rest } = row;
      return rest;
    });
    const parser = new Parser();
    const csv = parser.parse(exportData);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "scorecard.csv";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  function reorderObjectFields(obj, order) {
    const orderedObj = {};
    for (const key of order) {
      if (obj.hasOwnProperty(key)) {
        orderedObj[key] = obj[key];
      }
    }
    for (const key in obj) {
      if (!orderedObj.hasOwnProperty(key)) {
        orderedObj[key] = obj[key];
      }
    }
    return orderedObj;
  }

  function reorderFieldsInArray(objectsArray, order) {
    return objectsArray.map((obj) => reorderObjectFields(obj, order));
  }

  const handleExportExcel = async () => {
    const exportData = (
      selectedRows.length
        ? filteredData.filter((row) => selectedRows.includes(row.id))
        : filteredData
    ).map((row) => {
      const { avatar, ...rest } = row;
      return rest;
    });

    // Create a new workbook and worksheet
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet("Data");

    // Define title row styles
    const titleRowStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "142F72" } },
      font: { color: { argb: "FFFFFF" }, bold: true },
    };

    // Define alternating row styles
    const firstColumnStyle = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "142F72" } },
      font: { color: { argb: "FFFFFF" }, bold: true },
    };
    const alternateRowStyle1 = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "CAD6FF" } },
      font: { color: { argb: "000000" } },
    };
    const alternateRowStyle2 = {
      fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } },
      font: { color: { argb: "000000" } },
    };

    // Add header row
    const headerRow = worksheet.addRow(Object.keys(exportData[0]));
    headerRow.eachCell((cell) => {
      cell.style = titleRowStyle;
    });

    // Add data rows
    exportData.forEach((data, rowIndex) => {
      const row = worksheet.addRow(Object.values(data));
      row.eachCell((cell, colIndex) => {
        if (colIndex === 1) {
          cell.style = firstColumnStyle;
        } else {
          cell.style =
            rowIndex % 2 === 0 ? alternateRowStyle1 : alternateRowStyle2;
        }
      });
    });

    const response = await fetch(logo);
    const blob = await response.blob();
    const reader = new FileReader();
    reader.onloadend = async () => {
      const base64data = reader.result.split(",")[1];
      const imageId = workbook.addImage({
        base64: base64data,
        extension: "png",
      });
      const lastRow = worksheet.lastRow.number;
      worksheet.addImage(imageId, {
        tl: { col: 0, row: lastRow + 2 },
        ext: { width: 169.54, height: 50 },
      });

      // Generate the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      a.href = url;
      a.download = "scorecard.xlsx";
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    };
    reader.readAsDataURL(blob);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await teamAPI.getScorecardData();
        console.log("Lambda REST API: ", data);

        if (data.results.length) {
          const reshapedData = data.results
            .map((entry) => {
              const parsedData = JSON.parse(entry.data);

              // Base object (minus year — we'll determine year from the week)
              const base = {
                id: entry.id,
                userId: entry.user_id,
                name: entry.user_displayname,
              };

              const monthlyData = {};
              Object.keys(parsedData).forEach((weekStr) => {
                const week = parseInt(weekStr, 10);

                // For now: if week > 30 => 2024, else => 2025
                const year = week >= 31 && week <= 52 ? 2024 : 2025;

                const month = weekToMonth(week, year);

                parsedData[weekStr].forEach((visit) => {
                  // Make a unique key per user-month-year-visitType
                  const key = `${entry.user_id}-${month}-${year}-${visit.visit_type}`;

                  if (!monthlyData[key]) {
                    monthlyData[key] = {
                      ...base,
                      id: key,
                      month,
                      year,
                      [visit.visit_type]: visit.visit_count,
                    };
                  } else {
                    if (monthlyData[key][visit.visit_type]) {
                      monthlyData[key][visit.visit_type] += Number(
                        visit.visit_count
                      );
                    } else {
                      monthlyData[key][visit.visit_type] = Number(
                        visit.visit_count
                      );
                    }
                  }
                });
              });

              return Object.values(monthlyData);
            })
            .flat();

          // Identify all visitType columns dynamically
          const visitTypes = [
            ...new Set(
              reshapedData.flatMap((row) =>
                Object.keys(row).filter(
                  (key) =>
                    ![
                      "id",
                      "userId",
                      "name",
                      "avatar",
                      "month",
                      "year",
                    ].includes(key)
                )
              )
            ),
          ];

          // Create the column definitions
          const dynamicColumns = [
            { field: "name", headerName: "Name", width: 150 },
            { field: "month", headerName: "Month", width: 150 },
            { field: "year", headerName: "Year", width: 100 },
            ...visitTypes.map((visitType) => ({
              field: visitType,
              headerName: camelCaseToTitleCase(
                visitType.replace("form_", "(Form) ")
              ),
              width: 150,
              valueFormatter: (value, row) => Number(value).toFixed(2),
            })),
          ];

          // Fill missing visitType fields with 0
          const completeData = reshapedData.map((row) => {
            visitTypes.forEach((visitType) => {
              if (!(visitType in row)) {
                row[visitType] = 0;
              }
            });
            return row;
          });

          // Reorder fields: id, userId, name, month, <visitTypes>...
          const order = [
            "id",
            "userId",
            "name",
            "month",
            "year",
            ...visitTypes,
          ];
          const orderedObjects = reorderFieldsInArray(completeData, order);

          // Merge any duplicates (if needed)
          const mergedObjects = mergeObjects(orderedObjects);

          setColumns(dynamicColumns);
          setData(mergedObjects);
        }
      } catch (err) {
        console.log("error fetching data..", err);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const filtered = data.filter(
      (item) =>
        (!selectedUser || item.userId === selectedUser) &&
        (!selectedMonth || item.month === selectedMonth) &&
        (!selectedYear || item.year === selectedYear)
    );
    setFilteredData(filtered);
  }, [selectedUser, selectedMonth, selectedYear, data]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Paper className={classes.root} title="Scorecard">
      <Box sx={{ height: 600, width: "100%" }}>
        {/* USER SELECT */}
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>User</InputLabel>
          <Select
            value={selectedUser}
            onChange={(e) => setSelectedUser(e.target.value)}
            label="User"
          >
            <MenuItem value="">
              <em>None</em>
            </MenuItem>
            {users.map((user) => (
              <MenuItem key={user.user_id} value={user.user_id}>
                {user.user_displayname}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* YEAR SELECT */}
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Year</InputLabel>
          <Select
            value={selectedYear}
            onChange={(e) => setSelectedYear(e.target.value)}
            label="Year"
          >
            {years.map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* MONTH SELECT */}
        <FormControl variant="outlined" sx={{ m: 1, minWidth: 120 }}>
          <InputLabel>Month</InputLabel>
          <Select
            value={selectedMonth}
            onChange={(e) => setSelectedMonth(e.target.value)}
            label="Month"
          >
            {months.map((month) => (
              <MenuItem key={month} value={month}>
                {month}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {/* EXPORT BUTTONS */}
        <Button
          variant="contained"
          color="primary"
          onClick={handleExportCSV}
          sx={{ m: 1 }}
        >
          Export CSV
        </Button>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleExportExcel}
          sx={{ m: 1 }}
        >
          Export Excel
        </Button>

        {/* DATA GRID */}
        <DataGrid
          rows={filteredData}
          columns={columns}
          pageSize={10}
          checkboxSelection
          onSelectionModelChange={(ids) => setSelectedRows(ids)}
        />
      </Box>
    </Paper>
  );
};

export default TeamScorecardView;
