import React, { useEffect } from "react";
import { Container, Grid2 } from "@mui/material";
import Page from "../../../components/Page";
import Profile from "./Profile";
import ProfileDetails from "./ProfileDetails";

import { useStore } from "../../../stores/StoreContext";
import { trackEvent } from "../../../api/analytics";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const SettingsView = () => {
  const classes = useStyles();
  const [user, setUser] = React.useState({});
  const store = useStore();
  const { userInfo, setUserInfo } = store;

  useEffect(() => {
    let _user = userInfo;
    _user.image_scr = awsS3Image(
      _user.user_image,
      _user.user_identitykey,
      "0x100"
    );
    setUser(_user);

    trackEvent(userInfo.uid, "PageView:Settings");
  }, []);

  return (
    <Page className={classes.root} title="Settings">
      {user.uid && (
        <Container maxWidth="lg">
          <Grid2 container spacing={3}>
            <Grid2 size={4}>
              <Profile
                userInfo={userInfo}
                profile={user}
                setUser={setUser}
                setUserInfo={setUserInfo}
              />
            </Grid2>
            <Grid2 size={8}>
              <ProfileDetails
                profile={user}
                setUser={setUser}
                setUserInfo={setUserInfo}
                userInfo={userInfo}
              />
            </Grid2>
          </Grid2>
        </Container>
      )}
    </Page>
  );
};

export default SettingsView;
