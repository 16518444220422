import { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../../../stores/StoreContext";
import StLabel from "./StLabel";
import { makeStyles } from "@mui/styles";

const StDynamicOptions = observer(
  ({
    label,
    required,
    error,
    helperText,
    answer,
    variant,
    changeAnswer = () => {},
  }) => {
    const [val, setVal] = useState([]);
    const [options, setOptions] = useState([]);

    const { distributorsList, productsList } = useStore();

    const useStyles = makeStyles(() => ({
      select: {
        minWidth: "14rem",
      },
    }));

    useEffect(() => {
      // answers should be an array
      // if the answer.value is not an array, but is truthy - not an empty string - insert it into one and
      // set that as the value
      if (answer.value !== val) {
        if (Array.isArray(answer.value)) {
          setVal(answer.value);
        } else {
          if (answer.value) {
            setVal([answer.value]);
          } else {
            // wipe on falsy
            setVal([]);
          }
        }
      }
    }, [answer]);

    const classes = useStyles();
    const setupDistributors = () => {
      switch (variant) {
        case "distributors":
          setOptions(
            distributorsList.map((r) => ({
              value: r.dist_id,
              text: r.dist_displayname,
            }))
          );
          break;
        case "products":
          setOptions(
            productsList.map((r) => ({
              value: r.product_id,
              text: r.product_displayname + " " + r.product_size,
            }))
          );
          break;
        case "brands":
          const uniSet = [
            ...new Set(productsList.map((r) => r.product_brand)),
          ].sort();
          setOptions(
            uniSet.map((pl) => ({
              value: pl,
              text: pl,
            }))
          );
          break;
        default:
          setOptions([]);
      }
    };

    useEffect(() => {
      setupDistributors();
    }, [variant, distributorsList, productsList]);

    const selected = (value) => {
      const matchingLabel = options.filter((o) => value.includes(o.value));
      const newVal = matchingLabel.map((ml) => ml.value);
      const newTextVal = matchingLabel.map((ml) => ml.text).join(", ");
      changeAnswer(newVal, newTextVal);
      setVal(value);
    };
    useEffect(() => {
      const matchingOption = options.find((o) => o.value === answer.value);
      if (matchingOption && val !== matchingOption.value) {
        setVal(matchingOption.value);
      }
    }, [answer]);

    const [active, setActive] = useState(false);

    return (
      <FormControl fullWidth error={error} className={classes.select}>
        <StLabel
          required={required}
          text={label}
          error={error}
          active={active}
        />
        <Select
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
          value={val}
          multiple
          {...{
            required,
          }}
          onChange={(e) => selected(e.target.value)}
          {...{
            error,
          }}
          MenuProps={{ disableScrollLock: true }}
          // className={classes.select}
        >
          {options.map((o, ind) => (
            <MenuItem key={ind} value={o.value}>
              {o.text}
            </MenuItem>
          ))}
        </Select>
        {helperText && <FormHelperText>{helperText}</FormHelperText>}
      </FormControl>
    );
  }
);

export default StDynamicOptions;
