// ProductList.jsx

import React, { useState, useEffect, useMemo } from "react";
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  TextField,
  FormControlLabel,
  InputAdornment,
  SvgIcon,
  CircularProgress,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Avatar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Switch,
  Pagination,
  AvatarGroup,
} from "@mui/material";
import { DefaultAccountBottleById } from "../../../views/product/DefaultBottleSVG/getDefaultProductSVG";
import productsAPI from "../../../api/products";
import useApi from "../../../hooks/useApi";
import { useStore } from "../../../stores/StoreContext";
import {
  Search as SearchIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import Page from "../../../components/Page";

import { useNavigate } from "react-router-dom";
import { awsS3Image } from "../../../helpers/awsS3Image";

const ProductList = () => {
  const navigate = useNavigate();

  // State variables
  const [products, setProducts] = useState([]); // Original products
  const [groupedProducts, setGroupedProducts] = useState([]); // Grouped by brand
  const [searchTerm, setSearchTerm] = useState(""); // Search input
  const [currentPage, setCurrentPage] = useState(1); // Current pagination page
  const itemsPerPage = 20; // Brands per page
  const [loading, setLoading] = useState(false); // Loading state
  const [error, setError] = useState(null); // Error state

  // States for Activate/Deactivate functionality
  const [dialogOpen, setDialogOpen] = useState(false); // Controls dialog visibility
  const [selectedItem, setSelectedItem] = useState(null); // Stores the selected brand or product
  const [actionType, setActionType] = useState("Deactivate"); // "Activate" or "Deactivate"
  const [processing, setProcessing] = useState(false); // Indicates if API call is in progress
  const [showDeactivated, setShowDeactivated] = useState(false);

  const getProductsApi = useApi(productsAPI.getProducts);
  const store = useStore();
  const { userInfo } = store;
  // Fetch products on component mount
  useEffect(() => {
    const getProducts = async () => {
      setLoading(true);
      setError(null);
      try {
        const data = await getProductsApi.request({
          mid: userInfo.mid,
          limit: 1000,
        });
        // Ensure each product has an 'is_deactivated' property
        const productsWithStatus = data.results.map((product) => ({
          ...product,
          is_deactivated: product.is_deactivated || 0, // Default to 0 (active) if undefined
        }));
        setProducts(productsWithStatus);
      } catch (err) {
        setError("Failed to fetch products.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    getProducts();
  }, []);

  // Group products by brand whenever products or searchTerm changes
  useEffect(() => {
    const groupByBrand = () => {
      const filteredProducts = products.filter(
        (product) => showDeactivated || product.is_deactivated === 0
      );

      const grouped = filteredProducts.reduce((acc, product) => {
        const brand = product.product_brand || "Unknown Brand";
        if (!acc[brand]) {
          acc[brand] = [];
        }

        if (brand.toLowerCase().includes(searchTerm.toLowerCase())) {
          acc[brand].push(product);
        } else if (
          product.product_displayname
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
        ) {
          acc[brand].push(product);
        }

        return acc;
      }, {});

      const filtered = Object.entries(grouped).filter(
        ([brand, products]) => products.length > 0
      );

      return filtered;
    };

    const grouped = groupByBrand();
    setGroupedProducts(grouped);
    setCurrentPage(1);
  }, [products, searchTerm, showDeactivated]);

  const handleToggleDeactivated = () => {
    setShowDeactivated((prev) => !prev);
  };

  // Calculate total pages
  const totalPages = Math.ceil(groupedProducts.length / itemsPerPage);

  // Get current page's brands
  const currentBrands = useMemo(() => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return groupedProducts.slice(startIndex, startIndex + itemsPerPage);
  }, [groupedProducts, currentPage]);

  // Handle search input change
  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  // Handle page change
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  // Handle product click to navigate to edit page
  const handleProductClick = (productId) => {
    navigate(`edit/${productId}`);
  };

  // Handle Activate/Deactivate Button Click
  const handleToggleClick = (type, data) => (event) => {
    event.stopPropagation(); // Prevent accordion toggle
    setSelectedItem({ type, data });
    setActionType(data.is_deactivated === 1 ? "Activate" : "Deactivate");
    setDialogOpen(true);
  };

  // Handle Dialog Close
  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedItem(null);
  };
  const postProductsBrandDeactivate = useApi(
    productsAPI.postProductsBrandDeactivate
  );
  const postProductsDeactivate = useApi(productsAPI.postProductsDeactivate);
  // Handle Confirm Toggle
  const handleConfirmToggle = async () => {
    if (!selectedItem) return;

    setProcessing(true);
    try {
      if (selectedItem.type === "brand") {
        // Toggle Brand Activation
        const response = await postProductsBrandDeactivate.request({
          brand: selectedItem.data.product_brand,
          is_deactivated: actionType === "Deactivate",
        });

        if (response.status === 200) {
          // Update 'is_deactivated' status for all products under the brand
          const updatedProducts = products.map((prod) => {
            if (prod.product_brand === selectedItem.data.product_brand) {
              return {
                ...prod,
                is_deactivated: actionType === "Deactivate" ? 1 : 0,
              };
            }
            return prod;
          });
          setProducts(updatedProducts);
          console.log(
            `Brand "${
              selectedItem.data.product_brand
            }" has been ${actionType.toLowerCase()}d successfully.`
          );
        } else {
          console.error("Failed to toggle brand activation:", response);
        }
      } else if (selectedItem.type === "product") {
        // Toggle Product Activation
        const response = await postProductsDeactivate.request({
          product_id: selectedItem.data.product_id,
          is_deactivated: actionType === "Deactivate",
        });
        if (response.status === 200) {
          // Update 'is_deactivated' status for the specific product
          const updatedProducts = products.map((prod) => {
            if (prod.product_id === selectedItem.data.product_id) {
              return {
                ...prod,
                is_deactivated: actionType === "Deactivate" ? 1 : 0,
              };
            }
            return prod;
          });
          setProducts(updatedProducts);
          console.log(
            `Product "${
              selectedItem.data.product_displayname
            }" has been ${actionType.toLowerCase()}d successfully.`
          );
        } else {
          console.error("Failed to toggle product activation:", response);
        }
      }
    } catch (error) {
      console.error(`Error toggling ${selectedItem.type} activation:`, error);
      // Optionally, display an error message to the user here
    } finally {
      setProcessing(false);
      setDialogOpen(false);
      setSelectedItem(null);
    }
  };

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box display="flex" justifyContent="center" mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Page title="Products">
      <Container
        maxWidth="md"
        style={{ marginTop: "32px", marginBottom: "32px" }}
      >
        {/* Search and Add Product */}
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Card style={{ width: "60%" }}>
            <CardContent>
              <TextField
                fullWidth
                variant="outlined"
                placeholder="Search by brand or product"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon>
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  ),
                }}
              />
            </CardContent>
          </Card>

          <FormControlLabel
            control={
              <Switch
                checked={showDeactivated}
                onChange={handleToggleDeactivated}
                color="primary"
              />
            }
            label="Show Deactivated"
          />
        </Box>

        {/* Product Table */}
        <Box mt={4}>
          {currentBrands.length === 0 ? (
            <Typography>No products found.</Typography>
          ) : (
            currentBrands.map(([brand, brandProducts]) => (
              <Accordion
                key={brand}
                style={{
                  marginBottom: "16px",
                  backgroundColor: brandProducts.every(
                    (prod) => prod.is_deactivated
                  )
                    ? "#f0f0f0"
                    : "#ffffff",
                  opacity: brandProducts.every((prod) => prod.is_deactivated)
                    ? 0.6
                    : 1,
                }}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${brand}-content`}
                  id={`${brand}-header`}
                >
                  <Box display="flex" alignItems="center" width="100%">
                    {/* Brand Name */}
                    <Typography variant="h6" style={{ flex: 1 }}>
                      {brand}
                    </Typography>

                    {/* Product Avatars */}
                    <AvatarGroup max={3} style={{ marginRight: "16px" }}>
                      {brandProducts.map((product) =>
                        product.product_imagekey ? (
                          <Avatar
                            key={product.product_id}
                            alt={product.product_displayname}
                            src={awsS3Image(
                              product.product_image,
                              product.product_imagekey,
                              "40x40"
                            )}
                            style={{ background: "#f6f6f6" }}
                          />
                        ) : (
                          <DefaultAccountBottleById
                            key={product.product_id}
                            product_id={product.product_id}
                            width={40}
                            height={40}
                          />
                        )
                      )}
                    </AvatarGroup>

                    {/* Activate/Deactivate Brand Button */}
                    <Button
                      variant="contained"
                      color={
                        brandProducts.every((prod) => prod.is_deactivated)
                          ? "primary"
                          : "secondary"
                      }
                      size="small"
                      onClick={handleToggleClick("brand", {
                        product_brand: brand,
                        is_deactivated: brandProducts.every(
                          (prod) => prod.is_deactivated === 1
                        )
                          ? 1
                          : 0,
                      })}
                      style={{ marginLeft: "auto" }}
                    >
                      {brandProducts.every((prod) => prod.is_deactivated === 1)
                        ? "Activate"
                        : "Deactivate"}
                    </Button>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box width="100%">
                    {brandProducts.map((product) => (
                      <Box
                        key={product.product_id}
                        display="flex"
                        justifyContent="space-between"
                        alignItems="center"
                        style={{
                          padding: "8px 16px",
                          borderBottom: "1px solid #e0e0e0",
                          cursor: "pointer",
                          backgroundColor:
                            product.is_deactivated === 1
                              ? "#f0f0f0"
                              : "#ffffff",
                          opacity: product.is_deactivated === 1 ? 0.6 : 1,
                        }}
                        onClick={() => handleProductClick(product.product_id)}
                      >
                        {/* Product Info */}
                        <Box display="flex" alignItems="center">
                          {product.product_imagekey ? (
                            <Avatar
                              alt={product.product_displayname}
                              src={awsS3Image(
                                product.product_image,
                                product.product_imagekey,
                                "40x40"
                              )}
                              style={{
                                background: "#f6f6f6",
                                marginRight: "16px",
                              }}
                            />
                          ) : (
                            <DefaultAccountBottleById
                              product_id={product.product_id}
                              width={40}
                              height={40}
                            />
                          )}
                          <Box>
                            <Typography variant="subtitle1">
                              {product.product_displayname}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                              {product.product_display_category} |{" "}
                              {product.product_size}
                            </Typography>
                          </Box>
                        </Box>

                        {/* Activate/Deactivate Product Button */}
                        <Button
                          variant="contained"
                          color={
                            product.is_deactivated ? "primary" : "secondary"
                          }
                          size="small"
                          onClick={(event) =>
                            handleToggleClick("product", {
                              product_id: product.product_id,
                              product_displayname: product.product_displayname,
                              is_deactivated: product.is_deactivated,
                            })(event)
                          }
                        >
                          {product.is_deactivated ? "Activate" : "Deactivate"}
                        </Button>
                      </Box>
                    ))}
                  </Box>
                </AccordionDetails>
              </Accordion>
            ))
          )}
        </Box>

        {/* Pagination */}
        {totalPages > 1 && (
          <Box display="flex" justifyContent="center" mt={4}>
            <Pagination
              count={totalPages}
              page={currentPage}
              onChange={handlePageChange}
              color="primary"
            />
          </Box>
        )}

        {/* Confirmation Dialog */}
        <Dialog
          open={dialogOpen}
          onClose={handleDialogClose}
          aria-labelledby="confirmation-dialog-title"
          aria-describedby="confirmation-dialog-description"
        >
          <DialogTitle id="confirmation-dialog-title">
            {actionType} {selectedItem?.type === "brand" ? "Brand" : "Product"}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="confirmation-dialog-description">
              {selectedItem?.type === "brand"
                ? `Are you sure you want to ${actionType.toLowerCase()} the brand "${
                    selectedItem.data.product_brand
                  }"? This will ${actionType.toLowerCase()} all associated products. This only hides some Filters and Form Options.`
                : selectedItem
                ? `Are you sure you want to ${actionType.toLowerCase()} the product "${
                    selectedItem.data.product_displayname
                  }"? This only hides some Filters and Form Options.`
                : ""}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={handleDialogClose}
              color="primary"
              disabled={processing}
            >
              Cancel
            </Button>
            <Button
              onClick={handleConfirmToggle}
              color={actionType === "Deactivate" ? "secondary" : "primary"}
              variant="contained"
              autoFocus
              disabled={processing}
            >
              {actionType}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ProductList;
