import React, { useState } from "react";
import { Box, Card, Dialog } from "@mui/material";
import { Move } from "react-feather";
import useStorage from "../../hooks/useStorage.js";
import StorageClient from "../../storage/client";
import { awsS3Image } from "../../helpers/awsS3Image";
import marketingAPI from "../../api/marketing.js";
import DeleteModal from "../deleteModal/DeleteModal";
import LibraryTableRowFolder from "./LibraryTableRowFolder.js";
import LibraryTableRowFile from "./LibraryTableRowFile.js";
import { DndContext } from "@dnd-kit/core";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";

const MediaLibraryTableView = ({
  selectionMode,
  selectedItems,
  handleSelectItem,
  handleDownload,
  handleDeleteConfirm,
  handleEditFileName,
  handleCard,
  currentFiles,
  currentFolders,
  folders,
  setFolders,
  files,
  setFiles,
}) => {
  const [isEditing, setIsEditing] = useState(null);
  const [newName, setNewName] = useState("");
  const [storing, setStoring] = useState(false);
  const s3Storage = useStorage(StorageClient.download);
  const [viewOriginalFile, setViewOriginalFile] = useState(null);
  const [viewOriginalOpen, setViewOriginalOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [fileToDelete, setFileToDelete] = useState(null);
  const toggleSelection = (file) => {
    handleSelectItem(file);
  };

  const pseudoRowStyle = {
    icon: {
      // flex: "1 0 30px",
    },
    name: {
      // flex: 1,
      flex: "1 1 30vw",
    },
    date: {
      flex: "1 1 10em",
    },
    actions: {
      flex: "1 1 10em",
    },
    dragging: {
      background: "#fff",
      borderRadius: ".5rem",
      border: "2px solid #33c",
    },
  };

  const fileToMediaType = (file) => {
    const fileEnd = file.file_name.split(".").reverse()[0];
    const images = ["jpeg", "jpg", "png"];
    const videos = ["mp4", "m4v"];
    const pdfs = ["pdf"];
    let _mediaType = "generic";
    if (videos.includes(fileEnd)) _mediaType = "video";
    if (images.includes(fileEnd)) _mediaType = "image";
    if (pdfs.includes(fileEnd)) _mediaType = "pdf";
    return _mediaType;
  };

  const handleFullView = async (f) => {
    const mediaType = fileToMediaType(f);
    if (mediaType === "pdf") {
      let windowReference = window.open();
      s3Storage
        .getSignedUrl(f.file_key, f.identity_id, false)
        .then(function (url) {
          windowReference.location = url;
        });
    } else if (mediaType === "image" || mediaType === "video") {
      setViewOriginalOpen(true);
      setViewOriginalFile(f);
    }
  };

  const startEditing = (file) => {
    setIsEditing(file.file_id);
    setNewName(file.nickname || file.file_name);
  };

  const saveEditedName = (file) => {
    handleEditFileName(file, newName);
    setIsEditing(null);
  };

  const isImageFile = (fileName) => {
    if (!fileName) return false;
    const ext = fileName.split(".").pop().toLowerCase();
    return ["jpg", "jpeg", "png", "gif"].includes(ext);
  };

  const getFileType = (item) => {
    if (item.isFolder) {
      return "Folder";
    } else if (isImageFile(item.file_name)) {
      return "Image";
    } else {
      return "File";
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleDateString();
  };

  const handleRowClick = (file) => {
    if (selectionMode) {
      toggleSelection(file);
    } else {
      handleCard(file);
    }
  };

  const handleOpenDeleteModal = (file) => {
    setFileToDelete(file);
    setDeleteModalOpen(true);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalOpen(false);
    setFileToDelete(null);
  };

  const handleConfirmDelete = () => {
    if (fileToDelete) {
      handleDeleteConfirm(fileToDelete);
      handleCloseDeleteModal();
    }
  };

  const moo = ({ active, over }) => {
    console.log(active, over);
    if (!over) return;
    const targetFolder = over?.data?.current;
    if (active?.data?.current.type === "folder") {
      const parent_folder_id = targetFolder.folder_id;
      const { folder_id } = active.data.current;
      console.log(
        "would move the folder, ",
        folder_id,
        "into",
        parent_folder_id
      );
      if (folder_id === parent_folder_id) return;
      setStoring(true);
      marketingAPI
        .patchFolder({
          folder_id,
          patch: {
            parent_folder_id,
          },
        })
        .then(() => {
          console.log(folders, folder_id, parent_folder_id);
          setFolders(
            folders.map((f) => {
              if (f.folder_id === folder_id) {
                return {
                  ...f,
                  parent_folder_id,
                };
              }
              return f;
            })
          );
        })
        .catch((error) => console.error(error))
        .finally(() => setStoring(false));
    }
    if (active?.data?.current?.type === "file") {
      setStoring(true);
      const { folder_id } = targetFolder;
      const { file_id } = active.data.current;
      console.log("would move the file, ", file_id, "into", folder_id);
      marketingAPI
        .patchFile({
          file_id,
          patch: {
            folder_id,
          },
        })
        .then(() => {
          setFiles(
            files.map((f) => {
              if (f.file_id === file_id) {
                return {
                  ...f,
                  folder_id,
                };
              }
              return f;
            })
          );
        })
        .catch((error) => console.error(error))
        .finally(() => setStoring(false));
    }
  };

  return (
    <div>
      <Card style={{ padding: "20px", backgroundColor: "white" }}>
        <Box>
          <Box display={"flex"} justifyContent={"flex-start"}>
            {selectionMode && <Box>Select</Box>}
            <Box p={1} style={pseudoRowStyle.icon}>
              Icon
            </Box>
            <Box style={pseudoRowStyle.name} p={1}>
              Name
            </Box>
            <Box style={pseudoRowStyle.date} p={1}>
              Date Added
            </Box>
            <Box p={1}>
              <Move color="#fff" />
            </Box>
          </Box>
          <Box position={"relative"}>
            <DndContext onDragEnd={moo} modifiers={[restrictToVerticalAxis]}>
              {currentFolders
                .sort((a, b) => a.name.localeCompare(b.name))
                .map((folder) => (
                  <LibraryTableRowFolder
                    key={folder.folder_id}
                    folder={folder}
                    rowFunctions={{
                      formatDate,
                      handleRowClick,
                    }}
                    prStyle={pseudoRowStyle}
                    storing={storing}
                  />
                ))}
              {currentFiles
                .sort((a, b) => a.file_name.localeCompare(b.file_name))
                .map((file) => (
                  <LibraryTableRowFile
                    key={file.file_id}
                    rowFunctions={{
                      toggleSelection,
                      isImageFile,
                      handleFullView,
                      setNewName,
                      saveEditedName,
                      setIsEditing,
                      startEditing,
                      getFileType,
                      formatDate,
                      handleDownload,
                      handleOpenDeleteModal,
                      handleCard,
                      awsS3Image,
                    }}
                    prStyle={pseudoRowStyle}
                    {...{
                      file,
                      selectionMode,
                      selectedItems,
                      isEditing,
                      newName,
                      storing,
                    }}
                  />
                ))}
            </DndContext>
          </Box>
        </Box>
      </Card>

      {viewOriginalOpen && (
        <Dialog
          onClose={() => setViewOriginalOpen(false)}
          open={viewOriginalOpen}
          PaperProps={{ style: { width: "90%", padding: 10 } }}
        >
          <img
            alt="noimage"
            src={awsS3Image(
              viewOriginalFile?.file_key || "",
              viewOriginalFile?.identity_id || "",
              "0x0"
            )}
            style={{ objectFit: "cover" }}
          />
        </Dialog>
      )}

      <DeleteModal
        title={`Delete ${fileToDelete?.nickname || fileToDelete?.file_name}?`}
        description="Are you sure you want to delete this file?"
        open={deleteModalOpen}
        onClose={handleCloseDeleteModal}
        handleConfirmDelete={handleConfirmDelete}
      />
    </div>
  );
};

export default MediaLibraryTableView;
