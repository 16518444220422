import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Divider,
  Typography,
  TextField,
  Button,
  IconButton,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { useStore } from "../../stores/StoreContext";
import usersAPI from "../../api/users";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import { Star, Delete, Unarchive, StarOutline } from "@mui/icons-material";
import { DateTime } from "luxon";
import reportsAPI from "../../api/reports";

const Editor = ({ report, updateHistory }) => {
  const [title, setTitle] = useState(report.title);
  const [comment, setComment] = useState(report.comment);
  const [archived, setArchived] = useState(false);

  const reset = () => {
    setComment(report.comment);
    setTitle(report.title);
  };

  const deleteReport = () => {
    reportsAPI
      .updateReportHistory({
        ...report,
        archived: 1,
      })
      .then(() => setArchived(true));
  };
  const unDeleteReport = () => {
    reportsAPI
      .updateReportHistory({
        ...report,
        archived: 0,
      })
      .then(() => setArchived(false));
  };

  return (
    <Box p={2}>
      <Box>
        <Box display="flex" justifyContent={"space-between"}>
          <Typography variant="h6">
            Report Saved at {new Date(report.created_at).toLocaleString()}
          </Typography>
          {!archived && (
            <Button onClick={deleteReport} startIcon={<Delete />}>
              Delete
            </Button>
          )}
          {archived && (
            <Button onClick={unDeleteReport} startIcon={<Unarchive />}>
              Restore
            </Button>
          )}
        </Box>
        <Divider sx={{ mb: 2 }} />
        <Box pb={2}>
          <Typography variant="body2">
            You can give the report a title for convenience, and add comments.
          </Typography>
        </Box>
        <Divider sx={{ mb: 2 }} />
        <TextField
          value={title}
          onChange={(event) => setTitle(event.target.value)}
          label="Title"
          fullWidth
          sx={{ mb: 1 }}
        />
        <TextField
          value={comment}
          onChange={(event) => setComment(event.target.value)}
          label="Comment"
          fullWidth
          sx={{ mb: 1 }}
          multiline
          rows={3}
        />
      </Box>
      <Divider sx={{ my: 2 }} />
      <Box display={"flex"} justifyContent={"space-between"}>
        <Button onClick={reset}>Reset</Button>
        <Button
          onClick={() =>
            updateHistory({
              ...report,
              comment,
              title,
            })
          }
          variant={"contained"}
        >
          Update
        </Button>
      </Box>
    </Box>
  );
};

const ReportsHistoryLarge = observer(
  ({ reports, updateHistory, fave, unfave }) => {
    const { userList, setUserList, userInfo } = useStore();
    const columns = useMemo(() => {
      return [
        {
          field: "fave",
          headerName: "Fave",
          renderCell: (params) => (
            <IconButton
              onClick={() => {
                if (params.value === null) {
                  fave(params.row.hash);
                } else {
                  unfave(params.row.hash);
                }
              }}
            >
              {params.value !== null && <Star />}
              {params.value === null && <StarOutline />}
            </IconButton>
          ),
        },
        {
          field: "title",
          headerName: "Title",
        },
        {
          field: "hash",
          headerName: "Hash",
        },
        {
          field: "user_id",
          headerName: "User",
          width: 220,
          valueGetter: (params) => {
            const match = userList.find((u) => u.user_id === params.value);
            if (!match && userList.length > 0) {
              return "Deleted User";
            }
            return match?.user_displayname || "";
          },
        },
        {
          field: "hash_link",
          headerName: "Use",
          renderCell: (params, row) => (
            <Link to={`/app/reports/report-builder/${params.row.hash}`}>
              Load
            </Link>
          ),
        },
        {
          field: "created_at",
          headerName: "Created",
          width: 160,
          valueGetter: (params) => {
            const n = DateTime.now();
            const d = DateTime.fromISO(params.value);
            let f = "";
            // if today
            if (n.toISODate() === d.toISODate()) {
              f += "HH:mm ";
            }
            f += "LLL dd ";
            if (n.year !== d.year) {
              f += "`yy";
            }
            return d.toFormat(f);
          },
        },
      ];
    }, [userList, fave, unfave]);

    useEffect(() => {
      const loadUsers = async () => {
        let _result = await usersAPI.getUsers({ mid: userInfo.mid });
        if (_result.status < 400) {
          setUserList(_result.results);
        } else {
          throw new Error("unable to load users list");
        }
      };
      if (userList.length === 0) {
        loadUsers();
      }
    }, [userList, userInfo, setUserList]);

    return (
      <Box minWidth={"80vw"}>
        <DataGridPro
          rows={reports}
          columns={columns}
          rowHeight={50}
          autoHeight
          getDetailPanelContent={({ row }) => (
            <Editor report={row} updateHistory={updateHistory} />
          )}
          getDetailPanelHeight={() => "auto"}
        ></DataGridPro>
      </Box>
    );
  }
);

export default ReportsHistoryLarge;
