import React, { useState } from "react";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogContent,
  DialogTitle,
  Modal,
  Typography,
} from "@mui/material";
import DragAndDropUpload from "./DragAndDropUpload";
import {
  Cancel,
  CheckCircle,
  Delete,
  Deselect,
  Download,
  Folder,
  SelectAll,
  Upload,
} from "@mui/icons-material";

export const MediaLibraryTopBar = ({
  uploadModalOpen,
  setUploadModalOpen,
  handleFilesUpload,
  folder_id,
  setShowFolderDialog,
  showFolderDialog,
  setSelectionMode,
  selectionMode,
  selectedItems,
  handleBulkDelete,
  files,
  handleDownload,
  handleSelectAll,
  handleDeselectAll,
}) => {
  const [confirmDeleteModalOpen, setConfirmDeleteModalOpen] = useState(false);

  const handleDeleteConfirmation = () => {
    setConfirmDeleteModalOpen(true);
  };

  const handleDeleteConfirmed = () => {
    handleBulkDelete();
    setConfirmDeleteModalOpen(false);
  };

  return (
    <Card
      style={{
        marginLeft: 20,
        marginRight: 20,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        padding: 10,
        justifyContent: "space-between",
      }}
    >
      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          variant="contained"
          color="primary"
          startIcon={<Upload />}
          onClick={() => setUploadModalOpen(true)}
          style={{ marginRight: 10 }}
        >
          Upload Files
        </Button>

        <Dialog
          open={uploadModalOpen}
          onClose={() => setUploadModalOpen(false)}
          aria-labelledby="upload-modal-title"
          aria-describedby="upload-modal-description"
        >
          <DialogTitle>Upload Files</DialogTitle>
          <DialogContent>
            <Box className="modalStyle" minWidth={"30vw"}>
              <DragAndDropUpload onUpload={handleFilesUpload} />
            </Box>
          </DialogContent>
        </Dialog>

        <Button
          variant="contained"
          color="primary"
          startIcon={<Folder />}
          onClick={() => setShowFolderDialog(!showFolderDialog)}
        >
          New Folder
        </Button>
      </Box>

      <Box display="flex" flexDirection="row" alignItems="center">
        <Button
          onClick={() => setSelectionMode(!selectionMode)}
          startIcon={selectionMode ? <Cancel /> : <CheckCircle />}
          variant="contained"
        >
          {selectionMode ? "Cancel Selection" : "Select Files"}
        </Button>

        {selectionMode && (
          <>
            <Button
              onClick={handleSelectAll}
              style={{ marginLeft: "10px" }}
              startIcon={<SelectAll />}
              variant="contained"
              color="primary"
            >
              Select All
            </Button>

            <Button
              onClick={handleDeselectAll}
              style={{ marginLeft: "10px" }}
              startIcon={<Deselect />}
              variant="contained"
              color="primary"
            >
              Deselect All
            </Button>
            <Button
              variant="contained"
              onClick={() => {
                selectedItems.forEach((id) => {
                  const item = files.find((f) => f.file_id === id);
                  handleDownload(item);
                });
              }}
              style={{ marginLeft: "10px" }}
              startIcon={<Download />}
              color="primary"
              disabled={selectedItems.length === 0}
            >
              Download Selected
            </Button>
            <Button
              onClick={handleDeleteConfirmation}
              style={{
                marginLeft: "10px",
                backgroundColor: selectedItems.length === 0 ? "gray" : "red",
                color: "white",
              }}
              variant="contained"
              startIcon={<Delete />}
              disabled={selectedItems.length === 0}
            >
              Delete Selected
            </Button>
          </>
        )}
      </Box>

      <Modal
        open={confirmDeleteModalOpen}
        onClose={() => setConfirmDeleteModalOpen(false)}
        aria-labelledby="confirm-delete-modal-title"
        aria-describedby="confirm-delete-modal-description"
      >
        <Box
          style={{
            padding: 20,
            backgroundColor: "white",
            margin: "10% auto",
            width: 400,
          }}
        >
          <Typography id="confirm-delete-modal-title" variant="h6">
            Confirm Delete
          </Typography>
          <Typography id="confirm-delete-modal-description">
            Are you sure you want to delete the selected items? This action
            cannot be undone.
          </Typography>
          <Box display="flex" justifyContent="flex-end" mt={2}>
            <Button
              onClick={handleDeleteConfirmed}
              variant="contained"
              color="secondary"
              startIcon={<Delete />}
            >
              Confirm
            </Button>
            <Button
              onClick={() => setConfirmDeleteModalOpen(false)}
              style={{ marginLeft: 10 }}
            >
              Cancel
            </Button>
          </Box>
        </Box>
      </Modal>
    </Card>
  );
};
