import loadingStore from "../stores/loading";

// Helper function to handle API calls with loading state
const APICallWrapper = async (
  key,
  loadingMessage,
  successMessage,
  errorMessage,
  apiCall
) => {
  try {
    loadingStore.setLoading(key, loadingMessage);
    const response = await apiCall();
    loadingStore.setFinished(key, true, successMessage);
    return response;
  } catch (error) {
    loadingStore.setFinished(key, false, errorMessage);
    throw error;
  }
};

export default APICallWrapper;
