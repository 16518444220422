const globals = {
  allDistributorsObjects: [],
  setAllDistributorsObjects(data) {
    this.allDistributorsObjects = data;
  },
  allListsObjects: [],
  setAllListsObjects(data) {
    this.allListsObjects = data;
  },
  allAccountsObjects: [],
  setAllAccountsObjects(data) {
    this.allAccountsObjects = data;
  },
};

export default globals;
