import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import {
  Avatar,
  Box,
  Card,
  CardActionArea,
  Button,
  CardContent,
  Typography,
  Chip,
} from "@mui/material";
import AccountListModal from "./AccountListModal";
import { useStore } from "../../../stores/StoreContext";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { makeStyles } from "@mui/styles";
import { Edit, Lock } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.1),
    },
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ListCard = ({
  className,
  list,
  allLists,
  setData,
  style = {},
  adjustListsByRegion,
  ...rest
}) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const { setCustomers } = useStore();

  const handleClose = (value) => {
    setRefresh(false);
    setOpen(false);
    setSelectedValue(value);
    setRefresh(true);
  };
  const loadAccounts = (list_id) => {
    //console.log(list_id)
    setCustomers([]);
    navigate("/app/lists/" + list_id, {
      state: { type: list.type, title: list.list_name, lists: allLists },
    });
  };

  const [item, setItem] = useState(list);
  const [bgUrl, setBgUrl] = useState("");
  const [refresh, setRefresh] = useState(true);
  const handleClickOpen = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const s3ImageEl = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  useEffect(() => {
    if (list) {
      let _list = { ...list };
      _list.image_scr = awsS3Image(
        _list.list_image,
        _list.list_imagekey,
        "0x110"
      );
      setItem({ ..._list });
    }
  }, [list]);

  /**
   * after the S3 image is loaded, hook into a ref to find the img url via the DOM
   * and then use it as abackground image on it's previous sibling
   *
   */
  const imageLoaded = (event, item) => {
    const el = s3ImageEl.current;
    if (el && el.nextElementSibling) {
      const next = el.nextElementSibling.shadowRoot;
      const imgUrl = next.querySelector("img").src;
      setBgUrl(imgUrl);
    }
  };

  return (
    <div style={style}>
      <AccountListModal
        selectedValue={selectedValue}
        open={open}
        item={item}
        allLists={allLists}
        setData={setData}
        setItem={setItem}
        onClose={handleClose}
        {...{
          adjustListsByRegion,
        }}
      />

      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <CardActionArea onClick={() => loadAccounts(item.list_id)}>
            <Box display="flex">
              <Box mr={1}>
                {item.type !== "AUTO" && (
                  <Button
                    color="primary"
                    startIcon={<Edit />}
                    onClick={(e) => handleClickOpen(e)}
                  >
                    Edit
                  </Button>
                )}
                {item.type === "AUTO" && <Lock />}
              </Box>
              <Avatar
                alt="Product"
                style={{
                  height: "40px",
                  width: "40px",
                  marginRight: "1em",
                  backgroundColor: "#ffffff",
                  position: "relative",
                }}
                variant="circular"
              >
                {refresh && item.list_image !== "none" && (
                  <div
                    style={{
                      position: "relative",
                      height: "100%",
                      width: "100%",
                    }}
                  >
                    <div
                      className="s3ImgToBg"
                      ref={s3ImageEl}
                      style={{
                        height: "100%",
                        width: "100%",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        backgroundRepeat: "no-repeat",
                        backgroundImage: `url(${bgUrl})`,
                      }}
                    ></div>
                    <img
                      src={item.image_scr}
                      style={{
                        width: "100%",
                        height: "100%",
                        position: "absolute",
                        top: "0px",
                        // left: '-50%',
                        borderRadius: "50%",
                      }}
                      alt=""
                    />
                  </div>
                )}
                {refresh && item.list_image === "" && (
                  <img
                    src={"/static/images/placeholders/missing-image.png"}
                    width="100%"
                    height="100%"
                    alt=""
                  />
                )}
              </Avatar>
              <Box>
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="h6"
                >
                  {item.list_name}
                </Typography>
                <Typography align="center" color="textPrimary" variant="body1">
                  {item.description}
                </Typography>
                <div className={classes.chip}>
                  {item.tags != "" &&
                    item.tags
                      ?.split(";")
                      .map((tag, i) => <Chip label={tag} key={i + tag} />)}
                </div>
              </Box>
            </Box>
          </CardActionArea>
        </CardContent>
        <Box flexGrow={1} />
      </Card>
    </div>
  );
};

ListCard.propTypes = {
  className: PropTypes.string,
  list: PropTypes.object.isRequired,
};

export default ListCard;
