import React, { useState, useEffect, useMemo } from "react";
import { useParams, useLocation } from "react-router-dom";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";
import { API } from "aws-amplify";
import clsx from "clsx";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Container,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
} from "@mui/material";

import Page from "../../../components/Page";
import AccountListAssociator from "../../../components/regions/associator/AccountListAssociator";
import Results from "./Results";
import useApi from "../../../hooks/useApi";
import listsAPI from "../../../api/lists";
import { trackEvent } from "../../../api/analytics";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";
import { Cancel } from "@mui/icons-material";

// --------------------------------------------------
// Styles
// --------------------------------------------------
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  resultWrapper: {
    overflow: "visible",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    minHeight: "600px",
    alignItems: "stretch",
  },
}));

// --------------------------------------------------
// Helper Functions
// --------------------------------------------------
function SortArray(x, y) {
  if (x.name < y.name) {
    return -1;
  }
  if (x.name > y.name) {
    return 1;
  }
  return 0;
}

// --------------------------------------------------
// Main Component
// --------------------------------------------------
const CustomerListView = observer(() => {
  const { id } = useParams();
  const location = useLocation();
  const classes = useStyles();

  // --------------------------------------------------
  // Store & State
  // --------------------------------------------------
  const { setCustomers, locations, setListId, userInfo, markets, setMarkets } =
    useStore();

  const [distributorAccounts, setDistributorAccounts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [boxFlexValue, setBoxFlexValue] = useState(0);
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState([]); // For multiple select
  const [premiseType, setPremiseType] = useState("ALL");
  const [selection, setSelection] = useState([]);

  const market = useMemo(
    () => markets.find((m) => m.list_id === parseInt(id, 10)),
    [markets, id]
  );

  // --------------------------------------------------
  // Side Effects
  // --------------------------------------------------
  // Page View Tracking
  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:AccountsList");
  }, [userInfo.uid]);

  // Adjust boxFlexValue based on locations
  useEffect(() => {
    autorun(() => {
      const val = locations.length || location.state.type === "AUTO" ? 1 : 0;
      setBoxFlexValue(val);
    });
  }, [locations, location.state]);

  // Set up customers & list when component mounts
  useEffect(() => {
    setCustomers([]);
    setListId(id);
    setLoading(true);
    getListAccounts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  // Fetch user markets if not already available
  const getListsApi = useApi(listsAPI.getLists);
  async function getData() {
    try {
      const { results } = await getListsApi.request({
        uid: userInfo.uid,
        mid: userInfo.mid,
        limit: 1000,
      });
      setMarkets(results);
    } catch (err) {
      console.error("Error fetching lists:", err);
    }
  }
  useEffect(() => {
    if (markets.length === 0) {
      getData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markets]);

  // --------------------------------------------------
  // Handlers
  // --------------------------------------------------
  const pickCity = (event) => {
    setCity(event.target.value); // value will be an array
  };

  const premiseToggle = (event) => {
    setPremiseType(event.target.value);
  };

  const clearCity = () => setCity([]);

  const reportUp = ({ action, data }) => {
    if (action === "customerListUpdate") {
      const regexp = new RegExp(city, "i");
      setDistributorAccounts(
        data.filter(
          (a) =>
            (city.length === 0 || regexp.test(a.account_city)) &&
            (premiseType === "ALL" || premiseType === a.premise_type)
        )
      );
    }
  };

  // --------------------------------------------------
  // Data Fetching
  // --------------------------------------------------
  async function getListAccounts() {
    try {
      const data = await API.get(
        "backendGateway",
        `/accounts/listdetailsnewfixes/${id}`,
        {
          queryStringParameters: { limit: "10000" },
        }
      );
      const sortedAccounts = data.results.sort((a, b) =>
        a.account_displayname > b.account_displayname ? 1 : -1
      );
      setDistributorAccounts(sortedAccounts);
      setCustomers(sortedAccounts);

      // Build city data
      const citySet = data.results.reduce((acc, cur) => {
        const cty = cur.account_city;
        if (!cty) return acc;
        const cityUp = cty.toUpperCase();
        acc[cityUp] = acc[cityUp] || { name: cityUp, count: 0 };
        acc[cityUp].count += 1;
        return acc;
      }, {});
      setCities(Object.values(citySet));

      setLoading(false);
    } catch (err) {
      console.error("Error fetching data:", err);
      setLoading(false);
    }
  }

  // --------------------------------------------------
  // Early Return
  // --------------------------------------------------
  if (!market) return null;

  // --------------------------------------------------
  // Render
  // --------------------------------------------------
  return (
    <Page
      className={classes.root}
      title="Markets"
      style={{ overflow: "visible", position: "relative", height: "100%" }}
    >
      <Container
        maxWidth={false}
        className={clsx(classes.resultWrapper)}
        style={{}}
      >
        {/* Header Card */}
        <Box
          mt={3}
          style={{
            overflow: "visible",
            flex: boxFlexValue,
            height: "100%",
          }}
        >
          <Card style={{ overflow: "visible", width: "100%" }}>
            <CardHeader
              title={market.list_name}
              style={{
                backgroundColor: "#0C45A6",
                textAlign: "center",
                color: "white",
                borderTopLeftRadius: "5px",
                borderTopRightRadius: "5px",
              }}
            />
          </Card>
        </Box>

        {/* Filters + Associator */}
        <Box mt={3}>
          <Card>
            <CardContent
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {/* City Filter */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                <div>
                  <InputLabel id="filterToCity-label">City</InputLabel>
                  <Select
                    labelId="filterToCity-label"
                    label="City"
                    multiple
                    value={city}
                    onChange={pickCity}
                    renderValue={(selected) =>
                      selected.length === 0 ? "All Cities" : selected.join(", ")
                    }
                    defaultValue=""
                    displayEmpty={true}
                    style={{ width: "260px" }}
                  >
                    {cities.sort(SortArray).map((c) => (
                      <MenuItem value={c.name} key={c.name}>
                        {c.name} ({c.count})
                      </MenuItem>
                    ))}
                  </Select>
                </div>
                <div style={{ paddingLeft: "12px" }}>
                  <Button
                    variant="contained"
                    endIcon={<Cancel />}
                    onClick={clearCity}
                  >
                    All
                  </Button>
                </div>
              </div>

              {/* Premise Type Filter */}
              <FormControl component="fieldset">
                <RadioGroup
                  onChange={premiseToggle}
                  row
                  aria-label="premiseType"
                  name="premiseType"
                  defaultValue="ALL"
                >
                  <FormControlLabel
                    value="ON-PREMISE"
                    control={<Radio color="primary" />}
                    label="ON-PREMISE"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="OFF-PREMISE"
                    control={<Radio color="primary" />}
                    label="OFF-PREMISE"
                    labelPlacement="end"
                  />
                  <FormControlLabel
                    value="ALL"
                    control={<Radio color="primary" />}
                    label="ALL"
                    labelPlacement="end"
                  />
                </RadioGroup>
              </FormControl>
            </CardContent>

            <CardContent>
              <AccountListAssociator accountList={market} />
            </CardContent>
          </Card>
        </Box>

        {/* Results Table */}
        <Results
          market={market}
          listType={market.type}
          listName={market.list_name}
          lists={markets}
          loading={loading}
          city={city}
          premiseType={premiseType}
          reportUp={reportUp}
          setSelection={setSelection}
          selection={selection}
          style={{
            height: "100%",
            width: "100%",
          }}
        />
      </Container>
    </Page>
  );
});

export default CustomerListView;
