import React, { useState } from "react";
import {
  Avatar,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  IconButton,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
  Menu,
  MenuItem,
  Checkbox,
  Snackbar,
  Tooltip,
  Pagination,
} from "@mui/material";
import { parseScheduleData } from "../../helpers/dateUtils";
import { awsS3Image } from "../../helpers/awsS3Image";
import { DateTime } from "luxon";
import { Alert, FormControlLabel, Switch } from "@mui/material";
import SearchBar from "../../components/widgets/SearchBar";
import { API } from "aws-amplify";
import { GridMoreVertIcon } from "@mui/x-data-grid-premium";
import ScheduleManager from "../../components/EventManager";
import Page from "../../components/Page";
const ScheduleList = ({
  scheduleData,
  isMySchedule,
  handleToggle,
  userInfo,
  members,
  refresh,
}) => {
  const { today, tomorrow, thisWeek, future, past } =
    parseScheduleData(scheduleData);
  const [open, setOpen] = useState(false);
  const [editOpen, setEditOpen] = useState(false);
  const [deleteOpen, setDeleteOpen] = useState(false);
  const [teamMembers, setTeamMembers] = useState([userInfo]);
  const [account, setAccount] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredAccount, setFilteredAccount] = useState("");
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [selectedJourney, setSelectedJourney] = useState(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const itemsPerPage = 8;
  const [shouldSendEmail, setShouldSendEmail] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const showToast = (status) => {
    setToastMessage(status);
    setToastOpen(true);
  };

  const handleEdit = async (item) => {
    setTeamMembers(JSON.parse(item.members) || []);
    setSelectedJourney(item);
    setAccount({ id: item.account_id });
    setEditOpen(true);
    setOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
    setEditOpen(false);
    setTeamMembers([userInfo]);
    setAccount(null);
  };

  const handleClose = () => {
    setOpen(false);
    setEditOpen(false);
    setDeleteOpen(false);
    setTeamMembers([userInfo]);
    setAccount(null);
  };

  const handleDelete = async () => {
    setProcessingSubmit(true);
    try {
      const apiName = "backendGateway";
      const path = `/journeys/${selectedJourney.journey_id}`;
      const myInit = {
        queryStringParameters: {
          journey_id: selectedJourney.journey_id,
        },
        headers: {},
      };
      const response = await API.del(apiName, path, myInit);
      console.log("Journey deletion response:", response);

      if (shouldSendEmail) {
        await sendDeleteEmail();
      }
      showToast("Event Successfully Deleted");
    } catch (err) {
      console.log("error deleting journey:", err);
      showToast("Event Failed to be Deleted");
    }
    handleClose();
    refresh();
    setAnchorEl(null);
    setProcessingSubmit(false);
  };

  const sendDeleteEmail = async () => {
    const apiName = "backendGateway";
    const emailInit = {
      body: {
        account_name: selectedJourney.account_displayname,
        account_id: selectedJourney.account_id,
        account_location: selectedJourney.account_address,
        appointment: selectedJourney.appointment,
        appointment_creator: userInfo.user_email,
        user_email: userInfo.user_email,
        user_emails: userInfo.user_email
          ? [
              ...teamMembers.map((member) => member.user_email),
              userInfo.user_email,
            ]
          : [...teamMembers.map((member) => member.user_email)],
      },
      headers: {
        "Content-Type": "application/json",
      },
    };

    try {
      const emailResponse = await API.del(
        apiName,
        "/invites/appointment",
        emailInit
      );
      console.log("Email notification response:", emailResponse);
    } catch (error) {
      console.log("Error sending email notification:", error);
    }
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const getCurrentPageData = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return data.slice(startIndex, endIndex);
  };

  const allItems = [
    ...today.map((item) => ({
      ...item,
      section: "Today - ",
      color: "#CEFFBC",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...tomorrow.map((item) => ({
      ...item,
      section: "Tomorrow - ",
      color: "#B0F5FF",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...thisWeek.map((item) => ({
      ...item,
      section: "Rest of this week - ",
      color: "#BDB6FF",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...future.map((item) => ({
      ...item,
      section: "Future - ",
      color: "#FFB3B3",
      dateRange: DateTime.fromISO(item.appointment).toFormat("dd MMM"),
    })),
    ...past.map((item) => ({ ...item, section: "Past", color: "#DCDCDC" })),
  ];

  const filteredItems = filteredAccount
    ? allItems.filter((item) =>
        item.account_displayname
          ?.toLowerCase()
          .includes(filteredAccount.toLowerCase())
      )
    : allItems;

  const pageCount = Math.ceil(filteredItems.length / itemsPerPage);
  const currentPageData = getCurrentPageData(filteredItems);

  const handleCallback = (selectedAccount) => {
    if (selectedAccount && selectedAccount.name) {
      setFilteredAccount(selectedAccount.name);
    } else {
      setFilteredAccount("");
    }
  };

  const handleMenuClick = (event, journey) => {
    setAnchorEl(event.currentTarget);
    setSelectedJourney(journey);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderTableBody = () => {
    let lastSection = "";
    return (
      <TableBody>
        {currentPageData.length === 0 && (
          <TableRow>
            <TableCell colSpan={6}>No events found</TableCell>
          </TableRow>
        )}
        {currentPageData.map((item, index) => {
          const showSectionHeader = item.section !== lastSection;
          lastSection = item.section;
          return (
            <React.Fragment key={index}>
              {showSectionHeader && (
                <TableRow
                  style={{ backgroundColor: item.color, width: "100%" }}
                >
                  <TableCell colSpan={6}>
                    {item.section} {item.dateRange}
                  </TableCell>
                </TableRow>
              )}
              <TableRow key={item.journey_id}>
                <TableCell>
                  <Avatar
                    alt={item.user_displayname}
                    src={awsS3Image(
                      item.user_image,
                      item.user_identitykey,
                      "50x50"
                    )}
                    style={{ background: "#f6f6f6", marginRight: "10px" }}
                  />
                  {item.user_displayname}
                </TableCell>
                <TableCell>
                  {DateTime.fromISO(item.appointment).toFormat("dd MMM h:mma")}
                </TableCell>
                <TableCell>
                  <Link
                    href={`/app/accountdetails/${item.account_id}`}
                    target="_blank"
                  >
                    {item.account_displayname}
                  </Link>
                </TableCell>
                <TableCell>
                  {item?.purpose ? (
                    <Tooltip title={item.purpose} arrow>
                      <span>
                        {item.purpose.length > 20
                          ? `${item.purpose.substring(0, 20)}...`
                          : item.purpose}
                      </span>
                    </Tooltip>
                  ) : (
                    "No purpose"
                  )}
                </TableCell>
                <TableCell>
                  {item?.description ? (
                    JSON.parse(item.description)?.text ? (
                      <Tooltip title={JSON.parse(item.description)?.text} arrow>
                        <span>
                          {JSON.parse(item.description)?.text.length > 20
                            ? `${JSON.parse(item.description)?.text.substring(
                                0,
                                20
                              )}...`
                            : JSON.parse(item.description)?.text}
                        </span>
                      </Tooltip>
                    ) : (
                      "No description"
                    )
                  ) : (
                    "No description"
                  )}
                </TableCell>
                <TableCell>
                  <IconButton
                    edge="end"
                    aria-label="more"
                    onClick={(event) => handleMenuClick(event, item)}
                  >
                    <GridMoreVertIcon style={{ transform: "rotate(90deg)" }} />
                  </IconButton>
                  <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                  >
                    <MenuItem onClick={() => handleEdit(selectedJourney)}>
                      Edit
                    </MenuItem>
                    <MenuItem onClick={() => setDeleteOpen(true)}>
                      Delete
                    </MenuItem>
                  </Menu>
                </TableCell>
              </TableRow>
            </React.Fragment>
          );
        })}
      </TableBody>
    );
  };

  return (
    <Page title="Schedule">
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          onClose={() => setToastOpen(false)}
          severity={toastMessage.includes("Successfully") ? "success" : "error"}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <Container>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginBottom: "10px",
            marginTop: "10px",
          }}
        >
          <div style={{ display: "flex", alignItems: "center" }}>
            <Typography style={{ marginRight: 15 }}>
              My Team's Schedule
            </Typography>
            <FormControlLabel
              control={
                <Switch checked={isMySchedule} onChange={handleToggle} />
              }
            />
            <Typography style={{ marginLeft: -10 }}>My Schedule</Typography>
          </div>
          <Button variant="contained" color="primary" onClick={handleOpen}>
            Add to Planner
          </Button>
        </div>
        <TableContainer style={{ backgroundColor: "#FFFFFF", minHeight: 600 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Team Member</TableCell>
                <TableCell>Date</TableCell>
                <TableCell>
                  <SearchBar
                    callback={handleCallback}
                    placeholder={"Account (filter)"}
                  />
                </TableCell>
                <TableCell>Purpose</TableCell>
                <TableCell>Description</TableCell>
                <TableCell>Actions</TableCell>
              </TableRow>
            </TableHead>
            {renderTableBody()}
          </Table>
        </TableContainer>
        <Pagination
          count={pageCount}
          page={currentPage}
          onChange={handlePageChange}
          style={{
            marginTop: "20px",
            display: "flex",
            justifyContent: "center",
          }}
        />

        <ScheduleManager
          isEditing={editOpen}
          accountDetails={account}
          editingDefaultState={selectedJourney}
          members={members}
          userInfo={userInfo}
          open={open}
          handleClose={handleClose}
          refresh={refresh}
        />

        <Dialog
          open={deleteOpen}
          fullWidth
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
        >
          <DialogTitle id="form-dialog-title">
            Are you sure you want to delete this event?
          </DialogTitle>
          <DialogContent>
            <Typography>Deleting this event cannot be undone.</Typography>
          </DialogContent>
          <DialogActions
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <div style={{ fontSize: 14, paddingLeft: 15 }}>
              Send Email Confirmation
              <Checkbox
                checked={shouldSendEmail}
                onChange={() => setShouldSendEmail(!shouldSendEmail)}
              />
            </div>
            <div>
              <Button onClick={handleClose} color="primary">
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                color="primary"
                disabled={processingSubmit}
              >
                Delete
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
};

export default ScheduleList;
