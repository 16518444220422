// loadingStore.js
import { observable, action } from "mobx";

const loadingStore = observable({
  loaders: observable.map({}),

  /**
   * Sets the loading state for a specific loader.
   *
   * @param {string} key - Unique identifier for the loader.
   * @param {string} [message=""] - Optional loading message to display.
   */
  setLoading(key, message = "") {
    action(() => {
      this.loaders.set(
        key,
        observable({
          isLoading: true,
          isFinished: false,
          isSuccess: false,
          isError: false,
          loadingMessage: message,
          successMessage: "",
          errorMessage: "",
          progress: 0,
        })
      );
    })();
  },

  /**
   * Marks the loading process as finished and sets success or error state.
   *
   * @param {string} key - Unique identifier for the loader.
   * @param {boolean} [success=true] - Determines if the process was successful.
   * @param {string} [message=""] - Optional message to display.
   */
  setFinished(key, success = true, message = "") {
    action(() => {
      const loader = this.loaders.get(key);
      if (loader) {
        loader.isFinished = true;
        loader.isLoading = false;
        loader.progress = 0;
        if (success) {
          loader.isSuccess = true;
          loader.successMessage = message;
        } else {
          loader.isError = true;
          loader.errorMessage = message;
        }

        // Automatically remove the loader after a delay
        setTimeout(
          action(() => {
            this.loaders.delete(key);
          }),
          2000
        );
      }
    })();
  },

  /**
   * Sets the progress for a specific loader.
   *
   * @param {string} key - Unique identifier for the loader.
   * @param {number} progress - Progress percentage (0 to 100).
   */
  setProgress(key, progress) {
    action(() => {
      const loader = this.loaders.get(key);
      if (loader) {
        loader.progress = progress;
      }
    })();
  },

  /**
   * Resets the loader state for a specific key.
   *
   * @param {string} key - Unique identifier for the loader.
   */
  resetLoader(key) {
    action(() => {
      this.loaders.delete(key);
    })();
  },
});

export default loadingStore;
