// ScheduleManager.jsx
import React, { useState, useEffect } from "react";
import {
  TextField,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Checkbox,
  Snackbar,
  Chip,
  Autocomplete,
  Typography,
  Divider,
} from "@mui/material";
import { DateTime } from "luxon";
import SearchBar from "./widgets/SearchBar";
import { Alert } from "@mui/material";
import journeysApi from "../api/appointments"; // Adjust the import path as necessary
import { LocalizationProvider, DateTimePicker } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const ScheduleManager = ({
  isEditing = false,
  accountDetails = null,
  editingDefaultState = null,
  members,
  userInfo,
  open,
  handleClose,
  refresh,
}) => {
  const [teamMembers, setTeamMembers] = useState([userInfo]);
  const [dateTime, setDateTime] = useState(
    editingDefaultState?.appointment
      ? DateTime.fromISO(editingDefaultState.appointment).toUTC()
      : null
  );

  const [accountDisplayName, setAccountDisplayName] = useState("");
  const [accountAddress, setAccountAddress] = useState("");
  const [purpose, setPurpose] = useState("");
  const [description, setDescription] = useState("");
  const [shouldSendEmail, setShouldSendEmail] = useState(true);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [errors, setErrors] = useState({ purpose: "", members: "" });
  const [processingSubmit, setProcessingSubmit] = useState(false);
  const [searchedAccount, setSearchedAccount] = useState({});

  const closeAndEmptyContext = () => {
    setTeamMembers([userInfo]);
    setDateTime(null);
    setAccountDisplayName("");
    setAccountAddress("");
    setPurpose("");
    setDescription("");
    setShouldSendEmail(true);
    setToastOpen(false);
    setToastMessage("");
    setErrors({ purpose: "", members: "" });
    setProcessingSubmit(false);
    handleClose();
  };

  useEffect(() => {
    if (isEditing && editingDefaultState) {
      setTeamMembers(JSON.parse(editingDefaultState.members) || []);
      setDateTime(DateTime.fromISO(editingDefaultState.appointment).toUTC());
      setPurpose(editingDefaultState.purpose);
      setDescription(JSON.parse(editingDefaultState.description).text);
      setAccountDisplayName(editingDefaultState.account_displayname || "");
      setAccountAddress(editingDefaultState.account_address || "");
    }
  }, [isEditing, editingDefaultState]);

  const handleSave = async () => {
    const utcAppointment = dateTime ? dateTime.toUTC().toISO() : null;
    let newErrors = { purpose: "", members: "" };

    if (!purpose) {
      newErrors.purpose = "Purpose is required.";
    }
    if (teamMembers.length === 0) {
      newErrors.members = "At least one team member is required.";
    }
    if (!dateTime) {
      alert("Date and Time cannot be empty");
      setProcessingSubmit(false);
      return;
    }
    setErrors(newErrors);

    if (newErrors.purpose || newErrors.members) {
      setProcessingSubmit(false);
      return;
    }

    setProcessingSubmit(true);

    const plainTeamMembers = teamMembers.map((member) => ({
      user_id: member.user_id,
      user_displayname: member.user_displayname,
      user_email: member.user_email,
    }));

    const {
      address: account_address,
      city: account_city,
      geo: [
        account_latitude = searchedAccount?.account_latitude ?? null,
        account_longitude = searchedAccount?.account_longitude ?? null,
      ] = [null, null],
      name: account_displayname,
      id: account_id,
    } = searchedAccount || accountDetails || {};

    const { latitude, longitude } = accountDetails || {};
    const final_latitude = account_latitude ?? latitude ?? null;
    const final_longitude = account_longitude ?? longitude ?? null;

    const appoint = {
      appointment: utcAppointment,
      account_id: account_id || accountDetails?.id || null,
      purpose: purpose,
      description: { text: description },
      members: plainTeamMembers,
      account: {
        account_displayname: account_displayname || accountDetails?.name || "",
        account_address: account_address || accountDetails?.address || "",
        account_city: account_city || accountDetails?.city || "",
        account_longitude: final_longitude,
        account_latitude: final_latitude,
        distance: 0,
      },
    };

    try {
      if (isEditing) {
        await journeysApi.updateJourney(
          editingDefaultState.journey_id,
          appoint
        );
      } else {
        await journeysApi.createJourney(appoint);
      }

      if (shouldSendEmail) {
        const emailPayload = isEditing
          ? {
              account_name: accountDisplayName || accountDetails?.name || "",
              account_location: accountAddress,
              appointment: dateTime.toString(),
              appointment_creator: userInfo.user_email,
              user_emails: teamMembers.map((member) => member.user_email),
              purpose: purpose,
              description: description,
              distance: 0,
            }
          : {
              user_emails: teamMembers.map((member) => member.user_email),
              appointment_creator: userInfo.user_email,
              appointment: dateTime.toString(),
              purpose: purpose,
              description: description,
              account_name: account_displayname || accountDetails?.name || "",
              account_location: account_address || "",
              distance: 0,
            };

        if (isEditing) {
          await journeysApi.sendEditEmail(emailPayload);
        } else {
          await journeysApi.sendCreateEmail(emailPayload);
        }
      }

      showToast(
        isEditing ? "Event Successfully Updated" : "Event Successfully Created"
      );
      refresh();
      handleClose();
    } catch (err) {
      console.log("error creating/updating journey:", err);
      showToast(
        isEditing ? "Event Failed to be Updated" : "Event Failed to be Created"
      );
    } finally {
      setProcessingSubmit(false);
    }
  };

  const showToast = (status) => {
    setToastMessage(status);
    setToastOpen(true);
  };

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert
          onClose={() => setToastOpen(false)}
          severity={toastMessage.includes("Successfully") ? "success" : "error"}
        >
          {toastMessage}
        </Alert>
      </Snackbar>
      <Dialog
        open={open}
        fullWidth
        onClose={closeAndEmptyContext}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          {isEditing ? "Edit Event With This Account" : "Add to Planner"}
        </DialogTitle>
        <DialogContent>
          <Autocomplete
            sx={{ marginTop: 2 }}
            freeSolo
            multiple
            options={members}
            getOptionLabel={(option) => option.user_displayname}
            value={teamMembers}
            onChange={(event, newValue) => {
              if (typeof newValue[newValue.length - 1] === "string") {
                const email = newValue[newValue.length - 1];
                const newUser = {
                  user_displayname: email.split("@")[0],
                  user_email: email,
                  user_id: 0,
                };
                setTeamMembers([...teamMembers, newUser]);
              } else {
                setTeamMembers(newValue);
              }
            }}
            renderTags={(value, getTagProps) =>
              value.map((option, index) => (
                <Chip
                  label={option.user_displayname}
                  {...getTagProps({ index })}
                />
              ))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                label="Enter Team Member/s"
                error={!!errors.members}
                helperText={errors.members}
              />
            )}
          />

          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <DateTimePicker
              sx={{
                width: "100%",
                marginTop: 2,
                marginBottom: 2,
              }}
              label="Select Date & Time"
              value={dateTime}
              onChange={(newValue) => setDateTime(newValue)}
              renderInput={(params) => (
                <TextField
                  {...params}
                  fullWidth
                  sx={{ mt: 2, mb: 2 }}
                  error={!dateTime}
                  helperText={!dateTime && "Date and Time cannot be empty"}
                />
              )}
            />
          </LocalizationProvider>

          {accountDetails === null ? (
            <div style={{ marginBottom: "20px", zIndex: 1000 }}>
              <SearchBar
                placeholder="Search for Account"
                callback={(selectedAccount) => {
                  setSearchedAccount(selectedAccount);
                }}
              />
            </div>
          ) : accountDetails?.name ? (
            <Typography
              fontSize={20}
              style={{ marginBottom: 10, marginTop: 10 }}
            >
              Account:{accountDetails?.name}
            </Typography>
          ) : (
            <Divider sx={{ marginTop: "20px", marginBottom: "20px" }} />
          )}
          <TextField
            error={!!errors.purpose}
            helperText={errors.purpose}
            id="purpose-event"
            label="Purpose of Event"
            fullWidth
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
          />
          <TextField
            id="description-event"
            label="Description of Event"
            fullWidth
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            style={{ marginTop: "20px" }}
          />
        </DialogContent>
        <DialogActions>
          <div style={{ fontSize: 14, paddingLeft: 15 }}>
            Send Email Confirmation
            <Checkbox
              checked={shouldSendEmail}
              onChange={() => setShouldSendEmail(!shouldSendEmail)}
            />
          </div>
          <div>
            <Button onClick={closeAndEmptyContext} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSave}
              color="primary"
              disabled={processingSubmit}
            >
              {isEditing ? "Save" : "Add"}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ScheduleManager;
