import React, { useEffect, useState, useMemo } from "react";
import { Box, Typography, Button, TextField, IconButton } from "@mui/material";
import { fieldGroups, metrics } from "./report_builder_filter_values";
import { DateTime } from "luxon";
import reportsAPI from "../../api/reports";
import ReportTable from "./components/table/ReportTable";
import ReportOverlay from "./components/table/ReportOverlay";
import BuilderSteps from "./components/BuilderSteps.js";
import { useStore } from "../../stores/StoreContext.js";
import { observer } from "mobx-react-lite";
import { useParams, useNavigate } from "react-router";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ReportsHistory from "../../components/reports/ReportsHistory.js";
import GenericModal from "../../components/modals/GenericModal.js";
import ReportPreview from "../../components/reports/ReportPreview.js";
import {
  states_and_codes,
  premise_types,
  default_request_body,
} from "./report_builder_filter_values.js";
import Page from "../../components/Page.js";
// Generate columns dynamically based on the first row of data
const generateColumns = ({
  fields,
  firstRow,
  requestBody,
  columnDefinitions,
}) => {
  const nicerNames = {
    account_repname: "Rep",
    distributor_name: "Distributor",
    account_region: "State",
    product_displayname: "Product",
    account_city: "City",
    account_address: "Address",
    product_display_category: "Category",
    product_brand: "Brand",
    account_regioncode: "Zip",
    accounts_sold: "Accounts Sold",
  };

  const isCaseField = (column_name) => {
    return /(cases|units|cases_eqv|accounts_sold)_(\d){4,6}/i.test(column_name);
  };
  const isTotalField = (column_name) =>
    column_name.includes("comparison_total");

  const isPeriodsTotal = (column_name) =>
    column_name.includes("comparison_total");
  if (!firstRow) return [];
  const groupers = requestBody.select.dimensions;
  const derived = Object.keys(firstRow).filter((k) => !groupers.includes(k));

  return [...groupers, ...derived].map((key) => {
    const baseColumn = {
      field: key,
      headerName:
        nicerNames[key] ||
        key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" "),
      flex: 1,
      minWidth: 150,
      ...(columnDefinitions[key] || {}),
      ...(isCaseField(key) ? columnDefinitions.unitPeriod : {}),
      ...(isTotalField(key) ? columnDefinitions.numberColumn : {}),
      ...(isPeriodsTotal(key)
        ? columnDefinitions.periodsTotal({
            time: requestBody.filters.time,
            headerName: key,
            measures: requestBody.select.measures,
          })
        : {}),
    };

    // Check if this column is one of the "Diff" columns
    if (key.includes("diff_pct") || key.includes("diff_value")) {
      return {
        ...baseColumn,
        cellClassName: (params) => {
          return params.value > 0
            ? "positive-diff"
            : params.value < 0
            ? "negative-diff"
            : "";
        },
      };
    }

    return baseColumn;
  });
};

const ReportBuilder = observer(() => {
  // Local state for view toggling, field selections, filters, etc.
  const [expandedSection, setExpandedSection] = useState(null);
  const [searchQueries, setSearchQueries] = useState({
    account: "",
    product: "",
    distributor: "",
    other: "",
  });
  const [selectedFields, setSelectedFields] = useState({
    account: [],
    product: [],
    distributor: [],
    other: [],
  });

  const [selectedMetric, setSelectedMetric] = useState("cases");
  const [selectedFilter, setSelectedFilter] = useState("accounts");
  const initialGroupBy = "premise_id";
  const [loading, setLoading] = useState(false);
  const [viewMode, setViewMode] = useState("table");
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [settingsChanged, setSettingsChanged] = useState(false);
  const [hasGeneratedReport, setHasGeneratedReport] = useState(false);
  const [reportHash, setReportHash] = useState(null);
  const { userInfo, resultSets, addResultSet, addResultSetItems } = useStore();
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const dgPageSize = 250;
  const [savedInHistory, setSavedInHistory] = useState(null);
  const [previewRequestBody, setPreviewRequestBody] = useState(null);
  const [totalResultRow, setTotalResultRow] = useState([]);
  const [filteringArrays, setFilteringArrays] = useState({
    dists: [],
  });
  const [filterReports, setFilterReports] = useState([]);
  const [reports, setReports] = useState([]);

  const [requestBody, setRequestBody] = useState(default_request_body);

  const params = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    const ls = [];
    const filters = requestBody.filters.dimensions;
    const derivedDistNames = filteringArrays.dists.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur.name,
      };
    }, {});
    const distnames = filters.dist_id || [];
    if (distnames.length > 0) {
      ls.push({
        group: "Distributors",
        names: distnames.map((dn) => derivedDistNames[dn] || dn),
      });
    }
    if (filters.state_id) {
      const stateObj = states_and_codes.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: cur.state,
        };
      }, {});

      ls.push({
        group: "States",
        names: filters.state_id.map((f) => stateObj[f] || f),
        // use: stateObj,
      });
    }
    if (filters.premise_id) {
      const premiseObj = premise_types.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.premise_id]: cur.premise_name,
        };
      }, {});
      ls.push({
        group: "Premise Types",
        names: filters.premise_id.map((f) => premiseObj[f] || f),
      });
    }
    if (filters.prod_id) {
      const prodsObj = Object.values(filteringArrays.prods)
        .flat()
        .reduce((acc, prod) => {
          return {
            ...acc,
            [prod.id]: prod.name,
          };
        }, {});
      ls.push({
        group: "Products",
        names: filters.prod_id.map((f) => prodsObj[f] || f),
      });
    }
    setFilterReports(ls);
  }, [filteringArrays, requestBody]);
  useEffect(() => {
    console.log(params);
    if (params.reportHash && params.reportHash !== reportHash) {
      // load stored report
      reportsAPI
        .getReportHistory(params.reportHash)
        .then(({ results }) => {
          const { structure } = results;
          const structureObj = JSON.parse(structure);
          setPreviewRequestBody(structureObj);
          navigate("/app/reports/report-builder");
        })
        .catch((error) => console.error(error));
    }
  }, [params]);

  const retryConfig = {
    delay: 5000,
    attempts: 3,
  };
  const reportData = useMemo(
    () => resultSets[reportHash] || [],
    [resultSets, reportHash]
  );

  const endReached = useMemo(() => {
    return totalRows >= reportData.length;
  }, [reportData, totalRows]);

  const tableRows = useMemo(() => {
    const pageSize = dgPageSize;
    const p = currentPage - 1;
    const sliceStart = p * pageSize;
    return totalResultRow.concat(
      reportData.slice(sliceStart, sliceStart + pageSize)
    );
  }, [reportData, currentPage, totalResultRow]);

  useEffect(() => {
    const reset = () => {
      setRequestBody({
        ...requestBody,
        page: 1,
      });
      setReportHash(null);
      setSettingsChanged(true);
    };
    // check if key items have changed
    if (hasGeneratedReport) {
      console.log("do a reset");
      reset();
    }
  }, [
    JSON.stringify([
      requestBody.select,
      requestBody.filters,
      requestBody.comparisons,
    ]),
  ]);
  // Add handler to dismiss the overlay
  const handleDismissOverlay = () => {
    setSettingsChanged(false);
  };

  // Update requestBody.select.dimensions when selectedFields change.
  useEffect(() => {
    const newDimensions = selectedFields.account
      .concat(selectedFields.product)
      .concat(selectedFields.distributor)
      .concat(selectedFields.other);
    console.log(requestBody.select.dimensions, newDimensions);
    setRequestBody((prev) => ({
      ...prev,
      select: {
        ...prev.select,
        dimensions: newDimensions,
      },
    }));
  }, [selectedFields]);

  // Helper function to retrieve field configuration from fieldGroups.
  const getFieldConfig = (fieldValue) => {
    for (const groupKey in fieldGroups) {
      const field = fieldGroups[groupKey].fields.find(
        (f) => f.value === fieldValue
      );
      if (field) return field;
    }
    return null;
  };

  const loadNextAPIChange = ({ page = requestBody.page, retriesLeft }) => {
    if (!reportHash || settingsChanged || endReached) return;
    if (retriesLeft < 1) {
      setLoading(false);
    }
    setLoading(true);
    setSettingsChanged(false);

    reportsAPI
      .getReport({
        reportHash,
        page,
        pageSize: requestBody.pageSize,
      })
      .then((response) => {
        let { data, totalRows } = response;
        if (data.length === 0) {
          setTimeout(() => {
            loadNextAPIChange({ page, retriesLeft: retriesLeft - 1 });
          }, retryConfig.delay);
        }
        if (data.length > 0) {
          setTotalRows(totalRows);
          // setReportData(data || []);
          setLoading(false);
          addResultSetItems({
            hash: reportHash,
            list: data,
          });
        }
      })
      .catch((error) => {
        console.error("Error fetching page:", error);
        setLoading(false);
      });
  };

  const changePage = (diff) => {
    const newPage = currentPage + diff;
    setCurrentPage(newPage);

    if (newPage * dgPageSize < reportData.length) {
      return;
    }
    const newApiPage = requestBody.page + 1;
    setRequestBody({
      ...requestBody,
      page: newApiPage,
    });
    // Call the API with the updated page
    loadNextAPIChange({
      page: newApiPage,
      retriesLeft: retryConfig.attempts,
    });
  };

  // Then update onNextPage and onPreviousPage to accept a parameter
  const onNextPage = (step = 1) => changePage(step);
  const onPreviousPage = (step = 1) => changePage(-step);

  useEffect(() => {
    console.log({ selectedFilter });
  }, [selectedFilter]);

  // Submits the report request after transforming field names as needed.
  const submitPostReportRequest = async ({ body }) => {
    const { pageSize } = body;
    const page = 1;
    setLoading(true);
    setSettingsChanged(false);

    const selectArray = body.select.dimensions.map((dim) => {
      switch (dim) {
        case "premise_type":
          return "premise_id";
        case "distributor_state_code":
          return "state_id";
        default:
          return dim;
      }
    });
    try {
      const transformedRequest = {
        body: {
          select: {
            dimensions: selectArray,
            measures: body.select.measures,
          },
          filters: {
            dimensions: {
              ...body.filters.dimensions,
            },
            time: body.filters.time,
          },
          group_by: selectArray,
          comparisons: body.comparisons,
        },
      };
      const response = await reportsAPI.postReport(transformedRequest, {
        page,
        pageSize,
      });
      const { data, reportHash, totalRows } = response;
      setReportHash(reportHash);
      const hist = {
        hash: reportHash,
        structure: body,
        title: "",
        comment: "",
        private: 0,
        archived: 0,
        user_id: userInfo.user_id,
      };
      if (reportHash && !reports.find((r) => r.hash === reportHash)) {
        reportsAPI.saveReportHistory(hist).then(({ results }) => {
          console.log(results);
          const id = results[0];
          setSavedInHistory({
            ...hist,
            id,
          });
        });
      }
      setHasGeneratedReport(true);
      // this should be the only call to setTotalRows
      setTotalRows(totalRows);
      if (data.length > 0) {
        addResultSet({
          hash: reportHash,
          list: data,
        });
      }
      setTotalResultRow([data[0]]);
      setLoading(false);
      setCurrentPage(1);
      if (!previewRequestBody) {
        setRequestBody({
          ...body,
          page: body.page,
        });
      }
      return response;
    } catch (error) {
      console.error("Error submitting report:", error);
      throw error;
    }
  };

  const submitExportRequest = async ({ retries }) => {
    if (!reportHash) {
      return alert("Report is not ready");
    }
    setLoading(true);
    const reportName =
      ["Report", reportHash.substr(0, 8), requestBody.filters.time.offset].join(
        "-"
      ) + ".xlsx";

    try {
      // Call the API which now returns a JSON response with the signed URL.
      const response = await reportsAPI.getReportExport({ reportHash });

      if (response.message && response.message.startsWith("No data")) {
        console.error("Failed to generate report", response);
        if (retries > 0) {
          setTimeout(() => {
            submitExportRequest({
              retries: retries - 1,
            });
          }, retryConfig.delay);
        } else {
          alert(
            "Export does not appear to be prepared yet. Please wait 1 minute and try again."
          );
        }
        return;
      }

      // Parse the body to extract the URL
      const url = response.url;
      console.log("Download URL:", url);
      // // Trigger the download by creating a temporary anchor element.
      const link = document.createElement("a");
      link.href = url;
      link.download = reportName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error submitting report:", error);
      alert("Error downloading report. Please try again later.");
    } finally {
      setLoading(false);
    }
  };
  // Render a button for submitting the report.
  const RenderSubmitButton = () => (
    <Button
      variant="contained"
      onClick={() => submitPostReportRequest({ body: requestBody })}
      fullWidth
      sx={{
        height: 40,
        bgcolor: "#1F449F",
        color: "white",
        "&:hover": { bgcolor: "#1a3b8a" },
      }}
    >
      Generate Report
    </Button>
  );

  // Initialize additional props for FieldsContent.
  const initialGroupByField = getFieldConfig(initialGroupBy);
  const initialOtherFields = requestBody.select.dimensions
    .filter((dim) => dim !== initialGroupBy)
    .map(getFieldConfig)
    .filter(Boolean);

  // Handles filter updates.
  const onFilterChange = (filterType, values) => {
    setRequestBody((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        dimensions: {
          ...prev.filters.dimensions,
          [`${filterType}_id`]: values,
        },
      },
    }));
  };

  // Handles field selection (adding or removing a field).
  const handleFieldSelection = (category, field, isSelected) => {
    setSelectedFields((prev) => ({
      ...prev,
      [category]: isSelected
        ? [...prev[category], field]
        : prev[category].filter((f) => f !== field),
    }));

    setRequestBody((prev) => {
      const dimensions = isSelected
        ? [...prev.select.dimensions, field]
        : prev.select.dimensions.filter((d) => d !== field);
      return {
        ...prev,
        select: {
          ...prev.select,
          dimensions: [...new Set(dimensions)],
        },
      };
    });
  };

  // Handles metric selection.
  const handleMetricSelection = (metric) => {
    setSelectedMetric(metric);
    setRequestBody((prev) => ({
      ...prev,
      select: {
        ...prev.select,
        measures: [metric],
      },
    }));
  };

  const loadLibraryReport = (generate = true, body) => {
    setRequestBody(body);
    let cols = body.select.dimensions
      .map((c) => {
        let item = c;
        Object.entries(fieldGroups).forEach(([key, val]) => {
          const matchingField = val.fields.find((f) => f.value === c);
          if (matchingField) {
            // console.log("looks good")
            item = {
              group: key,
              value: c,
            };
          }
        });
        return item;
      })
      .reduce((acc, cur) => {
        let { group, value } = cur;
        if (acc[group]) {
          acc[group].push(value);
        } else {
          acc[group] = [value];
        }
        return acc;
      }, {});
    // replacement candidate
    const replacement = {
      account: [],
      product: [],
      distributor: [],
      other: [],
      ...selectedFields,
      ...cols,
    };
    setSelectedFields(replacement);
    if (generate) {
      setTimeout(() => {
        submitPostReportRequest({ body });
        setPreviewRequestBody(null);
      }, 500);
    }
  };

  return (
    <Page title="Report Builder">
      <Box
        sx={{
          position: "fixed",
          display: "flex",
          zIndex: 1200,
          left: 0,
          right: 0,
          bottom: 0,
          marginTop: 8,
          marginLeft: 8,
          top: 0,
          height: "calc(100vh - 64px)",
          overflow: "auto",
          overscrollBehavior: "none",
        }}
      >
        <Box
          sx={{
            width: sidebarExpanded ? "22vw" : "80px",
            minWidth: sidebarExpanded ? "300px" : "80px",
            height: "93vh",
            overflowY: "auto",
            position: "relative",
            borderRight: 1,
            top: 0,
            borderColor: "divider",
            backgroundColor: "#FFFFFF",
            display: "flex",
            flexDirection: "column",
            overscrollBehavior: "contain",
            transition: "width 0.2s ease-in-out, min-width 0.2s ease-in-out",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              right: 8,
              bottom: 8,
              display: "flex",
              flexDirection: "row",
              gap: 1,
              alignItems: "center",
            }}
          >
            <Typography variant="h6" sx={{ textAlign: "center" }}>
              {sidebarExpanded && "Minimize"}
            </Typography>
            <IconButton
              onClick={() => setSidebarExpanded(!sidebarExpanded)}
              sx={{
                zIndex: 100,
                bgcolor: "rgba(255, 255, 255, 0.9)",
                border: "1px solid #e0e0e0",
                "&:hover": {
                  bgcolor: "rgba(240, 240, 240, 0.9)",
                },
              }}
              size="medium"
            >
              {sidebarExpanded ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            </IconButton>
          </Box>

          {sidebarExpanded && (
            <Box>
              <BuilderSteps
                submit={
                  <>
                    <RenderSubmitButton />
                  </>
                }
                selectedFilter={selectedFilter}
                setSelectedFilter={setSelectedFilter}
                {...{
                  fieldGroups,
                  expandedSection,
                  setExpandedSection,
                  selectedFields,
                  setSelectedFields,
                  searchQueries,
                  setSearchQueries,
                  selectedMetric,
                  handleMetricSelection,
                  metrics,
                  handleFieldSelection,
                  onFilterChange,
                  requestBody,
                  setRequestBody,
                  initialGroupByField,
                  initialOtherFields,
                  filteringArrays,
                  setFilteringArrays,
                  filterReports,
                }}
              />
              {/* <ReportsHistory
              savedInHistory={savedInHistory}
              setSavedInHistory={setSavedInHistory}
              reports={reports}
              setReports={setReports}
            /> */}
            </Box>
          )}
        </Box>

        <Box
          sx={{
            height: "93vh",
            position: "relative",
            top: 0,
            flexGrow: 1,
            overflowY: "auto",
            display: "flex",
            flexDirection: "column",
            overscrollBehavior: "contain",
            padding: 2,
            transition: "margin-left 0.2s ease-in-out",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <div>
              <Typography variant="h5">
                {viewMode === "table" ? "Report Results" : "Request Body"}
              </Typography>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              {/* us only */}
              {[349, 102, 275].includes(userInfo.uid) ? (
                <Button
                  variant="outlined"
                  onClick={() =>
                    setViewMode(viewMode === "table" ? "request" : "table")
                  }
                  sx={{
                    height: 40,
                    ml: 2,
                    borderColor: "#1F449F",
                    color: "#1F449F",
                    "&:hover": {
                      borderColor: "#1a3b8a",
                      backgroundColor: "#ffffff",
                    },
                  }}
                >
                  {viewMode === "table" ? "View Request Body" : "View Table"}
                </Button>
              ) : null}
            </div>
          </Box>

          <Box sx={{ position: "relative", flex: 1 }}>
            {viewMode === "table" ? (
              <>
                <ReportTable
                  data={tableRows}
                  requestBody={requestBody}
                  loading={loading}
                  previewColumns={[
                    ...selectedFields.account,
                    ...selectedFields.product,
                    ...selectedFields.distributor,
                    ...selectedFields.other,
                  ]}
                  totalRows={totalRows}
                  onExport={() =>
                    submitExportRequest({ retries: retryConfig.attempts })
                  }
                  onNextPage={onNextPage}
                  onPreviousPage={onPreviousPage}
                  endReached={endReached}
                  currentPage={currentPage}
                  safePages={Math.floor(reportData.length / dgPageSize) + 1}
                  dgPageSize={dgPageSize}
                  hash={reportHash}
                  generateColumns={generateColumns}
                />
                {hasGeneratedReport && settingsChanged && (
                  <ReportOverlay
                    onDismiss={handleDismissOverlay}
                    onRegenerate={() =>
                      submitPostReportRequest({ body: requestBody })
                    }
                  />
                )}
              </>
            ) : (
              <Box
                sx={{ bgcolor: "white", p: 3, borderRadius: 1, boxShadow: 1 }}
              >
                <pre
                  style={{
                    whiteSpace: "pre-wrap",
                    wordWrap: "break-word",
                    margin: 0,
                  }}
                >
                  {JSON.stringify(requestBody, null, 2)}
                  {"Hash: " + JSON.stringify(reportHash, null, 2)}
                </pre>
              </Box>
            )}
          </Box>
          {previewRequestBody && (
            <GenericModal
              open={previewRequestBody}
              onClose={() => setPreviewRequestBody(null)}
              confirmAction={() => loadLibraryReport(true, previewRequestBody)}
              title={"Use this Report structure?"}
              confirmText="Generate Report"
              style={{
                width: "900px",
              }}
            >
              <ReportPreview
                {...{
                  previewRequestBody,
                  setPreviewRequestBody,
                  setRequestBody,
                  fieldGroups,
                  filterReports,
                  setSettingsChanged,
                }}
              />
            </GenericModal>
          )}
        </Box>
      </Box>
    </Page>
  );
});

export default ReportBuilder;
