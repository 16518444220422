import React, { useState, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  DataGridPremium,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { useStore } from "../../../stores/StoreContext";
import { Box, Typography, Paper, Snackbar } from "@mui/material";
import FilterMenu from "../menu/FilterMenu";
import { useDepletionsContainerContext } from "../../../stores/DepletionContextStore";

const DepletionsView = observer(() => {
  const {
    allDistributorsObjects,
    premiseTypes,
    allBrands,
    allDistributors,
    allProducts,
    userInfo,
  } = useStore();

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [filtersApplied, setFiltersApplied] = useState(false);

  const [filters, setFilters] = useState({
    distributorId: [],
    accountId: null,
    productId: null,
    dateRange: ["2024-01-01", "2024-12-31"],
    quantityType: "cases_eqv",
    page: 0,
    pageSize: 50,
    searchFilters: [],
    sortField: "acc_name",
    sortDirection: "asc",
    premiseTypes: [],
    selectedBrands: [],
    selectedProducts: [],
  });

  const [rowGroupingModel, setRowGroupingModel] = useState(["STORENUMBER"]); // Default grouping by Account Name
  const [columnVisibilityModel, setColumnVisibilityModel] = useState({
    ACCOUNT_ID: false,
    start_range: false,
    end_range: false,
  });
  const { depletions } = useDepletionsContainerContext();

  const getDistributorIds = (selectedDistributors, allDistributors) => {
    return selectedDistributors
      .map((id) => allDistributors.find((distributor) => distributor.id === id))
      .filter(Boolean) // Remove any unmatched names
      .map((distributor) => distributor.id); // Extract numeric IDs
  };

  const onApplyFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
      page: 0,
    }));
    setFiltersApplied(true);
  };

  const onResetFilters = () => {
    setFilters({
      distributorId: [],
      accountId: null,
      productId: null,
      dateRange: ["2024-01-01", "2024-12-31"],
      quantityType: "cases_eqv",
      page: 0,
      pageSize: 50,
      searchFilters: [],
      sortField: "acc_name",
      sortDirection: "asc",
      premiseTypes: [],
      selectedBrands: [],
    });
    setFiltersApplied(false);
  };

  const columns = useMemo(
    () => [
      { field: "ACCOUNT_ID", headerName: "Account ID", width: 150 },
      {
        field: "start_range",
        headerName: "Start Range",
        width: 180,
        valueFormatter: (value, row) => {
          if (value === null || value === undefined) {
            return "";
          }
          const { start_range } = row || {};

          const date = new Date(start_range);

          // Extract the month and year
          const month = date.getUTCMonth() + 1; // getUTCMonth() is zero-based
          const year = date.getUTCFullYear();

          // Format as "month-year"
          return `${month}-${year}`;
        },
      },
      {
        field: "end_range",
        headerName: "End Range",
        width: 180,
        valueFormatter: (value, row) => {
          if (value === null || value === undefined) {
            return "";
          }
          const { end_range } = row || {};

          const date = new Date(end_range);

          // Extract the month and year
          const month = date.getUTCMonth() + 1; // getUTCMonth() is zero-based
          const year = date.getUTCFullYear();

          // Format as "month-year"
          return `${month}-${year}`;
        },
      },
      { field: "DISTRIBUTOR_NAME", headerName: "Distributor Name", width: 150 },
      { field: "PREMISETYPE", headerName: "Premise", width: 150 },
      { field: "STORENUMBER", headerName: "Account Name", width: 200 },
      { field: "PRODUCTNAME", headerName: "Product Name", width: 200 },
      { field: "BRAND", headerName: "Brand", width: 150 },
      { field: "STATE", headerName: "State", width: 150 },
      {
        field: "current_quantity",
        headerName: "Current Period Quantity",
        width: 180,
        type: "number",
        valueFormatter: (value, row) => {
          if (value === null || value === undefined) {
            return "N/A";
          }
          return `${value.toFixed(2)}`;
        },
        aggregable: true,
      },
      {
        field: "previous_quantity",
        headerName: "Previous Period Quantity",
        width: 180,
        type: "number",
        valueFormatter: (value, row) => {
          if (value === null || value === undefined) {
            return "N/A";
          }
          return `${value.toFixed(2)}`;
        },
        aggregable: true,
      },
      {
        field: "difference",
        headerName: "Difference",
        width: 150,
        type: "number",
        valueGetter: (value, row) => {
          const { current_quantity, previous_quantity } = row || {};
          if (
            previous_quantity === 0 ||
            previous_quantity === null ||
            previous_quantity === undefined
          ) {
            return null; // Avoid division by zero or invalid data
          }
          return current_quantity - previous_quantity;
        },
        valueFormatter: (value, row) => {
          if (value === null || value === undefined) {
            return "N/A";
          }
          return `${value.toFixed(2)}`;
        },
        aggregable: true,
      },
      {
        field: "difference_percent",
        headerName: "Difference %",
        width: 150,
        type: "number",
        valueGetter: (value, row) => {
          const { current_quantity, previous_quantity } = row || {};
          if (
            previous_quantity === 0 ||
            previous_quantity === null ||
            previous_quantity === undefined
          ) {
            return null; // Avoid division by zero or invalid data
          }
          return (
            ((current_quantity - previous_quantity) / previous_quantity) * 100
          );
        },
        valueFormatter: (value, row) => {
          if (value === null || value === undefined) {
            return "N/A";
          }
          return `${value.toFixed(2)}%`;
        },
        aggregable: true,
      },
    ],
    []
  );

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        excelOptions={{ fileName: "DepletionExport" }}
      />
    </GridToolbarContainer>
  );
  function getLastDayOfMonth(yearMonth) {
    const [year, month] = yearMonth.split("-").map(Number);
    return new Date(year, month, 0).toISOString().split("T")[0]; // Get last day in YYYY-MM-DD format
  }
  const fileBasedRows = useMemo(() => {
    setLoading(true);
    const { manufacturer_id } = userInfo;
    const quantityMap = {
      //nine eqv unless beer text change
      cases_eqv: [614, 629, 632].includes(manufacturer_id)
        ? "CASE_EQV"
        : "NINE_SOLD",
      cases: "PHYSICAL_SOLD",
      unit_sold: "UNIT_SOLD",
    };
    const {
      quantityType,
      dateRange,
      selectedBrands,
      selectedProducts,
      distributorId: selectedDistributors,
    } = filters;
    const distributorIds = getDistributorIds(
      selectedDistributors,
      allDistributorsObjects
    );
    const [start, end] = dateRange.map((r) => r.substring(0, 7));
    const [previousStart, previousEnd] = [start, end].map((s) => {
      const year = parseInt(s.substring(0, 4));
      return year - 1 + s.substring(4, 7);
    });
    const propsToCheck = {
      distributors:
        distributorIds &&
        distributorIds.length &&
        distributorIds.length !== allDistributorsObjects.length,
      brands:
        selectedBrands &&
        selectedBrands.length &&
        selectedBrands.length !== allBrands.length,
      products:
        selectedProducts &&
        selectedProducts.length &&
        selectedProducts.length !== allProducts.length,
      premiseTypes: filters.premiseTypes.length !== premiseTypes.length,
    };

    const volumeProp = quantityMap[quantityType];
    const fbr = depletions.reduce((acc, cur) => {
      // Extract relevant data
      const fullDate = cur.date.substring(0, 10); // Assuming `date` is in ISO format
      const dateTimestamp = new Date(fullDate).getTime();
      const { ACCOUNT_ID, PRODUCT_ID, PREMISETYPE, BRAND, DISTRIBUTOR_ID } =
        cur;

      const objString = [ACCOUNT_ID, PRODUCT_ID].join("_"); // Unique key for grouping

      // Compute ranges
      const startDate = new Date(start).getTime();
      const endDate = new Date(getLastDayOfMonth(end)).getTime();
      const prevStartDate = new Date(previousStart).getTime();
      const prevEndDate = new Date(getLastDayOfMonth(previousEnd)).getTime();

      // Filter based on user criteria
      if (propsToCheck.distributors && !distributorIds.includes(DISTRIBUTOR_ID))
        return acc;
      if (propsToCheck.brands && !selectedBrands.includes(BRAND)) return acc;
      if (propsToCheck.products && !selectedProducts.includes(PRODUCT_ID))
        return acc;
      if (
        propsToCheck.premiseTypes &&
        !filters.premiseTypes.includes(PREMISETYPE)
      )
        return acc;

      // Check and update current or previous quantities
      const isCurrent = dateTimestamp >= startDate && dateTimestamp <= endDate;
      const isPrevious =
        dateTimestamp >= prevStartDate && dateTimestamp <= prevEndDate;

      if (!isCurrent && !isPrevious) return acc; // Skip if outside both ranges

      // Initialize or update the object
      if (!acc[objString]) {
        acc[objString] = {
          ...cur,
          start_range: startDate,
          end_range: endDate,
          current_quantity: 0,
          previous_quantity: 0,
        };
      }

      // Update quantities
      if (isCurrent) {
        acc[objString].current_quantity += cur[volumeProp];
      }
      if (isPrevious) {
        acc[objString].previous_quantity += cur[volumeProp];
      }

      return acc;
    }, {});
    const results = Object.values(fbr);
    setLoading(false);
    return results;
  }, [depletions, filters]);

  return (
    <Box component={Paper} elevation={3} p={3} m={3} borderRadius={8}>
      <Typography variant="h4" gutterBottom>
        Monthly Depletion Report
      </Typography>
      {depletions.length === 0 && <Box>loading depletions</Box>}
      {depletions.length > 0 && (
        <FilterMenu
          distributors={allDistributors}
          brands={allBrands}
          products={allProducts}
          premiseTypes={premiseTypes}
          unitOfMeasures={["Units", "Cases", "Case Eqv."]}
          onApplyFilters={onApplyFilters}
          onResetFilters={onResetFilters}
          autoUpdate={true}
        />
      )}
      <Box
        gap={2}
        mb={2}
        alignItems="center"
        overflow={"auto"}
        style={{
          width: "100%",
        }}
      >
        <DataGridPremium
          rows={fileBasedRows || []}
          columns={columns}
          sx={{
            "& .MuiDataGrid-overlay": {
              backgroundColor: "rgba(255, 255, 255, 0.7)",
              zIndex: 1,
            },
          }}
          loading={loading}
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={(newModel) => setRowGroupingModel(newModel)}
          groupingColDef={{
            headerName: "Group",
            hideDescendantCount: true,
          }}
          aggregationModel={{
            current_quantity: "sum",
            previous_quantity: "sum",
            difference: "sum",
            difference_percent: "sum",
          }}
          slots={{
            toolbar: CustomToolbar,
          }}
          columnVisibilityModel={columnVisibilityModel}
          onColumnVisibilityModelChange={setColumnVisibilityModel}
          getRowId={(row) => `${row.ACCOUNT_ID}-${row.PRODUCT_ID}`}
          disableSelectionOnClick
          pagination
          autoHeight
        />
      </Box>
      {error && (
        <Snackbar
          open
          autoHideDuration={6000}
          onClose={() => setError(null)}
          message={error}
        />
      )}
    </Box>
  );
});

export default DepletionsView;
