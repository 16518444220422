import { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Button,
  Select,
  MenuItem,
  FormControl,
  Typography,
  Modal,
  Grid2,
  Box,
  ButtonGroup,
  InputLabel,
} from "@mui/material";
import { useStore } from "../../stores/StoreContext";
import ProductSelecter from "./ProductSelecter";
import { Loader } from "react-feather";
import PremiseSelecter from "./PremiseSelecter";
import { Divider } from "@mui/material";
import { DateTime } from "luxon";
import useApi from "../../hooks/useApi";
import productsAPI from "../../api/products";
import { ArrowLeft, ArrowRight, CloudDownload } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";

const DatePickerModal = ({
  open,
  setOpen,
  editDateRange,
  handleFromDateChange,
  handleToDateChange,
  prepareCustomDateRange,
}) => {
  // Convert editDateRange to Luxon DateTime objects for MUI DatePicker
  const luxonDateRange = useMemo(
    () =>
      editDateRange.map((date) => {
        console.log("date from memo", date);
        return DateTime.fromJSDate(date);
      }),
    [editDateRange]
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="date-picker-modal"
    >
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          divShadow: 24,
          p: 4,
          borderRadius: 2,
          width: "95%",
          maxWidth: 600,
        }}
      >
        <Typography variant="h6" mb={2}>
          Select Date Range
        </Typography>

        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Grid2 xs={5}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="START DATE"
                value={luxonDateRange[0]} // Luxon DateTime
                onChange={(newDate) => {
                  if (newDate) handleFromDateChange(newDate.toJSDate());
                }}
                views={["month", "year"]}
                maxDate={luxonDateRange[1]}
              />
            </LocalizationProvider>
          </Grid2>
          <Typography variant="h6" style={{ margin: "0 10px" }}>
            -
          </Typography>
          <Grid2 xs={5}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="END DATE"
                value={luxonDateRange[1]} // Luxon DateTime
                onChange={(newDate) => {
                  if (newDate) handleToDateChange(newDate.toJSDate());
                }}
                views={["month", "year"]}
                maxDate={luxonDateRange[1]}
              />
            </LocalizationProvider>
          </Grid2>
        </Box>

        <Box mt={3} display="flex" justifyContent="flex-end">
          <Button
            size="small"
            variant="contained"
            onClick={() => {
              prepareCustomDateRange();
              setOpen(false);
            }}
          >
            Confirm Dates
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

const MegaFilterGlobalBar = observer(
  ({ location, globalMinimal, selectAll, selectNone }) => {
    const {
      dateRange,
      dateRangeName,
      latestSale,
      setDateRangeName,
      dateRangeOptions,
      setCustomDateRange,
      changeDateOffset,
      allBrands,
      allDistributors,
      allStates,
      quantities,
      selectedQuantity,
      setSelectedQuantity,
      exportSupportedPaths,
      userInfo,
      setAutoCompleteProducts,
      selectedProducts,
    } = useStore();

    const [editDateRange, setEditDateRange] = useState([...dateRange]);
    const [showSecondaryFilters, setShowSecondaryFilters] = useState({
      products: false,
      premise: false,
    });
    const quantityFilter = [614, 629, 632].includes(userInfo.mid)
      ? "case_eqv"
      : "nine_sold";

    const changeQuantity = (event) => {
      setSelectedQuantity(event.target.value);
    };

    const styles = {
      button: {
        margin: "0 0.6rem",
        height: 40,
      },
    };

    useEffect(() => {
      if (selectedProducts.length > 0) {
        setShowSecondaryFilters({
          ...showSecondaryFilters,
          products: true,
        });
      }
    }, [selectedProducts]);

    const rangeToText = (range, dateRangeName) => {
      if (dateRangeName === "All Time") return "All Time";

      const years = range.map((r) => r.getUTCFullYear());
      const months = range.map((r) => r.getUTCMonth());
      // single month
      if (months[0] === months[1] && years[0] === years[1]) {
        return (
          range[0].toLocaleString("default", {
            month: "short",
            timeZone: "UTC",
          }) +
          " " +
          years[0]
        );
      }
      if (years[0] === years[1]) {
        return (
          range[0].toLocaleString("default", {
            month: "short",
            timeZone: "UTC",
          }) +
          " - " +
          range[1].toLocaleString("default", {
            month: "short",
            timeZone: "UTC",
          }) +
          " " +
          years[0]
        );
      }
      return (
        range[0].toLocaleString("default", {
          month: "short",
          timeZone: "UTC",
        }) +
        " " +
        years[0] +
        " - " +
        range[1].toLocaleString("default", {
          month: "short",
          timeZone: "UTC",
        }) +
        " " +
        years[1]
      );
    };

    useEffect(() => {
      if (dateRangeName === "Custom") {
      }
    }, [dateRangeName]);

    const filtersActive =
      [...allStates, ...allDistributors, ...allBrands].length !== 0 &&
      !globalMinimal;
    const exportEnabled =
      exportSupportedPaths.filter((ep) => location.pathname.match(ep)).length >
      0;
    const joggable = /(months?|Custom)$/.test(dateRangeName);
    const showDatePickers = dateRangeName === "Custom";

    const inLastMonth = useMemo(() => {
      return dateRange[1] && latestSale && dateRange[1].getTime() >= latestSale;
    }, [latestSale, dateRange]);

    const [selectFormatOpen, setSelectFormatOpen] = useState(false);
    const [exportLoading, setExportLoading] = useState(false);

    const [datePickerModalOpen, setDatePickerModalOpen] = useState(false);

    const handleFromDateChange = (date) => {
      if (date) {
        const luxonDate = DateTime.fromJSDate(date);
        setEditDateRange([
          luxonDate.startOf("month").toJSDate(),
          editDateRange[1],
        ]);
      }
    };

    const handleToDateChange = (date) => {
      if (date) {
        const luxonDate = DateTime.fromJSDate(date);
        setEditDateRange([
          editDateRange[0],
          luxonDate.endOf("month").toJSDate(),
        ]);
      }
    };

    const prepareCustomDateRange = () => {
      setCustomDateRange(
        editDateRange.map((date) =>
          date instanceof Date ? date : DateTime.fromJSDate(date).toJSDate()
        )
      );
    };

    const emitGlobalExportEvent = (format) => {
      setExportLoading(true);
      if (format === "CSV") {
        const exportEventCSV = new CustomEvent("exportactionCSV", {
          component: "MegaFilterGlobalBar",
        });
        window.dispatchEvent(exportEventCSV);
      } else if (format === "XLSX") {
        const exportEventXLSX = new CustomEvent("exportactionXLSX", {
          component: "MegaFilterGlobalBar",
        });
        window.dispatchEvent(exportEventXLSX);
      } else if (format === "PDF") {
        const exportEventPDF = new CustomEvent("exportactionPDF", {
          component: "MegaFilterGlobalBar",
        });
        window.dispatchEvent(exportEventPDF);
      } else {
        console.log("invalid file format");
        setExportLoading(false);
      }
      window.addEventListener("exportComplete", () => {
        setExportLoading(false);
      });

      setSelectFormatOpen(false);
    };

    const getProductsApi = useApi(productsAPI.getProducts);
    useEffect(() => {
      if (!userInfo.manufacturer_id) return;
      getProductsApi
        .request({ mid: userInfo.manufacturer_id, limit: 1000 })
        .then(({ results }) => setAutoCompleteProducts(results))
        .catch((error) => console.log(error));
    }, [userInfo]);

    const changeDateRange = (value) => {
      console.log("Value", value);
      setDateRangeName(value);
      if (value === "Custom") {
        console.log("Custom date range selected");
        setDatePickerModalOpen(true);
      }
    };

    const toggleFilter = (filterType) => {
      setShowSecondaryFilters((prevFilters) => {
        const newFilters = { products: false, premise: false };
        if (!prevFilters[filterType]) {
          newFilters[filterType] = true; // Open the selected filter
        }
        return newFilters; // Ensures mutual exclusivity
      });
    };

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            padding: 10,
            paddingTop: 20,
            paddingBottom: 20,
            backgroundColor: "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            flexWrap: "wrap",
          }}
        >
          {!globalMinimal && (
            <Grid2 flexDirection={"row"}>
              <Button
                size="small"
                onClick={() => changeDateOffset(-1)}
                startIcon={<ArrowLeft />}
                disabled={!joggable}
              />
              <FormControl>
                <Select
                  size="small"
                  labelId="date-range-select-label"
                  id="date-range-select"
                  value={dateRangeName}
                  onChange={(event) => changeDateRange(event.target.value)}
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenu-list": {
                          display: "flex",
                          flexDirection: "column",
                        },
                      },
                    },
                  }}
                >
                  {dateRangeOptions
                    .filter((opt) => opt.name !== "Custom")
                    .map((opt) => (
                      <MenuItem key={opt.name} value={opt.name}>
                        {opt.name}
                      </MenuItem>
                    ))}
                  <MenuItem
                    sx={{
                      marginTop: "auto",
                      borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                    onClick={() => {
                      setDatePickerModalOpen(true);
                    }}
                    key={"Custom"}
                    value={"Custom"}
                  >
                    Custom
                  </MenuItem>
                </Select>
              </FormControl>

              <Button
                size="small"
                onClick={() => changeDateOffset(1)}
                startIcon={<ArrowRight />}
                disabled={inLastMonth || !joggable}
              />
              <Typography
                variant="overline"
                style={{
                  whiteSpace: "nowrap",
                }}
              >
                &nbsp;{rangeToText(dateRange, dateRangeName)}
              </Typography>
            </Grid2>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              flexWrap: "wrap",
              gap: "10px",
            }}
          >
            <Box
              className="product-selecter-wrapper"
              paddingX={1}
              display={showSecondaryFilters.products ? "block" : "none"}
            >
              <ProductSelecter location={location} />
            </Box>
            {showSecondaryFilters.premise && (
              <Box className="premise-selecter-wrapper" paddingX={1}>
                <PremiseSelecter />
              </Box>
            )}
          </div>
          {filtersActive && (
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "10px",
                justifyContent: "center",
              }}
            >
              <Button
                onClick={() => toggleFilter("products")}
                variant="contained"
                style={styles.button}
                color={showSecondaryFilters.products ? "primary" : "default"}
                size="small"
              >
                {`Products`}
              </Button>
              {(location.pathname.includes("depletions") ||
                location.pathname.includes("home")) && (
                <Button
                  onClick={() => toggleFilter("premise")}
                  variant="contained"
                  style={styles.button}
                  color={showSecondaryFilters.premise ? "primary" : "default"}
                  size="small"
                >
                  {`Premises`}
                </Button>
              )}
              <Divider orientation="vertical" flexItem />
              <Button
                size="small"
                title="Select All"
                onClick={selectAll}
                variant="contained"
                style={styles.button}
              >
                Select All
              </Button>
              <Button
                size="small"
                title="Select None"
                onClick={selectNone}
                variant="contained"
                style={styles.button}
              >
                Select None
              </Button>
              <FormControl
                style={{
                  minWidth: "120px",
                }}
              >
                <InputLabel>Quantity Display</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedQuantity}
                  onChange={changeQuantity}
                  label="Quantity Display"
                  size="small"
                >
                  {quantities
                    .filter((item) => item.value !== quantityFilter)
                    .map((opt) => (
                      <MenuItem key={opt.value} value={opt.value}>
                        {opt.text}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
              {!selectFormatOpen ? (
                <Button
                  size="small"
                  title="Export"
                  startIcon={<CloudDownload />}
                  variant="contained"
                  style={styles.button}
                  onClick={() => setSelectFormatOpen(true)}
                  disabled={exportLoading || !exportEnabled}
                >
                  Export
                  {exportLoading ? <Loader /> : ""}
                </Button>
              ) : (
                <ButtonGroup style={{ marginTop: "20px" }}>
                  <Button size="small" onClick={() => {}}>
                    File Type:
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => emitGlobalExportEvent("CSV")}
                    type="button"
                    disabled={!exportEnabled}
                  >
                    CSV
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => emitGlobalExportEvent("XLSX")}
                    type="button"
                    disabled={!exportEnabled}
                  >
                    XLSX
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    color="primary"
                    onClick={() => emitGlobalExportEvent("PDF")}
                    type="button"
                    disabled={!exportEnabled}
                  >
                    PDF
                  </Button>
                  <Button
                    size="small"
                    variant="outlined"
                    style={{ color: "red", borderColor: "red" }}
                    onClick={() => setSelectFormatOpen(!selectFormatOpen)}
                    type="button"
                    disabled={!exportEnabled}
                  >
                    Cancel
                  </Button>
                </ButtonGroup>
              )}
            </div>
          )}
          {showDatePickers && (
            <DatePickerModal
              open={datePickerModalOpen}
              editDateRange={editDateRange}
              setOpen={setDatePickerModalOpen}
              handleFromDateChange={handleFromDateChange}
              handleToDateChange={handleToDateChange}
              prepareCustomDateRange={prepareCustomDateRange}
            />
          )}
        </div>
      </>
    );
  }
);

export default MegaFilterGlobalBar;
