import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CircularProgress,
  Container,
  Dialog,
  Grid2,
  IconButton,
} from "@mui/material";
import { DataGridPro } from "@mui/x-data-grid-pro";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import useStorage from "../../../hooks/useStorage";
import StorageClient from "../../../storage/client";
import useApi from "../../../hooks/useApi";
import storageAPI from "../../../api/storage";
import FileUploadDetails from "./FileUploadDetails";
import { DateTime } from "luxon";
import DeleteModal from "../../../components/deleteModal/DeleteModal";
import { useStore } from "../../../stores/StoreContext";
import { useParams } from "react-router-dom";
import { makeStyles } from "@mui/styles";
import { Cached, CloudUpload, Delete } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const LogView = () => {
  const { month = false, dist_id = false } = useParams();
  const getDistStorageApi = useApi(storageAPI.getDistStorage);
  const getDistIdsApi = useApi(storageAPI.getDistIds);
  const [open, setOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedFileId, setSelectedFileId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const classes = useStyles();
  const store = useStore();
  const { userInfo } = store;
  const downloadS3Items = useStorage(StorageClient.download);

  const handleClose = () => setOpen(false);

  async function listFiles() {
    getDistStorageApi.request({
      mid: userInfo.mid,
      limit: 1000,
    });
  }

  function downloadFiles(key, identityId) {
    downloadS3Items.download(key, identityId);
  }

  useEffect(() => {
    listFiles();
    trackEvent(userInfo.uid, "PageView:Media");
    getDistributors();
  }, []);

  useState(() => {
    if (month && dist_id) {
      setOpen(true);
    }
  }, []);

  const getDistributors = () => {
    getDistIdsApi.request({ mid: userInfo.mid, limit: 1000 });
  };

  const dateFormat = (isoDateString) =>
    DateTime.fromISO(isoDateString).toFormat("MM/dd/yyyy h:mma");

  // Use DataGridPro column definitions:
  const columns = [
    {
      field: "dist_displayname",
      headerName: "Distributor",
      flex: 1,
      sortable: true,
    },
    {
      field: "file_name",
      headerName: "File Name",
      flex: 1,
      sortable: true,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      sortable: true,
    },
    {
      field: "created",
      headerName: "Last Updated",
      flex: 1,
      sortable: true,
      // Show formatted date in the cell
      valueGetter: (params) => dateFormat(params.row.created),
    },
    {
      field: "action",
      headerName: "Action",
      flex: 0.5,
      sortable: false,
      // Render a delete button
      renderCell: (params) => (
        <IconButton
          aria-label="Delete"
          color="primary"
          onClick={(event) =>
            handleDelete(event, params.row.gg_storage_file_id)
          }
        >
          <Delete />
        </IconButton>
      ),
    },
  ];

  const handleDelete = (event, fileId) => {
    event.preventDefault();
    event.stopPropagation();
    setShowDeleteModal(true);
    setSelectedFileId(fileId);
  };

  const handleConfirmDelete = async () => {
    setIsLoading(true);
    try {
      const response = await storageAPI.deleteStorage(selectedFileId);
      if (response.status < 400) {
        listFiles();
        closeModal();
      }
    } catch (error) {
      console.error("Error deleting file: ", error);
    }
    setIsLoading(false);
  };

  const closeModal = () => {
    setShowDeleteModal(false);
    setIsLoading(false);
  };

  // Download the file on row click
  const handleRowClick = (params) => {
    try {
      downloadFiles(params.row.file_key, params.row.identity_id);
    } catch (error) {
      console.error("Error downloading file: ", error);
    }
  };

  // DataGridPro props
  const pageSize = 50;
  const rowsPerPageOptions = [50, 100, 150];

  // Transform your data so each row has a unique `id`
  const rows = (getDistStorageApi.data || []).map((fileItem) => ({
    ...fileItem,
    id: fileItem.gg_storage_file_id, // DataGridPro requires an id
  }));

  return (
    <Page className={classes.root} title="Depletions">
      <Container maxWidth={false}>
        <Button
          component="span"
          onClick={() => setOpen(true)}
          className={classes.button}
          variant="contained"
          color="primary"
          endIcon={<CloudUpload />}
        >
          Upload
        </Button>
        <Button
          className={classes.button}
          onClick={() => listFiles()}
          variant="contained"
          color="primary"
          endIcon={<Cached />}
        >
          Refresh
        </Button>

        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <Card style={{ height: 600 }}>
              {getDistStorageApi.loading && <CircularProgress />}
              {!getDistStorageApi.loading && rows.length > 0 && (
                <DataGridPro
                  rows={rows}
                  columns={columns}
                  pageSize={pageSize}
                  rowsPerPageOptions={rowsPerPageOptions}
                  onRowClick={handleRowClick}
                  disableSelectionOnClick
                  // disableColumnMenu, filtering, etc. are optional,
                  // depending on how much of DataGridPro's UI you want
                />
              )}
            </Card>
          </Grid2>
        </Grid2>
      </Container>

      <Dialog onClose={handleClose} open={open}>
        <FileUploadDetails
          open={open}
          setOpen={setOpen}
          refreshData={listFiles}
          distributors={getDistIdsApi.data}
          distributorFiles={getDistStorageApi.data}
          month={month}
          dist_id={dist_id}
        />
      </Dialog>

      <DeleteModal
        title="Delete"
        open={showDeleteModal}
        description="Are you sure you want to delete this file?"
        deleteButtonLoading={isLoading}
        handleConfirmDelete={handleConfirmDelete}
        onClose={closeModal}
      />
    </Page>
  );
};

export default LogView;
