// Bottle1.js

import React from "react";

const Bottle1 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M27 0H76V6H73V82.3392L104 104V256H0V104L30 82.3392V6H27V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle2 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M26.4302 0H76.5002V9.5H73.4302V82.3392H104L76.5002 137.5L107 170.5L82.9302 212.5L104 256H0.000175781L43.9302 184L8.50018 149L25.5002 121.5L0.000175781 82.3392H29.4302V10H26.4302V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle3 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M41 0H83V14.5H80V79.5L100.757 105.639C128.445 140.504 129.747 189.475 103.953 225.763L82.4601 256H39.8202L18.8638 226.418C-7.06844 189.812 -5.44966 140.416 22.8236 105.587L44 79.5V14.5H41V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle4 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M25 0H77.5V22H72.8V136H104V256H0V136H30.16V22H25V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle5 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M29.0957 45.2777C33.628 39.1873 39.5975 34.3137 46.4714 31.0915L47.3188 30.6943C50.4312 29.2354 53.6865 28.1032 57.0326 27.3159L68.4144 24.6378C68.8039 24.5461 69.2014 24.4928 69.6012 24.4785L71.7651 24.4012C83.603 23.9785 95.2599 27.398 104.993 34.1487V34.1487C105.33 34.3822 105.64 34.6526 105.916 34.955L111.008 40.5247C112.658 42.3294 114.001 44.3922 114.984 46.6312V46.6312C116.941 51.0886 117.392 56.0624 116.268 60.7989L115.586 63.6732C112.788 75.4645 103.416 84.5691 91.5485 87.0244L89.656 87.416C84.6169 88.4586 79.4075 88.3519 74.4153 87.1038L73.4637 86.8659C71.1595 86.2899 68.9135 85.502 66.7543 84.5124L63.9245 83.2154C60.0062 81.4195 56.4395 78.9395 53.3917 75.8917V75.8917C49.5144 72.0144 46.5669 67.3083 44.7709 62.1275L41 51.25V4H39V0H59V4H57V38.3946C57 41.6201 57.384 44.8341 58.1439 47.9687L59.383 53.0798C60.438 57.4319 62.7402 61.3814 66.0071 64.4441V64.4441C67.9867 66.3001 70.2791 67.7912 72.7783 68.8485L78.5374 71.2851C80.8134 72.248 83.2851 72.6582 85.7502 72.4821L87.4399 72.3614C92.6366 71.9902 97.1164 68.5682 98.8414 63.6521V63.6521C100.201 59.7783 99.6607 55.491 97.3834 52.0751L95.4087 49.113C92.8776 45.3164 89.1361 42.4883 84.7932 41.0889L82.2826 40.28C73.9255 37.5871 64.7871 39.0052 57.6391 44.1041L52.4564 47.8011C40.3902 56.4083 36.3837 72.5174 43.0121 85.7741V85.7741C45.9252 91.6005 50.6495 96.3248 56.4759 99.2379L72 107V107C89.6731 107 104 121.327 104 139V210.8C104 228.031 90.0313 242 72.8 242V242H41.5V242C18.5802 242 0 223.42 0 200.5V148.5C0 125.58 18.5802 107 41.5 107V107L40.6412 106.721C29.838 103.218 21.9591 93.8871 20.3146 82.6497V82.6497C20.1051 81.2185 20 79.7739 20 78.3274V72.7354C20 62.8428 23.1897 53.2138 29.0957 45.2777V45.2777Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle6 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M27 0H76V6H73V82.3392H84C95.0457 82.3392 104 91.2935 104 102.339V236C104 247.046 95.0457 256 84 256H20C8.95431 256 0 247.046 0 236V102.339C0 91.2935 8.95431 82.3392 20 82.3392H30V6H27V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle7 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M25 0H77.5V22H72.8V136H74C90.5685 136 104 149.431 104 166V226C104 242.569 90.5685 256 74 256H30C13.4315 256 0 242.569 0 226V166C0 149.431 13.5915 136 30.16 136V136V22H25V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle8 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M38.43 0H88.5V6.30859H85.43V54.6784H116L128.5 83L112 98.9453L128.5 132L116 170H12L0 132L15.5 98.9453L0 83L12 54.6784H41.43V6.64062H38.43V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle9 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M29.5 0H71.5V14.5H68.5V79.5L102.5 164.5L72.14 274.5H29.5L0 164.5L32.5 79.5V14.5H29.5V0Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle10 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M0 91.4084C0 88.1112 1.85673 85.0951 4.80055 83.6101L44.5475 63.5608C47.4914 62.0758 49.3481 59.0597 49.3481 55.7625V30.5696C49.3481 29.6049 48.566 28.8228 47.6013 28.8228V28.8228C46.6365 28.8228 45.8544 28.0407 45.8544 27.076V20.5253C45.8544 19.5606 46.6365 18.7785 47.6013 18.7785H50.441C50.7583 18.7785 50.9785 18.4626 50.8689 18.1649L49.1875 13.6012C48.4444 11.5842 48.4263 9.37138 49.1365 7.34248V7.34248C50.3302 3.93176 53.3994 1.52736 56.9967 1.18476L67.1131 0.2213C68.6584 0.0741239 70.2145 0.0793219 71.7588 0.23682L81.7304 1.25376C84.6857 1.55516 87.2069 3.52369 88.2159 6.31778V6.31778C88.7825 7.88652 88.8264 9.59643 88.3411 11.1922L86.7547 16.4086C86.6604 16.7186 86.8924 17.0316 87.2164 17.0316H89.5253C90.4901 17.0316 91.2722 17.8137 91.2722 18.7785V25.3291C91.2722 26.2939 90.4901 27.0759 89.5253 27.0759V27.0759C88.5606 27.0759 87.7785 27.858 87.7785 28.8228V55.7202C87.7785 59.0394 89.6599 62.0718 92.6339 63.5458L133.145 83.6251C136.119 85.0991 138 88.1315 138 91.4507V267.266C138 272.09 134.09 276 129.266 276H8.73418C3.91042 276 0 272.09 0 267.266V91.4084Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle11 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M1.5 133.5C-2.38892 105.1 12.1838 77.3637 37.7772 64.4538L40.8908 62.8832C43.0109 61.8138 44.3481 59.6416 44.3481 57.267V30.5696C44.3481 29.6049 43.566 28.8228 42.6013 28.8228V28.8228C41.6365 28.8228 40.8544 28.0407 40.8544 27.0759V20.5253C40.8544 19.5606 41.6365 18.7785 42.6013 18.7785H45.441C45.7583 18.7785 45.9785 18.4626 45.8689 18.1649L44.1875 13.6012C43.4444 11.5842 43.4263 9.37138 44.1365 7.34248V7.34248C45.3302 3.93176 48.3994 1.52736 51.9967 1.18476L62.1131 0.2213C63.6584 0.0741239 65.2145 0.079322 66.7588 0.23682L76.7304 1.25376C79.6857 1.55516 82.2069 3.52369 83.2159 6.31778V6.31778C83.7825 7.88652 83.8264 9.59643 83.3411 11.1922L81.7547 16.4086C81.6604 16.7186 81.8924 17.0316 82.2164 17.0316H84.5253C85.4901 17.0316 86.2722 17.8137 86.2722 18.7785V25.3291C86.2722 26.2939 85.4901 27.076 84.5253 27.076V27.076C83.5606 27.076 82.7785 27.858 82.7785 28.8228V57.3274C82.7785 59.6622 84.1019 61.7952 86.1938 62.832L89.8695 64.6539C115.478 77.3469 129.75 105.316 125 133.5V133.5L110.823 268.18C110.355 272.625 106.607 276 102.137 276H23.4181C18.9251 276 15.1651 272.591 14.7258 268.12L1.5 133.5V133.5Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle12 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M26.5731 9.95279C26.709 6.25801 29.2951 3.1087 32.8929 2.2566L40.4738 0.461112C41.7531 0.158128 43.0864 0.166408 44.3618 0.48526L51.1175 2.17418C54.7173 3.07414 57.2678 6.27429 57.342 9.98415L58.0044 43.1065C58.0257 44.1682 57.5346 45.1754 56.685 45.8125V45.8125C55.5333 46.6763 55.0745 48.1871 55.5512 49.5455L83.6602 129.637C84.3837 131.698 84.7738 133.862 84.8159 136.047L85.9854 196.863V279.061C85.9854 288.14 78.6252 295.5 69.5459 295.5H16.9395C7.86022 295.5 0.5 288.14 0.5 279.061V196.863L1.67316 135.077C1.7128 132.989 2.07034 130.92 2.73346 128.94L29.1063 50.2038C29.6727 48.5128 28.9192 46.6616 27.333 45.8467V45.8467C26.0869 45.2066 25.3237 43.9033 25.3752 42.5033L26.5731 9.95279Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle13 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M8.29171 216.968C-9.91726 173.052 5.30961 122.388 44.7119 95.7864L63.8153 82.8893C66.0484 81.3817 67.4607 78.9278 67.6425 76.2396L70.7263 30.6245C70.7922 29.6497 70.0193 28.8228 69.0423 28.8228V28.8228C68.1101 28.8228 67.3544 28.0671 67.3544 27.1349V20.5253C67.3544 19.5606 68.1365 18.7785 69.1013 18.7785H71.941C72.2583 18.7785 72.4786 18.4626 72.3689 18.1649L70.6875 13.6012C69.9444 11.5842 69.9264 9.37138 70.6365 7.34248V7.34248C71.8302 3.93176 74.8994 1.52736 78.4967 1.18476L88.6131 0.2213C90.1584 0.0741239 91.7145 0.0793219 93.2588 0.23682L103.23 1.25376C106.186 1.55516 108.707 3.5237 109.716 6.31779V6.31779C110.282 7.88652 110.326 9.59642 109.841 11.1922L108.255 16.4086C108.16 16.7186 108.392 17.0316 108.716 17.0316H111.025C111.99 17.0316 112.772 17.8137 112.772 18.7785V25.3862C112.772 26.3194 112.016 27.076 111.082 27.076V27.076C110.106 27.076 109.332 27.9015 109.396 28.876L112.482 76.07C112.665 78.8538 114.165 81.3832 116.522 82.8769L136.441 95.5039C178.171 121.957 194.327 174.922 174.465 220.163L157.293 259.277C155.9 262.451 152.762 264.5 149.296 264.5H33.8337C30.3022 264.5 27.1182 262.373 25.7656 259.111L8.29171 216.968Z"
      fill={fillColor}
    />
  </svg>
);

const Bottle14 = ({ width = 104, height = 256, fillColor = "#7C7C7C" }) => (
  <svg width={width} height={height} viewBox="0 0 104 256">
    <path
      d="M5.64052 57.5641C5.71077 54.5681 7.82889 52.0124 10.7597 51.3871L11.0895 51.3168C15.045 50.4729 18.333 47.7384 19.8836 44.0028V44.0028C20.504 42.5081 20.8234 40.9056 20.8234 39.2872V4.96323C20.8234 3.49784 21.8477 2.23183 23.2808 1.92589L28.2657 0.861686C32.4721 -0.0363108 36.8192 -0.0508032 41.0315 0.819128L46.4531 1.93881C47.8959 2.23676 48.9308 3.50725 48.9308 4.98041V39.0519C48.9308 40.8227 49.3086 42.5731 50.0391 44.1862V44.1862C51.684 47.8188 54.9699 50.4433 58.8763 51.2446L59.487 51.3699C62.585 52.0054 64.8423 54.6833 64.9445 57.8441L72.4828 291.088C72.5961 294.595 69.7837 297.5 66.2745 297.5H6.37358C2.88602 297.5 0.0819559 294.63 0.163708 291.143L5.64052 57.5641Z"
      fill={fillColor}
    />
  </svg>
);

export const bottles = [
  Bottle1,
  Bottle2,
  Bottle3,
  Bottle4,
  Bottle5,
  Bottle6,
  Bottle7,
  Bottle8,
  Bottle9,
  Bottle10,
  Bottle11,
  Bottle12,
  Bottle13,
  Bottle14,
];
