import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useParams } from "react-router-dom";
import { DateTime } from "luxon";
import {
  CardHeader,
  Typography,
  Card,
  Grid2,
  List,
  ListItem,
  ListItemText,
  CardContent,
  Switch,
  Tooltip,
  Paper,
  Box,
} from "@mui/material";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { useStore } from "../../../stores/StoreContext";
import { DefaultAccountBottleById } from "../../product/DefaultBottleSVG/getDefaultProductSVG";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      width: theme.spacing(7),
      height: theme.spacing(7),
    },
  },
  statbox: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
  avatar: {
    height: 100,
    width: 100,
  },
  statcontent: {
    height: "100%",
  },
  listItemText: {
    fontSize: "1.2em",
  },
  typography: {
    textAlign: "center",
  },
  list: {
    maxHeight: "70%",
    overflow: "auto",
  },
  productName: {
    overflow: "hidden",
    textOverflow: "ellipsis",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    fontSize: "0.9em",
    textAlign: "center",
    marginTop: "5px",
  },
}));

const CustomTooltipContent = ({ product }) => (
  <Paper sx={{ p: 2, maxWidth: 250 }}>
    <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
      {product.name}
    </Typography>
    <Typography variant="body2">Total Sold: {product.sold} Cases</Typography>
  </Paper>
);

const AccountMetrics = ({ className, checkin, setCheckin, ...rest }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [products, setProducts] = useState([]);
  const [lastSale, setLastSale] = useState("");
  const [checkinList, setCheckinList] = useState([]);
  const [isToggle, setIsToggle] = useState(true);
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    getAccountMetrics();
  }, []);

  useEffect(() => {
    getLastCheckin();
  }, [checkin]);

  async function getAccountMetrics() {
    try {
      const data = await API.get("backendGateway", "/accounts/stats/", {
        queryStringParameters: {
          mid: userInfo.mid,
          id: id,
        },
      });
      createProducts(data.results);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }

  async function getLastCheckin() {
    try {
      const data = await API.get("backendGateway", "/accounts/checkin", {
        queryStringParameters: {
          mid: userInfo.mid,
          id: id,
        },
      });
      if (data.results[0]?.checkin_date) {
        setCheckin(
          DateTime.fromISO(data.results[0]?.checkin_date).toFormat(
            "dd MMM yyyy"
          )
        );
        setCheckinList(
          data.results.sort((a, b) => {
            return a.checkin_date > b.checkin_date
              ? -1
              : a.checkin_date < b.checkin_date
              ? 1
              : 0;
          })
        );
      } else {
        setCheckin("No Checkins");
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }

  function createProducts(data) {
    let dates = new Array();
    let _data = data.map((item) => {
      dates.push(item.account_sales_date);
      return {
        ...item,
        image_src: awsS3Image(
          item.product_image,
          item.product_imagekey,
          "0x100"
        ),
      };
    });
    dates.sort((a, b) => new Date(b) - new Date(a));
    if (dates.length > 0) {
      setLastSale(DateTime.fromISO(dates[0]).toFormat("dd MMM yyyy"));
    }
    _data.sort((a, b) => b.PHYSICAL_SOLD - a.PHYSICAL_SOLD);
    setProducts(_data);
  }

  return (
    <>
      <Grid2 container spacing={3}>
        <Grid2 size={6}>
          <Card className={classes.statcontent}>
            <CardHeader title={"Products"} className="st-card-header" />
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
              }}
            >
              <Typography>Toggle</Typography>
              <Switch
                checked={isToggle}
                onChange={() => setIsToggle(!isToggle)}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </div>
            <CardContent>
              {!isToggle && (
                <Grid2 container spacing={3}>
                  <Grid2 xs={6}>
                    <Typography style={{ flex: 1, fontWeight: "bold" }}>
                      Brand
                    </Typography>
                  </Grid2>
                  <Grid2 xs={6}>
                    <Typography style={{ flex: 1, fontWeight: "bold" }}>
                      Product Name
                    </Typography>
                  </Grid2>
                </Grid2>
              )}

              {isToggle ? (
                <div
                  style={{
                    display: "grid",
                    gridTemplateColumns: "repeat(3, 1fr)",
                    gap: "10px",
                    maxHeight: 350,
                    overflowY: "auto",
                  }}
                >
                  {products.map((prod, index) => (
                    <Tooltip
                      key={prod.product_id}
                      slotProps={{
                        tooltip: {
                          sx: {
                            color: "#514E6A",
                            backgroundColor: "transparent",
                          },
                        },
                      }}
                      title={
                        <CustomTooltipContent
                          product={{
                            name: prod.product_displayname,
                            sold: prod.PHYSICAL_SOLD,
                          }}
                        />
                      }
                      arrow
                      followCursor
                      placement="top"
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                      >
                        {prod.image_src !== "" ? (
                          <img
                            src={prod.image_src}
                            style={{
                              height: "auto",
                            }}
                            width={40}
                            height={40}
                            alt=""
                          />
                        ) : (
                          <>
                            {prod?.product_id ? (
                              <DefaultAccountBottleById
                                product_id={prod?.product_id}
                                width={40}
                                height={40}
                              />
                            ) : null}
                          </>
                        )}
                        <Typography className={classes.productName}>
                          {prod.product_displayname}
                        </Typography>
                      </Box>
                    </Tooltip>
                  ))}
                </div>
              ) : (
                <Grid2
                  style={{
                    maxHeight: 350,
                    overflowY: "auto",
                  }}
                >
                  {products.map((prod, index) => (
                    <Grid2
                      style={{
                        display: "flex",
                        flexDirection: "column",
                      }}
                    >
                      <Grid2 container spacing={3}>
                        <Grid2 xs={6}>
                          <Typography style={{ flex: 1 }}>
                            {prod.product_brand || ""}
                          </Typography>
                        </Grid2>
                        <Grid2 xs={6}>
                          <Typography style={{ flex: 1 }}>
                            {prod.product_displayname}
                          </Typography>
                        </Grid2>
                      </Grid2>
                    </Grid2>
                  ))}
                </Grid2>
              )}
            </CardContent>
          </Card>
        </Grid2>

        <Grid2 size={6}>
          <Card className={classes.statcontent}>
            <CardHeader title={"Account Orders"} className="st-card-header" />
            <CardContent style={{ maxHeight: 350, overflowY: "auto" }}>
              <List className={classes.list}>
                {products.map((prod) => (
                  <ListItem
                    key={prod.product_id}
                    style={{
                      height: "auto",
                      padding: 0,
                    }}
                  >
                    <ListItemText
                      primary={`${
                        prod.product_displayname +
                        " " +
                        prod.product_size +
                        " (" +
                        prod.PHYSICAL_SOLD +
                        " Cases) "
                      }`}
                      style={{
                        padding: "2px",
                        height: "auto",
                        margin: 0,
                      }}
                    />
                  </ListItem>
                ))}
              </List>
            </CardContent>
          </Card>
        </Grid2>
      </Grid2>
    </>
  );
};

AccountMetrics.propTypes = {
  className: PropTypes.string,
};

export default AccountMetrics;
