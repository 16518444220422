import React, { useState, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { autorun } from "mobx";

import RegionCompareUI from "./RegionCompareUI";

const RegionCompare = observer(
  ({
    selectedDistributors,
    selectedBrands,
    selectedStates,
    completeDistBrandData,
    allBrands,
    allDistributors,
  }) => {
    const [filteredData, setFilteredData] = useState([]);
    const funnel = ["states", "distributors", "brands"];

    useEffect(() => {
      autorun(() => {
        const propMap = {
          states: {
            prop_name: "state",
            filterList: selectedStates,
          },
          distributors: {
            prop_name: "dist_displayname",
            filterList: selectedDistributors,
          },
          brands: {
            prop_name: "product_brand",
            filterList: selectedBrands,
          },
        };
        let filt = [...completeDistBrandData];
        funnel.forEach((val) => {
          filt = filt.filter((f) =>
            propMap[val].filterList.includes(f[propMap[val].prop_name])
          );
        });
        setFilteredData(filt);
      });
    }, [
      selectedDistributors,
      selectedBrands,
      selectedStates,
      completeDistBrandData.length,
    ]);

    return (
      <div
        data-io={
          [...selectedBrands, ...selectedDistributors, ...selectedStates].length
        }
      >
        <RegionCompareUI
          data={completeDistBrandData}
          filteredData={filteredData}
          selectedStates={selectedStates}
          selectedBrands={selectedBrands}
          allBrands={allBrands}
          allDistributors={allDistributors}
          selectedDistributors={selectedDistributors}
          completeDistBrandData={completeDistBrandData}
          regions
        />
      </div>
    );
  }
);

export default RegionCompare;
