import React, { useState } from "react";
import {
  DataGridPremium,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { API } from "aws-amplify";
import { Box, Paper, Typography } from "@mui/material";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react-lite";
import { DateTime } from "luxon";
import FilterMenu from "../menu/FilterMenu";
import Page from "../../../components/Page";
const AccountsSoldReport = observer(() => {
  const { allDistributors, allBrands, allProducts, premiseTypes } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  // State for filters
  const [filters, setFilters] = useState({
    distributorId: [],
    selectedBrands: [],
    dateRange: [
      DateTime.now().minus({ days: 30 }).toISODate(),
      DateTime.now().toISODate(),
    ],
    month: DateTime.now().toFormat("MM"),
    year: DateTime.now().toFormat("yyyy"),
  });

  // Fetch report data from API
  const fetchReportData = async (appliedFilters) => {
    console.log(appliedFilters);
    const {
      distributorId,
      selectedBrands,
      dateRange,
      premiseTypes,
      selectedProducts,
    } = appliedFilters;

    setLoading(true);
    try {
      const response = await API.get(
        "backendGateway",
        "/stats/accounts-sold-report",
        {
          queryStringParameters: {
            distributorIds: distributorId,
            productIds: selectedProducts,
            productBrands: selectedBrands,
            premiseTypes,
            startDate: dateRange[0],
            endDate: dateRange[1],
          },
        }
      );
      setData(response.data || []);
    } catch (error) {
      console.error("Error fetching report data:", error);
      setData([]);
    } finally {
      setLoading(false);
    }
  };

  const handleApplyFilters = (appliedFilters) => {
    setFilters(appliedFilters); // Update filters state
    fetchReportData(appliedFilters); // Fetch data with applied filters
  };

  const handleResetFilters = () => {
    const resetFilters = {
      distributorId: [],
      selectedBrands: [],
      dateRange: [
        DateTime.now().minus({ days: 120 }).toISODate(),
        DateTime.now().toISODate(),
      ],
      month: DateTime.now().toFormat("MM"),
      year: DateTime.now().toFormat("yyyy"),
    };
    setFilters(resetFilters); // Reset filters state
    setData([]); // Clear data without fetching
  };
  const columns = [
    { field: "acc_name", headerName: "Account Name", minWidth: 200, flex: 1 },
    { field: "premise", headerName: "Premise Type", minWidth: 150, flex: 0.8 },
    { field: "dist_name", headerName: "Distributor", minWidth: 200, flex: 1 },
    {
      field: "first_sales_date",
      headerName: "First Sales Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "latest_sales_date",
      headerName: "Latest Sales Date",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "lifetime_product_orders",
      headerName: "Lifetime Orders",
      minWidth: 150,
      flex: 0.8,
    },
    {
      field: "lifetime_pods",
      headerName: "Lifetime PODs",
      minWidth: 150,
      flex: 0.8,
    },
    {
      field: "pods_in_date_range",
      headerName: "PODs in Selected Month",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "total_units_sold_lifetime",
      headerName: "Total Case Eqv. Sold (Lifetime)",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "total_units_sold_date_range",
      headerName: "Case Eqv. Sold (Selected Month)",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "customer_lifetime_days",
      headerName: "Customer Lifetime (Days)",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "orders_per_month",
      headerName: "Orders Per Month",
      minWidth: 150,
      flex: 0.8,
    },
    {
      field: "days_since_last_order",
      headerName: "Days Since Last Order",
      minWidth: 180,
      flex: 1,
    },
    {
      field: "average_units_per_order",
      headerName: "Avg Case Eqv. per Order",
      minWidth: 150,
      flex: 0.8,
    },
    {
      field: "top_product_by_volume",
      headerName: "Top Product by Volume",
      minWidth: 200,
      flex: 1,
    },
  ];
  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        excelOptions={{
          fileName: "AccountSoldReport",
        }}
      />
    </GridToolbarContainer>
  );
  return (
    <Page title="Report Account">
      <Box
        component={Paper}
        elevation={3}
        p={3}
        m={3}
        borderRadius={8}
        maxWidth="100%"
        sx={{
          overflow: "hidden",
          padding: 4,
          backgroundColor: "#f9f9f9",
          boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
        }}
      >
        <Typography variant="h4" gutterBottom textAlign="left">
          Monthly Account Sold Report
        </Typography>

        {/* Filter Menu */}
        <FilterMenu
          distributors={allDistributors}
          brands={allBrands}
          products={allProducts}
          onApplyFilters={handleApplyFilters}
          onResetFilters={handleResetFilters}
          showUnitOfMeasure={false}
          showDates={false}
          showAlternativeDate={true}
          premiseTypes={premiseTypes}
        />

        {/* Data Grid2 */}
        <Box style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={data}
            columns={columns}
            loading={loading}
            pageSize={10}
            getRowId={(row) => row.acc_id}
            slots={{
              toolbar: CustomToolbar,
            }}
            getRowClassName={(params) =>
              params.row.first_sales_date === params.row.latest_sales_date
                ? "new-account"
                : ""
            }
            sx={{
              "& .new-account": {
                backgroundColor: "#d3f9d8", // Light green for new account
              },
              "& .MuiDataGrid-virtualScrollerContent": { overflowX: "auto" },
            }}
            initialState={{
              sorting: {
                sortModel: [{ field: "first_sales_date", sort: "asc" }],
              },
            }}
          />
        </Box>
      </Box>
    </Page>
  );
});

export default AccountsSoldReport;
