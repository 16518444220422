import { Parser } from "json2csv";
import { v4 as uuid } from "uuid";
import { fieldTypes } from "./fieldTypes";
import ExcelJS from "exceljs";
import logo from "../../../components/logo.png";
export const normaliseFields = (fields, types) => {
  const objectMatch = {
    Brand: {
      ...(types.find((f) => f.element === "DatabaseOption") || {}),
      variant: "brands",
    },
    Account: {
      ...(types.find((f) => f.element === "Account") || {}),
    },
    Product: {
      ...(types.find((f) => f.element === "DatabaseOption") || {}),
      variant: "products",
    },
    Distributor: {
      ...(types.find((f) => f.element === "DatabaseOption") || {}),
      variant: "distributors",
    },
    DistributorContact: {
      ...(types.find((f) => f.element === "Contact") || {}),
      variant: "distributor",
    },
    EmailInput: {
      ...(types.find((f) => f.element === "TextInput") || {}),
      variant: "email",
    },
  };
  const standardElements = types.map((f) => f.element);
  return fields.map((f) => {
    const { id, field_name } = f;
    if (standardElements.includes[f.element]) {
      return f;
    }
    if (f.key && objectMatch[f.key])
      return {
        ...f,
        ...objectMatch[f.key],
        label: f.label,
        required: f.required,
        id,
        field_name,
      };
    if (f.element === "EmailInput") {
      return {
        ...(types.find((f) => f.element === "TextInput") || {}),
        variant: "email",
        label: f.label,
        required: f.required,
        id,
        field_name,
      };
    }
    if (f.element === "PhoneNumber") {
      return {
        ...(types.find((f) => f.element === "TextInput") || {}),
        variant: "phone",
        label: f.label,
        required: f.required,
        id,
        field_name,
      };
    }
    return f;
  });
};

export const normaliseAnswers = (answers) => {
  return answers.map((a) => {
    if (/^{"/.test(a.value)) {
      const obj = JSON.parse(a.value);
      const { text, id } = obj;
      return {
        ...a,
        value: id ? id : text,
        textValue: text,
      };
    } else {
      return a;
    }
  });
};
export const answerToText = (answer, questions, config) => {
  if (!answer || (Array.isArray(answer.value) && answer.value.length === 0)) {
    return "";
  }
  const question = questions.find((q) => q.field_name === answer.name);
  if (answer.textValue && (!config?.options || question.options.length === 1)) {
    if (Array.isArray(answer.textValue)) {
      return answer.textValue.join(", ");
    }
    return answer.textValue;
  }
  if (answer.value) {
    // if it's an old style complex one
    if (typeof answer.value === "string" && answer.value.match(/(^{"|}"$)/)) {
      const parsed = JSON.parse(answer.value);
      if (parsed?.text) {
        return parsed.text;
      }
    }
  }
  // for legacy responses, map value to options in question
  if (question && Array.isArray(answer.value)) {
    const answerTextArray = question.options
      .filter(
        (o) =>
          answer.value.includes(o.key) &&
          (!config?.options || config.options.includes(o.key))
      )
      .map((ot) => ot.text);
    return answerTextArray.join(", ");
  }
  // format old us dates to YYYY-MM-DD
  if (
    question &&
    question.element === "DatePicker" &&
    question.dateFormat === "MM/dd/yyyy"
  ) {
    const [month, day, year] = answer.value.split("/");
    if (year && month && day) {
      return [year, month, day].join("-");
    }
  }
  if (question && question.element === "Dropdown") {
    const match = question.options.find((q) => q.value === answer.value);
    if (match) {
      return match.text;
    }
  }
  return answer.value;
};
const processLine = (item) => {
  const { questions, answers } = item;
  const cgQuestions = questions
    .map((q) => {
      if (q.conditionalGroups) {
        const qpbg = prebakeRepeatingGroups(q);
        return qpbg.map((cg) => cg.fields).flat();
      }
      return [];
    })
    .flat();
  // questions.concat(cgQuestions)
  // console.log(cgQuestions)
  // console.log(questions)

  const cg = questions.concat(cgQuestions);
  const response = cg.reduce((acc, cur) => {
    const answer = answers.find((a) => a.name === cur.field_name);
    const pl = answer?.parentLabel || cur?.parentLabel;
    const propName = (pl ? pl + " / " : "") + cur.label;
    return {
      ...acc,
      [propName]: answerToText(answer, questions),
    };
  }, {});
  return {
    ...response,
    form_id: item.form_id,
    response_id: item.response_id,
    user_email: item.user.user_email,
    user_name: item.user.user_displayname,
    account_name: item.account_displayname,
    distributor: item.dist_displayname || "",
    address: item.account_address,
  };
};

export const exportResponses = async ({
  responses,
  forms,
  form,
  format = "XLSX",
  userList,
}) => {
  const userObj = userList.reduce((acc, cur) => {
    acc[cur.user_id] = cur;
    return acc;
  }, {});
  const { form_uuid } = form;
  const combined = responses.map((r) => ({
    ...r,
    answers: JSON.parse(r.response_body).values,
    questions: JSON.parse(forms[r.form_id].form_body),
    user: userObj[r.user_id] || {
      user_email: null,
      user_displayname: null,
    },
  }));
  let lines = combined.map((item) => processLine(item));

  if (lines.length > 0) {
    if (format === "XLSX") {
      // Create a new workbook and worksheet
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("Responses");

      // Define title row styles
      const titleRowStyle = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "142F72" },
        },
        font: { color: { argb: "FFFFFF" }, bold: true },
      };

      // Define alternating row styles
      const alternateRowStyle1 = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "CAD6FF" },
        },
        font: { color: { argb: "000000" } },
      };
      const alternateRowStyle2 = {
        fill: {
          type: "pattern",
          pattern: "solid",
          fgColor: { argb: "FFFFFF" },
        },
        font: { color: { argb: "000000" } },
      };

      // Add header row
      const headerRow = worksheet.addRow(Object.keys(lines[0]));
      headerRow.eachCell((cell) => {
        cell.style = titleRowStyle;
      });

      // Add data rows
      lines.forEach((data, rowIndex) => {
        const row = worksheet.addRow(Object.values(data));
        row.eachCell((cell) => {
          // Alternate row background color
          cell.style =
            rowIndex % 2 === 0 ? alternateRowStyle1 : alternateRowStyle2;
        });
      });

      // Add the logo image
      const response = await fetch(logo);
      const blob = await response.blob();
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result.split(",")[1];
        const imageId = workbook.addImage({
          base64: base64data,
          extension: "png",
        });
        worksheet.addImage(imageId, {
          tl: { col: 0, row: worksheet.lastRow.number + 1 },
          ext: { width: 169.54, height: 50 },
        });

        // Generate Excel file
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        const url = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = url;
        downloadLink.download = `form-${form_uuid}_responses_${new Date()
          .toISOString()
          .slice(0, 10)}.xlsx`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      };
      reader.readAsDataURL(blob);
    }
  }
};
export class optionMaker {
  constructor(string) {
    this.text = string;
    this.value = string;
    this.key = uuid();
    this.children = [];
  }
}
export class rowMaker {
  constructor(string) {
    this.text = string;
    this.value = string;
    this.key = uuid();
  }
}

export class v3Field {
  constructor(value, label, element, prefix) {
    this.field_type = value;
    this.value = value;
    this.label = label;
    this.decimal = true;
    this.options = [new optionMaker("option a")];
    this.rows = [new rowMaker("row a")];
    this.required = false;
    this.id = uuid();
    // FB2 compatibility
    this.element = element;
    this.canHaveAnswer = true;
    this.canHaveOptionValue = true;
    this.field_name = prefix + uuid();
    if (this.value === "datetime") {
      this.dateFormat = "MM/dd/yyyy";
      this.timeFormat = "hh:mm aa";
    }
  }
}
const { value, label, element, prefix } = fieldTypes[0];
export const defaultField = () => new v3Field(value, label, element, prefix);

export const condtionalRuleResults = (answer, group, options) => {
  const answerType = typeof answer.value;
  const av = answer.value;
  // console.log(av, options, group.rule, group)
  // console.log(Array.isArray(answer.value) ,answer.value.length, options.length)
  return {
    truthy:
      (answerType === "string" && av.trim().length > 0) ||
      (Array.isArray(answer.value) &&
        options.some((o) => answer.value.includes(o.key))),
    falsey: answerType === "string" && av.trim().length === 0,
    always: true,
    forOne:
      group.rule === "forOne" &&
      ((Array.isArray(answer.value) &&
        answer.value.some((v) => group.parentOptions.includes(v))) ||
        (typeof answer.value === "string" &&
          options.find((o) => group.parentOptions.includes(o.key))?.value ===
            answer.value)),
    forOneNot:
      group.rule === "forOneNot" &&
      (!answer?.value ||
        (Array.isArray(answer.value) &&
          !answer.value.some((v) => group.parentOptions.includes(v))) ||
        (typeof answer.value === "string" &&
          !(
            options.find((o) => group.parentOptions.includes(o.key)).value ===
            answer.value
          ))),
  };
};

export const prebakeRepeatingGroups = (field) => {
  const { conditionalGroups: groups, options } = field;
  const newGroups = [];
  const rootLabel = field.label;
  // 1. loop through groups and get those with repeating sets
  const repeaterGroups = groups.filter(
    (g) => g.rule === "forEachSelected" || g.rule === "forEachNotSelected"
  );
  const nonRepeaterGroups = groups.filter(
    (g) => g.rule !== "forEachSelected" && g.rule !== "forEachNotSelected"
  );
  // 2. for every option in a repeating set make a new group which is a forSelected, forNotSelected group
  repeaterGroups.forEach((rg, rgIndex) => {
    options.forEach((o, oIndex) => {
      const ng = {
        ...rg,
        parentOptions: [o.key],
        id: rg.id + "_" + rgIndex + "_" + oIndex,
        originalId: rg.id,
        rule: rg.rule === "forEachSelected" ? "forOne" : "forOneNot",
        isGenerated: true,
        awaitingCheck: true,
        fields: rg.fields.map((ngf, ngfIndex) => {
          const field_id = [rg.id, rgIndex, oIndex, ngfIndex].join("_");
          const field_name = [ngf.field_name, field_id].join("_");
          return {
            ...ngf,
            originalId: ngf.id,
            id: field_id,
            field_name,
            parentLabel: [rootLabel, o.text].join(" / "),
            options: ngf.options.map((op, opIndex) => ({
              ...op,
              key: [field_id, opIndex].join("_"),
            })),
          };
        }),
      };
      if (
        !groups.find(
          (g) =>
            g.originalId === rg.originalId &&
            JSON.stringify(g.parentOptions) === JSON.stringify(rg.options)
        )
      ) {
        newGroups.push(ng);
      }
    });
  });
  // 3. for each such group that has dependant groups recursively make new groups matching the groups made at 2
  const rgChildFieldIds = repeaterGroups
    .map((rg) => rg.fields.map((rgf) => rgf.id))
    .flat();
  const rgChildren = groups.filter((g) =>
    rgChildFieldIds.includes(g.parentFieldId)
  );
  const newChildGroups = [];
  newGroups.forEach((ng) => {
    const fids = ng.fields.map((f) => f.originalId);
    const childrenToClone = rgChildren.filter((rgc) =>
      fids.includes(rgc.parentFieldId)
    );
    childrenToClone.forEach((ctc, ctcIndex) => {
      const ngc = {
        ...ctc,
        id: ng.id + "_" + ctcIndex,
        fields: ctc.fields.map((ctcf, ctcfIndex) => {
          const genId = [ng.id, ctcIndex, ctcfIndex].join("_");
          return {
            ...ctcf,
            id: genId,
            field_name: [genId, ctcf.field_name].join("_"),
            parentLabel: rootLabel,
            options: ctcf.options.map((op, opIndex) => ({
              ...op,
              key: [genId, opIndex].join("_"),
            })),
          };
        }),
        parentFieldId: ng.fields.find(
          (ngf) => ngf.originalId === ctc.parentFieldId
        ).id,
      };
      newChildGroups.push(ngc);
    });
  });
  // 4. append to groups

  const summedGroups = [...nonRepeaterGroups, ...newGroups, ...newChildGroups];
  if (summedGroups.length !== groups.length) {
    // pass it through again to see if there are any new repeating groups to be processed
    return prebakeRepeatingGroups({
      ...field,
      conditionalGroups: summedGroups,
    });
  }

  return summedGroups;
};
