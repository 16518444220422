import APICallWrapper from "./APICallWrappers";
import client from "./client";

const getProducts = async ({ mid, limit }) => {
  const key = `getProducts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading products...",
    "Products loaded successfully.",
    "Failed to load products.",
    () => client.get("/products", { mid: mid, limit: limit })
  );
};

const getProductsPID = async ({ pid, mid, limit }) => {
  const key = `getProductsPID-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading product details...",
    "Product details loaded successfully.",
    "Failed to load product details.",
    () => client.get(`/products/${pid}`, { mid: mid, limit: limit })
  );
};

const getProductsAllStates = async ({ mid, limit }) => {
  const key = `getProductsAllStates-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading all product states...",
    "All product states loaded successfully.",
    "Failed to load all product states.",
    () => client.get("/products/allstates", { mid: mid, limit: limit })
  );
};

const getProductsSku = async ({ pid, mid, limit }) => {
  const key = `getProductsSku-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading product SKU...",
    "Product SKU loaded successfully.",
    "Failed to load product SKU.",
    () => client.get("/products/sku", { pid: pid, mid: mid, limit: limit })
  );
};

const putProductsSku = async (product) => {
  const key = `putProductsSku-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating product SKU...",
    "Product SKU updated successfully.",
    "Failed to update product SKU.",
    () => client.put("/products/sku", product)
  );
};

const postProductsSku = async (product) => {
  const key = `postProductsSku-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Creating product SKU...",
    "Product SKU created successfully.",
    "Failed to create product SKU.",
    () => client.post("/products/sku", product)
  );
};

const postProductsBrandDeactivate = async (deactivateBrand) => {
  const key = `postProductsBrandDeactivate-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating Brands...",
    "Brand Updated successfully.",
    "Failed to update Brand",
    () => client.post("/products/deactivate/brand", deactivateBrand)
  );
};

const postProductsDeactivate = async (deactivateProduct) => {
  const key = `postProductsDeactivate-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating Product...",
    "Product Updated successfully.",
    "Failed to update Product",
    () => client.post("/products/deactivate/product", deactivateProduct)
  );
};

const reportBuilderBrandsAndProducts = async () => {
  return await client.get("/products/reportBuilderBrands");
};

export default {
  getProducts,
  getProductsPID,
  getProductsAllStates,
  getProductsSku,
  putProductsSku,
  postProductsBrandDeactivate,
  postProductsDeactivate,
  postProductsSku,
  reportBuilderBrandsAndProducts,
};
