import React from "react";
import { Box, Typography } from "@mui/material";
import { DataGridPremium, GridToolbar } from "@mui/x-data-grid-premium";
import { SAMPLE_DATA } from "../sample_preview_table_data";
import {
  getPreviewTableRowClassName,
  getPreviewTableCellClassName,
} from "../../../reports/ReportsView/helpers";

/**
 * Helper function to determine if a field is a numeric/cases field
 */
const isCaseField = (field) => {
  return /(cases|units|cases_eqv)_(\d){4,6}/i.test(field);
};

/**
 * Returns a random integer between min and max, inclusive.
 */
const getRandomInt = (min, max) => {
  const _min = Math.ceil(min);
  const _max = Math.floor(max);
  return Math.floor(Math.random() * (_max - _min + 1)) + _min;
};

const getGrandTotalRow = (requestBody) => {
  return [
    {
      id: "grandTotal",
      [requestBody.select.dimensions[0]]: "Grand Total",
      totals: 1479,
    },
  ];
};

const createHierarchicalData = (data, groupByFields) => {
  // If no select.dimensions fields, return "detail" rows only, each with a random totals
  if (!groupByFields?.length) {
    return data.map((row) => ({
      ...row,
      id: `detail_${row.id}`,
      totals: getRandomInt(-3, 20),
    }));
  }

  const result = [];
  const grandTotal = { id: "grandTotal", totals: 0 };
  result.push(grandTotal);

  const currentGroup = {};

  // First pass: create detail rows and their random totals (once per original row)
  const detailRows = data.map((originalRow) => {
    const detailTotals = getRandomInt(-3, 20);
    return {
      ...originalRow,
      id: `detail_${originalRow.id}`,
      totals: detailTotals,
    };
  });

  // Second pass: add group rows and accumulate totals
  detailRows.forEach((detailRow) => {
    // Grand total accumulation
    grandTotal.totals += detailRow.totals;

    // For each grouping level, accumulate the detailTotals
    groupByFields.forEach((field, index) => {
      const groupId = groupByFields
        .slice(0, index + 1)
        .map((f) => detailRow[f])
        .join("_");

      if (!currentGroup[groupId]) {
        currentGroup[groupId] = {
          id: groupId,
          totals: 0,
          ...groupByFields.slice(0, index + 1).reduce((acc, f) => {
            acc[f] = detailRow[f];
            return acc;
          }, {}),
        };
        result.push(currentGroup[groupId]);
      }
      currentGroup[groupId].totals += detailRow.totals;
    });
  });

  // Finally, add all detail rows
  result.push(...detailRows);

  return result;
};

const removeDetailRows = (rows) => {
  return rows.filter((row) => !String(row.id).startsWith("detail_"));
};

const PreviewTable = ({ selectedFields, requestBody }) => {
  // 1) Sort sample data according to select.dimensions (if any)
  const sortedSampleData = createHierarchicalData(
    [...SAMPLE_DATA].sort((a, b) => {
      if (!requestBody?.select.dimensions) return 0;
      for (const field of requestBody.select.dimensions) {
        // number comparison vs string comparison
        if (typeof a[field] === "number") {
          const comparison = a[field] - b[field];
          if (comparison !== 0) return comparison;
        } else {
          const comparison = (a[field] || "").localeCompare(b[field] || "");
          if (comparison !== 0) return comparison;
        }
      }
      return 0;
    }),
    requestBody?.select.dimensions
  );

  // 2) Generate your main columns from "selectedFields"
  const columns = selectedFields.map((field) => {
    const baseColumn = {
      field,
      headerName: field
        .split("_")
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(" "),
      flex: 1,
      minWidth: 150,
    };

    // numeric columns
    if (isCaseField(field)) {
      return {
        ...baseColumn,
        type: "number",
        valueFormatter: (params) => {
          if (typeof params.value === "number") {
            return params.value.toLocaleString("en-US", {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            });
          }
          return params.value;
        },
        headerAlign: "right",
        align: "right",
      };
    }

    // percentage columns
    if (field.includes("_pct")) {
      return {
        ...baseColumn,
        type: "number",
        valueFormatter: (params) => {
          if (typeof params.value === "number") {
            return `${params.value.toFixed(1)}%`;
          }
          return params.value;
        },
        headerAlign: "right",
        align: "right",
      };
    }

    return baseColumn;
  });

  /**
   * Adds the Totals column at the end, with numeric formatting.
   */
  const addTotalsColumn = (cols) => {
    const newCols = [...cols];
    newCols.push({
      field: "totals",
      headerName: "Example Totals",
      type: "number",
      width: 120,
      align: "right",
      headerAlign: "right",
      valueFormatter: (params) => {
        if (params == null) return "";
        return params.toLocaleString("en-US");
      },
    });
    return newCols;
  };

  /**
   * Re-order columns so select.dimensions fields appear first, then everything else,
   * and finally the Totals column to the far right.
   */
  const getOrderedColumns = () => {
    if (!requestBody?.select.dimensions?.length) {
      return addTotalsColumn(columns);
    }

    // copy
    let orderedColumns = [...columns];

    // sort based on select.dimensions order
    orderedColumns.sort((a, b) => {
      const aIndex = requestBody.select.dimensions.indexOf(a.field);
      const bIndex = requestBody.select.dimensions.indexOf(b.field);

      // if not found, push to the end
      if (aIndex === -1 && bIndex === -1) return 0;
      if (aIndex === -1) return 1;
      if (bIndex === -1) return -1;
      return aIndex - bIndex;
    });

    // add Totals to the end
    return addTotalsColumn(orderedColumns);
  };

  return (
    <Box sx={{ height: 600, width: "100%", backgroundColor: "#FFFFFF" }}>
      <Typography fontWeight={"bold"} fontSize={24}>
        Preview Table Structure
      </Typography>
      <Typography fontSize={12} mb={2} color={"text.secondary"}>
        Below is an example of what the final table might look like.
      </Typography>
      <DataGridPremium
        rows={removeDetailRows(sortedSampleData)}
        columns={getOrderedColumns()}
        disableRowGrouping
        pagination={false}
        sx={{
          "& .MuiDataGrid-cell": {
            whiteSpace: "normal",
            lineHeight: "normal",
            padding: "8px",
          },
          "& .MuiDataGrid-virtualScroller": {
            overscrollBehavior: "contain",
          },
        }}
        style={{ overscrollBehavior: "contain" }}
        getRowClassName={(params) => {
          if (params.id !== "grandTotal") {
            return getPreviewTableRowClassName(params, requestBody);
          }
        }}
        getCellClassName={(params) => {
          if (params.id !== "grandTotal") {
            return getPreviewTableCellClassName(params, requestBody);
          }
        }}
        onRowClick={(params) => {
          console.log(params);
        }}
        pinnedRows={{ top: getGrandTotalRow(requestBody) }}
      />
    </Box>
  );
};

export default PreviewTable;
