import {
  Box,
  Checkbox,
  Avatar,
  TextField,
  Button,
  Typography,
  IconButton,
} from "@mui/material";
import { useDraggable } from "@dnd-kit/core";
import { Download, Move, Trash, Settings } from "react-feather";
import { Image } from "@mui/icons-material";
const LibraryTableRowFile = ({
  file,
  selectionMode,
  selectedItems,
  isEditing,
  rowFunctions,
  newName,
  prStyle,
  storing,
}) => {
  const { setNodeRef, attributes, listeners, transform } = useDraggable({
    id: file.file_id,
    data: {
      type: "file",
      ...file,
    },
    disabled: storing,
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        ...prStyle.dragging,
      }
    : undefined;
  return (
    <Box
      display="flex"
      justifyContent={"flex-start"}
      key={file.file_id}
      style={{ cursor: selectionMode ? "default" : "pointer", ...style }}
      ref={setNodeRef}
    >
      {selectionMode && (
        <Box p={1}>
          <Checkbox
            checked={selectedItems.includes(file.file_id)}
            onChange={() => rowFunctions.toggleSelection(file)}
          />
        </Box>
      )}
      <Box p={1} style={prStyle.icon}>
        {rowFunctions.isImageFile(file.file_name) ? (
          <Avatar
            style={{ zIndex: 100 }}
            variant="square"
            key={file.file_id}
            onClick={() =>
              selectionMode
                ? rowFunctions.toggleSelection(file)
                : rowFunctions.handleFullView(file)
            }
            src={rowFunctions.awsS3Image(
              file?.file_key,
              file?.identity_id,
              "0x100",
              ["fit-in", "-100-100"]
            )}
          />
        ) : (
          <Avatar
            style={{ zIndex: 100 }}
            onClick={() =>
              selectionMode
                ? rowFunctions.toggleSelection(file)
                : rowFunctions.handleFullView(file)
            }
          >
            <Image />
          </Avatar>
        )}
      </Box>
      <Box p={1} style={prStyle.name}>
        {isEditing === file.file_id ? (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              gap: 5,
            }}
          >
            <TextField
              value={newName}
              onChange={(e) => rowFunctions.setNewName(e.target.value)}
              variant="outlined"
              size="small"
              autoFocus
            />
            <Button
              color="primary"
              variant="contained"
              size="small"
              onClick={() => rowFunctions.saveEditedName(file)}
            >
              <Typography>Save</Typography>
            </Button>
            <Button
              variant="contained"
              size="small"
              onClick={() => rowFunctions.setIsEditing(null)}
            >
              <Typography>Close</Typography>
            </Button>
          </div>
        ) : (
          <div onClick={() => rowFunctions.startEditing(file)}>
            {file.nickname || file.file_name}
          </div>
        )}
      </Box>
      <Box p={1} style={prStyle.actions}>
        <IconButton onClick={() => rowFunctions.handleDownload(file)}>
          <Download />
        </IconButton>
        <IconButton onClick={() => rowFunctions.handleOpenDeleteModal(file)}>
          <Trash />
        </IconButton>
        <IconButton
          aria-label="more"
          aria-controls="actions-menu"
          aria-haspopup="true"
          onClick={() => rowFunctions.handleCard(file)}
        >
          <Settings />
        </IconButton>
      </Box>
      <Box p={1}>
        <Move {...listeners} {...attributes} />
      </Box>
    </Box>
  );
};

export default LibraryTableRowFile;
