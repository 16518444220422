import { useParams } from "react-router";
import formAPI from "../../../api/forms";

import Page from "../../../components/Page";
import {
  Box,
  Container,
  Card,
  CardContent,
  Grid2,
  useMediaQuery,
  Dialog,
} from "@mui/material";
import { useState, useMemo, useEffect } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/StoreContext";
import AnswersOnScreen from "../../../components/forms/v3/answers/AnswerOnScreen";
import AnswerFileDisplay from "../../../components/forms/v3/answers/AnswerFileDisplay";
import usersApi from "../../../api/users";
import accountsAPI from "../../../api/accounts";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { ResponseHeader } from ".";
import { makeStyles } from "@mui/styles";
import GenericModal from "../../../components/modals/GenericModal";
import { Link } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const FormResponses = observer(() => {
  const [response, setResponse] = useState(null);
  const [form, setForm] = useState(null);
  const [account, setAccount] = useState(null);
  const [loading, setLoading] = useState(true);
  const [chosenFile, setChosenFile] = useState(null);
  const [deleted, setDeleted] = useState(false);
  const { uuid, response_id } = useParams();
  const { userList, setUserList, userInfo } = useStore();
  const classes = useStyles();

  const user = useMemo(() => {
    const unknown = { unknown: true };
    if (!response) return unknown;
    const match = userList.find((user) => user.user_id === response.user_id);
    if (match) return match;
    return unknown;
  }, [userList, response]);

  const questions = useMemo(
    () => (form ? JSON.parse(form.form_body) : []),
    [form]
  );
  const { values: answers, attachedImages } = useMemo(
    () =>
      response
        ? JSON.parse(response.response_body)
        : { values: [], attachedImages: [] },
    [response]
  );

  useEffect(() => {
    const loadUsers = async () => {
      let _result = await usersApi.getUsers({ mid: userInfo.mid });
      if (_result.status < 400) {
        return _result.results;
      } else {
        throw new Error("unable to load users list");
      }
    };
    const loadResponse = async () => {
      const { results: response } = await formAPI.getFormResponse({
        form_id: uuid,
        response_id,
      });
      return response || null;
    };
    const loadForm = async (response) => {
      const { form_uuid, form_id } = response;
      const { result: form } = await formAPI.getFormVersionById({
        form_uuid,
        form_id,
      });
      return form;
    };
    const loadAccount = async (response) => {
      if (!response.account_id) return null;

      const {
        results: [account],
      } = await accountsAPI.getAccountsAID({
        aid: response.account_id,
      });
      return account;
    };
    const loadData = async () => {
      setLoading(true);

      const [users, response] = await Promise.all([
        loadUsers(),
        loadResponse(),
      ]);
      setUserList(users);
      if (!response) {
        return setDeleted(true);
      }
      setResponse(response);
      const [form, account] = await Promise.all([
        loadForm(response),
        loadAccount(response),
      ]);
      console.log(account);
      setForm(form);
      setAccount(account);

      setLoading(false);
    };

    loadData();
  }, [userInfo.mid, uuid, response_id, setUserList]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Page title="Form Response" className={classes.root}>
      <Container>
        <Grid2
          container
          spacing={3}
          direction={smallScreen ? "column" : "row"}
          style={{
            minHeight: 0,
          }}
        >
          <Grid2 size={12}>
            <Card>
              <CardContent>
                <Box
                  display="flex"
                  alignItems={"center"}
                  flexDirection={"column"}
                >
                  {loading && <p>Loading</p>}
                  {response && (
                    <ResponseHeader
                      distributorName={response.dist_displayname}
                      account={account}
                      user={user}
                      response={response}
                      editLink={"edit"}
                    />
                  )}
                  <Box minWidth={"50%"} maxWidth={"75%"}>
                    <AnswersOnScreen answers={answers} questions={questions} />
                  </Box>
                  {attachedImages.length > 0 && (
                    <Box display={"flex"}>
                      {attachedImages.map((a, i) => (
                        <AnswerFileDisplay
                          file={a}
                          key={i}
                          setChosenFile={setChosenFile}
                        />
                      ))}
                    </Box>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Container>
      <Dialog
        onClose={() => setChosenFile(null)}
        aria-labelledby="product-dialog"
        open={chosenFile !== null}
        maxWidth="100%"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
          maxWidth={"90%"}
          maxHeight={"80%"}
          alignContent={"center"}
          p={1}
          mx={"auto"}
        >
          <Box>
            {chosenFile && (
              <img
                src={awsS3Image(
                  chosenFile.image_name,
                  chosenFile.identity_key,
                  "1000x0"
                )}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            )}
          </Box>
        </Box>
      </Dialog>
      <GenericModal
        title="Deleted response"
        open={deleted}
        onClose={() => setDeleted(false)}
        showActions={false}
      >
        <p>This form response has been deleted.</p>
        <Link to={`/app/forms/`}>Go back to Forms list</Link>
      </GenericModal>
    </Page>
  );
});

export default FormResponses;
