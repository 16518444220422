import { useEffect, useState } from "react";
import { Typography, Divider, Box, Button } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";

const BrandDistribution = ({ data, brand, externalBrandList }) => {
  const [brandsWithStates, setBrandsWithStates] = useState([]);
  const [expanded, setExpanded] = useState([]);
  useEffect(() => {
    if (data.length && brand.length) {
      const bws = [
        ...data,
        ...externalBrandList.map((eb) => ({
          ...eb,
          product_brand: eb.brand,
          state: eb.dist_report_state_key,
        })),
      ]
        .filter((d) => d.product_brand === brand)
        .reduce((acc, cur) => {
          console.log({ cur, externalBrandList });
          const { product_brand, state, dist_displayname } = cur;
          const distIndex = acc.findIndex(
            (e) => e.distributor === dist_displayname
          );
          if (acc.length === 0) {
            return [
              {
                distributor: dist_displayname,
                states: [state],
              },
            ];
          } else if (distIndex > -1) {
            acc[distIndex].states.push(state);
            return acc;
          } else {
            return [
              ...acc,
              {
                distributor: dist_displayname,
                states: [state],
              },
            ];
          }
        }, []);
      setBrandsWithStates(bws);
    } else {
      setBrandsWithStates([]);
    }
  }, [data, brand, externalBrandList]);
  return (
    <>
      <Typography variant={"h6"}>{brand}</Typography>
      <Box pr={2}>
        {brandsWithStates.map((bw, i) => (
          <Box pb={2} key={i}>
            <Button
              style={{
                flexDirection: "row",
                width: "100%",
                maxWidth: "350px",
              }}
              onClick={() =>
                setExpanded(expanded.filter((item) => item !== bw.distributor))
              }
            >
              <Typography variant={"subtitle2"}>{bw.distributor}</Typography>
            </Button>
            <Divider my={1} />
            <LocationOnIcon
              style={{
                display: "inline-block",
                marginRight: "10px",
                color: "#2053D4",
              }}
            />{" "}
            <Typography style={{ display: "inline-block" }} variant={"body2"}>
              {bw.states.join(", ")}
            </Typography>
          </Box>
        ))}
      </Box>
    </>
  );
};

export default BrandDistribution;
