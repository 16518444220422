import { useState, useEffect, useCallback } from "react";
import { useMemo } from "react";
import { Interval } from "luxon";
import { API } from "aws-amplify";

import { useStore } from "../../../stores/StoreContext";
import { stateCodeToState } from "../../../helpers/data/usStates";
import { awsS3Image } from "../../../helpers/awsS3Image";

export const useAccountsUnsold = () => {
  const [apiCallCount, setApiCallCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [accounts, setAccounts] = useState([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await API.get("backendGateway", "/accounts/unsold");
      setAccounts(result.results);
    } catch (error) {
      setError(
        `Something went wrong when fetching unsold accounts... ${error.message}`
      );
    } finally {
      setApiCallCount((apiCallCount) => apiCallCount + 1);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
    // refresh data every 30 mins
    const intervalId = setInterval(() => loadData(), 1000 * 60 * 30);
    return () => {
      clearInterval(intervalId);
    };
  }, [loadData]);

  return {
    reload: loadData,
    loading,
    error,
    accounts,
    apiCallCount,
  };
};

export const useProducts = () => {
  const [apiCallCount, setApiCallCount] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);

  const loadData = useCallback(async () => {
    setLoading(true);
    try {
      const result = await API.get("backendGateway", "/products");
      setProducts(result.results);
      // console.log(result.results);
    } catch (error) {
      setError(
        `Something went wrong when fetching products... ${error.message}`
      );
    } finally {
      setApiCallCount((apiCallCount) => apiCallCount + 1);
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    loadData();
    // refresh data every 30 mins
    const intervalId = setInterval(() => loadData(), 1000 * 60 * 30);
    return () => {
      clearInterval(intervalId);
    };
  }, [loadData]);

  return {
    reload: loadData,
    loading,
    error,
    products,
    apiCallCount,
  };
};

export const useFilteredData = ({
  accountsUnsold,
  accountsSold,
  products,
  showProspectiveAccounts,
  showAccountsSold,
  applyDateFilter,
}) => {
  const store = useStore();
  const {
    userInfo,
    selectedProducts,
    selectedStates,
    selectedDistributors,
    selectedBrands,
    regions,
    dateRange,
    premiseTypes,
    productSold,
    selectedQuantity,
  } = store;

  const longCodeRegionMap = useMemo(() => {
    return Object.fromEntries(regions.map(({ long, code }) => [long, code]));
  }, [regions]);

  const filterArgs = useMemo(
    () => ({
      brands: new Set(selectedBrands),
      states: new Set(selectedStates),
      distributors: new Set(selectedDistributors),
      products: new Set(selectedProducts),
      applyDateFilter,
      dateRange: Interval.fromDateTimes(...dateRange),
      premiseTypes,
      productSold,
    }),
    [
      selectedBrands,
      selectedStates,
      selectedDistributors,
      selectedProducts,
      longCodeRegionMap,
      applyDateFilter,
      dateRange,
      premiseTypes,
      productSold,
    ]
  );

  const unsoldAccounts = useMemo(() => {
    let unsoldTransformed = {};

    if (showProspectiveAccounts) {
      unsoldTransformed = accountsUnsold
        .filter((account) => {
          return (
            (filterArgs.states.size === 0 ||
              filterArgs.states.has(account.account_region)) &&
            account.account_latitude !== null &&
            account.account_longitude !== null
          );
        })
        .reduce((acc, account) => {
          acc[account.gg_dist_acc_id] = {
            id: account.gg_dist_acc_id,
            name: account.account_displayname,
            address: `${account.account_address}\n${
              account.account_address_2 || ""
            }\n${account.account_city}`.replaceAll("\n\n", "\n"),
            zip: account.account_regioncode,
            region: `${stateCodeToState(account.account_region)} (${
              account.account_region
            })`,
            lat: account.account_latitude,
            long: account.account_longitude,
            premiseType: account.premise_type || "",
            productsSold: [],
            totalSold: null,
            lastSoldDate: null,
          };
          return acc;
        }, {});
    }
    return unsoldTransformed;
  }, [accountsUnsold, filterArgs, showProspectiveAccounts]);

  const soldAccounts = useMemo(() => {
    const productsById = Object.fromEntries(
      products.map((product) => [product.id, product])
    );
    let filteredDataTransformed = {};

    if (showAccountsSold) {
      filteredDataTransformed = accountsSold.reduce((accumulator, current) => {
        const currentSaleDate = new Date(current.DATE);
        const filter =
          (filterArgs.states.size === 0 ||
            filterArgs.states.has(current.STATE)) &&
          (filterArgs.brands.size === 0 ||
            filterArgs.brands.has(current.BRAND)) &&
          (filterArgs.distributors.size === 0 ||
            filterArgs.distributors.has(current.DISTRIBUTOR_ID)) &&
          (filterArgs.products.size === 0 ||
            (filterArgs.productSold &&
              filterArgs.products.has(current.PRODUCT_ID)) ||
            (!filterArgs.productSold &&
              !filterArgs.products.has(current.PRODUCT_ID))) &&
          filterArgs.premiseTypes.includes(current.PREMISETYPE) &&
          (!filterArgs.applyDateFilter ||
            filterArgs.dateRange.contains(currentSaleDate)) &&
          current.latlong &&
          current.latlong.split(",").length > 1;
        if (!filter) return accumulator;

        accumulator[current.ACCOUNT_ID] = accumulator[current.ACCOUNT_ID] || {
          id: current.ACCOUNT_ID,
          name: current.STORENUMBER,
          address: `${current.STOREADDRESS}\n${current.CITY}`.replaceAll(
            "\n\n",
            "\n"
          ),
          zip: current.ZIP,
          region: `${stateCodeToState(current.STATE)} (${current.STATE})`,
          premiseType: current.premise_type || "",
          lat: current.latlong.split(",")[0],
          long: current.latlong.split(",")[1],
          productsSold: {},
          lastSoldDate: new Date(0),
          totalSold: 0,
        };

        const productSold = accumulator[current.ACCOUNT_ID].productsSold;
        productSold[current.PRODUCT_ID] = productSold[current.PRODUCT_ID] || {
          productName: current.PRODUCTNAME,
          productUrl:
            productsById[current.PRODUCT_ID]?.product_image &&
            productsById[current.PRODUCT_ID]?.product_imagekey
              ? awsS3Image(
                  productsById[current.PRODUCT_ID].product_image,
                  productsById[current.PRODUCT_ID].product_imagekey,
                  "50x50"
                )
              : "",
          lastSoldDate: new Date(0),
          totalSold: 0,
        };

        if (currentSaleDate > productSold[current.PRODUCT_ID].lastSoldDate)
          productSold[current.PRODUCT_ID].lastSoldDate = currentSaleDate;
        if (currentSaleDate > accumulator[current.ACCOUNT_ID].lastSoldDate)
          accumulator[current.ACCOUNT_ID].lastSoldDate = currentSaleDate;

        const qty_type = [614, 629, 632].includes(userInfo.mid)
          ? "CASE_EQV"
          : "NINE_SOLD";

        productSold[current.PRODUCT_ID].totalSold += current[qty_type];

        accumulator[current.ACCOUNT_ID].totalSold += current[qty_type];

        return accumulator;
      }, {});

      // Convert productsSold from an object to an array for each account
      for (const accountId in filteredDataTransformed) {
        filteredDataTransformed[accountId].productsSold = Object.values(
          filteredDataTransformed[accountId].productsSold
        );
      }
    }

    return filteredDataTransformed;
  }, [accountsSold, products, filterArgs, showAccountsSold, selectedQuantity]);

  const allAccounts = useMemo(() => {
    return { ...unsoldAccounts, ...soldAccounts };
  }, [unsoldAccounts, soldAccounts]);

  return {
    accounts: allAccounts,
  };
};
