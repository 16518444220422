import { useMemo, useEffect, useState } from "react";
import {
  TextField,
  ButtonGroup,
  Button,
  Box,
  Autocomplete,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import { Check, Close } from "@mui/icons-material";

const ProductSelecter = observer(({ location }) => {
  const {
    allProducts,
    selectedProducts,
    setSelectedProducts,
    selectedBrands,
    productJoiner,
    setProductJoiner,
    productSold,
    setProductSold,
  } = useStore();

  const [localSelectedProducts, setLocalSelectedProducts] =
    useState(selectedProducts);

  const adjustedProducts = useMemo(() => {
    if (!allProducts.length > 0) {
      return [];
    }
    return allProducts
      .filter((p) => selectedBrands.includes(p.brand))
      .map((p) => ({ id: p.id, label: p.name }));
  }, [allProducts, selectedBrands]);

  const acValue = useMemo(() => {
    return adjustedProducts.filter((product) =>
      localSelectedProducts.some((p) => p.id === product.id)
    );
  }, [allProducts, localSelectedProducts]);

  useEffect(() => {
    const lspIds = localSelectedProducts.map((p) => p.id);
    if (lspIds.length + selectedProducts.length > 0) {
      if (JSON.stringify(lspIds) !== JSON.stringify(selectedProducts)) {
        setSelectedProducts(lspIds);
      }
    }
  }, [localSelectedProducts, selectedProducts]);

  const productChange = (values) => {
    console.log(values);
    setLocalSelectedProducts(values);
  };

  const productJoinerTypeSelectionEnabled = useMemo(() => {
    return location.pathname.match("app/list");
  }, [location]);

  useEffect(() => {
    if (!productSold && productJoiner === "Or") {
      setProductJoiner("And");
    }
  }, [productSold, productJoiner, setProductJoiner]);

  const booleanIsRelevant = useMemo(() => {
    return localSelectedProducts.length > 1;
  }, [localSelectedProducts]);

  const buttonStyle = {
    width: "30px",
    textAlign: "center",
    marginRight: 0,
  };

  return (
    <Box
      display="flex"
      alignItems="center"
      id="product-selecter"
      className="state-filter home-filter-chunk"
    >
      <Box
        flexDirection={"column"}
        alignItems="flex-end"
        sx={{
          flexGrow: { sx: 0, md: 1 },
        }}
      >
        <Box m={1} textAlign={"right"}>
          <ButtonGroup color="primary" size="small">
            <Button
              variant={productSold === true ? "contained" : ""}
              onClick={() => setProductSold(true)}
              startIcon={<Check />}
              style={buttonStyle}
              title="Include Products"
            ></Button>
            <Button
              variant={productSold === false ? "contained" : ""}
              onClick={() => setProductSold(false)}
              startIcon={<Close />}
              style={buttonStyle}
              title="Exclude Products"
            ></Button>
          </ButtonGroup>
        </Box>
        {productJoinerTypeSelectionEnabled && (
          <Box m={1} textAlign={"right"}>
            <ButtonGroup
              color="primary"
              size="small"
              disabled={!booleanIsRelevant || !productSold}
            >
              <Button
                variant={productJoiner === "And" ? "contained" : ""}
                onClick={() => setProductJoiner("And")}
                style={buttonStyle}
                title="All Chosen Products"
              >
                +
              </Button>
              <Button
                variant={productJoiner === "Or" ? "contained" : ""}
                onClick={() => setProductJoiner("Or")}
                style={buttonStyle}
                title="Any Chosen Product"
              >
                /
              </Button>
            </ButtonGroup>
          </Box>
        )}
      </Box>
      <Box flexGrow={3}>
        <Autocomplete
          disablePortal
          multiple
          options={adjustedProducts}
          getOptionLabel={(option) => option.label}
          style={{
            marginTop: 0,
            minWidth: 200,
          }}
          // getOptionDisabled={(option) => !option.enabled}
          renderInput={(params) => (
            <TextField
              {...params}
              label={`Products (${adjustedProducts.length})`}
              style={{
                marginTop: 0,
              }}
            />
          )}
          onChange={(event, value) => productChange(value)}
          value={acValue}
        />
      </Box>
    </Box>
  );
});

export default ProductSelecter;
