import React, {
  useState,
  useMemo,
  useRef,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import {
  DndContext,
  PointerSensor,
  useSensor,
  useSensors,
  useDroppable,
  DragOverlay,
  MouseSensor,
  rectIntersection,
} from "@dnd-kit/core";
import Box from "@mui/material/Box";
import { throttle, isEqual } from "lodash";
import client from "../../api/client"; // Import API client

import SummaryTotalComparison from "./SummaryTotalComparison";
import TopProducts from "./TopProducts";
import TopAccounts from "./TopAccounts";
import TeamActivityPreview from "./TeamActivityPreview";
import DraggableWidget from "./DraggableWidget";
import MonthlyGoals from "./MonthlyGoals";
import STHeatMapCluster from "../../components/visualisation/brands/STHeatMapCluster";
import LoadingSkeleton from "./LoadingSkeleton";
import WidgetSidebar from "./WidgetSidebar";

import {
  encodeCell,
  decodeCell,
  resizeWidgetColSpan,
  handleCollision,
  resolveOverlapsRecursively,
  finalDislodgeStep,
  validateAndFixWidgetsConfig,
  calculateWidgetDimensions,
  getWidgetHeight,
  useScreenTransition,
  handleResponsiveResize,
  ensureUniqueRootCells,
  checkNoOverlaps,
  ensureNoRootCellDuplicates,
  verifyNoOverlaps,
} from "./gridUtils";
import SalesTargets from "./SalesTargets/SalesTargets";
import RepTargets from "./RepTotals_RepTargets/RepTargets";
import { AVAILABLE_WIDGETS } from "./AVAILABLE_WIDGETS";

/**
 * A droppable grid cell for the drag-and-drop area
 */
function DroppableCell({ id, children, isEditMode }) {
  const { isOver, setNodeRef } = useDroppable({ id });

  const style = {
    width: "100%",
    height: "100%",
    borderRadius: "8px",
    backgroundColor: isEditMode
      ? isOver
        ? "rgba(0, 120, 215, 0.2)"
        : "rgba(0, 120, 215, 0.05)"
      : "transparent",
    transition: "all 0.2s ease",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    overflow: "hidden",
  };
  return (
    <div ref={setNodeRef} style={style}>
      {children}
    </div>
  );
}

// Render a widget's underlying component by string identifier
const renderComponent = (componentName, props, handleWidgetConfigUpdate) => {
  switch (componentName) {
    case "SummaryTotalComparison":
      return <SummaryTotalComparison {...props} />;
    case "TopProducts":
      return <TopProducts {...props} />;
    case "TopAccounts":
      return <TopAccounts {...props} />;
    case "TeamActivityPreview":
      return <TeamActivityPreview {...props} />;
    case "MonthlyGoals":
      return <MonthlyGoals {...props} />;
    case "STHeatMapCluster":
      return <STHeatMapCluster {...props} />;
    case "SalesTargets":
      return (
        <SalesTargets
          {...props}
          onWidgetConfigUpdate={(update) => {
            if (props && props.widgetId) {
              handleWidgetConfigUpdate(props.widgetId, update);
            } else {
              console.error(
                "Widget ID is missing when trying to update configuration"
              );
            }
          }}
        />
      );
    case "RepTargets":
      return (
        <RepTargets
          {...props}
          onWidgetConfigUpdate={(update) => {
            if (props && props.widgetId) {
              handleWidgetConfigUpdate(props.widgetId, update);
            } else {
              console.error(
                "Widget ID is missing when trying to update configuration"
              );
            }
          }}
        />
      );
    default:
      return <div>Unknown component: {componentName}</div>;
  }
};

// Finalize layout: run collision resolution + final dislodge repeatedly
function finalizeLayout(widgets, cfg, maxIterations = 5) {
  let out = [...widgets];
  let iteration = 0;

  while (iteration < maxIterations) {
    const before = JSON.stringify(out);

    out = resolveOverlapsRecursively(out, null, cfg);
    out = finalDislodgeStep(out, cfg);

    const after = JSON.stringify(out);
    if (before === after) {
      break; // Stop loop if no changes
    }
    iteration++;
  }

  return out;
}

const DashboardGrid = forwardRef(function DashboardGrid(
  { isEditMode, onHiddenWidgetsChange, toggleEditMode, isAdmin },
  ref
) {
  // Move hooks to the top level of your component
  const sensors = useSensors(useSensor(MouseSensor), useSensor(PointerSensor));

  const [isLoading, setIsLoading] = useState(true);
  const [widgets, setWidgets] = useState(() =>
    validateAndFixWidgetsConfig([...AVAILABLE_WIDGETS(isAdmin)], {
      columns: 4,
      rows: 6,
      cellWidth: 350,
      cellHeight: 430,
      gap: 10,
    })
  );
  const [hiddenWidgets, setHiddenWidgets] = useState([]);
  const [originalWidgetSizes, setOriginalWidgetSizes] = useState({});
  const [tempWidgets, setTempWidgets] = useState(widgets);
  const [isDragging, setIsDragging] = useState(false);
  const [resizingWidgetId, setResizingWidgetId] = useState(null);
  const resizeStartX = useRef(0);
  const resizeStartColSpan = useRef(1);
  const [resizePreview, setResizePreview] = useState(null);
  const [activeId, setActiveId] = useState(null);

  const { isSmallScreen, isTransitioning, columns } = useScreenTransition(1600);

  // Expose ref methods
  useImperativeHandle(ref, () => ({
    addWidget: (widgetId) => handleAddWidget(widgetId),
    getHiddenWidgets: () => hiddenWidgets,
  }));

  // Save layout (debounced)
  const saveLayout = useRef(
    throttle(async (layout) => {
      try {
        const completeLayout = [...layout, ...hiddenWidgets];
        await client.post("/users/layouts", { layout: completeLayout });
      } catch (error) {
        console.error("Error saving dashboard layout:", error);
      }
    }, 2000)
  ).current;

  // When hiddenWidgets changes, tell the parent
  useEffect(() => {
    if (onHiddenWidgetsChange) {
      onHiddenWidgetsChange(hiddenWidgets);
    }
  }, [hiddenWidgets, onHiddenWidgetsChange]);

  // Load saved layout from server
  useEffect(() => {
    const loadSavedLayout = async () => {
      setIsLoading(true);
      try {
        const response = await client.get("/users/layouts");
        if (response.layout) {
          let savedLayout;
          try {
            savedLayout =
              typeof response.layout === "string"
                ? JSON.parse(response.layout)
                : response.layout;
          } catch (e) {
            console.error("Error parsing saved layout:", e);
            savedLayout = null;
          }

          if (savedLayout && Array.isArray(savedLayout)) {
            const savedWidgetIds = savedLayout.map((widget) => widget.id);
            const allHidden = AVAILABLE_WIDGETS(isAdmin).filter(
              (widget) => !savedWidgetIds.includes(widget.id)
            );

            setWidgets(
              validateAndFixWidgetsConfig(savedLayout, {
                columns: 4,
                rows: 6,
                cellWidth: 350,
                cellHeight: 430,
                gap: 10,
              })
            );
            setHiddenWidgets(allHidden);
          } else {
            setWidgets(
              validateAndFixWidgetsConfig([...AVAILABLE_WIDGETS(isAdmin)], {
                columns: 4,
                rows: 6,
                cellWidth: 350,
                cellHeight: 430,
                gap: 10,
              })
            );
          }
        } else {
          setWidgets(
            validateAndFixWidgetsConfig([...AVAILABLE_WIDGETS(isAdmin)], {
              columns: 4,
              rows: 6,
              cellWidth: 350,
              cellHeight: 430,
              gap: 10,
            })
          );
        }
      } catch (error) {
        console.error("Error loading dashboard layout:", error);
        setWidgets(
          validateAndFixWidgetsConfig([...AVAILABLE_WIDGETS(isAdmin)], {
            columns: 4,
            rows: 6,
            cellWidth: 350,
            cellHeight: 430,
            gap: 10,
          })
        );
      } finally {
        setIsLoading(false);
        if (isSmallScreen) {
          setWidgets(
            handleResponsiveResize(
              widgets,
              isSmallScreen,
              originalWidgetSizes,
              gridConfig
            )
          );
        }
      }
    };
    loadSavedLayout();
  }, []);

  // Save layout when leaving edit mode
  const prevEditModeRef = useRef(isEditMode);
  useEffect(() => {
    if (
      prevEditModeRef.current === true &&
      isEditMode === false &&
      widgets.length > 0
    ) {
      saveLayout(widgets);
    }
    prevEditModeRef.current = isEditMode;
  }, [isEditMode, widgets, saveLayout]);

  // Calculate how many rows we need
  const calculateRequiredRows = useMemo(() => {
    if (!widgets.length) return 3;
    const lowestRow = Math.max(
      ...widgets.map((widget) => widget.rootCell.row + widget.size.rowSpan)
    );
    return lowestRow + 1;
  }, [widgets]);

  // Our grid config
  const gridConfig = useMemo(
    () => ({
      columns,
      rows: calculateRequiredRows,
      cellWidth: 350,
      cellHeight: 400,
      gap: 10,
    }),
    [columns, calculateRequiredRows]
  );

  // Keep original widget sizes
  useEffect(() => {
    const sizes = {};
    widgets.forEach((w) => {
      sizes[w.id] = w.size.colSpan;
    });
    setOriginalWidgetSizes(sizes);
  }, []);

  // If screen transitions (small <-> large), do a responsive re-layout
  useEffect(() => {
    if (isTransitioning) {
      setWidgets((prev) => {
        const newLayout = handleResponsiveResize(
          prev,
          isSmallScreen,
          originalWidgetSizes,
          gridConfig
        );
        return isEqual(newLayout, prev) ? prev : newLayout;
      });
    }
  }, [isTransitioning, isSmallScreen, gridConfig, originalWidgetSizes]);

  // Switch from small->large or large->small
  const [wasSmallScreen, setWasSmallScreen] = useState(
    typeof window !== "undefined" && window.innerWidth < 900
  );
  useEffect(() => {
    if (wasSmallScreen !== isSmallScreen) {
      setWidgets((prev) => {
        let updated = [...prev];
        if (isSmallScreen) {
          updated = updated.map((widget) => {
            if (widget.size.colSpan > 2) {
              return {
                ...widget,
                size: { ...widget.size, colSpan: 2 },
              };
            }
            return widget;
          });
        } else {
          updated = updated.map((widget) => {
            const orig = originalWidgetSizes[widget.id] || widget.size.colSpan;
            const preferred = Math.min(orig, 4);
            if (preferred > widget.size.colSpan) {
              return {
                ...widget,
                size: { ...widget.size, colSpan: preferred },
              };
            }
            return widget;
          });
        }
        let final = resolveOverlapsRecursively(updated, null, gridConfig);
        final = finalDislodgeStep(final, gridConfig);
        return isEqual(final, prev) ? prev : final;
      });
      setWasSmallScreen(isSmallScreen);
    }
  }, [isSmallScreen, wasSmallScreen, originalWidgetSizes, gridConfig]);

  // If columns change, ensure colSpan <= 2
  useEffect(() => {
    if (!widgets.length) return;
    setWidgets((prev) => {
      const colFixed = prev.map((w) => {
        if (w.size.colSpan > 2) {
          return { ...w, size: { ...w.size, colSpan: 2 } };
        }
        return w;
      });
      const validated = validateAndFixWidgetsConfig(colFixed, gridConfig);
      return isEqual(validated, prev) ? prev : validated;
    });
  }, [columns, gridConfig]);

  // Bump the entire layout if not dragging
  useEffect(() => {
    if (!isDragging && !resizingWidgetId) {
      setWidgets((prev) => {
        let final = finalizeLayout(prev, gridConfig);
        final = ensureNoRootCellDuplicates(final, gridConfig);
        final = verifyNoOverlaps(final, gridConfig);

        if (!isEqual(final, prev)) return final;
        return prev;
      });
    }
  }, [widgets, isDragging, resizingWidgetId, gridConfig]);

  // The displayed widgets are either the dragging temp or the final
  const displayedWidgets = isDragging ? tempWidgets : widgets;

  // Throttled dragOver handling
  const handleDragOverLogic = ({ active, over }) => {
    if (!over) return;
    const draggedId = active.id;
    const { col, row } = decodeCell(over.id);

    setTempWidgets((prev) => {
      const draggedWidget = prev.find((w) => w.id === draggedId);
      if (!draggedWidget) return prev;
      const previousPos = { ...draggedWidget.rootCell };
      return handleCollision(
        prev,
        draggedId,
        { col, row },
        previousPos,
        gridConfig
      );
    });
  };

  // A small custom hook that batches dragOver events in requestAnimationFrame
  const rafId = useRef(null);
  function useRafDragOver(callback) {
    const lastEventRef = useRef(null);
    const [pending, setPending] = useState(false);

    function onDragOverRaf(event) {
      lastEventRef.current = event;
      if (!pending) {
        setPending(true);
        rafId.current = requestAnimationFrame(() => {
          rafId.current = null;
          setPending(false);

          const finalEvent = lastEventRef.current;
          lastEventRef.current = null;
          if (finalEvent) {
            callback(finalEvent);
          }
        });
      }
    }
    return onDragOverRaf;
  }

  const handleDragOver = useRafDragOver(handleDragOverLogic);

  // Drag start
  function handleDragStart(event) {
    setIsDragging(true);
    setTempWidgets([...widgets]);
    setActiveId(event.active.id);
  }

  // Drag end
  function handleDragEnd(event) {
    const { active, over } = event;

    if (active.id.toString().startsWith("sidebar-") && over) {
      // Handle sidebar widget drop
      handleSidebarDrop(active.id, over.id);
      return; // Return early to avoid the regular widget logic below
    }

    if (!over) {
      // No valid drop target
      setIsDragging(false);
      setActiveId(null);
      return;
    }

    // Regular widget repositioning logic
    setWidgets((prev) => {
      let finalWidgets = [...tempWidgets];

      // Run comprehensive overlap checks & resolution
      finalWidgets = resolveOverlapsRecursively(finalWidgets, null, gridConfig);
      finalWidgets = finalDislodgeStep(finalWidgets, gridConfig);
      finalWidgets = ensureNoRootCellDuplicates(finalWidgets, gridConfig);

      // Add final verification as the last step
      finalWidgets = verifyNoOverlaps(finalWidgets, gridConfig);
      return isEqual(finalWidgets, prev) ? prev : finalWidgets;
    });

    setIsDragging(false);
    setActiveId(null);
  }

  // On resizing
  useEffect(() => {
    function onMouseMove(event) {
      if (!resizingWidgetId) return;
      const deltaX = event.clientX - resizeStartX.current;
      setResizePreview({ x: event.clientX, y: event.clientY });

      setTempWidgets((prev) => {
        const target = prev.find((w) => w.id === resizingWidgetId);
        if (!target) return prev;

        const columnWidth = gridConfig.cellWidth + gridConfig.gap;
        let additionalCols = Math.round(deltaX / columnWidth);
        let newColSpan = resizeStartColSpan.current + additionalCols;
        newColSpan = Math.max(newColSpan, target.minColSpan || 1);
        newColSpan = Math.min(newColSpan, 2, gridConfig.columns);
        const maxSpan = gridConfig.columns - target.rootCell.col;
        newColSpan = Math.min(newColSpan, maxSpan);

        let updated = resizeWidgetColSpan(
          prev,
          target.id,
          newColSpan,
          gridConfig
        );
        updated = resolveOverlapsRecursively(updated, null, gridConfig);
        updated = finalDislodgeStep(updated, gridConfig);
        return updated;
      });
    }

    // This helper updates the "original" stored size for a widget
    const updateOriginalSize = (widgetId, newSize) => {
      setOriginalWidgetSizes((prev) => {
        const next = { ...prev, [widgetId]: newSize };
        return next;
      });
    };

    function onMouseUp() {
      if (!resizingWidgetId) return;
      setWidgets((prev) => {
        let finalized = [...tempWidgets];
        finalized = finalizeLayout(finalized, gridConfig);
        finalized = ensureNoRootCellDuplicates(finalized, gridConfig);

        checkNoOverlaps(finalized);
        ensureUniqueRootCells(finalized);

        const resized = finalized.find((w) => w.id === resizingWidgetId);
        if (resized) {
          updateOriginalSize(resizingWidgetId, resized.size.colSpan);
        }
        return isEqual(finalized, prev) ? prev : finalized;
      });
      setResizingWidgetId(null);
      setResizePreview(null);
    }

    window.addEventListener("mousemove", onMouseMove);
    window.addEventListener("mouseup", onMouseUp);
    return () => {
      window.removeEventListener("mousemove", onMouseMove);
      window.removeEventListener("mouseup", onMouseUp);
    };
  }, [resizingWidgetId, gridConfig, tempWidgets]);

  // Additional finalize step if not dragging/resizing
  useEffect(() => {
    if (!isDragging && !resizingWidgetId) {
      setWidgets((prev) => {
        let final = finalizeLayout(prev, gridConfig);
        final = ensureNoRootCellDuplicates(final, gridConfig);
        final = verifyNoOverlaps(final, gridConfig);

        if (!isEqual(final, prev)) {
          return final;
        }
        return prev;
      });
    }
  }, [isDragging, resizingWidgetId, gridConfig]);

  function onResizeMouseDown(widgetId, e) {
    e.stopPropagation();
    setTempWidgets([...widgets]);
    setResizingWidgetId(widgetId);
    resizeStartX.current = e.clientX;
    const target = widgets.find((w) => w.id === widgetId);
    resizeStartColSpan.current = target?.size.colSpan || 1;
  }

  // Build the grid cells
  const cells = useMemo(() => {
    const arr = [];
    for (let row = 0; row < gridConfig.rows; row++) {
      for (let col = 0; col < gridConfig.columns; col++) {
        arr.push({ id: encodeCell({ col, row }), col, row });
      }
    }
    return arr;
  }, [gridConfig.columns, gridConfig.rows]);

  function getWidgetTopLeft(col, row) {
    return displayedWidgets.find(
      (widget) => widget.rootCell.col === col && widget.rootCell.row === row
    );
  }

  function isCellOccupied(col, row) {
    return displayedWidgets.some((widget) => {
      const left = widget.rootCell.col;
      const top = widget.rootCell.row;
      const right = left + widget.size.colSpan - 1;
      const bottom = top + widget.size.rowSpan - 1;
      return col >= left && col <= right && row >= top && row <= bottom;
    });
  }

  const widgetDimension = (w) => calculateWidgetDimensions(w, gridConfig);
  const wHeight = (w) => getWidgetHeight(w, gridConfig);
  const isResizingWidget = (w) => w.id === resizingWidgetId;

  const gridHeight = useMemo(() => {
    return (
      gridConfig.cellHeight * gridConfig.rows +
      gridConfig.gap * (gridConfig.rows - 1) +
      gridConfig.gap * 2
    );
  }, [gridConfig.cellHeight, gridConfig.rows, gridConfig.gap]);

  // Hiding a widget
  function handleHideWidget(widgetId) {
    const widgetToHide = widgets.find((w) => w.id === widgetId);
    if (!widgetToHide) return;

    setHiddenWidgets((prev) => [...prev, { ...widgetToHide, hidden: true }]);
    setWidgets((prev) => {
      const updated = prev.filter((w) => w.id !== widgetId);
      return validateAndFixWidgetsConfig(updated, gridConfig);
    });
  }

  // Adding a widget from hidden
  function handleAddWidget(widgetId) {
    const widgetToAdd = hiddenWidgets.find((w) => w.id === widgetId);
    if (!widgetToAdd) return;

    const defaultWidget = AVAILABLE_WIDGETS(isAdmin).find(
      (w) => w.id === widgetId
    );
    const updatedWidget = {
      ...(defaultWidget || {}),
      ...(widgetToAdd || {}),
      hidden: false,
      rootCell: findEmptySpot(
        widgets,
        widgetToAdd.size || defaultWidget.size,
        gridConfig
      ),
    };

    setHiddenWidgets((prev) => prev.filter((w) => w.id !== widgetId));
    setWidgets((prev) => {
      const updatedWidgets = [...prev, updatedWidget];
      return validateAndFixWidgetsConfig(updatedWidgets, gridConfig);
    });
  }

  function findEmptySpot(currentWidgets, size, config) {
    const { colSpan, rowSpan } = size;
    const grid = Array(config.rows)
      .fill()
      .map(() => Array(config.columns).fill(false));

    currentWidgets.forEach((widget) => {
      const { col, row } = widget.rootCell;
      const { colSpan, rowSpan } = widget.size;
      for (let r = row; r < row + rowSpan; r++) {
        for (let c = col; c < col + colSpan; c++) {
          if (r < config.rows && c < config.columns) {
            grid[r][c] = true;
          }
        }
      }
    });

    // Find the first open space that fits the widget
    for (let row = 0; row < config.rows; row++) {
      for (let col = 0; col <= config.columns - colSpan; col++) {
        let canFit = true;
        for (let r = 0; r < rowSpan; r++) {
          for (let c = 0; c < colSpan; c++) {
            if (
              r + row >= config.rows ||
              c + col >= config.columns ||
              grid[r + row][c + col]
            ) {
              canFit = false;
              break;
            }
          }
          if (!canFit) break;
        }
        if (canFit) return { col, row };
      }
    }

    // If no empty spot is found, default to top-left
    return { col: 0, row: 0 };
  }

  function handleSidebarDrop(id, dropTarget) {
    const widgetId = id.replace("sidebar-", "");
    const widgetFromSidebar = hiddenWidgets.find((w) => w.id === widgetId);
    if (!widgetFromSidebar) return;

    const defaultWidget = AVAILABLE_WIDGETS(isAdmin).find(
      (w) => w.id === widgetId
    );
    if (!defaultWidget) return;

    const newWidget = {
      ...defaultWidget,
      hidden: false,
      rootCell: findEmptySpot(widgets, defaultWidget.size, gridConfig),
    };

    setHiddenWidgets((prev) => prev.filter((w) => w.id !== widgetId));
    setWidgets((prev) => {
      const updatedWidgets = [...prev, newWidget];
      return validateAndFixWidgetsConfig(updatedWidgets, gridConfig);
    });

    setIsDragging(false);
    setActiveId(null);
  }

  // Add this function to the DashboardGrid component
  function handleWidgetConfigUpdate(widgetId, update) {
    setWidgets((prevWidgets) => {
      const newWidgets = prevWidgets.map((widget) => {
        if (widget.id === widgetId) {
          return {
            ...widget,
            data: {
              ...widget.data,
              ...update.data,
            },
          };
        }
        return widget;
      });

      saveLayout(newWidgets);

      return newWidgets;
    });
  }

  // Rendering
  if (isLoading) {
    return <LoadingSkeleton />;
  }

  return (
    <Box sx={{ display: "flex", flexDirection: "row" }}>
      <DndContext
        sensors={sensors}
        onDragStart={handleDragStart}
        onDragOver={handleDragOver}
        collisionDetection={rectIntersection}
        onDragEnd={handleDragEnd}
        modifiers={isEditMode ? [] : [() => null]}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: `repeat(${gridConfig.columns}, ${gridConfig.cellWidth}px)`,
            gridTemplateRows: `repeat(${gridConfig.rows}, ${gridConfig.cellHeight}px)`,
            gap: `${gridConfig.gap}px`,
            padding: `${gridConfig.gap}px`,
            backgroundColor: "#FFF",
            borderRadius: "8px",
            width: "fit-content",
            height: `${gridHeight}px`,
            margin: "0 auto",
            position: "relative",
            overflow: "hidden",
          }}
        >
          {cells.map((cell) => {
            const widgetAtCell = getWidgetTopLeft(cell.col, cell.row);
            const occupied = isCellOccupied(cell.col, cell.row);

            if (occupied && !widgetAtCell) {
              // This cell is covered by a widget, but isn't top-left => skip
              return null;
            }

            return (
              <Box
                key={cell.id}
                sx={{
                  gridColumn: widgetAtCell
                    ? `${cell.col + 1} / span ${widgetAtCell.size.colSpan}`
                    : cell.col + 1,
                  gridRow: widgetAtCell
                    ? `${cell.row + 1} / span ${widgetAtCell.size.rowSpan}`
                    : cell.row + 1,
                  overflow: "hidden",
                }}
              >
                <DroppableCell id={cell.id} isEditMode={isEditMode}>
                  {widgetAtCell && (
                    <Box
                      sx={{
                        position: "relative",
                        ...widgetDimension(widgetAtCell),
                        borderRadius: "8px",
                        backgroundColor: "#f8f8f8",
                        overflow: "hidden",
                        transition: isResizingWidget(widgetAtCell)
                          ? "none"
                          : "all 0.2s ease",
                      }}
                    >
                      <DraggableWidget
                        id={widgetAtCell.id}
                        title={widgetAtCell.title}
                        infoText={widgetAtCell.infoText}
                        width={widgetDimension(widgetAtCell).width}
                        height={widgetDimension(widgetAtCell).height}
                        isEditMode={isEditMode}
                        onHideWidget={handleHideWidget}
                        style={{
                          opacity: isResizingWidget(widgetAtCell) ? 0.9 : 1,
                          transition: isResizingWidget(widgetAtCell)
                            ? "none"
                            : "opacity 0.2s ease",
                        }}
                        colSpan={widgetAtCell.size.colSpan}
                        data={widgetAtCell.data}
                      >
                        {!isEditMode &&
                          renderComponent(
                            widgetAtCell.component,
                            {
                              widgetData: widgetAtCell.data,
                              reportLoaded: () => {},
                              height: wHeight(widgetAtCell),
                              size: {
                                colSpan: widgetAtCell.size.colSpan,
                              },
                              widgetId: widgetAtCell.id,
                            },
                            handleWidgetConfigUpdate
                          )}
                      </DraggableWidget>
                      {isEditMode && widgetAtCell.minColSpan !== 2 && (
                        <div
                          style={{
                            position: "absolute",
                            right: 0,
                            bottom: 0,
                            cursor: isEditMode ? "se-resize" : "default",
                            marginRight: "6px",
                            marginBottom: "6px",
                            visibility: isEditMode ? "visible" : "hidden",
                          }}
                          onMouseDown={(e) =>
                            isEditMode && onResizeMouseDown(widgetAtCell.id, e)
                          }
                        >
                          <svg
                            width="12"
                            height="18"
                            viewBox="0 0 15 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            style={{
                              transform:
                                widgetAtCell.size.colSpan >= 2 ||
                                widgetAtCell.size.colSpan >=
                                  gridConfig.columns - widgetAtCell.rootCell.col
                                  ? "rotate(180deg)"
                                  : "none",
                              marginRight: "6px",
                            }}
                          >
                            <path
                              d="M1 19.1885L13.7355 1.00035"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                            <path
                              d="M6.82227 18.252L13.9125 8.12606"
                              stroke="black"
                              strokeWidth="2"
                              strokeLinecap="round"
                            />
                          </svg>
                        </div>
                      )}
                    </Box>
                  )}
                </DroppableCell>
              </Box>
            );
          })}
          {resizePreview && (
            <div
              style={{
                position: "fixed",
                left: resizePreview.x,
                top: resizePreview.y,
                width: "10px",
                height: "10px",
                background: "#0070d7",
                borderRadius: "8px",
                transform: "translate(-50%, -50%)",
                pointerEvents: "none",
              }}
            />
          )}
        </Box>

        {/* Drag Overlay */}
        <DragOverlay>
          {activeId && isEditMode
            ? (() => {
                if (activeId.toString().startsWith("sidebar-")) {
                  // Sidebar widget
                  const widgetId = activeId.replace("sidebar-", "");
                  const sidebarWidget = hiddenWidgets.find(
                    (w) => w.id === widgetId
                  );
                  if (!sidebarWidget) return null;
                  const preview = {
                    ...sidebarWidget,
                    size: {
                      colSpan: sidebarWidget.minColSpan || 1,
                      rowSpan: sidebarWidget.size?.rowSpan || 1,
                    },
                  };
                  return (
                    <Box
                      sx={{
                        ...widgetDimension(preview),
                        border: "2px dashed #0070D7",
                        borderRadius: "8px",
                        backgroundColor: "#f8f8f8",
                        overflow: "hidden",
                        opacity: 0.9,
                      }}
                    >
                      <DraggableWidget
                        id={preview.id}
                        title={preview.title}
                        infoText={preview.infoText}
                        width={widgetDimension(preview).width}
                        height={widgetDimension(preview).height}
                        isEditMode={isEditMode}
                        colSpan={preview.size.colSpan}
                        data={preview.data}
                      />
                    </Box>
                  );
                } else {
                  // Normal widget
                  const activeWidget = widgets.find((w) => w.id === activeId);
                  if (!activeWidget) return null;
                  return (
                    <Box
                      sx={{
                        ...widgetDimension(activeWidget),
                        border: "2px dashed #0070D7",
                        borderRadius: "8px",
                        backgroundColor: "#f8f8f8",
                        overflow: "hidden",
                        opacity: 0.9,
                      }}
                    >
                      <DraggableWidget
                        id={activeWidget.id}
                        title={activeWidget.title}
                        infoText={activeWidget.infoText}
                        width={widgetDimension(activeWidget).width}
                        height={widgetDimension(activeWidget).height}
                        isEditMode={isEditMode}
                        colSpan={activeWidget.size.colSpan}
                        data={activeWidget.data}
                      />
                    </Box>
                  );
                }
              })()
            : null}
        </DragOverlay>

        {/* Widget Sidebar */}
        <WidgetSidebar
          hiddenWidgets={hiddenWidgets}
          isEditMode={isEditMode}
          toggleEditMode={toggleEditMode}
          isAdmin={isAdmin}
        />
      </DndContext>
    </Box>
  );
});

export default DashboardGrid;
