import { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import reportsAPI from "../../api/reports";
import ReportsHistorySmall from "./ReportsHistorySmall";
import ReportsHistoryLarge from "./ReportsHistoryLarge";

const ReportsHistory = ({
  savedInHistory = null,
  setSavedInHistory = () => {},
  size = "small",
  reports,
  setReports,
}) => {
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [comment, setComment] = useState("");
  const [starring, setStarring] = useState(false);

  const load = () => {
    setLoading(true);
    reportsAPI
      .getReportHistory()
      .then(({ results }) => setReports(results))
      .catch((error) => console.error(error))
      .finally(() => setLoading(false));
  };

  const showStarred = (hash) => {
    setReports(
      reports.map((r) =>
        r.hash === hash
          ? {
              ...r,
              fave: 1,
            }
          : r
      )
    );
  };
  const showUnstarred = (hash) => {
    setReports(
      reports.map((r) =>
        r.hash === hash
          ? {
              ...r,
              fave: null,
            }
          : r
      )
    );
  };

  const fave = (hash) => {
    console.log({ hash });
    showStarred(hash);
    reportsAPI
      .faveReportHistory(hash)
      .then(() => {})
      .catch((error) => {
        showUnstarred(hash);
      });
  };
  const unfave = (hash) => {
    console.log({ hash });
    showUnstarred(hash);
    reportsAPI
      .unfaveReportHistory(hash)
      .then(() => showUnstarred(hash))
      .catch((error) => showStarred(hash));
  };
  const updateHistory = (obj) => {
    reportsAPI
      .updateReportHistory(obj)
      .then(() => {
        setReports(
          reports.map((r) => {
            if (r.id === obj.id) {
              return obj;
            }
            return r;
          })
        );
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (!reports.length) {
      load();
    }
  }, []);

  useEffect(() => {
    if (savedInHistory === null) {
      setTitle("");
      setComment("");
      return;
    }
    const { title, comment, id } = savedInHistory;
    if (!reports.find((r) => r.id === id)) {
      setReports([...reports, savedInHistory]);
    }
    setTitle(title);
    setComment(comment);
  }, [savedInHistory]);

  if (savedInHistory) {
    return (
      <Box p={2}>
        <Box>
          <Typography variant="h6">Report Saved</Typography>
          <Divider sx={{ mb: 2 }} />
          <Box pb={2}>
            <Typography variant="body2">
              This report has been saved to your history.
            </Typography>
            <Typography variant="body2">
              You have the option to give it a title or add comments for future
              reference.
            </Typography>
          </Box>
          <Divider sx={{ mb: 2 }} />
          <TextField
            value={title}
            onChange={(event) => setTitle(event.target.value)}
            label="Title"
            fullWidth
            sx={{ mb: 1 }}
          />
          <TextField
            value={comment}
            onChange={(event) => setComment(event.target.value)}
            label="Comment"
            fullWidth
            sx={{ mb: 1 }}
            multiline
            rows={3}
          />
        </Box>
        <Divider sx={{ my: 2 }} />
        <Box display={"flex"} justifyContent={"space-between"}>
          <Button onClick={() => setSavedInHistory(null)}>Dismiss</Button>
          <Button
            onClick={() =>
              updateHistory({
                ...savedInHistory,
                comment,
                title,
              })
            }
            variant={"contained"}
          >
            Update
          </Button>
        </Box>
      </Box>
    );
  }
  if (size === "small")
    return (
      <ReportsHistorySmall
        {...{
          fave,
          unfave,
          reports,
        }}
      />
    );
  if (size === "large")
    return (
      <ReportsHistoryLarge
        {...{
          fave,
          unfave,
          reports,
          updateHistory,
        }}
      />
    );

  return (
    <Box>
      <Box display="flex">
        <Box>
          <p>Report History</p>
        </Box>
        <Box>
          <Button onClick={load}>Load</Button>
        </Box>
      </Box>
      {loading ? "loading" : "not loading"}
    </Box>
  );
};

export default ReportsHistory;
