import React, { useState, useEffect, useMemo } from "react";
import { observer } from "mobx-react-lite";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  TableContainer,
  Card,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import statsAPI from "../../api/stats";
import TopAccountProducts from "../../views/reports/DataView/TopAccountProducts";
import { useStore } from "../../stores/StoreContext";
import { DateTime } from "luxon";
import { Link as RouterLink } from "react-router-dom";
import useQueryParamsBuilder from "../megaFilter/useQueryParamsBuilder";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  loading: {
    alignContent: "center",
    justifyContent: "center",
    display: "flex",
    marginTop: theme.spacing(4),
  },
  statbox: {
    backgroundColor: theme.palette.primary.main,
    color: "#ffffff",
    textAlign: "center",
  },
  selectableRow: {
    "&:hover": {
      backgroundColor: "#eee",
    },
  },
}));

// Utility function to format numbers
const formatNumber = (num) => {
  if (num >= 1000) {
    return (num / 1000).toFixed(2) + "k";
  }
  return num.toString();
};

const visibleColumnsAtEachSize = {
  account: 2, // Show account address if colspan is 2 or above
  product: 2, // Show product image if colspan is 2 or above
};

const TopAccounts = observer(({ reportLoaded, size, timeRange }) => {
  const { colSpan } = size;
  const classes = useStyles();
  const [productHash, setProductHash] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedAccount, setSelectedAccount] = useState(null);
  const [tops, setTops] = useState([]);
  const [loading, setLoading] = useState(false);
  const { queryParams } = useQueryParamsBuilder();
  const { selectedDistributors, dateRange } = useStore();

  const [dateStart, dateEnd] = useMemo(() => {
    return dateRange.map((d) => DateTime.fromJSDate(d).toISODate());
  }, [dateRange]);

  const load = () => {
    setLoading(true);
    statsAPI
      .getTopAccounts({
        ...queryParams,
        dists: selectedDistributors,
        timeRange: timeRange,
      })
      .then(({ results }) => {
        const sorted = results.sort((a, b) => b.units - a.units);
        const top10 = sorted.slice(0, 10);
        setTops(top10);
        reportLoaded("top-accounts");
      })
      .catch((err) => console.error(err))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    load();
  }, [timeRange, selectedDistributors, queryParams]);

  const handleRowClick = (account) => {
    setSelectedAccount(account);
    setModalOpen(true);
  };

  const handleClose = () => {
    setModalOpen(false);
    setSelectedAccount(null);
  };

  return (
    <Box
      sx={{
        height: "100%",
        overflow: "auto",
        overscrollBehavior: "contain",
        p: 3,
      }}
    >
      {loading && <LinearProgress />}
      <TableContainer
        component={Card}
        sx={{ borderRadius: 2, overflow: "auto" }}
      >
        <Table size="small" sx={{ boxShadow: "none" }}>
          <TableHead sx={{ bgcolor: "#f5f5f5" }}>
            <TableRow>
              <TableCell style={{ padding: "8px" }}>Account Name</TableCell>
              {colSpan >= visibleColumnsAtEachSize.account && (
                <TableCell style={{ padding: "8px" }}>Address</TableCell>
              )}
              <TableCell style={{ padding: "8px" }}>Physical Case</TableCell>
              <TableCell style={{ padding: "8px" }}>Case Eqv</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tops.map((account, index) => {
              const {
                acc_name,
                account_city,
                account_address,
                acc_id,
                cases_eqv,
                cases,
              } = account;
              return (
                <TableRow
                  key={index}
                  className={classes.selectableRow}
                  onClick={() => handleRowClick(account)}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  <TableCell>
                    <RouterLink
                      style={{
                        color: "black",
                        display: "-webkit-box",
                        WebkitLineClamp: 3,
                        WebkitBoxOrient: "vertical",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                      }}
                      to={"/app/accountdetails/" + acc_id}
                    >
                      {acc_name} - {account_city}
                    </RouterLink>
                  </TableCell>
                  {colSpan >= visibleColumnsAtEachSize.account && (
                    <TableCell>
                      {account_address} - {account_city}
                    </TableCell>
                  )}
                  <TableCell style={{ padding: "8px" }}>
                    {formatNumber(cases)}
                  </TableCell>
                  <TableCell style={{ padding: "8px" }}>
                    {formatNumber(cases_eqv)}
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Dialog open={modalOpen} onClose={handleClose} fullWidth maxWidth="md">
        <DialogTitle>
          Account Details
          <IconButton
            aria-label="close"
            onClick={handleClose}
            sx={{
              position: "absolute",
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>
          {selectedAccount && (
            <TopAccountProducts
              acc_id={selectedAccount.acc_id}
              productHash={productHash}
              setProductHash={setProductHash}
              dists={selectedDistributors}
              dateStart={dateStart}
              dateEnd={dateEnd}
              colspan={colSpan}
            />
          )}
        </DialogContent>
      </Dialog>
    </Box>
  );
});

export default TopAccounts;
