import { Box } from "@mui/material";
// import { ReportSummary } from "../../views/ReportBuilder/components/BuilderSteps";
const ReportPreview = ({
  fieldGroups,
  previewRequestBody,
  setPreviewRequestBody,
  filterReports,
}) => {
  return (
    <Box>
      {/* <ReportSummary
        flexDirection="row"
        requestBody={previewRequestBody}
        readOnly={true}
        {...{
          fieldGroups,
          filterReports,
        }}
      /> */}
    </Box>
  );
};

export default ReportPreview;
