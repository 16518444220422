import { useEffect, useMemo, useState } from "react";
import {
  Box,
  Chip,
  MobileStepper,
  Button,
  Typography,
  Divider,
  IconButton,
} from "@mui/material";
import {
  CheckCircle,
  Close,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  LastPage,
  Settings,
} from "@mui/icons-material";
import { FieldsContent, FiltersContent } from "./filters/FieldFilterContent";
import GroupingSection from "./grouping/GroupingSection";
import { DateRangeFilter } from "./filters/DateRangeFilter";
import { DateTime } from "luxon";
import {
  states_and_codes,
  premise_types,
} from "../report_builder_filter_values";

const generateTimeString = (time) => {
  const { granularity, offset, periods } = time;
  if (granularity === "month") {
    const end = DateTime.fromObject({
      year: Math.floor(offset / 100),
      month: offset % 100,
    });

    const start = end.minus({
      months: periods - 1,
    });
    return start.toFormat("MMM yy") + " to " + end.toFormat("MMM yy");
  }
  if (granularity === "year") {
    return offset - (periods - 1) + " to " + offset;
  }
  if (granularity === "quarter") {
    const q = offset % 10;
    const y = Math.floor(offset / 10);
    let end = `Q${q} ${y}`;
    if (periods === 1) {
      return end;
    }
    const endDate = DateTime.fromObject({
      year: y,
      month: q > 1 ? (q - 1) * 3 + 1 : 1,
    });
    const start = endDate.minus({
      months: (periods - 1) * 3,
    });
    const startString = `Q${start.quarter} ${start.year}`;
    const endString = `Q${endDate.quarter} ${endDate.year}`;
    return `${startString} - ${endString}`;
  }
  return JSON.stringify(time);
};

const Summary = ({
  requestBody,
  fieldGroups,
  setWizardStep,
  filterReports,
}) => {
  console.log({ fieldGroups });
  const flattened = Object.values(fieldGroups)
    .map((fg) => fg.fields)
    .flat();

  const arrToNiceNames = (arr) => {
    return arr.map((a) => flattened.find((f) => f.value === a).label);
  };

  return (
    <Box>
      <Box py={1}>
        <Typography variant="h6">Columns</Typography>
        <Divider />
        <Box display="flex" justifyContent={"space-between"} py={1}>
          <Button
            size="small"
            variant="outlined"
            fontSize="small"
            onClick={() => setWizardStep(0)}
            startIcon={<Settings />}
          >
            Selection
          </Button>
          <Button
            size="small"
            variant="outlined"
            fontSize="small"
            onClick={() => setWizardStep(1)}
            startIcon={<Settings />}
          >
            Order
          </Button>
        </Box>
        <Divider />
        <Box py={1}>
          {arrToNiceNames(requestBody.select.dimensions).map((s, index) => (
            <Chip
              key={s}
              label={index + 1 + ". " + s}
              sx={{
                marginRight: "2px",
                marginBottom: "2px",
              }}
            />
          ))}
        </Box>
      </Box>
      <Box py={1}>
        <Box display={"flex"} justifyContent={"space-between"} pb={1}>
          <Typography variant="h6">Filters</Typography>
          <Button
            size="small"
            variant="outlined"
            fontSize="small"
            onClick={() => setWizardStep(2)}
            startIcon={<Settings />}
          >
            Change
          </Button>
        </Box>
        <Divider />
        {filterReports.map((fr) => (
          <Box key={fr.group}>
            <Typography variant="subtitle1">
              {fr.group} ({fr.names.length})
            </Typography>
            <Divider />
            <Typography variant="body2">
              {fr.names.slice(0, 5).join(", ")}
            </Typography>
            {fr.names.length > 5 && (
              <Typography variant="body2" fontStyle={"italic"} color="primary">
                + {fr.names.length - 5} more...
              </Typography>
            )}
          </Box>
        ))}
      </Box>
      <Box py={1}>
        <Box display={"flex"} justifyContent={"space-between"} pb={1}>
          <Typography variant="h6">Dates & Values</Typography>
          <Button
            size="small"
            variant="outlined"
            fontSize="small"
            onClick={() => setWizardStep(3)}
            startIcon={<Settings />}
          >
            Change
          </Button>
        </Box>
        <Divider />
        <Typography variant="subtitle1">
          {generateTimeString(requestBody.filters.time)}
        </Typography>
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box flex={4}>
            <Typography variant="subtitle2">Include totals</Typography>
          </Box>
          <Box flex={1}>
            {requestBody.comparisons.includeTotals ? (
              <CheckCircle fontSize="small" />
            ) : (
              <Close fontSize="small" />
            )}
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box flex={4}>
            <Typography variant="subtitle2">Include differences</Typography>
          </Box>
          <Box flex={1}>
            {requestBody.comparisons.includeDifferences ? (
              <CheckCircle fontSize="small" />
            ) : (
              <Close fontSize="small" />
            )}
          </Box>
        </Box>
        <Divider />
        <Box
          display="flex"
          alignItems={"center"}
          justifyContent={"space-between"}
        >
          <Box flex={4}>
            <Typography variant="subtitle2">Comparison period</Typography>
          </Box>
          <Box flex={1}>
            {requestBody.comparisons.comparisonPeriod ? (
              <CheckCircle fontSize="small" />
            ) : (
              <Close fontSize="small" />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
const sectionHeaderStyle = {
  fontWeight: "bold",
  fontSize: 18,
  marginBottom: 20,
  component: "div",
};
const BuilderSteps = ({
  fieldGroups,
  expandedSection,
  setExpandedSection,
  selectedFields,
  setSelectedFields,
  searchQueries,
  setSearchQueries,
  selectedMetric,
  handleMetricSelection,
  selectedFilter,
  setSelectedFilter,
  metrics,
  handleFieldSelection,
  onFilterChange,
  requestBody,
  setRequestBody,
  initialGroupByField,
  initialOtherFields,
  submit,
}) => {
  const [wizardStep, setWizardStep] = useState(0);
  const [filterReports, setFilterReports] = useState([]);
  const [filteringArrays, setFilteringArrays] = useState({
    dists: [],
  });
  const handleNext = () => {
    setWizardStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setWizardStep((prevActiveStep) => prevActiveStep - 1);
  };

  useEffect(() => {
    const ls = [];
    const filters = requestBody.filters.dimensions;
    const derivedDistNames = filteringArrays.dists.reduce((acc, cur) => {
      return {
        ...acc,
        [cur.id]: cur.name,
      };
    }, {});
    const distnames = filters.dist_id || [];
    if (distnames.length > 0) {
      ls.push({
        group: "Distributors",
        names: distnames.map((dn) => derivedDistNames[dn] || dn),
      });
    }
    if (filters.state_id) {
      const stateObj = states_and_codes.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.id]: cur.state,
        };
      }, {});

      ls.push({
        group: "States",
        names: filters.state_id.map((f) => stateObj[f] || f),
        // use: stateObj,
      });
    }
    if (filters.premise_id) {
      const premiseObj = premise_types.reduce((acc, cur) => {
        return {
          ...acc,
          [cur.premise_id]: cur.premise_name,
        };
      }, {});
      ls.push({
        group: "Premise Types",
        names: filters.premise_id.map((f) => premiseObj[f] || f),
      });
    }
    if (filters.prod_id) {
      const prodsObj = Object.values(filteringArrays.prods)
        .flat()
        .reduce((acc, prod) => {
          return {
            ...acc,
            [prod.id]: prod.name,
          };
        }, {});
      ls.push({
        group: "Products",
        names: filters.prod_id.map((f) => prodsObj[f] || f),
      });
    }
    setFilterReports(ls);
  }, [filteringArrays, requestBody]);
  const selectFields = (
    <FieldsContent
      fieldGroups={fieldGroups}
      expandedSection={expandedSection}
      setExpandedSection={setExpandedSection}
      selectedFields={selectedFields}
      setSelectedFields={setSelectedFields}
      searchQueries={searchQueries}
      setSearchQueries={setSearchQueries}
      onFieldSelection={handleFieldSelection}
      onFilterChange={onFilterChange}
      requestBody={requestBody}
      setRequestBody={setRequestBody}
      initialGroupByFields={[initialGroupByField]}
      initialOtherFields={initialOtherFields}
    />
  );
  const orderGroups = (
    <Box>
      <Box sx={{ borderBottom: "1px solid #EAEAEA", mb: 2, pb: 4 }}>
        <Box
          sx={{
            borderRadius: 6,
            backgroundColor: "#F4F6F8",
            maxHeight: 500,
            overflowY: "scroll",
          }}
        >
          <GroupingSection
            selectedFields={selectedFields}
            fieldGroups={fieldGroups}
            {...{
              requestBody,
              setRequestBody,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
  const wheres = (
    <FiltersContent
      selectedFilter={selectedFilter}
      setSelectedFilter={setSelectedFilter}
      selectedFields={selectedFields}
      requestBody={requestBody}
      setRequestBody={setRequestBody}
      filteringArrays={filteringArrays}
      setFilteringArrays={setFilteringArrays}
      onFilterChange={(filterState) => {
        setRequestBody((prev) => ({
          ...prev,
          filters: {
            ...prev.filters,
            ...filterState,
          },
        }));
      }}
    />
  );

  const ReviewAndSubmit = () => (
    <Box>
      <Summary
        requestBody={requestBody}
        fieldGroups={fieldGroups}
        setWizardStep={setWizardStep}
        filterReports={filterReports}
      />
      <Box py={2}>{submit}</Box>
    </Box>
  );
  const step = useMemo(() => {
    const steps = [
      {
        slug: "select",
        title: "",
        jsx: selectFields,
      },
      {
        slug: "order",
        title: "Order fields to build report",
        jsx: orderGroups,
      },
      {
        slug: "filter",
        title: "",
        jsx: wheres,
      },
      {
        slug: "tune",
        title: "",
        jsx: (
          <DateRangeFilter
            requestBody={requestBody}
            onChange={setRequestBody}
            selectedMetric={selectedMetric}
            setSelectedMetric={handleMetricSelection}
            metrics={metrics}
          />
        ),
      },
      {
        title: "",
        jsx: <ReviewAndSubmit />,
      },
    ];
    return steps[wizardStep] || { title: "", jsx: <></> };
  }, [
    wizardStep,
    requestBody,
    selectedFilter,
    expandedSection,
    selectedFields,
  ]);
  return (
    <Box p={2}>
      <Box display={"flex"} alignItems={"center"}>
        <Box flex={9}>
          <MobileStepper
            variant="dots"
            steps={5}
            position="static"
            activeStep={wizardStep}
            sx={{ maxWidth: 400, flexGrow: 1, px: 0 }}
            nextButton={
              <Button
                size="small"
                onClick={handleNext}
                disabled={wizardStep + 1 === 5}
              >
                Next
                <KeyboardArrowRight />
              </Button>
            }
            backButton={
              <Button
                size="small"
                onClick={handleBack}
                disabled={wizardStep === 0}
              >
                <KeyboardArrowLeft />
                Back
              </Button>
            }
          />
        </Box>
        <Box flex={1}>
          <IconButton
            onClick={() => setWizardStep(4)}
            sx={{ px: 0 }}
            disabled={wizardStep === 4}
          >
            <LastPage />
          </IconButton>
        </Box>
      </Box>
      <Box>
        {step.title.length > 1 && (
          <Typography style={sectionHeaderStyle} sx={{ mb: 1, mt: 2 }}>
            {step.title}
          </Typography>
        )}
        {/** markup for the element comes next */}
        {step.jsx}
      </Box>
    </Box>
  );
};
export default BuilderSteps;
