import APICallWrapper from "./APICallWrappers";
import client from "./client";

const getBookMarks = async ({ mid, uid }) => {
  const key = `getBookMarks-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading bookmarks...",
    "Bookmarks loaded successfully.",
    "Failed to load bookmarks.",
    () =>
      client.get("/bookmarks", {
        mid,
        uid,
      })
  );
};

const getBookMark = async (bid) => {
  const key = `getBookMark-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading bookmark...",
    "Bookmark loaded successfully.",
    "Failed to load bookmark.",
    () => client.get(`/bookmarks/${bid}`)
  );
};

const deleteBookMark = async (bid) => {
  const key = `deleteBookMark-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting bookmark...",
    "Bookmark deleted successfully.",
    "Failed to delete bookmark.",
    () => client.del(`/bookmarks/${bid}`)
  );
};

const saveBookMark = async (payload) => {
  const key = `saveBookMark-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Saving bookmark...",
    "Bookmark saved successfully.",
    "Failed to save bookmark.",
    () => client.post("/bookmarks", payload)
  );
};

export default {
  getBookMarks,
  getBookMark,
  saveBookMark,
  deleteBookMark,
};
