import { Parser } from "json2csv";
import ExcelJS from "exceljs";
import { cloneDeep } from "lodash";
import logo from "../../../components/logo.png";

const formatForExport = (headers, rows) => {
  const clonedRows = cloneDeep(rows);

  //const colNames = this.state.localPivot.columns.map((c) => c.field);
  const cleanNumber = (val) => {
    if (typeof val === "string" || !val) {
      return val;
    }
    // return an integer unchanged
    if (Number.isInteger(val)) {
      return val;
    }
    // return a float capped to 2 decimal points
    if (typeof val === "number") {
      return parseFloat(val.toFixed(2));
    }
    // if it's a Set, return size of Set
    if (val && val.size) {
      return val.size;
    }
    // return non-numeric unchanged
    return val;
  };
  return clonedRows.map((dr) => {
    let row = {};
    headers.forEach((header) => {
      row[header.headerName] = cleanNumber(dr[header.field]);
    });
    return row;
  });
};

const exportDataCSV = async (headers, rows, preset) => {
  const data = formatForExport(headers, rows);
  if (data.length === 0) {
    this.setState({
      modalOpen: true,
    });
    return;
  }
  const parser = new Parser();
  const csv = parser.parse(data);
  const blob = new Blob(["\ufeff", csv]);
  const url = URL.createObjectURL(blob);

  fetch(url)
    .then((response) => {
      // Trigger the download
      response.blob().then((blob) => {
        const downloadUrl = URL.createObjectURL(blob);
        const downloadLink = document.createElement("a");
        downloadLink.href = downloadUrl;
        downloadLink.download = `report_${preset}.csv`;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
      });
    })
    .finally(() => {
      // Dispatch the "exportComplete" event after the download is finished
      setTimeout(() => {
        window.dispatchEvent(new Event("exportComplete"));
      }, 0);
    });
};

const exportDataXLSX = async (headers, rows, preset) => {
  const data = formatForExport(headers, rows);
  if (data.length === 0) {
    this.setState({
      modalOpen: true,
    });
    return;
  }

  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Sheet1");

  // Define title row styles
  const titleRowStyle = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "142F72" } },
    font: { color: { argb: "FFFFFF" }, bold: true },
  };

  // Define alternating row styles
  const alternateRowStyle1 = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "CAD6FF" } },
    font: { color: { argb: "000000" } },
  };
  const alternateRowStyle2 = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } },
    font: { color: { argb: "000000" } },
  };

  // Add header row
  const headerRow = worksheet.addRow(Object.keys(data[0]));
  headerRow.eachCell((cell) => {
    cell.style = titleRowStyle;
  });

  // Add data rows
  data.forEach((rowData, rowIndex) => {
    const row = worksheet.addRow(Object.values(rowData));

    // Apply alternating styles for data rows
    const rowStyle =
      rowIndex % 2 === 0 ? alternateRowStyle1 : alternateRowStyle2;

    // Ensure all cells in the row, including empty ones, are styled
    for (let colIndex = 1; colIndex <= headerRow.cellCount; colIndex++) {
      const cell = row.getCell(colIndex);

      // Apply the row style to each cell
      cell.style = rowStyle;

      // If the cell value is null or undefined, set it to an empty string
      if (cell.value === null || cell.value === undefined) {
        cell.value = "";
      }
    }
  });

  // Add the logo
  try {
    const response = await fetch(logo);
    const blob = await response.blob();
    const reader = new FileReader();

    reader.onloadend = async () => {
      const base64data = reader.result.split(",")[1];
      const imageId = workbook.addImage({
        base64: base64data,
        extension: "png",
      });

      const lastRow = worksheet.lastRow.number;
      worksheet.addImage(imageId, {
        tl: { col: 0, row: lastRow + 2 },
        ext: { width: 169.54, height: 50 },
      });

      // Generate the Excel file
      const buffer = await workbook.xlsx.writeBuffer();
      const fileContent = new Blob([buffer], {
        type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      });
      const url = URL.createObjectURL(fileContent);
      const downloadLink = document.createElement("a");
      downloadLink.href = url;
      downloadLink.download = `report_${preset}.xlsx`;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);

      // Dispatch the "exportComplete" event
      window.dispatchEvent(new Event("exportComplete"));
    };

    reader.readAsDataURL(blob);
  } catch (error) {
    console.error("Failed to add the logo image:", error);
  }
};

export { exportDataCSV, exportDataXLSX };
