import { createContext, useContext, useReducer } from "react";

const DepletionsContainerContext = createContext(null);
const DepletionsContainerDispatchContext = createContext(null);

export function DepletionsContainerProvider({ children }) {
  const [depletionsContainer, depletionsContainerDispatch] = useReducer(
    function depletionsReducer(depletionsContainer, action) {
      switch (action.type) {
        case "setDepletions": {
          return {
            ...depletionsContainer,
            depletions: action.depletions,
            setDepletionListCount:
              depletionsContainer.setDepletionListCount + 1,
          };
        }
        case "setDepletionsFiltered": {
          return {
            ...depletionsContainer,
            globallyFiltered: action.filteredDepletions,
          };
        }
        default: {
          throw Error("Unknown action: " + action.type);
        }
      }
    },
    {
      depletions: [],
      setDepletionListCount: 0,
      globallyFiltered: {
        current: [],
        previous: [],
      },
    }
  );

  return (
    <DepletionsContainerContext.Provider value={depletionsContainer}>
      <DepletionsContainerDispatchContext.Provider
        value={depletionsContainerDispatch}
      >
        {children}
      </DepletionsContainerDispatchContext.Provider>
    </DepletionsContainerContext.Provider>
  );
}

export function useDepletionsContainerContext() {
  return useContext(DepletionsContainerContext);
}

export function useDepletionsContainerDispatchContext() {
  return useContext(DepletionsContainerDispatchContext);
}
