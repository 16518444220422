import React, { useState, useCallback, useMemo } from "react";
import {
  DataGridPremium,
  GridToolbarExport,
  GridToolbarContainer,
} from "@mui/x-data-grid-premium";
import { API } from "aws-amplify";
import { DateTime } from "luxon";
import { Box, Paper, Typography } from "@mui/material";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react-lite";
import FilterMenu from "../menu/FilterMenu";
import { Check, Close } from "@mui/icons-material";
import Page from "../../../components/Page";
const GapReportView = observer(() => {
  const { allDistributors, allBrands, allProducts } = useStore();
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [uniqueProducts, setUniqueProducts] = useState(new Map());
  const [filters, setFilters] = useState({
    distributorId: [],
    selectedBrands: [],
    premiseTypes: ["ON-PREMISE", "OFF-PREMISE"],
    dateRange: [
      DateTime.now().minus({ days: 120 }).toISODate(),
      DateTime.now().toISODate(),
    ],
  });

  const distributors = [...allDistributors].sort((a, b) =>
    a.name.localeCompare(b.name)
  );
  const brands = [...allBrands].sort();
  const premiseOptions = ["ON-PREMISE", "OFF-PREMISE"];

  const fetchGapAnalysisData = useCallback(
    async (newFilters) => {
      setLoading(true);
      try {
        const {
          distributorId,
          selectedBrands,
          premiseTypes,
          dateRange,
          selectedProducts,
        } = newFilters;

        const response = await API.get(
          "backendGateway",
          "/stats/gap-analysis",
          {
            queryStringParameters: {
              distributorIds: distributorId,
              productBrands: selectedBrands,
              productIds: selectedProducts,
              premiseTypes,
              startDate: dateRange[0],
              endDate: dateRange[1],
              page,
              pageSize,
            },
          }
        );

        const responseData = response.results || [];
        setData(responseData);

        const newProducts = new Map();
        responseData.forEach((account) =>
          account.products.forEach((product) =>
            newProducts.set(product.prod_id, {
              prod_id: product.prod_id,
              prod_name: product.prod_name,
              brand: product.brand,
            })
          )
        );
        setUniqueProducts(newProducts);
      } catch (error) {
        console.error("Error fetching data", error);
        setData([]);
      } finally {
        setLoading(false);
      }
    },
    [filters, page, pageSize, allDistributors]
  );

  const productColumns = Array.from(uniqueProducts.values()).map((product) => ({
    field: product.prod_id.toString(),
    headerName: `${product.brand} - ${product.prod_name}`,
    width: 150,
    renderCell: (params) => {
      const isSold = params.row.products.find(
        (p) => p.prod_id.toString() === params.field
      )?.is_sold;
      return (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          {isSold ? "Sold" : "Not Sold"}
          {isSold ? (
            <Check style={{ color: "green" }} />
          ) : (
            <Close style={{ color: "red" }} />
          )}
        </div>
      );
    },
    valueGetter: (value, row) => {
      return row.products.find((p) => p.prod_id === product.prod_id)?.is_sold
        ? "sold"
        : "unsold";
    },
  }));

  const rows = useMemo(
    () =>
      data.map((account) => ({
        id: account.acc_id,
        acc_name: account.acc_name,
        premise: account.premise,
        products: account.products,
      })),
    [data]
  );

  const columns = [
    { field: "acc_name", headerName: "Account", width: 200 },
    { field: "premise", headerName: "Premise", width: 150 },
    ...productColumns,
  ];

  const handleApplyFilters = (newFilters) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      ...newFilters,
      page: 0, // Reset to first page
    }));
    fetchGapAnalysisData(newFilters);
  };

  const handleResetFilters = () => {
    setFilters({
      distributorId: [],
      selectedBrands: [],
      premiseTypes: ["ON-PREMISE", "OFF-PREMISE"],
      dateRange: [
        DateTime.now().minus({ days: 120 }).toISODate(),
        DateTime.now().toISODate(),
      ],
    });
    setData([]);
    setUniqueProducts(new Map());
  };

  const CustomToolbar = () => (
    <GridToolbarContainer>
      <GridToolbarExport
        printOptions={{ disableToolbarButton: true }}
        excelOptions={{
          fileName: "GapReport",
        }}
      />
    </GridToolbarContainer>
  );

  return (
    <Page title="Report Gap">
      <Box component={Paper} elevation={3} p={3} m={3} borderRadius={8}>
        <Typography variant="h4" gutterBottom>
          120 Day Gap Report
        </Typography>
        <FilterMenu
          distributors={distributors}
          brands={brands}
          products={allProducts}
          premiseTypes={premiseOptions}
          unitOfMeasures={["Units", "Cases", "Case Eqv."]}
          onApplyFilters={handleApplyFilters}
          onResetFilters={handleResetFilters}
          showDates={false} // Hide date range
          showUnitOfMeasure={false} // Hide unit of measure
          defaultDateRange={{
            start: DateTime.now().minus({ days: 120 }).toISODate(),
            end: DateTime.now().toISODate(),
          }}
        />
        <div style={{ height: 600, width: "100%" }}>
          <DataGridPremium
            rows={rows}
            sx={{
              "& .MuiDataGrid-overlay": {
                backgroundColor: "rgba(255, 255, 255, 0.7)",
                zIndex: 1,
              },
            }}
            columns={columns}
            loading={loading}
            slots={{
              toolbar: CustomToolbar,
            }}
            pagination
            pageSize={pageSize}
            onPageChange={(newPage) => setPage(newPage)}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
          />
        </div>
      </Box>
    </Page>
  );
});

export default GapReportView;
