import React, { useState, useEffect, useRef } from "react";
import { API } from "aws-amplify";
import LuxonUtils from "@date-io/luxon";
import {
  Container,
  CircularProgress,
  Button,
  ButtonGroup,
  MenuItem,
  Popper,
  Grow,
  MenuList,
  Paper,
  ClickAwayListener,
  Grid2,
} from "@mui/material";
import Page from "../../../components/Page";
import Profile from "./Profile";
import Targets from "../../targets";
import { useParams } from "react-router-dom";
import { trackEvent } from "../../../api/analytics";
import TeamTargetModal from "./TeamTargetModal";
import useApi from "../../../hooks/useApi";
import teamsApi from "../../../api/team";
import listsApi from "../../../api/lists";
import { useStore } from "../../../stores/StoreContext";
import ProfileActivityTracker from "./ProfileActivityTracker";
import { Loader } from "@aws-amplify/ui-react";
import { makeStyles } from "@mui/styles";
import { ArrowDropDown } from "@mui/icons-material";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";

const filterTitles = [
  "All",
  "account_checkin",
  "contact_created",
  "media_upload",
  "form_response",
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const TeamProfileView = () => {
  const [fromDate, setFromDate] = useState(DateTime.local().startOf("month"));
  const [toDate, setToDate] = useState(DateTime.local());
  const [activeDateAction, setActiveDateAction] = useState("This Month");
  const dateFilterButtons = ["All", "This Month", "This Week", "Today"];
  const [openFilterDate, setOpenFilterDate] = useState(false);
  const [formattedData, setFormattedData] = useState([]);
  const [activitiesData, setActivityData] = useState([]);
  const [dateChanged, setDateChanged] = useState(false);
  const [openFilter, setOpenFilter] = useState(false);
  const [openTarget, setTargetOpen] = useState(false);
  const [dataFilter, setDataFilter] = useState("All");
  const getTeamsApi = useApi(teamsApi.getTeamTarget);
  const getListsApi = useApi(listsApi.getLists);
  const [loading, setLoading] = useState(true);
  const anchorRefDate = useRef(null);
  const anchorRef = useRef(null);
  const classes = useStyles();
  const store = useStore();
  const { id } = useParams();
  const { userInfo } = store;
  const { roles } = userInfo;

  const isAdmin = () => {
    return roles.includes("ADMIN");
  };
  const admin = isAdmin();

  useEffect(() => {
    setLoading(true);
    getListOfAllData().then((res) => {
      filterData(res);
    });
  }, [fromDate, toDate, id]);

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:TeamMember");
    getLists();
    getTargets();
  }, []);

  const resetDateRangesToDefault = () => {
    setFromDate(DateTime.local().startOf("month"));
    setToDate(DateTime.local());
    setActiveDateAction("This Month");
    setDateChanged(false);
  };

  const filterData = (res) => {
    const from = fromDate.toMillis();
    const to = toDate.toMillis();

    const filtered = res.filter((item) => {
      const createdTime = DateTime.fromISO(item.created).toMillis();
      return (
        createdTime >= from &&
        createdTime <= to &&
        item.user_id === parseInt(id, 10) &&
        (dataFilter === "All" || item.type === dataFilter)
      );
    });

    setFormattedData(filtered);
    setLoading(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenFilter(false);
    }
  }

  const handleDateChange = (type, date) => {
    setDateChanged(true);
    setActiveDateAction("Custom");
    const luxonDate = date ? DateTime.fromJSDate(date.toJSDate()) : null;

    if (type === "to") {
      setToDate(luxonDate);
    } else if (type === "from") {
      setFromDate(luxonDate);
    }
  };

  const setFilter = (filter) => {
    if (filter === "All") {
      setFormattedData(
        activitiesData.filter(
          (event) =>
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate) &&
            event.user_id === parseInt(id, 10)
        )
      );
    } else {
      setFormattedData(
        activitiesData.filter(
          (event) =>
            event.type === filter &&
            new Date(event.created) >= new Date(fromDate) &&
            new Date(event.created) <= new Date(toDate) &&
            event.user_id === parseInt(id, 10)
        )
      );
    }
    setOpenFilter(false);
    setDataFilter(filter);
  };

  const handleToggle = (param) => {
    if (param === "filter") {
      setOpenFilter((prevOpen) => !prevOpen);
    } else {
      setOpenFilterDate((prevOpen) => !prevOpen);
    }
  };

  const getListOfAllData = async () => {
    try {
      const data = await API.get("backendGateway", "/teams/activities", {
        queryStringParameters: {
          mid: userInfo.mid,
        },
      });
      setActivityData(data.results);
      return data.results;
    } catch (err) {
      console.log("error fetching visits data..", err);
    }
  };

  const quickDateActions = (action) => {
    setDateChanged(true);
    setDataFilter("All");
    setActiveDateAction(action);

    const now = DateTime.local();

    if (action === "This Month") {
      setFromDate(now.startOf("month"));
      setToDate(now.endOf("day"));
    } else if (action === "This Week") {
      setFromDate(now.startOf("week"));
      setToDate(now.endOf("day"));
    } else if (action === "Today") {
      setFromDate(now.startOf("day"));
      setToDate(now.endOf("day"));
    } else if (action === "All") {
      setFromDate(DateTime.fromMillis(0));
      setToDate(now.endOf("day"));
    }
  };

  const handleClose = () => {
    setOpenFilterDate(false);
    setOpenFilter(false);
  };

  async function getTargets() {
    getTeamsApi.request({
      mid: userInfo.mid,
      uid: id,
      limit: 1000,
    });
  }

  async function getLists() {
    getListsApi.request({
      mid: userInfo.mid,
      uid: id,
      limit: 1000,
    });
  }

  const handleTargetClose = (value) => {
    setTargetOpen(false);
    getTargets();
  };

  return (
    <Page className={classes.root} title="Team">
      <TeamTargetModal
        open={openTarget}
        onClose={handleTargetClose}
        userInfo={userInfo}
      />
      <Container maxWidth="lg">
        <Grid2 container spacing={3} mb={2}>
          <Grid2 size={4}>
            <Profile userInfo={userInfo} />
          </Grid2>

          <Grid2 size={8}>
            {(getTeamsApi.loading ||
              (userInfo.uid == id && getTeamsApi.data?.length > 0) ||
              admin) && (
              <div>
                <div>
                  {getTeamsApi.loading && (
                    <div>
                      <CircularProgress />
                    </div>
                  )}
                </div>
                {((userInfo.uid == id && getTeamsApi.data?.length > 0) ||
                  admin) && <Targets userInfo={userInfo} />}
              </div>
            )}
          </Grid2>
        </Grid2>

        <Paper
          style={{
            display: "flex",
            width: "100%",
            padding: "10px",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            paddingTop: "20px",
            paddingBottom: "20px",
            marginBottom: "20px",
          }}
        >
          <div
            style={{
              fontSize: "26px",
              fontWeight: "500",
              marginLeft: "10px",
            }}
          >
            Activity
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              gap: "10px",
            }}
          >
            <ButtonGroup size="small" sx={{ maxHeight: "60px" }}>
              <Button
                size="small"
                disabled
                style={{
                  cursor: "unset",
                  color: "black",
                  borderColor: "1px solid rgba(0, 0, 0, 0.23)",
                }}
                onClick={() => handleToggle("filter")}
                sx={{ maxHeight: "60px" }}
              >
                Filter Type:
              </Button>
              <Button
                size="small"
                ref={anchorRef}
                aria-controls={openFilter ? "menu-list-grow" : undefined}
                aria-haspopup="true"
                onClick={() => handleToggle("filter")}
                title={dataFilter}
                sx={{ maxHeight: "60px" }}
              >
                {dataFilter}
                <ArrowDropDown />
              </Button>
            </ButtonGroup>
            <div
              style={{
                borderLeft: "1px solid lightGrey",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
            <Popper
              open={openFilter}
              anchorEl={anchorRef.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={openFilter}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {filterTitles.map((title) => (
                          <MenuItem
                            key={title}
                            onClick={() => setFilter(title)}
                          >
                            {title.replace("_", " ").toUpperCase()}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="START DATE"
                disableFuture
                value={fromDate}
                onChange={(e) => handleDateChange("from", e)}
                sx={{ maxHeight: "60px", maxWidth: "180px" }}
              />
              <DatePicker
                label="END DATE"
                disableFuture
                value={toDate}
                onChange={(e) => handleDateChange("to", e)}
                sx={{ maxHeight: "60px", maxWidth: "180px" }}
              />
            </LocalizationProvider>
            <div
              style={{
                borderLeft: "1px solid lightGrey",
                marginLeft: "5px",
                marginRight: "5px",
              }}
            />
            <Button
              style={{
                border: "1px solid lightGrey",
                paddingTop: "6px",
                paddingBottom: "6px",
              }}
              ref={anchorRefDate}
              aria-controls={openFilterDate ? "menu-list-grow" : undefined}
              aria-haspopup="true"
              onClick={() => handleToggle("date")}
              title={dataFilter}
              size="large"
            >
              {activeDateAction}
              <ArrowDropDown />
            </Button>
            <Popper
              open={openFilterDate}
              anchorEl={anchorRefDate.current}
              role={undefined}
              transition
              disablePortal
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === "bottom" ? "center top" : "center bottom",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleClose}>
                      <MenuList
                        autoFocusItem={openFilterDate}
                        id="menu-list-grow"
                        onKeyDown={handleListKeyDown}
                      >
                        {dateFilterButtons.map((title) => (
                          <MenuItem
                            key={title}
                            onClick={() => quickDateActions(title)}
                          >
                            {title}
                          </MenuItem>
                        ))}
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
            {dateChanged ? (
              <Button
                style={{
                  border: "1px solid lightGrey",
                  paddingTop: "6px",
                  paddingBottom: "6px",
                  color: "#1A4399",
                }}
                onClick={() => {
                  resetDateRangesToDefault();
                }}
              >
                Reset Date
              </Button>
            ) : null}
          </div>
        </Paper>
        {!loading ? (
          <ProfileActivityTracker
            data={formattedData.sort(
              (a, b) => new Date(b.created) - new Date(a.created)
            )}
          />
        ) : (
          <Loader />
        )}
      </Container>
    </Page>
  );
};

export default TeamProfileView;
