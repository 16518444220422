import { styled } from "@mui/system";
import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import NavBar from "./NavBar";
import TopBar from "./TopBar";
import Footer from "./Footer";
import MegaFilter from "../../components/megaFilter";
import useSalesData from "../../components/boot/useSalesDataHook";
import useDepletions from "../../components/boot/useDepletions";
import UniversalLoadingIndicator from "./UniversalLoadingIndicator";
import { Box } from "@mui/material";

const Root = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.background.dark,
  height: "100%",
  overflow: "visible",
  width: "100%",
  flexDirection: "column",
}));

// Make one wrapper that changes its paddingLeft based on the mini prop
const Wrapper = styled("div", {
  shouldForwardProp: (prop) => prop !== "mini",
})(({ theme, mini }) => ({
  flex: "1 1 auto",
  overflow: "visible",
  paddingTop: 64,
  paddingBottom: 60,
  [theme.breakpoints.up("lg")]: {
    paddingLeft: mini ? 60 : 256,
  },
}));

const ContentContainer = styled("div")({
  display: "flex",
  overflow: "visible",
});

const Content = styled("div")({
  height: "100%",
  width: "100%",
  overflow: "visible",
});

const DashboardLayout = () => {
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const [mini, setMini] = useState(true);

  // Hook to load sales data other than depletions
  useSalesData();
  // hook to load depletions
  useDepletions();
  return (
    <Root>
      <TopBar
        onMobileNavOpen={() => {
          setMobileNavOpen(true);
          setMini(false);
        }}
      />
      <Box sx={{ display: { xs: "none", lg: "block" } }}>
        <NavBar
          onMobileClose={() => setMobileNavOpen(false)}
          openMobile={isMobileNavOpen}
          mini={mini}
          setMini={setMini}
        />
      </Box>
      <UniversalLoadingIndicator />

      {/* Always render the same Wrapper, but pass mini as a prop */}
      <Wrapper mini={mini}>
        <ContentContainer>
          <Content>
            <MegaFilter />
            <Outlet />
          </Content>
        </ContentContainer>
      </Wrapper>

      <Footer />
    </Root>
  );
};

export default DashboardLayout;
