import { useEffect, useMemo, useState } from "react";
import {
  Card,
  CardContent,
  Box,
  FormControl,
  InputLabel,
  Typography,
  Select,
  MenuItem,
  Checkbox,
  TextField,
  Dialog,
  Button,
  FormControlLabel,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/Help";
import { DateTime } from "luxon";
import { makeStyles } from "@mui/styles";
import StText from "./fields/StText";
import StTextArea from "./fields/StTextArea";
import StNumber from "./fields/StNumber";
import StSelect from "./fields/StSelect";
import StDate from "./fields/StDate";
import StCheckbox from "./fields/StCheckbox";
import StRadio from "./fields/StRadio";
import StDynamicOptions from "./fields/StDynamicOptions";
import StContact from "./fields/StContact";
import StAccount from "./fields/StAccount";
import StFile from "./fields/StFile";
import FieldOptions from "./FieldOptions";
import ConditionalGroupEditor from "./ConditionalGroupEditor";
import conditionalTutorial from "./conditionalTutorial.gif";
import { Delete, DragHandle, FileCopy, Settings } from "@mui/icons-material";

const useStyles = makeStyles(() => ({
  select: {
    minWidth: 200,
  },
}));

const dummyTextAnswer = { value: "" };
const dummyCheckAnswer = { value: [] };
const dummyDateAnswer = { value: DateTime.now() };

const VariantSelect = ({ field, changeVariant }) => {
  const { variant, variants } = field;
  const [val, setVal] = useState(variant);
  const classes = useStyles();
  const changeVal = (event) => {
    const selectedVariant = event.target.value;
    setVal(selectedVariant);
    changeVariant(selectedVariant);
  };

  return (
    <FormControl className={classes.select}>
      <InputLabel>Variant</InputLabel>
      <Select
        value={val}
        onChange={changeVal}
        style={{ textTransform: "capitalize" }}
        MenuProps={{ disableScrollLock: true }}
      >
        {variants.map((v, i) => (
          <MenuItem key={i} value={v} style={{ textTransform: "capitalize" }}>
            {v}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

const BuilderCard = ({
  field,
  addField,
  deleteField,
  duplicateField,
  fieldTypes,
  changeField,
  setActiveFieldUuid,
  activeFieldUuid,
  dragHandleProps,
  fieldRef,
}) => {
  const classes = useStyles();
  const handleChange = (event) => {
    const field_type = event.target.value;
    const fieldObj = fieldTypes.find((ft) => ft.value === field_type);
    const oldField = fieldTypes.find((ft) => ft.value === field.value);
    const label = oldField.label === field.label ? fieldObj.label : field.label;
    const { id } = field;
    changeField(id, {
      ...fieldObj,
      label,
    });
  };

  const [editState, setEditState] = useState({ ...field });
  const [changeTimer, setChangeTimer] = useState(null);
  const [showAdvanced, setShowAdvanced] = useState(false);
  const [showExplainationModal, setShowExplainationModal] = useState(false);
  const groups = useMemo(() => {
    if (!field.conditionalGroups) {
      return [];
    }
    return field.conditionalGroups;
  }, [field]);

  const fieldSets = {
    checkbox: ["options"],
    dropdown: ["options"],
    radio: ["options"],
    number: ["decimal"],
  };

  const extraFields = useMemo(() => {
    return fieldSets[field.value] || [];
  }, [field]);

  useEffect(() => {
    clearTimeout(changeTimer);
    setEditState({ ...field });
  }, [field, field.uuid]);

  useEffect(() => {
    if (
      JSON.stringify(editState) === JSON.stringify(field) ||
      editState.id !== field.id
    ) {
      clearTimeout(changeTimer);
      return;
    }
    clearTimeout(changeTimer);
    setChangeTimer(
      setTimeout(
        () =>
          changeField(field.id, {
            ...editState,
          }),
        1000
      )
    );
  }, [editState]);

  const handleChangeBuilder = (value, property) => {
    setEditState({
      ...editState,
      [property]: value,
    });
  };

  const changeVariant = (string) => {
    const { id } = field;
    changeField(id, {
      variant: string,
    });
  };

  // TODO: make everything use the editState pattern to avoid races, etc
  // use this block for reference

  const optionTextChange = (key, text) => {
    const updatedOptions = editState.options.map((o) => {
      if (o.key !== key) {
        return o;
      } else {
        return {
          ...o,
          text,
          value: text,
        };
      }
    });
    if (!updatedOptions.find((o) => o.key === key)) {
      updatedOptions.push({
        key,
        value: text,
        text,
      });
    }
    setEditState({
      ...editState,
      options: updatedOptions.filter((uo) => uo.value.length > 0),
    });
  };

  const active = activeFieldUuid === field.id;

  return (
    <Card
      onFocus={() => {
        setActiveFieldUuid(field.id);
        fieldRef.current = document.querySelector(`#f_${field.id}`);
      }}
      onBlur={() => {
        setActiveFieldUuid("");
      }}
      style={{
        ...(active
          ? {
              marginTop: 10,
              paddingLeft: 10,
              border: "2px solid transparent",
              borderLeft: "4px solid blue",
              boxShadow: "none",
            }
          : {
              marginTop: 10,
              paddingLeft: 10,
              border: "2px solid transparent",
              borderLeft: "2px solid transparent",
              boxShadow: "none",
            }),
      }}
    >
      <Box px={1}>
        <div>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: 5,
              marginBottom: 30,
            }}
          >
            <div style={{ width: "100%" }}>
              <Typography variant="subtitle2">Label</Typography>

              <TextField
                value={editState.label}
                onChange={(event) =>
                  handleChangeBuilder(event.target.value, "label")
                }
                InputProps={{
                  style: {
                    fontSize: "20px",
                  },
                }}
                InputLabelProps={{
                  style: {
                    fontSize: "20px",
                  },
                }}
                variant="standard"
                fullWidth
              />
            </div>
            <FormControlLabel
              label="Required"
              control={
                <Checkbox
                  checked={editState ? editState.required : false}
                  onChange={(event) =>
                    handleChangeBuilder(event.target.checked, "required")
                  }
                />
              }
              style={{ marginLeft: 10 }}
            />
          </div>
          <div
            style={{ borderBottom: "2px solid #F4F6F8", marginBottom: 10 }}
          />
        </div>
      </Box>
      <CardContent style={{ padding: 0 }}>
        <div
          style={{
            padding: "1rem 0.5rem ",
            display: "flex",
          }}
          id={"f_" + field.id}
        >
          <div style={{ flex: 11 }}>
            {extraFields.includes("decimal") && (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={editState ? editState.decimal : false}
                    onChange={(event) =>
                      handleChangeBuilder(event.target.checked, "decimal")
                    }
                  />
                }
                label="Decimal"
              />
            )}
            {extraFields.includes("options") && (
              <div style={{ marginBottom: 10 }}>
                <Typography variant="subtitle2">Options</Typography>
                <div style={{ display: "flex", flexDirection: "column" }}>
                  <FieldOptions
                    options={editState.options}
                    updateOptions={({ option, text }) => {
                      optionTextChange(option.key, text);
                    }}
                  />
                </div>
              </div>
            )}

            <div
              style={{
                fontSize: 12,
                color: "#1F449F",
                fontWeight: "600",
                marginBottom: 10,
              }}
            >
              How This Field Will Appear
            </div>
            <Box display={"flex"}>
              <Box
                style={{
                  border: "2px solid #1F449F",
                  borderRadius: 5,
                  padding: 10,
                }}
                flex={1}
              >
                {field.element === "TextInput" && (
                  <StText {...field} answer={dummyTextAnswer} />
                )}
                {field.element === "TextArea" && (
                  <StTextArea {...field} answer={dummyTextAnswer} />
                )}
                {field.element === "NumberInput" && (
                  <StNumber {...field} answer={dummyTextAnswer} />
                )}
                {field.element === "Dropdown" && (
                  <StSelect {...field} answer={dummyTextAnswer} />
                )}
                {field.element === "DatePicker" && (
                  <StDate {...field} answer={dummyDateAnswer} />
                )}
                {field.element === "Checkboxes" && (
                  <StCheckbox {...field} answer={dummyCheckAnswer} />
                )}
                {field.element === "RadioButtons" && (
                  <StRadio {...field} answer={dummyCheckAnswer} />
                )}
                {field.element === "DatabaseOption" && (
                  <StDynamicOptions {...field} answer={dummyTextAnswer} />
                )}
                {field.element === "Contact" && (
                  <StContact {...field} answer={dummyCheckAnswer} />
                )}
                {field.element === "File" && (
                  <StFile {...field} answer={dummyCheckAnswer} />
                )}
                {field.element === "Account" && (
                  <StAccount
                    {...field}
                    answer={dummyCheckAnswer}
                    builderMode={true}
                  />
                )}
              </Box>
              <Box flex={1} px={2}>
                <Box>
                  <FormControl className={classes.select}>
                    <InputLabel>Form Field Type</InputLabel>
                    <Select
                      value={field.value}
                      onChange={handleChange}
                      MenuProps={{ disableScrollLock: true }}
                    >
                      {fieldTypes.map((f, ind) => (
                        <MenuItem key={ind} value={f.value}>
                          {f.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Box>
                <Box>
                  {field.variants && field.variants.length > 1 && (
                    <VariantSelect
                      field={field}
                      changeVariant={changeVariant}
                    />
                  )}
                </Box>
              </Box>
            </Box>
          </div>

          {/* <p>{field.id}</p> */}
          <div style={{ flex: 1, marginBottom: "auto" }}>
            <Box style={{ display: "flex", flexDirection: "column", gap: 5 }}>
              <div {...dragHandleProps}>
                <DragHandle />
              </div>
              <FileCopy
                style={{ cursor: "pointer" }}
                onClick={() => duplicateField(field)}
              />
              <Delete
                style={{ cursor: "pointer" }}
                onClick={() => deleteField(field.id)}
              />
              <Settings
                style={{ cursor: "pointer" }}
                onClick={() => setShowAdvanced(!showAdvanced)}
              />
            </Box>
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 10,
            borderTop: "1px solid #EEEEEE",
            paddingTop: 10,
          }}
        >
          <Button
            style={{ marginRight: 10 }}
            onClick={() => setShowAdvanced(true)}
            variant="contained"
            color="primary"
          >
            {groups.length > 0
              ? "Edit Conditional Groups"
              : "Add Conditional Groups"}
          </Button>
          <HelpIcon
            style={{ cursor: "pointer" }}
            fontSize="small"
            color="primary"
            onClick={() => setShowExplainationModal(true)}
          />

          <div style={{ marginLeft: "auto", fontSize: 16, marginRight: 10 }}>
            {groups.length} conditional group(s)
          </div>
        </div>

        <Dialog
          onClose={() => setShowExplainationModal(false)}
          open={showExplainationModal}
          fullWidth
        >
          <div style={{ padding: 10 }}>
            <div
              style={{
                fontSize: 20,
                fontWeight: "600",
                marginBottom: 10,
                padding: 10,
              }}
            >
              How do conditional groups work?
            </div>
            <div
              style={{ paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}
            >
              This allows you to conditionally show question fields based on
              answers to previous questions. <br />
              <img
                src={conditionalTutorial}
                alt="How Conditional Groups Work"
                style={{ width: "100%", marginTop: 10, marginBottom: 10 }}
              />
              <div style={{ fontSize: 12, fontColor: "#ADADAD" }}>
                Example: Did you taste the product? Yes/No <br />
                If yes is selected show the question "How did it taste?"
              </div>
            </div>
          </div>
        </Dialog>

        <Dialog
          onClose={() => setShowAdvanced(false)}
          open={showAdvanced}
          fullWidth
        >
          <ConditionalGroupEditor
            field={field}
            groups={groups}
            rootField={field}
            changeRootField={changeField}
            rootFieldId={field.id}
            close={() => setShowAdvanced(false)}
          />
        </Dialog>
      </CardContent>
    </Card>
  );
};

export default BuilderCard;
