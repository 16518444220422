import React, { FormControl, InputLabel, Select, Box } from "@mui/material";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";

import _ from "lodash";

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const GeneratedDropDowns = ({ dropDowns, data, handleFilter }) => {
  const dropDownLists = () =>
    dropDowns.map((ddl) => {
      return {
        colName: ddl,
        list: _.uniq(
          data.map((dm) => {
            return dm[ddl];
          })
        ).sort(),
      };
    });
  const [ddOptions, setDdOptions] = useState([]);
  useEffect(() => {
    setDdOptions(dropDownLists());
  }, [data, dropDowns]);
  const q = useQuery();
  const history = useNavigate();

  // TODO: refactor for multi column filtering support
  const dropDowntoQuery = (event, colName) => {
    const newQueryParam = {
      name: "filterCol",
      value: encodeURI(colName + "_" + event.target.value),
    };
    if (newQueryParam.value.includes("SHOWALL")) {
      q.delete(newQueryParam.name);
    } else {
      q.set(newQueryParam.name, newQueryParam.value);
    }
    handleFilter([{ col: colName, value: event.target.value }]);
    const nq = "?" + q.toString();
    history({ search: nq });
  };
  return (
    <Box className="generatedDropdowns" my={1}>
      {ddOptions.map((dd, ind) => {
        return (
          <FormControl key={ind}>
            <InputLabel htmlFor="age-native-simple" style={{ minWidth: 120 }}>
              {dd.colName}
            </InputLabel>
            <Select
              native
              onChange={(event) => dropDowntoQuery(event, dd.colName)}
              inputProps={{
                name: "age",
                id: "age-native-simple",
              }}
            >
              <option aria-label="All Stores" value="SHOWALL">
                Show All
              </option>
              {dd.list.map((option, index) => {
                return (
                  <option value={option} key={index}>
                    {option}
                  </option>
                );
              })}
            </Select>
          </FormControl>
        );
      })}
    </Box>
  );
};

export default GeneratedDropDowns;
