import React, { useState } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Storage, API } from "aws-amplify";
import { Link as RouterLink } from "react-router-dom";

import {
  Card,
  CardContent,
  CardHeader,
  Divider,
  ListItemAvatar,
  Avatar,
  List,
  ListItem,
  ListItemText,
  Dialog,
  DialogTitle,
  DialogContent,
  Button,
  DialogActions,
  IconButton,
} from "@mui/material";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";
import { DateRange, LocalBar } from "@mui/icons-material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime } from "luxon";

Storage.configure({ level: "protected" });

const useStyles = makeStyles(() => ({
  root: {
    maxHeight: "350px",
    overflow: "auto",
  },
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Recommendation = ({
  className,
  city,
  recommendations,
  setReco,
  ...rest
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const store = useStore();
  const { userInfo } = store;
  const [accountSelected, setAccountSelected] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDateChange = (newDate) => {
    const luxonDate = newDate ? DateTime.fromJSDate(newDate.toJSDate()) : null;
    setSelectedDate(luxonDate);
  };

  const createJourney = async () => {
    const appoint = {
      user_id: userInfo.uid,
      type: 0,
      manufacturer_id: userInfo.mid,
      account_id: accountSelected.account_id || null,
      appointment: selectedDate ? selectedDate.toISO() : null,
      is_active: 1,
      cw: 99,
    };
    try {
      const apiName = "backendGateway";
      const path = "/journeys";
      const myInit = {
        body: appoint,
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.error("Error creating journey:", err);
    }
    await createJourneyiCal();
    handleClose();
  };

  const createJourneyiCal = async () => {
    const invite = {
      user_email: userInfo.user_email,
      account_name: accountSelected.account_displayname || null,
      account_location: accountSelected.account_address || null,
      appointment: selectedDate ? selectedDate.toISO() : null,
      account_id: accountSelected.account_id || null,
    };
    try {
      const apiName = "backendGateway";
      const path = "/invites/appointment";
      const myInit = {
        body: invite,
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.error("Error creating iCal invite:", err);
    }
  };

  return (
    <Card className={clsx(className)} {...rest}>
      <CardHeader subheader="On Premise Accounts" title="Account List" />
      <Divider />
      <CardContent>
        <List dense className={classes.root}>
          {recommendations.map((rec) => (
            <RouterLink
              to={`/app/accountdetails/${rec.account_id}`}
              style={{
                textDecoration: "none",
                color: "black",
              }}
              key={rec.account_id}
            >
              <ListItem divider>
                <ListItemAvatar>
                  <Avatar>
                    <LocalBar />
                  </Avatar>
                </ListItemAvatar>

                <ListItemText
                  primary={`${rec.account_displayname}`}
                  secondary={`${rec.account_address}`}
                />

                <IconButton
                  aria-label="schedule"
                  className={classes.margin}
                  onClick={(e) => {
                    setAccountSelected(rec);
                    handleClickOpen();
                    e.preventDefault();
                  }}
                >
                  <DateRange fontSize="large" />
                </IconButton>
              </ListItem>
            </RouterLink>
          ))}
        </List>
        <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="form-dialog-title"
          BackdropProps={{
            style: { backgroundColor: "black", opacity: 0.1 },
          }}
        >
          <DialogTitle id="form-dialog-title">Create Event</DialogTitle>
          <DialogContent>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DateTimePicker
                label="Date + Time"
                disablePast
                value={selectedDate}
                onChange={handleDateChange}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button onClick={createJourney} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </CardContent>
    </Card>
  );
};

Recommendation.propTypes = {
  className: PropTypes.string,
};

export default Recommendation;
