import React from "react";
import { Link as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import { AppBar, Box, IconButton, Toolbar } from "@mui/material";
import Logo from "../../components/Logo";
import { Auth, Cache } from "aws-amplify";
import { Input, Menu } from "@mui/icons-material";

const TopBar = ({ className, onMobileNavOpen, ...rest }) => {
  async function signOut() {
    try {
      await Auth.signOut();
      Cache.clear();
      window.location.replace("/");
    } catch (error) {
      console.log("error signing out: ", error);
    }
  }

  return (
    <AppBar className="st-app-bar" elevation={0} {...rest}>
      <Toolbar>
        <RouterLink to="/app/home">
          <Logo />
        </RouterLink>
        <Box flexGrow={1} />
        <Box
          sx={{
            display: {
              xs: "flex",
              sm: "flex",
              md: "flex",
              lg: "none",
              xl: "none",
            },
          }}
        >
          <IconButton color="inherit" onClick={onMobileNavOpen}>
            <Menu />
          </IconButton>
        </Box>
        <IconButton color="inherit" onClick={signOut}>
          <Input />
        </IconButton>
      </Toolbar>
    </AppBar>
  );
};

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func,
};

export default TopBar;
