import React from "react";
import PropTypes from "prop-types";
import ContactList from "./ContactList";
import { Dialog } from "@mui/material";

const ContactListModal = ({
  onClose,
  open,
  contactList,
  setContactList,
  userInfo,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <ContactList
        onClose={handleClose}
        setContactList={setContactList}
        contactList={contactList}
        userInfo={userInfo}
      />
    </Dialog>
  );
};

ContactListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default ContactListModal;
