import {
  Box,
  Button,
  ButtonGroup,
  IconButton,
  Input,
  TextField,
  Typography,
} from "@mui/material";
import { Link } from "react-router-dom";
import { Star, StarBorderOutlined, Upload } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { useStore } from "../../stores/StoreContext";
const ReportsHistorySmall = ({
  reports,
  goToReport,
  downloadReport,
  fave,
  unfave,
}) => {
  const options = ["star", "mine", "team", "all"];
  const [option, setOption] = useState("mine");

  const { userInfo } = useStore();

  const filteredReports = useMemo(() => {
    console.log(userInfo);
    switch (option) {
      case "star":
        return reports.filter((r) => r.fave);
        break;
      case "mine":
        return reports.filter((r) => r.user_id === userInfo.user_id);
        break;
      case "team":
        return reports.filter((r) => r.user_id !== userInfo.user_id);
        break;
      default:
        return reports;
    }
  }, [reports, option]);
  return (
    <Box px={2}>
      <Typography variant="h6">Report History</Typography>
      <ButtonGroup sx={{ my: 1 }}>
        <Button
          onClick={() => setOption("star")}
          variant={option === "star" ? "contained" : "outlined"}
          endIcon={<Star />}
          aria-label="Starred Reports"
        ></Button>
        <Button
          onClick={() => setOption("mine")}
          variant={option === "mine" ? "contained" : "outlined"}
        >
          Mine
        </Button>
        <Button
          onClick={() => setOption("team")}
          variant={option === "team" ? "contained" : "outlined"}
        >
          Team
        </Button>
        <Button
          onClick={() => setOption("all")}
          variant={option === "all" ? "contained" : "outlined"}
        >
          All
        </Button>
      </ButtonGroup>
      {filteredReports.slice(0, 5).map((r) => (
        <Box key={r.id} display={"flex"}>
          <Box flex={1}>
            <Link to={`/app/reports/report-builder/${r.hash}`}>
              <Upload />
            </Link>
          </Box>
          <Box flex={8}>{(r.title || r.hash).substr(0, 12)}</Box>
          <Box flex={1}>
            <IconButton
              aria-label={
                r.fave ? "Remove from favourites" : "Add to favourites"
              }
              onClick={() => (r.fave ? unfave(r.hash) : fave(r.hash))}
            >
              {r.fave ? <Star /> : <StarBorderOutlined />}
            </IconButton>
          </Box>
        </Box>
      ))}
      <Box>
        <Link to="/app/reports/report-builder-library/">View Full History</Link>
      </Box>
      {filteredReports.length > 5 && (
        <Box>
          <Typography variant="caption">
            Showing 5 of {filteredReports.length} reports of this type.
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default ReportsHistorySmall;
