import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Storage } from "aws-amplify";
import { Card, CardContent } from "@mui/material";
import L from "leaflet";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import "leaflet/dist/leaflet.css";
import states from "./mapdata/us-states-min.json";
import { makeStyles } from "@mui/styles";

Storage.configure({ level: "protected" });

const useStyles = makeStyles(() => ({
  root: {},
}));

const JourneyMap = ({
  className,
  center,
  setCenter,
  zoom,
  setZoom,
  active,
  city,
  ...rest
}) => {
  const classes = useStyles();

  const [map, setMap] = useState(null);
  useEffect(() => {
    if (!map) return;

    map.eachLayer(function (layer) {
      if (layer instanceof L.GeoJSON) map.removeLayer(layer);
    });

    if (active === "US") {
      const geoJson = new L.GeoJSON(states, {
        onEachFeature: (feature = {}, layer) => {
          const { properties = {} } = feature;
          const { name } = properties;

          if (!name) return;
          layer.bindPopup(`<p>${name}</p>`);
        },
      });

      geoJson.addTo(map);
    }

    map.invalidateSize(true);
  }, [map, active, city]);

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Map
          center={center}
          zoom={zoom}
          whenCreated={(mapInstance) => {
            setMap(mapInstance);
          }}
        >
          <TileLayer
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
          />
        </Map>
      </CardContent>
    </Card>
  );
};

JourneyMap.propTypes = {
  className: PropTypes.string,
};

export default JourneyMap;
