import React, { useEffect, useMemo, useState, useCallback } from "react";
import {
  Box,
  Typography,
  Divider,
  Tooltip,
  Paper,
  IconButton,
  Button,
  ToggleButtonGroup,
  ToggleButton,
  CircularProgress,
} from "@mui/material";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Tooltip as ChartTooltip,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import statsAPI from "../../../api/stats";
import EditIcon from "@mui/icons-material/Edit";
import TargetEditModal from "./TargetEditModal";
import { DateTime } from "luxon";

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, BarElement, ChartTooltip);

const RepTargets = ({ widgetData = {}, height, onWidgetConfigUpdate }) => {
  const [timeRange, setTimeRange] = useState(
    widgetData?.initialState?.timeRange || "YTD"
  );

  // Default targets by time range
  const getDefaultTarget = () => {
    switch (timeRange) {
      case "MTD":
        return 2000;
      case "Q1":
        return 5000;
      case "Q2":
        return 10000;
      case "Q3":
        return 15000;
      case "Q4":
        return 20000;
      case "YTD":
        return 24000;
      default:
        return 20000;
    }
  };

  const [representatives, setRepresentatives] = useState([]);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [editTargets, setEditTargets] = useState(() => {
    const initialTargets = {};

    // Initialize default targets for each period
    ["MTD", "Q1", "Q2", "Q3", "Q4", "YTD"].forEach((period) => {
      initialTargets[`default_${period}`] =
        widgetData?.repTargets?.[period]?.defaultTarget || getDefaultTarget();
    });

    // Initialize rep-specific targets
    Object.entries(widgetData?.repTargets || {}).forEach(([period, data]) => {
      Object.entries(data.reps || {}).forEach(([repId, target]) => {
        if (!initialTargets[repId]) initialTargets[repId] = {};
        initialTargets[repId][period] = target;
      });
    });

    return initialTargets;
  });
  const [loading, setLoading] = useState(false);
  const [visibleCount, setVisibleCount] = useState(20); // Initially show 10 reps
  const [apiData, setApiData] = useState(null);

  // Increase the number of visible reps
  const loadMore = useCallback(() => {
    setVisibleCount((prevCount) => prevCount + 20);
  }, []);

  // Reset visible count when time range changes
  useEffect(() => {
    setVisibleCount(10);
  }, [timeRange]);

  const fetchData = async () => {
    try {
      const res = await statsAPI.getRepTotals();
      setApiData(res.data);
      console.log(res);
    } catch (error) {
      console.error("Error fetching rep totals:", error);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data from API once
  useEffect(() => {
    if (apiData === null) {
      setLoading(true);
      fetchData();
    }
    return () => {
      setLoading(false);
    };
  }, []);

  useEffect(() => {
    if (apiData) {
      const reps = apiData
        .map((rep) => ({
          name: rep.rep_name,
          id: rep.id,
          OnPrem: rep[`on_prem_${timeRange}`],
          OffPrem: rep[`off_prem_${timeRange}`],
        }))
        .filter((rep) => rep.name !== "N/A");
      setRepresentatives(reps);
    }
  }, [timeRange, apiData]);

  // Initialize edit targets when modal opens
  const handleOpenEditModal = () => {
    const initialTargets = {};

    // Add default targets for each time period
    ["MTD", "Q1", "Q2", "Q3", "Q4", "YTD"].forEach((period) => {
      initialTargets[`default_${period}`] =
        widgetData?.repTargets?.[period]?.defaultTarget ||
        (period === "MTD"
          ? 2000
          : period === "Q1"
          ? 5000
          : period === "Q2"
          ? 10000
          : period === "Q3"
          ? 15000
          : period === "Q4"
          ? 20000
          : 24000);
    });

    // Only add reps with specific overrides
    representatives.forEach((rep) => {
      ["MTD", "Q1", "Q2", "Q3", "Q4", "YTD"].forEach((period) => {
        const repValue = widgetData?.repTargets?.[period]?.reps?.[rep.id];

        // Only set specific value if it exists as an override
        if (repValue !== undefined) {
          if (!initialTargets[rep.id]) initialTargets[rep.id] = {};
          initialTargets[rep.id][period] = repValue;
        }
      });
    });

    setEditTargets(initialTargets);
    setOpenEditModal(true);
  };

  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleTargetChange = (repId, period) => (event) => {
    const value = Number(event.target.value);
    const defaultValue = editTargets[`default_${period}`];

    setEditTargets((prev) => {
      // If value equals default, remove the specific setting
      if (value === defaultValue) {
        const updated = { ...prev };
        if (updated[repId]) {
          const repUpdated = { ...updated[repId] };
          delete repUpdated[period];

          if (Object.keys(repUpdated).length === 0) {
            delete updated[repId];
          } else {
            updated[repId] = repUpdated;
          }
        }
        return updated;
      } else {
        // Otherwise store the specific value
        return {
          ...prev,
          [repId]: {
            ...(prev[repId] || {}),
            [period]: value,
          },
        };
      }
    });
  };

  const handleTimeRangeChange = (event, newTimeRange) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  const handleSaveTargets = (savedTargets) => {
    // Use the targets passed from the modal component
    setEditTargets(savedTargets);

    // Prepare the updated configuration
    const repTargets = {
      MTD: {
        defaultTarget: savedTargets.default_MTD,
        reps: {},
      },
      Q1: {
        defaultTarget: savedTargets.default_Q1,
        reps: {},
      },
      Q2: {
        defaultTarget: savedTargets.default_Q2,
        reps: {},
      },
      Q3: {
        defaultTarget: savedTargets.default_Q3,
        reps: {},
      },
      Q4: {
        defaultTarget: savedTargets.default_Q4,
        reps: {},
      },
      YTD: {
        defaultTarget: savedTargets.default_YTD,
        reps: {},
      },
    };

    // Only add rep targets that differ from defaults
    Object.entries(savedTargets).forEach(([key, value]) => {
      if (key.startsWith("default_")) return;

      ["MTD", "Q1", "Q2", "Q3", "Q4", "YTD"].forEach((period) => {
        if (
          value[period] !== undefined &&
          value[period] !== savedTargets[`default_${period}`]
        ) {
          repTargets[period].reps[key] = value[period];
        }
      });
    });

    // Call update function from parent
    if (onWidgetConfigUpdate) {
      onWidgetConfigUpdate({
        data: {
          initialState: {
            timeRange: timeRange,
          },
          repTargets,
        },
      });
    }

    handleCloseEditModal();
  };

  // Memoize the data object based on representatives
  const data = useMemo(() => {
    // Determine current quarter when timeRange is QTD
    let effectiveTimeRange = timeRange;
    if (timeRange === "QTD") {
      const currentQuarter = DateTime.now().quarter;
      effectiveTimeRange = `Q${currentQuarter}`;
    }

    const currentDefaultTarget =
      widgetData?.repTargets?.[effectiveTimeRange]?.defaultTarget ||
      getDefaultTarget();

    // Calculate derived values for each rep
    const processedReps = representatives.map((rep) => {
      const repTarget =
        widgetData?.repTargets?.[effectiveTimeRange]?.reps?.[rep.id] ||
        currentDefaultTarget;
      const current = rep.OnPrem + rep.OffPrem;
      const percentage = ((current / repTarget) * 100).toFixed(1);

      // Format display values
      const formatNumber = (num) => {
        if (num >= 1000) {
          return (num / 1000).toFixed(2) + "k";
        }
        num = num.toFixed(2);
        return num.toString();
      };

      return {
        ...rep,
        current,
        currentFormatted: formatNumber(current),
        percentage: parseFloat(percentage),
        target: formatNumber(repTarget),
        targetValue: repTarget,
      };
    });

    // Sort by percentage completion (descending), then by total sales volume (descending)
    processedReps.sort((a, b) => {
      // First sort by percentage (highest first)
      if (b.percentage !== a.percentage) {
        return b.percentage - a.percentage;
      }
      // If percentages are equal, sort by total sales (highest first)
      return b.current - a.current;
    });

    // Calculate total
    const totalValue = processedReps.reduce((sum, rep) => sum + rep.current, 0);
    const total = (totalValue / 1000).toFixed(2) + "k";

    return {
      representatives: processedReps,
      total,
    };
  }, [representatives, timeRange, widgetData, editTargets]);

  // Get only the visible representatives
  const visibleReps = useMemo(() => {
    return data.representatives.slice(0, visibleCount);
  }, [data.representatives, visibleCount]);

  // Check if there are more reps to load
  const hasMore = visibleReps.length < data.representatives.length;

  // Custom tooltip component
  const CustomTooltipContent = ({ rep }) => (
    <Paper sx={{ p: 2, maxWidth: 250 }}>
      <Typography variant="subtitle1" sx={{ fontWeight: "bold", mb: 1 }}>
        {rep.name}
      </Typography>

      <Typography variant="body2" sx={{ mb: 1 }}>
        {rep.currentFormatted} / {rep.target} ({rep.percentage}%)
      </Typography>

      <Divider sx={{ my: 1 }} />

      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: "50%",
              backgroundColor: "#1a2e6e",
            }}
          ></Box>
          <Typography variant="body2">
            On Premise:{" "}
            {rep.OnPrem >= 1000
              ? (rep.OnPrem / 1000).toFixed(2) + "k"
              : rep.OnPrem}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: "50%",
              backgroundColor: "#3d67e0",
            }}
          ></Box>
          <Typography variant="body2">
            Off Premise:{" "}
            {rep.OffPrem >= 1000
              ? (rep.OffPrem / 1000).toFixed(2) + "k"
              : rep.OffPrem}
          </Typography>
        </Box>
      </Box>
    </Paper>
  );

  return (
    <Box sx={{ py: 6, height: height, overflow: "visible", width: "100%" }}>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "calc(100% - 60px)",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          {/* Legend and Total */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              mb: 3,
              alignItems: "center",
            }}
          >
            <Box sx={{ display: "flex", gap: 3 }}>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: "#1a2e6e",
                  }}
                ></Box>
                <Typography variant="body2">On Premise</Typography>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
                <Box
                  sx={{
                    width: 12,
                    height: 12,
                    borderRadius: "50%",
                    backgroundColor: "#3d67e0",
                  }}
                ></Box>
                <Typography variant="body2">Off Premise</Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <IconButton
                size="small"
                onClick={handleOpenEditModal}
                sx={{ mr: 1, color: "text.secondary" }}
                aria-label="Edit targets"
              >
                <EditIcon fontSize="small" />
              </IconButton>

              <ToggleButtonGroup
                value={timeRange}
                exclusive
                onChange={handleTimeRangeChange}
                aria-label="time range"
                size="small"
              >
                <ToggleButton value="YTD" aria-label="year">
                  YTD
                </ToggleButton>
                <ToggleButton value="QTD" aria-label="quarter">
                  QTD
                </ToggleButton>
                <ToggleButton value="MTD" aria-label="month">
                  MTD
                </ToggleButton>
              </ToggleButtonGroup>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
              <Typography variant="body2" sx={{ fontWeight: "medium" }}>
                Total:
              </Typography>
              <Typography variant="h5" sx={{ fontWeight: "bold" }}>
                {data.total}
              </Typography>
            </Box>
          </Box>

          <Divider sx={{ mb: 3 }} />

          {representatives.length === 0 ? (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h6" color="textSecondary">
                No available rep data for this time range
              </Typography>
            </Box>
          ) : (
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 4, pb: 4 }}
            >
              {visibleReps.map((rep, index) => {
                // Chart data - clamp visual representation to 100%
                const chartData = {
                  labels: [""],
                  datasets: [
                    {
                      label: "On Premise",
                      // Clamp OnPrem value to ensure total doesn't exceed target
                      data: [
                        rep.OnPrem > rep.targetValue
                          ? rep.targetValue *
                            (rep.OnPrem / (rep.OnPrem + rep.OffPrem))
                          : rep.OnPrem,
                      ],
                      backgroundColor: "#1a2e6e",
                      borderWidth: 0,
                      borderRadius: {
                        topLeft: 10,
                        bottomLeft: 10,
                        topRight: 0,
                        bottomRight: 0,
                      },
                      barThickness: 30,
                      stack: "Stack 0",
                    },
                    {
                      label: "Off Premise",
                      // Clamp OffPrem value to ensure total doesn't exceed target
                      data: [
                        rep.OffPrem > rep.targetValue
                          ? rep.targetValue *
                            (rep.OffPrem / (rep.OnPrem + rep.OffPrem))
                          : rep.OnPrem + rep.OffPrem > rep.targetValue
                          ? rep.targetValue - rep.OnPrem
                          : rep.OffPrem,
                      ],
                      backgroundColor: "#3d67e0",
                      borderWidth: 0,
                      borderRadius: {
                        topLeft: 0,
                        bottomLeft: 0,
                        topRight: 10,
                        bottomRight: 10,
                      },
                      barThickness: 30,
                      stack: "Stack 0",
                    },
                  ],
                };

                // Chart options - cap the max display at target value
                const chartOptions = {
                  indexAxis: "y",
                  responsive: true,
                  maintainAspectRatio: false,
                  plugins: {
                    legend: {
                      display: false,
                    },
                    tooltip: {
                      enabled: false, // Disable Chart.js tooltips
                    },
                  },
                  scales: {
                    x: {
                      stacked: true,
                      display: false,
                      beginAtZero: true,
                      max: rep.targetValue,
                      grid: {
                        display: false,
                      },
                    },
                    y: {
                      stacked: true,
                      display: false,
                      grid: {
                        display: false,
                      },
                      border: {
                        display: false,
                      },
                    },
                  },
                  animation: false,
                  layout: {
                    padding: 0,
                  },
                };

                return (
                  <Box key={index}>
                    {/* Name and target */}
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        mb: 1,
                      }}
                    >
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Typography
                          variant="body1"
                          sx={{ fontWeight: "medium", mr: 0.5 }}
                        >
                          {rep.name}
                        </Typography>
                        <Typography
                          variant="body2"
                          component="span"
                          sx={{ color: "#0040c1" }}
                        >
                          - Target: {rep.target}
                        </Typography>
                      </Box>
                      <Typography variant="body1" sx={{ fontWeight: "bold" }}>
                        {rep.currentFormatted}
                      </Typography>
                    </Box>

                    {/* Percentage and Chart */}
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        overflow: "visible",
                      }}
                    >
                      <Box sx={{ width: "60px", pr: 1 }}>
                        <Typography
                          variant="body2"
                          sx={{
                            color: "#555",
                            fontWeight: "medium",
                            textAlign: "right",
                          }}
                        >
                          {rep.percentage}%
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          flexGrow: 1,
                          height: 30,
                          borderRadius: "10px",
                          backgroundColor: "#f5f5fa",
                          overflow: "hidden",
                          position: "relative",
                        }}
                      >
                        <Tooltip
                          title={<CustomTooltipContent rep={rep} />}
                          arrow
                          slotProps={{
                            tooltip: {
                              sx: {
                                color: "#514E6A",
                                backgroundColor: "transparent",
                              },
                            },
                          }}
                          placement="top"
                          followCursor
                        >
                          <Box sx={{ height: 30, width: "100%" }}>
                            <Bar
                              data={chartData}
                              options={chartOptions}
                              height={30}
                            />
                          </Box>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                );
              })}

              {/* Load More Button */}
              {hasMore && (
                <Box sx={{ display: "flex", justifyContent: "center", mt: 2 }}>
                  <Button variant="outlined" onClick={loadMore} size="small">
                    Load More (
                    {data.representatives.length - visibleReps.length}{" "}
                    remaining)
                  </Button>
                </Box>
              )}
            </Box>
          )}
        </>
      )}

      {/* Target Editing Modal */}
      <TargetEditModal
        open={openEditModal}
        onClose={handleCloseEditModal}
        onSave={handleSaveTargets}
        editTargets={editTargets}
        representatives={representatives}
        setDefaultTimePeriod={setTimeRange}
        defaultTimePeriod={timeRange}
      />
    </Box>
  );
};

export default RepTargets;
