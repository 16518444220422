const minified = {
  allMarketsObjectsMinified: {},
  allAccountsObjectsMinified: {},
  allDistributorsObjectsMinified: {},
  allProductsMinified: {},

  allMarketsObjectsMinifiedCount: 0,
  allAccountsObjectsMinifiedCount: 0,
  allDistributorsObjectsMinifiedCount: 0,
  allProductsMinifiedCount: 0,

  setAllMarketsObjectsMinified(list) {
    this.allMarketsObjectsMinified = list;
    this.allMarketsObjectsMinifiedCount =
      this.allMarketsObjectsMinifiedCount + 1;
  },
  setAllAccountsObjectsMinified(list) {
    this.allAccountsObjectsMinified = list;
    this.allAccountsObjectsMinifiedCount =
      this.allAccountsObjectsMinifiedCount + 1;
  },
  setAllDistributorsObjectsMinified(list) {
    this.allDistributorsObjectsMinified = list;
    this.allDistributorsObjectsMinifiedCount =
      this.allDistributorsObjectsMinifiedCount + 1;
  },
  setAllProductsMinified(list) {
    this.allProductsMinified = list;
    this.allProductsMinifiedCount = this.allProductsMinifiedCount + 1;
  },
};

export default minified;
