import { useEffect, useState } from "react";
import Associator from ".";
import regionsApi from "../../../api/regions";
import { Button, Box } from "@mui/material";
const AccountListAssociator = ({ accountList }) => {
  const [checked, setChecked] = useState(false);
  const [showAssociator, setShowAssociator] = useState(false);
  const [knownAssociations, setKnownAssociations] = useState([]);
  const { list_id } = accountList;
  const getAssociations = () => regionsApi.getAccountListRegions({ list_id });
  const makeAssociation = ({ region_id }) =>
    regionsApi.makeAccountListRegionAssoc({ list_id, region_id });
  const removeAssociation = ({ assoc_id }) =>
    regionsApi.removeAccountListRegionAssoc({ list_id, assoc_id });
  const reportAssociationChange = (list) => setKnownAssociations(list);

  useEffect(() => {
    getAssociations()
      .then(({ results }) => {
        setKnownAssociations(results);
      })
      .catch((error) => console.error(error))
      .finally(() => {
        setChecked(true);
        return [];
      });
  }, [list_id]);

  return (
    <div>
      <Box display="flex" alignItems={"center"}>
        <Box>
          Regions: {knownAssociations.map((ka) => ka.region_name).join(", ")}
        </Box>
        <Box pl={1}>
          <Button
            disabled={!checked}
            onClick={() => setShowAssociator(true)}
            variant="outlined"
          >
            Change
          </Button>
        </Box>
      </Box>
      <Associator
        isOpen={showAssociator}
        getAssociations={getAssociations}
        makeAssociation={makeAssociation}
        removeAssociation={removeAssociation}
        reportAssociationChange={reportAssociationChange}
        handleClose={() => setShowAssociator(false)}
        modalTitle={"Regions assigned to this List"}
      />
    </div>
  );
};

export default AccountListAssociator;
