import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import { Accordion, AccordionSummary, AccordionDetails } from "@mui/material";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { makeStyles } from "@mui/styles";
import { ExpandMore } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.1),
    },
  },
  chip: {
    display: "flex",
    justifyContent: "center",
    flexWrap: "wrap",
    "& > *": {
      margin: theme.spacing(0.5),
    },
  },
  statsItem: {
    alignItems: "center",
    display: "flex",
  },
  statsIcon: {
    marginRight: theme.spacing(1),
  },
}));

const ListCard = ({
  className,
  list,
  allLists,
  setData,
  expandState,
  children,
  ...rest
}) => {
  const classes = useStyles();

  const [item, setItem] = useState(list);
  const [bgUrl, setBgUrl] = useState("");
  const [refresh, setRefresh] = useState(true);
  const handleClickOpen = (e) => {
    setOpen(true);
    e.stopPropagation();
  };

  const s3ImageEl = useRef(null);
  const [open, setOpen] = React.useState(false);
  const [selectedValue, setSelectedValue] = React.useState("");

  useEffect(() => {
    if (list) {
      let _list = { ...list };
      _list.image_scr = awsS3Image(
        _list.list_image,
        _list.list_imagekey,
        "0x110"
      );
      setItem({ ..._list });
    }
  }, [list]);

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        {item.list_name}
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  );
};

ListCard.propTypes = {
  className: PropTypes.string,
  list: PropTypes.object.isRequired,
};

export default ListCard;
