import client from "./client";
import APICallWrapper from "./APICallWrappers";

// ==================================================
// Accounts Endpoints
// ==================================================

const getAccounts = async ({ page, pageSize, filterModel, sortingModel }) => {
  const key = `getAccounts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading accounts...",
    "Accounts loaded successfully.",
    "Failed to load accounts.",
    () =>
      client.get("/locations/brandfinder/accounts", {
        page,
        pageSize,
        filterModel: JSON.stringify(filterModel),
        sortingModel: JSON.stringify(sortingModel),
      })
  );
};
const getMapPoints = async () => {
  const key = `getMap-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading map...",
    "Map loaded successfully.",
    "Failed to load map.",
    () => client.get("/locations/brandfinder/map", {})
  );
};

const getAccountsExport = async (filters) => {
  const key = `getAccountsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading accounts...",
    "Accounts loaded successfully.",
    "Failed to load accounts.",
    () => client.getBinary("/locations/brandfinder/accounts/export", filters)
  );
};
const getProductsExport = async (filters) => {
  const key = `getProductsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading accounts...",
    "Accounts loaded successfully.",
    "Failed to load accounts.",
    () => client.getBinary("/locations/brandfinder/products/export", filters)
  );
};
/**
/**
 * 
 * Updates an account.
 * Expects an account object which must include an id.
 */
const putAccount = async (account) => {
  const key = `putAccount-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating account...",
    "Account updated successfully.",
    "Failed to update account.",
    () => client.put(`/locations/brandfinder/accounts/${account.id}`, account)
  );
};

/**
 * Inserts an account into the ignore table.
 * Used by the "Remove from Map" button.
 */
const removeAccountFromMap = async (accountId) => {
  const key = `removeAccountFromMap-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Removing account from map...",
    "Account removed from map successfully.",
    "Failed to remove account from map.",
    () => client.post("/locations/brandfinder/accounts/ignore", { accountId })
  );
};

// ==================================================
// Products Endpoints
// ==================================================

/**
 * Retrieves a paginated list of products.
 * Expects an object with { page, pageSize }.
 */

const getProducts = async ({ page, pageSize, filterModel, sortingModel }) => {
  const key = `getProducts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading products...",
    "Products loaded successfully.",
    "Failed to load products.",
    () =>
      client.get("/locations/brandfinder/products", {
        // pass them as query params
        page,
        pageSize,
        // We stringify the models so we can parse on the server
        filterModel: JSON.stringify(filterModel),
        sortingModel: JSON.stringify(sortingModel),
      })
  );
};

/**
 * Updates a product.
 * Expects a product object which must include an id.
 */
const putProduct = async (product) => {
  const key = `putProduct-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating product...",
    "Product updated successfully.",
    "Failed to update product.",
    () => client.put(`/locations/brandfinder/products/${product.id}`, product)
  );
};

/**
 * Inserts a product into the ignore table.
 * Used by the "Remove from Map" button.
 */
const removeProductFromMap = async (productId) => {
  const key = `removeProductFromMap-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Removing product from map...",
    "Product removed from map successfully.",
    "Failed to remove product from map.",
    () => client.post("/locations/brandfinder/products/ignore", { productId })
  );
};

// ==================================================
// Export the endpoints
// ==================================================

export default {
  // Accounts
  getMapPoints,
  getAccounts,
  getAccountsExport,
  putAccount,
  removeAccountFromMap,
  // Products
  getProducts,
  getProductsExport,
  putProduct,
  removeProductFromMap,
};
