import client from "./client";
import APICallWrapper from "./APICallWrappers";

const getReport = async (params) => {
  const key = `getFormList-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading  report data...",
    "Data loaded successfully.",
    "Failed to load data.",
    () => client.get("/stats/v2/report", params)
  );
};

const saveReport = async ({ reportHash, reportName }) => {
  const key = `postSaveReport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading report data...",
    "Data loaded successfully.",
    "Failed to load data.",
    () => client.post("/stats/v2/report/save", { reportHash, reportName })
  );
};
const postReport = async (body, params) => {
  const key = `getFormList-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading  report data...",
    "Data loaded successfully.",
    "Failed to load data.",
    () => client.post("/stats/v2/report", body, params)
  );
};
const getReportExport = async (reportHash) => {
  const key = `getReportsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading Report...",
    "Report loaded successfully.",
    "Failed to load Report.",
    () => client.get("/stats/v2/report/export", reportHash)
  );
};
const getReportHistory = async (reportHash) => {
  const key = `getReportsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading Report...",
    "Report loaded successfully.",
    "Failed to load Report.",
    () =>
      client.get(
        `/stats/v2/report/history${reportHash ? "/" + reportHash : ""}`
      )
  );
};

const saveReportHistory = async (report) => {
  const key = `getReportsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading Report...",
    "Report loaded successfully.",
    "Failed to load Report.",
    () => client.post(`/stats/v2/report/history`, report)
  );
};

const updateReportHistory = async (report) => {
  const key = `getReportsExport-${Date.now()}`;
  const { hash } = report;
  return await APICallWrapper(
    key,
    "Updating Report details...",
    "Report updated successfully.",
    "Failed to update Report.",
    () => client.put(`/stats/v2/report/history/${hash}`, report)
  );
};

const faveReportHistory = async (hash) => {
  const key = `getReportsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Starring Report...",
    "Report starred successfully.",
    "Failed to star report.",
    () => client.get(`/stats/v2/report/history/${hash}/fave`)
  );
};

const unfaveReportHistory = async (hash) => {
  const key = `getReportsExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Unstarring Report...",
    "Report unstarred.",
    "Failed to unstar Report.",
    () => client.del(`/stats/v2/report/history/${hash}/fave`)
  );
};

export default {
  getReport,
  postReport,
  saveReport,
  getReportExport,
  getReportHistory,
  saveReportHistory,
  updateReportHistory,
  faveReportHistory,
  unfaveReportHistory,
};
