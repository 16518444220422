import { useEffect, useState, useMemo } from "react";
import { Button } from "@mui/material";
import { useLocation } from "react-router";
import { v4 as uuid } from "uuid";
import { Bookmark as BookmarkIcon } from "react-feather";
import { useSearchParams } from "react-router-dom";
import bookmarks from "../../api/bookmarks";

import BookmarkModal from "./BookmarkModal";

const BookMark = ({
  selectedBrands,
  selectedStates,
  selectedDistributors,
  dateRange,
  dateRangeName,
  selectedQuantity,
  selectedProducts,
  premiseTypes,
  userInfo,
  rfState = {},
  tabButtonStyle,
  productJoiner,
  productSold,
}) => {
  const location = useLocation();
  let [searchParams] = useSearchParams();
  const [open, setOpen] = useState(false);
  const [name, setName] = useState("Bookmark - " + new Date().toDateString());
  const [pinDate, setPinDate] = useState(false);
  const [saving, setSaving] = useState(false);
  const [saved, setSaved] = useState(false);
  const [bookmarkUuid, setBookmarkUuid] = useState("");

  const reportPreset = useMemo(() => {
    const preset = searchParams.get("preset");
    if (!preset) return null;
    return preset;
  }, [searchParams]);

  const reset = () => {
    setSaving(false);
    setPinDate(false);
    setName("Bookmark - " + new Date().toDateString());
    setSaved(false);
  };

  useEffect(() => {
    setBookmarkUuid(uuid());
  }, [open]);

  const prepareBookMark = async () => {
    const page_path = location.pathname;
    const { manufacturer_id: mid, user_id: uid } = userInfo;
    const payload = {
      mid,
      uid,
      uuid: bookmarkUuid,
      page_path,
      page_name: name,
      qs_state: {
        selectedBrands,
        selectedStates,
        selectedDistributors,
        dateRangeName,
        selectedQuantity,
        selectedProducts,
        premiseTypes,
        productJoiner,
        productSold,
        reportPreset,
      },
      rf_state: rfState,
    };
    if (pinDate) {
      payload.qs_state.dateRange = dateRange;
    }
    console.log(payload);
    setSaving(true);
    try {
      const result = await bookmarks.saveBookMark(payload);
      console.log({ result });
      reset();
      setSaved(true);
    } catch (error) {
      console.error(error);
    }
  };

  const cancel = () => {
    reset();
    setOpen(false);
  };
  return (
    <>
      <Button
        onClick={() => setOpen(true)}
        style={tabButtonStyle}
        variant="contained"
        size="small"
        startIcon={<BookmarkIcon />}
      >
        Bookmark
      </Button>
      <BookmarkModal
        open={open}
        save={prepareBookMark}
        cancel={cancel}
        {...{
          name,
          setName,
          pinDate,
          setPinDate,
          saving,
          saved,
          bookmarkUuid,
        }}
      ></BookmarkModal>
    </>
  );
};

export default BookMark;
