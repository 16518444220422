import React, { useEffect, useState } from "react";
import { API } from "aws-amplify";
import ScheduleList from "./ScheduleList";
import { useStore } from "../../stores/StoreContext";
import { CircularProgress, Container, Typography } from "@mui/material";
import useApi from "../../hooks/useApi";
import usersApi from "../../api/users";
import appointmentsAPI from "../../api/appointments";

function ScheduleView() {
  const getUsersApi = useApi(usersApi.getUsers);
  const [scheduleData, setScheduleData] = useState([]);
  const [isMySchedule, setIsMySchedule] = useState(true);
  const store = useStore();
  const { userInfo } = store;
  const [members, setMembers] = useState(false);

  const fetchScheduleData = async () => {
    try {
      const response = await appointmentsAPI.fetchScheduleData({
        limit: 1000,
        mid: userInfo.mid,
      });
      setScheduleData(response.results);
    } catch (error) {
      console.error("Failed to fetch schedule data", error);
    }
  };

  const fetchUsersData = async () => {
    try {
      console.log("Fetching users data...");
      const usersResponse = await getUsersApi.request({
        mid: userInfo.mid,
        limit: 1000,
      });
      console.log("Fetched users data:", usersResponse);
      setMembers(usersResponse.results);
    } catch (error) {
      console.error("Failed to fetch users data", error);
    }
  };

  const fetchData = async () => {
    try {
      await fetchScheduleData();
      await fetchUsersData();
    } catch (error) {
      console.error("Failed to fetch data", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handleToggle = () => {
    setIsMySchedule((prev) => !prev);
  };

  const filteredData = scheduleData.filter((item) =>
    isMySchedule ? item.user_id === userInfo.user_id : true
  );

  return (
    <div>
      {members && members.length ? (
        <Container>
          <ScheduleList
            userInfo={userInfo}
            scheduleData={filteredData}
            isMySchedule={isMySchedule}
            handleToggle={handleToggle}
            members={members}
            refresh={fetchData}
          />
        </Container>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "80vh",
          }}
        >
          <Typography
            style={{ color: "#2053D4", fontSize: "24px", marginBottom: "20px" }}
          >
            Fetching Schedule Data
          </Typography>
          <CircularProgress
            style={{ color: "#2053D4", width: "100px", height: "100px" }}
          />
        </div>
      )}
    </div>
  );
}

export default ScheduleView;
