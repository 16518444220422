import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  Container,
  CircularProgress,
  Button,
  Card,
  CardContent,
  TextField,
  ButtonGroup,
  InputAdornment,
  SvgIcon,
  Snackbar,
  Grid2,
  MenuItem,
  Popper,
  Grow,
  MenuList,
  Paper,
  ClickAwayListener,
  Alert,
  ToggleButtonGroup,
  ToggleButton,
  Pagination,
  Modal,
  Typography,
} from "@mui/material";
import Page from "../../../components/Page";
import { Search as SearchIcon } from "react-feather";
import { trackEvent } from "../../../api/analytics";
import useApi from "../../../hooks/useApi";
import usersApi from "../../../api/users";
import TeamCard from "./TeamCard";
import { useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
import TeamActivityTracker from "./TeamActivityTracker";
import TeamScorecardView from "../TeamScorecardView";
import teamApi from "../../../api/team";
import { makeStyles } from "@mui/styles";
import {
  ArrowDropDown,
  FormatListBulleted,
  People,
  ScatterPlot,
} from "@mui/icons-material";
import { StaticDateRangePicker } from "@mui/x-date-pickers-pro/StaticDateRangePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import { DateTime, Settings } from "luxon";

Settings.defaultZone = "local";

const filterTitles = [
  "All",
  "account_checkin",
  "contact_created",
  "media_upload",
  "form_response",
];

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const DateRangeModal = ({ dateRange, setDateRange }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <>
      {/* Button to open the modal */}
      <Button variant="outlined" onClick={handleOpen}>
        Select Date Range
      </Button>

      {/* Modal for the date range picker */}
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="date-range-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography id="date-range-modal" variant="h6" component="h2" mb={2}>
            Select Date Range
          </Typography>

          {/* Date Range Picker */}
          <LocalizationProvider dateAdapter={AdapterLuxon}>
            <StaticDateRangePicker
              calendars={2} // Ensures two calendars are displayed
              value={dateRange}
              onChange={(newRange) => setDateRange(newRange)}
              slotProps={{
                shortcuts: {
                  items: [
                    {
                      label: "Last 6 months",
                      getValue: () => {
                        const now = DateTime.local();
                        return [now.minus({ months: 6 }), now];
                      },
                    },
                    {
                      label: "This Month",
                      getValue: () => {
                        const now = DateTime.local();
                        return [now.startOf("month"), now.endOf("month")];
                      },
                    },
                    {
                      label: "This Week",
                      getValue: () => {
                        const now = DateTime.local();
                        return [now.startOf("week"), now.endOf("week")];
                      },
                    },
                    {
                      label: "Today",
                      getValue: () => {
                        const now = DateTime.local();
                        return [now.startOf("day"), now.endOf("day")];
                      },
                    },
                    {
                      label: "Reset",
                      getValue: () => [
                        DateTime.fromMillis(0),
                        DateTime.local(),
                      ],
                    },
                  ],
                },
                actionBar: { actions: [] },
              }}
            />
          </LocalizationProvider>

          {/* Close Button */}
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button variant="contained" onClick={handleClose}>
              Close
            </Button>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

const TeamListView = () => {
  const getUsersApi = useApi(usersApi.getUsers);
  const navigate = useNavigate();
  const store = useStore();
  const { userInfo } = store;
  const [listType, setListType] = useState("ACTIVITY");
  const anchorRef = useRef(null);
  const anchorRefDate = useRef(null);
  const anchorRef2 = useRef(null);
  const [openFilter, setOpenFilter] = useState(false);
  const [openFilterDate, setOpenFilterDate] = useState(false);
  const [dataFilter, setDataFilter] = useState("All");
  const [memberFilter, setMemberFilter] = useState("Everyone");
  const [dateRange, setDateRange] = useState([
    DateTime.fromMillis(0),
    DateTime.local(),
  ]);
  const [activityData, setActivityData] = useState([]);
  const [formattedData, setFormattedData] = useState([]);
  const [earliestDate, setEarliestDate] = useState(null);
  const [latestDate, setLatestDate] = useState(null);
  const [openMemberFilter, setOpenMemberFilter] = useState(false);
  const [sbOpen, setOpen] = useState(false);

  const classes = useStyles();

  const reset = () => {
    setDateToAllTime();
    setMemberFilter("Everyone");
    setDataFilter("All");
  };

  const setDateToAllTime = () => {
    setDateRange((DateTime.fromJSDate(earliestDate), DateTime.local()));
  };

  const fetchData = async () => {
    try {
      const response = await teamApi.getTeamActivity(userInfo.mid);
      setActivityData(response.results);
      const dates = response.results.map((item) => new Date(item.created));
      const earliest = new Date(Math.min(...dates));
      const latest = new Date(Math.max(...dates));
      setEarliestDate(earliest);
      setLatestDate(latest);
      setDateRange([
        DateTime.fromJSDate(earliest),
        DateTime.fromJSDate(latest),
      ]);
    } catch (err) {
      console.error("Error fetching visits data..", err);
    }
  };

  useEffect(() => {
    if (userInfo.mid) {
      fetchData();
    }
  }, [userInfo.mid]);

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Team");
    getUsersApi.request({ mid: userInfo.mid, limit: 1000 });
  }, []);

  useEffect(() => {
    const [start, end] = dateRange;
    const filtered = activityData.filter((event) => {
      const eventDate = DateTime.fromJSDate(new Date(event.created));
      return (
        (dataFilter === "All" || event.type === dataFilter) &&
        (memberFilter === "Everyone" || event.user_id === memberFilter) &&
        eventDate >= start &&
        eventDate <= end
      );
    });
    setFormattedData(filtered);
  }, [memberFilter, dataFilter, dateRange, activityData]);

  const handleChangeListType = (event, newType) => {
    setListType(newType);
  };

  const handleToggle = (param) => {
    if (param === "member") {
      setOpenMemberFilter(true);
    } else if (param === "filter") {
      setOpenFilter((prevOpen) => !prevOpen);
    } else {
      setOpenFilterDate((prevOpen) => !prevOpen);
    }
  };

  const handleClose = () => {
    setOpenFilterDate(false);
    setOpenFilter(false);
    setOpenMemberFilter(false);
  };

  const handleSBClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  const openManagerView = () => {
    navigate("/app/team/manager", {
      state: { team: getUsersApi.data },
    });
  };

  const isAdmin = userInfo.roles ? userInfo.roles.includes("ADMIN") : false;

  return (
    <Page className={classes.root} title="Team">
      <Snackbar open={sbOpen} autoHideDuration={6000} onClose={handleSBClose}>
        <Alert onClose={handleSBClose} severity="success">
          Sent!
        </Alert>
      </Snackbar>
      <Container maxWidth={false}>
        <div
          className="toolbar"
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginBottom: "20px",
          }}
        >
          <ToggleButtonGroup
            size="large"
            exclusive
            value={listType}
            onChange={handleChangeListType}
          >
            <ToggleButton value="ACTIVITY">
              <FormatListBulleted />
              <Box ml={1}>Team Activity</Box>
            </ToggleButton>
            <ToggleButton value="MEMBERS">
              <People />
              <Box ml={1}>Team Members</Box>
            </ToggleButton>
            {userInfo.mid === 585 && (
              <ToggleButton value="SCORECARD">
                <ScatterPlot />
                <Box ml={1}>Team Scorecard</Box>
              </ToggleButton>
            )}
          </ToggleButtonGroup>
          {isAdmin && getUsersApi.currentItems?.length > 0 && (
            <Button
              variant="contained"
              color="primary"
              style={{ margin: "5px" }}
              onClick={openManagerView}
            >
              Manager View
            </Button>
          )}
        </div>
        {listType === "SCORECARD" ? (
          <TeamScorecardView users={getUsersApi.data} />
        ) : listType === "MEMBERS" ? (
          <>
            <Box>
              <Card>
                <CardContent>
                  <Box maxWidth={500}>
                    <TextField
                      fullWidth
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SvgIcon fontSize="small" color="action">
                              <SearchIcon />
                            </SvgIcon>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Search Team"
                      variant="outlined"
                      onChange={(e) =>
                        getUsersApi.filter("user_displayname", e.target.value)
                      }
                    />
                  </Box>
                </CardContent>
              </Card>
            </Box>
            <Box mt={3}>
              <div>
                {getUsersApi.loading && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "80px",
                    }}
                  >
                    <CircularProgress />
                  </div>
                )}
              </div>
              {getUsersApi.currentItems?.length > 0 && (
                <Grid2 container spacing={3}>
                  {getUsersApi.currentItems.map((member) => (
                    <Grid2 key={member.user_id} lg={4} md={6} size={12}>
                      <TeamCard member={member} key={member.user_id} />
                    </Grid2>
                  ))}
                </Grid2>
              )}
            </Box>
            <Box mt={3} display="flex" justifyContent="center">
              <Pagination
                color="primary"
                count={getUsersApi.pageCount}
                page={getUsersApi.page}
                onChange={(event, value) => {
                  getUsersApi.paginationWeb(value);
                }}
                size="small"
              />
            </Box>
          </>
        ) : (
          <>
            <Paper
              style={{
                display: "flex",
                width: "100%",
                padding: "10px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignItems: "center",
                paddingTop: "20px",
                paddingBottom: "20px",
                marginBottom: "20px",
              }}
            >
              <div
                style={{
                  fontSize: "26px",
                  fontWeight: "500",
                  marginLeft: "10px",
                }}
              >
                Team Activity
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  gap: "10px",
                }}
              >
                <ButtonGroup>
                  <Button
                    ref={anchorRef2}
                    aria-controls={openFilter ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle("member")}
                    title={memberFilter}
                    disabled={getUsersApi.loading}
                    size={"large"}
                  >
                    {getUsersApi.data.find(
                      (member) => member.user_id === memberFilter
                    )?.user_displayname || "Everyone"}
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <ButtonGroup>
                  <Button
                    disabled={getUsersApi.loading}
                    style={{
                      cursor: "unset",
                      color: "black",
                      borderColor: "1px solid rgba(0, 0, 0, 0.23)",
                    }}
                    onClick={() => handleToggle("filter")}
                  >
                    Filter Type:
                  </Button>
                  <Button
                    ref={anchorRef}
                    aria-controls={openFilter ? "menu-list-grow" : undefined}
                    aria-haspopup="true"
                    onClick={() => handleToggle("filter")}
                    title={dataFilter}
                    size={"large"}
                  >
                    {dataFilter.replace("_", " ")}
                    <ArrowDropDown />
                  </Button>
                </ButtonGroup>
                <div
                  style={{
                    borderLeft: "1px solid lightGrey",
                    marginLeft: "5px",
                    marginRight: "5px",
                  }}
                />
                <DateRangeModal
                  dateRange={dateRange}
                  setDateRange={setDateRange}
                />
                <Popper
                  open={openFilter}
                  anchorEl={anchorRef.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={openFilter}
                            id="menu-list-grow"
                            onKeyDown={(event) => {
                              if (event.key === "Tab") {
                                event.preventDefault();
                                setOpenFilter(false);
                              }
                            }}
                          >
                            {filterTitles.map((title) => (
                              <MenuItem
                                key={title}
                                onClick={() => setDataFilter(title)}
                              >
                                {title.replace("_", " ").toUpperCase()}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
                <Popper
                  open={openMemberFilter}
                  anchorEl={anchorRef2.current}
                  role={undefined}
                  transition
                  disablePortal
                  style={{ zIndex: 1 }}
                >
                  {({ TransitionProps, placement }) => (
                    <Grow
                      {...TransitionProps}
                      style={{
                        transformOrigin:
                          placement === "bottom"
                            ? "center top"
                            : "center bottom",
                      }}
                    >
                      <Paper>
                        <ClickAwayListener onClickAway={handleClose}>
                          <MenuList
                            autoFocusItem={openMemberFilter}
                            id="menu-list-grow"
                            onKeyDown={(event) => {
                              if (event.key === "Tab") {
                                event.preventDefault();
                                setOpenMemberFilter(false);
                              }
                            }}
                            style={{
                              maxHeight: 200,
                              overflowY: "auto",
                              zIndex: 200,
                            }}
                          >
                            <MenuItem
                              onClick={() => setMemberFilter("Everyone")}
                            >
                              Everyone
                            </MenuItem>
                            {getUsersApi.data.map((member) => (
                              <MenuItem
                                key={member.user_id}
                                onClick={() => setMemberFilter(member.user_id)}
                              >
                                {member.user_displayname}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </ClickAwayListener>
                      </Paper>
                    </Grow>
                  )}
                </Popper>
              </div>
            </Paper>

            {formattedData.length > 0 ? (
              <TeamActivityTracker
                hideDateColumn={openMemberFilter}
                members={getUsersApi.data}
                data={formattedData}
              />
            ) : (
              <Paper>
                <div
                  style={{
                    fontSize: "20px",
                    marginLeft: "10px",
                    padding: "20px",
                  }}
                >
                  {getUsersApi.loading ? (
                    <CircularProgress />
                  ) : (
                    "No data to show, check filters and date ranges"
                  )}
                </div>
              </Paper>
            )}
          </>
        )}
      </Container>
    </Page>
  );
};

export default TeamListView;
