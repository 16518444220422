export const sectionHeaderStyle = {
  fontWeight: "bold",
  fontSize: 18,
  marginBottom: 20,
  component: "div",
};

export const filterButtonSx = (isSelected) => ({
  justifyContent: "space-around",
  bgcolor: isSelected ? "#1F449F" : "#F4F6F8",
  color: isSelected ? "white" : "black",
  p: 1,
  display: "flex",
  flexDirection: "row",
  borderRadius: 2,
  textTransform: "none",
  fontSize: 16,
  "&:hover": {
    bgcolor: isSelected ? "#1a3b8a" : "#e5e7eb",
  },
});
