import React, { useState } from "react";
import {
  Box,
  Button,
  TextField,
  Typography,
  Stack,
  Checkbox,
} from "@mui/material";
import { ExpandMore } from "@mui/icons-material";

const FilterDropdown = ({
  title,
  options,
  valueKey,
  idKey, // for storing IDs instead of display values
  selectedValues,
  setSelectedValues,
  disabled = false,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");

  const filteredOptions = options.filter((option) => {
    const searchValue =
      typeof option === "string" ? option : option[valueKey || "state"];
    return searchValue.toLowerCase().includes(searchQuery.toLowerCase());
  });

  return (
    <Box
      sx={{
        mb: 1,
        opacity: disabled ? 0.5 : 1,
        pointerEvents: disabled ? "none" : "auto",
      }}
    >
      <Button
        onClick={() => setIsExpanded(!isExpanded)}
        sx={{
          bgcolor: "#1F449F",
          color: "white",
          justifyContent: "space-around",
          p: 1,
          "&:hover": {
            bgcolor: "#1a3b8a",
          },
          borderRadius: 2,
          width: "90%",
          margin: "0 auto",
          display: "flex",
        }}
        endIcon={
          <ExpandMore
            sx={{
              transform: isExpanded ? "rotate(180deg)" : "none",
              transition: "transform 0.2s",
            }}
          />
        }
      >
        <div />
        <Typography fontSize={16} fontWeight={"semibold"}>
          {`${title} (${
            selectedValues.length ? `Show ${selectedValues.length}` : "Show All"
          })`}
        </Typography>
      </Button>

      {isExpanded && (
        <div
          style={{
            marginTop: 10,
            padding: 20,
            maxHeight: "300px",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
              gap: 1,
            }}
          >
            <TextField
              size="small"
              placeholder="Search..."
              sx={{ flex: 1 }}
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </Box>
          <Stack spacing={1}>
            {filteredOptions.map((option) => {
              const displayValue = option[valueKey || "state"];
              const actualValue = idKey ? option[idKey] : displayValue;

              return (
                <Box
                  key={actualValue}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Checkbox
                    checked={selectedValues.includes(actualValue)}
                    onChange={(e) => {
                      setSelectedValues((prev) =>
                        e.target.checked
                          ? [...prev, actualValue]
                          : prev.filter((v) => v !== actualValue)
                      );
                    }}
                  />
                  <Typography>{displayValue}</Typography>
                </Box>
              );
            })}
          </Stack>
        </div>
      )}
    </Box>
  );
};

export default FilterDropdown;
