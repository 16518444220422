import {
  Box,
  FormControl,
  InputLabel,
  Button,
  Select,
  MenuItem,
} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import { observer } from "mobx-react-lite";
import { makeStyles } from "@mui/styles";
import { useStore } from "../../../../../stores/StoreContext";
import DistributorContactModal from "../../../../contacts/ContactsModal";
import distributorsAPI from "../../../../../api/distributors";
import accountsAPI from "../../../../../api/accounts";
import { ButtonGroup } from "@mui/material";
import StAccount from "./StAccount";
import usersAPI from "../../../../../api/users";
import StLabel from "./StLabel";
import { PersonAdd } from "@mui/icons-material";
const useStyles = makeStyles(() => ({
  select: {
    minWidth: 240,
  },
}));
const StContact = observer(
  ({
    variant,
    label,
    required,
    error,
    parentAccount = null,
    answer,
    changeAnswer = () => {},
    builderMode = false,
  }) => {
    const [contactType, setContactType] = useState("");
    const [contacts, setContacts] = useState([]);
    const [distributor, setDistributor] = useState("");
    const [contact, setContact] = useState("");
    const [account, setAccount] = useState("");
    const [newContact, setNewContact] = useState(null);
    const [showContactModal, setShowContactModal] = useState(false);
    const { distributorsList, setDistributors, userInfo } = useStore();

    const classes = useStyles();

    const contactTypes = ["distributor", "account", "team"];

    const dataChecks = () => {
      const { manufacturer_id } = userInfo;
      if (distributorsList.length === 0) {
        distributorsAPI.getDistributors(manufacturer_id).then((results) => {
          setDistributors(results);
          if (results.length > 0 && contactType === "distributor") {
            setDistributor(results[0].dist_id);
          }
        });
      }
    };

    useEffect(() => {
      dataChecks();
    }, []);

    useEffect(() => {
      if (variant !== "any") {
        setContactType(variant);
      }
    }, [variant]);

    useEffect(() => {
      if (distributor) {
        const { manufacturer_id } = userInfo;
        distributorsAPI
          .getDistributorContacts(manufacturer_id, distributor)
          .then(({ results }) =>
            setContacts(
              results.map((r) => ({
                ...r,
                name: r.name,
                id: r.contact_id,
              }))
            )
          );
      }
    }, [distributor]);

    useEffect(() => {
      if (!answer.objectValue) return;
      const { distributor_id, contact_id, user_id, account_id } =
        answer.objectValue;
      if (variant === "distributor") {
        if (distributor_id && distributor_id !== distributor) {
          setDistributor(distributor_id);
        }
        if (contact_id && contact !== contact_id) {
          setContact(contact_id);
        }
      }
      if (account_id) {
        setAccount(account_id);
      }
      if (answer.value && answer.value !== contact) {
        setContact(answer.value);
      }
    }, [answer]);

    useEffect(() => {
      if (!contact) return;
      const contactObj = contacts.find((c) => c.id === contact);
      if (!contactObj) {
        return console.error("Could not match id to a contact");
      }
      changeAnswer({
        objectValue: {
          ...contactObj,
          distributor_id: distributor,
          account: account,
          variant,
        },
        value: contact,
        textValue: contactObj.name,
      });
    }, [contact]);

    useEffect(() => {
      const { manufacturer_id } = userInfo;
      if (contactType === "team") {
        usersAPI.getUsers({ mid: manufacturer_id }).then(({ results }) =>
          setContacts(
            results.map((r) => ({
              ...r,
              id: r.user_id,
              name: r.user_displayname,
            }))
          )
        );
      }
    }, [contactType]);

    useEffect(() => {
      if (account) {
        accountsAPI
          .getContacts({
            aid: account,
            mid: userInfo.manufacturer_id,
          })
          .then(({ results }) => {
            setContacts(
              results.map((r) => ({
                ...r,
                name: r.name,
                id: r.contact_id,
              }))
            );
          });
      }
    }, [account]);

    const contactContext = useMemo(() => {
      if (contactType === "account") {
        return {
          context: "account",
          account,
        };
      }
      if (contactType === "distributor") {
        return {
          context: "distributor",
          distributor: distributorsList.find((d) => d.dist_id === distributor),
        };
      }
      if (contactType === "team") {
        return {
          context: "team",
        };
      }
      return {
        context: contactType,
      };
    }, [contactType, distributor, account]);

    const appendContact = (contact) => {
      const id = contact.contact_id ? contact.contact_id : 0;
      const name = contact.name ? contact.name : "Missing name";
      // TODO: get account contact returning object and change accordingly
      setContacts([
        ...contacts,
        {
          ...contact,
          id,
          name,
        },
      ]);
      setContact(id);
    };

    const contactChanged = ({ objectValue }) => {
      const { account } = objectValue;
      setAccount(account);
    };

    const [active, setActive] = useState(false);

    return (
      <FormControl fullWidth>
        <StLabel
          required={required}
          text={label}
          error={error}
          active={active}
        />
        {variant === "any" && (
          <div>
            <strong>Contact Type</strong>
            <ButtonGroup>
              {contactTypes.map((ct, i) => (
                <Button
                  key={i}
                  onClick={() => setContactType(ct)}
                  color={contactType === ct ? "primary" : "default"}
                >
                  {ct}
                </Button>
              ))}
            </ButtonGroup>
          </div>
        )}
        {contactType === "distributor" && (
          <Box>
            <Box>
              <FormControl
                style={{ marginTop: 10 }}
                fullWidth
                className={classes.select}
              >
                <InputLabel shrink>Distributor</InputLabel>
                <Select
                  value={distributor || ""}
                  onChange={(event) => setDistributor(event.target.value)}
                  MenuProps={{ disableScrollLock: true }}
                >
                  {distributorsList.map((dl) => (
                    <MenuItem key={dl.dist_id} value={dl.dist_id}>
                      {dl.dist_displayname}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </Box>
        )}
        {contactType === "account" && (
          <StAccount changeAnswer={contactChanged} setAccount={setAccount} />
        )}
        <FormControl
          style={{ marginTop: 10 }}
          fullWidth
          className={classes.select}
        >
          <InputLabel shrink required={required} error={error}>
            Contact
          </InputLabel>
          <Select
            value={contact}
            onChange={(event) => setContact(event.target.value)}
            error={error}
            MenuProps={{ disableScrollLock: true }}
            onFocus={() => setActive(true)}
            onBlur={() => setActive(false)}
          >
            {contacts.map((con) => (
              <MenuItem key={con.id} value={con.id}>
                {con.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>

        {contactType !== "team" && (
          <div style={{ flexDirection: "row" }}>
            <Button
              disabled={builderMode}
              onClick={() => setShowContactModal(true)}
              style={{ border: "1px solid black", marginTop: 10 }}
            >
              <PersonAdd style={{ marginRight: 5 }} />
              Create Contact
            </Button>
          </div>
        )}
        <Box display={"flex"}></Box>
        {showContactModal && (
          <DistributorContactModal
            contact={newContact}
            setContact={setNewContact}
            disabled={distributor === null}
            id={distributor}
            contactContext={contactContext}
            open={showContactModal}
            onClose={() => setShowContactModal(false)}
            appendContact={appendContact}
            parentAccount={null}
          />
        )}
      </FormControl>
    );
  }
);

export default StContact;
