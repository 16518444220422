import APICallWrapper from "./APICallWrappers";
import client from "./client";

const getFolders = ({ manufacturer_id }) => {
  return client.get("/storage/folders", { mid: manufacturer_id });
};

const createFolder = async ({
  folder_name,
  manufacturer_id,
  user_id,
  parent_folder_id = 0,
}) => {
  const key = `createFolder-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Creating folder...",
    "Folder created successfully.",
    "Failed to create folder.",
    () =>
      client.post("/storage/folders", {
        name: folder_name,
        manufacturer_id,
        user_id,
        parent_folder_id,
      })
  );
};

const updateFolder = async ({
  folder_name,
  folder_id,
  manufacturer_id,
  user_id,
}) => {
  const key = `updateFolder-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating folder...",
    "Folder updated successfully.",
    "Failed to update folder.",
    () =>
      client.put(`/storage/folders/${folder_id}`, {
        name: folder_name,
        manufacturer_id,
        user_id,
      })
  );
};

const deleteFolder = async (folder_id) => {
  const key = `deleteFolder-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting folder...",
    "Folder deleted successfully.",
    "Failed to delete folder.",
    () => client.del(`/storage/folders/${folder_id}`)
  );
};

export default {
  getFolders,
  createFolder,
  updateFolder,
  deleteFolder,
};
