// Define the complete set of default widgets
export const AVAILABLE_WIDGETS = () => {
  const widgets = [
    {
      id: "widget-7",
      title: "Sales Targets",
      infoText: "Shows your sales goals for your distributors",
      component: "SalesTargets",
      data: {
        initialState: {
          timeRange: "MTD",
          showBreakdown: false,
        },
        salesTargets: {
          year: 24000,
          q1: 4000,
          q2: 6000,
          q3: 6000,
          q4: 10000,
          month: 2000,
        },
      },
      rootCell: { col: 0, row: 0 },
      size: { colSpan: 2, rowSpan: 1 },
      minColSpan: 1,
      hidden: false,
    },
    {
      id: "widget-8",
      title: "Rep Targets",
      infoText: "Shows your accounts on a map",
      component: "RepTargets",
      data: {
        initialState: {
          timeRange: "MTD",
        },
        repTargets: {},
      },
      rootCell: { col: 2, row: 0 },
      size: { colSpan: 2, rowSpan: 1 },
      minColSpan: 2,
      hidden: false,
    },
    {
      id: "widget-3",
      title: "Monthly Goals",
      infoText: "Shows monthly goals for media, checkins, contacts, forms",
      component: "MonthlyGoals",
      data: {},
      rootCell: { col: 0, row: 1 },
      size: { colSpan: 1, rowSpan: 1 },
      minColSpan: 1,
      hidden: false,
    },
    {
      id: "widget-2",
      title: "Team Activity",
      infoText: "Shows recent team activity for all team members",
      component: "TeamActivityPreview",
      data: {},
      rootCell: { col: 1, row: 1 },
      size: { colSpan: 1, rowSpan: 1 },
      minColSpan: 1,
      hidden: false,
    },
    {
      id: "widget-1",
      title: "Period Comparison",
      infoText: "Shows comparisons in cases over different time periods",
      component: "SummaryTotalComparison",
      data: {},
      rootCell: { col: 2, row: 1 },
      size: { colSpan: 2, rowSpan: 1 },
      minColSpan: 2,
      hidden: false,
    },
    {
      id: "widget-4",
      title: "Top Accounts",
      infoText: "Shows a quick list of your top accounts",
      component: "TopAccounts",
      data: {
        initialState: {
          timeRange: "LAST_30_DAYS",
        },
      },
      rootCell: { col: 0, row: 2 },
      size: { colSpan: 2, rowSpan: 1 },
      minColSpan: 1,
      hidden: false,
    },
    {
      id: "widget-5",
      title: "Top Products",
      infoText: "Shows a quck list of your top products",
      component: "TopProducts",
      data: {
        initialState: {
          timeRange: "LAST_30_DAYS",
        },
      },
      rootCell: { col: 2, row: 2 },
      size: { colSpan: 2, rowSpan: 1 },
      minColSpan: 1,
      hidden: false,
    },
    {
      id: "widget-6",
      title: "Cluster Map",
      infoText: "Shows your accounts on a map",
      component: "STHeatMapCluster",
      data: {},
      rootCell: { col: 0, row: 3 },
      size: { colSpan: 2, rowSpan: 1 },
      minColSpan: 2,
      hidden: false,
    },
  ];
  return widgets;
};
