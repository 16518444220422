import React, { useEffect, useState } from "react";
import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";

// 1) Leaflet CSS imports (in your main CSS or here if bundler supports it):
// import "leaflet/dist/leaflet.css";
// import "react-leaflet-markercluster/dist/styles.min.css";

// 2) (Optional) Fix Leaflet's default icon URLs:
import markerIcon from "leaflet/dist/images/marker-icon.png";
import markerIcon2x from "leaflet/dist/images/marker-icon-2x.png";
import markerShadow from "leaflet/dist/images/marker-shadow.png";
import BrandFinderAPI from "../../api/brandfinder";

// Fix icons so they display properly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  iconRetinaUrl: markerIcon2x,
  iconUrl: markerIcon,
  shadowUrl: markerShadow,
});

function BrandFinderMap() {
  const [locations, setLocations] = useState([]);

  // Fetch your /brandfinder/map endpoint on mount:
  useEffect(() => {
    const fetchLocations = async () => {
      try {
        const response = await BrandFinderAPI.getMapPoints();
        const data = await response.data;
        setLocations(data); // e.g. [{name, latitude, longitude}, ...]
      } catch (error) {
        console.error("Error fetching map locations:", error);
      }
    };
    fetchLocations();
  }, []);

  // Decide on a default center & zoom (fallback if you have no locations)
  const defaultCenter = [40.0, -95.0]; // example: center of US
  const defaultZoom = 5;

  return (
    <div style={{ height: "600px", width: "100%" }}>
      <MapContainer
        center={defaultCenter}
        zoom={defaultZoom}
        style={{ height: "100%", width: "100%" }}
      >
        {/* A common tile layer: OpenStreetMap */}
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://openstreetmap.org">OpenStreetMap</a> contributors'
        />

        {/* Marker clustering */}
        <MarkerClusterGroup>
          {locations.map((loc, idx) => {
            // Ensure lat/long are valid
            if (!loc.latitude || !loc.longitude) return null;

            return (
              <Marker key={idx} position={[loc.latitude, loc.longitude]}>
                <Popup>{loc.name}</Popup>
              </Marker>
            );
          })}
        </MarkerClusterGroup>
      </MapContainer>
    </div>
  );
}

export default BrandFinderMap;
