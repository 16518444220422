import React, { useEffect, useState } from "react";
import {
  Paper,
  TableContainer,
  Typography,
  TablePagination,
  Button,
  CircularProgress,
  Switch,
} from "@mui/material";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
import { API } from "aws-amplify";
import {
  KeyboardArrowDown,
  KeyboardArrowUp,
  PriorityHigh,
} from "@mui/icons-material";

/**
 * Reusable Brick components
 */
const BlueBrick = () => (
  <div
    style={{
      width: 20,
      height: 20,
      backgroundColor: "#3C7DF6",
      borderRadius: 2,
    }}
  />
);

/**
 * RedBrick Component
 */
const RedBrick = ({ month = false, dist_id, legend = false }) => {
  const navigate = useNavigate();

  const linkToUploads = () => {
    if (month && dist_id) {
      navigate("/app/log/" + month + "/" + dist_id);
    }
  };

  const styles = {
    base: {
      width: 20,
      height: 20,
      backgroundColor: "#FF0000",
      borderRadius: 2,
      cursor: month && !legend ? "pointer" : "default",
      position: "relative",
    },
    icon: {
      color: "white",
      margin: 0,
      padding: 0,
      fontSize: "1.3rem",
      marginTop: -8,
    },
  };

  return (
    <div
      style={styles.base}
      onClick={() => (month && !legend ? linkToUploads() : null)}
    >
      <PriorityHigh style={styles.icon} />
    </div>
  );
};

const GreyBrick = () => (
  <div
    style={{
      width: 20,
      height: 20,
      backgroundColor: "#EDEDED",
      borderRadius: 2,
    }}
  />
);

/**
 * Table Header Row
 */
const DistributorTableHeader = ({
  monthNames,
  sortAsc,
  handleSortClick,
  currentYear,
  setCurrentYear,
  showDeactivated,
  setShowDeactivated,
}) => {
  return (
    <>
      {/* Toggle "Show Deactivated" Switch */}
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 10,
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Button onClick={() => setCurrentYear(currentYear - 1)}>{"<"}</Button>
          <Typography variant="subtitle1">{currentYear}</Typography>
          <Button
            onClick={() => {
              if (currentYear + 1 <= new Date().getFullYear()) {
                setCurrentYear(currentYear + 1);
              }
            }}
          >
            {">"}
          </Button>
        </div>

        {/* Show Deactivated Switch */}
        <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
          <Typography>Show Deactivated</Typography>
          <Switch
            checked={showDeactivated}
            onChange={() => setShowDeactivated((prev) => !prev)}
            color="primary"
          />
        </div>
      </div>

      {/* Column Headers */}
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginBottom: "10px",
        }}
      >
        <div style={{ flex: "1 0 25%" }}>
          <Button onClick={handleSortClick}>
            Dist Names {sortAsc ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </Button>
        </div>
        <div
          style={{
            flex: "1 0 75%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          {monthNames.map((monthName, index) => (
            <div key={index}>{monthName}</div>
          ))}
        </div>
      </div>
    </>
  );
};

/**
 * Distributor Row
 */
const DistributorRow = ({
  dist,
  monthNames,
  currentYear,
  currentMonth,
  thisYear,
  onRequestToggleDeactivate, // change function name for clarity
}) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        marginBottom: "10px",
      }}
    >
      {/* Distributor Name + Deactivate Button */}
      <div
        style={{
          flex: "1 0 25%",
          display: "flex",
          alignItems: "center",
          gap: 8,
        }}
      >
        <div
          style={{
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
          }}
          className="business-name"
        >
          <RouterLink to={"/app/distributorsDetails/" + dist.dist_id}>
            {dist.dist_displayname}
          </RouterLink>
        </div>
      </div>

      {/* Monthly Bricks */}
      <div
        style={{
          flex: "1 0 75%",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        {monthNames.map((_, index) => {
          const monthNumber = index + 1;
          const hasData = dist[currentYear]?.some(
            (item) => item.month === monthNumber
          );
          const isPastMonth = monthNumber <= currentMonth;
          const isCurrentYear = currentYear === thisYear;

          // If it has data, show BlueBrick
          if (hasData) {
            return <BlueBrick key={index} />;
          }

          // If no data, but it's a past month or a past year, show RedBrick
          if (isPastMonth || !isCurrentYear) {
            return (
              <RedBrick
                key={index}
                month={monthNumber}
                dist_id={dist.dist_id}
              />
            );
          }

          // Otherwise, show GreyBrick
          return <GreyBrick key={index} />;
        })}
      </div>
    </div>
  );
};

const DistributorDataByMonth = () => {
  const store = useStore();
  const { userInfo } = store;

  const [data, setData] = useState(null);
  const [currentYear, setCurrentYear] = useState(new Date().getFullYear());
  const currentMonth = new Date().getMonth();
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [page, setPage] = useState(0);
  const [sortAsc, setSortAsc] = useState(true);
  const thisYear = new Date().getFullYear();

  // New state: showDeactivated
  const [showDeactivated, setShowDeactivated] = useState(false);

  // State for confirmation dialog

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

  useEffect(() => {
    fetchDistributorData().then(setData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  /**
   * Fetch data from AWS Amplify
   */
  const fetchDistributorData = async () => {
    try {
      const response = await API.get(
        "backendGateway",
        `/manufacturers/${userInfo.mid}/distributors_data`
      );
      return response;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /**
   * Sort Distributors
   */
  const sortDistributors = (ascending) => {
    if (!data) return [];
    const sortedData = [...data].sort((a, b) => {
      const nameA = a.dist_displayname.toUpperCase();
      const nameB = b.dist_displayname.toUpperCase();
      if (nameA < nameB) return ascending ? -1 : 1;
      if (nameA > nameB) return ascending ? 1 : -1;
      return 0;
    });
    return sortedData;
  };

  const handleSortClick = () => {
    setSortAsc((prev) => !prev);
  };

  /**
   * For pagination
   */
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  /**
   * Count Blue & Red bricks for percentage
   */
  const countBlueAndRedBricks = () => {
    if (!data) return { blueBricks: 0, redBricks: 0 };

    let blueBricks = 0;
    let redBricks = 0;

    // Only filter out if user chooses not to show them
    const distributorsToCount = data.filter((dist) => {
      if (showDeactivated) {
        return true; // count all, including deactivated
      }
      return !dist.is_deactivated; // count only active
    });

    distributorsToCount.forEach((dist) => {
      monthNames.forEach((_, index) => {
        const monthNumber = index + 1;
        const hasData = dist[currentYear]?.some(
          (item) => item.month === monthNumber
        );

        if (hasData) {
          blueBricks++;
        } else if (monthNumber <= currentMonth || !(currentYear === thisYear)) {
          redBricks++;
        }
      });
    });

    return { blueBricks, redBricks };
  };

  const calculatePercentage = () => {
    const { blueBricks, redBricks } = countBlueAndRedBricks();
    const total = blueBricks + redBricks;
    if (total === 0) return "0.00";
    return ((blueBricks / total) * 100).toFixed(2);
  };

  /**
   * Actual toggle after user confirms
   */

  // Sort data first
  const sorted = sortDistributors(sortAsc);

  // Filter out deactivated unless showDeactivated is true
  const filtered = sorted.filter((dist) => {
    if (!showDeactivated) {
      return !dist.is_deactivated; // only show active
    }
    return true; // show all
  });

  return (
    <>
      {data ? (
        <TableContainer
          component={Paper}
          style={{ marginBottom: 20, padding: 10 }}
        >
          <div style={{ marginBottom: 10 }}>
            <Typography variant="subtitle1">
              {calculatePercentage()}% of Data Complete
            </Typography>
          </div>

          <DistributorTableHeader
            monthNames={monthNames}
            sortAsc={sortAsc}
            handleSortClick={handleSortClick}
            currentYear={currentYear}
            setCurrentYear={setCurrentYear}
            showDeactivated={showDeactivated}
            setShowDeactivated={setShowDeactivated}
          />

          <div style={{ display: "flex", flexDirection: "column" }}>
            {filtered
              .slice(page * rowsPerPage, (page + 1) * rowsPerPage)
              .map((dist, idx) => (
                <DistributorRow
                  key={idx}
                  dist={dist}
                  monthNames={monthNames}
                  currentYear={currentYear}
                  currentMonth={currentMonth}
                  thisYear={thisYear}
                />
              ))}
          </div>

          {/* Legend and Pagination */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              marginTop: 10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                gap: 10,
              }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {"Data Uploaded/Depletions: "} <BlueBrick />
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: 5,
                }}
              >
                {"Data Missing/No Depletions: "} <RedBrick legend={true} />
              </div>
            </div>

            <TablePagination
              component="div"
              count={filtered.length}
              page={page}
              rowsPerPage={rowsPerPage}
              onPageChange={handleChangePage}
              onRowsPerPageChange={(e) => setRowsPerPage(e.target.value)}
            />
          </div>
        </TableContainer>
      ) : (
        <CircularProgress />
      )}
    </>
  );
};

export default DistributorDataByMonth;
