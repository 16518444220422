import React, { useEffect, useState, useRef, useMemo } from "react";
import { Grid2, Box, Typography, Button } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useLocation } from "react-router";
import { useStore } from "../../stores/StoreContext";
import {
  useDepletionsContainerContext,
  useDepletionsContainerDispatchContext,
} from "../../stores/DepletionContextStore";
import { MegaFilterSection } from "./MegaFilterSection";
import MegaFilterGlobalBar from "./MegaFilterGlobalBar";
import BookMark from "./BookMark";
const tabButtonStyle = {
  float: "right",
  color: "#000000",
  boxShadow: "0 2px 2px rgba(0,0,0,0.5)",
  backgroundColor: "#fff",
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  marginRight: "0.5rem",
};

const MegaFilter = observer(() => {
  const [display, setDisplay] = useState(true);
  const [openSection, setOpenSection] = useState(null);
  const [enabledDistributors, setEnabledDistributors] = useState([]);
  const [enabledBrands, setEnabledBrands] = useState([]);
  const [interacted, setInteracted] = useState(false);
  const location = useLocation();
  const mfWrapper = useRef();

  const isBookMarkPage = /\/bm\/[\d\w-]{36}/.test(location.pathname);
  const {
    userInfo,
    allowPaths,
    allStates,
    allBrands,
    allDistributors,
    quantities,
    selectedStates,
    selectedBrands,
    selectedDistributors,
    selectedQuantity,
    selectedProducts,
    premiseTypes,
    regions,
    batchSetFilters,
    dateRange,
    dateRangeName,
    productJoiner,
    productSold,
    mappings,
    simpleMode,
    setSimpleMode,
    setSimpleModeCategory,
  } = useStore();

  const [localSets, setLocalSets] = useState({
    selectedBrands: [],
    selectedDistributors: [],
    selectedStates: [],
  });

  useEffect(() => {
    if (
      ((localSets.selectedBrands.length +
        localSets.selectedDistributors.length +
        localSets.selectedBrands.length ===
        0 &&
        !interacted) ||
        isBookMarkPage) &&
      JSON.stringify({
        selectedBrands,
        selectedDistributors,
        selectedStates,
      }) !== JSON.stringify(localSets)
    ) {
      setLocalSets({
        selectedBrands,
        selectedDistributors,
        selectedStates,
      });
    }
  }, [localSets, selectedBrands, selectedDistributors, selectedStates]);

  useEffect(() => {
    const {
      selectedStates: ss,
      selectedDistributors: sd,
      selectedBrands: sb,
    } = localSets;
    const currentString = JSON.stringify([
      selectedStates,
      selectedDistributors,
      selectedBrands,
    ]);
    const proposed = [ss, sd, sb];
    const proposedString = JSON.stringify(proposed);
    // exit if there's no filters to set yet
    if (proposed.flat().length === 0) return;
    // see if previous and proposed filters are different
    if (currentString !== proposedString && proposed.flat().length > 0) {
      // cheeky timeout to let checkbox render change
      setTimeout(() => {
        // batch set filters, triggering filtering in components
        batchSetFilters({
          selectedStates: ss,
          selectedBrands: sb,
          selectedDistributors: sd,
        });
      }, 50);
    }
  }, [
    localSets,
    selectedStates,
    selectedBrands,
    selectedDistributors,
    batchSetFilters,
  ]);
  const { depletions } = useDepletionsContainerContext();
  const depletionsContainerDispatch = useDepletionsContainerDispatchContext();

  const allowed =
    allowPaths.findIndex((ap) => location.pathname.match(ap)) > -1;

  const globalMinimal = useMemo(() => {
    return /(app\/lists)/.test(location.pathname);
  }, [location.pathname]);

  useEffect(() => {
    setDisplay(allowed);
  }, [allowed]);

  useEffect(() => {
    setOpenSection(null);
  }, [location]);

  const regionCodes = useMemo(() => {
    return regions
      .filter((r) => selectedStates.includes(r.long))
      .map((r) => r.code);
  }, [regions, selectedStates]);

  useEffect(() => {
    if (depletions.length === 0 || regionCodes.length === 0) {
      return;
    }
    const [start, end] = dateRange.map((d) => new Date(d).getTime());
    let [previousStart, previousEnd] = dateRange.map((d) => {
      const date = new Date(d);
      date.setFullYear(date.getFullYear() - 1);
      return date.getTime();
    });
    // do difference shift for previous
    // eg: if 15 months, it will be immediate previous 15 months
    // if "All Time" it will be two zeroes
    if (previousEnd > start) {
      const currentPeriodDiff = end - start;
      previousEnd = start - 1;
      previousStart = previousEnd - currentPeriodDiff;
    }
    // do we need to filter the other 3?
    if (
      selectedBrands.length !== allBrands.length ||
      selectedDistributors.length !== allDistributors.length ||
      regionCodes.length !== allStates.length
    ) {
      // one of the three is different, lets filter
      // by them and date
      const filtered = depletions.filter((item) => {
        return (
          regionCodes.includes(item.STATE) &&
          selectedDistributors.includes(item.DISTRIBUTOR_NAME) &&
          selectedBrands.includes(item.BRAND) &&
          premiseTypes.includes(item.PREMISETYPE) &&
          start <= item.TIMESTAMP &&
          end >= item.TIMESTAMP
        );
      });
      // TODO: use a better solution than this for working with "COMPARISON DATA"
      // timestamp checks probably will be performant enough
      // if doing All Time, there is no previous range
      const previousFiltered = depletions
        .filter((item) => {
          return (
            regionCodes.includes(item.STATE) &&
            selectedDistributors.includes(item.DISTRIBUTOR_NAME) &&
            selectedBrands.includes(item.BRAND) &&
            previousStart <= item.TIMESTAMP &&
            premiseTypes.includes(item.PREMISETYPE) &&
            previousEnd >= item.TIMESTAMP
          );
        })
        .map((item) => ({
          ...item,
          COMPARISON_DATA: true,
        }));

      depletionsContainerDispatch({
        type: "setDepletionsFiltered",
        filteredDepletions: {
          current: filtered,
          previous: previousFiltered,
        },
      });
    } else {
      // filter by date only
      const inRange = depletions.filter(
        (d) =>
          start <= d.TIMESTAMP &&
          end >= d.TIMESTAMP &&
          premiseTypes.includes(d.PREMISETYPE)
      );
      const previousInRange = depletions
        .filter(
          (d) =>
            previousStart <= d.TIMESTAMP &&
            previousEnd >= d.TIMESTAMP &&
            premiseTypes.includes(d.PREMISETYPE)
        )
        .map((item) => ({
          ...item,
          COMPARISON_DATA: true,
        }));

      depletionsContainerDispatch({
        type: "setDepletionsFiltered",
        filteredDepletions: {
          current: inRange,
          previous: previousInRange,
        },
      });
    }
  }, [
    selectedBrands,
    selectedDistributors,
    regionCodes,
    allBrands,
    allDistributors,
    allStates,
    dateRange,
    depletions,
    depletionsContainerDispatch,
    premiseTypes,
  ]);

  const showSelectRow = useMemo(() => {
    return {
      states: allStates.length > 1 && !globalMinimal,
      brands: allBrands.length > 1 && !globalMinimal,
      distributors: allDistributors.length > 1 && !globalMinimal,
    };
  }, [allStates, allDistributors, allBrands, globalMinimal]);

  const rangeToText = (range) => {
    const years = range.map((r) => r.getFullYear());
    const twoYears = years[0] !== years[1];
    return [
      range[0].toLocaleString("default", { month: "short" }),
      twoYears ? ` ${years[0]}` : "",
      " - ",
      range[1].toLocaleString("default", { month: "short" }),
      " ",
      years[1],
    ].join("");
  };

  /**
   *
   * @property {string} list name of list, corresponds to global object
   * @property {object} sets filter arrays to be addressed
   * @property {string} value value of checkbox clicked if available
   * @property {boolean} toggleAll is it a toggleAll action, short-circuits some things
   * @returns object with three arrays for new filter values
   */
  const modifySets = ({ list, sets, value, toggleAll = false }) => {
    // target propNames for each filter item
    const propNames = {
      selectedStates: "state_id",
      selectedDistributors: "dist_id",
      selectedBrands: "brand_id",
    };
    const queryProps = {
      selectedStates: "states",
      selectedDistributors: "dists",
      selectedBrands: "brands",
    };
    // this defines the downward funneling logic, propName changes filters matching array values
    const funnelSets = {
      selectedStates: ["selectedDistributors", "selectedBrands"],
      selectedDistributors: ["selectedBrands"],
      selectedBrands: [],
    };
    setSimpleModeCategory(queryProps[list]);
    /**
     * in simpleMode, we get the value of the interacted checkbox and filter
     * to only those items which correspond to that value
     *
     * in multi mode, we filter to those items which match any of the items in
     * the relative list. If we're changing distributors, we filter to those items
     * matching any of the active distributors, including the new addition
     *
     * if toggleAll has been pressed, we treat simpleMode like multi mode
     */
    const matches =
      simpleMode && !toggleAll
        ? mappings.filter((c) => c[propNames[list]] === value)
        : mappings.filter((c) =>
            sets[list].some((i) => c[propNames[list]] === i)
          );

    const changed = [
      "selectedStates",
      "selectedDistributors",
      "selectedBrands",
    ];

    let c = {};
    changed.forEach((item) => {
      /**
       * the targeted filter array is already correct when passed into this function
       * we just return it
       */
      if (item === list) {
        c[list] = sets[list];
      } else if (
        simpleMode ||
        (!simpleMode && funnelSets[list].includes(item))
      ) {
        // we make a new array for row of QS that needs updated
        c[item] = [...new Set(matches.map((m) => m[propNames[item]]))];
      }
    });
    // for multi mode, we enable certain fields and disable others
    // depending on what is currently selected
    if (!simpleMode) {
      funnelSets[list].forEach((item) => {
        if (item === "selectedDistributors") {
          setEnabledDistributors(matches.map((m) => m.dist_id));
        }
        if (item === "selectedBrands") {
          setEnabledBrands(matches.map((m) => m.brand_id));
        }
      });
    }
    // combine the passed-in sets with changed ones
    return {
      ...sets,
      ...c,
    };
  };

  const toggleInList = () => {};

  const addToFilter = ({ list, value }) => {
    let listToChange = new Set(localSets[list]);
    if (simpleMode) {
      listToChange = new Set([value]);
    } else {
      listToChange.add(value);
    }
    const newLocalSets = modifySets({
      list,
      sets: {
        ...localSets,
        [list]: [...listToChange],
      },
      simpleMode,
      value,
    });
    setLocalSets(newLocalSets);
    setInteracted(true);
  };
  const removeFromFilter = ({ list, value }) => {
    const listToChange = new Set(localSets[list]);
    listToChange.delete(value);
    const newLocalSets = modifySets({
      list,
      sets: {
        ...localSets,
        [list]: [...listToChange],
      },
      simpleMode,
      value,
    });
    setLocalSets(newLocalSets);
    setInteracted(true);
  };

  /**
   * @property {string} list name of the filter array we're changing
   * @property {boolean} on are we toggling all on or not on a.k.a. off
   */
  const setAll = ({ list, on }) => {
    // use list and an object to point at which source arrays would be used
    // each of these are "all values"
    const sourceLists = {
      selectedBrands: allBrands.map((s) => s.id),
      selectedStates: allStates.map((s) => s.id),
      selectedDistributors: allDistributors.map((s) => s.id),
    };
    const newLocalSets = modifySets({
      list,
      sets: {
        ...localSets,
        // set the array matching list to everything or nothing
        [list]: on ? sourceLists[list] : [],
      },
      toggleAll: true,
    });
    setLocalSets(newLocalSets);
    setInteracted(true);
  };

  const selectAll = () => {
    !interacted && setInteracted(true);
    setLocalSets({
      selectedStates: allStates.map((s) => s.id),
      selectedDistributors: allDistributors.map((s) => s.id),
      selectedBrands: allBrands.map((s) => s.id),
    });
  };
  const selectNone = () => {
    !interacted && setInteracted(true);
    setLocalSets({
      selectedStates: [],
      selectedDistributors: [],
      selectedBrands: [],
    });
  };

  const toggleSimpleMode = (v) => {
    if (!v) {
      const justInState = mappings.filter((db) =>
        selectedStates.includes(db.state)
      );
      const dd = justInState.map((d) => d.dist_displayname);
      const db = justInState.map((d) => d.product_brand);
      setEnabledDistributors(dd);
      setEnabledBrands(db);
    } else {
      setEnabledDistributors(mappings.map((d) => d.dist_displayname));
      setEnabledBrands(mappings.map((d) => d.brand));
    }
    setSimpleMode(v);
    setInteracted(true);
  };

  const toggleEditing = () => setDisplay(!display);

  const quantity = quantities.find((q) => q.value === selectedQuantity);
  const quantityText = quantity ? quantity.text : "";
  const quickBarText = `
      Showing data for ${selectedStates.length}/${allStates.length} States \
      ${selectedDistributors.length}/${allDistributors.length} Distributors \
      ${selectedBrands.length}/${allBrands.length} Brands \
      over ${dateRangeName} - ${rangeToText(dateRange)}. \
      Units: ${quantityText}
    `;

  if (!window.location.pathname.includes("/depletions/")) {
    return <></>;
  }

  return (
    <div className={`mega-filter-wrapper`} ref={mfWrapper}>
      <>
        {!display && !location.pathname.includes("/report-builder") && (
          <Grid2
            container
            alignItems="center"
            style={{
              boxShadow: "0 2px 2px rgba(0,0,0,0.5)",
              backgroundColor: "#fff",
            }}
          >
            <Grid2 xs={10}>
              <Box
                p={2}
                onClick={() => allowed && setDisplay(true)}
                style={{
                  cursor: "pointer",
                }}
              >
                <Typography
                  variant="subtitle2"
                  style={{
                    color: allowed ? "#000" : "#999",
                  }}
                >
                  {quickBarText}
                  {allowed && (
                    <span
                      className="floating-link"
                      style={{
                        color: "#24c",
                        fontSize: "0.8rem",
                        textDecoration: "underline",
                      }}
                    >
                      Edit
                    </span>
                  )}
                  {!allowed && (
                    <span style={{ color: "#999" }}>
                      (Inactive on this page)
                    </span>
                  )}
                </Typography>
              </Box>
            </Grid2>
          </Grid2>
        )}
        <div
          className="mega-filter-inner"
          style={{
            display: display ? "block" : "none",
          }}
        >
          {showSelectRow.states && (
            <MegaFilterSection
              title={"States"}
              completeFilter={mappings}
              data={allStates}
              data-arrlen={localSets.selectedStates.length}
              allFields={{
                propName: "state",
                list: allStates,
              }}
              activeFields={localSets.selectedStates}
              mfType="states"
              toggleBox={toggleInList("state")}
              filterParent={{
                name: "none",
                list: [],
              }}
              {...{
                openSection,
                setOpenSection,
                simpleMode,
                setSimpleMode: toggleSimpleMode,
                addToFilter,
                removeFromFilter,
                listName: "selectedStates",
                setAll,
              }}
            ></MegaFilterSection>
          )}
          {showSelectRow.distributors && (
            <MegaFilterSection
              title={"Distributors"}
              completeFilter={mappings}
              allFields={{
                propName: "dist_displayname",
                list: allDistributors,
              }}
              enabledList={enabledDistributors}
              data-arrlen={localSets.selectedDistributors.length}
              activeFields={localSets.selectedDistributors}
              mfType="distributors"
              toggleBox={toggleInList("dist_displayname")}
              filterParent={{
                propName: "state",
                list: selectedStates,
              }}
              {...{
                openSection,
                setOpenSection,
                simpleMode,
                setSimpleMode: toggleSimpleMode,
                addToFilter,
                removeFromFilter,
                listName: "selectedDistributors",
                setAll,
              }}
            ></MegaFilterSection>
          )}
          {showSelectRow.brands && (
            <MegaFilterSection
              title={"Brands"}
              completeFilter={mappings}
              data-arrlen={localSets.selectedBrands.length}
              allFields={{
                propName: "product_brand",
                list: allBrands,
              }}
              enabledList={enabledBrands}
              activeFields={localSets.selectedBrands}
              mfType="brands"
              toggleBox={toggleInList("product_brand")}
              filterParent={{
                propName: "dist_displayname",
                list: localSets.selectedDistributors,
              }}
              {...{
                openSection,
                setOpenSection,
                simpleMode,
                setSimpleMode: toggleSimpleMode,
                addToFilter,
                removeFromFilter,
                listName: "selectedBrands",
                setAll,
              }}
            ></MegaFilterSection>
          )}
          <MegaFilterGlobalBar
            location={location}
            globalMinimal={globalMinimal}
            {...{
              selectAll,
              selectNone,
            }}
          />
        </div>
        {allowed && (
          <div>
            <Button
              onClick={toggleEditing}
              variant="contained"
              size="small"
              style={tabButtonStyle}
            >
              {display ? "Hide Quick Select" : "Show Quick Select"}
            </Button>
            <BookMark
              {...{
                selectedBrands,
                selectedStates,
                selectedDistributors,
                dateRange,
                dateRangeName,
                selectedQuantity,
                selectedProducts,
                premiseTypes,
                userInfo,
                productJoiner,
                productSold,
                tabButtonStyle,
              }}
            />
          </div>
        )}
      </>
    </div>
  );
});

export default MegaFilter;
