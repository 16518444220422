export const SAMPLE_DATA = [
  {
    id: 1,
    account_name: "Sample Account 1",
    account_address: "789 Oak Avenue",
    account_city: "New York",
    account_region: "FL",
    account_regioncode: "44699",
    premise_name: "Off Premise",
    product_displayname: "Brother White",
    product_display_category: "IPA",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 212,
    cases_202402_prior: 498,
    cases_diff_value: -286,
    cases_diff_pct: -57.4,
  },
  {
    id: 2,
    account_name: "Sample Account 2",
    account_address: "654 Maple Lane",
    account_city: "Los Angeles",
    account_region: "IL",
    account_regioncode: "69721",
    premise_name: "Off Premise",
    product_displayname: "Brother Gold",
    product_display_category: "Ale",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 376,
    cases_202402_prior: 182,
    cases_diff_value: 194,
    cases_diff_pct: 106.6,
  },
  {
    id: 3,
    account_name: "Sample Account 3",
    account_address: "123 Main Street",
    account_city: "Los Angeles",
    account_region: "CA",
    account_regioncode: "20563",
    premise_name: "On Premise",
    product_displayname: "Cool Lager",
    product_display_category: "Stout",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "James Oliver",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 422,
    cases_202402_prior: 372,
    cases_diff_value: 50,
    cases_diff_pct: 13.4,
  },
  {
    id: 4,
    account_name: "Sample Account 4",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "FL",
    account_regioncode: "41962",
    premise_name: "On Premise",
    product_displayname: "Wave Amber",
    product_display_category: "Lager",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 256,
    cases_202402_prior: 485,
    cases_diff_value: -229,
    cases_diff_pct: -47.2,
  },
  {
    id: 5,
    account_name: "Sample Account 5",
    account_address: "654 Maple Lane",
    account_city: "New York",
    account_region: "CA",
    account_regioncode: "46291",
    premise_name: "Off Premise",
    product_displayname: "Brother Black",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 419,
    cases_202402_prior: 270,
    cases_diff_value: 149,
    cases_diff_pct: 55.2,
  },
  {
    id: 6,
    account_name: "Sample Account 6",
    account_address: "456 Broadway",
    account_city: "Los Angeles",
    account_region: "FL",
    account_regioncode: "52390",
    premise_name: "On Premise",
    product_displayname: "Cool IPA",
    product_display_category: "Lager",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "Ben Andrews",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 292,
    cases_202402_prior: 269,
    cases_diff_value: 23,
    cases_diff_pct: 8.6,
  },
  {
    id: 7,
    account_name: "Sample Account 7",
    account_address: "456 Broadway",
    account_city: "Houston",
    account_region: "CA",
    account_regioncode: "74702",
    premise_name: "On Premise",
    product_displayname: "Brother Blue",
    product_display_category: "IPA",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 473,
    cases_202402_prior: 278,
    cases_diff_value: 195,
    cases_diff_pct: 70.1,
  },
  {
    id: 8,
    account_name: "Sample Account 8",
    account_address: "456 Broadway",
    account_city: "Houston",
    account_region: "TX",
    account_regioncode: "84009",
    premise_name: "On Premise",
    product_displayname: "Wave Hazy",
    product_display_category: "Pilsner",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "James Oliver",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 269,
    cases_202402_prior: 434,
    cases_diff_value: -165,
    cases_diff_pct: -38.0,
  },
  {
    id: 9,
    account_name: "Sample Account 9",
    account_address: "321 Elm Street",
    account_city: "New York",
    account_region: "TX",
    account_regioncode: "67214",
    premise_name: "On Premise",
    product_displayname: "Wave Hazy",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 133,
    cases_202402_prior: 394,
    cases_diff_value: -261,
    cases_diff_pct: -66.2,
  },
  {
    id: 10,
    account_name: "Sample Account 10",
    account_address: "456 Broadway",
    account_city: "Los Angeles",
    account_region: "FL",
    account_regioncode: "72849",
    premise_name: "Off Premise",
    product_displayname: "Cool Lager",
    product_display_category: "Ale",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 287,
    cases_202402_prior: 220,
    cases_diff_value: 67,
    cases_diff_pct: 30.5,
  },
  {
    id: 11,
    account_name: "Sample Account 11",
    account_address: "789 Oak Avenue",
    account_city: "Miami",
    account_region: "IL",
    account_regioncode: "61682",
    premise_name: "On Premise",
    product_displayname: "Wave Light",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 187,
    cases_202402_prior: 201,
    cases_diff_value: -14,
    cases_diff_pct: -7.0,
  },
  {
    id: 12,
    account_name: "Sample Account 12",
    account_address: "321 Elm Street",
    account_city: "Chicago",
    account_region: "TX",
    account_regioncode: "36549",
    premise_name: "On Premise",
    product_displayname: "Cool Pilsner",
    product_display_category: "Stout",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 264,
    cases_202402_prior: 280,
    cases_diff_value: -16,
    cases_diff_pct: -5.7,
  },
  {
    id: 13,
    account_name: "Sample Account 13",
    account_address: "654 Maple Lane",
    account_city: "Chicago",
    account_region: "NY",
    account_regioncode: "47978",
    premise_name: "On Premise",
    product_displayname: "Cool Pilsner",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 331,
    cases_202402_prior: 347,
    cases_diff_value: -16,
    cases_diff_pct: -4.6,
  },
  {
    id: 14,
    account_name: "Sample Account 14",
    account_address: "654 Maple Lane",
    account_city: "Miami",
    account_region: "FL",
    account_regioncode: "64806",
    premise_name: "Off Premise",
    product_displayname: "Brother Black",
    product_display_category: "IPA",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 488,
    cases_202402_prior: 173,
    cases_diff_value: 315,
    cases_diff_pct: 182.1,
  },
  {
    id: 15,
    account_name: "Sample Account 15",
    account_address: "456 Broadway",
    account_city: "Miami",
    account_region: "NY",
    account_regioncode: "67541",
    premise_name: "On Premise",
    product_displayname: "Brother Blue",
    product_display_category: "Stout",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 184,
    cases_202402_prior: 120,
    cases_diff_value: 64,
    cases_diff_pct: 53.3,
  },
  {
    id: 16,
    account_name: "Sample Account 16",
    account_address: "456 Broadway",
    account_city: "New York",
    account_region: "CA",
    account_regioncode: "85818",
    premise_name: "Off Premise",
    product_displayname: "Wave Clear",
    product_display_category: "Lager",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 221,
    cases_202402_prior: 381,
    cases_diff_value: -160,
    cases_diff_pct: -42.0,
  },
  {
    id: 17,
    account_name: "Sample Account 17",
    account_address: "456 Broadway",
    account_city: "Los Angeles",
    account_region: "TX",
    account_regioncode: "96963",
    premise_name: "On Premise",
    product_displayname: "Brother Gold",
    product_display_category: "Stout",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 174,
    cases_202402_prior: 149,
    cases_diff_value: 25,
    cases_diff_pct: 16.8,
  },
  {
    id: 18,
    account_name: "Sample Account 18",
    account_address: "123 Main Street",
    account_city: "Miami",
    account_region: "IL",
    account_regioncode: "79926",
    premise_name: "Off Premise",
    product_displayname: "Brother Black",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 328,
    cases_202402_prior: 457,
    cases_diff_value: -129,
    cases_diff_pct: -28.2,
  },
  {
    id: 19,
    account_name: "Sample Account 19",
    account_address: "789 Oak Avenue",
    account_city: "Miami",
    account_region: "CA",
    account_regioncode: "80866",
    premise_name: "On Premise",
    product_displayname: "Cool Lager",
    product_display_category: "Stout",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 381,
    cases_202402_prior: 150,
    cases_diff_value: 231,
    cases_diff_pct: 154.0,
  },
  {
    id: 20,
    account_name: "Sample Account 20",
    account_address: "654 Maple Lane",
    account_city: "Houston",
    account_region: "CA",
    account_regioncode: "62750",
    premise_name: "On Premise",
    product_displayname: "Cool IPA",
    product_display_category: "Lager",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 321,
    cases_202402_prior: 259,
    cases_diff_value: 62,
    cases_diff_pct: 23.9,
  },
  {
    id: 21,
    account_name: "Sample Account 21",
    account_address: "654 Maple Lane",
    account_city: "Chicago",
    account_region: "FL",
    account_regioncode: "96056",
    premise_name: "On Premise",
    product_displayname: "Cool Pilsner",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 237,
    cases_202402_prior: 270,
    cases_diff_value: -33,
    cases_diff_pct: -12.2,
  },
  {
    id: 22,
    account_name: "Sample Account 22",
    account_address: "321 Elm Street",
    account_city: "Chicago",
    account_region: "CA",
    account_regioncode: "71264",
    premise_name: "On Premise",
    product_displayname: "Wave Dark",
    product_display_category: "Pilsner",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 326,
    cases_202402_prior: 432,
    cases_diff_value: -106,
    cases_diff_pct: -24.5,
  },
  {
    id: 23,
    account_name: "Sample Account 23",
    account_address: "456 Broadway",
    account_city: "Miami",
    account_region: "IL",
    account_regioncode: "98950",
    premise_name: "On Premise",
    product_displayname: "Brother White",
    product_display_category: "IPA",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 104,
    cases_202402_prior: 285,
    cases_diff_value: -181,
    cases_diff_pct: -63.5,
  },
  {
    id: 24,
    account_name: "Sample Account 24",
    account_address: "321 Elm Street",
    account_city: "Los Angeles",
    account_region: "TX",
    account_regioncode: "87231",
    premise_name: "Off Premise",
    product_displayname: "Wave Amber",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 498,
    cases_202402_prior: 402,
    cases_diff_value: 96,
    cases_diff_pct: 23.9,
  },
  {
    id: 25,
    account_name: "Sample Account 25",
    account_address: "456 Broadway",
    account_city: "Miami",
    account_region: "NY",
    account_regioncode: "89057",
    premise_name: "Off Premise",
    product_displayname: "Wave Clear",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 489,
    cases_202402_prior: 288,
    cases_diff_value: 201,
    cases_diff_pct: 69.8,
  },
  {
    id: 26,
    account_name: "Sample Account 26",
    account_address: "456 Broadway",
    account_city: "Los Angeles",
    account_region: "FL",
    account_regioncode: "48924",
    premise_name: "Off Premise",
    product_displayname: "Wave Dark",
    product_display_category: "IPA",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 465,
    cases_202402_prior: 343,
    cases_diff_value: 122,
    cases_diff_pct: 35.6,
  },
  {
    id: 27,
    account_name: "Sample Account 27",
    account_address: "654 Maple Lane",
    account_city: "Los Angeles",
    account_region: "CA",
    account_regioncode: "38868",
    premise_name: "Off Premise",
    product_displayname: "Wave Light",
    product_display_category: "Lager",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 462,
    cases_202402_prior: 385,
    cases_diff_value: 77,
    cases_diff_pct: 20.0,
  },
  {
    id: 28,
    account_name: "Sample Account 28",
    account_address: "789 Oak Avenue",
    account_city: "Houston",
    account_region: "CA",
    account_regioncode: "36473",
    premise_name: "Off Premise",
    product_displayname: "Cool Pale Ale",
    product_display_category: "Stout",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 203,
    cases_202402_prior: 395,
    cases_diff_value: -192,
    cases_diff_pct: -48.6,
  },
  {
    id: 29,
    account_name: "Sample Account 29",
    account_address: "654 Maple Lane",
    account_city: "Miami",
    account_region: "FL",
    account_regioncode: "55911",
    premise_name: "On Premise",
    product_displayname: "Wave Amber",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 497,
    cases_202402_prior: 145,
    cases_diff_value: 352,
    cases_diff_pct: 242.8,
  },
  {
    id: 30,
    account_name: "Sample Account 30",
    account_address: "789 Oak Avenue",
    account_city: "New York",
    account_region: "NY",
    account_regioncode: "31379",
    premise_name: "On Premise",
    product_displayname: "Cool Pale Ale",
    product_display_category: "Stout",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 127,
    cases_202402_prior: 377,
    cases_diff_value: -250,
    cases_diff_pct: -66.3,
  },
  {
    id: 31,
    account_name: "Sample Account 31",
    account_address: "789 Oak Avenue",
    account_city: "Miami",
    account_region: "NY",
    account_regioncode: "45451",
    premise_name: "On Premise",
    product_displayname: "Wave Clear",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "James Oliver",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 391,
    cases_202402_prior: 248,
    cases_diff_value: 143,
    cases_diff_pct: 57.7,
  },
  {
    id: 32,
    account_name: "Sample Account 32",
    account_address: "789 Oak Avenue",
    account_city: "Miami",
    account_region: "FL",
    account_regioncode: "36779",
    premise_name: "On Premise",
    product_displayname: "Brother White",
    product_display_category: "Pilsner",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 354,
    cases_202402_prior: 428,
    cases_diff_value: -74,
    cases_diff_pct: -17.3,
  },
  {
    id: 33,
    account_name: "Sample Account 33",
    account_address: "789 Oak Avenue",
    account_city: "Chicago",
    account_region: "TX",
    account_regioncode: "82789",
    premise_name: "Off Premise",
    product_displayname: "Wave Dark",
    product_display_category: "Ale",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 121,
    cases_202402_prior: 258,
    cases_diff_value: -137,
    cases_diff_pct: -53.1,
  },
  {
    id: 34,
    account_name: "Sample Account 34",
    account_address: "456 Broadway",
    account_city: "Houston",
    account_region: "TX",
    account_regioncode: "41576",
    premise_name: "Off Premise",
    product_displayname: "Wave Hazy",
    product_display_category: "Pilsner",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 145,
    cases_202402_prior: 183,
    cases_diff_value: -38,
    cases_diff_pct: -20.8,
  },
  {
    id: 35,
    account_name: "Sample Account 35",
    account_address: "789 Oak Avenue",
    account_city: "Houston",
    account_region: "NY",
    account_regioncode: "37044",
    premise_name: "On Premise",
    product_displayname: "Wave Hazy",
    product_display_category: "Ale",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 320,
    cases_202402_prior: 317,
    cases_diff_value: 3,
    cases_diff_pct: 0.9,
  },
  {
    id: 36,
    account_name: "Sample Account 36",
    account_address: "456 Broadway",
    account_city: "Chicago",
    account_region: "TX",
    account_regioncode: "27858",
    premise_name: "Off Premise",
    product_displayname: "Brother Red",
    product_display_category: "Ale",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "James Oliver",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 257,
    cases_202402_prior: 399,
    cases_diff_value: -142,
    cases_diff_pct: -35.6,
  },
  {
    id: 37,
    account_name: "Sample Account 37",
    account_address: "456 Broadway",
    account_city: "Los Angeles",
    account_region: "FL",
    account_regioncode: "26897",
    premise_name: "On Premise",
    product_displayname: "Brother Gold",
    product_display_category: "IPA",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Brand of Brothers",
    account_repname: "James Oliver",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 361,
    cases_202402_prior: 433,
    cases_diff_value: -72,
    cases_diff_pct: -16.6,
  },
  {
    id: 38,
    account_name: "Sample Account 38",
    account_address: "321 Elm Street",
    account_city: "Los Angeles",
    account_region: "TX",
    account_regioncode: "10532",
    premise_name: "Off Premise",
    product_displayname: "Wave Hazy",
    product_display_category: "Stout",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 404,
    cases_202402_prior: 132,
    cases_diff_value: 272,
    cases_diff_pct: 206.1,
  },
  {
    id: 39,
    account_name: "Sample Account 39",
    account_address: "123 Main Street",
    account_city: "Chicago",
    account_region: "FL",
    account_regioncode: "19359",
    premise_name: "Off Premise",
    product_displayname: "Cool Lager",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 224,
    cases_202402_prior: 356,
    cases_diff_value: -132,
    cases_diff_pct: -37.1,
  },
  {
    id: 40,
    account_name: "Sample Account 40",
    account_address: "321 Elm Street",
    account_city: "Miami",
    account_region: "FL",
    account_regioncode: "26063",
    premise_name: "On Premise",
    product_displayname: "Brother Black",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 261,
    cases_202402_prior: 301,
    cases_diff_value: -40,
    cases_diff_pct: -13.3,
  },
  {
    id: 41,
    account_name: "Sample Account 41",
    account_address: "456 Broadway",
    account_city: "Chicago",
    account_region: "FL",
    account_regioncode: "31846",
    premise_name: "Off Premise",
    product_displayname: "Brother White",
    product_display_category: "IPA",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 455,
    cases_202402_prior: 290,
    cases_diff_value: 165,
    cases_diff_pct: 56.9,
  },
  {
    id: 42,
    account_name: "Sample Account 42",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "FL",
    account_regioncode: "83982",
    premise_name: "On Premise",
    product_displayname: "Cool Lager",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 265,
    cases_202402_prior: 265,
    cases_diff_value: 0,
    cases_diff_pct: 0.0,
  },
  {
    id: 43,
    account_name: "Sample Account 43",
    account_address: "654 Maple Lane",
    account_city: "Miami",
    account_region: "IL",
    account_regioncode: "59521",
    premise_name: "On Premise",
    product_displayname: "Cool Stout",
    product_display_category: "IPA",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 463,
    cases_202402_prior: 246,
    cases_diff_value: 217,
    cases_diff_pct: 88.2,
  },
  {
    id: 44,
    account_name: "Sample Account 44",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "IL",
    account_regioncode: "96453",
    premise_name: "Off Premise",
    product_displayname: "Brother Gold",
    product_display_category: "Stout",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "James Oliver",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 419,
    cases_202402_prior: 295,
    cases_diff_value: 124,
    cases_diff_pct: 42.0,
  },
  {
    id: 45,
    account_name: "Sample Account 45",
    account_address: "321 Elm Street",
    account_city: "Houston",
    account_region: "NY",
    account_regioncode: "17195",
    premise_name: "On Premise",
    product_displayname: "Wave Amber",
    product_display_category: "Lager",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 175,
    cases_202402_prior: 355,
    cases_diff_value: -180,
    cases_diff_pct: -50.7,
  },
  {
    id: 46,
    account_name: "Sample Account 46",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "NY",
    account_regioncode: "15604",
    premise_name: "Off Premise",
    product_displayname: "Wave Light",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "James Oliver",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 256,
    cases_202402_prior: 270,
    cases_diff_value: -14,
    cases_diff_pct: -5.2,
  },
  {
    id: 47,
    account_name: "Sample Account 47",
    account_address: "321 Elm Street",
    account_city: "New York",
    account_region: "CA",
    account_regioncode: "86541",
    premise_name: "On Premise",
    product_displayname: "Brother Blue",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 213,
    cases_202402_prior: 376,
    cases_diff_value: -163,
    cases_diff_pct: -43.4,
  },
  {
    id: 48,
    account_name: "Sample Account 48",
    account_address: "456 Broadway",
    account_city: "Chicago",
    account_region: "TX",
    account_regioncode: "22945",
    premise_name: "On Premise",
    product_displayname: "Cool Pilsner",
    product_display_category: "Pilsner",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "The Cool Brand",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 453,
    cases_202402_prior: 187,
    cases_diff_value: 266,
    cases_diff_pct: 142.2,
  },
  {
    id: 49,
    account_name: "Sample Account 49",
    account_address: "789 Oak Avenue",
    account_city: "New York",
    account_region: "TX",
    account_regioncode: "57893",
    premise_name: "On Premise",
    product_displayname: "Wave Amber",
    product_display_category: "Lager",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 438,
    cases_202402_prior: 381,
    cases_diff_value: 57,
    cases_diff_pct: 15.0,
  },
  {
    id: 50,
    account_name: "Sample Account 50",
    account_address: "789 Oak Avenue",
    account_city: "Houston",
    account_region: "TX",
    account_regioncode: "73125",
    premise_name: "Off Premise",
    product_displayname: "Wave Light",
    product_display_category: "Lager",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Third Wave Brewing",
    account_repname: "Ben Andrews",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 425,
    cases_202402_prior: 300,
    cases_diff_value: 125,
    cases_diff_pct: 41.7,
  },
  {
    id: 51,
    account_name: "Sample Account 51",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "CA",
    account_regioncode: "52010",
    premise_name: "On Premise",
    product_displayname: "Wave Amber",
    product_display_category: "Pilsner",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 197,
    cases_202402_prior: 158,
    cases_diff_value: 39,
    cases_diff_pct: 24.7,
  },
  {
    id: 52,
    account_name: "Sample Account 52",
    account_address: "456 Broadway",
    account_city: "Miami",
    account_region: "TX",
    account_regioncode: "49085",
    premise_name: "Off Premise",
    product_displayname: "Brother Black",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 102,
    cases_202402_prior: 322,
    cases_diff_value: -220,
    cases_diff_pct: -68.3,
  },
  {
    id: 53,
    account_name: "Sample Account 53",
    account_address: "456 Broadway",
    account_city: "Houston",
    account_region: "IL",
    account_regioncode: "62257",
    premise_name: "On Premise",
    product_displayname: "Brother Black",
    product_display_category: "Stout",
    product_size: "12oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 482,
    cases_202402_prior: 500,
    cases_diff_value: -18,
    cases_diff_pct: -3.6,
  },
  {
    id: 54,
    account_name: "Sample Account 54",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "TX",
    account_regioncode: "38941",
    premise_name: "On Premise",
    product_displayname: "Cool Lager",
    product_display_category: "Stout",
    product_size: "12oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "Ben Andrews",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 218,
    cases_202402_prior: 365,
    cases_diff_value: -147,
    cases_diff_pct: -40.3,
  },
  {
    id: 55,
    account_name: "Sample Account 55",
    account_address: "321 Elm Street",
    account_city: "Houston",
    account_region: "NY",
    account_regioncode: "96473",
    premise_name: "On Premise",
    product_displayname: "Brother Red",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Miley Cahill",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 299,
    cases_202402_prior: 254,
    cases_diff_value: 45,
    cases_diff_pct: 17.7,
  },
  {
    id: 56,
    account_name: "Sample Account 56",
    account_address: "123 Main Street",
    account_city: "Houston",
    account_region: "NY",
    account_regioncode: "76732",
    premise_name: "On Premise",
    product_displayname: "Cool Pilsner",
    product_display_category: "Ale",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "Miley Cahill",
    distributor_name: "Hop 'n' Drop",
    cases_202502: 212,
    cases_202402_prior: 259,
    cases_diff_value: -47,
    cases_diff_pct: -18.1,
  },
  {
    id: 57,
    account_name: "Sample Account 57",
    account_address: "789 Oak Avenue",
    account_city: "New York",
    account_region: "IL",
    account_regioncode: "29524",
    premise_name: "On Premise",
    product_displayname: "Brother Black",
    product_display_category: "IPA",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 224,
    cases_202402_prior: 200,
    cases_diff_value: 24,
    cases_diff_pct: 12.0,
  },
  {
    id: 58,
    account_name: "Sample Account 58",
    account_address: "123 Main Street",
    account_city: "New York",
    account_region: "CA",
    account_regioncode: "11919",
    premise_name: "Off Premise",
    product_displayname: "Brother Blue",
    product_display_category: "Lager",
    product_size: "16oz",
    product_retail_unit: "BOTTLE",
    product_brand: "Brand of Brothers",
    account_repname: "Ben Andrews",
    distributor_name: "Yeast Mode Distribution",
    cases_202502: 477,
    cases_202402_prior: 105,
    cases_diff_value: 372,
    cases_diff_pct: 354.3,
  },
  {
    id: 59,
    account_name: "Sample Account 59",
    account_address: "456 Broadway",
    account_city: "Miami",
    account_region: "TX",
    account_regioncode: "84073",
    premise_name: "On Premise",
    product_displayname: "Cool IPA",
    product_display_category: "IPA",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "The Cool Brand",
    account_repname: "Miley Cahill",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 257,
    cases_202402_prior: 340,
    cases_diff_value: -83,
    cases_diff_pct: -24.4,
  },
  {
    id: 60,
    account_name: "Sample Account 60",
    account_address: "654 Maple Lane",
    account_city: "New York",
    account_region: "IL",
    account_regioncode: "90878",
    premise_name: "Off Premise",
    product_displayname: "Wave Light",
    product_display_category: "Stout",
    product_size: "16oz",
    product_retail_unit: "CAN",
    product_brand: "Third Wave Brewing",
    account_repname: "James Oliver",
    distributor_name: "Grainy Smith Distributor",
    cases_202502: 115,
    cases_202402_prior: 198,
    cases_diff_value: -83,
    cases_diff_pct: -41.9,
  },
];
