import { Dialog, DialogTitle } from "@mui/material";
import CustomerDetails from "../../../../views/lists/AccountDetailsView/CustomerDetails";

const SaveLocationDialog = ({
  location,
  onClose,
  open,
  customers,
  setCustomers,
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="simple-dialog-title"
      open={open}
    >
      <DialogTitle id="simple-dialog-title">Save Location</DialogTitle>
      <CustomerDetails
        onClose={handleClose}
        customers={customers}
        setCustomers={setCustomers}
        location={location}
      />
    </Dialog>
  );
};

export default SaveLocationDialog;
