import React, { useState, useMemo, useEffect } from "react";
import PropTypes from "prop-types";

import {
  Dialog,
  DialogTitle,
  Button,
  Box,
  Typography,
  TextField,
  FormControlLabel,
  Checkbox,
  Snackbar,
  Alert,
} from "@mui/material";

const BookmarkModal = ({
  open,
  save,
  cancel,
  name,
  setName,
  pinDate,
  setPinDate,
  saving,
  saved,
  bookmarkUuid,
}) => {
  const changeName = (event) => {
    setName(event.target.value);
  };

  const handlePinChange = (event) => {
    setPinDate(event.target.checked);
  };

  useEffect(() => {
    setCopied(false);
  }, [open]);

  const [copied, setCopied] = useState(false);

  const bookmarkUrl = useMemo(() => {
    return window.location.origin + "/app/bm/" + bookmarkUuid;
  }, [bookmarkUuid]);

  const copyBookmarkUrl = () => {
    navigator.clipboard.writeText(bookmarkUrl).then(
      () => {
        setCopied(true);
        /* success */
      },
      () => {
        /* failure */
      }
    );
  };

  return (
    <Dialog
      onClose={cancel}
      aria-labelledby="product-dialog"
      open={open}
      PaperProps={{
        style: {
          width: "540px",
          minHeight: 250,
          padding: 10,
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={"100%"}
      >
        <DialogTitle id="simple-dialog-title">
          Save a Bookmark of Your Current View
        </DialogTitle>
        <Typography variant="body1">
          Make a bookmark to save your current data filtering for re-use by you
          and your team.
        </Typography>
        <Box m={2} flexDirection={"column"} display={"flex"}>
          <TextField label="Bookmark Name" value={name} onChange={changeName} />
          <FormControlLabel
            control={
              <Checkbox
                name="is_public"
                checked={pinDate}
                onChange={handlePinChange}
              />
            }
            label="Pin Date"
          />
        </Box>
        <Typography variant="body2">
          Bookmarks with pinned dates will always show the same date.
          <br />
          If this box isn't checked, it will always show eg: YTD or last 3
          months, relative to the date of viewing.
        </Typography>
        {saved && (
          <Box
            style={{
              padding: "1rem",
              margin: "1rem 0",
              border: "1px solid #3c3",
            }}
          >
            <Typography variant="body1">Bookmark Saved</Typography>
            <Typography variant="body2">Url: {bookmarkUrl}</Typography>
            <Box my={1}>
              <Button
                onClick={copyBookmarkUrl}
                variant="contained"
                size="small"
              >
                Copy Link
              </Button>
            </Box>
          </Box>
        )}

        {saved && (
          <Box display="flex" gridColumnGap={10} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={cancel}
              type="button"
            >
              Close
            </Button>
          </Box>
        )}
        {!saved && (
          <Box display="flex" gridColumnGap={10} mt={2}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={cancel}
              type="button"
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              fullWidth
              type="button"
              onClick={save}
              disabled={saving}
            >
              Save Bookmark
            </Button>
          </Box>
        )}
      </Box>
      <Snackbar
        open={copied}
        autoHideDuration={6000}
        severity="success"
        onClose={() => {
          setCopied(false);
        }}
      >
        <Alert severity="success">Bookmark link copied to clipboard.</Alert>
      </Snackbar>
    </Dialog>
  );
};

BookmarkModal.propTypes = {
  open: PropTypes.bool.isRequired,
};
export default BookmarkModal;
