import { DateTime } from "luxon";
const generateComparisonString = (time, previous = false) => {
  const { granularity, offset, periods } = time;
  if (granularity === "month") {
    let end = DateTime.fromObject({
      year: Math.floor(offset / 100),
      month: offset % 100,
    });

    let start = end.minus({
      months: periods - 1,
    });
    if (previous) {
      start = start.minus({ year: 1 });
      end = end.minus({ year: 1 });
    }

    return start.toFormat("MMM yy") + " - " + end.toFormat("MMM yy");
  }
  if (granularity === "year") {
    if (previous) {
      return offset - periods + " - " + (offset - 1);
    }
    return offset - (periods - 1) + " - " + offset;
  }
  if (granularity === "quarter") {
    const q = offset % 10;
    const y = Math.floor(offset / 10);
    let end = `Q${q} ${y}`;
    if (periods === 1) {
      return end;
    }
    let endDate = DateTime.fromObject({
      year: y,
      month: q > 1 ? (q - 1) * 3 + 1 : 1,
    });
    let startDate = endDate.minus({
      months: (periods - 1) * 3,
    });
    if (previous) {
      startDate = startDate.minus({ year: 1 });
      endDate = endDate.minus({ year: 1 });
    }
    const startString = `Q${startDate.quarter} ${startDate.toFormat("yy")}`;
    const endString = `Q${endDate.quarter} ${endDate.toFormat("yy")}`;
    return `${startString} - ${endString}`;
  }
  return JSON.stringify(time);
};

const columnDefinitions = {
  premise_name: {
    width: 220,
  },
  account_region: {
    minWidth: 50,
  },
  product_displayname: {
    width: 220,
  },
  product_display_category: {
    minWidth: 120,
  },
  unitPeriod: {
    renderHeader: (params) => {
      const dateString = params.field.match(/[\d]{4,6}/)[0];
      const unit = ["cases_eqv", "cases", "units", "accounts_sold"]
        .find((c) => params.field.includes(c))
        .replace("_", " ");
      const compared = params.field.endsWith("prior");
      const isYear = dateString.length === 4;
      const isQuarter = dateString.length === 5;
      const isMonth = dateString.length === 6;

      let formattedDateString = "";

      const isoDateString = isYear
        ? dateString
        : dateString.slice(0, 4) + "-" + dateString.slice(4, 6);
      const date = new Date(isoDateString);
      if (isMonth) {
        const [year, month] = isoDateString.split("-");
        formattedDateString +=
          DateTime.fromObject({
            year,
            month,
          }).toFormat("LLL") + " ";
        formattedDateString += date.getFullYear();
      }
      if (isQuarter) {
        formattedDateString += `Q${dateString % 10} ${Math.floor(
          dateString / 10
        )}`;
      }
      if (isYear) {
        formattedDateString += date.getFullYear();
      }

      return (
        <div>
          {formattedDateString}
          <br />({compared ? "prev. " : ""}
          {unit})
        </div>
      );
    },
  },
  numberColumn: {
    valueFormatter(value) {
      if (!isNaN(parseFloat(value))) {
        return value.toFixed(2);
      }
      return value;
    },
  },
  periodsTotal({ time, headerName, measures }) {
    let periodRow = "";
    if (headerName.includes("current")) {
      periodRow = generateComparisonString(time);
    }
    if (headerName.includes("prior")) {
      periodRow = generateComparisonString(time, true);
    }
    return {
      renderHeader() {
        return (
          <div style={{ textAlign: "center" }}>
            {periodRow}
            <br />
            <span style={{ textTransform: "capitalize" }}>
              {measures.map((x) => x?.replace("_", " "))}
            </span>
          </div>
        );
      },
    };
  },
};

export default columnDefinitions;
