import React from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Grid2,
  Typography,
} from "@mui/material";
import MegaFilterStates from "./MegaFilterStates";
import { ExpandMore } from "@mui/icons-material";

export const MegaFilterSection = ({
  title,
  data,
  allFields,
  activeFields,
  toggleBox,
  completeFilter,
  filterParent,
  mfType,
  openSection,
  setOpenSection,
  simpleMode,
  setSimpleMode,
  enabledList,
  addToFilter,
  removeFromFilter,
  listName,
  setAll,
}) => {
  const listString = allFields.list
    .filter((f) => activeFields.includes(f.id))
    .map((f) => f.name)
    .sort()
    .join(", ");
  const toggleAccordion = () => {
    if (openSection === mfType) {
      setOpenSection(null);
    } else {
      setOpenSection(mfType);
    }
  };
  return allFields.list.length > 0 ? (
    <Accordion
      expanded={openSection === mfType}
      onChange={toggleAccordion}
      classes={{
        root: "mega-filter-section",
      }}
    >
      <AccordionSummary
        expandIcon={<ExpandMore />}
        classes={{
          content: "mega-filter-summary",
        }}
      >
        <Grid2 container spacing={0}>
          <Grid2 item>
            <Typography
              variant={"subtitle2"}
              style={{
                lineHeight: "2rem",
              }}
            >
              {title} {`${activeFields.length}/${allFields.list.length}`}
            </Typography>
          </Grid2>
          <Grid2 xs={10}>
            <Box
              style={{
                maxWidth: "70vw",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                overflow: "hidden",
              }}
            >
              <Typography
                variant="overline"
                style={{
                  lineHeight: "2rem",
                  paddingLeft: "8px",
                }}
              >
                {listString.length
                  ? listString
                  : "Nothing selected. No data will display."}{" "}
              </Typography>
            </Box>
          </Grid2>
        </Grid2>
      </AccordionSummary>
      <AccordionDetails
        classes={{
          root: "mega-filter-states",
        }}
      >
        <MegaFilterStates
          allList={data}
          enabledList={enabledList}
          {...{
            allFields,
            activeFields,
            toggleBox,
            completeFilter,
            filterParent,
            mfType,
            simpleMode,
            setSimpleMode,
            addToFilter,
            removeFromFilter,
            listName,
            setAll,
          }}
        />
      </AccordionDetails>
    </Accordion>
  ) : (
    <></>
  );
};
