import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import { API } from "aws-amplify";
import RadialBarChart from "../../views/targets/radialBarChart";
import { useStore } from "../../stores/StoreContext";
import CircularProgress from "@mui/material/CircularProgress";

const GoalItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(3),
}));

const GoalIcon = styled(Box)(({ color }) => ({
  width: 60,
  height: 60,
  borderRadius: "50%",
  backgroundColor: color,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginRight: 16,
}));

const MonthlyGoals = ({ height, size }) => {
  const { userInfo } = useStore();
  const [contactObject, setContactObject] = useState({ count: 0, data: [] });
  const [checkInObject, setCheckInObject] = useState({ count: 0, data: [] });
  const [imageObject, setImageObject] = useState({ count: 0, data: [] });
  const [formResponseObject, setFormResponseObject] = useState({
    count: 0,
    data: [],
  });

  const [goals, setGoals] = useState({
    Contacts: 0,
    CheckIns: 0,
    Media: 0,
    Forms: 0,
  });

  const [isLoading, setIsLoading] = useState(true);

  // Get colSpan from gridProps
  const colSpan = size?.colSpan || 1;
  const isCompact = colSpan === 1;

  useEffect(() => {
    if (userInfo && userInfo.mid) {
      setIsLoading(true);
      fetchGoals()
        .then(() => {
          getCurrentProgress().then((res) => {
            if (res) {
              fillTypeObjects(res);
            }
            setIsLoading(false);
          });
        })
        .catch((err) => {
          console.error("Error fetching activities:", err);
          setIsLoading(false);
        });
    }
  }, [userInfo]);

  // Function to get current progress
  const getCurrentProgress = async () => {
    try {
      const data = await API.get("backendGateway", "/teams/activities", {
        queryStringParameters: {
          mid: userInfo.mid,
        },
      });

      return data.results.filter(
        (item) => item.user_id === parseInt(userInfo.user_id)
      );
    } catch (err) {
      console.error("Error fetching activities:", err);
      return [];
    }
  };

  const fetchGoals = async () => {
    if (!userInfo || !userInfo.user_id) {
      console.warn("No userInfo.user_id available");
      return;
    }

    try {
      const response = await API.get("backendGateway", "/teams/targets", {
        queryStringParameters: {
          uid: userInfo.user_id,
          limit: 1000,
        },
      });

      const filteredGoals = response.results.filter((item) =>
        ["Forms", "Contacts", "CheckIns", "Media"].includes(item?.target_type)
      );

      const mostRecentGoals = filteredGoals.reduce((acc, goal) => {
        if (
          !acc[goal.target_type] ||
          goal.created > acc[goal.target_type].created
        ) {
          acc[goal.target_type] = goal;
        }
        return acc;
      }, {});

      const tempGoals = {
        Contacts: 0,
        CheckIns: 0,
        Media: 0,
        Forms: 0,
      };

      Object.values(mostRecentGoals).forEach((item) => {
        tempGoals[item.target_type] = item.target || 0;
      });

      setGoals(tempGoals);
    } catch (err) {
      console.error("Error fetching goals:", err);
    }
  };

  const fillTypeObjects = (profileResults) => {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1; // month in range 1-12

    let tempContactCount = 0;
    let tempCheckInCount = 0;
    let tempImageCount = 0;
    let formResponseCount = 0;
    const tempContactArray = [];
    const tempCheckInArray = [];
    const tempImageArray = [];
    const formResponseArray = [];

    profileResults.forEach((item) => {
      const itemDate = new Date(item.created);
      const itemYear = itemDate.getFullYear();
      const itemMonth = itemDate.getMonth() + 1; // 1-12

      if (item.type === "account_checkin") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          tempCheckInCount += 1;
        }
        tempCheckInArray.push(item.created);
      } else if (item.type === "media_upload") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          tempImageCount += 1;
        }
        tempImageArray.push(item.created);
      } else if (item.type === "form_response") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          formResponseCount += 1;
        }
        formResponseArray.push(item.created);
      } else if (item.type === "contact_created") {
        if (itemYear === currentYear && itemMonth === currentMonth) {
          tempContactCount += 1;
        }
        tempContactArray.push(item.created);
      }
    });

    setContactObject({
      count: tempContactCount,
      data: tempContactArray.sort((a, b) => b.created - a.created),
    });
    setCheckInObject({
      count: tempCheckInCount,
      data: tempCheckInArray.sort((a, b) => b.created - a.created),
    });
    setImageObject({
      count: tempImageCount,
      data: tempImageArray.sort((a, b) => b.created - a.created),
    });
    setFormResponseObject({
      count: formResponseCount,
      data: formResponseArray.sort((a, b) => b.created - a.created),
    });
  };

  // Modify chart data calculation
  const chartData = [
    [
      contactObject.count || 0,
      Math.max(goals["Contacts"] - (contactObject.count || 0), 0),
    ],
    [
      checkInObject.count || 0,
      Math.max(goals["CheckIns"] - (checkInObject.count || 0), 0),
    ],
    [
      imageObject.count || 0,
      Math.max(goals["Media"] - (imageObject.count || 0), 0),
    ],
    [
      formResponseObject.count || 0,
      Math.max(goals["Forms"] - (formResponseObject.count || 0), 0),
    ],
  ];

  // Render different layouts based on compact mode
  return (
    <Paper
      elevation={0}
      sx={{
        height: height || 400,
        display: "flex",
        flexDirection: "column",
        p: 3,
        borderRadius: 2,
        overflow: "hidden",
        overscrollBehavior: "contain",
        overflowY: "hidden",
      }}
    >
      {isCompact ? (
        // Compact layout (colSpan=1)
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: "100%",
          }}
        >
          {!isLoading ? (
            <>
              <Box sx={{ mb: 2, width: "100%", maxWidth: 200, height: 200 }}>
                <RadialBarChart counts={chartData} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  width: "100%",
                  gap: 2,
                }}
              >
                <GoalIcon color="#75B96D" sx={{ width: 40, height: 40, mb: 0 }}>
                  <Box
                    component="img"
                    src="/static/images/icons/Contact.svg"
                    alt="Contacts"
                    sx={{ width: 50, height: 50 }}
                  />
                </GoalIcon>
                <GoalIcon color="#49D1DD" sx={{ width: 40, height: 40, mb: 0 }}>
                  <Box
                    component="img"
                    src="/static/images/icons/CheckIn.svg"
                    alt="CheckIns"
                    sx={{ width: 50, height: 50 }}
                  />
                </GoalIcon>
                <GoalIcon color="#8676FF" sx={{ width: 40, height: 40, mb: 0 }}>
                  <Box
                    component="img"
                    src="/static/images/icons/Image.svg"
                    alt="Media"
                    sx={{ width: 50, height: 50 }}
                  />
                </GoalIcon>
                <GoalIcon color="#FF708B" sx={{ width: 40, height: 40, mb: 0 }}>
                  <Box
                    component="img"
                    src="/static/images/icons/Form.svg"
                    alt="Forms"
                    sx={{ width: 50, height: 50 }}
                  />
                </GoalIcon>
              </Box>
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      ) : (
        // Expanded layout (colSpan=2)
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            flexGrow: 1,
          }}
        >
          {/* Left column - Goals list */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              marginRight: "40px",
              mt: 2,
            }}
          >
            <GoalItem>
              <GoalIcon color="#75B96D">
                <Box
                  component="img"
                  src="/static/images/icons/Contact.svg"
                  alt="Contacts"
                  sx={{ width: 60, height: 60 }}
                />
              </GoalIcon>
              <Box>
                <Typography variant="h6">Contacts</Typography>
                <Typography variant="h5">
                  {contactObject.count}/{goals.Contacts}
                </Typography>
              </Box>
            </GoalItem>

            <GoalItem>
              <GoalIcon color="#49D1DD">
                <Box
                  component="img"
                  src="/static/images/icons/CheckIn.svg"
                  alt="CheckIns"
                  sx={{ width: 60, height: 60 }}
                />
              </GoalIcon>
              <Box>
                <Typography variant="h6">CheckIns</Typography>
                <Typography variant="h5">
                  {checkInObject.count}/{goals.CheckIns}
                </Typography>
              </Box>
            </GoalItem>

            <GoalItem>
              <GoalIcon color="#8676FF">
                <Box
                  component="img"
                  src="/static/images/icons/Image.svg"
                  alt="Media"
                  sx={{ width: 60, height: 60 }}
                />
              </GoalIcon>
              <Box>
                <Typography variant="h6">Media</Typography>
                <Typography variant="h5">
                  {imageObject.count}/{goals.Media}
                </Typography>
              </Box>
            </GoalItem>

            <GoalItem>
              <GoalIcon color="#FF708B">
                <Box
                  component="img"
                  src="/static/images/icons/Form.svg"
                  alt="Forms"
                  sx={{ width: 60, height: 60 }}
                />
              </GoalIcon>
              <Box>
                <Typography variant="h6">Forms</Typography>
                <Typography variant="h5">
                  {formResponseObject.count}/{goals.Forms}
                </Typography>
              </Box>
            </GoalItem>
          </Box>

          {/* Right column - Chart */}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "60%",
            }}
          >
            {!isLoading ? (
              <RadialBarChart counts={chartData} />
            ) : (
              <CircularProgress />
            )}
          </Box>
        </Box>
      )}
    </Paper>
  );
};

export default MonthlyGoals;
