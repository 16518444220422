import React, { useState, useEffect } from "react";
import {
  Box,
  Typography,
  Avatar,
  LinearProgress,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from "@mui/material";
import { Person } from "@mui/icons-material";
import { DateTime } from "luxon";
import { awsS3Image } from "../../helpers/awsS3Image";
import { useStore } from "../../stores/StoreContext";
import teamApi from "../../api/team";
import usersApi from "../../api/users";
import useApi from "../../hooks/useApi";

// Type name mapping for activity icons
const typeNames = {
  account_checkin: "CheckIn",
  contact_created: "Contact",
  media_upload: "Image",
  form_response: "Form",
  visit_scheduled: "Calendar",
};

const TeamActivityPreview = ({ size, height }) => {
  const getUsersApi = useApi(usersApi.getUsers);
  const [activityData, setActivityData] = useState([]);
  const [members, setMembers] = useState(null);
  const [loading, setLoading] = useState(true);
  const store = useStore();
  const { userInfo } = store;

  useEffect(() => {
    getUsersApi.request({ mid: userInfo.mid, limit: 1000 }).then((res) => {
      setMembers(res.results);
    });
  }, []);

  // Determine if we should show the full or compact view
  const isCompact = size.colSpan === 1;

  useEffect(() => {
    const fetchData = async () => {
      if (!userInfo?.mid) return;

      try {
        setLoading(true);
        // Fetch activity data using the proper teamApi
        const activityResponse = await teamApi.getTeamActivity(userInfo.mid);

        // Sort by date and take only the 10 most recent items
        const sortedActivity = (activityResponse.results || [])
          .sort((a, b) => new Date(b.created) - new Date(a.created))
          .slice(0, 10);

        setActivityData(sortedActivity);
      } catch (error) {
        console.error("Error fetching team activity data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    return () => {
      setLoading(false);
    };
  }, [userInfo?.mid]);

  // User image rendering functions based on TeamActivityTracker
  const renderFormerEmployeeImage = () => (
    <Avatar sx={{ width: 50, height: 50 }}>
      <Person sx={{ color: "white" }} />
    </Avatar>
  );

  const renderDefaultUserImage = () => (
    <Avatar
      src="/static/images/avatars/gg_default_image.png"
      sx={{ width: 50, height: 50 }}
    />
  );

  const renderCustomUserImage = (identitykey, name, image) => (
    <Avatar
      src={awsS3Image(image, identitykey, "0x110")}
      alt={name}
      sx={{ width: 50, height: 50 }}
    />
  );

  const renderUserImage = (
    identitykey = "",
    name = "Unknown",
    image = "",
    userId
  ) => {
    // Add a check to ensure members is an array before using find
    const member = Array.isArray(members)
      ? members.find((m) => m.user_id === userId)
      : null;
    if (!member) {
      return renderFormerEmployeeImage();
    }
    const normalizedImage = (image || "").toUpperCase();
    if (normalizedImage === "FORMER_EMPLOYEE.PNG") {
      return renderFormerEmployeeImage();
    } else if (normalizedImage === "GG_DEFAULT_IMAGE.PNG") {
      return renderDefaultUserImage();
    } else {
      return renderCustomUserImage(identitykey, name, image);
    }
  };

  // Format the time
  const formatTimeAgo = (timestamp) => {
    const now = DateTime.now();
    const time = DateTime.fromISO(timestamp);
    const diff = now.diff(time, ["minutes", "hours", "days"]);

    if (diff.days >= 1) {
      return `${Math.floor(diff.days)}d ago`;
    } else if (diff.hours >= 1) {
      return `${Math.floor(diff.hours)}h ago`;
    } else {
      return `${Math.floor(diff.minutes)}m ago`;
    }
  };

  // Get activity label based on type
  const getActivityLabel = (type) => {
    switch (type) {
      case "account_checkin":
        return "Checked in";
      case "contact_created":
        return "Created a Contact";
      case "media_upload":
        return "Uploaded an Image";
      case "form_response":
        return "Filled a form";
      case "visit_scheduled":
        return "Scheduled a Visit";
      default:
        return "Performed an action";
    }
  };

  if (loading || !members) {
    return (
      <Box sx={{ height: "100%", overflow: "hidden" }}>
        <LinearProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ height: "100%", overflow: "hidden", mt: 1 }}>
      {loading && <LinearProgress />}
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <Table>
          <TableBody>
            {activityData.map((activity, index) => {
              // Add a check for members being an array
              const member = Array.isArray(members)
                ? members.find((m) => m.user_id === activity.user_id)
                : null;
              const eventType = activity.type || "";
              const iconName = typeNames[eventType] || "Generic";
              const firstName = activity.user_displayname.includes("@")
                ? activity.user_displayname.split("@")[0]
                : activity.user_displayname.split(" ")[0];

              return (
                <TableRow
                  key={`${activity.user_id}-${activity.created}`}
                  sx={{
                    "&:last-child td, &:last-child th": { border: 0 },
                  }}
                >
                  {/* User column */}
                  <TableCell style={{ padding: "8px", width: "100px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      {renderUserImage(
                        member?.user_identitykey,
                        activity.user_displayname,
                        member?.user_image || "",
                        activity.user_id
                      )}
                      <Typography variant="body2" align="center">
                        {firstName}
                      </Typography>
                    </Box>
                  </TableCell>

                  {/* Activity column */}
                  <TableCell style={{ padding: "8px" }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box
                        component="img"
                        src={`/static/images/icons/${iconName}.svg`}
                        alt={iconName}
                        sx={{ width: 50, height: 50 }}
                      />
                      <Typography variant="body2" align="center">
                        {getActivityLabel(eventType)}
                      </Typography>
                    </Box>
                  </TableCell>

                  {/* Account column - Only visible when expanded */}
                  {!isCompact && (
                    <TableCell style={{ padding: "8px", textAlign: "center" }}>
                      {activity.account_displayname && (
                        <Typography
                          color="primary"
                          variant="body2"
                          sx={{ fontWeight: "medium" }}
                        >
                          {activity.account_displayname}
                        </Typography>
                      )}
                    </TableCell>
                  )}

                  {/* Time column */}
                  <TableCell style={{ padding: "8px", textAlign: "right" }}>
                    <Typography variant="body2" color="text.secondary">
                      {formatTimeAgo(activity.created)}
                    </Typography>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box>
    </Box>
  );
};

export default TeamActivityPreview;
