import React from "react";
import Page from "../../components/Page";
import "leaflet/dist/leaflet.css";
import { Container } from "@mui/material";
import BrandMap from "../../components/visualisation/brands/BrandMap";

const BrandMapView = () => {
  return (
    <Page title="Brand Map">
      <Container
        maxWidth={false}
        style={{ paddingBottom: "24px", paddingTop: "24px" }}
      >
        <BrandMap />
      </Container>
    </Page>
  );
};

export default BrandMapView;
