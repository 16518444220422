import { Typography, Box, Card, CardContent } from "@mui/material";
import { useEffect, useState } from "react";
import ViewDistributors from "./viewDistributors";
import EditDistributors from "./editDistributors";
import { useStore } from "../../../stores/StoreContext";
import { autorun } from "mobx";
import { useObserver } from "mobx-react-lite";
import { Link as RouterLink } from "react-router-dom";

const Detail = () => {
  const [isEdit, setIsEdit] = useState(false);
  const [distributorDetails, setDistributorDetails] = useState({});
  const [distributorData, setDistributorData] = useState({});
  const store = useStore();

  useEffect(() => {
    autorun(() => {
      setDistributorDetails(store.distributorDetails);
      setDistributorData(store.distributorDetails);
    });
  }, [store.distributorDetails]);
  const handleEdit = (value) => {
    setIsEdit(value);
    if (value === false) {
      setDistributorData(distributorDetails);
    }
  };

  return useObserver(() => (
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="flex-between" alignItems="center">
          <Typography gutterBottom variant="h5" component="h2">
            <RouterLink to={"/app/distributors"}>{"Distributors"}</RouterLink>
            {` > ${
              distributorDetails.dist_displayname
                ? distributorDetails.dist_displayname
                : ""
            }`}
          </Typography>
          {/* {!isEdit ? (
            <Button
              color="primary"
              startIcon={<EditIcon />}
              onClick={() => handleEdit(true)}
            >
              Edit
            </Button>
          ) : (
            <Button
              color="primary"
              startIcon={<CancelIcon />}
              onClick={() => handleEdit(false)}
            >
              Cancel
            </Button>
          )} */}
        </Box>
        {!isEdit ? (
          <ViewDistributors distributorDetails={distributorDetails} />
        ) : (
          <EditDistributors
            distributor={distributorData}
            setDistributor={setDistributorData}
          />
        )}
      </CardContent>
    </Card>
  ));
};
export default Detail;
