import { Box, Breadcrumbs, Grid2, Link } from "@mui/material";
import { useMemo } from "react";
import { File } from "react-feather";

import InfoCard from "./InfoCard";
import FileDetailView from "./FileDetailView.js";
import { useStore } from "../../stores/StoreContext";
import { useLocation, useNavigate } from "react-router";
import { ChevronRight, Image, PictureAsPdf, Replay } from "@mui/icons-material";

const fileIcon = (fileName) => {
  const ext = fileName.split(".").reverse()[0].toLowerCase();
  if (ext.length > 3) return <File size={"40"} style={{ color: "black" }} />;
  switch (ext) {
    case "png":
    case "jpg":
      return <Image style={{ fontSize: "40", color: "black" }} />;
    case "pdf":
      return <PictureAsPdf style={{ fontSize: "40", color: "black" }} />;
    default:
      return <File style={{ fontSize: "40", color: "black" }} />;
  }
};

const MediaFile = ({ files, folders, folder_id, file_id }) => {
  const { manufacturer } = useStore();
  const navigate = useNavigate();
  const location = useLocation();

  const file = useMemo(() => {
    const existingFile = files.find((f) => f.file_id === parseInt(file_id, 10));
    return existingFile || null;
  }, [files, file_id]);

  const infoCards = [
    {
      isBack: true,
      name: "Go Back",
      icon: <Replay style={{ fontSize: "40", color: "black" }} />,
    },
    {
      name: file?.file_name || "",
      icon: fileIcon(file?.file_key || ""),
    },
  ];
  const { manufacturer_displayname } = manufacturer;
  const folder = useMemo(() => {
    const match = folders.find((f) => f.folder_id === parseInt(folder_id, 10));
    return (
      match || {
        files: [],
        name: "",
      }
    );
  }, [folders, folder_id]);
  const crumbs = [
    {
      text: `${manufacturer_displayname} files`,
      link: "marketingFiles",
    },
  ];
  if (folder_id)
    crumbs.push({ text: folder.name, link: "folder", folder_id: folder_id });
  crumbs.push({ text: file?.file_name || "", link: "file" });

  const handleCard = (item) => {
    if (item?.isBack) {
      console.log(location);
      if (location.pathname.includes("/root/")) {
        navigate(-2);
      } else {
        navigate(-1);
      }
    }
  };
  const handleBreadCrums = (c) => {
    switch (c.link) {
      case "marketingFiles":
        navigate(`/app/media/`);
        break;
      case "folder":
        navigate(`/app/media/folder/${c.folder_id}`);
        break;
      default:
        break;
    }
  };

  return !file ? (
    <></>
  ) : (
    <>
      <Box mb={3}>
        <Breadcrumbs separator={<ChevronRight />}>
          {crumbs.map((c, index) => {
            return (
              <Link onClick={() => handleBreadCrums(c)} key={index}>
                {c.text}
              </Link>
            );
          })}
        </Breadcrumbs>
      </Box>
      <Grid2 container spacing={3}>
        {infoCards.map((item, index) => (
          <Grid2 key={index} item size={4}>
            <InfoCard handleCard={handleCard} item={item} />
          </Grid2>
        ))}
      </Grid2>
      <Box mt={3}>
        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <FileDetailView file={file} folders={folders} />
          </Grid2>
        </Grid2>
      </Box>
    </>
  );
};
export default MediaFile;
