import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router";
import { Container, Grid2 } from "@mui/material";
import formsAPI from "../../../api/forms";
import Page from "../../../components/Page";
import Respond from "../../../components/forms/v3/form/Respond";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const FormRespond = () => {
  const [form, setForm] = useState(null);
  const [answers, setAnswers] = useState([]);
  const { uuid, response_id } = useParams();
  const classes = useStyles();

  const getForm = useCallback(() => {
    formsAPI
      .getForm(uuid)
      .then(({ results }) => {
        setForm(results);
      })
      .catch((error) => {
        console.error("Error fetching form:", error);
      });
  }, [uuid]);

  const loadResponse = useCallback(({ form_id, response_id }) => {
    formsAPI
      .getFormResponse({
        form_id,
        response_id,
      })
      .then(({ results }) => {
        const rAnswers = JSON.parse(results.response_body);
        if (rAnswers.values.length > 0) {
          setAnswers(rAnswers.values);
        }
        formsAPI
          .getFormVersionById({
            form_uuid: uuid,
            form_id: results.form_id,
            include_archived: 1,
          })
          .then(({ result }) => setForm(result));
      })
      .catch((error) => {
        console.error("Error loading response:", error);
      });
  }, []);

  useEffect(() => {
    if (uuid && response_id) {
      loadResponse({ form_id: uuid, response_id: response_id });
    } else if (uuid) {
      getForm();
    }
  }, [uuid, response_id, loadResponse]);

  return (
    <Page
      title={`${form ? form.form_name : ""} Form`}
      className={classes.root}
      style={{ backgroundColor: "#EEEEEE" }}
    >
      <Container maxWidth="xl">
        <Grid2 container justifyContent="center">
          <Grid2 size={12} sm={8} md={6}>
            <div>
              <Respond
                uuid={uuid}
                response_id={response_id}
                form={form}
                answers={answers}
                setAnswers={setAnswers}
              />
            </div>
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  );
};

export default FormRespond;
