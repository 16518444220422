import React, { useEffect, useState } from "react";
import { DatePicker, TimePicker, DateTimePicker } from "@mui/x-date-pickers";
import { FormControl } from "@mui/material";
import StLabel from "./StLabel";
import { DateTime } from "luxon";

const standardiseDate = (date) => {
  if (!date) return null;

  // Handle strings
  if (typeof date === "string") {
    if (date.length < 11 && date.match(/\/|-/)) {
      let dateObject = {};
      if (date.includes("/")) {
        const [month, day, year] = date.split("/");
        dateObject = {
          year: parseInt(year),
          month: parseInt(month),
          day: parseInt(day),
        };
      } else if (date.includes("-")) {
        const [year, month, day] = date.split("-");
        dateObject = {
          year: parseInt(year),
          month: parseInt(month),
          day: parseInt(day),
        };
      }
      return DateTime.fromObject(dateObject);
    }
    return DateTime.fromISO(date);
  }

  // Handle Luxon DateTime or native Date objects
  if (date instanceof Date) {
    return DateTime.fromJSDate(date);
  }

  if (DateTime.isDateTime(date)) {
    return date;
  }

  return null; // Fallback for invalid input
};

const StDate = ({
  required,
  label,
  answer,
  changeAnswer = () => {},
  error,
  helperText,
  variant = "date+time",
}) => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [active, setActive] = useState(false);

  useEffect(() => {
    if (!answer?.value) return;
    const sd = standardiseDate(answer.value);
    // if DateTime timestamps don't match
    if (!selectedDate || (sd && sd.toMillis() !== selectedDate.toMillis())) {
      setSelectedDate(sd);
    }
  }, [answer, selectedDate]);

  const dateChange = (value) => {
    const standardizedValue = value
      ? DateTime.fromJSDate(value.toJSDate())
      : null;
    setSelectedDate(standardizedValue);
    changeAnswer(standardizedValue ? standardizedValue.toISO() : null); // Send ISO string as the answer like it was before
  };

  return (
    <FormControl fullWidth error={!!error}>
      <StLabel required={required} text={label} active={active} />
      {variant === "date+time" && (
        <div>
          <DateTimePicker
            label="Date and Time"
            value={selectedDate}
            onChange={(newVal) => dateChange(newVal)}
            sx={{
              marginTop: "20px",
              width: "100%",
            }}
          />
        </div>
      )}
      {variant === "date" && (
        <DatePicker
          label="Date"
          value={selectedDate}
          onChange={(newVal) => dateChange(newVal)}
        />
      )}
      {variant === "time" && (
        <TimePicker
          label="Time"
          value={selectedDate}
          onChange={(newVal) => dateChange(newVal)}
        />
      )}
    </FormControl>
  );
};

export default StDate;
