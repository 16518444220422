import client from "./client";
import APICallWrapper from "./APICallWrappers";

const getLists = async ({ mid, uid, limit }) => {
  const key = `getLists-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading lists...",
    "Lists loaded successfully.",
    "Failed to load lists.",
    () => client.get("/accounts/lists", { mid: mid, uid: uid, limit: limit })
  );
};

const putLists = async (list) => {
  const key = `putLists-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating list...",
    "List updated successfully.",
    "Failed to update list.",
    () => client.put("/accounts/lists", list)
  );
};

const postLists = async (list) => {
  const key = `postLists-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Creating list...",
    "List created successfully.",
    "Failed to create list.",
    () => client.post("/accounts/lists", list)
  );
};

const getListsLID = ({ lid, limit }) => {
  const endpoint = "/accounts/listdetailsnew/" + lid;
  return client.get(endpoint, { limit: limit });
};

const getListsBottles = ({ mid, limit }) => {
  const endpoint = "/accounts/list/bottles";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const putListsItems = async (listItem) => {
  const key = `putListsItems-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating list item...",
    "List item updated successfully.",
    "Failed to update list item.",
    () => client.put("/accounts/listitems", listItem)
  );
};

const postListsItems = async (listItem) => {
  const key = `postListsItems-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Adding list item...",
    "List item added successfully.",
    "Failed to add list item.",
    () => client.post("/accounts/listitems", listItem)
  );
};

export default {
  getLists,
  putLists,
  postLists,
  getListsLID,
  getListsBottles,
  putListsItems,
  postListsItems,
};
