import React, { useRef, useEffect } from "react";
import Chart from "chart.js/auto";

// Define the custom shadow plugin
const addShadowPlugin = {
  id: "addShadow",
  beforeRender: (chart) => {
    const { ctx } = chart;
    ctx.shadowColor = "rgba(0, 0, 0, 0.25)"; // Shadow color
    ctx.shadowBlur = 4; // Shadow blur radius
    ctx.shadowOffsetX = 2; // Shadow offset in X direction
    ctx.shadowOffsetY = 2; // Shadow offset in Y direction
  },
};

const RadialBarChart = ({ counts }) => {
  const chartRef = useRef(null);

  const colors = ["#75B96D", "#49D1DD", "#8676FF", "#FF708B"];
  const gap = {
    data: [0],
    backgroundColor: ["rgba(255, 255, 255, 0.0)"],
  };
  const data = {
    labels: ["Completed", "Remaining"],
    datasets: [
      {
        data: counts[0],
        backgroundColor: [colors[0], "rgba(255, 255, 255, 0)"],
        borderWidth: 0,
        borderRadius: counts[0][1] === 0 ? 0 : 10,
        weight: 3,
      },
      gap,
      {
        data: counts[1],
        backgroundColor: [colors[1], "rgba(255, 255, 255, 0)"],
        borderWidth: 0,
        borderRadius: counts[1][1] === 0 ? 0 : 10,
        weight: 3,
      },
      gap,
      {
        data: counts[2],
        backgroundColor: [colors[2], "rgba(255, 255, 255, 0)"],
        borderWidth: 0,
        borderRadius: counts[2][1] === 0 ? 0 : 10,
        weight: 3,
      },
      gap,
      {
        data: counts[3],
        backgroundColor: [colors[3], "rgba(255, 255, 255, 0)"],
        borderWidth: 0,
        borderRadius: counts[3][1] === 0 ? 0 : 10,
        weight: 3,
      },
    ],
  };

  const config = {
    type: "doughnut",
    data: data,
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: false,
        title: false,
        addShadow: true, // Enable the custom shadow plugin
      },
      elements: {
        arc: {
          borderWidth: 0,
        },
      },
      rotation: 180,
    },
    plugins: [addShadowPlugin], // Register the custom shadow plugin
  };

  useEffect(() => {
    const chartInstance = new Chart(chartRef.current, config);

    return () => {
      chartInstance.destroy();
    };
  }, []);

  return (
    <>
      {counts[0][0] === 0 &&
      counts[1][0] === 0 &&
      counts[2][0] === 0 &&
      counts[3][0] === 0 ? (
        "No data to show, fill forms, add media, add content or check-in to accounts to see progress"
      ) : (
        <canvas
          ref={chartRef}
          style={{
            width: "100%",
            height: "100%",
          }}
        />
      )}
    </>
  );
};

export default RadialBarChart;
