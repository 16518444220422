import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import {
  Dialog,
  DialogTitle,
  Grid2,
  MenuItem,
  Select,
  InputLabel,
  TextField,
  ListItemText,
  ListItemIcon,
  Button,
} from "@mui/material";
import { useForm } from "react-hook-form";
import useApi from "../../../hooks/useApi";
import teamsApi from "../../../api/team";
import { LocalBar, LocationOn, PermMedia, Receipt } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    width: "100%",
  },
  formControl: {
    padding: theme.spacing(2),
    overflow: "hidden",
  },
}));
const icons = {
  visits: LocationOn,
  cases: LocalBar,
  media: PermMedia,
  accounts: Receipt,
};
const labels = {
  visits: "Account Visits",
  cases: "Cases Sold",
  media: "Media Uploads",
  accounts: "New Accounts",
};
function createIcon(iconName) {
  const Icon = icons[iconName];
  const Label = labels[iconName];

  return (
    <MenuItem>
      <ListItemIcon>
        <Icon />
      </ListItemIcon>
      <ListItemText primary={Label} />
    </MenuItem>
  );
}

const TeamTargetModal = ({ onClose, open, userInfo, ...rest }) => {
  const classes = useStyles();
  const postTeamsApi = useApi(teamsApi.postTeamTarget);
  const [submitInProgress, setSubmitButton] = useState(false);
  const { handleSubmit } = useForm();

  const handleClose = () => {
    setValues({
      user_id: userInfo.uid,
      manufacturer_id: userInfo.mid,
      target_type: "",
      cadence: "",
      target: "1",
      is_active: 1,
    });
    onClose();
  };

  const handleChange = (event, value) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (data) => {
    setSubmitButton(postTeamsApi.loading);
    postTeamsApi.request(values);
    setSubmitButton(postTeamsApi.loading);
    handleClose();
  };
  const [values, setValues] = useState({
    user_id: userInfo.uid,
    manufacturer_id: userInfo.mid,
    target_type: "",
    cadence: "",
    target: "1",
    is_active: 1,
  });
  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <DialogTitle id="simple-dialog-title">Create A Goal</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.formControl}>
        <Grid2 container spacing={5}>
          <Grid2 size={12}>
            <InputLabel id="demo-simple-select-outlined-label">Type</InputLabel>
            <Select
              fullWidth
              required
              id="demo-simple-select-outlined"
              name="target_type"
              onChange={handleChange}
              value={values.target_type}
              renderValue={(value) => createIcon(value)}
            >
              <MenuItem value={"cases"} selected>
                <ListItemIcon>
                  <LocalBar />
                </ListItemIcon>
                <ListItemText primary="Cases Sold" />
              </MenuItem>
              <MenuItem value={"visits"}>
                <ListItemIcon>
                  <LocationOn />
                </ListItemIcon>
                <ListItemText primary="Account Visits" />
              </MenuItem>
              <MenuItem value={"media"}>
                <ListItemIcon>
                  <PermMedia />
                </ListItemIcon>
                <ListItemText primary="Media Uploads" />
              </MenuItem>
              <MenuItem value={"accounts"}>
                <ListItemIcon>
                  <Receipt />
                </ListItemIcon>
                <ListItemText primary="New Accounts" />
              </MenuItem>
            </Select>
          </Grid2>
          <Grid2 size={12}>
            <InputLabel id="demo-simple-select-outlined-label">
              Candence
            </InputLabel>
            <Select
              fullWidth
              required
              id="demo-simple-select-outlined"
              name="cadence"
              onChange={handleChange}
              value={values.cadence}
            >
              <MenuItem value={"week"}>Weekly</MenuItem>
              <MenuItem value={"month"}>Monthly</MenuItem>
              <MenuItem value={"quarter"}>Quarterly</MenuItem>
            </Select>
          </Grid2>

          <Grid2 size={12}>
            <TextField
              label="Goal"
              required
              type="number"
              variant="outlined"
              name="target"
              fullWidth
              onChange={handleChange}
              value={values.target}
              InputProps={{
                inputProps: {
                  min: "1",
                },
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Button
              disabled={submitInProgress}
              variant="contained"
              color="primary"
              fullWidth
              type="submit"
            >
              Create
            </Button>
          </Grid2>
        </Grid2>
      </form>
    </Dialog>
  );
};

TeamTargetModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default TeamTargetModal;
