import { colors, createTheme } from "@mui/material";
import shadows from "./shadows";

const theme = createTheme({
  palette: {
    background: {
      default: colors.common.white,
      paper: colors.common.white,
      success: "rgb(22, 220, 80)",
    },
    primary: {
      main: "rgb(12, 69, 165)",
    },
    secondary: {
      main: "rgb(87, 235, 224)",
    },
    success: {
      main: "rgb(22, 220, 80)",
    },
    text: {
      primary: colors.blueGrey[900],
      secondary: colors.blueGrey[600],
    },
    action: {},
  },
  overrides: {
    MuiAppBar: {
      colorPrimary: {
        backgroundImage: "linear-gradient(to right, white, #2D58F6)",
      },
    },
  },
  shadows,
  typography: {
    fontFamily: ["Poppins", "sans-serif"],
  },
});

export default theme;
