import {
  Box,
  Collapse,
  Checkbox,
  IconButton,
  TextField,
  Button,
  Typography,
  Stack,
  CircularProgress,
} from "@mui/material";
import { ExpandMore, ExpandLess } from "@mui/icons-material";
import products from "../../../../api/products";
import { useEffect, useState, useRef } from "react";
import { useStore } from "../../../../stores/StoreContext";
import { observer } from "mobx-react-lite";

export const ProductsFilter = observer(
  ({ requestBody, setRequestBody, setFilteringArrays, filteringArrays }) => {
    const [productBrandsData, setProductBrandsData] = useState(null);
    const [expandedBrands, setExpandedBrands] = useState({});
    const [isProductsExpanded, setIsProductsExpanded] = useState(false);
    const [searchQuery, setSearchQuery] = useState("");
    const [loading, setLoading] = useState(true);

    // New state for lazy loading the brands
    const [visibleBrandsCount, setVisibleBrandsCount] = useState(20);
    const containerRef = useRef(null);

    const { reportBuilderBrands, setReportBuilderBrands } = useStore();

    useEffect(() => {
      if (Object.keys(reportBuilderBrands).length > 0) {
        setProductBrandsData(reportBuilderBrands);
        setFilteringArrays((prev) => ({
          ...prev,
          prods: reportBuilderBrands,
        }));
        setLoading(false);
      } else {
        const fetchBrands = async () => {
          setLoading(true);
          const data = await products.reportBuilderBrandsAndProducts();
          setProductBrandsData(data.results);
          setFilteringArrays((prev) => ({
            ...prev,
            prods: data.results,
          }));
          setReportBuilderBrands(data.results);
          setLoading(false);
        };
        fetchBrands();
      }
    }, [reportBuilderBrands, setFilteringArrays, setReportBuilderBrands]);

    const handleBrandToggle = (brand) => {
      setExpandedBrands((prev) => ({
        ...prev,
        [brand]: !prev[brand],
      }));
    };

    // Get selected products from requestBody
    const selectedProducts = requestBody.filters?.dimensions?.prod_id || [];

    const handleProductCheckboxChange = (productId) => {
      const newSelectedProducts = selectedProducts.includes(productId)
        ? selectedProducts.filter((id) => id !== productId)
        : [...selectedProducts, productId];

      setRequestBody((prev) => {
        if (newSelectedProducts.length === 0) {
          const { prod_id, ...otherDimensions } =
            prev.filters?.dimensions || {};
          return {
            ...prev,
            filters: {
              ...prev.filters,
              dimensions: otherDimensions,
            },
          };
        }
        return {
          ...prev,
          filters: {
            ...prev.filters,
            dimensions: {
              ...prev.filters?.dimensions,
              prod_id: newSelectedProducts,
            },
          },
        };
      });
    };

    const handleBrandCheckboxChange = (brand, products) => {
      const productIds = products.map((product) => product.id);
      const someSelected = productIds.some((id) =>
        selectedProducts.includes(id)
      );

      // If any products are selected, deselect all. Otherwise, select all.
      const newSelectedProducts = someSelected
        ? selectedProducts.filter((id) => !productIds.includes(id))
        : [...new Set([...selectedProducts, ...productIds])];

      setRequestBody((prev) => {
        if (newSelectedProducts.length === 0) {
          const { prod_id, ...otherDimensions } =
            prev.filters?.dimensions || {};
          return {
            ...prev,
            filters: {
              ...prev.filters,
              dimensions: otherDimensions,
            },
          };
        }
        return {
          ...prev,
          filters: {
            ...prev.filters,
            dimensions: {
              ...prev.filters?.dimensions,
              prod_id: newSelectedProducts,
            },
          },
        };
      });
    };

    // Update the isBrandSelected function to handle partial selections
    const isBrandSelected = (brand, products) => {
      const selectedCount = products.filter((product) =>
        selectedProducts.includes(product.id)
      ).length;
      return selectedCount > 0 && selectedCount === products.length;
    };

    const isBrandIndeterminate = (brand, products) => {
      const selectedCount = products.filter((product) =>
        selectedProducts.includes(product.id)
      ).length;
      return selectedCount > 0 && selectedCount < products.length;
    };

    const handleSearchChange = (event) => {
      setSearchQuery(event.target.value.toLowerCase());
      // Reset the lazy load count when a new search is initiated.
      setVisibleBrandsCount(20);
    };

    const handleDeselectAll = () => {
      setRequestBody((prev) => {
        const { prod_id, ...otherDimensions } = prev.filters?.dimensions || {};
        return {
          ...prev,
          filters: {
            ...prev.filters,
            dimensions: otherDimensions,
          },
        };
      });
    };

    const filteredBrands = productBrandsData
      ? Object.entries(productBrandsData).filter(
          ([brand, products]) =>
            brand.toLowerCase().includes(searchQuery) ||
            products.some((product) =>
              product.name.toLowerCase().includes(searchQuery)
            )
        )
      : [];

    // This function is called on scroll. When the user nears the bottom, we load more brands.
    const handleScroll = (event) => {
      const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;
      if (scrollHeight - scrollTop - clientHeight < 100) {
        if (visibleBrandsCount < filteredBrands.length) {
          setVisibleBrandsCount((prev) =>
            Math.min(prev + 20, filteredBrands.length)
          );
        }
      }
    };

    return (
      <Box sx={{ mt: 2 }}>
        {loading ? (
          <CircularProgress />
        ) : (
          <>
            <Button
              fullWidth
              onClick={() => setIsProductsExpanded(!isProductsExpanded)}
              sx={{
                bgcolor: "#1F449F",
                color: "white",
                justifyContent: "space-around",
                p: 1,
                "&:hover": {
                  bgcolor: "#1a3b8a",
                },
                borderRadius: 2,
                width: "75%",
                margin: "0 auto",
                display: "flex",
              }}
              endIcon={
                <ExpandMore
                  sx={{
                    transform: isProductsExpanded ? "rotate(180deg)" : "none",
                    transition: "transform 0.2s",
                  }}
                />
              }
            >
              <div />
              <Typography
                fontSize={16}
                fontWeight={"semibold"}
                sx={{ textAlign: "center" }}
              >
                Products
                <br />(
                {selectedProducts.length === 0
                  ? "Show All"
                  : `Showing ${selectedProducts.length}`}
                )
              </Typography>
            </Button>

            {Object.values(selectedProducts).filter(Boolean).length === 0 ? (
              <Typography fontSize={12} color="#999999" fontWeight={"semibold"}>
                Selecting 0 products will apply no product filters to your
                report.
              </Typography>
            ) : (
              <div />
            )}

            <Collapse in={isProductsExpanded} timeout="auto" unmountOnExit>
              <div
                ref={containerRef}
                onScroll={handleScroll}
                style={{
                  marginTop: 10,
                  padding: 20,
                  maxHeight: "500px",
                  overflowY: "auto",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    mb: 2,
                    gap: 1,
                  }}
                >
                  <TextField
                    size="small"
                    placeholder="Search brands..."
                    sx={{ flex: 1 }}
                    value={searchQuery}
                    onChange={handleSearchChange}
                  />
                  <Button
                    size="small"
                    onClick={handleDeselectAll}
                    sx={{ color: "#1F449F", whiteSpace: "nowrap" }}
                  >
                    Deselect All
                  </Button>
                </Box>
                <Stack spacing={1}>
                  {filteredBrands
                    .slice(0, visibleBrandsCount)
                    .map(([brand, products]) => (
                      <div key={brand}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: 1,
                            mb: 1,
                          }}
                        >
                          <Checkbox
                            checked={isBrandSelected(brand, products)}
                            indeterminate={isBrandIndeterminate(
                              brand,
                              products
                            )}
                            onChange={() =>
                              handleBrandCheckboxChange(brand, products)
                            }
                            sx={{
                              "&.Mui-checked": {
                                color: "#1F44A0",
                              },
                              "&.MuiCheckbox-indeterminate": {
                                color: "#8BA3E1",
                              },
                            }}
                          />
                          <Typography component="span" color="text.secondary">
                            (
                            {
                              products.filter((p) =>
                                selectedProducts.includes(p.id)
                              ).length
                            }
                            /{products.length})
                          </Typography>
                          <Typography>{brand}</Typography>
                          <IconButton
                            size="small"
                            onClick={() => handleBrandToggle(brand)}
                            sx={{ ml: "auto" }}
                          >
                            {expandedBrands[brand] ? (
                              <ExpandLess />
                            ) : (
                              <ExpandMore />
                            )}
                          </IconButton>
                        </Box>
                        <Collapse in={expandedBrands[brand]} timeout="auto">
                          <Box sx={{ pl: 4 }}>
                            {products.map((product) => (
                              <Box
                                key={product.id}
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: 1,
                                }}
                              >
                                <Checkbox
                                  checked={selectedProducts.includes(
                                    product.id
                                  )}
                                  onChange={() =>
                                    handleProductCheckboxChange(product.id)
                                  }
                                />
                                <Typography>{product.name}</Typography>
                              </Box>
                            ))}
                          </Box>
                        </Collapse>
                      </div>
                    ))}
                </Stack>
              </div>
            </Collapse>
          </>
        )}
      </Box>
    );
  }
);
