export const PresetOptions = [
  {
    name: "Account > Product",
    associations: ["account", "product"],
    urlString: "account_product",
    sortModel: [
      {
        field: "STORENUMBER",
        sort: "asc",
      },
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Account > Brand",
    associations: ["account", "brand"],
    urlString: "account_brand",
    sortModel: [
      {
        field: "STORENUMBER",
        sort: "asc",
      },
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "State > Product",
    associations: ["region", "product"],
    urlString: "region_product",
    sortModel: [
      {
        field: "STATE",
        sort: "asc",
      },
      // {
      //   field: "CITY",
      //   sort: "asc",
      // },
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "State > Brand",
    associations: ["region", "brand"],
    urlString: "region_brand",
    sortModel: [
      {
        field: "STATE",
        sort: "asc",
      },
      // {
      //   field: "CITY",
      //   sort: "asc",
      // },
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Product Totals",
    associations: ["product"],
    urlString: "product_totals",
    sortModel: [
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Brand Totals",
    associations: ["brand"],
    urlString: "brand_totals",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Brand Accounts Sold",
    associations: ["brand"],
    urlString: "brand_accounts_sold",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Product Accounts Sold",
    associations: ["product"],
    urlString: "product_accounts_sold",
    sortModel: [
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Distributor Comp.",
    associations: ["product"],
    urlString: "distributor_totals",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Distributor Comp. - Brand",
    associations: ["product"],
    urlString: "distributor_brand_totals",
    sortModel: [
      {
        field: "BRAND",
        sort: "asc",
      },
    ],
  },
  {
    name: "Distributor Comp. - Product",
    associations: ["product"],
    urlString: "distributor_product_totals",
    sortModel: [
      {
        field: "PRODUCTNAME",
        sort: "asc",
      },
    ],
  },
  {
    name: "Account > Reorders",
    associations: ["account", "product"],
    urlString: "account_reorders",
    sortModel: [
      {
        field: "orders",
        sort: "desc",
      },
    ],
  },
];

export const queryBuilder = (preset) => {
  const query = encodeURI(`?` + [`preset=${preset.urlString}`].join("&"));
  return query;
};

export const dataFilter = ({
  globallyFiltered,
  quantity,
  products,
  productPositive,
  filterProductStage,
  showComparison,
}) => {
  const productBeforePass = (item) => {
    const itemHasProduct = products.find((p) =>
      item.PRODUCTNAME.startsWith(p.product_displayname)
    );
    const found = typeof itemHasProduct === "object";
    return productPositive ? found : !found;
  };
  // if showing comparison, combine previous and current
  let toProcess = showComparison
    ? globallyFiltered.previous.concat(globallyFiltered.current)
    : // if not showing comparison, all you need is current
      globallyFiltered.current;

  // if some products are selected, you need to filter
  // else toProcess is ready to go
  if (products.length > 0) {
    let productAccounts = new Set();
    toProcess = toProcess
      .filter((item) => {
        if (filterProductStage === "before" && products.length) {
          if (!productBeforePass(item)) return false;
        }
        return true;
      })
      .map((f) => {
        if (products.length && filterProductStage === "after") {
          if (
            products.find((p) =>
              f.PRODUCTNAME.startsWith(p.product_displayname)
            )
          ) {
            productAccounts.add(f.STORENUMBER);
          }
        }
        return {
          ...f,
          QTYSOLD: f[quantity],
        };
      });
    if (filterProductStage === "after") {
      toProcess = toProcess.filter((sale) => {
        if (productPositive) {
          return productAccounts.has(sale.STORENUMBER);
        }
        if (!productPositive) {
          return !productAccounts.has(sale.STORENUMBER);
        }
      });
    }
  }

  return toProcess;
};

export default {
  PresetOptions,
};
