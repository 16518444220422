import React, { useEffect, useState } from "react";
import dayjs from "dayjs";
import { utils as XLSXUtils, writeFile as XLSXWriteFile } from "xlsx";
import formsAPI from "../../../api/forms";
import {
  Box,
  Typography,
  Divider,
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  FormControlLabel,
  Checkbox,
  Button,
  FormGroup,
  TextField,
  Grid2,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";
import {
  Header,
  Document,
  Packer,
  Paragraph,
  TextRun,
  ImageRun,
  HeadingLevel,
  AlignmentType,
} from "docx";
import { useStore } from "../../../stores/StoreContext";

const FormRecap = ({ responses, onClose }) => {
  const [parsedResponses, setParsedResponses] = useState([]);
  const [formData, setFormData] = useState(null);
  const { manufacturer } = useStore();
  const [allFields, setAllFields] = useState([]);

  const fieldsMode = "Selected";
  const [selectedFields, setSelectedFields] = useState([]);

  // Date Presets
  const [timeRange, setTimeRange] = useState("today");
  const [customStart, setCustomStart] = useState("");
  const [customEnd, setCustomEnd] = useState("");

  // Sorting
  const [sortColumn, setSortColumn] = useState("created");
  const [sortDirection, setSortDirection] = useState("desc"); // "asc" or "desc"

  // Export
  const [exportFormat, setExportFormat] = useState("csv");
  const [isExporting, setIsExporting] = useState(false);

  const [selectedUser, setSelectedUser] = useState("all");
  const [uniqueUsers, setUniqueUsers] = useState([]);

  useEffect(() => {
    if (!responses || !responses.length) return;

    // 1) Parse each response_body if needed
    const newParsed = responses.map((res) => {
      let parsedBody = res.response_body;
      if (typeof parsedBody === "string") {
        try {
          parsedBody = JSON.parse(parsedBody);
        } catch (err) {
          console.error("Could not parse response_body JSON:", err);
          parsedBody = {};
        }
      }
      return { ...res, response_body: parsedBody };
    });
    setParsedResponses(newParsed);

    // 2) Load the form definition
    formsAPI
      .getFormVersionById({
        form_uuid: responses[0].form_uuid,
        form_id: responses[0].form_id,
        include_archived: 1,
      })
      .then(({ result }) => {
        const formInfo = Array.isArray(result) ? result[0] : result;
        if (!formInfo) return;

        // parse the top-level form_body if string
        let formBody = formInfo.form_body;
        if (typeof formBody === "string") {
          try {
            formBody = JSON.parse(formBody);
          } catch (err) {
            console.error("Could not parse form_body JSON:", err);
            formBody = [];
          }
        }

        // Flatten with correct multi-level nesting
        const flattened = buildAndFlattenFieldTree(formBody);
        setFormData(formInfo);
        setAllFields(flattened);
      })
      .catch((err) => {
        console.error("Error loading form:", err);
      });
    const usersSet = new Set();
    newParsed.forEach((r) => {
      if (r.user_displayname) {
        usersSet.add(r.user_displayname);
      }
    });
    setUniqueUsers(Array.from(usersSet));
  }, [responses]);

  // ----------------------------------------------------------------------------
  // 1) Build a hierarchical "field tree" that respects multi-level parent IDs,
  //    then flatten that tree with the "1, 1a, 1aa" numbering.
  // ----------------------------------------------------------------------------

  function buildAndFlattenFieldTree(topLevelFields) {
    const fieldMap = new Map(); // fieldId -> { ...fieldData, children: [] }
    const allGroups = []; // each item: { parentFieldId, fields: [...] }

    function gatherFields(fields) {
      if (!fields || !fields.length) return;
      fields.forEach((fld) => {
        if (!fieldMap.has(fld.id)) {
          fieldMap.set(fld.id, {
            ...fld,
            children: [],
          });
        }

        if (Array.isArray(fld.conditionalGroups)) {
          fld.conditionalGroups.forEach((grp) => {
            allGroups.push({
              parentFieldId: grp.parentFieldId,
              fields: grp.fields,
            });
            gatherFields(grp.fields);
          });
        }
      });
    }

    gatherFields(topLevelFields);

    // Link children to parents
    allGroups.forEach((grp) => {
      const parentId = grp.parentFieldId;
      if (fieldMap.has(parentId)) {
        const parentNode = fieldMap.get(parentId);
        grp.fields.forEach((childField) => {
          const childNode = fieldMap.get(childField.id);
          if (childNode && !parentNode.children.includes(childNode)) {
            parentNode.children.push(childNode);
          }
        });
      }
    });

    // Identify root nodes (topLevel fields not in childIds)
    const childIds = new Set();
    fieldMap.forEach((node) => {
      node.children.forEach((c) => childIds.add(c.id));
    });

    const rootNodes = topLevelFields
      .map((fld) => fieldMap.get(fld.id))
      .filter((node) => node && !childIds.has(node.id));

    // Flatten
    const flatList = [];
    function dfs(node, path) {
      const exportNumber = pathToNumbering(path);
      const exportLabel = exportNumber
        ? `${exportNumber}) ${node.label}`
        : node.label;
      const level = path.length - 1 >= 0 ? path.length - 1 : 0;

      flatList.push({
        id: node.id,
        label: node.label,
        field_name: node.field_name,
        level,
        exportLabel,
      });

      node.children.forEach((child, idx) => {
        dfs(child, [...path, idx]);
      });
    }

    rootNodes.forEach((root, idx) => {
      dfs(root, [idx]);
    });
    return flatList;
  }

  function indexToLetter(i) {
    return String.fromCharCode(97 + i); // 0->'a', 1->'b', etc.
  }
  function pathToNumbering(path) {
    if (!path.length) return "";
    const topNumber = path[0] + 1; // 0->1
    let letters = "";
    for (let i = 1; i < path.length; i++) {
      letters += indexToLetter(path[i]);
    }
    return topNumber + letters;
  }

  const handleFieldToggle = (fieldId) => {
    setSelectedFields((prev) =>
      prev.includes(fieldId)
        ? prev.filter((f) => f !== fieldId)
        : [...prev, fieldId]
    );
  };

  function getPresetDateRange(preset) {
    if (preset === "all") return [null, null];
    const now = dayjs();
    switch (preset) {
      case "today":
        return [now.startOf("day"), now.endOf("day")];
      case "thisWeek":
        return [now.startOf("week"), now.endOf("week")];
      case "thisMonth":
        return [now.startOf("month"), now.endOf("month")];
      case "thisYear":
        return [now.startOf("year"), now.endOf("year")];
      case "lastQuarter":
        return [now.subtract(3, "month"), now];
      case "last2Quarters":
        return [now.subtract(6, "month"), now];
      case "last3Quarters":
        return [now.subtract(9, "month"), now];
      default:
        return [null, null];
    }
  }

  function getProcessedResponses() {
    let start = null;
    let end = null;

    if (timeRange === "custom") {
      start = customStart ? dayjs(customStart).startOf("day") : null;
      end = customEnd ? dayjs(customEnd).endOf("day") : null;
    } else {
      const [pStart, pEnd] = getPresetDateRange(timeRange);
      start = pStart;
      end = pEnd;
    }

    // Filter by date
    let filtered = parsedResponses.filter((res) => {
      if (!start && !end) return true; // "all"
      const createdDate = dayjs(res.created);
      if (start && createdDate.isBefore(start)) return false;
      if (end && createdDate.isAfter(end)) return false;
      return true;
    });
    if (selectedUser !== "all") {
      filtered = filtered.filter(
        (res) => res.user_displayname === selectedUser
      );
    }
    // Sort
    filtered = [...filtered];
    filtered.sort((a, b) => {
      let aVal, bVal;
      switch (sortColumn) {
        case "created":
          aVal = dayjs(a.created).valueOf();
          bVal = dayjs(b.created).valueOf();
          break;
        case "user":
          aVal = (a.user_displayname || "").toLowerCase();
          bVal = (b.user_displayname || "").toLowerCase();
          break;
        case "account":
          aVal = (a.account_displayname || "").toLowerCase();
          bVal = (b.account_displayname || "").toLowerCase();
          break;
        case "distributor":
          aVal = (a.account_repname || "").toLowerCase();
          bVal = (b.account_repname || "").toLowerCase();
          break;
        default:
          aVal = 0;
          bVal = 0;
      }
      if (aVal < bVal) return sortDirection === "asc" ? -1 : 1;
      if (aVal > bVal) return sortDirection === "asc" ? 1 : -1;
      return 0;
    });

    return filtered;
  }

  function generateExportRows() {
    const data = getProcessedResponses();

    // Determine which fields to export
    let fieldsToExport = [];
    if (fieldsMode === "all") {
      fieldsToExport = allFields;
    } else {
      fieldsToExport = allFields.filter((f) => selectedFields.includes(f.id));
    }

    // Build header
    const header = [
      "Form ID",
      "User",
      "Account",
      "Address",
      "Distributor",
      "Rep Name",
      "Date",
      ...fieldsToExport.map((f) => f.exportLabel),
    ];
    const rows = [header];

    // Build rows
    data.forEach((res) => {
      const row = [];
      row.push(res.form_id);
      row.push(res.user_displayname);
      row.push(res.account_displayname);
      row.push(res.account_address);
      row.push(res.dist_displayname);
      row.push(res.account_repname);
      row.push(dayjs(res.created).format("YYYY-MM-DD HH:mm"));

      fieldsToExport.forEach((f) => {
        let answer = "";
        if (res.response_body && Array.isArray(res.response_body.values)) {
          // match by "startsWith" in case name has suffix
          const found = res.response_body.values.find((v) =>
            v.name.startsWith(f.field_name)
          );
          if (found) {
            answer = found.textValue || found.value || "";
          }
        }
        row.push(answer);
      });

      rows.push(row);
    });
    return rows;
  }

  function handleExport() {
    setIsExporting(true);
    const rows = generateExportRows();
    if (exportFormat === "csv") {
      exportToCSV(rows);
    } else if (exportFormat === "xlsx") {
      const rows = generateExportRows();
      exportToXLSX(rows);
    } else if (exportFormat === "docx") {
      exportToDocx();
    }
    setIsExporting(false);
  }

  function exportToCSV(rows) {
    const csvContent = rows
      .map((row) =>
        row
          .map((cell) => {
            const cellStr = (cell || "").toString().replace(/"/g, '""');
            return `"${cellStr}"`;
          })
          .join(",")
      )
      .join("\n");
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "form_recap.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  async function fetchImageData(url) {
    const response = await fetch(url);
    const buffer = await response.arrayBuffer();
    return new Uint8Array(buffer);
  }
  async function exportToDocx() {
    // 1) Get data
    const data = getProcessedResponses(); // your filtered/sorted array of responses

    // 2) Figure out which fields to export
    let fieldsToExport = [];
    if (fieldsMode === "all") {
      fieldsToExport = allFields;
    } else {
      fieldsToExport = allFields.filter((f) => selectedFields.includes(f.id));
    }
    const imageUrl =
      manufacturer.manufacturer_id === 614
        ? `/images/${manufacturer.manufacturer_id}.png`
        : `/images/default.png`; // Update with your image URL or path
    const imageData = await fetchImageData(imageUrl);
    const header = new Header({
      children: [
        new Paragraph({
          alignment: AlignmentType.CENTER,
          children: [
            new ImageRun({
              type: "png",
              data: imageData,
              transformation: {
                width: 180, // adjust width as needed
                height: 80, // adjust height as needed
              },
              altText: {
                title: "Company Logo",
                description: "Company Logo",
                name: "Company Logo",
              },
            }),
          ],
        }),
      ],
    });
    // We'll accumulate *all* paragraphs in one array
    const allParagraphs = [];

    data.forEach((res, index) => {
      const account = res.account_displayname || "";
      const user = res.user_displayname || "";
      const address = res.account_address || "";
      const distributor = res.dist_displayname || "";
      const repname = res.account_repname || "";
      const created = dayjs(res.created).format("MM-DD-YYYY hh:mm A");

      // --- Add paragraphs for this response ---
      const answers = fieldsToExport.map((field) => {
        // default empty
        let answer = "";

        if (res.response_body && Array.isArray(res.response_body.values)) {
          const found = res.response_body.values.find((v) =>
            v.name.startsWith(field.field_name)
          );
          if (found) {
            answer = found.textValue || found.value || "";
          }
        }

        // Ensure answer is a string before trimming
        return typeof answer === "string" ? answer.trim() : "";
      });

      // Check if *every* answer is empty:
      const allAnswersEmpty = answers.every((ans) => ans === "");

      // If all answers are empty, skip building paragraphs for this entry
      if (allAnswersEmpty) {
        return; // <--- Skip this entire response
      }

      // 1) Response header
      allParagraphs.push(
        new Paragraph({
          text: created,
          heading: HeadingLevel.HEADING_2,
          alignment: AlignmentType.LEFT,
          spacing: { after: 200 },
        })
      );

      // 2) Right-aligned info
      allParagraphs.push(
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [new TextRun({ text: account, bold: true })],
        })
      );
      allParagraphs.push(
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [new TextRun({ text: address })],
        })
      );
      allParagraphs.push(
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [new TextRun({ text: distributor })],
        })
      );
      allParagraphs.push(
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [new TextRun({ text: user })],
        })
      );
      allParagraphs.push(
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [new TextRun({ text: "FAO: " + repname, bold: true })],
        })
      );
      allParagraphs.push(
        new Paragraph({
          alignment: AlignmentType.RIGHT,
          children: [new TextRun({ text: created })],
        })
      );

      // Add some spacing
      allParagraphs.push(new Paragraph({ text: "", spacing: { after: 200 } }));

      // 3) Q/A pairs
      fieldsToExport.forEach((field) => {
        let answer = "";
        if (res.response_body && Array.isArray(res.response_body.values)) {
          const found = res.response_body.values.find((v) =>
            v.name.startsWith(field.field_name)
          );
          if (found) {
            answer = found.textValue || found.value || "";
          }
        }
        if (answer) {
          // Paragraph for Question
          allParagraphs.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: `Question: ${field.label}`,
                  bold: true,
                }),
              ],
            })
          );

          // Paragraph for Answer
          allParagraphs.push(
            new Paragraph({
              text: `Answer: ${answer}`,
              spacing: { after: 100 },
            })
          );
        }
      });

      // --- Insert a horizontal line after each response ---
      // This will simply add a rule to visually separate responses
      allParagraphs.push(
        new Paragraph({
          thematicBreak: true, // This creates the horizontal rule/line
        })
      );
    });

    // Build the document with a single section
    const doc = new Document({
      creator: "SalesTier",
      title: "Form Recap",
      description: "Form recap DOCX export",
      sections: [
        {
          headers: { default: header },
          children: allParagraphs,
        },
      ],
    });

    // 6) Generate the .docx blob, trigger file download
    const blob = await Packer.toBlob(doc);
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.download = "form_recap.docx";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
  function exportToXLSX(rows) {
    const wb = XLSXUtils.book_new();
    const ws = XLSXUtils.aoa_to_sheet(rows);
    XLSXUtils.book_append_sheet(wb, ws, "Form Recap");
    XLSXWriteFile(wb, "form_recap.xlsx");
  }

  if (!parsedResponses.length || !formData || !allFields.length) {
    return (
      <Box sx={{ p: 2 }}>
        <Typography variant="body1">Loading Form Recap...</Typography>
      </Box>
    );
  }

  // A simple function to build a small "preview" array of data for the table
  // (e.g., the first 5 rows).
  const previewData = getProcessedResponses().slice(0, 5);

  let fieldsToPreview = [];
  if (fieldsMode === "all") {
    fieldsToPreview = allFields;
  } else {
    fieldsToPreview = allFields.filter((f) => selectedFields.includes(f.id));
  }

  return (
    <Box sx={{ p: 2, backgroundColor: "#fff" }}>
      <Typography variant="h6" gutterBottom>
        Create Form Recap
      </Typography>
      <Divider sx={{ mb: 2 }} />

      {/* Wrap everything in a Grid2 container to create two columns */}
      <Grid2 container flexDirection={"row"}>
        <Grid2 size={4}>
          <Box sx={{ mb: 2 }}>
            <FormControl size="small" sx={{ mr: 2, minWidth: 120 }}>
              <InputLabel id="time-range-label">Time Range</InputLabel>
              <Select
                labelId="time-range-label"
                id="time-range-select"
                label="Time Range"
                value={timeRange}
                onChange={(e) => setTimeRange(e.target.value)}
              >
                <MenuItem value="all">All</MenuItem>
                <MenuItem value="today">Today</MenuItem>
                <MenuItem value="thisWeek">This Week</MenuItem>
                <MenuItem value="thisMonth">This Month</MenuItem>
                <MenuItem value="thisYear">This Year</MenuItem>
                <MenuItem value="lastQuarter">Last Quarter (3 mo)</MenuItem>
                <MenuItem value="last2Quarters">
                  Last 2 Quarters (6 mo)
                </MenuItem>
                <MenuItem value="last3Quarters">
                  Last 3 Quarters (9 mo)
                </MenuItem>
                <MenuItem value="custom">Custom</MenuItem>
              </Select>
            </FormControl>

            {timeRange === "custom" && (
              <>
                <TextField
                  label="Start"
                  type="date"
                  size="small"
                  sx={{ mr: 1 }}
                  InputLabelProps={{ shrink: true }}
                  value={customStart}
                  onChange={(e) => setCustomStart(e.target.value)}
                />
                <TextField
                  label="End"
                  type="date"
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={customEnd}
                  onChange={(e) => setCustomEnd(e.target.value)}
                />
              </>
            )}
          </Box>
          <Box sx={{ mb: 2 }}>
            <FormControl size="small" sx={{ mr: 2, minWidth: 120 }}>
              <InputLabel id="user-filter-label">User</InputLabel>
              <Select
                labelId="user-filter-label"
                id="user-filter-select"
                label="User"
                value={selectedUser}
                onChange={(e) => setSelectedUser(e.target.value)}
              >
                <MenuItem value="all">All Users</MenuItem>
                {uniqueUsers.map((user) => (
                  <MenuItem key={user} value={user}>
                    {user}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          {/* Sort Section */}
          <Box sx={{ mb: 2 }}>
            <FormControl size="small" sx={{ mr: 2, minWidth: 140 }}>
              <InputLabel id="sort-column-label">Sort By</InputLabel>
              <Select
                labelId="sort-column-label"
                id="sort-column-select"
                label="Sort By"
                value={sortColumn}
                onChange={(e) => setSortColumn(e.target.value)}
              >
                <MenuItem value="created">Created Date</MenuItem>
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="account">Account</MenuItem>
                <MenuItem value="distributor">Distributor Rep</MenuItem>
              </Select>
            </FormControl>

            <RadioGroup
              value={sortDirection}
              onChange={(e) => setSortDirection(e.target.value)}
            >
              <FormControlLabel
                value="asc"
                control={<Radio />}
                label="Ascending"
              />
              <FormControlLabel
                value="desc"
                control={<Radio />}
                label="Descending"
              />
            </RadioGroup>
          </Box>
        </Grid2>
        <Grid2 size={8}>
          <Typography variant="h6" sx={{ mb: 1 }}>
            Preview Table
          </Typography>
          <Typography variant="body2" sx={{ mb: 1 }}>
            Showing the first 5 responses based on the selected filters. If you
            see nothing, try changing the time range or filters.
          </Typography>
          <Box
            sx={{
              maxHeight: 350,
              minHeight: 350,
              overflowY: "auto",
              border: "1px solid #ddd",
            }}
          >
            <Table size="small">
              <TableHead>
                <TableRow>
                  <TableCell>Form ID</TableCell>
                  <TableCell>User</TableCell>
                  <TableCell>Account</TableCell>
                  <TableCell>Date</TableCell>
                  {fieldsToPreview.map((f) => (
                    <TableCell key={f.id}>
                      <Typography noWrap>{f.label}</Typography>
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {previewData.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={4 + fieldsToPreview.length}>
                      No data found for this filter/time range.
                    </TableCell>
                  </TableRow>
                ) : (
                  previewData.map((res, rowIndex) => (
                    <TableRow key={rowIndex}>
                      <TableCell>{res.form_id}</TableCell>
                      <TableCell>{res.user_displayname}</TableCell>
                      <TableCell>{res.account_displayname}</TableCell>
                      <TableCell>
                        {dayjs(res.created).format("MM-DD-YYYY hh:mm A")}
                      </TableCell>
                      {fieldsToPreview.map((f) => {
                        let answer = "";
                        if (
                          res.response_body &&
                          Array.isArray(res.response_body.values)
                        ) {
                          const found = res.response_body.values.find((v) =>
                            v.name.startsWith(f.field_name)
                          );
                          if (found) {
                            answer = found.textValue || found.value || "";
                          }
                        }
                        return <TableCell key={f.id}>{answer}</TableCell>;
                      })}
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </Box>
        </Grid2>
      </Grid2>
      <Grid2 size={12}>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          Fields to Export:
        </Typography>
        <Box
          sx={{
            maxHeight: 350,
            minHeight: 350,
            marginTop: 1,
            overflowY: "auto",
            border: "1px solid #ccc",
            p: 1,
            mb: 2,
          }}
        >
          <FormGroup>
            <Box>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={selectedFields.length === allFields.length}
                    onChange={() => {
                      if (selectedFields.length === allFields.length) {
                        setSelectedFields([]);
                      } else {
                        setSelectedFields(allFields.map((f) => f.id));
                      }
                    }}
                  />
                }
                label="Select All"
              />
            </Box>
            {allFields.map((f) => (
              <Box key={f.id} sx={{ ml: f.level * 2 }}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedFields.includes(f.id)}
                      onChange={() => handleFieldToggle(f.id)}
                    />
                  }
                  label={f.label}
                />
              </Box>
            ))}
          </FormGroup>
        </Box>
        <Grid2
          size={12}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          {/* Export Format */}
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Export Format:
          </Typography>
          <FormControl component="fieldset" sx={{ mt: 1 }}>
            <RadioGroup
              row
              value={exportFormat}
              onChange={(e) => setExportFormat(e.target.value)}
            >
              <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              <FormControlLabel value="xlsx" control={<Radio />} label="XLSX" />
              <FormControlLabel value="docx" control={<Radio />} label="DOCX" />
            </RadioGroup>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleExport}
            disabled={isExporting}
            sx={{ mr: 2 }}
          >
            {isExporting ? "Exporting..." : "Export"}
          </Button>
          {onClose && (
            <Button variant="outlined" onClick={onClose}>
              Close
            </Button>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
};

export default FormRecap;
