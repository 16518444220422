import { useEffect, useState, useMemo } from "react";
import FieldWrapper from "../form/FieldWrapper";
import {
  Select,
  FormControl,
  InputLabel,
  MenuItem,
  Grid2,
  Box,
  Button,
  Checkbox,
  Dialog,
} from "@mui/material";
import { v4 } from "uuid";
import HelpIcon from "@mui/icons-material/Help";
import BuilderCard from "./BuilderCardConditional";
import { fieldTypes } from "../fieldTypes";
import { v3Field } from "../helpers";

// set up default field for when you click add
const { value, label, element, prefix } = fieldTypes[0];
const defaultField = () => new v3Field(value, label, element, prefix);

const commonRules = [
  {
    value: "truthy",
    label: "an answer",
    description:
      "If the question has an answer, the group of questions you make below will appear",
  },
  {
    value: "falsey",
    label: "no answer",
    description:
      "If the question is not answered, the group of questions you make below will appear",
  },
  {
    value: "always",
    label: "always show",
    description: "The group of questions you make below will always appear",
  },
];
const selectRules = [
  {
    value: "forOne",
    label: "show group if particular items are selected",
    description:
      "Group of questions only appears if specified box is checked or item is selected",
    matchesValues: true,
    instruction:
      "Select options that will make the group show if chosen by the respondent.",
  },
  {
    value: "forOneNot",
    label: "show group if particular items are not selected",
    description:
      "Group of questions only appears if specified box is not checked or item is not selected",
    matchesValues: true,
    instruction:
      "Select options that will make the group show if not chosen by the respondent.",
  },
  {
    value: "forEachSelected",
    label: "repeat group for each selected item",
    description:
      "Group of questions appears once for each box checked or item selected",
    matchesValues: true,
    instruction:
      "Select the options to display conditional questions if these items are selected by the respondent.",
  },
  {
    value: "forEachNotSelected",
    label: "repeat group for each unselected item",
    description:
      "Group of questions appears once for each box unchecked or item unselected",
    matchesValues: true,
    instruction:
      "Select the options to display conditional questions if these items are not selected by the respondent.",
  },
];

const getRules = (element) => {
  if (["Checkboxes", "Dropdown", "RadioButtons"].includes(element)) {
    return [...selectRules, ...commonRules];
  }
  return commonRules;
};

const createConditionGroup = ({ field, rootFieldId }) => {
  return {
    rule: getRules(field.element)[0].value,
    fields: [],
    parentOptions: [],
    parentFieldId: field.id,
    rootFieldId,
    id: v4(),
  };
};

const RuleSetter = ({
  rules,
  rule,
  changeRule,
  changeFields,
  field,
  group,
}) => {
  const ruleDetail = rules.find((r) => r.value === rule) || rules[0];

  const updateFields = (event) => {
    const fieldVal = event.target.value;
    if (group.parentOptions.includes(fieldVal)) {
      changeFields(group.parentOptions.filter((f) => f !== fieldVal));
    } else {
      changeFields([...group.parentOptions, fieldVal]);
    }
  };
  const [focus, setFocus] = useState(false);
  const [showDescription, setShowDescription] = useState(false);

  return (
    <>
      <Grid2 container>
        <div style={{ width: "100%" }}>
          <Grid2
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginBottom: 20,
            }}
            item
          >
            <div>
              <InputLabel
                style={{ color: focus ? "#1F449F" : "#BDBDBD", fontSize: 12 }}
              >
                Question display condition
              </InputLabel>
              <FormControl
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                style={{ minWidth: "fit-content" }}
                // className={classes.select}
              >
                <Select
                  value={rule}
                  onChange={(event) => changeRule(event.target.value)}
                  //   MenuProps={{ disableScrollLock: true }}
                >
                  {rules.map((ru, index) => (
                    <MenuItem key={index} value={ru.value}>
                      {ru.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ display: "flex", flexDirection: "column" }}>
              <HelpIcon
                style={{
                  cursor: "pointer",
                  color: "#CAC9C9",
                  marginTop: "auto",
                  marginBottom: 5,
                }}
                fontSize="small"
                onClick={() => setShowDescription(ruleDetail.description)}
              />
            </div>
          </Grid2>
        </div>

        {ruleDetail.matchesValues &&
          field?.options &&
          rule.startsWith("forOne") && (
            <Grid2 sm={12}>
              <div style={{ color: "#999", fontSize: 14 }}>
                {ruleDetail.instruction}
              </div>
              {field.options.map((o, i) => (
                <p key={i}>
                  <Checkbox
                    value={o.key}
                    checked={group.parentOptions.includes(o.key)}
                    onChange={updateFields}
                  />
                  {o.value}
                </p>
              ))}
            </Grid2>
          )}
      </Grid2>
      <Dialog
        onClose={() => setShowDescription(false)}
        open={showDescription}
        fullWidth
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              fontSize: 20,
              fontWeight: "600",
              marginBottom: 10,
              paddingTop: 30,
            }}
          >
            Condition Description
          </div>
          <div style={{ paddingBottom: 20, paddingLeft: 20, paddingRight: 20 }}>
            {showDescription}
          </div>
        </div>
      </Dialog>
    </>
  );
};

const ConditionalGroup = ({
  group,
  groups,
  element,
  groupActions,
  rootFieldId,
  changeRootField,
  field,
}) => {
  const rules = getRules(element);
  const changeRule = (ruleName) => {
    groupActions.updateGroup(group, { rule: ruleName });
  };
  const changeFields = (fields) => {
    groupActions.updateGroup(group, { parentOptions: fields });
  };
  return (
    <div>
      <RuleSetter
        rules={rules}
        rule={group.rule}
        group={group}
        changeRule={changeRule}
        changeFields={changeFields}
        field={field}
      />
      <Box>
        <div
          style={{
            fontSize: 18,
            fontWeight: "500",
            borderTop: "2px solid #EEEEEE",
            paddingTop: 20,
            marginBottom: 20,
          }}
        >
          Questions that appear if the above condition is met (
          {group.fields.length})
        </div>
        {/* <p>RPID:{group.rootFieldId}.</p>
        <p>PID:{group.parentFieldId}</p>
        <p>GID:{group.id}</p> */}
        {group.fields.map((f) => (
          <div style={{ marginTop: 10 }}>
            <BuilderCard
              key={f.id}
              field={f}
              groups={groups}
              addField={() => {}}
              deleteField={(field_id) =>
                groupActions.removeField({
                  group,
                  groups,
                  field_id,
                })
              }
              duplicateField={() => {}}
              changeField={(field_id, props) =>
                groupActions.changeField({
                  field_id,
                  props,
                  group,
                  groups,
                })
              }
              changeRootField={changeRootField}
              rootFieldId={rootFieldId}
              fieldTypes={fieldTypes}
              setActiveFieldUuid={() => {}}
            />
          </div>
        ))}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
          }}
        >
          <Button
            onClick={() => groupActions.addField(group)}
            variant="contained"
            style={{ marginTop: 20, marginBottom: 10 }}
          >
            + Add Another Question to This Group
          </Button>
        </div>
      </Box>
    </div>
  );
};

const ConditionalGroupEditor = ({
  field,
  changeRootField,
  rootField,
  rootFieldId,
  groups: inheritedGroups,
  close,
}) => {
  const [groups, setGroups] = useState(inheritedGroups);
  const [showFieldWrapper, setShowFieldWrapper] = useState(false);

  const localGroups = useMemo(() => {
    return groups.filter((g) => g.parentFieldId === field.id);
  }, [groups, field]);

  const addGroup = () => {
    const newGroup = createConditionGroup({ field, rootFieldId });
    setGroups([...groups, newGroup]);
  };

  useEffect(() => {
    if (inheritedGroups.length !== groups.length) {
      setGroups(inheritedGroups);
    }
  }, [inheritedGroups]);

  const saveGroupsToParent = () => {
    changeRootField(rootFieldId, {
      conditionalGroups: groups.filter((g) => g.fields.length > 0),
    });
    close();
  };
  const groupActions = {
    updateGroup: (group, update) => {
      const newGroups = groups.map((g) => {
        if (group.id === g.id) {
          return {
            ...g,
            ...update,
          };
        }
        return g;
      });
      setGroups(newGroups);
    },
    addField: (group) => {
      const newGroups = groups.map((g) => {
        if (group.id === g.id) {
          return {
            ...g,
            fields: [...g.fields, defaultField()],
          };
        }
        return g;
      });
      setGroups(newGroups);
    },
    removeField: ({ group, groups, field_id }) => {
      const newGroups = groups.map((g) => {
        if (group.uuid === g.uuid) {
          return {
            ...g,
            fields: g.fields.filter((f) => f.id !== field_id),
          };
        }
        return g;
      });
      setGroups(newGroups);
    },
    changeField: ({ field_id, props, group, groups }) => {
      const groupToChange = groups.find((g) => g.id === group.id);
      if (!groupToChange) return console.log("not finding the group");
      let fieldToChangeIndex = groupToChange.fields.findIndex(
        (f) => f.id === field_id
      );
      const fieldToChange = groupToChange.fields[fieldToChangeIndex];
      if (!fieldToChange) {
        return console.log({
          msg: "no field to change",
          field_id,
          props,
          group,
        });
      }
      Object.assign(fieldToChange, props);
      setGroups(groups.map((gr) => (gr.id === group.id ? groupToChange : gr)));
    },
  };

  return (
    <Box p={2}>
      <div
        style={{
          fontSize: 24,
          fontWeight: "600",
          color: "#000000",
          marginBottom: 10,
        }}
      >
        Add/Edit Condtional Groups
      </div>
      <div style={{ fontSize: 16, color: "#BDBDBD", marginBottom: 10 }}>
        This feature allows you to display additional questions if specific
        conditions are met for the following field.
      </div>
      <Button
        size="small"
        style={{ marginBottom: 10, fontSize: 10, color: "#1F449F" }}
        onClick={() => setShowFieldWrapper(!showFieldWrapper)}
      >
        {showFieldWrapper ? "Hide Field" : "Show Field"}
      </Button>
      {showFieldWrapper ? (
        <>
          <div
            style={{
              fontSize: 12,
              color: "#1F449F",
              fontWeight: "600",
              marginBottom: 10,
            }}
          >
            How This Field Will Appear
          </div>
          <Box display={"flex"}>
            <Box
              style={{
                border: "2px solid #1F449F",
                borderRadius: 5,
                padding: 10,
              }}
              flex={1}
            >
              <FieldWrapper
                field={field}
                updateAnswer={() => {}}
                disabled={true} //TODO
              />
            </Box>
          </Box>
        </>
      ) : (
        ""
      )}

      {groups && groups?.length > 0 ? (
        <div>
          <div
            style={{
              border: "1px solid #EEEEEE",
              borderTopColor: "#EEEEEE",
              width: "100%",
              marginBottom: 10,
            }}
          />
          <div
            style={{
              fontSize: 20,
              fontWeight: "600",
              color: "#000000",
              marginBottom: 10,
            }}
          >
            Conditional Groups
          </div>
        </div>
      ) : (
        ""
      )}
      {groups
        .filter((g) => g.parentFieldId === field.id)
        .map((g, ind) => (
          <ConditionalGroup
            key={ind}
            group={g}
            element={field.element}
            groupActions={groupActions}
            groups={groups}
            rootFieldId={rootFieldId}
            changeRootField={changeRootField}
            rootField={rootField}
            field={field}
          />
        ))}
      <div style={{ marginTop: 20 }}>
        <div
          style={{
            border: "1px solid #EEEEEE",
            borderTopColor: "#EEEEEE",
            width: "100%",
            marginBottom: 20,
          }}
        />
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Button
            onClick={addGroup}
            variant="contained"
            color="secondary"
            style={{ marginRight: 10 }}
          >
            Create a conditional group
          </Button>
          <Button
            onClick={saveGroupsToParent}
            variant="contained"
            color="primary"
          >
            Save & Close
          </Button>
        </div>
      </div>
    </Box>
  );
};

export default ConditionalGroupEditor;
