import { useEffect } from "react";
import { API, Storage } from "aws-amplify";
import protobufjs from "protobufjs";
import { useStore } from "../../stores/StoreContext";
import { stateCodeToState } from "../../helpers/data/usStates";
import { startTimer } from "../../helpers/instrumentation";
import { useDepletionsContainerDispatchContext } from "../../stores/DepletionContextStore";
import { toJS } from "mobx";
import { useLocation } from "react-router";
import productsAPI from "../../api/products";
import useApi from "../../hooks/useApi";
function getStateName(stateCode) {
  const stateMap = {
    AL: "Alabama",
    AK: "Alaska",
    AZ: "Arizona",
    AR: "Arkansas",
    CA: "California",
    CO: "Colorado",
    CT: "Connecticut",
    DE: "Delaware",
    FL: "Florida",
    GA: "Georgia",
    HI: "Hawaii",
    ID: "Idaho",
    IL: "Illinois",
    IN: "Indiana",
    IA: "Iowa",
    KS: "Kansas",
    KY: "Kentucky",
    LA: "Louisiana",
    ME: "Maine",
    MD: "Maryland",
    MA: "Massachusetts",
    MI: "Michigan",
    MN: "Minnesota",
    MS: "Mississippi",
    MO: "Missouri",
    MT: "Montana",
    NE: "Nebraska",
    NV: "Nevada",
    NH: "New Hampshire",
    NJ: "New Jersey",
    NM: "New Mexico",
    NY: "New York",
    NC: "North Carolina",
    ND: "North Dakota",
    OH: "Ohio",
    OK: "Oklahoma",
    OR: "Oregon",
    PA: "Pennsylvania",
    RI: "Rhode Island",
    SC: "South Carolina",
    SD: "South Dakota",
    TN: "Tennessee",
    TX: "Texas",
    UT: "Utah",
    VT: "Vermont",
    VA: "Virginia",
    WA: "Washington",
    WV: "West Virginia",
    WI: "Wisconsin",
    WY: "Wyoming",
  };

  // Normalize input to uppercase and trim whitespace
  const normalizedCode = stateCode.toUpperCase().trim();

  return stateMap[normalizedCode] || stateCode;
}
async function loadData({
  userInfo,
  filters,
  setFilters,
  setAllPremiseTypes,
  isBookMarkPage,
}) {
  const allowedDistIDs = toJS(userInfo.access?.distributors);
  const allowedStates = toJS(userInfo.access?.markets);
  // const data = await API.get("backendGateway", "/users/filters");
  // let products = {};
  // let distributors = {};
  // let premises = [];
  // let states = [];
  let brands = [];
  // let mappings = {};
  let megaFilterMappings = [];
  // let maxDate = new Date().getTime();
  const parsedFilters = {};
  Object.keys(filters).forEach(
    (f) => (parsedFilters[f] = JSON.parse(filters[f]))
  );
  let { distributors, products, premises, states, mappings, maxDate } =
    parsedFilters;
  // products = JSON.parse(data.results[0][0].products);

  // distributors = JSON.parse(data.results[0][0].distributors);
  distributors = distributors.filter((item) => {
    if (allowedDistIDs && allowedDistIDs.length > 0) {
      return allowedDistIDs.includes(item.id);
    }
    return true;
  });
  states = states.filter((item) => {
    if (allowedStates && allowedStates.length > 0) {
      return allowedStates.includes(item);
    }
    return true;
  });

  mappings = mappings.map((m) => {
    const prod = products.find((p) => p.id === m.prod_id);
    if (prod) {
      return {
        ...m,
        brand_id: prod.brand,
      };
    }
    return m;
  });
  const seen = new Set();
  /** */
  megaFilterMappings = mappings
    .filter((item) => {
      const distributor = distributors.find((dist) => dist.id === item.dist_id);
      return distributor?.name; // Only include items where distributor.name exists
    })
    .map((item) => {
      const distributor = distributors.find((dist) => dist.id === item.dist_id);
      const product = products.find((prod) => prod.id === item.prod_id);
      return {
        dist_displayname: distributor?.name || "Unknown Distributor",
        distributor_id: String(item.dist_id),
        product_brand: product?.brand || "Unknown Brand",
        state: getStateName(item.state_id),
      };
    })
    .filter((obj) => {
      const uniqueKey = `${obj.distributor_id}-${obj.product_brand}-${obj.state}`;
      if (seen.has(uniqueKey)) {
        return false; // Skip if already in the set
      }
      seen.add(uniqueKey);
      return true; // Include if not in the set
    });
  brands = [...new Set(products.map((x) => x.brand))];
  products = Object.fromEntries(
    products.map((d) => [d.id, { ...d, id: d.id }]) // its a string below
  );
  distributors = Object.fromEntries(
    distributors.map((d) => [d.id, { ...d, id: d.id }]) // its a string below
  );
  let preppedFilters = {
    allStates: states.map((s) => ({
      id: s,
      name: getStateName(s) || "Unknown or bad state code",
    })),
    latestSale: maxDate,
    allBrands: [...brands].map((b) => ({ id: b, name: b })),
    allDistributors: Object.values(distributors),
    allProducts: Object.values(products),
    premiseTypes: premises,
    mappings,
  };
  // we check if we are on a bookmark page before setting selected values
  // this leave the arrays empty until the bookmark has been loaded, and does not overwrite them afterwards
  if (!isBookMarkPage) {
    preppedFilters = {
      ...preppedFilters,
      selectedStates: states,
      selectedDistributors: Object.keys(distributors).map((k) =>
        parseInt(k, 10)
      ),
      // selectedProducts: Object.keys(products).map((k) => parseInt(k, 10)),
      selectedBrands: [...brands],
    };
  } else {
    // if it's bookmarks being uses, we need to turn off simple mode
    // otherwise, by default no query params will be set up other than date
    // that would mean eg: home page modules get empty API responses
    preppedFilters.simpleMode = false;
  }
  // /**
  setFilters(preppedFilters);
  setAllPremiseTypes(premises);

  let json;

  // Combine processed data into final JSON object
  json = {
    results: {
      manufacturer_id: userInfo.mid,
      // accounts: Object.fromEntries(
      //   rawData.accounts.map((a) => [
      //     a.id,
      //     { ...a, premise_type: a.premiseType },
      //   ])
      // ),
      maxDate,
      brands,
      megaFilterMappings,
      premises,
      states,
      products,
      distributors,
      // depletions: rawData.depletions.map((d) => ({
      //   ...d,
      //   qty_individual_unit: d.qtyIndividualUnit,
      // })),
    },
  };

  // property should be results, refactoredFile is from a migration name
  const results = json.refactoredFile || json.results;
  return results;
}

function createMergedSaleEntry(
  sale,
  account,
  distributor,
  product,
  manufacturer_id,
  TIMESTAMP
) {
  const bottles_sold = sale.qty_individual_unit;
  const productRetailUnit = product.retailUnit || 1;
  const productUnitSize = product.unitSize || 1;
  const productPhysicalCase = product.physicalCase || 12;

  return {
    ...sale,
    DATE: sale.date,
    TIMESTAMP,
    ZIP: account.zip,
    premise_type: account.premise_type ? account.premise_type : "UNKNOWN",
    DISTRIBUTOR_ID: distributor.id,
    DISTRIBUTOR_NAME: distributor.name,
    ACCOUNT_ID: account.id,
    STORENUMBER: account.name,
    latlong:
      account.geo && account.geo[0] && account.geo[1]
        ? `${account.geo[0]}, ${account.geo[1]}`
        : null,
    STOREADDRESS: account.address,
    REGION: `${account.city} - ${account.state}`,
    STATE: account.state,
    CITY: account.city,
    PRODUCTNAME: product.name,
    PRODUCT_ID: product.id,
    SKU: product.brand,
    QTYSOLD: bottles_sold,
    NINELTR:
      sale.NINELTR ||
      Number((bottles_sold / productPhysicalCase).toPrecision(8)),
    UNIT_SOLD:
      sale.UNIT_SOLD ||
      Number((bottles_sold / productRetailUnit).toPrecision(8)),
    PHYSICAL_SOLD:
      sale.PHYSICAL_SOLD ||
      Number(
        (bottles_sold / (productPhysicalCase * productRetailUnit)).toPrecision(
          8
        )
      ),
    NINE_SOLD:
      sale.NINE_SOLD ||
      Number(((bottles_sold * productUnitSize) / 9000).toPrecision(8)),
    CASE_EQV:
      sale.CASE_EQV ||
      Number(((bottles_sold * productUnitSize) / 288).toPrecision(8)),
    PREMISETYPE: account.premise_type ? account.premise_type : "UNKNOWN",
    BRAND: product.brand,
    MANUFACTURER_ID: manufacturer_id,
    INTERNAL_PRODUCT_ID: product.internalProductId,
    phone: account.phone,
  };
}
const useSalesData = () => {
  const {
    userInfo,
    depletionList,
    setDepletionList,
    setAllAccountsObjects,
    setAllDistributorsObjects,
    setAllProducts,
    setLatestSale,
    setManufacturerStates,
    setAllMarketsObjectsMinified,
    setAllAccountsObjectsMinified,
    setAllDistributorsObjectsMinified,
    setAllProductsMinified,
    setAllPremiseTypes,
    setCompleteDistBrandData,
    batchSetFilters,
    manufacturer,
    setAutoCompleteProducts,
  } = useStore();

  const location = useLocation();
  const isBookMarkPage = /\/bm\/[\d\w-]{36}/.test(location.pathname);

  useEffect(() => {
    if (!manufacturer.filters) {
      return;
    }
    const fetchData = async () => {
      const results = await loadData({
        userInfo,
        filters: JSON.parse(manufacturer.filters),
        setFilters: batchSetFilters,
        setAllPremiseTypes,
        isBookMarkPage,
      });

      // const processedData = await processData(userInfo, results);

      const setterTimers = startTimer("setters");
      let products = Object.values(results.products).map((x) => ({
        ...x,
        PRODUCTNAME: x.name,
        PRODUCT_ID: x.id,
      }));

      let distributors = Object.values(results.distributors).map((x) => ({
        ...x,
        displayname: x.name,
      }));
      setManufacturerStates(results.states);
      setAllMarketsObjectsMinified(results.states); //this did show states that have no sales, moving to with sales

      setAllDistributorsObjects(distributors);

      setAllAccountsObjectsMinified(results.accounts);
      setAllDistributorsObjectsMinified(results.distributors);
      setAllProductsMinified(results.products);

      setterTimers.logTimer();
      // setDepletionList(processedData.mergedSalesData);
      setterTimers.logTimer();

      if (results.megaFilterMappings.length > 0)
        setCompleteDistBrandData(results.megaFilterMappings);

      setterTimers.endTimer();
    };
    fetchData();
  }, [
    setAllAccountsObjects,
    setAllAccountsObjectsMinified,
    setAllDistributorsObjects,
    setAllDistributorsObjectsMinified,
    setAllMarketsObjectsMinified,
    setAllPremiseTypes,
    setDepletionList,
    setLatestSale,
    setManufacturerStates,
    setAllProducts,
    setAllProductsMinified,
    userInfo,
    batchSetFilters,
    setCompleteDistBrandData,
    manufacturer,
  ]);

  const getProductsApi = useApi(productsAPI.getProducts);
  useEffect(() => {
    if (!userInfo.manufacturer_id) return;
    getProductsApi
      .request({ mid: userInfo.manufacturer_id, limit: 1000 })
      .then(({ results }) => setAutoCompleteProducts(results))
      .catch((error) => console.log(error));
  }, [userInfo]);

  useEffect(() => {
    let debounceCooldown;
    const checkManufacturer = async () => {
      const latestUser = await API.get("backendGateway", "/users/me");
      let user = latestUser.results[0];
      return user.current_data_hash !== userInfo.current_data_hash;
    };

    checkManufacturer().then((bool) => {
      if (bool) {
        debounceCooldown = setTimeout({}, 800);
      }
    });

    return () => {
      clearTimeout(debounceCooldown);
    };
  }, [userInfo, depletionList]);

  return {};
};

export default useSalesData;
