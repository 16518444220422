import React, { useState } from "react";
import { Dialog } from "@mui/material";
import { Loader } from "@aws-amplify/ui-react";
import { Person } from "@mui/icons-material";
import { Link } from "react-router-dom";
import { Settings } from "luxon";

// Import DataGridPro from MUI X Pro
import { DataGridPro } from "@mui/x-data-grid-pro";

import { awsS3Image } from "../../../helpers/awsS3Image";

// Set default Luxon timezone (optional)
Settings.defaultZone = "local";

// Type name mapping for your event icons
const typeNames = {
  account_checkin: "CheckIn",
  contact_created: "Contact",
  media_upload: "Image",
  form_response: "Form",
};

const TeamActivityTracker = ({ members, data }) => {
  const [imageOpen, setImageOpen] = useState(false);
  const [contactOpen, setContactOpen] = useState(false);

  /**
   * Renders the circle/gray placeholder for a former employee
   */
  const renderFormerEmployeeImage = () => (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <div
        style={{
          width: "50px",
          height: "50px",
          borderRadius: "50%",
          backgroundColor: "gray",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Person style={{ color: "white", fontSize: "30px" }} />
      </div>
    </div>
  );

  /**
   * Renders default user image
   */
  const renderDefaultUserImage = () => (
    <img
      src="/static/images/avatars/gg_default_image.png"
      style={{
        width: "50px",
        height: "50px",
        borderRadius: "10px",
        padding: "5px",
      }}
      alt="Default"
    />
  );

  /**
   * Renders custom user image
   */
  const renderCustomUserImage = (identitykey, name, image) => (
    <img
      src={awsS3Image(image, identitykey, "0x110")}
      alt={name}
      style={{ width: "50px", height: "50px", borderRadius: "1000px" }}
    />
  );

  /**
   * Decides which type of user image to render
   */
  const renderUserImage = (
    identitykey = "",
    name = "Unknown",
    image = "",
    userId
  ) => {
    const member = members.find((m) => m.user_id === userId);
    if (!member) {
      return renderFormerEmployeeImage();
    }
    const normalizedImage = (image || "").toUpperCase();
    if (normalizedImage === "FORMER_EMPLOYEE.PNG") {
      return renderFormerEmployeeImage();
    } else if (normalizedImage === "GG_DEFAULT_IMAGE.PNG") {
      return renderDefaultUserImage();
    } else {
      return renderCustomUserImage(identitykey, name, image);
    }
  };

  /**
   * Build rows for the DataGridPro
   */
  const rows = data.map((event) => ({
    id: `${event.type}-${event.user_displayname}-${event.created}`,
    user_id: event.user_id,
    user_displayname: event.user_displayname,
    account_id: event.account_id,
    account_displayname: event.account_displayname,
    type: event.type,
    created: event.created, // numeric timestamp
  }));

  /**
   * Define columns for DataGridPro
   */
  const columns = [
    {
      field: "teamMember",
      headerName: "Team Member",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        const member = members.find((m) => m.user_id === row.user_id);

        return (
          <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
            {renderUserImage(
              member?.user_identitykey,
              row.user_displayname,
              member?.user_image || "",
              row.user_id
            )}
            {member ? (
              <Link
                style={{
                  color: "black",
                  fontWeight: 500,
                  textDecoration: "underline",
                }}
                to={`/app/profile/${row.user_id}`}
              >
                {row.user_displayname}
              </Link>
            ) : (
              <span style={{ color: "gray", fontWeight: 500 }}>
                {row.user_displayname}
                <div style={{ fontSize: 12, color: "gray" }}>
                  (Former Employee)
                </div>
              </span>
            )}
          </div>
        );
      },
    },
    {
      field: "created",
      headerName: "Date",
      flex: 1,
      type: "dateTime",
      sortable: true,
      // The DataGrid will sort by numeric timestamp automatically.
      // Use a formatter to display how you want:
      valueFormatter: (params) => {
        const dateNum = params.value; // numeric timestamp
        const localDate = new Date(dateNum);
        const day = localDate.getDate();
        const monthStr = new Intl.DateTimeFormat(undefined, {
          month: "short",
        }).format(localDate);
        const year = localDate.getFullYear();
        const hour = localDate.getHours() % 12 || 12;
        const minute = localDate.getMinutes().toString().padStart(2, "0");
        const ampm = localDate.getHours() < 12 ? "am" : "pm";

        // e.g., 1 -> '1st', 2-> '2nd', etc.
        const suffix =
          day === 1 ? "st" : day === 2 ? "nd" : day === 3 ? "rd" : "th";

        return `${day}${suffix} ${monthStr} ${year} ${hour}:${minute} ${ampm}`;
      },
    },
    {
      field: "type",
      headerName: "Type",
      flex: 0.7,
      sortable: false,
      renderCell: (params) => {
        const eventType = params.value || "";
        const iconName = typeNames[eventType] || "Generic";
        return (
          <img
            src={`/static/images/icons/${iconName}.svg`}
            alt={iconName}
            width="40"
            height="40"
            style={{ marginRight: "10px" }}
          />
        );
      },
    },
    {
      field: "info",
      headerName: "Info",
      flex: 2,
      sortable: false,
      renderCell: (params) => {
        const row = params.row;
        return (
          <Link
            style={{
              color: "black",
              fontWeight: 500,
              textDecoration: "underline",
            }}
            to={`/app/accountdetails/${row.account_id}`}
          >
            Account: {row.account_displayname}
          </Link>
        );
      },
    },
  ];

  /**
   * If you want to open the image or contact dialog from the grid,
   * you can do so in the above renderCell's onClick or similar. For now,
   * we'll keep them as your original code that simply uses toggles.
   */

  return (
    <>
      <Dialog
        fullWidth
        maxWidth="lg"
        onClose={() => setImageOpen(false)}
        open={Boolean(imageOpen)}
        onClick={() => setImageOpen(false)}
      >
        {imageOpen ? (
          <img
            src={imageOpen}
            style={{ height: "100%", width: "100%" }}
            alt="clicked"
          />
        ) : null}
      </Dialog>

      <Dialog
        fullWidth
        onClose={() => setContactOpen(false)}
        open={Boolean(contactOpen)}
        onClick={() => setContactOpen(false)}
      >
        <div style={{ padding: 10 }}>
          <div
            style={{
              paddingBottom: 10,
              fontSize: 24,
              fontWeight: 600,
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            Contact Details
          </div>
          <div>Name: {contactOpen && contactOpen.name}</div>
          <div>Title: {contactOpen && contactOpen.title}</div>
          <div>Email: {contactOpen && contactOpen.email}</div>
          <div>Phone: {contactOpen && contactOpen.phone}</div>
        </div>
      </Dialog>

      {!members ? (
        <Loader />
      ) : (
        <div style={{ height: 600, width: "100%" }}>
          {/* The DataGridPro with built-in sorting + pagination */}
          <DataGridPro
            rows={rows}
            columns={columns}
            sx={{ backgroundColor: "white" }}
            pagination
            pageSizeOptions={[8, 16, 32]}
            selectionModel={false}
            initialState={{
              pageSize: 8,
              sorting: {
                sortModel: [{ field: "created", sort: "desc" }],
              },
            }}
            rowHeight={70}
          />
        </div>
      )}
    </>
  );
};

export default TeamActivityTracker;
