// AccountsExplorerView.js
import React, { useState, useEffect, useRef, useMemo } from "react";
import {
  Grid2,
  Container,
  Card,
  CardHeader,
  CardContent,
  Switch,
  FormControlLabel,
  Tooltip,
  CircularProgress,
  Button,
  Icon,
  useMediaQuery,
} from "@mui/material";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";

import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import { useStore } from "../../../stores/StoreContext";
import { useAccountsUnsold, useProducts, useFilteredData } from "./DataHooks";
import AccountsTable from "./AccountsTable";

import ExcelJS from "exceljs";
import { Parser } from "json2csv";
import STHeatMapNew from "../../../components/visualisation/brands/STHeatMapIncludingUnsold";

import { useDepletionsContainerContext } from "../../../stores/DepletionContextStore";
import { makeStyles } from "@mui/styles";

const handleExportExcel = async (accounts, selectedRows) => {
  // Convert accounts from an object to an array
  const accountsArray = Object.values(accounts);

  // Filter accounts based on selected rows if any are selected
  const exportData = (
    selectedRows.length
      ? accountsArray.filter((account) => selectedRows.includes(account.id))
      : accountsArray
  ).map((account) => {
    // Format the export data
    const {
      lat, // Remove lat
      long, // Remove long
      lastSoldDate,
      productsSold,
      ...rest
    } = account;

    // Format lastSoldDate and productsSold
    return {
      ...rest,
      lastSoldDate: lastSoldDate
        ? new Date(lastSoldDate).toLocaleDateString() // Format as a human-readable date
        : "N/A",
      productsSold: Array.isArray(productsSold)
        ? productsSold.map((product) => product.productName).join(", ") // Join product names
        : "N/A",
    };
  });

  if (exportData.length === 0) {
    alert("No data available for export.");
    return;
  }
  // Create a new workbook and worksheet
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Accounts");

  // Define title row styles
  const titleRowStyle = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "142F72" } },
    font: { color: { argb: "FFFFFF" }, bold: true },
  };

  // Define alternating row styles
  const firstColumnStyle = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "142F72" } },
    font: { color: { argb: "FFFFFF" }, bold: true },
  };
  const alternateRowStyle1 = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "CAD6FF" } },
    font: { color: { argb: "000000" } },
  };
  const alternateRowStyle2 = {
    fill: { type: "pattern", pattern: "solid", fgColor: { argb: "FFFFFF" } },
    font: { color: { argb: "000000" } },
  };

  // Add header row
  const headerRow = worksheet.addRow(Object.keys(exportData[0]));
  headerRow.eachCell((cell) => {
    cell.style = titleRowStyle;
  });

  // Add data rows
  exportData.forEach((data, rowIndex) => {
    const row = worksheet.addRow(Object.values(data));
    row.eachCell((cell, colIndex) => {
      if (!cell.value) {
        cell.value = ""; // Explicitly set to an empty string
      }
      // Style rows
      cell.style =
        colIndex === 1
          ? firstColumnStyle
          : rowIndex % 2 === 0
          ? alternateRowStyle1
          : alternateRowStyle2;
    });
  });

  // Generate and download the Excel file as before
  const buffer = await workbook.xlsx.writeBuffer();
  const blob = new Blob([buffer], {
    type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "accounts.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const handleExportCSV = (accounts, selectedRows) => {
  // Convert accounts from an object to an array
  const accountsArray = Object.values(accounts);

  // Filter accounts based on selected rows if any are selected
  const exportData = (
    selectedRows.length
      ? accountsArray.filter((account) => selectedRows.includes(account.id))
      : accountsArray
  ).map((account) => {
    // Format the export data
    const {
      lat, // Remove lat
      long, // Remove long
      lastSoldDate,
      productsSold,
      ...rest
    } = account;

    // Format lastSoldDate and productsSold
    return {
      ...rest,
      lastSoldDate: lastSoldDate
        ? new Date(lastSoldDate).toLocaleDateString() // Format as a human-readable date
        : "N/A",
      productsSold: Array.isArray(productsSold)
        ? productsSold.map((product) => product.productName).join(", ") // Join product names
        : "N/A",
    };
  });

  if (exportData.length === 0) {
    alert("No data available for export.");
    return;
  }

  // Convert data to CSV
  const parser = new Parser();
  const csv = parser.parse(exportData);
  const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
  const url = URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.href = url;
  a.download = "accounts.csv";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const FilterSwitch = ({ label, checked, onChange, tooltip }) => (
  <Tooltip title={tooltip}>
    <FormControlLabel
      control={<Switch checked={checked} onChange={onChange} />}
      label={label}
    />
  </Tooltip>
);

const AccountsExplorerView = observer(() => {
  const store = useStore();
  const {
    userInfo,
    selectedQuantityLabel,
    selectedBrands,
    selectedStates,
    regions,
    dateRange,
    premiseTypes,
    productSold,
    selectedDistributors,
    selectedProducts,
  } = store;

  const { setDepletionListCount, depletions: depletionList } =
    useDepletionsContainerContext();

  const longCodeRegionMap = useMemo(() => {
    return Object.fromEntries(regions.map(({ long, code }) => [long, code]));
  }, [regions]);

  const filterArgs = useMemo(
    () => ({
      brands: new Set(selectedBrands),
      states: new Set(selectedStates.map((state) => longCodeRegionMap[state])),
      distributors: new Set(selectedDistributors),
      products: new Set(selectedProducts.map((fp) => fp.product_id)),
      dateRange,
      premiseTypes,
      productSold,
    }),
    [
      selectedBrands,
      selectedStates,
      selectedDistributors,
      selectedProducts,
      longCodeRegionMap,
      dateRange,
      premiseTypes,
      productSold,
    ]
  );

  const classes = useStyles();
  const scrollPreserver = useRef(null);
  const navigate = useNavigate();

  const [showProspectiveAccounts, setShowProspectiveAccounts] = useState(true);
  const [showAccountsSold, setShowAccountsSold] = useState(true);
  const [applyDateFilter, setApplyDateFilter] = useState(false);

  const accountsUnsoldState = useAccountsUnsold();
  const productsState = useProducts();

  // console.log(productsState.products);
  const { accounts: allFilteredAccounts = [] } = useFilteredData({
    accountsUnsold: accountsUnsoldState.accounts || [],
    accountsSold: depletionList || [],
    products: productsState.products || [],
    showProspectiveAccounts,
    showAccountsSold,
    applyDateFilter,
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const showSplash = !(
    (accountsUnsoldState.apiCallCount || 0) > 0 &&
    (productsState.apiCallCount || 0) > 0 &&
    (setDepletionListCount || 0) > 0
  );

  useEffect(() => {
    if (
      (accountsUnsoldState.apiCallCount || 0) > 0 ||
      (productsState.apiCallCount || 0) > 0 ||
      (setDepletionListCount || 0) > 0
    ) {
      trackEvent(userInfo.uid, "PageView:AccountsExplorer");
    }
  }, [
    userInfo.uid,
    accountsUnsoldState.apiCallCount,
    productsState.apiCallCount,
    setDepletionListCount,
  ]);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  const handleRowClick = (param) => {
    navigate(`/app/accountdetails/${param.row.id}`);
  };

  if (showSplash) {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  const handleExportToExcel = () => {
    handleExportExcel(allFilteredAccounts, selectedRows);
  };

  const handleExportToCSV = () => {
    handleExportCSV(allFilteredAccounts, selectedRows);
  };

  return (
    <Page className={classes.root} title="Accounts Explorer">
      <Container maxWidth="xl" className={classes.root}>
        <Grid2
          container
          spacing={3}
          direction={smallScreen ? "column" : "row"}
          style={{
            minHeight: 0,
          }}
        >
          <Grid2 size={12} ref={scrollPreserver}>
            <Grid2 container spacing={3}>
              <Grid2 size={12}>
                <Card
                  style={{
                    padding: 10,
                    marginBottom: 10,
                    maxHeight: 600,
                  }}
                >
                  <CardHeader
                    title={
                      selectedRows.length
                        ? "Showing Selected Rows Accounts"
                        : "Filtered Accounts"
                    }
                  />
                  <STHeatMapNew
                    accounts={allFilteredAccounts}
                    onlyShowTheseAccounts={selectedRows}
                  />
                </Card>
                <Card>
                  <CardHeader title="Accounts Explorer" />
                  <CardContent>
                    {/* <FilterSwitch
                      label="Show Accounts Without Sales Data"
                      tooltip="If enabled this will show all accounts without any sales attributed to it via the sales depletion data pipeline. These may be manually added or imported accounts. If product or date filters are applied and this option is enabled, these accounts will still show up. These accounts show up with N/A in Total Sold and Last Sale Date Columns."
                      checked={showProspectiveAccounts}
                      onChange={() =>
                        setShowProspectiveAccounts(!showProspectiveAccounts)
                      }
                    /> */}
                    {/* <FilterSwitch
                      label="Show Accounts with Sales Data"
                      tooltip="If enabled this will show all accounts with sales attributed to them via the sales depletion data pipeline."
                      checked={showAccountsSold}
                      onChange={() => setShowAccountsSold(!showAccountsSold)}
                    /> */}
                    {/* <FilterSwitch
                      label="Apply Date Filter"
                      tooltip="If enabled, this option will filter the accounts with sales data to only show accounts with sales during the selected dates within your filters."
                      checked={applyDateFilter}
                      onChange={() => setApplyDateFilter(!applyDateFilter)}
                    /> */}
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        gap: 10,
                        justifyContent: "flex-end",
                        marginBottom: 10,
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleExportToExcel}
                      >
                        <Icon
                          className="fas fa-file-excel"
                          type="file-excel"
                          style={{ marginRight: 5 }}
                          color="white"
                        />
                        Export to Excel
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleExportToCSV}
                      >
                        <Icon
                          className="fas fa-file-csv"
                          type="file-csv"
                          style={{ marginRight: 5 }}
                          color="white"
                        />
                        Export to CSV
                      </Button>
                    </div>
                    <AccountsTable
                      rows={allFilteredAccounts}
                      selectedRows={selectedRows}
                      onRowClick={handleRowClick}
                      onSelectionModelChange={setSelectedRows}
                      selectedQuantityLabel={selectedQuantityLabel}
                    />
                  </CardContent>
                </Card>
              </Grid2>
            </Grid2>
          </Grid2>
        </Grid2>
      </Container>
    </Page>
  );
});

export default AccountsExplorerView;
