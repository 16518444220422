import { useState, useEffect } from "react";
import { TextField, FormControl } from "@mui/material";
import StLabel from "./StLabel";

const StNumber = ({
  required,
  decimal,
  label,
  answer,
  error,
  helperText,
  changeAnswer = () => {},
}) => {
  const [val, setVal] = useState(answer.value);
  const onChange = (e) => {
    setVal(e.target.value);
    changeAnswer(e.target.value);
  };
  useEffect(() => {
    if (answer.value !== val) {
      setVal(answer.value);
    }
  }, [answer, val]);

  const [active, setActive] = useState(false);

  return (
    <FormControl fullWidth>
      <StLabel required={required} text={label} error={error} active={active} />
      <TextField
        {...{
          required,
          error,
          helperText,
        }}
        size="small"
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        variant="outlined"
        type="number"
        value={val}
        inputProps={{ step: decimal ? "0.01" : "1" }}
        onChange={onChange}
      />
    </FormControl>
  );
};

export default StNumber;
