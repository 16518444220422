import React, { useState } from "react";
import { useDraggable } from "@dnd-kit/core";
import { CSS } from "@dnd-kit/utilities";
import {
  Box,
  Paper,
  Typography,
  IconButton,
  Tooltip,
  Menu,
  MenuItem,
  ButtonGroup,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import MoreVertIcon from "@mui/icons-material/MoreVert";

// A reusable draggable widget wrapper component
const DraggableWidget = ({
  id,
  title,
  infoText,
  children,
  width,
  height,
  isEditMode = false,
  onHideWidget,
  data,
  gridCellWidth,
  gridCellHeight,
  gridGap,
  colSpan,
  rowSpan,
  ...otherProps
}) => {
  const { attributes, listeners, setNodeRef, transform, isDragging } =
    useDraggable({ id, disabled: !isEditMode });

  // State for menu
  const [menuAnchor, setMenuAnchor] = useState(null);
  const isMenuOpen = Boolean(menuAnchor);

  // Check if this is a TopProducts or TopAccounts widget
  const isTopProductsOrAccounts =
    title === "Top Products" || title === "Top Accounts";

  const isSalesTargets = title === "Sales Targets";

  // Generate a unique storage key for this widget
  const storageKey = `widget-timerange-${id}`;

  // Get initial time range from localStorage or use default from widget data
  const initialTimeRange =
    localStorage.getItem(storageKey) ||
    data?.initialState?.timeRange ||
    "LAST_30_DAYS";

  const [timeRange, setTimeRange] = useState(initialTimeRange);

  // Save time range to localStorage when it changes
  const handleTimeRangeChange = (newTimeRange) => {
    localStorage.setItem(storageKey, newTimeRange);
    setTimeRange(newTimeRange);
  };

  // Add state for sales targets breakdown toggle
  const [showBreakdown, setShowBreakdown] = useState(
    data?.initialState?.showBreakdown || false
  );

  const handleOpenMenu = (event) => {
    setMenuAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setMenuAnchor(null);
  };

  const handleHideWidget = () => {
    handleCloseMenu();
    if (onHideWidget) {
      onHideWidget(id);
    }
  };

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: isDragging ? "none" : "transform 0.2s ease",
    width: width || "100%",
    height: height || "100%",
    zIndex: isDragging ? 1000 : 1,
    // Hide the original when dragging
    opacity: isDragging ? 0 : 1,
    visibility: isDragging ? "hidden" : "visible",
  };

  return (
    <div ref={setNodeRef} style={style}>
      <Paper
        sx={{
          height: "100%",
          width: "100%",
          display: "flex",
          flexDirection: "column",
          borderRadius: "8px",
          overflow: "hidden",
          border: "1px solid #C6C6CC",
        }}
      >
        {/* Widget Header with Title and Info */}
        {title && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              pl: 1.5,
              pt: 1.5,
            }}
          >
            <Box
              component="span"
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {isEditMode && (
                <DragIndicatorIcon
                  sx={{
                    mr: 1,
                    cursor: "grab",
                    color: "text.secondary",
                    "&:hover": { color: "primary.main" },
                  }}
                  {...listeners}
                  {...attributes}
                />
              )}
              <Box
                component="span"
                sx={{ display: "flex", alignItems: "baseline" }}
              >
                <Tooltip title={infoText || ""}>
                  <Typography
                    variant="h6"
                    component="h2"
                    sx={{ fontWeight: "bold", mr: 1, cursor: "pointer" }}
                  >
                    {title}
                  </Typography>
                </Tooltip>
              </Box>
            </Box>
            {isEditMode && (
              <>
                <IconButton onClick={handleOpenMenu}>
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id="widget-menu"
                  anchorEl={menuAnchor}
                  open={isMenuOpen}
                  onClose={handleCloseMenu}
                  MenuListProps={{
                    "aria-labelledby": "widget-menu-button",
                    disableAutoFocusItem: true,
                    disableListWrap: true,
                    autoFocus: false,
                    dense: true,
                  }}
                  disableAutoFocus={true}
                  disableEnforceFocus={true}
                  disableRestoreFocus={true}
                  disableScrollLock={true}
                  PopoverProps={{
                    disableScrollLock: true,
                    disableRestoreFocus: true,
                    disableAutoFocus: true,
                    keepMounted: true,
                    anchorOrigin: {
                      vertical: "bottom",
                      horizontal: "right",
                    },
                    transformOrigin: {
                      vertical: "top",
                      horizontal: "right",
                    },
                    sx: {
                      "& .MuiBackdrop-root": {
                        pointerEvents: "none",
                      },
                    },
                  }}
                >
                  <MenuItem onClick={handleHideWidget}>Hide Widget</MenuItem>
                </Menu>
              </>
            )}
            {!isEditMode && isTopProductsOrAccounts && (
              <Box sx={{ px: 2, pt: 1, display: "flex" }}>
                <ButtonGroup
                  size="small"
                  variant="outlined"
                  aria-label="Loading button group"
                >
                  <Button
                    onClick={() => handleTimeRangeChange("LAST_30_DAYS")}
                    variant={
                      timeRange === "LAST_30_DAYS" ? "contained" : "outlined"
                    }
                    value="LAST_30_DAYS"
                  >
                    30 Days
                  </Button>
                  <Button
                    onClick={() => handleTimeRangeChange("LAST_90_DAYS")}
                    variant={
                      timeRange === "LAST_90_DAYS" ? "contained" : "outlined"
                    }
                    value="LAST_90_DAYS"
                  >
                    90 Days
                  </Button>
                  <Button
                    onClick={() => handleTimeRangeChange("LAST_12_MONTHS")}
                    variant={
                      timeRange === "LAST_12_MONTHS" ? "contained" : "outlined"
                    }
                    value="LAST_12_MONTHS"
                  >
                    12 Months
                  </Button>
                </ButtonGroup>
              </Box>
            )}
            {!isEditMode && isSalesTargets && colSpan === 1 && (
              <Box sx={{ px: 2, pt: 1, display: "flex", ml: "auto" }}>
                <FormControlLabel
                  control={
                    <Switch
                      checked={showBreakdown}
                      onChange={() => setShowBreakdown(!showBreakdown)}
                      name="breakdown-toggle"
                    />
                  }
                  label="Breakdown"
                />
              </Box>
            )}
          </Box>
        )}

        {/* Widget Content */}
        <Box
          sx={{
            flex: 1,
            overflow: "auto",
            pl: 2,
            pr: 2,
            pb: 2,
            display: "flex",
            justifyContent: "center", // Center horizontally
            alignItems: "center", // Center vertically
          }}
        >
          {isEditMode ? (
            <Typography color="text.secondary" align="center">
              {title} Widget
              <br />
              <Typography variant="caption" color="text.disabled">
                Edit mode - content hidden
              </Typography>
            </Typography>
          ) : (
            /* Pass all relevant props to children, including timeRange and showBreakdown */
            React.Children.map(children, (child) =>
              React.isValidElement(child)
                ? React.cloneElement(child, {
                    width: width,
                    height: height,
                    gridCellWidth,
                    gridCellHeight,
                    gridGap,
                    colSpan,
                    rowSpan,
                    timeRange: timeRange,
                    showBreakdown: showBreakdown, // Pass the showBreakdown state
                    ...otherProps,
                  })
                : child
            )
          )}
        </Box>
      </Paper>
    </div>
  );
};

export default DraggableWidget;
