import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import statsAPI from "../../../api/stats";
import { useStore } from "../../../stores/StoreContext";
const TopProductAccounts = ({
  prod_id,
  accountHash,
  setAccountHash,
  dists,
  dateStart,
  dateEnd,
}) => {
  const [accounts, setAccounts] = useState([]);
  const [loading, setLoading] = useState(false);
  const { manufacturer } = useStore();
  useEffect(() => {
    const hash = accountHash["p_" + prod_id];
    if (hash) {
      setAccounts(hash);
    } else if (!accounts.length) {
      setLoading(true);
      statsAPI
        .getProductTopAccounts({ prod_id, dists, dateStart, dateEnd })
        .then(({ results }) =>
          setAccountHash({
            ...accountHash,
            ["p_" + prod_id]: results,
          })
        )
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [prod_id, accountHash, setAccountHash]);
  return (
    <Box>
      {loading && <LinearProgress />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Top Accounts</TableCell>
            <TableCell>Address</TableCell>
            <TableCell>Physical Case</TableCell>
            <TableCell>Case Eqv</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {accounts.map((account) => {
            const {
              acc_id,
              acc_name,
              account_address,
              account_city,
              account_region,
              units,
              cases,
              cases_eqv,
            } = account;
            return (
              <TableRow key={acc_id}>
                <TableCell>
                  <RouterLink to={"/app/accountdetails/" + acc_id}>
                    {acc_name}
                  </RouterLink>
                </TableCell>
                <TableCell>
                  {account_address}
                  <br />
                  {account_city} {account_region}
                </TableCell>

                <TableCell>{units}</TableCell>
                <TableCell>
                  {[614, 629, 632].includes(manufacturer.manufacturer_id)
                    ? cases_eqv
                    : cases}
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TopProductAccounts;
