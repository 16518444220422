import React, { useState, useEffect } from "react";
import DistributorBrandGrid from "./DistributorBrandGrid";
import DistributorBrandMap from "./DistributorBrandMap";
import BrandDistribution from "./BrandDistribution";
import ExternalBrands from "../externalBrands";
import { Card, CardContent, CardHeader, Grid2, Tab, Tabs } from "@mui/material";

const RegionCompareUI = ({
  data,
  filteredData,
  io,
  selectedStates,
  selectedBrands,
  allBrands,
  allDistributors,
  selectedDistributors,
  completeDistBrandData,
  regions,
}) => {
  const [activeBrand, setActiveBrand] = useState([]);
  const [externalBrandList, setExternalBrandList] = useState([]);
  const [activeTab, setActiveTab] = useState(0);

  useEffect(() => {
    if (
      activeBrand === "" ||
      !filteredData.map((fd) => fd.product_brand).includes(activeBrand)
    ) {
      setActiveBrand([]);
    }
  }, [filteredData]);

  return (
    <Grid2 container data-io={io} spacing={2}>
      <Grid2 size={12}>
        <ExternalBrands
          externalBrandList={externalBrandList}
          setExternalBrandList={setExternalBrandList}
        />
      </Grid2>
      <Grid2 size={12}>
        <Tabs
          indicatorColor="primary"
          textColor="primary"
          onChange={(e, v) => setActiveTab(v)}
          value={activeTab}
        >
          <Tab selected={activeTab === 0} label="Map" />
          <Tab selected={activeTab === 1} label="Grid2" />
        </Tabs>
      </Grid2>
      {activeTab === 1 && (
        <div style={{ width: "100%" }}>
          <Grid2 size={12}>
            <Card>
              <CardHeader
                style={{ flexDirection: "row" }}
                title={"Brands By Distributor"}
              />

              <CardContent>
                <DistributorBrandGrid
                  data={filteredData}
                  {...{
                    setActiveBrand,
                    activeBrand,
                    externalBrandList,
                  }}
                />
              </CardContent>
            </Card>
          </Grid2>
          {activeBrand.length > 0 && (
            <Grid2 size={12}>
              <Card
                style={{
                  width: "100%",
                  marginTop: "8px",
                  marginBottom: "8px",
                }}
              >
                <CardHeader
                  title={`States by Distributor for ${activeBrand.join(", ")}`}
                />
                <CardContent>
                  <Grid2 container>
                    {activeBrand.map((ab, i) => (
                      <Grid2 xs={6} md={3} key={i}>
                        <BrandDistribution
                          data={data}
                          brand={ab}
                          externalBrandList={externalBrandList}
                        />
                      </Grid2>
                    ))}
                  </Grid2>
                </CardContent>
              </Card>
            </Grid2>
          )}
        </div>
      )}
      <Grid2
        item
        size={12}
        style={{
          visibility: activeTab === 0 ? "visible" : "hidden",
        }}
      >
        <DistributorBrandMap
          externalBrandList={externalBrandList}
          data={data}
          selectedStates={selectedStates}
          selectedBrands={selectedBrands}
          allBrands={allBrands}
          allDistributors={allDistributors}
          selectedDistributors={selectedDistributors}
          completeDistBrandData={completeDistBrandData}
          regions={regions}
        />
      </Grid2>
    </Grid2>
  );
};

export default RegionCompareUI;
