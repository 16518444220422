import React, { useState } from "react";
import {
  TextField,
  Button,
  Card,
  CardHeader,
  CardContent,
  Grid2,
  Box,
  Divider,
} from "@mui/material";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import { useForm, Controller } from "react-hook-form";

const PasswordForm = ({ submit }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const sendInfo = (data) => {
    submit(data);
  };

  return (
    <Grid2 size={12} md={6} size={12}>
      <Card margin={3}>
        <CardHeader title="Password" subheader="Update Password" />
        <Divider />
        <form onSubmit={handleSubmit(sendInfo)}>
          <CardContent>
            <Grid2 container spacing={3}>
              <Grid2 md={6} size={12}>
                <Controller
                  name="oldPassword"
                  defaultValue={oldPassword}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="Old Password"
                      type="password"
                      error={errors.oldPassword ? true : false}
                      helperText={`${
                        errors.oldPassword?.type === "required"
                          ? "Old password is required"
                          : ""
                      }`}
                    />
                  )}
                  rules={{
                    required: true,
                  }}
                />
              </Grid2>
              <Grid2 md={6} size={12}>
                <Controller
                  name="newPassword"
                  defaultValue={newPassword}
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label="New Password"
                      type="password"
                      error={errors.newPassword ? true : false}
                      helperText={`${
                        errors.newPassword?.type === "required"
                          ? "New password is required"
                          : ""
                      }
											${
                        errors.newPassword?.type === "minLength"
                          ? "New password must be at least 7 characters"
                          : ""
                      }`}
                    />
                  )}
                  rules={{
                    required: true,
                    minLength: 8,
                  }}
                />
              </Grid2>
            </Grid2>
          </CardContent>
          <Divider />
          <Box display="flex" justifyContent="flex-end" p={2}>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              startIcon={<RotateRightIcon />}
            >
              Update
            </Button>
          </Box>
        </form>
      </Card>
    </Grid2>
  );
};

export default PasswordForm;
