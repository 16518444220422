import React from "react";
import { Box, Typography, Divider, Paper, Button } from "@mui/material";
import { useDraggable } from "@dnd-kit/core";
import GridViewIcon from "@mui/icons-material/GridView";
import CompareArrowsIcon from "@mui/icons-material/CompareArrows";
import GroupIcon from "@mui/icons-material/Group";
import FlagIcon from "@mui/icons-material/Flag";
import BusinessIcon from "@mui/icons-material/Business";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import MapIcon from "@mui/icons-material/Map";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Done } from "@mui/icons-material";

// Map widget types to icons based on available widgets
const getWidgetIcon = (widget) => {
  // Get widget type from title or component for mapping
  const title = widget.title?.toLowerCase() || "";

  if (
    title.includes("period") ||
    title.includes("comparison") ||
    widget.component === "SummaryTotalComparison"
  ) {
    return <CompareArrowsIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
  }

  if (title.includes("team") || widget.component === "TeamActivityPreview") {
    return <GroupIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
  }

  if (title.includes("goal") || widget.component === "MonthlyGoals") {
    return <FlagIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
  }

  if (title.includes("account") || widget.component === "TopAccounts") {
    return <BusinessIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
  }

  if (title.includes("product") || widget.component === "TopProducts") {
    return <ShoppingCartIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
  }

  if (
    title.includes("map") ||
    title.includes("cluster") ||
    widget.component === "STHeatMapCluster"
  ) {
    return <MapIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
  }

  // Default icon
  return <GridViewIcon sx={{ fontSize: 40, color: "#0070d7" }} />;
};

// Draggable widget preview component for the sidebar
function DraggableWidgetPreview({ widget }) {
  const { attributes, listeners, setNodeRef, isDragging } = useDraggable({
    id: `sidebar-${widget.id}`,
    data: {
      type: "widget-preview",
      widgetId: widget.id,
      widgetData: widget,
    },
  });

  // Determine icon based on widget
  const widgetIcon = getWidgetIcon(widget);

  return (
    <Paper
      ref={setNodeRef}
      {...listeners}
      {...attributes}
      elevation={1}
      sx={{
        width: "100%",
        mb: 2,
        cursor: "grab",
        transition: "all 0.2s ease",
        opacity: isDragging ? 0.5 : 1,
        overflow: "hidden",
        borderRadius: "8px",
        "&:hover": {
          boxShadow: "0px 4px 8px rgba(0,0,0,0.1)",
          backgroundColor: "#f8f8f8",
        },
      }}
    >
      <Box sx={{ p: 2 }}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
          <DragIndicatorIcon
            sx={{
              mr: 1,
              cursor: "grab",
              color: "text.secondary",
              "&:hover": { color: "primary.main" },
            }}
          />
          <Typography
            variant="h6"
            sx={{ fontSize: "1.1rem", fontWeight: "bold" }}
          >
            {widget.title}
          </Typography>
        </Box>

        <Box sx={{ display: "flex", alignItems: "center" }}>
          <Box
            sx={{
              mr: 2,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: 60,
            }}
          >
            {widgetIcon}
          </Box>
          <Box>
            <Typography variant="body2" sx={{ color: "#333", mb: 0.5 }}>
              {widget.infoText}
            </Typography>
          </Box>
        </Box>
      </Box>
    </Paper>
  );
}

// Main sidebar component
const WidgetSidebar = ({ hiddenWidgets, isEditMode, toggleEditMode }) => {
  if (!isEditMode) return null;

  return (
    <Box
      sx={{
        width: "280px",
        height: "100%",
        borderLeft: "1px solid #e0e0e0",
        padding: 2,
        backgroundColor: "#fff",
        overflowY: "auto",
        boxShadow: "-2px 0px 5px rgba(0,0,0,0.05)",
        position: "absolute",
        top: 60,
        right: 0,
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Add Widgets
        </Typography>
        <Button
          size="small"
          variant="contained"
          color="primary"
          onClick={toggleEditMode}
          startIcon={<Done />}
        >
          Done
        </Button>
      </Box>
      <Divider sx={{ mb: 2 }} />

      {hiddenWidgets.length === 0 ? (
        <Typography variant="body2" color="textSecondary">
          All widgets are already on your dashboard
        </Typography>
      ) : (
        hiddenWidgets.map((widget) => (
          <DraggableWidgetPreview key={widget.id} widget={widget} />
        ))
      )}
    </Box>
  );
};

export default WidgetSidebar;
