import React from "react";
import PropTypes from "prop-types";
import { Box, Typography, Paper, Button, Divider, Chip } from "@mui/material";
import { Link } from "react-router-dom";
import { format } from "date-fns";

const MapAccountCard = ({ account, close, measurementUnit }) => {
  if (!account) return null;

  const { acc_id, name, premiseType, last_sale_date } = account.properties;
  const coordinates = account.geometry.coordinates;

  // Format the last sale date if it exists
  const formattedLastSaleDate = last_sale_date
    ? format(new Date(last_sale_date), "MMM dd, yyyy")
    : "No sales data";

  return (
    <Paper
      elevation={3}
      sx={{
        position: "absolute",
        top: 10,
        right: 10,
        zIndex: 1000,
        width: 300,
        p: 2,
        borderRadius: 1,
      }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={1}
      >
        <Typography variant="h5" component="h2" sx={{ fontWeight: "bold" }}>
          Account Details
        </Typography>
        <Button
          size="small"
          color="error"
          variant="text"
          onClick={close}
          sx={{ minWidth: 30, p: 0.5 }}
        >
          ✕
        </Button>
      </Box>

      <Divider sx={{ mb: 2 }} />

      <Typography variant="h6" gutterBottom>
        {name}
      </Typography>

      <Box my={1}>
        <Chip
          label={premiseType || "Unknown Premise Type"}
          color={"primary"}
          size="small"
          sx={{ mb: 1 }}
        />
      </Box>

      <Typography variant="body2" color="textSecondary" gutterBottom>
        <strong>Account ID:</strong> {acc_id}
      </Typography>

      <Typography variant="body2" color="textSecondary" gutterBottom>
        <strong>Last Sale:</strong> {formattedLastSaleDate}
      </Typography>

      <Typography variant="body2" color="textSecondary" gutterBottom>
        <strong>Coordinates:</strong> {coordinates[0].toFixed(4)},{" "}
        {coordinates[1].toFixed(4)}
      </Typography>

      <Box mt={2} display="flex" justifyContent="space-between">
        <Button
          component={Link}
          to={`/app/accountdetails/${acc_id}`}
          variant="contained"
          color="primary"
          size="small"
          fullWidth
        >
          Go to account
        </Button>
      </Box>
    </Paper>
  );
};

MapAccountCard.propTypes = {
  account: PropTypes.object.isRequired,
  close: PropTypes.func.isRequired,
  measurementUnit: PropTypes.object,
};

export default MapAccountCard;
