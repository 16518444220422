import React from "react";
import { Box, Typography, Button, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";

const ReportOverlay = ({ onDismiss, onRegenerate }) => {
  return (
    <Box
      sx={{
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(255, 255, 255, 0.8)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        maxHeight: "92vh",
        zIndex: 1,
      }}
    >
      <Box
        sx={{
          backgroundColor: "white",
          padding: 3,
          borderRadius: 2,
          boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.1)",
          position: "relative",
          maxWidth: "400px",
          textAlign: "center",
          marginBottom: 20,
        }}
      >
        <IconButton
          onClick={onDismiss}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: "text.secondary",
          }}
        >
          <Close />
        </IconButton>
        <Typography variant="h6" sx={{ mb: 2 }}>
          Report settings have changed
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
          The current report doesn't reflect your latest changes
        </Typography>
        <Button
          variant="contained"
          onClick={onRegenerate}
          sx={{
            bgcolor: "#1F449F",
            color: "white",
            "&:hover": { bgcolor: "#1a3b8a" },
          }}
        >
          Regenerate Report
        </Button>
      </Box>
    </Box>
  );
};

export default ReportOverlay;
