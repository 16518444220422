import {
  Box,
  Typography,
  CircularProgress,
  ToggleButtonGroup,
  ToggleButton,
  IconButton,
  Button,
  Modal,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Divider,
} from "@mui/material";
import { useState, useEffect } from "react";
import SalesTargetsBreakdown from "./SalesTargetsBreakdown";
import SalesTargetsGauge from "./SalesTargetsGauge";
import { useStore } from "../../../stores/StoreContext";
import distributorsAPI from "../../../api/distributors";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";

const SalesTargets = ({
  showBreakdown,
  size = { colSpan: 1 },
  widgetData = {},
  onWidgetConfigUpdate,
}) => {
  const { allDistributors, userInfo } = useStore();
  const distributorIdsUserHasAccessTo = allDistributors.map((item) => item.id);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [timeRange, setTimeRange] = useState(
    widgetData?.initialState?.timeRange || "MTD"
  );
  const [currentPage, setCurrentPage] = useState(0);
  const itemsPerPage = 4;
  const [openEditModal, setOpenEditModal] = useState(false);

  // Get current quarter (1-4)
  const getCurrentQuarter = () => {
    const month = new Date().getMonth();
    return Math.floor(month / 3) + 1;
  };

  const currentQuarter = getCurrentQuarter();

  const [editConfig, setEditConfig] = useState({
    monthTarget: widgetData?.salesTargets?.month || 2000,
    q1Target: widgetData?.salesTargets?.q1 || 4000,
    q2Target: widgetData?.salesTargets?.q2 || 6000,
    q3Target: widgetData?.salesTargets?.q3 || 6000,
    q4Target: widgetData?.salesTargets?.q4 || 10000,
    yearTarget: widgetData?.salesTargets?.year || 24000,
    showBreakdown: widgetData?.initialState?.showBreakdown || false,
    defaultTimeRange: widgetData?.initialState?.timeRange || "MTD",
  });

  // Fetch data from API once
  useEffect(() => {
    async function fetchData() {
      try {
        setLoading(true);
        const response = await distributorsAPI.getDistributorsSalesTotals({
          distributor_ids: distributorIdsUserHasAccessTo,
          user_id: userInfo.user_id,
        });

        setData(response.results);
      } catch (err) {
        console.log(err);
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  // Map data for the breakdown chart based on time period
  const getChartData = () => {
    if (!data || data.length === 0) return [];

    return data
      .map((item) => {
        if (timeRange === "YTD") {
          return {
            label: item.distributor,
            OnPrem: Number(item.year_on_prem || 0),
            OffPrem: Number(item.year_off_prem || 0),
            Total: Number(item.year_total || 0),
            dist_id: item.dist_id,
          };
        } else if (timeRange === "QTD") {
          return {
            label: item.distributor,
            OnPrem: Number(item.quarter_on_prem || 0),
            OffPrem: Number(item.quarter_off_prem || 0),
            Total: Number(item.quarter_total || 0),
            dist_id: item.dist_id,
          };
        } else {
          // MTD
          return {
            label: item.distributor,
            OnPrem: Number(item.month_on_prem || 0),
            OffPrem: Number(item.month_off_prem || 0),
            Total: Number(item.month_total || 0),
            dist_id: item.dist_id,
          };
        }
      })
      .filter((item) => item.Total > 0); // Only show distributors with data
  };

  // Calculate total number of pages
  const chartData = getChartData();
  const totalPages = Math.ceil(chartData.length / itemsPerPage);

  // Navigation handlers
  const handlePrevPage = () => {
    setCurrentPage((prev) => Math.max(0, prev - 1));
  };

  const handleNextPage = () => {
    setCurrentPage((prev) => Math.min(totalPages - 1, prev + 1));
  };

  useEffect(() => {
    // Reset page when time range changes
    setCurrentPage(0);
  }, [timeRange]);

  const handleTimeRangeChange = (event, newTimeRange) => {
    if (newTimeRange !== null) {
      setTimeRange(newTimeRange);
    }
  };

  // Get the appropriate total based on selected time period
  const getTotalByTimeRange = () => {
    if (!data || data.length === 0) return 0;

    if (timeRange === "YTD") {
      return data.reduce((acc, item) => acc + Number(item.year_total || 0), 0);
    } else if (timeRange === "QTD") {
      return data.reduce(
        (acc, item) => acc + Number(item.quarter_total || 0),
        0
      );
    } else {
      // MTD
      return data.reduce((acc, item) => acc + Number(item.month_total || 0), 0);
    }
  };

  const current = getTotalByTimeRange();
  const monthTarget = widgetData.salesTargets.month;
  const yearTarget = widgetData.salesTargets.year;

  // Get the appropriate quarter target based on current quarter
  const getQuarterTarget = () => {
    const quarterKey = `q${getCurrentQuarter()}`;
    return widgetData.salesTargets[quarterKey] || 0;
  };

  const quarterTarget = getQuarterTarget();

  // Calculate progress as a percentage (clamped to 100% max)
  const target =
    timeRange === "MTD"
      ? monthTarget
      : timeRange === "QTD"
      ? quarterTarget
      : yearTarget;

  const progress = Math.min(100, (current / target) * 100);

  const formatNumber = (number) => {
    if (number === null) {
      return 0;
    } else if (number > 1000) {
      return (number / 1000).toFixed(1) + "K";
    } else if (number > 1000000) {
      return (number / 1000000).toFixed(1) + "M";
    } else if (number > 1000000000) {
      return (number / 1000000000).toFixed(1) + "B";
    } else {
      return number;
    }
  };

  // Get paginated data
  const getCurrentPageData = () => {
    const startIdx = currentPage * itemsPerPage;
    const endIdx = Math.min(startIdx + itemsPerPage, chartData.length);
    return chartData.slice(startIdx, endIdx);
  };

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  const handleEditChange = (field) => (event) => {
    const value = field.includes("Target")
      ? Number(event.target.value)
      : field === "showBreakdown"
      ? event.target.value === "true"
      : event.target.value;

    setEditConfig({
      ...editConfig,
      [field]: value,
    });
  };

  const handleSaveConfig = () => {
    if (onWidgetConfigUpdate) {
      onWidgetConfigUpdate({
        data: {
          initialState: {
            timeRange: editConfig.defaultTimeRange,
            showBreakdown: editConfig.showBreakdown,
          },
          salesTargets: {
            month: editConfig.monthTarget,
            q1: editConfig.q1Target,
            q2: editConfig.q2Target,
            q3: editConfig.q3Target,
            q4: editConfig.q4Target,
            year: editConfig.yearTarget,
          },
        },
      });
    }
    handleCloseEditModal();
  };

  if (loading) {
    return (
      <Box
        sx={{ p: 3, borderRadius: 2, bgcolor: "white", textAlign: "center" }}
      >
        <CircularProgress />
      </Box>
    );
  }

  // Use different layouts based on colSpan
  const isWideLayout = size.colSpan === 2;

  return (
    <Box
      sx={{
        p: 3,
        borderRadius: 2,
        bgcolor: "white",
        textAlign: "center",
        mt: showBreakdown ? 6 : isWideLayout ? 6 : 0,
      }}
    >
      <Box
        sx={{
          mb: 2,
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <IconButton
          size="small"
          onClick={handleOpenEditModal}
          sx={{ mr: 1, color: "text.secondary" }}
          aria-label="Edit widget settings"
        >
          <EditIcon fontSize="small" />
        </IconButton>

        <ToggleButtonGroup
          value={timeRange}
          exclusive
          onChange={handleTimeRangeChange}
          aria-label="time range"
          size="small"
        >
          <ToggleButton value="YTD" aria-label="year">
            YTD
          </ToggleButton>
          <ToggleButton value="QTD" aria-label="quarter">
            QTD
          </ToggleButton>
          <ToggleButton value="MTD" aria-label="month">
            MTD
          </ToggleButton>
        </ToggleButtonGroup>

        {/* Pagination controls - only show in breakdown view and when multiple pages exist */}
        {(showBreakdown || isWideLayout) && totalPages > 1 && (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              ml: 2,
            }}
          >
            <IconButton
              onClick={handlePrevPage}
              disabled={currentPage === 0}
              size="small"
              sx={{
                color: currentPage === 0 ? "text.disabled" : "primary.main",
                p: 0.5,
              }}
            >
              <ArrowBackIosNewIcon fontSize="small" />
            </IconButton>

            <Typography sx={{ fontSize: 10 }}>
              {currentPage + 1} of {totalPages}
            </Typography>

            <IconButton
              onClick={handleNextPage}
              disabled={currentPage === totalPages - 1}
              size="small"
              sx={{
                color:
                  currentPage === totalPages - 1
                    ? "text.disabled"
                    : "primary.main",
                p: 0.5,
              }}
            >
              <ArrowForwardIosIcon fontSize="small" />
            </IconButton>
          </Box>
        )}
      </Box>

      {isWideLayout ? (
        // Side by side layout when colSpan is 2
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            gap: 4,
          }}
        >
          <Box sx={{ flex: 1 }}>
            <SalesTargetsGauge
              current={current}
              target={target}
              progress={progress}
              timeRange={timeRange}
              formatNumber={formatNumber}
            />
          </Box>
          <Box sx={{ flex: 1 }}>
            <SalesTargetsBreakdown
              data={getCurrentPageData()}
              target={target}
              timeRangeTitle={timeRange}
              formatNumber={formatNumber}
              totalItems={chartData.length}
              showPagination={false} // Don't show pagination in the chart component
            />
          </Box>
        </Box>
      ) : // Original toggle layout for colSpan = 1
      !showBreakdown ? (
        <SalesTargetsGauge
          current={current}
          target={target}
          progress={progress}
          timeRange={timeRange}
          formatNumber={formatNumber}
        />
      ) : (
        <SalesTargetsBreakdown
          data={getCurrentPageData()}
          target={target}
          timeRangeTitle={timeRange}
          formatNumber={formatNumber}
          totalItems={chartData.length}
          showPagination={false} // Don't show pagination in the chart component
        />
      )}

      {/* Configuration Modal */}
      <Modal
        open={openEditModal}
        onClose={handleCloseEditModal}
        aria-labelledby="sales-totals-config-modal"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
            borderRadius: 2,
          }}
        >
          <Typography
            id="sales-totals-config-modal"
            variant="h6"
            component="h2"
            sx={{ mb: 3 }}
          >
            Configure Sales Targets
          </Typography>

          <TextField
            fullWidth
            label="Monthly Target"
            type="number"
            value={editConfig.monthTarget}
            onChange={handleEditChange("monthTarget")}
            margin="normal"
          />

          <Divider sx={{ my: 1 }} />

          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Q1 Target"
              type="number"
              value={editConfig.q1Target}
              onChange={handleEditChange("q1Target")}
              margin="normal"
              sx={{ flex: 1 }}
            />

            <TextField
              label="Q2 Target"
              type="number"
              value={editConfig.q2Target}
              onChange={handleEditChange("q2Target")}
              margin="normal"
              sx={{ flex: 1 }}
            />
          </Box>

          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Q3 Target"
              type="number"
              value={editConfig.q3Target}
              onChange={handleEditChange("q3Target")}
              margin="normal"
              sx={{ flex: 1 }}
            />

            <TextField
              label="Q4 Target"
              type="number"
              value={editConfig.q4Target}
              onChange={handleEditChange("q4Target")}
              margin="normal"
              sx={{ flex: 1 }}
            />
          </Box>

          <Divider sx={{ my: 1 }} />

          <TextField
            fullWidth
            label="Yearly Target"
            type="number"
            value={editConfig.yearTarget}
            onChange={handleEditChange("yearTarget")}
            margin="normal"
          />

          <FormControl component="fieldset" sx={{ mt: 3 }}>
            <FormLabel component="legend">
              Default View (Only when widget is minimized)
            </FormLabel>
            <RadioGroup
              value={editConfig.showBreakdown.toString()}
              onChange={handleEditChange("showBreakdown")}
            >
              <FormControlLabel
                value="false"
                control={<Radio />}
                label="Total Gauge"
              />
              <FormControlLabel
                value="true"
                control={<Radio />}
                label="Distributor Breakdown"
              />
            </RadioGroup>
          </FormControl>

          <FormControl component="fieldset" sx={{ mt: 3 }}>
            <FormLabel component="legend">Default Time Period</FormLabel>
            <RadioGroup
              value={editConfig.defaultTimeRange}
              onChange={handleEditChange("defaultTimeRange")}
            >
              <FormControlLabel value="MTD" control={<Radio />} label="MTD" />
              <FormControlLabel value="QTD" control={<Radio />} label="QTD" />
              <FormControlLabel value="YTD" control={<Radio />} label="YTD" />
            </RadioGroup>
          </FormControl>

          <Box
            sx={{ mt: 4, display: "flex", justifyContent: "flex-end", gap: 2 }}
          >
            <Button onClick={handleCloseEditModal} color="inherit">
              Cancel
            </Button>
            <Button onClick={handleSaveConfig} variant="contained">
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

export default SalesTargets;
