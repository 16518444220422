export const states_and_codes = [
  { id: 1, state: "Army", code: "AA" },
  { id: 2, state: "Alaska", code: "AK" },
  { id: 3, state: "Alabama", code: "AL" },
  { id: 5, state: "Arizona", code: "AZ" },
  { id: 4, state: "Arkansas", code: "AR" },
  { id: 6, state: "California", code: "CA" },
  { id: 7, state: "Colorado", code: "CO" },
  { id: 8, state: "Connecticut", code: "CT" },
  { id: 9, state: "Washington DC", code: "DC" },
  { id: 10, state: "Delaware", code: "DE" },
  { id: 12, state: "Florida", code: "FL" },
  { id: 13, state: "Georgia", code: "GA" },
  { id: 14, state: "Hawaii", code: "HI" },
  { id: 16, state: "Idaho", code: "ID" },
  { id: 17, state: "Illinois", code: "IL" },
  { id: 18, state: "Indiana", code: "IN" },
  { id: 15, state: "Iowa", code: "IA" },
  { id: 19, state: "Kansas", code: "KS" },
  { id: 20, state: "Kentucky", code: "KY" },
  { id: 21, state: "Louisiana", code: "LA" },
  { id: 24, state: "Maine", code: "ME" },
  { id: 23, state: "Maryland", code: "MD" },
  { id: 22, state: "Massachusetts", code: "MA" },
  { id: 25, state: "Michigan", code: "MI" },
  { id: 26, state: "Minnesota", code: "MN" },
  { id: 28, state: "Mississippi", code: "MS" },
  { id: 27, state: "Missouri", code: "MO" },
  { id: 29, state: "Montana", code: "MT" },
  { id: 32, state: "Nebraska", code: "NE" },
  { id: 36, state: "Nevada", code: "NV" },
  { id: 33, state: "New Hampshire", code: "NH" },
  { id: 34, state: "New Jersey", code: "NJ" },
  { id: 35, state: "New Mexico", code: "NM" },
  { id: 37, state: "New York", code: "NY" },
  { id: 30, state: "North Carolina", code: "NC" },
  { id: 31, state: "North Dakota", code: "ND" },
  { id: 38, state: "Ohio", code: "OH" },
  { id: 39, state: "Oklahoma", code: "OK" },
  { id: 40, state: "Oregon", code: "OR" },
  { id: 41, state: "Pennsylvania", code: "PA" },
  { id: 42, state: "Rhode Island", code: "RI" },
  { id: 43, state: "South Carolina", code: "SC" },
  { id: 44, state: "South Dakota", code: "SD" },
  { id: 45, state: "Tennessee", code: "TN" },
  { id: 46, state: "Texas", code: "TX" },
  { id: 47, state: "Utah", code: "UT" },
  { id: 49, state: "Vermont", code: "VT" },
  { id: 48, state: "Virginia", code: "VA" },
  { id: 50, state: "Washington", code: "WA" },
  { id: 51, state: "Wisconsin", code: "WI" },
  { id: 52, state: "West Virginia", code: "WV" },
  { id: 53, state: "Wyoming", code: "WY" },
];

export const premise_types = [
  {
    premise_id: 1,
    premise_name: "BILLBACK",
  },
  {
    premise_id: 2,
    premise_name: "BREAKAGE",
  },
  {
    premise_id: 3,
    premise_name: "DISTRIBUTOR",
  },
  {
    premise_id: 4,
    premise_name: "DONATION",
  },
  {
    premise_id: 5,
    premise_name: "ECOMMERCE",
  },
  {
    premise_id: 6,
    premise_name: "EMPLOYEE",
  },
  {
    premise_id: 7,
    premise_name: "INVENTORYADJUSTMENT",
  },
  {
    premise_id: 8,
    premise_name: "OFF-PREMISE",
  },
  {
    premise_id: 9,
    premise_name: "ON-PREMISE",
  },
  {
    premise_id: 10,
    premise_name: "SAMPLE",
  },
  {
    premise_id: 11,
    premise_name: "SPOILAGE",
  },
  {
    premise_id: 12,
    premise_name: "SUPPLIER",
  },
  {
    premise_id: 13,
    premise_name: "TRANSFEROUT",
  },
  {
    premise_id: 14,
    premise_name: "UNKNOWN",
  },
  {
    premise_id: 15,
    premise_name: "WAREHOUSE",
  },
];

export const metrics = [
  { value: "cases", label: "Cases" },
  // { value: "units", label: "Units" },
  { value: "cases_eqv", label: "Cases Equivalence" },
  { value: "accounts_sold", label: "Accounts Sold" },
];

export const fieldGroups = {
  account: {
    title: "Account fields",
    fields: [
      {
        label: "Account Name",
        value: "account_name",
        groupIdMapping: "acc_id",
        unique: true,
      },
      {
        label: "Address",
        value: "account_address",
        groupIdMapping: "acc_id",
        unique: true,
      },
      {
        label: "City",
        value: "account_city",
        groupIdMapping: "acc_id",
      },
      {
        label: "Zip",
        value: "account_regioncode",
        groupIdMapping: "acc_id",
      },
      {
        label: "State",
        value: "account_region",
        groupIdMapping: "acc_id",
      },
      {
        label: "Premise Type",
        value: "premise_name",
        groupIdMapping: "premise_id",
      },
    ],
  },
  product: {
    title: "Product fields",
    fields: [
      {
        label: "Brand",
        value: "product_brand",
        groupIdMapping: "prod_id",
      },
      {
        label: "Product Name",
        value: "product_displayname",
        groupIdMapping: "prod_id",
        unique: true,
      },
      {
        label: "Category",
        value: "product_display_category",
        groupIdMapping: "prod_id",
      },
      // {
      //   label: "Size",
      //   value: "product_size",
      //   groupIdMapping: "prod_id",
      // },
      {
        label: "Retail Unit",
        value: "product_retail_unit",
        groupIdMapping: "prod_id",
      },
    ],
  },
  distributor: {
    title: "Distributor fields",
    fields: [
      {
        label: "Distributor Name",
        value: "distributor_name",
        groupIdMapping: "dist_id",
        unique: true,
      },
    ],
  },
  other: {
    title: "Additional",
    fields: [
      {
        label: "Rep Name",
        value: "account_repname",
        groupIdMapping: "rep_id",
        unique: true,
      },
    ],
  },
};

export const default_request_body = {
  select: {
    dimensions: [],
    measures: ["cases"],
  },
  filters: {
    dimensions: {
      premise_id: [8, 9],
    },
    time: {
      granularity: "month",
      offset:
        new Date().getFullYear().toString() + new Date().getMonth().toString(),
      periods: 1,
    },
  },
  group_by: [],
  comparisons: {
    includeTotals: true,
    includeDifferences: true,
    comparisonPeriod: true,
  },
  page: 1,
  pageSize: 1000,
};
