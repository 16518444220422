import APICallWrapper from "./APICallWrappers";
import client from "./client";

const getUsers = ({ mid, limit }) => {
  const endpoint = "/users";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const putUsers = async (user) => {
  const endpoint = "/users";
  const key = `putUsers-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating user...",
    "User has been updated successfully.",
    "Failed to updated user.",
    () => client.put(endpoint, user)
  );
};
const postUsers = (user) => {
  const endpoint = "/users";
  return client.post(endpoint, user);
};
const getUsersUID = ({ uid, mid, limit }) => {
  const endpoint = "/users/" + uid;
  return client.get(endpoint, { mid: mid, limit: limit });
};

export default {
  getUsers,
  putUsers,
  postUsers,
  getUsersUID,
};
