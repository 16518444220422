import { Box, Avatar } from "@mui/material";
import { useDroppable, useDraggable } from "@dnd-kit/core";

import { Move } from "react-feather";
import { Folder } from "@mui/icons-material";

const LibraryTableRowFolder = ({
  folder,
  selectionMode,
  rowFunctions,
  prStyle,
  storing,
}) => {
  const { setNodeRef, attributes, listeners, transform } = useDraggable({
    id: folder.folder_id,
    data: {
      type: "folder",
      ...folder,
    },
    disabled: storing,
  });

  const { setNodeRef: dropRef, isOver } = useDroppable({
    id: folder.folder_id,
    data: {
      type: "folder",
      ...folder,
    },
  });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
        ...prStyle.dragging,
      }
    : {};
  return (
    <Box
      ref={dropRef}
      style={{
        backgroundColor: isOver ? "rgba(100, 200, 50, 1)" : "rgba(0,0,0,0)",
        zIndex: transform ? 2 : 1,
      }}
    >
      <Box
        key={folder.file_id}
        display="flex"
        justifyContent={"flex-start"}
        alignContent={"center"}
        onClick={() => rowFunctions.handleRowClick(folder)}
        style={{
          ...style,
          position: "relative",
        }}
        ref={setNodeRef}
      >
        {selectionMode && <Box />}
        <Box style={prStyle.icon} p={1}>
          <Avatar>
            <Folder />
          </Avatar>
        </Box>
        <Box style={prStyle.name} p={1}>
          {folder.name}
        </Box>
        <Box p={1} style={prStyle.date}>
          {rowFunctions.formatDate(folder.created)}
        </Box>
        <Box p={1}>
          <Move {...attributes} {...listeners} />
        </Box>
      </Box>
    </Box>
  );
};

export default LibraryTableRowFolder;
