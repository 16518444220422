import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  Stack,
  TextField,
  Typography,
} from "@mui/material";

export const FieldDropdown = ({
  groupKey,
  fieldGroups,
  expandedSection,
  selectedFields,
  setExpandedSection,
  searchQueries,
  setSearchQueries,
  setSelectedFields,
}) => {
  const group = fieldGroups[groupKey];
  const isExpanded = expandedSection === groupKey;
  const selectedCount = selectedFields[groupKey].length;

  return (
    <Box sx={{ mb: 1 }}>
      <Button
        fullWidth
        onClick={() => setExpandedSection(isExpanded ? null : groupKey)}
        sx={{
          bgcolor: "#1F449F",
          color: "white",
          justifyContent: "space-around",
          p: 1,
          "&:hover": {
            bgcolor: "#1a3b8a",
          },
          borderRadius: 2,
          width: "90%",
          margin: "0 auto",
          display: "flex",
        }}
        endIcon={
          <ExpandMore
            sx={{
              transform: isExpanded ? "rotate(180deg)" : "none",
              transition: "transform 0.2s",
            }}
          />
        }
      >
        <div />
        <Typography fontSize={16} fontWeight={"semibold"}>
          {`${group.title} - ${selectedCount}/${group.fields.length}`}
        </Typography>
      </Button>

      {isExpanded && (
        <div
          style={{
            marginTop: 10,
            padding: 20,
            maxHeight: "600px",
            overflowY: "auto",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              mb: 2,
            }}
          >
            <TextField
              size="small"
              placeholder="Search fields..."
              sx={{ flex: 1 }}
              value={searchQueries[groupKey]}
              onChange={(e) => {
                setSearchQueries((prev) => ({
                  ...prev,
                  [groupKey]: e.target.value,
                }));
              }}
            />
            {selectedCount > 0 && (
              <Button
                sx={{ ml: 1, color: "#1F449F" }}
                onClick={() => {
                  setSelectedFields((prev) => ({
                    ...prev,
                    [groupKey]: [],
                  }));
                }}
              >
                Clear Selected
              </Button>
            )}
          </Box>
          <Stack spacing={1}>
            {group.fields
              .filter((field) =>
                field.label
                  .toLowerCase()
                  .includes(searchQueries[groupKey].toLowerCase())
              )
              .map((field) => (
                <Box
                  key={field.value}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <Checkbox
                    checked={selectedFields[groupKey].includes(field.value)}
                    onChange={(e) => {
                      setSelectedFields((prev) => ({
                        ...prev,
                        [groupKey]: e.target.checked
                          ? [...prev[groupKey], field.value]
                          : prev[groupKey].filter((v) => v !== field.value),
                      }));
                    }}
                  />
                  <Box>
                    <Typography>{field.label}</Typography>
                    <Typography variant="caption" color="textSecondary">
                      {group.title.replace(" fields", "")}
                    </Typography>
                  </Box>
                </Box>
              ))}
          </Stack>
        </div>
      )}
    </Box>
  );
};
