import { Box, Button, Grid2, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

const EditDistributors = ({ distributor, setDistributor }) => {
  const [submitInProgress, setSubmitInProgress] = useState(false);
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    reset(distributor);
  }, [distributor]);

  const handleChange = (event) => {
    setDistributor({
      ...distributor,
      [event.target.name]: event.target.value,
    });
  };

  const onSubmit = (data) => {};

  return (
    <Box mt={3}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid2 container spacing={3}>
          <Grid2 size={12}>
            <Controller
              name="dist_displayname"
              // defaultValue sets the value of child component
              defaultValue={distributor.dist_displayname || ""}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Name"
                  variant="outlined"
                  // handleChange must be on the child component to work as normal
                  onChange={handleChange}
                  fullWidth
                  error={errors.dist_displayname ? true : false}
                  helperText={`${
                    errors.dist_displayname?.type === "required"
                      ? "Name is required"
                      : ""
                  }
                      ${
                        errors.dist_displayname?.type === "minLength"
                          ? "Name must be at least 2 characters"
                          : ""
                      }`}
                />
              )}
              rules={{
                required: true,
                minLength: 2,
              }}
            />
          </Grid2>
          <Grid2 size={12}>
            <Controller
              name="description"
              // defaultValue sets the value of child component
              defaultValue={distributor?.description || ""}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Description"
                  variant="outlined"
                  // handleChange must be on the child component to work as normal
                  onChange={handleChange}
                  fullWidth
                  multiline
                  rows={5}
                  error={errors.name ? true : false}
                  helperText={`
                    ${
                      errors.description?.type === "minLength"
                        ? "Description must be at least 2 characters"
                        : ""
                    }
                      `}
                  rules={{
                    minLength: 2,
                  }}
                />
              )}
            />
          </Grid2>
          <Box ml={1.1}>
            <Button
              disabled={submitInProgress}
              variant="contained"
              type="submit"
              title="Save"
              color="primary"
            >
              Save
            </Button>
          </Box>
        </Grid2>
      </form>
    </Box>
  );
};
export default EditDistributors;
