const reportBuilder = {
  reportBuilderBrands: {},
  setReportBuilderBrands(data) {
    this.reportBuilderBrands = data;
  },
  reportBuilderDistributors: [],
  setReportBuilderDistributors(data) {
    this.reportBuilderDistributors = data;
  },
  resultSets: {},
  addResultSet({ hash, list }) {
    let rs = { ...this.resultSets };
    rs[hash] = list;
    this.resultSets = rs;
  },
  addResultSetItems({ hash, list }) {
    this.resultSets = {
      ...this.resultSets,
      [hash]: this.resultSets[hash].concat(list),
    };
  },
};

export default reportBuilder;
