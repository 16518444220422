import client from "./client";
const getAvailableRegions = ({ country_code = "us" }) => {
  const endpoint = `/accounts/regions?country_code=${country_code}`;
  return client.get(endpoint);
};
const getAccountListRegions = ({ list_id }) => {
  const endpoint = `/accounts/lists/${list_id}/regions`;
  return client.get(endpoint);
};
// /accounts/lists_by_region?mid=27
const getAccountListsByRegion = ({ manufacturer_id }) => {
  const endpoint = `/accounts/lists_by_region/?mid=${manufacturer_id}`;
  return client.get(endpoint);
};
const makeAccountListRegionAssoc = ({ list_id, region_id }) => {
  const endpoint = `/accounts/lists/${list_id}/regions`;
  return client.post(endpoint, {
    region_id,
  });
};

const removeAccountListRegionAssoc = ({ list_id, assoc_id }) => {
  const endpoint = `/accounts/lists/${list_id}/regions/${assoc_id}`;
  return client.del(endpoint);
};

export default {
  getAvailableRegions,
  getAccountListRegions,
  getAccountListsByRegion,
  makeAccountListRegionAssoc,
  removeAccountListRegionAssoc,
};
