import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Paper } from "@mui/material";
import { AccountBalance, Assessment, Description } from "@mui/icons-material";
import { useStore } from "../../../stores/StoreContext";
import Page from "../../../components/Page";

const ReportLanding = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { userInfo } = useStore();
  const { manufacturer_id, user_id } = userInfo;
  const buttonStyles = {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center", // Center content vertically
    width: "220px", // Fixed width for uniformity
    height: "200px", // Fixed height for uniformity
    padding: "30px 40px",
    margin: "15px",
    fontSize: "1.2rem",
    cursor: "pointer",
    textAlign: "center",
    backgroundColor: "#007BFF",
    color: "#FFFFFF",
    border: "none",
    borderRadius: "12px",
    boxShadow: "0px 5px 15px rgba(0, 0, 0, 0.15)",
    transition: "transform 0.2s, backgroundColor 0.2s",
  };

  const hoverStyles = {
    ...buttonStyles,
    transform: "scale(1.05)",
    backgroundColor: "#0056b3",
  };

  const iconStyles = {
    fontSize: "2.8rem",
    marginBottom: "12px",
  };

  const buttonContainerStyles = {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "20px",
    paddingTop: "60px",
  };

  const handleButtonClick = (route) => {
    console.log(route);
    if (!route.startsWith("/app")) {
      navigate(`${location.pathname}${route}`);
    } else {
      navigate(route);
    }
  };

  const ButtonWithHover = ({ route, icon: Icon, label, disabled = false }) => {
    const [hover, setHover] = React.useState(false);

    return (
      <button
        disabled={disabled}
        style={hover ? hoverStyles : buttonStyles}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        onClick={() => handleButtonClick(route)}
      >
        <Icon style={iconStyles} />
        {label}
      </button>
    );
  };

  return (
    <Page title="Reports">
      <Box component={Paper} elevation={3} p={3} m={3} borderRadius={8}>
        <Typography variant="h4" gutterBottom>
          Available Reports
        </Typography>

        <Box style={buttonContainerStyles}>
          {manufacturer_id === 39 && (
            <ButtonWithHover
              route="/app/depletions/us"
              icon={Assessment}
              label="Reports"
            />
          )}

          <ButtonWithHover
            route="/report-builder"
            icon={Assessment}
            label="Report Builder (Beta)"
          />

          <ButtonWithHover
            route="/gapreport"
            icon={Description}
            label="120 Day Gap Report"
          />
          <ButtonWithHover
            route="/accounts-sold"
            icon={AccountBalance}
            label="Accounts Sold"
          />
        </Box>
      </Box>
    </Page>
  );
};

export default ReportLanding;
