import { useState, useEffect } from "react";
import { useStore } from "../../stores/StoreContext";
import _ from "lodash";
import { Forum } from "@mui/icons-material";

const Footer = () => {
  const [ready, setReady] = useState(false);
  const [visible, setVisible] = useState(false);
  const { userInfo } = useStore();

  useEffect(() => {
    if (ready && !visible) {
      setVisible(true);
    }
  }, [ready]);
  const checkReady = () => {
    if (window.HubSpotConversations) {
      if (!ready) setReady(true);
    } else {
      window.hsConversationsOnReady = [
        () => {
          if (!ready) setReady(true);
        },
      ];
    }
  };

  useEffect(() => {
    if (userInfo && ready) {
      const { user_email } = userInfo;
      const _hsq = (window._hsq = window._hsq || []);
      _hsq.push([
        "identify",
        {
          email: user_email,
        },
      ]);
      _hsq.push(["trackPageView"]);
    }
  }, [userInfo, ready]);

  checkReady();

  const clickMarker = () => {
    if (window.HubSpotConversations) {
      window.HubSpotConversations.widget.open();
    } else {
      console.log("Hubspot chat not ready yet");
    }
  };
  return (
    <div className="footer">
      <div className="st-hs-trigger" onClick={clickMarker}>
        {visible && <Forum color="primary" className="st-hs-trigger-icon" />}
      </div>
    </div>
  );
};

export default Footer;
