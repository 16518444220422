import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  Box,
  LinearProgress,
} from "@mui/material";
import { useEffect, useState } from "react";
import statsAPI from "../../../api/stats";
const TopAccountProducts = ({
  acc_id,
  productHash,
  setProductHash,
  dists,
  dateStart,
  dateEnd,
}) => {
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const hash = productHash["p_" + acc_id];
    if (hash) {
      setProducts(hash);
    } else if (!products.length) {
      setLoading(true);
      statsAPI
        .getAccountTopProducts({ acc_id, dists, dateStart, dateEnd })
        .then(({ results }) => {
          setProductHash({
            ...productHash,
            ["p_" + acc_id]: results,
          });
        })
        .catch((err) => console.error(err))
        .finally(() => setLoading(false));
    }
  }, [acc_id, productHash, setProductHash]);
  return (
    <Box>
      {loading && <LinearProgress />}
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Top Products</TableCell>
            <TableCell>Physical Case</TableCell>
            <TableCell>Case Eqv</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {products.map((account) => {
            const { acc_id, prod_name, cases, cases_eqv } = account;
            return (
              <TableRow key={acc_id}>
                <TableCell>{prod_name}</TableCell>
                <TableCell>{cases}</TableCell>
                <TableCell>{cases_eqv}</TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </Box>
  );
};

export default TopAccountProducts;
