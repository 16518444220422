import React, { useEffect, useState } from "react";
import { Paper, Card } from "@mui/material";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import distributorsAPI from "../../api/distributors";
import sampleData from "./sugarlands_dummy_inventory_data";

// 1) Import from DataGrid Premium
import { DataGridPremium, GridToolbarExport } from "@mui/x-data-grid-premium";

const InventoryView = observer(() => {
  const store = useStore();
  const { userInfo } = store;

  const [data, setData] = useState([]);

  // Row grouping state ok:
  const [rowGroupingModel, setRowGroupingModel] = useState([
    "dist_displayname",
  ]);

  // Pagination state:
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50,
  });

  // 2) Define your custom aggregation functions in an object
  const aggregationFunctions = {
    sum: {
      label: "Sum",
      apply: (params) => {
        // Sum up all numeric values (ignoring null/undefined)
        return params.values.reduce((acc, value) => acc + (value || 0), 0);
      },
    },
    firstValue: {
      label: "First Value",
      apply: (params) => {
        // Return the first non-empty value in the group
        return params.values.length > 0 ? params.values[0] : null;
      },
    },
  };

  // 3) Define columns, using the correct `(params) => …` signature
  const columns = [
    {
      field: "product_displayname",
      headerName: "Product Name",
      width: 400,
    },
    {
      field: "dist_displayname",
      headerName: "Distributor",
      width: 220,
    },
    {
      field: "location_name",
      headerName: "Location Name",
      width: 220,
    },
    {
      field: "onfloor_inventory",
      headerName: "On Floor Inventory",
      type: "number",
      width: 170,
      // Example: If you want to display it in a custom way, you can:
      // valueFormatter: (params) => `${params.value} units`,
    },
    {
      field: "average_daily_sales",
      headerName: "Average Daily Sales",
      type: "number",
      width: 170,
    },
    {
      field: "days_on_hand",
      headerName: "Days on Hand",
      type: "number", // Important so it can be summed
      width: 150,
    },
    {
      field: "created",
      headerName: "Last Updated",
      type: "date",
      width: 200,
      // Convert raw value to a Date
      valueGetter: (value, row) => {
        if (!row?.created) return null;
        return new Date(row.created);
      },
    },
  ];

  // 4) Fetch data
  const getInventory = () => {
    const { manufacturer_id } = userInfo;
    if (manufacturer_id === 621) {
      setData(sampleData);
      console.log("Using sample data for manufacturer_id 621");
    } else if (data.length === 0) {
      distributorsAPI
        .getDistributorsInventoryV2(manufacturer_id)
        .then((results) => {
          console.log(results);
          setData(results);
        })
        .catch((error) => {
          console.error("Error fetching inventory data:", error);
        });
    }
  };

  useEffect(() => {
    getInventory();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Paper
      style={{
        display: "flex",
        width: "100%",
        padding: "10px",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      <Card style={{ height: 700, width: "95%", marginTop: 20 }}>
        <DataGridPremium
          // 5) Basic setup
          rows={data}
          columns={columns}
          getRowId={(row) =>
            row.id ??
            `${row.dist_displayname}-${row.location_name}-${row.product_displayname}`
          }
          // Row grouping
          rowGroupingModel={rowGroupingModel}
          onRowGroupingModelChange={setRowGroupingModel}
          groupingColDef={{ headerName: "Group By" }}
          // 6) Aggregation
          aggregationFunctions={aggregationFunctions}
          aggregationModel={{
            days_on_hand: "sum",
            created: "firstValue",
          }}
          // If you want to show aggregates in the footer:
          aggregationPosition="footer"
          // Sorting & pagination
          pagination
          paginationMode="client"
          pageSizeOptions={[50, 100, 200]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          initialState={{
            sorting: {
              sortModel: [{ field: "onfloor_inventory", sort: "desc" }],
            },
          }}
          // 7) Use `slots` rather than `components` if you want export, etc.
          slots={{
            toolbar: GridToolbarExport,
          }}
          // 8) Enable row grouping (per the new library pattern)
          experimentalFeatures={{ rowGrouping: true }}
        />
      </Card>
    </Paper>
  );
});

export default InventoryView;
