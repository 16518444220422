import React, { useState } from "react";
import { API } from "aws-amplify";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  CardHeader,
  Card,
  CardContent,
  InputLabel,
  Grid2,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "auto",
    backgroundColor: theme.palette.background.paper,
  },
  formControl: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

const VisitDetails = ({
  className,
  selectedValue,
  onClose,
  visitList,
  setVisitList,
  ...rest
}) => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const { userInfo } = store;

  const [values, setValues] = useState({
    account_id: id,
    user_id: selectedValue.user_id || userInfo.uid,
    manufacturer_id: selectedValue.manufacturer_id || userInfo.mid,
    created: selectedValue.created || new Date(),
    visit_purpose: selectedValue.visit_purpose || "",
    visit_contact: selectedValue.visit_contact || "",
    visit_notes: selectedValue.visit_notes || "",
    visit_id: selectedValue.visit_id || null,
  });
  async function deleteVisit(visitId) {
    setLoading(true);
    try {
      await API.del("backendGateway", "/visits", {
        queryStringParameters: {
          visit_id: visitId,
        },
      });
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setVisitList(visitList.filter((x) => x.visit_id !== visitId));

    setLoading(false);
    onClose();
  }

  async function handleSave() {
    setLoading(true);

    try {
      const apiName = "backendGateway";
      const path = "/visits";
      const myInit = {
        body: values,
        headers: {},
      };

      if (values.visit_id !== null) {
        console.log(myInit);
        await API.patch(apiName, path, myInit);
        const objIndex = visitList.findIndex(
          (obj) => obj.visit_id === values.visit_id
        );
        visitList[objIndex] = values;
        setVisitList(visitList);
      } else {
        const data = await API.post(apiName, path, myInit);

        const newVisit = {
          ...values,
          visit_id: data.results,
        };
        setVisitList([...visitList, newVisit]);
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
    onClose();
  }

  const handleChange = (event) => {
    const checks = [
      "brand_opportunity_display",
      "brand_opportunity_collaboration",
      "brand_opportunity_locator",
    ];
    let update = event.target.value;
    if (checks.includes(event.target.name)) {
      update = event.target.checked;
    }

    setValues({
      ...values,
      [event.target.name]: update,
    });
  };
  return (
    <form
      autoComplete="off"
      noValidate
      className={clsx(classes.root, className)}
      {...rest}
    >
      <Card>
        <CardHeader title="Visit Overview" />
        <CardContent>
          <Grid2 container spacing={3}>
            <Grid2 size={12}>
              <InputLabel id="demo-simple-select-outlined-label">
                Visit Purpose
              </InputLabel>
              <div>{values.visit_purpose}</div>
            </Grid2>
            <Grid2 size={12}>
              <InputLabel id="demo-simple-select-outlined-label">
                Who Did You Visit?
              </InputLabel>
              <div>{values.visit_contact}</div>
            </Grid2>
            <Grid2 size={12}>
              <InputLabel id="demo-simple-select-outlined-label">
                Note
              </InputLabel>
              <div>{values.visit_notes}</div>
            </Grid2>
          </Grid2>
        </CardContent>
      </Card>
    </form>
  );
};

VisitDetails.propTypes = {
  className: PropTypes.string,
};

export default VisitDetails;
