import React, { useEffect, useMemo, useState } from "react";
import {
  Box,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Button,
  Card,
} from "@mui/material";
import statsAPI from "../../api/stats";
import { useStore } from "../../stores/StoreContext";
import { DateTime } from "luxon";
import useQueryParamsBuilder from "../megaFilter/useQueryParamsBuilder";
// Uncomment these for the trending icons
import { TrendingDown, TrendingUp, FileDownload } from "@mui/icons-material";

const load = ({
  mid,
  callBack,
  premiseTypes,
  dists,
  selectedQuantity,
  setLoading,
}) => {
  setLoading(true);
  statsAPI
    .getSummary({
      mid,
      premiseTypes,
      dists,
      selectedQuantity,
    })
    .then(({ results }) => {
      callBack(results);
    })
    .catch((r) => console.log(r))
    .finally(() => setLoading(false));
};

const SummaryTotalComparison = React.memo(({ reportLoaded, height, size }) => {
  const [boxes, setBoxes] = useState([]);
  const [td, setTd] = useState(null);
  const [loading, setLoading] = useState(false);

  const { queryParams } = useQueryParamsBuilder();
  const {
    selectedQuantity,
    quantities,
    manufacturer,
    premiseTypes,
    selectedDistributors,
  } = useStore();

  useEffect(() => {
    if (td) {
      reportLoaded("summary");
    }
  }, [td, reportLoaded]);

  useMemo(() => {
    if (selectedDistributors.length > 0) {
      load({
        mid: manufacturer.manufacturer_id,
        premiseTypes,
        dists: selectedDistributors,
        ...queryParams,
        callBack: setTd,
        setLoading,
        selectedQuantity,
      });
    }

    return () => {
      // Cleanup logic here
      setLoading(false);
    };
  }, [
    manufacturer,
    premiseTypes,
    selectedQuantity,
    selectedDistributors,
    queryParams,
  ]);

  useEffect(() => {
    if (!td) return;

    // Percentage difference helper
    const pd = (now, then) => {
      if (!then) return 0;
      return Math.floor(((now - then) / (then || 1)) * 100);
    };

    // Just for label in table
    const makeWindowStrings = (range) => {
      return range
        .map((r) => DateTime.fromISO(r).toFormat("dd LLL yy"))
        .join(" - ");
    };

    setBoxes([
      {
        name: "threeMonths",
        label: "Last 90 Days",
        current: td.last_3_months_case_sales,
        previous: td.previous_3_months_case_sales,
        percentDiff: pd(
          td.last_3_months_case_sales,
          td.previous_3_months_case_sales
        ),
        intDiff:
          Math.floor(
            (td.last_3_months_case_sales - td.previous_3_months_case_sales) *
              100
          ) / 100,
        windowStrings: {
          current: makeWindowStrings([
            td.dateBoundaries.current.threeMonths.start,
            td.dateBoundaries.current.threeMonths.end,
          ]),
          previous: makeWindowStrings([
            td.dateBoundaries.previous.threeMonths.start,
            td.dateBoundaries.previous.threeMonths.end,
          ]),
        },
      },
      {
        name: "twelveMonths",
        label: "Last 12 Months",
        current: td.current_12_months_case_sales,
        previous: td.previous_12_months_case_sales,
        percentDiff: pd(
          td.current_12_months_case_sales,
          td.previous_12_months_case_sales
        ),
        intDiff:
          Math.floor(
            (td.current_12_months_case_sales -
              td.previous_12_months_case_sales) *
              100
          ) / 100,
        windowStrings: {
          current: makeWindowStrings([
            td.dateBoundaries.current.twelveMonths.start,
            td.dateBoundaries.current.twelveMonths.end,
          ]),
          previous: makeWindowStrings([
            td.dateBoundaries.previous.twelveMonths.start,
            td.dateBoundaries.previous.twelveMonths.end,
          ]),
        },
      },
      {
        name: "qtd",
        label: "QTD",
        current: td.current_qtd_case_sales,
        previous: td.previous_qtd_case_sales,
        percentDiff: pd(td.current_qtd_case_sales, td.previous_qtd_case_sales),
        intDiff:
          Math.floor(
            (td.current_qtd_case_sales - td.previous_qtd_case_sales) * 100
          ) / 100,
        windowStrings: {
          current: makeWindowStrings([
            td.dateBoundaries.current.qtd.start,
            td.dateBoundaries.current.qtd.end,
          ]),
          previous: makeWindowStrings([
            td.dateBoundaries.previous.qtd.start,
            td.dateBoundaries.previous.qtd.end,
          ]),
        },
      },
      {
        name: "ytd",
        label: "YTD",
        current: td.current_ytd_case_sales,
        previous: td.previous_ytd_case_sales,
        percentDiff: pd(td.current_ytd_case_sales, td.previous_ytd_case_sales),
        intDiff:
          Math.floor(
            (td.current_ytd_case_sales - td.previous_ytd_case_sales) * 100
          ) / 100,
        windowStrings: {
          current: makeWindowStrings([
            td.dateBoundaries.current.ytd.start,
            td.dateBoundaries.current.ytd.end,
          ]),
          previous: makeWindowStrings([
            td.dateBoundaries.previous.ytd.start,
            td.dateBoundaries.previous.ytd.end,
          ]),
        },
      },
    ]);
  }, [td]);

  const quantityText =
    quantities.find((q) => q.value === selectedQuantity)?.text || "";

  // Add export function
  const handleExportTable = () => {
    if (!boxes.length) return;

    // Create CSV content
    const headers = ["Period", "Sales", "Prev Year", "% Change", "Amount"];
    const csvContent = [
      headers.join(","),
      ...boxes.map((p) =>
        [
          p.label,
          p.current,
          p.previous,
          `${p.percentDiff > 0 ? "+" : ""}${p.percentDiff}%`,
          `${p.intDiff > 0 ? "+" : ""}${p.intDiff} ${quantityText}`,
        ].join(",")
      ),
    ].join("\n");

    // Create and download the file
    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8;" });
    const url = URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute(
      "download",
      `sales_summary_${DateTime.now().toFormat("yyyy-MM-dd")}.csv`
    );
    link.style.visibility = "hidden";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <Box
      sx={{
        p: 3,
        width: "100%",
        maxHeight: height,
        overflowY: "hidden",
        boxSizing: "border-box",
        overscrollBehavior: "contain",
      }}
    >
      {loading && <LinearProgress />}

      <TableContainer
        component={Card}
        sx={{ borderRadius: 2, overflow: "hidden" }}
      >
        <Table size="small">
          <TableHead sx={{ bgcolor: "#f5f5f5" }}>
            <TableRow>
              <TableCell>Period</TableCell>
              <TableCell>Sales</TableCell>
              <TableCell>Prev Year</TableCell>
              <TableCell>% Change</TableCell>
              <TableCell>Amount</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {boxes.map((p) => (
              <TableRow key={p.name}>
                <TableCell>
                  <strong>{p.label}</strong>
                  <br />
                  <Typography variant="caption">
                    {p.windowStrings.current}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Typography fontWeight="bold">
                    {p.current.toLocaleString(undefined, { timeZone: "UTC" })}
                  </Typography>
                </TableCell>
                <TableCell>
                  {p.previous.toLocaleString(undefined, { timeZone: "UTC" })}
                </TableCell>
                <TableCell>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    {p.percentDiff > 0 ? (
                      <TrendingUp sx={{ color: "green", mr: 0.5 }} />
                    ) : p.percentDiff < 0 ? (
                      <TrendingDown sx={{ color: "red", mr: 0.5 }} />
                    ) : null}
                    {p.percentDiff > 0 ? "+" : ""}
                    {p.percentDiff}%
                  </Box>
                </TableCell>
                <TableCell>
                  {p.intDiff > 0 ? "+" : ""}
                  {p.intDiff.toLocaleString(undefined, {
                    timeZone: "UTC",
                  })}{" "}
                  {quantityText}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box>
        <Button
          variant="outlined"
          startIcon={<FileDownload />}
          size="small"
          sx={{ borderRadius: 8, mt: 2 }}
          onClick={handleExportTable}
        >
          Export Table
        </Button>
      </Box>
    </Box>
  );
});

export default SummaryTotalComparison;
