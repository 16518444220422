import { useState } from "react";

const useStorage = (apiFunc) => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);

  const filter = (field, value) => {
    if (value !== "") {
      let filtered = data.filter((x) =>
        x[field].toLowerCase().includes(value.toLowerCase())
      );
      return filtered;
    }
  };
  async function download(key, id, download, nameOverride) {
    setLoading(true);
    const result = await apiFunc(key, id, download, nameOverride);
    var path2Filename = key.split("/");
    var filename = nameOverride || path2Filename[path2Filename.length - 1];
    downloadFile(result.Body, filename);
    setLoading(false);
  }
  function downloadFile(blob, filename) {
    console.log({ blob, filename });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename || "download";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  }
  async function getSignedUrl(...args) {
    const result = await apiFunc(...args);
    return result;
  }
  async function remove(...args) {
    setLoading(true);
    let response = [];
    try {
      response = await apiFunc(...args);
    } catch (e) {
      // console.log(e);
      setError(true);
    }
    setLoading(false);
    return response;
  }

  async function request(...args) {
    setLoading(true);
    let response = [];
    try {
      response = await apiFunc(...args);
    } catch (e) {
      console.log(e);
      setError(true);
    }
    setLoading(false);
    setData(response);

    return response;
  }

  return {
    data,
    error,
    filter,
    loading,
    request,
    getSignedUrl,
    download,
    remove,
  };
};
export default useStorage;
