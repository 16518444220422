import { ExpandMore } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  Button,
  ButtonGroup,
  Tooltip,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { sectionHeaderStyle } from "../styles";
import { DateTime } from "luxon";
import InfoIcon from "@mui/icons-material/Info";
import { useMemo } from "react";

const QuarterSelector = ({ value, onChange }) => {
  // For Q1 2025 => value is 20251 (year * 10 + quarter)
  // e.g. parseInt("20251") => 20251
  const intValue = value ? parseInt(value, 10) : null;
  const year = intValue ? Math.floor(intValue / 10) : DateTime.now().year;
  const quarter = intValue ? intValue % 10 : 1;

  return (
    <Box sx={{ alignItems: "center", gap: 1 }}>
      <Select
        value={year}
        onChange={(e) => onChange((e.target.value * 10 + quarter).toString())}
        size="small"
        sx={{ width: 200, mb: 2 }}
      >
        {Array.from({ length: 10 }, (_, i) => {
          const yearOption = DateTime.now().year - 5 + i;
          return (
            <MenuItem key={yearOption} value={yearOption}>
              {yearOption}
            </MenuItem>
          );
        })}
      </Select>
      <ButtonGroup sx={{ ml: 1 }}>
        {[1, 2, 3, 4].map((q) => (
          <Button
            key={q}
            variant={quarter === q ? "contained" : "outlined"}
            onClick={() => onChange((year * 10 + q).toString())}
            sx={{
              minWidth: "45px",
              bgcolor: quarter === q ? "#1F449F" : "white",
              color: quarter === q ? "white" : "#1F449F",
              "&:hover": {
                bgcolor: quarter === q ? "#1a3b8a" : "#f5f5f5",
              },
            }}
          >
            Q{q}
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export const DateRangeFilter = ({
  requestBody,
  onChange,
  selectedMetric,
  setSelectedMetric,
  metrics,
}) => {
  const settings = requestBody.filters.time;
  const comparisons = requestBody.comparisons;

  const handleSettingChange = (key, value) => {
    onChange({
      ...requestBody,
      filters: {
        ...requestBody.filters,
        time: {
          ...settings,
          [key]: value,
        },
      },
    });
  };

  const handleComparisonChange = (key, value) => {
    onChange({
      ...requestBody,
      comparisons: {
        ...comparisons,
        [key]: value,
      },
    });
  };

  /**
   * Parse the old offset from string → DateTime (using old granularity)
   */
  const parseOffset = (offset, granularity) => {
    if (!offset) return null;

    if (granularity === "quarter") {
      // stored as "20251" => year=2025, quarter=1
      const intVal = parseInt(offset, 10);
      const year = Math.floor(intVal / 10);
      const quarter = intVal % 10;
      // approximate month => Q1=1, Q2=4, Q3=7, Q4=10
      const month = (quarter - 1) * 3 + 1;
      return DateTime.fromObject({ year, month });
    } else if (granularity === "year") {
      // stored as "2025"
      return DateTime.fromFormat(offset, "yyyy");
    } else if (granularity === "month") {
      // stored as "202501" => Jan 2025
      return DateTime.fromFormat(offset, "yyyyMM");
    }
    return null;
  };

  /**
   * Convert DateTime → string offset (using new granularity)
   */
  const convertToNewOffset = (dateObj, newGranularity) => {
    // If no valid date, fallback to "now"
    const dt = dateObj?.isValid ? dateObj : DateTime.now();

    if (newGranularity === "year") {
      // e.g. "2025"
      return dt.toFormat("yyyy");
    } else if (newGranularity === "quarter") {
      // e.g. 20251 => 2025 Q1
      const q = Math.ceil(dt.month / 3);
      return dt.toFormat("yyyy") + q; // "2025" + "1" => "20251"
    } else {
      // month => "202501"
      return dt.toFormat("yyyyMM");
    }
  };

  const pickerValue = useMemo(() => {
    let offsetNum = Number.isInteger(settings.offset)
      ? settings.offset
      : parseInt(settings.offset, 10);
    // single digit months need to have their value changed to have zero padding
    if (offsetNum < 100000) {
      let y = Math.floor(offsetNum / 10);
      let m = offsetNum % 10;
      offsetNum = y * 100 + m;
    }
    try {
      const o = offsetNum
        ? settings.granularity === "year"
          ? DateTime.fromFormat(offsetNum.toString(), "yyyy")
          : DateTime.fromFormat(offsetNum.toString(), "yyyyMM")
        : null;
      return o;
    } catch (error) {
      console.error(error);
      console.error(offsetNum, "is not valid for DateTime.fromFormat");
    }
  }, [settings]);

  return (
    <Box sx={{ mt: 3, borderBottom: "1px solid #EAEAEA", pb: 4 }}>
      <Typography style={sectionHeaderStyle} sx={{ mb: 1 }}>
        Select date period
      </Typography>

      {/* GRANULARITY SELECT */}
      <Select
        value={settings?.granularity || "month"}
        onChange={(e) => {
          const oldGran = settings.granularity;
          const newGran = e.target.value;
          // Parse old offset to DateTime
          const dt = parseOffset(settings.offset, oldGran);
          // Convert to new offset string
          const newOffset = convertToNewOffset(dt, newGran);

          onChange({
            ...requestBody,
            filters: {
              ...requestBody.filters,
              time: {
                ...settings,
                granularity: newGran,
                offset: newOffset,
              },
            },
          });
        }}
        IconComponent={ExpandMore}
        size="small"
        MenuProps={{
          disableScrollLock: true,
        }}
        sx={{
          justifyContent: "space-around",
          bgcolor: "#1F449F",
          color: "white",
          display: "flex",
          flexDirection: "row",
          borderRadius: 2,
          textTransform: "none",
          width: "48%",
          fontSize: 16,
          "&:hover": {
            bgcolor: "#1a3b8a",
          },
          "& .MuiSelect-icon": {
            color: "white",
            transition: "transform 0.2s",
          },
        }}
      >
        <MenuItem value="month">Month</MenuItem>
        <MenuItem value="quarter">Quarter</MenuItem>
        <MenuItem value="year">Year</MenuItem>
      </Select>

      <Typography style={sectionHeaderStyle} sx={{ mb: 1, mt: 2 }}>
        Select end of period
      </Typography>

      {settings.granularity === "quarter" ? (
        // QUARTER SELECTOR
        <QuarterSelector
          value={settings.offset}
          onChange={(newValue) => {
            onChange({
              ...requestBody,
              filters: {
                ...requestBody.filters,
                time: {
                  ...settings,
                  offset: newValue, // e.g. "20251"
                },
              },
            });
          }}
        />
      ) : (
        // YEAR OR MONTH => USE <DatePicker />
        <DatePicker
          // parse offset from string
          value={
            settings.offset
              ? settings.granularity === "year"
                ? DateTime.fromFormat(settings.offset, "yyyy")
                : DateTime.fromFormat(settings.offset, "yyyyMM")
              : null
          }
          onChange={(newValue) => {
            if (newValue?.isValid) {
              // Convert selected date to correct format
              const formatted =
                settings.granularity === "year"
                  ? newValue.toFormat("yyyy") // e.g. "2025"
                  : newValue.toFormat("yyyyMM"); // e.g. "202501"
              onChange({
                ...requestBody,
                filters: {
                  ...requestBody.filters,
                  time: {
                    ...settings,
                    offset: formatted,
                  },
                },
              });
            }
          }}
          views={settings.granularity === "year" ? ["year"] : ["year", "month"]}
          openTo={settings.granularity === "year" ? "year" : "month"}
          sx={{
            width: "200px",
            bgcolor: "white",
            borderRadius: 1,
            "& .MuiOutlinedInput-root": {
              "& fieldset": { border: "1px solid #E5E7EB" },
            },
          }}
        />
      )}

      <Typography style={sectionHeaderStyle} sx={{ mb: 1, mt: 2 }}>
        How many periods do you want to show?
      </Typography>

      <Box
        sx={{
          bgcolor: "#ECECEC",
          borderRadius: 4,
          p: 2,
          pt: 0,
          width: "fit-content",
        }}
      >
        <Typography style={{ fontSize: 12, color: "#656565" }}>
          periods
        </Typography>
        <TextField
          type="number"
          value={settings.periods}
          min={1}
          max={36}
          onChange={(e) =>
            parseInt(e.target.value, 10) >= 1
              ? handleSettingChange("periods", parseInt(e.target.value, 10))
              : null
          }
          variant="outlined"
          sx={{
            width: "120px",
            "& .MuiOutlinedInput-root": {
              bgcolor: "white",
              "& fieldset": { border: "none" },
            },
            "& .MuiOutlinedInput-input": {
              p: "8px 14px",
            },
          }}
        />
      </Box>

      <Box sx={{ mt: 3, borderBottom: "1px solid #EAEAEA", pb: 4 }}>
        <Typography style={sectionHeaderStyle} sx={{ mb: 1 }}>
          Display units
        </Typography>
        <Select
          value={selectedMetric}
          onChange={(e) => setSelectedMetric(e.target.value)}
          sx={{
            width: "200px",
            height: "40px",
            backgroundColor: "white",
            "& .MuiSelect-select": {
              display: "flex",
              alignItems: "center",
              gap: 1,
            },
          }}
          MenuProps={{
            PaperProps: {
              sx: {
                // maxHeight: 300,
              },
            },
            disableScrollLock: true,
          }}
        >
          {metrics.map((metric) => (
            <MenuItem key={metric.value} value={metric.value}>
              {metric.label}
            </MenuItem>
          ))}
        </Select>
      </Box>

      {/* COMPARISONS */}
      <Stack spacing={1} sx={{ mt: 2 }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={comparisons.includeTotals}
                onChange={(e) =>
                  handleComparisonChange("includeTotals", e.target.checked)
                }
              />
            }
            label="Include Totals Row"
          />
          <Tooltip
            title="Adds summary rows with subtotals and grand totals across each dimension in your report. These appear as highlighted rows in the results table."
            arrow
            placement="left"
          >
            <InfoIcon fontSize="small" color="action" sx={{ cursor: "help" }} />
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={comparisons.includeDifferences}
                onChange={(e) =>
                  handleComparisonChange("includeDifferences", e.target.checked)
                }
              />
            }
            label="Add Difference Columns"
          />
          <Tooltip
            title="Adds additional columns showing the absolute difference and percentage change between periods for each measure."
            arrow
            placement="left"
          >
            <InfoIcon fontSize="small" color="action" sx={{ cursor: "help" }} />
          </Tooltip>
        </Box>

        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={comparisons.comparisonPeriod}
                onChange={(e) =>
                  handleComparisonChange("comparisonPeriod", e.target.checked)
                }
              />
            }
            label="Add Comparison Columns"
          />
          <Tooltip
            title="Adds columns showing summary data for current and prior periods."
            arrow
            placement="left"
          >
            <InfoIcon fontSize="small" color="action" sx={{ cursor: "help" }} />
          </Tooltip>
        </Box>
      </Stack>
    </Box>
  );
};
