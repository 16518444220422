import React, { useState, useEffect } from "react";
import {
  Tabs,
  Tab,
  Box,
  Button,
  Card,
  CardHeader,
  Grid2,
  Dialog,
  DialogTitle,
  DialogContent,
  Avatar,
  Typography,
} from "@mui/material";
import {
  DataGridPremium,
  GridFilterInputValue,
} from "@mui/x-data-grid-premium";
import BrandFinderAPI from "../../api/brandfinder";
import BrandFinderMap from "./BrandFinderMap";
import { awsS3Image } from "../../helpers/awsS3Image";
import Page from "../../components/Page";
function BrandFinder() {
  // ----- Tab State -----
  const [activeTab, setActiveTab] = useState(0); // 0 for Accounts, 1 for Products

  /*********************************************************************
   *  ACCOUNTS STATE
   *********************************************************************/
  const [accounts, setAccounts] = useState([]);
  const [accountsLoading, setAccountsLoading] = useState(false);
  const [accountsPage, setAccountsPage] = useState(0);
  const [accountsPageSize, setAccountsPageSize] = useState(100);
  const [accountsRowCount, setAccountsRowCount] = useState(0);

  const [accountsSortingModel, setAccountsSortingModel] = useState([]);
  // We now track two filter models:
  const [pendingAccountsFilterModel, setPendingAccountsFilterModel] = useState({
    items: [],
  });
  const [accountsFilterModel, setAccountsFilterModel] = useState({ items: [] });

  /*********************************************************************
   *  PRODUCTS STATE
   *********************************************************************/
  const [products, setProducts] = useState([]);
  const [productsLoading, setProductsLoading] = useState(false);
  const [productsPage, setProductsPage] = useState(0);
  const [productsPageSize, setProductsPageSize] = useState(100);
  const [productsRowCount, setProductsRowCount] = useState(0);

  const [productsSortingModel, setProductsSortingModel] = useState([]);
  // Again, two filter models for Products:
  const [pendingProductsFilterModel, setPendingProductsFilterModel] = useState({
    items: [],
  });
  const [productsFilterModel, setProductsFilterModel] = useState({ items: [] });
  const [openProductsModal, setOpenProductsModal] = useState(false);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState(false);
  const [itemToRemove, setItemToRemove] = useState(null);
  const [removeType, setRemoveType] = useState(""); // 'account' or 'product'

  // Add these handlers near your other handlers
  const handleRemoveClick = (id, type) => {
    setItemToRemove(id);
    setRemoveType(type);
    setRemoveConfirmOpen(true);
  };

  const handleConfirmRemove = () => {
    if (removeType === "account") {
      handleRemoveAccountFromMap(itemToRemove);
    } else {
      handleRemoveProductFromMap(itemToRemove);
    }
    setRemoveConfirmOpen(false);
  };

  // Add this handler function near other handlers
  const handleProductsClick = (products) => {
    setSelectedProducts(products);
    setOpenProductsModal(true);
  };

  /*********************************************************************
   *  DEBOUNCE: Apply the "pending" filter model to the "real" filter
   *  model after 1500 ms. We do this for both Accounts and Products.
   *********************************************************************/
  useEffect(() => {
    const timer = setTimeout(() => {
      setAccountsFilterModel(pendingAccountsFilterModel);
    }, 1500);

    return () => clearTimeout(timer);
  }, [pendingAccountsFilterModel]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setProductsFilterModel(pendingProductsFilterModel);
    }, 1500);

    return () => clearTimeout(timer);
  }, [pendingProductsFilterModel]);

  /*********************************************************************
   *  FETCH DATA whenever the "applied" filter or sorting/page changes
   *********************************************************************/
  useEffect(() => {
    if (activeTab === 0) {
      fetchAccounts();
    } else if (activeTab === 1) {
      fetchProducts();
    }
    // We now depend on accountsFilterModel (not the pending version)
  }, [
    activeTab,
    accountsPage,
    accountsPageSize,
    accountsSortingModel,
    accountsFilterModel, // <--- the "applied" filter
    productsPage,
    productsPageSize,
    productsSortingModel,
    productsFilterModel, // <--- the "applied" filter
  ]);

  // ----- Accounts Functions -----
  const fetchAccounts = async () => {
    setAccountsLoading(true);
    try {
      const response = await BrandFinderAPI.getAccounts({
        page: accountsPage,
        pageSize: accountsPageSize,
        filterModel: accountsFilterModel, // Use the applied filter
        sortingModel: accountsSortingModel,
      });
      console.log("Products response:", response.data); // Add this debug line

      setAccounts(response.data);
      setAccountsRowCount(response.total);
    } catch (error) {
      console.error("Error fetching accounts:", error);
    }
    setAccountsLoading(false);
  };

  // When a cell is edited in the Accounts grid, update using the custom endpoint.
  const handleAccountUpdate = async (updatedRow, oldRow) => {
    try {
      await BrandFinderAPI.putAccount(updatedRow);
      return updatedRow;
    } catch (error) {
      console.error("Error updating account:", error);
    }
  };

  const handleRemoveAccountFromMap = async (accountId) => {
    try {
      await BrandFinderAPI.removeAccountFromMap(accountId);
      fetchAccounts();
    } catch (error) {
      console.error("Error removing account from map:", error);
    }
  };

  // Example of a custom filter operator (client-side). You can keep or remove.
  const customContainsOperator = {
    label: "contains",
    value: "contains",
    getApplyFilterFn: (filterItem) => {
      if (!filterItem.value || filterItem.value.trim() === "") {
        return null;
      }
      return (params) => {
        let cellValue = "";
        if (Array.isArray(params)) {
          cellValue = params.map((item) => item.name).join(", ");
        } else if (params) {
          cellValue = params.toString();
        }
        return cellValue
          .toLowerCase()
          .includes(filterItem.value.toString().toLowerCase());
      };
    },
    InputComponent: GridFilterInputValue,
  };

  // Define the columns for the Accounts DataGrid.
  const accountsColumns = [
    { field: "id", headerName: "ID", width: 70 },
    { field: "name", headerName: "Name", width: 250, editable: true },
    { field: "street", headerName: "Street", width: 150, editable: true },
    { field: "city", headerName: "City", width: 150, editable: true },
    { field: "region", headerName: "State", width: 100, editable: true },
    { field: "code", headerName: "ZIP", width: 100, editable: true },
    { field: "premise", headerName: "Premise", width: 150, editable: true },
    { field: "latitude", headerName: "Latitude", width: 80, editable: true },
    { field: "longitude", headerName: "Longitude", width: 80, editable: true },
    {
      field: "products",
      headerName: "Products Sold",
      width: 300,
      renderCell: (params) => {
        const products = params.value;
        if (Array.isArray(products) && products.length > 0) {
          return (
            <Box
              onClick={() => handleProductsClick(products)}
              sx={{
                display: "flex",
                alignItems: "center",
                gap: 1,
                cursor: "pointer",
                "&:hover": { opacity: 0.8 },
              }}
              style={{ marginTop: 2 }}
            >
              {products.slice(0, 3).map((product, index) => (
                <Avatar
                  key={index}
                  src={awsS3Image(
                    product.product_image,
                    product.product_imagekey,
                    "0x190"
                  )}
                  alt={product.name}
                  variant="square"
                  sx={{
                    width: 45,
                    height: 45,
                    bgcolor: "grey.100",
                    borderRadius: 100,
                    p: 0.5,
                  }}
                >
                  <Typography color="black">
                    {product.name ? product.name.charAt(0).toUpperCase() : "?"}
                  </Typography>
                </Avatar>
              ))}
              {products.length > 3 && (
                <Avatar
                  sx={{
                    width: 40,
                    height: 40,
                    bgcolor: "primary.main",
                    fontSize: "0.875rem",
                  }}
                >
                  +{products.length - 3}
                </Avatar>
              )}
            </Box>
          );
        }
        return "";
      },
      filterOperators: [customContainsOperator],
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleRemoveClick(params.row.id, "account")}
        >
          Remove Item
        </Button>
      ),
    },
  ];

  /*********************************************************************
   *  PRODUCTS
   *********************************************************************/
  const fetchProducts = async () => {
    setProductsLoading(true);
    try {
      const response = await BrandFinderAPI.getProducts({
        page: productsPage,
        pageSize: productsPageSize,
        filterModel: productsFilterModel,
        sortingModel: productsSortingModel,
      });
      setProducts(response.data);
      setProductsRowCount(response.total);
    } catch (error) {
      console.error("Error fetching products:", error);
    }
    setProductsLoading(false);
  };

  const handleProductUpdate = async (updatedRow, oldRow) => {
    try {
      await BrandFinderAPI.putProduct(updatedRow);
      return updatedRow;
    } catch (error) {
      console.error("Error updating product:", error);
    }
  };

  const handleRemoveProductFromMap = async (productId) => {
    try {
      await BrandFinderAPI.removeProductFromMap(productId);
      fetchProducts();
    } catch (error) {
      console.error("Error removing product from map:", error);
    }
  };

  const productsColumns = [
    {
      field: "product_image",
      headerName: "Image",
      width: 100,
      renderCell: (params) => {
        const imageUrl = awsS3Image(
          params.row.product_image,
          params.row.product_imagekey,
          "0x190"
        );
        return (
          <Avatar
            src={imageUrl}
            alt={params.row.name}
            variant="square"
            sx={{
              width: 45,
              height: 45,
              bgcolor: "grey.100",
              borderRadius: 100,
              p: 0.5,
            }}
            style={{ marginTop: 2 }}
          >
            <Typography color="black">
              {params.row.name ? params.row.name.charAt(0).toUpperCase() : "?"}
            </Typography>
          </Avatar>
        );
      },
    },
    { field: "name", headerName: "Name", width: 350, editable: true },
    { field: "product_brand", headerName: "Brand", width: 150 },
    { field: "brand", headerName: "Legacy Brand", width: 150, editable: true },
    { field: "volume", headerName: "Volume", width: 100 },
    { field: "name", headerName: "Name", width: 350, editable: true },
    { field: "brand", headerName: "Brand", width: 150, editable: true },
    { field: "volume", headerName: "Volume", width: 100 },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleRemoveClick(params.row.id, "product")}
        >
          Remove Item
        </Button>
      ),
    },
  ];

  /*********************************************************************
   *  EXPORT EXCEL FUNCTIONS
   *********************************************************************/
  const handleExportAccountsExcel = async () => {
    try {
      const sortModelParam = JSON.stringify(accountsSortingModel);
      const filterModelParam = JSON.stringify(accountsFilterModel);
      const response = await BrandFinderAPI.getAccountsExport({
        sortModel: sortModelParam,
        filterModel: filterModelParam,
      });
      const blob = response;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Accounts.xlsx";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting Accounts Excel:", error);
    }
  };

  const handleExportProductsExcel = async () => {
    try {
      const sortModelParam = JSON.stringify(productsSortingModel);
      const filterModelParam = JSON.stringify(productsFilterModel);
      const response = await BrandFinderAPI.getProductsExport({
        sortModel: sortModelParam,
        filterModel: filterModelParam,
      });
      const blob = response;
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.download = "Products.xlsx";
      document.body.appendChild(link);
      link.click();
      link.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error exporting Products Excel:", error);
    }
  };

  /*********************************************************************
   *  HANDLERS FOR FILTER CHANGES (to update the PENDING models)
   *********************************************************************/
  const handleAccountsFilterModelChange = (newModel) => {
    // Update the PENDING filter; 1.5s later, the "applied" accountsFilterModel is updated
    setPendingAccountsFilterModel(newModel);
  };

  const handleProductsFilterModelChange = (newModel) => {
    setPendingProductsFilterModel(newModel);
  };

  /*********************************************************************
   *  RENDER
   *********************************************************************/
  return (
    <Page title="Accounts Explorer">
      <Grid2 size={12} spacing={4} p={3} m={3}>
        <Card>
          <CardHeader
            title="Accounts Explorer - Last 90 Days Did Buy"
            subheader="To filter tables, press three dots on column name, select 'Filter' and
        type in the search bar. You can Export accounts to Excel."
          />

          <Tabs
            value={activeTab}
            onChange={(e, newValue) => setActiveTab(newValue)}
          >
            <Tab label="Accounts" />
            <Tab label="Products" />
          </Tabs>
        </Card>
        <Box sx={{ height: 600, width: "100%", mt: 2 }}>
          <BrandFinderMap />
        </Box>
        {activeTab === 0 && (
          <Box sx={{ height: 600, width: "100%", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleExportAccountsExcel}
              sx={{ mb: 2 }}
            >
              Export Accounts to Excel
            </Button>
            <Card>
              <DataGridPremium
                rows={accounts}
                columns={accountsColumns}
                loading={accountsLoading}
                rowCount={accountsRowCount}
                pagination
                paginationMode="server"
                paginationModel={{
                  page: accountsPage,
                  pageSize: accountsPageSize,
                }}
                onPaginationModelChange={(newModel) => {
                  setAccountsPage(newModel.page);
                  setAccountsPageSize(newModel.pageSize);
                }}
                sortingModel={accountsSortingModel}
                onSortModelChange={setAccountsSortingModel}
                // We pass the *pending* model here, for immediate UI updates
                filterModel={pendingAccountsFilterModel}
                // This handler updates the *pending* model
                onFilterModelChange={handleAccountsFilterModelChange}
                processRowUpdate={handleAccountUpdate}
                slotProps={{
                  pagination: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
              />
            </Card>
          </Box>
        )}

        {activeTab === 1 && (
          <Box sx={{ height: 600, width: "100%", mt: 2 }}>
            <Button
              variant="contained"
              onClick={handleExportProductsExcel}
              sx={{ mb: 2 }}
            >
              Export Products to Excel
            </Button>
            <Card>
              <DataGridPremium
                rows={products}
                columns={productsColumns}
                loading={productsLoading}
                rowCount={productsRowCount}
                pagination
                paginationMode="server"
                paginationModel={{
                  page: productsPage,
                  pageSize: productsPageSize,
                }}
                onPaginationModelChange={(newModel) => {
                  setProductsPage(newModel.page);
                  setProductsPageSize(newModel.pageSize);
                }}
                sortingModel={productsSortingModel}
                onSortModelChange={setProductsSortingModel}
                // For products, same approach
                filterModel={pendingProductsFilterModel}
                onFilterModelChange={handleProductsFilterModelChange}
                processRowUpdate={handleProductUpdate}
                slotProps={{
                  pagination: {
                    showFirstButton: true,
                    showLastButton: true,
                  },
                }}
              />
            </Card>
          </Box>
        )}
        <Dialog
          open={openProductsModal}
          onClose={() => setOpenProductsModal(false)}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle sx={{ fontSize: 24, fontWeight: "bold" }}>
            Products Sold
          </DialogTitle>
          <DialogContent>
            <Grid2 container spacing={2}>
              {selectedProducts.map((product, index) => {
                console.log(
                  "Ethan",
                  product.product_image,
                  product.product_imagekey
                );
                const imageUrl = awsS3Image(
                  product.product_image,
                  product.product_imagekey,
                  "0x190"
                );
                return (
                  <Grid2 item size={12} key={index}>
                    <Card sx={{ display: "flex", p: 2 }}>
                      <Avatar
                        src={imageUrl}
                        alt={product.name}
                        variant="square"
                        sx={{
                          width: 80,
                          height: 80,
                          bgcolor: "grey.100",
                          borderRadius: 100,
                          p: 1,
                          mr: 2,
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <Typography color="black">
                          {product.name
                            ? product.name.charAt(0).toUpperCase()
                            : "?"}
                        </Typography>
                      </Avatar>
                      <Box>
                        <Typography variant="h6">{product.name}</Typography>
                        <Typography variant="body1" color="text.secondary">
                          Brand:{" "}
                          {product.product_brand || product.brand || "N/A"}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Volume: {product.volume || "N/A"}
                        </Typography>
                      </Box>
                    </Card>
                  </Grid2>
                );
              })}
            </Grid2>
          </DialogContent>
        </Dialog>
        <Dialog
          open={removeConfirmOpen}
          onClose={() => setRemoveConfirmOpen(false)}
        >
          <DialogTitle>Confirm Removal</DialogTitle>
          <DialogContent>
            <Typography>
              Are you sure you want to remove this item from the map?
            </Typography>
          </DialogContent>
          <Box
            sx={{ p: 2, display: "flex", justifyContent: "flex-end", gap: 1 }}
          >
            <Button onClick={() => setRemoveConfirmOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleConfirmRemove}
            >
              Confirm
            </Button>
          </Box>
        </Dialog>
      </Grid2>
    </Page>
  );
}

export default BrandFinder;
