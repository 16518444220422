import React, { useEffect, useState, useMemo } from "react";
import { Snackbar, Alert } from "@mui/material";
import { Container, TextField, Typography } from "@mui/material";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import useStorage from "../../../hooks/useStorage";
import StorageClient from "../../../storage/client";
import useApi from "../../../hooks/useApi";
import { useStore } from "../../../stores/StoreContext";
import storageAPI from "../../../api/storage";
import marketingAPI from "../../../api/marketing";
import { observer } from "mobx-react-lite";
import MediaLibrary from "../../../components/media/MediaLibrary";
import GenericModal from "../../../components/modals/GenericModal";
import { useParams } from "react-router";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { MediaLibraryTopBar } from "./MediaLibraryTopBar";
import { makeStyles } from "@mui/styles";
import { nanoid } from "nanoid";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

const MediaView = observer(() => {
  const { setFolders, folders, userInfo, files, setFiles, manufacturer } =
    useStore();
  const { folder_id, file_id } = useParams();
  const getStorageApi = useApi(storageAPI.getStorage);
  const [uploadSnackbarOpen, setUploadSnackbarOpen] = useState(false);
  const classes = useStyles();
  const setS3Items = useStorage(StorageClient.upload);
  const [fileUploadError, setFileUploadError] = useState(false);
  const [newFolder, setNewFolder] = useState({ name: "" });
  const [showFolderDialog, setShowFolderDialog] = useState(false);
  const fileRoot = "SalesTier/" + userInfo.mid + "/";
  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectionMode, setSelectionMode] = useState(false);

  const handleSelectItem = (item) => {
    if (selectedItems.includes(item.file_id)) {
      setSelectedItems(selectedItems.filter((id) => id !== item.file_id));
    } else {
      setSelectedItems([...selectedItems, item.file_id]);
    }
  };

  const handleSelectAll = () => {
    const itemsToSelect = currentFiles
      .filter((item) => !item.isFolder)
      .map((item) => item.file_id);

    setSelectedItems(itemsToSelect);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const currentFolders = useMemo(() => {
    if (folder_id) {
      return folders.filter(
        (f) => f.parent_folder_id === parseInt(folder_id, 10)
      );
    }
    const liveFolderIds = folders.map((f) => f.folder_id);
    return folders.filter((f) => !liveFolderIds.includes(f.parent_folder_id));
  }, [folders, folder_id]);

  const currentFiles = useMemo(() => {
    if (folder_id) {
      return files.filter((f) => f.folder_id === parseInt(folder_id, 10));
    }
    const liveFolderIds = folders.map((f) => f.folder_id);
    return files.filter((f) => !liveFolderIds.includes(f.folder_id));
  }, [files, folder_id, folders]);

  const startUpload = () => {
    setUploadSnackbarOpen(true);

    window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });

    setTimeout(() => {
      setUploadSnackbarOpen(false);
    }, 4000);
  };

  const handleFilesUpload = async (inputFiles) => {
    setUploadModalOpen(false);
    startUpload();
    const filesArray =
      inputFiles instanceof FileList ? Array.from(inputFiles) : inputFiles;

    try {
      let folderUpdates = {};
      const uploadedFiles = await Promise.all(
        filesArray.map(async (file) => {
          // TODO: use nanoid here
          const nano = nanoid(5);
          const rootKey = `${fileRoot}${nano}_${file.name}`;
          const newFile = {
            identity_id: userInfo.user_identitykey,
            file_key: rootKey,
            file_name: file.name,
            user_id: userInfo.uid,
            is_active: true,
            created: new Date().toISOString(),
            folder_id: folder_id || 0,
          };

          try {
            await setS3Items.request(rootKey, file, file.type);
            let { result } = await marketingAPI.saveFile(newFile);
            return result;
          } catch (error) {
            console.error("Error uploading file:", error);
            setFileUploadError(true);
            return null;
          }
        })
      );

      if (folder_id && folderUpdates[folder_id]) {
        setFolders(
          folders.map((folder) => {
            if (parseInt(folder_id, 10) === folder.folder_id) {
              return {
                ...folder,
                files: [...folder.files, ...folderUpdates[folder_id]],
              };
            }
            return folder;
          })
        );
      }

      const successfulUploads = uploadedFiles.filter((file) => file !== null);
      setFiles([...files, ...successfulUploads]);
    } catch (error) {
      console.error("An error occurred during the file upload process:", error);
    }
  };

  useEffect(() => {
    const run = async () => {
      const { manufacturer_id } = userInfo;
      if (manufacturer_id) {
        await marketingAPI.getFolders().then(({ results }) => {
          setFolders(results);
        });
        const storedFiles = await marketingAPI.getFiles();
        if (storedFiles.results) {
          setFiles(storedFiles.results);
        }
        trackEvent(userInfo.uid, "PageView:Media");
      }
    };
    run();
  }, [setFiles, setFolders, userInfo]);

  const createNewFolder = async () => {
    const { name } = newFolder;
    const parent_folder_id = folder_id ? parseInt(folder_id, 10) : 0;
    let folderPost = {
      name,
      manufacturer_id: userInfo.manufacturer_id,
      user_id: userInfo.uid,
      parent_folder_id,
    };
    const { results } = await marketingAPI.saveFolder(folderPost);
    setNewFolder({ name: "" });
    setShowFolderDialog(false);
    folderPost.folder_id = results[0];
    folderPost.created = Date.now();
    setFolders([...folders, folderPost]);
  };

  const handleDownload = async (item) => {
    try {
      await s3Storage.download(
        item.file_key,
        item.identity_id,
        true,
        item.file_name
      );
      setToastMessage("Download Started");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to Download: ", err);
      setToastMessage("Failed to Download");
      setToastOpen(true);
    }
  };

  const handleViewOriginal = (item) => {
    const url = awsS3Image(item.file_key, item.identity_id, "0x0");
    window.open(url, "_blank");
  };

  const handleDeleteConfirm = async (item) => {
    try {
      await marketingAPI.deleteFile(item.file_id);
      setFiles(files.filter((f) => f.file_id !== item.file_id));
      setToastMessage("File deleted successfully");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to delete file:", err);
      setToastMessage("Failed to delete file");
      setToastOpen(true);
    }
  };

  const handleCopyImageUrlToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setToastMessage("Image URL copied to clipboard");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setToastMessage("Failed to copy image URL");
      setToastOpen(true);
    }
  };

  const handleEditFileName = async (item, newNickname) => {
    try {
      await marketingAPI.patchFile({
        file_id: item.file_id,
        patch: {
          nickname: newNickname,
        },
      });
      setFiles(
        files.map((f) =>
          f.file_id === item.file_id ? { ...f, nickname: newNickname } : f
        )
      );
      setToastMessage("Nickname updated successfully");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to update nickname:", err);
      setToastMessage("Failed to update nickname");
      setToastOpen(true);
    }
  };

  const handleBulkDelete = async () => {
    const totalItems = selectedItems.length;
    let deletedCount = 0;

    for (const id of selectedItems) {
      const item = files.find((f) => f.file_id === id);
      try {
        await marketingAPI.deleteFile(item.file_id);
        deletedCount += 1;
        setFiles(files.filter((f) => !selectedItems.includes(f.file_id)));
        setToastMessage(`${deletedCount} of ${totalItems} items deleted`);
        setToastOpen(true);
      } catch (err) {
        console.error("Failed to delete file:", err);
        setToastMessage(`Error deleting item ${deletedCount + 1}`);
        setToastOpen(true);
      }
    }

    setSelectedItems([]);
  };

  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const s3Storage = useStorage(StorageClient.download);

  return (
    <Page className={classes.root} title="Marketing">
      <Snackbar
        open={uploadSnackbarOpen}
        autoHideDuration={4000}
        onClose={() => setUploadSnackbarOpen(false)}
      >
        <Alert onClose={() => setUploadSnackbarOpen(false)} severity="info">
          File uploading...
        </Alert>
      </Snackbar>
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert onClose={() => setToastOpen(false)} severity="success">
          {toastMessage}
        </Alert>
      </Snackbar>
      {!file_id && (
        <MediaLibraryTopBar
          uploadModalOpen={uploadModalOpen}
          setUploadModalOpen={setUploadModalOpen}
          handleFilesUpload={handleFilesUpload}
          folder_id={folder_id}
          setShowFolderDialog={setShowFolderDialog}
          showFolderDialog={showFolderDialog}
          setSelectionMode={setSelectionMode}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          handleBulkDelete={handleBulkDelete}
          files={files}
          handleDownload={handleDownload}
          handleSelectAll={handleSelectAll}
          handleDeselectAll={handleDeselectAll}
          deleteProgress
        />
      )}

      <Container maxWidth={false}>
        <MediaLibrary
          handleDownload={handleDownload}
          handleViewOriginal={handleViewOriginal}
          handleDeleteConfirm={handleDeleteConfirm}
          handleCopyImageUrlToClipboard={handleCopyImageUrlToClipboard}
          handleEditFileName={handleEditFileName}
          selectionMode={selectionMode}
          selectedItems={selectedItems}
          handleSelectItem={handleSelectItem}
          setFiles={setFiles}
          files={files}
          folder_id={folder_id}
          file_id={file_id}
          currentFiles={currentFiles}
          currentFolders={currentFolders}
          loading={getStorageApi.loading}
          manufacturer={manufacturer}
          setFolders={setFolders}
          userInfo={userInfo}
          folders={folders}
        />
        <GenericModal
          title={`New Folder`}
          open={showFolderDialog}
          onClose={() => setShowFolderDialog(false)}
          confirmAction={createNewFolder}
        >
          <TextField
            value={newFolder.name}
            onChange={(event) =>
              setNewFolder({
                ...newFolder,
                name: event.target.value,
              })
            }
          />
        </GenericModal>
        <GenericModal
          title={`Could not store file`}
          open={fileUploadError}
          onClose={() => setFileUploadError(false)}
          confirmAction={() => setFileUploadError(false)}
          doneText="Dismiss"
          showActions={false}
        >
          <Typography variant="body1">
            The file could not be stored at this time.
            <br />
            Please check network connectivity and file format are as expected.
          </Typography>
        </GenericModal>
      </Container>
    </Page>
  );
});

export default MediaView;
