import { API } from "aws-amplify";
import client from "./client";
import APICallWrapper from "./APICallWrappers";

const apiName = "backendGateway"; // Should be an environment variable
const getFormList = async (manufacturer_id, args = {}) => {
  const key = `getFormList-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading form list...",
    "Form list loaded successfully.",
    "Failed to load form list.",
    () => client.get("/forms", { mid: manufacturer_id, ...args })
  );
};

const getForm = async (form_uuid) => {
  const key = `getForm-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading form...",
    "Form loaded successfully.",
    "Failed to load form.",
    () => client.get(`/forms/${form_uuid}`, {})
  );
};

const getFormVersions = async (form_uuid) => {
  return client.get(`/forms/${form_uuid}/versions`, {});
};

const getFormVersionById = async ({
  form_uuid,
  form_id,
  include_archived = 0,
}) => {
  return client.get(`/forms/${form_uuid}/versions/${form_id}`, {
    include_archived,
  });
};

const deleteForm = async (formId) => {
  const key = `deleteForm-${Date.now()}`;
  const endpoint = `/forms/${formId}`;
  return await APICallWrapper(
    key,
    "Deleting form...",
    "Form deleted successfully.",
    "Failed to delete form.",
    () => API.del(apiName, endpoint, {})
  );
};
const archiveForm = (formId) => {
  const endpoint = `/forms/${formId}/archive`;
  return client.post(endpoint, formId);
};

const deleteFormVersion = async (form_uuid, form_id) => {
  const key = `deleteFormVersion-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting form version...",
    "Form version deleted successfully.",
    "Failed to delete form version.",
    () => API.del(apiName, `/forms/${form_uuid}/${form_id}`, {})
  );
};

const addForm = async (data) => {
  const endpoint = `/forms`;

  const key = `addForm-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Adding form...",
    "Form added successfully.",
    "Failed to add form.",
    () => client.post(endpoint, data)
  );
};

const editForm = async (data, formId) => {
  const key = `editForm-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Editing form...",
    "Form edited successfully.",
    "Failed to edit form.",
    () => client.put(`/forms/${formId}`, data)
  );
};

const saveFormResponse = async (data) => {
  const key = `saveFormResponse-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Saving form response...",
    "Form response saved successfully.",
    "Failed to save form response.",
    () => client.post(`/forms/${data.form_id}/responses`, data)
  );
};

const updateFormResponse = async (data) => {
  const key = `updateFormResponse-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating form response...",
    "Form response updated successfully.",
    "Failed to update form response.",
    () =>
      client.put(`/forms/${data.form_id}/responses/${data.response_id}`, data)
  );
};

const editFormResponse = async (data) => {
  const key = `editFormResponse-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Editing form response...",
    "Form response edited successfully.",
    "Failed to edit form response.",
    () =>
      client.put(`/forms/responses/${data.response_id}/editResponse`, {
        response_body: data.response_body,
      })
  );
};

const getFormResponse = async (data) => {
  const key = `getFormResponse-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading form response...",
    "Form response loaded successfully.",
    "Failed to load form response.",
    () =>
      client.get(`/forms/${data.form_id}/responses/${data.response_id}`, data)
  );
};

const getResponses = async (formId) => {
  const key = `getResponses-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading responses...",
    "Responses loaded successfully.",
    "Failed to load responses.",
    () => client.get(`/forms/${formId}/responses`, {})
  );
};

const getResponsesExport = async (formId) => {
  const key = `getResponsesExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Exporting responses...",
    "Responses exported successfully.",
    "Failed to export responses.",
    () => client.get(`/forms/${formId}/export`, {})
  );
};

const getResponsesExport2 = async (formId) => {
  const key = `getResponsesExport2-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Exporting responses (Test)...",
    "Responses exported successfully.",
    "Failed to export responses.",
    () => client.get(`/forms/${formId}/exportTestEthan`, {})
  );
};

const getMyResponsesExport = async (formId, user_email) => {
  const key = `getMyResponsesExport-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Exporting your responses...",
    "Your responses exported successfully.",
    "Failed to export your responses.",
    () => client.get(`/forms/${formId}/export/user/${user_email}`, {})
  );
};

const getMyFormResponses = async (userId) => {
  const key = `getMyFormResponses-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading your form responses...",
    "Your form responses loaded successfully.",
    "Failed to load your form responses.",
    () => client.get(`/forms/user_responses/${userId}`, {})
  );
};

const getAccountFormResponses = async (accountId) => {
  const key = `getAccountFormResponses-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading account form responses...",
    "Account form responses loaded successfully.",
    "Failed to load account form responses.",
    () => client.get(`/forms/account_responses/${accountId}`, {})
  );
};

const deleteFormResponse = async (formId, responseId) => {
  const key = `deleteFormResponse-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting form response...",
    "Form response deleted successfully.",
    "Failed to delete form response.",
    () => API.del(apiName, `/forms/${formId}/responses/${responseId}`, {})
  );
};

export default {
  archiveForm,
  getFormList,
  deleteForm,
  deleteFormVersion,
  addForm,
  getForm,
  getFormVersions,
  getFormVersionById,
  editForm,
  saveFormResponse,
  updateFormResponse,
  editFormResponse,
  getFormResponse,
  getResponses,
  deleteFormResponse,
  getMyFormResponses,
  getAccountFormResponses,
  getResponsesExport,
  getResponsesExport2,
  getMyResponsesExport,
};
