import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import { DateTime } from "luxon";
import formAPI from "../../api/forms";
import Page from "../../components/Page";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Menu,
  MenuItem,
  IconButton,
} from "@mui/material";
import {
  Add,
  Delete,
  Edit,
  Assignment,
  Restore,
  MoreVert,
} from "@mui/icons-material";
import { useEffect, useMemo } from "react";
import { useStore } from "../../stores/StoreContext";
import { useState } from "react";
import { observer } from "mobx-react-lite";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-premium";
import GenericModal from "../../components/modals/GenericModal";
import { makeStyles, withStyles } from "@mui/styles";
import { blue, red } from "@mui/material/colors";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

// Custom Buttons
const DeleteButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(red[500]),
    backgroundColor: red[500],
    margin: "2px",
    "&:hover": {
      backgroundColor: red[700],
    },
  },
}))(Button);

const ArchiveButton = withStyles((theme) => ({
  root: {
    color: theme.palette.getContrastText(blue[500]),
    backgroundColor: blue[500],
    margin: "2px",
    "&:hover": {
      backgroundColor: blue[700],
    },
  },
}))(Button);

// A Custom Toolbar identical in style to the previous example
function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const FormsList = observer(() => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [menuRow, setMenuRow] = useState(null);

  const handleMenuOpen = (event, row) => {
    setAnchorEl(event.currentTarget);
    setMenuRow(row);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setMenuRow(null);
  };

  const navigate = useNavigate();
  const classes = useStyles();
  const { userInfo, formList, setFormsList } = useStore();

  // Modals for Delete/Archive confirmation
  const [deleting, setDeleting] = useState(null);
  const [archiving, setArchiving] = useState(null);

  // Toggles for live forms, drafts, archived
  const [showDrafts, setShowDrafts] = useState(false);
  const [showArchived, setShowArchived] = useState(false);

  // Load forms
  const loadForms = () => {
    const { manufacturer_id } = userInfo;
    formAPI.getFormList(manufacturer_id).then((res) => {
      setFormsList(res.results);
      console.log(res.results);
    });
  };

  useEffect(() => {
    if (userInfo?.manufacturer_id) {
      loadForms();
    }
    // eslint-disable-next-line
  }, [userInfo]);

  // Delete action
  const deleteForm = (form) => {
    const { form_uuid, form_id } = form;
    console.log("deleting", form);
    if (form.is_open) {
      // If it's an "open" form, delete the entire form
      formAPI.deleteForm(form_uuid).then(() => {
        setFormsList(formList.filter((f) => f.form_uuid !== form_uuid));
        setDeleting(null);
      });
    } else {
      // If it's a draft, just delete this version
      formAPI.deleteFormVersion(form_uuid, form_id).then(() => {
        setFormsList(formList.filter((f) => f.form_id !== form_id));
        setDeleting(null);
      });
    }
  };

  // Archive action
  const archiveForm = (form) => {
    const { form_uuid } = form;
    console.log("archiving", form);
    if (form.is_open) {
      formAPI.archiveForm(form_uuid).then(() => {
        setArchiving(null);
        loadForms();
      });
    }
  };

  /**
   * Filter the formList into three categories:
   * 1) "Live" forms (public, active, open),
   * 2) "Drafts" (active but not open),
   * 3) "Archived" (active but not public).
   * These are combined if they share a uuid (multiple versions).
   */
  const forms = useMemo(() => {
    return Object.values(
      formList
        .filter((f) => f.is_open && f.is_active && f.is_public)
        .reduce((acc, cur) => {
          const { form_id, form_uuid, created, responseCount } = cur;
          if (form_uuid) {
            if (!acc[form_uuid]) {
              acc[form_uuid] = {
                ...cur,
                newest: {
                  created: cur.created,
                  response_id: cur.response_id,
                },
              };
              return acc;
            }

            const rc = responseCount + acc[form_uuid].responseCount;
            acc[form_uuid].responseCount = rc;

            if (cur.created > acc[form_uuid].created) {
              acc[form_uuid] = {
                ...acc[form_uuid],
                ...cur,
                responseCount: rc,
                is_open: acc[form_uuid].is_open || cur.is_open,
                newest: {
                  created: cur.created,
                  response_id: cur.response_id,
                },
              };
            } else {
              acc[form_uuid].responseCount = rc;
            }
          } else {
            acc[form_id] = cur;
          }
          return acc;
        }, {})
    );
  }, [formList]);

  const drafts = useMemo(() => {
    return formList.filter((f) => !f.is_open && f.is_active);
  }, [formList]);

  const archived = useMemo(() => {
    return Object.values(
      formList
        .filter((f) => !f.is_public && f.is_active)
        .reduce((acc, cur) => {
          const { form_id, form_uuid, created, responseCount } = cur;
          if (form_uuid) {
            if (!acc[form_uuid]) {
              acc[form_uuid] = {
                ...cur,
                newest: {
                  created: cur.created,
                  response_id: cur.response_id,
                },
              };
              return acc;
            }
            const rc = responseCount + acc[form_uuid].responseCount;
            acc[form_uuid].responseCount = rc;

            if (cur.created > acc[form_uuid].created) {
              acc[form_uuid] = {
                ...acc[form_uuid],
                ...cur,
                responseCount: rc,
                newest: {
                  created: cur.created,
                  response_id: cur.response_id,
                },
              };
            }
          } else {
            acc[form_id] = {
              ...cur,
              newest: {
                created: cur.created,
                response_id: cur.response_id,
              },
            };
          }
          return acc;
        }, {})
    );
  }, [formList]);

  // Build the columns for the DataGrid
  const columns = [
    {
      field: "form_name",
      headerName: "Form Name",
      flex: 1,
    },
    {
      field: "responseCount",
      headerName: "Responses",
      flex: 1,
      renderCell: (params) => {
        const { row } = params;
        return row.responseCount > 0 ? (
          <Link to={`/app/forms/${row.form_uuid}/responses`}>
            {row.responseCount} responses
          </Link>
        ) : (
          "-"
        );
      },
    },
    {
      field: "newest",
      headerName: "Latest",
      flex: 1,
      valueGetter: (params) => {
        return params.row?.newest?.created ?? null;
      },
      sortComparator: (v1, v2) => {
        if (!v1) return 1;
        if (!v2) return -1;
        return DateTime.fromISO(v1) > DateTime.fromISO(v2) ? 1 : -1;
      },
      renderCell: (params) => {
        const newest = params.row?.newest;
        if (!newest) return "-";
        return (
          <Link
            to={`/app/forms/${params.row.form_uuid}/responses/${newest.response_id}`}
          >
            {DateTime.fromISO(newest.created).toRelative()}
          </Link>
        );
      },
    },
    {
      field: "created",
      headerName: "Updated",
      flex: 1,
      renderCell: (params) => {
        return DateTime.fromISO(params.value).toRelative();
      },
    },
    // Ensure the "Actions" column is the last in the array
    {
      field: "actions",
      headerName: "Actions",
      flex: 0, // Set a fixed size or adjust accordingly
      sortable: false,
      filterable: false,
      align: "right", // Optional: Align content to the right
      renderCell: (params) => {
        const { row } = params;
        return (
          <IconButton onClick={(e) => handleMenuOpen(e, row)}>
            <MoreVert />
          </IconButton>
        );
      },
    },
  ];

  // Determine which list of rows to show
  const tableRows = useMemo(() => {
    if (showArchived) return archived;
    if (showDrafts) return drafts;
    return forms;
  }, [showArchived, showDrafts, archived, drafts, forms]);

  return (
    <Page title="Forms">
      <Container className={classes.root}>
        <Card>
          <CardContent>
            <Box display="flex" mb={2}>
              {/* New Form Button */}
              <Box>
                <Button
                  onClick={() => navigate("/app/forms/builder")}
                  variant="contained"
                  color="primary"
                  endIcon={<Add />}
                >
                  New Form
                </Button>
              </Box>

              {/* Toggle Buttons: Live / Drafts / Archived */}
              <Box display="flex" pl={2}>
                <Box pr={1}>
                  <Button
                    onClick={() => {
                      setShowDrafts(false);
                      setShowArchived(false);
                    }}
                    variant="contained"
                    color={showDrafts || showArchived ? "default" : "secondary"}
                  >
                    Live Forms
                  </Button>
                </Box>
                <Box pr={1}>
                  <Button
                    onClick={() => {
                      setShowDrafts(true);
                      setShowArchived(false);
                    }}
                    variant="contained"
                    color={!showDrafts ? "default" : "secondary"}
                  >
                    Drafts
                  </Button>
                </Box>
                <Box pr={1}>
                  <Button
                    onClick={() => {
                      setShowDrafts(false);
                      setShowArchived(true);
                    }}
                    variant="contained"
                    color={!showArchived ? "default" : "secondary"}
                  >
                    Archived
                  </Button>
                </Box>
              </Box>
            </Box>

            {/* DataGrid with same styling approach as previous example */}
            <div style={{ height: 600, width: "100%" }}>
              <DataGridPremium
                rows={tableRows}
                columns={columns}
                getRowId={(item) => item.form_id}
                pagination
                pageSizeOptions={[20, 50, 100, 250]}
                disableSelectionOnClick
                // because we dont use bootstrap, we need to add this otherwise the pagination isn't centered vertically.
                // see https://github.com/mui/mui-x/issues/4076
                sx={{
                  ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
                    {
                      "margin-top": "1em",
                      "margin-bottom": "1em",
                    },
                }}
                components={{ Toolbar: CustomToolbar }}
                initialState={{
                  pagination: {
                    paginationModel: { pageSize: 20, page: 0 },
                  },
                }}
              />
            </div>
          </CardContent>
        </Card>
      </Container>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        {/* Edit */}
        <MenuItem
          onClick={() => {
            navigate(
              `/app/forms/builder/${menuRow?.form_uuid}/${menuRow?.form_id}`
            );
            handleMenuClose();
          }}
        >
          <Edit fontSize="small" style={{ marginRight: 8 }} />
          Edit
        </MenuItem>

        {/* Respond (only if it's not a draft) */}
        {!showDrafts &&
          (menuRow?.on_account ? (
            <MenuItem
              onClick={() => {
                alert(
                  "This form is only available to accounts. Please go to the account page to respond."
                );
                handleMenuClose();
              }}
            >
              <Assignment fontSize="small" style={{ marginRight: 8 }} />
              Account Only
            </MenuItem>
          ) : (
            <MenuItem
              onClick={() => {
                navigate(`/app/forms/${menuRow?.form_uuid}`);
                handleMenuClose();
              }}
            >
              <Assignment fontSize="small" style={{ marginRight: 8 }} />
              Respond
            </MenuItem>
          ))}

        {/* Delete */}
        <MenuItem
          onClick={() => {
            setDeleting(menuRow);
            handleMenuClose();
          }}
        >
          <Delete fontSize="small" style={{ marginRight: 8 }} />
          Delete
        </MenuItem>

        {/* Archive (if not a draft or archived) */}
        {!showDrafts && !showArchived && (
          <MenuItem
            onClick={() => {
              setArchiving(menuRow);
              handleMenuClose();
            }}
          >
            <Restore fontSize="small" style={{ marginRight: 8 }} />
            Archive
          </MenuItem>
        )}
      </Menu>

      {/* Delete Confirmation Modal */}
      <GenericModal
        open={Boolean(deleting)}
        onClose={() => setDeleting(null)}
        title={`Delete form: ${deleting ? deleting.form_name : ""}?`}
        confirmAction={() => deleteForm(deleting)}
      />

      {/* Archive Confirmation Modal */}
      <GenericModal
        open={Boolean(archiving)}
        onClose={() => setArchiving(null)}
        title={`Archiving form: ${archiving ? archiving.form_name : ""}?`}
        confirmAction={() => archiveForm(archiving)}
      />
    </Page>
  );
});

export default FormsList;
