import { Redo, Undo } from "@mui/icons-material";
import { Button } from "@mui/material";
const BuilderHistory = ({ setStateStep, stateStep, stepCount }) => {
  const goBack = () => {
    if (stateStep > 0) setStateStep(stateStep - 1);
  };
  const goForward = () => {
    if (stateStep < stepCount - 1) setStateStep(stateStep + 1);
  };
  return (
    <div style={{ display: "flex", flexDirection: "row" }}>
      <Button disabled={stateStep < 1} onClick={goBack} startIcon={<Undo />}>
        Undo
      </Button>
      <Button
        disabled={stateStep >= stepCount - 1}
        onClick={goForward}
        startIcon={<Redo />}
      >
        Redo
      </Button>
    </div>
  );
};

export default BuilderHistory;
