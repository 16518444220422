import client from "./client";
import APICallWrapper from "./APICallWrappers";

const getAccounts = async ({ aid, limit }) => {
  const key = `getAccounts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading accounts...",
    "Accounts loaded successfully.",
    "Failed to load accounts.",
    () => client.get("/accounts", { account_id: aid, limit: limit })
  );
};
const getReportResults = async ({ mid, stateCode, date }) => {
  const key = `getReportResults-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading Report Data...",
    "Report loaded successfully.",
    "Failed to load reports.",
    () =>
      client.get("/accounts/reports/salesbyaccount", { mid, stateCode, date })
  );
};

const postAccounts = async (account) => {
  const key = `postAccounts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Creating account...",
    "Account created successfully.",
    "Failed to create account.",
    () => client.post("/accounts", account)
  );
};

const putAccounts = async (account) => {
  const key = `putAccounts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating account...",
    "Account updated successfully.",
    "Failed to update account.",
    () => client.put(`/accounts/${account.account_id}`, account)
  );
};

const removeAccountsFromList = async ({ account_ids, list_id }) => {
  const key = `removeAccountsFromList-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Removing accounts from list...",
    "Accounts succesfully removed.",
    "Failed to remove accounts.",
    () => client.post(`/accounts/list/${list_id}/items`, { account_ids })
  );
};

// TODO: clean up API
const postAccountsNew = async (account) => {
  const key = `postAccountsNew-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Creating new account...",
    "New account created successfully.",
    "Failed to create new account.",
    () => client.post("/accounts/new", account)
  );
};

const getContacts = async (contact) => {
  const key = `getContacts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading contacts...",
    "Contacts loaded successfully.",
    "Failed to load contacts.",
    () => client.get("/accounts/contact/list", contact)
  );
};

const postContact = async (contact) => {
  const key = `postContact-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Adding contact...",
    "Contact added successfully.",
    "Failed to add contact.",
    () => client.post("/accounts/contact/list", contact)
  );
};

const putContact = async (contact) => {
  const key = `putContact-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating contact...",
    "Contact updated successfully.",
    "Failed to update contact.",
    () => client.put("/accounts/contact/list", contact)
  );
};
const deleteContact = async (contactId) => {
  const key = `deleteContact-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting contact...",
    "Contact deleted successfully.",
    "Failed to delete contact.",
    () => client.del("/accounts/contact/list", { contact_id: contactId })
  );
};
const getAccountsAID = ({ aid, limit }) => {
  const endpoint = "/accounts/";
  return client.get(endpoint, { account_id: aid, limit: limit });
};

const getAccountsImages = ({ aid, mid, limit }) => {
  const endpoint = "/accounts/images/" + aid;
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getAccountsMap = ({ uid, mid, limit }) => {
  const endpoint = "/accounts/map";
  return client.get(endpoint, { uid: uid, mid: mid, limit: limit });
};

const getAccountsSearch = async ({ mid, limit }) => {
  const key = `getAccountsSearch-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Searching accounts...",
    "Accounts search completed.",
    "Failed to search accounts.",
    () => client.get("/accounts/search", { mid: mid, limit: limit })
  );
};

const getAccountsStats = ({ mid, aid, limit }) => {
  const endpoint = "/accounts/stats";
  return client.get(endpoint, { mid: mid, id: aid, limit: limit });
};

const saveAccountWithAWS = async ({ text, lat, lng, account_id }) => {
  const key = `saveAccountWithAWS-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Saving account location...",
    "Account location saved successfully.",
    "Failed to save account location.",
    () => client.put(`/locations/${account_id}/place`, { lat, lng, text })
  );
};

const getAccountById = async (id) => {
  const key = `getAccountById-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading account...",
    "Account loaded successfully.",
    "Failed to load account.",
    () => client.get("/accounts", { account_id: id })
  );
};

const getUnsoldAccounts = async () => {
  const key = `getUnsoldAccounts-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading accounts...",
    "Accounts loaded successfully.",
    "Failed to load accounts.",
    () => client.get("/accounts/unsold")
  );
};

const searchAccounts = (mid, search) => {
  const endpoint = "/accounts/search";
  console.log("searchAccounts", { mid, search });
  return client.get(endpoint, { mid, search });
};

export default {
  getAccounts,
  getReportResults,
  postAccounts,
  putAccounts,
  removeAccountsFromList,
  postAccountsNew,
  getAccountsAID,
  getAccountsMap,
  getAccountsImages,
  getAccountsSearch,
  searchAccounts,
  getAccountsStats,
  saveAccountWithAWS,
  getContacts,
  postContact,
  putContact,
  deleteContact,
  getAccountById,
  getUnsoldAccounts,
};
