import { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { useStore } from "../../stores/StoreContext";
import { v4 } from "uuid";

const useQueryParamsBuilder = () => {
  const {
    dateRange,
    allBrands,
    selectedBrands,
    allStates,
    selectedStates,
    allDistributors,
    selectedDistributors,
    allProducts,
    selectedProducts,
    simpleMode,
    simpleModeCategory,
    allPremiseTypes,
    premiseTypes,
    bookmarkUUID,
  } = useStore();

  const debounceTime = 500;

  const [queryParams, setQueryParams] = useState(null);
  const [queryUUID, setQueryUUID] = useState(v4());
  const [lastBookmarkId, setLastBookMarkId] = useState(0);

  useEffect(() => {
    let debounceCooldown;
    const params = [
      {
        param: "brands",
        available: allBrands,
        selected: selectedBrands,
      },
      {
        param: "dists",
        available: allDistributors,
        selected: selectedDistributors,
      },
      {
        param: "states",
        available: allStates,
        selected: selectedStates,
      },
      {
        param: "prods",
        available: allProducts,
        selected: selectedProducts,
      },
      {
        param: "premiseTypes",
        available: allPremiseTypes,
        selected: premiseTypes,
      },
    ];
    const bookmarkChanged = bookmarkUUID !== lastBookmarkId;
    if (bookmarkChanged) {
      setLastBookMarkId(bookmarkUUID);
    }
    const generate = () => {
      const [dateStart, dateEnd] = dateRange.map((d) =>
        DateTime.fromJSDate(d).toISODate()
      );
      const p = {
        dateStart,
        dateEnd,
      };
      params.forEach(({ available, selected, param }) => {
        if (selected.length && available.length > selected.length) {
          if (simpleMode && !bookmarkChanged && typeof x !== "undefined") {
            if (simpleModeCategory === param || "prods" === param) {
              p[param] = [...selected];
            }
          } else {
            p[param] = [...selected];
          }
        }
      });
      console.log(p, new Date());
      if (JSON.stringify(p) !== JSON.stringify(queryParams)) {
        setQueryParams(p);
        setQueryUUID(v4());
      }
    };
    debounceCooldown = setTimeout(generate, debounceTime);
    return () => {
      clearTimeout(debounceCooldown);
    };
  }, [
    dateRange,
    selectedBrands,
    selectedDistributors,
    selectedProducts,
    premiseTypes,
  ]);

  return {
    queryParams,
    queryUUID,
  };
};

export default useQueryParamsBuilder;
