import React, { memo } from "react";
import { TextField } from "@mui/material";

const MemoizedInput = memo(({ value, onChange, placeholder }) => {
  return (
    <TextField
      type="number"
      size="small"
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      InputProps={{
        sx: { width: 120 },
        inputProps: { min: 0 },
      }}
    />
  );
});

export default MemoizedInput;
