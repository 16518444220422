const isEnabled = process.env.ENABLE_INSTRUMENTATION || false;

if (isEnabled) console.log("timer instrumentation on!");

function generateUniqueLabel(label) {
  return `${label}-${Math.random().toString(36).substr(2, 9)}`;
}

function startTimer(label = "") {
  if (!isEnabled) return { logTimer: () => {}, endTimer: () => {} };
  const uniqueLabel = generateUniqueLabel(label);
  console.time(uniqueLabel);
  return {
    logTimer: (...args) => {
      if (!isEnabled) return;
      console.timeLog(uniqueLabel, ...args);
    },
    endTimer: () => {
      if (!isEnabled) return;
      console.timeEnd(uniqueLabel);
    },
  };
}

module.exports = { startTimer };
