import { useParams } from "react-router";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Card,
  CardContent,
  Dialog,
  Typography,
  Grid2,
  useMediaQuery,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { Download, Edit } from "react-feather";
import { useEffect, useState, useMemo } from "react";
import { DateTime } from "luxon";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../stores/StoreContext";
import accountsAPI from "../../../api/accounts";
import usersAPI from "../../../api/users";
import { exportResponses } from "../../../components/forms/v3/helpers";
import formAPI from "../../../api/forms";
import {
  DataGridPremium,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
} from "@mui/x-data-grid-premium";

import Page from "../../../components/Page";
import ListResponse from "../../../components/forms/v3/answers/ListReponse";
import { makeStyles } from "@mui/styles";
import { List } from "@mui/icons-material";
import FormRecap from "./FormRecap";

function CustomToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
    </GridToolbarContainer>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  button: {
    margin: theme.spacing(1),
  },
}));

function ResponseTable({
  responses,
  form_id,
  setResponses,
  onOpenResponse,
  onDeleteResponse,
}) {
  // For the delete confirmation dialog
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedResponse, setSelectedResponse] = useState(null);

  const handleDeleteClick = (row) => {
    setSelectedResponse(row);
    setOpenDialog(true);
  };

  const handleConfirmDelete = () => {
    if (!selectedResponse) return;
    console.log(selectedResponse.form_id, selectedResponse.response_id);
    formAPI
      .deleteFormResponse(
        selectedResponse.form_id,
        selectedResponse.response_id
      )
      .then(() => {
        setResponses((prevResponses) =>
          prevResponses.filter(
            (r) => r.response_id !== selectedResponse.response_id
          )
        );
      })
      .catch((error) => {
        console.error("Failed to delete response:", error);
      })
      .finally(() => {
        setOpenDialog(false);
      });
  };

  const columns = [
    {
      field: "response_id",
      headerName: "Response ID",
      flex: 1,
      // Simply display the text now, no button
    },
    {
      field: "created",
      headerName: "Created Date",
      flex: 1,
      valueFormatter: (value, row) =>
        DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_MED),
    },
    {
      field: "user_displayname",
      headerName: "Created By",
      flex: 1,
      renderCell: (params) => (
        <Link
          onClick={(e) => e.stopPropagation()}
          to={`/app/profile/${params.row.user_id}`}
        >
          {params.value}
        </Link>
      ),
    },
    {
      field: "account_displayname",
      headerName: "Associated Account",
      flex: 2,
      renderCell: (params) =>
        params.value ? (
          <Link
            onClick={(e) => e.stopPropagation()}
            to={`/app/accountdetails/${params.row.account_id}`}
          >
            {params.value}
          </Link>
        ) : (
          "-"
        ),
    },
    {
      field: "actions",
      headerName: "Form Actions",
      flex: 1.5,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ marginRight: "10px" }}
            onClick={(e) => {
              e.stopPropagation();
              onOpenResponse(params.row); // calls parent to open modal
            }}
          >
            Open
          </Button>
          <Button
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              handleDeleteClick(params.row);
            }}
          >
            Delete
          </Button>
        </>
      ),
    },
  ];

  return (
    <>
      <Box sx={{ height: "65vh", width: "100%" }}>
        <DataGridPremium
          rows={responses}
          columns={columns}
          getRowId={(row) => `${row.form_id}-${row.response_id}`}
          pagination
          pageSizeOptions={[20, 50, 100, 250]}
          components={{
            Toolbar: CustomToolbar,
          }}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 20, page: 0 },
            },
          }}
          // because we dont use bootstrap, we need to add this otherwise the pagination isn't centered vertically.
          // see https://github.com/mui/mui-x/issues/4076
          sx={{
            ".MuiTablePagination-displayedRows, .MuiTablePagination-selectLabel":
              {
                "margin-top": "1em",
                "margin-bottom": "1em",
              },
          }}
          disableSelectionOnClick
        />
      </Box>
      {/* Delete Confirmation Dialog */}
      <Dialog
        open={openDialog}
        onClose={() => setOpenDialog(false)}
        aria-labelledby="confirm-delete-dialog-title"
        aria-describedby="confirm-delete-dialog-description"
      >
        <DialogTitle id="confirm-delete-dialog-title">
          Are you sure you want to delete?
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="confirm-delete-dialog-description">
            This action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDialog(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmDelete} color="secondary">
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const ResponseHeader = ({
  distributorName = null,
  account,
  response,
  user,
  editLink,
}) => {
  return (
    <Box my={2} minWidth={"50vw"}>
      <Box display={"flex"}>
        <Box flex={1} pr={2} textAlign={"right"}>
          <Typography variant="subtitle1">Respondent</Typography>
        </Box>
        <Box flex={2}>
          <Typography variant="subtitle1">
            <strong>{user ? user.user_displayname : ""}</strong>
          </Typography>
        </Box>
      </Box>
      {response.account_id && (
        <Box display={"flex"}>
          <Box flex={1} pr={2} textAlign={"right"}>
            <Typography variant="subtitle1">Account</Typography>
          </Box>
          <Box flex={2}>
            <Typography variant="subtitle1">
              <span>
                {account && (
                  <Link to={`/app/accountdetails/${account.gg_dist_acc_id}/`}>
                    <strong>{account.account_displayname}</strong>
                  </Link>
                )}
                <br />
                {account ? account.account_address : <>&nbsp;</>}
                <br />
                {distributorName && <strong>{distributorName}</strong>}
              </span>
            </Typography>
          </Box>
        </Box>
      )}
      <Box display={"flex"}>
        <Box flex={1} pr={2} textAlign={"right"}>
          <Typography variant="subtitle1">Date</Typography>
        </Box>
        <Box flex={2}>
          <Typography variant="subtitle1">
            <strong>{new Date(response.created).toLocaleDateString()}</strong>
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box textAlign={"center"} py={2}>
          <Button
            variant="contained"
            component={Link}
            to={editLink}
            color="primary"
            startIcon={<Edit />}
          >
            Edit response
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

const FormResponses = observer(() => {
  const classes = useStyles();
  const [responses, setResponses] = useState([]);
  const [currentResponse, setCurrentResponse] = useState(null);
  const [loading, setLoading] = useState(true);
  const [forms, setForms] = useState({});
  const [accounts, setAccounts] = useState({});
  const { uuid } = useParams();
  const { userInfo, userList, setUserList } = useStore();
  const [formRecapModalOpen, setFormRecapModalOpen] = useState(false);

  // For opening/closing the response modal
  const [responseModalOpen, setResponseModalOpen] = useState(false);

  const smallScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    formAPI
      .getResponses(uuid)
      .then(({ results }) => {
        const sorted = results.sort(
          (a, b) => new Date(b.created) - new Date(a.created)
        );
        setResponses(sorted);
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  }, [uuid]);

  useEffect(() => {
    if (userList.length === 0) {
      usersAPI
        .getUsers({
          mid: userInfo.manufacturer_id,
        })
        .then(({ results }) => {
          setUserList(results);
        })
        .catch((err) => console.error(err));
    }
  }, [userList, setUserList, userInfo.manufacturer_id]);

  // If we want to load the first response automatically (not strictly required anymore)
  // useEffect(() => {
  //   if (responses.length > 0 && currentResponse === null) {
  //     setCurrentResponse(responses[0]);
  //   }
  // }, [responses]);

  // Fetch account details when the currentResponse changes
  useEffect(() => {
    if (!currentResponse?.account_id) return;
    const accountKey = "_" + currentResponse.account_id.toString();
    if (!accounts[accountKey]) {
      accountsAPI
        .getAccountsAID({ aid: currentResponse.account_id })
        .then(({ results }) => {
          setAccounts((prev) => ({
            ...prev,
            [accountKey]: results[0],
          }));
        })
        .catch((err) => console.error(err));
    }
  }, [currentResponse, accounts]);

  // Load form details from the API if not already cached
  const formDetails = useMemo(() => {
    if (!currentResponse?.form_id) return { name: "", questions: [] };
    if (!forms[currentResponse.form_id]) return { name: "", questions: [] };
    const form = forms[currentResponse.form_id];
    return {
      name: form.form_name,
      questions: JSON.parse(form.form_body),
    };
  }, [forms, currentResponse]);

  // Export responses as CSV/XLSX
  const beginExport = (format) => {
    exportResponses({
      responses,
      forms,
      form: {
        form_uuid: uuid,
      },
      format,
      userList,
    });
  };

  // Handler for "Open" button in the Actions column
  const handleOpenResponse = (row) => {
    setCurrentResponse(row);
    setResponseModalOpen(true);
  };

  const openFormRecapModal = () => {
    setFormRecapModalOpen(true);
  };

  return (
    <Page title="Form Responses">
      <Container className={classes.root}>
        <Grid2
          container
          spacing={3}
          direction={smallScreen ? "column" : "row"}
          style={{ minHeight: 0 }}
        >
          {/* Table now takes the full width (xs={12}) */}
          <Grid2 item size={12}>
            <Card>
              <CardContent>
                <Box display="flex" width="100%" alignItems={"center"} mb={2}>
                  <Typography variant="h4">
                    Responses for {formDetails.name}
                  </Typography>

                  <Box display="flex" flexDirection="row" ml={"auto"}>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<Download />}
                      onClick={() => openFormRecapModal()}
                      disabled={loading || forms.length === 0}
                    >
                      Create Form Recap
                    </Button>
                  </Box>
                </Box>

                <Box display="flex" flexDirection="column">
                  {loading && <p>Loading...</p>}
                  {!loading && responses.length > 0 && (
                    <ResponseTable
                      responses={responses}
                      form_id={currentResponse?.form_id || 0}
                      setResponses={setResponses}
                      onOpenResponse={handleOpenResponse}
                    />
                  )}
                  {!loading && responses.length === 0 && (
                    <Typography>No responses found.</Typography>
                  )}
                </Box>
              </CardContent>
            </Card>
          </Grid2>
        </Grid2>
      </Container>

      <Dialog
        open={responseModalOpen}
        onClose={() => setResponseModalOpen(false)}
        maxWidth="md"
        fullWidth
      >
        {currentResponse && (
          <>
            <DialogTitle>
              Response ID: {currentResponse.response_id}
            </DialogTitle>
            {/* Make this content scrollable for large forms */}
            <DialogContent
              dividers
              style={{ maxHeight: "70vh", overflowY: "auto" }}
            >
              <ListResponse
                currentResponse={currentResponse}
                userInfo={userInfo}
                accounts={accounts}
                forms={forms}
                setForms={setForms}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setResponseModalOpen(false)}>Close</Button>
            </DialogActions>
          </>
        )}
      </Dialog>
      <Dialog
        open={formRecapModalOpen}
        maxWidth="lg"
        fullWidth
        onClose={() => setFormRecapModalOpen(false)}
      >
        <FormRecap responses={responses} />
      </Dialog>
    </Page>
  );
});

export default FormResponses;
