import React from "react";
import PropTypes from "prop-types";

import { Dialog, DialogTitle, Button, Box, DialogContent } from "@mui/material";

const GenericModal = ({
  title,
  onClose,
  open,
  deleteButtonLoading,
  handleConfirmDelete,
  children,
  cancelText = "Cancel",
  confirmText = "Confirm",
  doneText = "",
  confirmAction,
  showActions = true,
  style = {},
}) => {
  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="product-dialog"
      open={open}
      maxWidth={style.width}
      fullWidth={true}
      slotProps={{
        paper: {
          style: {
            width: "540px",
            maxHeight: "75vh",
            padding: 10,
            ...style,
          },
        },
      }}
    >
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        height={"100%"}
      >
        <Box>
          <DialogTitle id="simple-dialog-title">{title}</DialogTitle>
        </Box>
        <Box>
          <DialogContent>{children}</DialogContent>
        </Box>
        {showActions && (
          <Box
            display="flex"
            gridColumnGap={10}
            justifyContent={"space-around"}
          >
            <Button
              variant="outlined"
              color="primary"
              onClick={handleClose}
              type="button"
            >
              {cancelText}
            </Button>

            <Button
              variant="contained"
              color="primary"
              type="button"
              disabled={deleteButtonLoading}
              onClick={confirmAction}
            >
              {confirmText}
            </Button>
          </Box>
        )}
        {!showActions && (
          <Box display="flex" gridColumnGap={10} pt={1}>
            <Button
              variant="outlined"
              color="primary"
              fullWidth
              onClick={handleClose}
              type="button"
            >
              {doneText.length ? doneText : "Done"}
            </Button>
          </Box>
        )}
      </Box>
    </Dialog>
  );
};

GenericModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};
export default GenericModal;
