import { useState } from "react";
import { Storage } from "aws-amplify";
import protobufjs from "protobufjs";
import { useStore } from "../../stores/StoreContext";
import { stateCodeToState } from "../../helpers/data/usStates";
import { startTimer } from "../../helpers/instrumentation";
import { useDepletionsContainerDispatchContext } from "../../stores/DepletionContextStore";
import { useDepletionsContainerContext } from "../../stores/DepletionContextStore";
import { useEffect } from "react";
import { useLocation } from "react-router";

async function loadData({ userInfo, distributors, products }) {
  let json;

  // Fetch cached protobuf and binary files from S3
  const [protobufFile, binFile] = await Promise.all([
    Storage.get(
      `cache/${userInfo.mid}/binaryMinified.${userInfo.current_data_hash}.proto`,
      { level: "protected", identityId: "system", download: true }
    ),
    Storage.get(
      `cache/${userInfo.mid}/binaryMinified.${userInfo.current_data_hash}.binpb`,
      { level: "protected", identityId: "system", download: true }
    ),
  ]);

  const rawProtobuf = await protobufFile.Body.text();
  const rawBin = await binFile.Body.arrayBuffer();
  const protoRoot = (await protobufjs.parse(rawProtobuf)).root;
  const CacheData = protoRoot.lookupType("CacheData");
  const rawData = CacheData.decode(new Uint8Array(rawBin)).toJSON();

  // Combine processed data into final JSON object
  json = {
    results: {
      manufacturer_id: userInfo.mid,
      accounts: Object.fromEntries(
        rawData.accounts.map((a) => [
          a.id,
          { ...a, premise_type: a.premiseType },
        ])
      ),
      products: Object.fromEntries(
        products.map((d) => [d.id, { ...d, id: d.id }]) // its a string below
      ),
      distributors: Object.fromEntries(
        distributors.map((d) => [d.id, { ...d, id: d.id }]) // its a string below
      ),
      depletions: rawData.depletions.map((d) => ({
        ...d,
        qty_individual_unit: d.qtyIndividualUnit,
      })),
    },
  };

  // property should be results, refactoredFile is from a migration name
  const results = json.refactoredFile || json.results;
  return results;
}

function createMergedSaleEntry(
  sale,
  account,
  distributor,
  product,
  manufacturer_id,
  TIMESTAMP
) {
  const bottles_sold = sale.qty_individual_unit;
  const productRetailUnit = product.retailUnit || 1;
  const productUnitSize = product.unitSize || 1;
  const productPhysicalCase = product.physicalCase || 12;

  return {
    ...sale,
    DATE: sale.date,
    TIMESTAMP,
    ZIP: account.zip,
    premise_type: account.premise_type ? account.premise_type : "UNKNOWN",
    DISTRIBUTOR_ID: distributor.id,
    DISTRIBUTOR_NAME: distributor.name,
    ACCOUNT_ID: account.id,
    STORENUMBER: account.name,
    latlong:
      account.geo && account.geo[0] && account.geo[1]
        ? `${account.geo[0]}, ${account.geo[1]}`
        : null,
    STOREADDRESS: account.address,
    REGION: `${account.city} - ${account.state}`,
    STATE: account.state,
    CITY: account.city,
    PRODUCTNAME: product.name,
    PRODUCT_ID: product.id,
    SKU: product.brand,
    QTYSOLD: bottles_sold,
    NINELTR:
      sale.NINELTR ||
      Number((bottles_sold / productPhysicalCase).toPrecision(8)),
    UNIT_SOLD:
      sale.UNIT_SOLD ||
      Number((bottles_sold / productRetailUnit).toPrecision(8)),
    PHYSICAL_SOLD:
      sale.PHYSICAL_SOLD ||
      Number(
        (bottles_sold / (productPhysicalCase * productRetailUnit)).toPrecision(
          8
        )
      ),
    NINE_SOLD:
      sale.NINE_SOLD ||
      Number(((bottles_sold * productUnitSize) / 9000).toPrecision(8)),
    CASE_EQV:
      sale.CASE_EQV ||
      Number(((bottles_sold * productUnitSize) / 288).toPrecision(8)),
    PREMISETYPE: account.premise_type ? account.premise_type : "UNKNOWN",
    BRAND: product.brand,
    MANUFACTURER_ID: manufacturer_id,
    INTERNAL_PRODUCT_ID: product.internalProductId,
    phone: account.phone,
  };
}

async function processData({ results }) {
  const processedData = {
    mergedSalesData: [],
    processedAccounts: [],
  };

  const uniqueEntryStrings = new Set();
  const mergedSalesDataTimer = startTimer("mergedSalesData");

  console.log(results.depletions[0], results.distributors, results.products);

  processedData.mergedSalesData = results.depletions.reduce(
    (mergedSalesDataFiltered, sale) => {
      const account = results.accounts[sale.account];
      const distributor = results.distributors[sale.distributor];
      const product = results.products[sale.product];
      if (!product) return mergedSalesDataFiltered;
      const saleDate = new Date(sale.date);
      const TIMESTAMP = saleDate.getTime();
      if (!distributor) return mergedSalesDataFiltered;
      const entry = {
        distributor_id: distributor.id,
        dist_displayname: distributor.name,
        state: stateCodeToState(account.state || ""),
        code: account.STATE,
        product_brand: product.brand,
      };
      uniqueEntryStrings.add(JSON.stringify(entry));

      mergedSalesDataFiltered.push(
        createMergedSaleEntry(
          sale,
          account,
          distributor,
          product,
          results.manufacturer_id,
          TIMESTAMP
        )
      );

      return mergedSalesDataFiltered;
    },
    []
  );

  mergedSalesDataTimer.endTimer();

  const processedAccountsTimer = startTimer("processedAccounts");
  processedData.processedAccounts = Object.keys(results.accounts).map(
    (key) => ({
      id: key,
      ...results.accounts[key],
    })
  );
  processedAccountsTimer.endTimer();

  return processedData;
}

const useDepletions = () => {
  const { userInfo, allDistributors, allProducts } = useStore();
  const location = useLocation();
  const [fetching, setFetching] = useState(false);
  const { depletions } = useDepletionsContainerContext();
  const depletionsContainerDispatch = useDepletionsContainerDispatchContext();
  useEffect(() => {
    if (
      !["accountExplorer", "depletions"].some(
        (s) => location.pathname.includes(s) || fetching
      )
    ) {
      console.log("not a page for depletions");
      return;
    }
    if (depletions.length > 0 && fetching) {
      console.log("there are depletions, do not load them");
      return setFetching(false);
    }
    if (depletions.length > 0) {
      return console.log("not fetching and we have depletions");
    }
    const fetchData = async () => {
      const results = await loadData({
        userInfo,
        distributors: allDistributors,
        products: allProducts,
      });

      const toObj = (arr, colname) => {
        return arr.reduce((acc, cur) => {
          if (!acc["pref_" + cur[colname]]) {
            acc[cur["pref_" + cur[colname]]] = cur;
          }
          return acc;
        }, {});
      };

      const processedData = await processData({
        results,
        distributors: toObj(allDistributors, "id"),
        products: toObj(allProducts, "id"),
      });

      depletionsContainerDispatch({
        type: "setDepletions",
        depletions: processedData.mergedSalesData,
      });
    };
    if (allDistributors.length > 0 && !fetching) {
      console.log("go fetch depletions", allDistributors);
      setFetching(true);
      fetchData();
    }
  }, [allDistributors, location.pathname, depletions, userInfo]);

  return {};
};

export default useDepletions;
