import React, { useEffect, useState } from "react";
import {
  CircularProgress,
  Button,
  ButtonGroup,
  Box,
  Popover,
  Card,
  CardContent,
  TextField,
  Grid2,
  Divider,
  Tooltip,
  Dialog,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Modal,
  Typography,
  Snackbar,
  Alert,
} from "@mui/material";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import { DataGridPremium as DataGrid } from "@mui/x-data-grid-premium";
import { useLocation } from "react-router-dom";
import useApi from "../../../hooks/useApi";
import { Loader, Target } from "react-feather";
import teamsAPI from "../../../api/team";
import avatarS3 from "../../../components/aws/avatarS3";
import { useStore } from "../../../stores/StoreContext";
import { exportDataCSV, exportDataXLSX } from "./helpers";
import { API } from "aws-amplify";
import usersApi from "../../../api/users";
import listsApi from "../../../api/lists";
import {
  Close,
  CloudDownload,
  MoreHoriz,
  Refresh,
  LockOpen,
  Lock,
  Visibility,
  Send,
  ConstructionOutlined,
} from "@mui/icons-material";
import client from "../../../api/client";
import { AVAILABLE_WIDGETS } from "../../../components/widgets/AVAILABLE_WIDGETS";

const SettingsGridModal = ({ onClose, open, userAccess }) => {
  const { allDistributorsObjects, manufacturer, allListsObjects } = useStore();
  const handleClose = () => {
    onClose();
  };
  const [loading, setLoading] = useState(false);

  const getEnabledDistributorItems = (userAccess, allObjects, getId) => {
    if (userAccess) {
      let objectFromJSON = JSON.parse(userAccess);
      setEnabledDistributors(
        allObjects
          .filter((object) =>
            objectFromJSON.distributors.includes(object[getId])
          )
          .map((object) => object.displayname)
          .sort()
      );
    } else {
      setEnabledDistributors(allObjects.map((object) => object.displayname));
    }
  };

  const getEnabledMarketItems = (userAccess, allObjects, getId) => {
    if (userAccess) {
      let objectFromJSON = JSON.parse(userAccess);
      setEnabledMarkets(
        allObjects
          .filter((object) => objectFromJSON.markets.includes(object))
          .sort()
      );
    } else {
      setEnabledMarkets(allObjects);
    }
  };

  const getEnabledListItems = (userAccess, allObjects, getId) => {
    if (userAccess) {
      let objectFromJSON = JSON.parse(userAccess);
      setEnabledLists(
        allObjects
          .filter((object) => objectFromJSON.lists.includes(object[getId]))
          .map((object) => object.displayname)
          .sort()
      );
    } else {
      setEnabledLists(allObjects.map((object) => object.displayname));
    }
  };

  const getEnabledScreenItems = (userAccess, defaultItems) => {
    if (userAccess) {
      let objectFromJSON = JSON.parse(userAccess);
      setEnabledScreens(
        defaultItems
          .filter((object) => objectFromJSON.screens.includes(object))
          .sort()
      );
    } else {
      setEnabledScreens(defaultItems);
    }
  };

  const usStates = manufacturer.state_codes;
  const distributorNames = allDistributorsObjects
    .map((x) => x.displayname)
    .sort();
  const listNames = allListsObjects.map((x) => x.displayname).sort();
  const screenNames = [
    "Bookmarks",
    "Depletion Uploads",
    "Distributors",
    "Marketing",
    "Products",
    "Reports",
    "Schedule",
    "Team",
    "Inventory",
  ];
  const [enabledMarkets, setEnabledMarkets] = useState(usStates);

  const [enabledDistributors, setEnabledDistributors] =
    useState(distributorNames);

  const [enabledLists, setEnabledLists] = useState(listNames);

  const [enabledScreens, setEnabledScreens] = useState(
    userAccess?.screens || screenNames
  );

  useEffect(() => {
    getEnabledMarketItems(
      userAccess.access,
      manufacturer.state_codes,
      "code",
      "displayname"
    );
    getEnabledDistributorItems(
      userAccess.access,
      allDistributorsObjects,
      "id",
      "displayname"
    );
    getEnabledListItems(
      userAccess.access,
      allListsObjects,
      "id",
      "displayname"
    );
    getEnabledScreenItems(userAccess.access, screenNames);
  }, [userAccess]);

  const deselectAll = (list, enableFunction) =>
    enableFunction((prevDisabledItems) =>
      list.filter((item) => !prevDisabledItems.includes(item))
    );
  const selectAll = (list, enableFunction) =>
    enableFunction((prevDisabledItems) => [...prevDisabledItems, ...list]);

  const toggleItem = (item, enableFunction) =>
    enableFunction((prevDisabledItems) =>
      prevDisabledItems.includes(item)
        ? prevDisabledItems.filter((disabledItem) => disabledItem !== item)
        : [...prevDisabledItems, item]
    );
  async function updateAccessControls() {
    setLoading(true);
    try {
      let lists = allListsObjects
        .filter(
          (x) => enabledLists.includes(x.displayname) && x.type === "AUTO"
        )
        .map((x) => x.id);
      let screens = enabledScreens;
      let distributors = allDistributorsObjects
        .filter((x) => enabledDistributors.includes(x.displayname))
        .map((x) => x.id);
      let markets = usStates
        .filter((x) => enabledMarkets.includes(x))
        .map((x) => x);
      const access = {
        lists: lists,
        screens: [
          "Home",
          "Markets",
          "Profile",
          "Settings",
          "Inventory",
          ...screens,
        ],
        distributors: distributors,
        markets: markets,
      };
      const apiName = "backendGateway";
      const path = "/users/access";
      const jsonBody = {
        user_id: userAccess.user_id,
        access_settings: JSON.stringify(access),
      };
      const myInit = {
        body: jsonBody,
        headers: {},
      };
      await API.put(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
    handleClose();
  }
  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="product-dialog"
      open={open}
      PaperProps={{
        style: {
          width: "100%",
          maxWidth: "none",
          padding: 10,
        },
      }}
    >
      <Close
        style={{
          position: "absolute",
          top: "0.5rem",
          right: "0.5rem",
          cursor: "pointer",
        }}
        onClick={handleClose}
      />

      <Box style={{ display: "flex", justifyContent: "space-around" }}>
        <Button
          variant="contained"
          color="primary"
          disabled={loading}
          onClick={() => updateAccessControls()}
        >
          Update Access
        </Button>
      </Box>
      {loading && (
        <Box style={{ display: "flex", justifyContent: "space-around" }}>
          <CircularProgress />
        </Box>
      )}
      <Box style={{ display: "flex", justifyContent: "space-around" }}>
        <Box>
          <h4>Markets</h4>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => selectAll(usStates, setEnabledMarkets)}
            >
              Select All
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deselectAll(usStates, setEnabledMarkets)}
            >
              Deselect All
            </Button>
          </Box>
          <Box
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {usStates
              .slice()
              .sort()
              .map((state) => (
                <div key={state}>
                  <input
                    type="checkbox"
                    checked={enabledMarkets.includes(state)}
                    onChange={() => toggleItem(state, setEnabledMarkets)}
                  />
                  <label>{state}</label>
                </div>
              ))}
          </Box>
        </Box>
        <Box>
          <h4>Distributors</h4>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                selectAll(distributorNames, setEnabledDistributors)
              }
            >
              Select All
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() =>
                deselectAll(distributorNames, setEnabledDistributors)
              }
            >
              Deselect All
            </Button>
          </Box>
          <Box
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {distributorNames.map((name) => (
              <Box key={name}>
                <input
                  type="checkbox"
                  checked={enabledDistributors.includes(name)}
                  onChange={() => toggleItem(name, setEnabledDistributors)}
                />
                <label>{name}</label>
              </Box>
            ))}
          </Box>
        </Box>
        <Box>
          <h4>Lists</h4>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => selectAll(listNames, setEnabledLists)}
            >
              Select All
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deselectAll(listNames, setEnabledLists)}
            >
              Deselect All
            </Button>
          </Box>
          <Box
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {listNames.map((name) => (
              <Box key={name}>
                <input
                  type="checkbox"
                  checked={enabledLists.includes(name)}
                  onChange={() => toggleItem(name, setEnabledLists)}
                />
                <label>{name}</label>
              </Box>
            ))}
          </Box>
        </Box>

        <Box>
          <h4>Screens</h4>
          <Box>
            <Button
              variant="contained"
              color="primary"
              onClick={() => selectAll(screenNames, setEnabledScreens)}
            >
              Select All
            </Button>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => deselectAll(screenNames, setEnabledScreens)}
            >
              Deselect All
            </Button>
          </Box>
          <Box
            style={{
              maxHeight: "400px",
              overflowY: "auto",
              scrollbarWidth: "none",
            }}
          >
            {screenNames.map((screen) => (
              <Box key={screen}>
                <input
                  type="checkbox"
                  checked={enabledScreens.includes(screen)}
                  onChange={() => toggleItem(screen, setEnabledScreens)}
                />
                <label>{screen}</label>
              </Box>
            ))}
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
};
const groupByKey = (list, key, { omitKey = false }) =>
  list.reduce(
    (hash, { [key]: value, ...rest }) => ({
      ...hash,
      [value]: (hash[value] || []).concat(
        omitKey ? { ...rest } : { [key]: value, ...rest }
      ),
    }),
    {}
  );

const TeamManagerView = () => {
  const getTeamsApi = useApi(teamsAPI.getTeamPerformance);
  const [rows, setRows] = useState([]);
  const [exportLoading, setExportLoading] = useState(false);
  const [selectFormatOpen, setSelectFormatOpen] = useState(false);
  const [selectedIDs, setSelectedIDs] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [invite, setInvite] = useState({ email: "" });
  const [send, setSend] = useState(false);
  const [resetingPassword, setResetingPassword] = useState(false);
  const [resend, setResend] = useState(false);
  const getUsersApi = useApi(usersApi.getUsers);
  const getListsApi = useApi(listsApi.getLists);
  const [showAccessModal, setShowAccessModal] = useState(false);
  const [activeUser, setActiveUser] = useState({ access: null });
  const location = useLocation();
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);

  const store = useStore();
  const {
    userInfo,
    setAllListsObjects,
    exportSupportedPaths,
    userList,
    setUserList,
  } = store;

  const exportEnabled =
    exportSupportedPaths.filter((ep) => location.pathname.match(ep)).length > 0;

  const id = Boolean(anchorEl) ? "simple-popover" : undefined;

  const setUserActiveStatus = (id, active) => {
    return API.post("backendGateway", "/users/toggleActive/" + id, {
      headers: {},
      body: {
        active: active,
      },
    });
  };
  const toggleModal = (userRow) => {
    setActiveUser(userRow);
    setShowAccessModal(!showAccessModal);
  };
  const handleMenuOpen = (event, row) => {
    setMenuAnchorEl(event.currentTarget);
    setSelectedRow(row);
  };

  const handleMenuClose = () => {
    setMenuAnchorEl(null);
    setSelectedRow(null);
  };

  const [showChangeTargetsModal, setShowChangeTargetsModal] = useState(false);
  const [selectedUserDashboardLayout, setSelectedUserDashboardLayout] =
    useState(null);

  const openChangeTargetsModal = (row) => {
    getUsersDashboardLayout(row.user_id);
    setActiveUser(row);
    setShowChangeTargetsModal(true);
  };

  const { roles } = userInfo;

  const isAdmin = () => {
    return roles.includes("ADMIN");
  };

  const getUsersDashboardLayout = async (user_id) => {
    console.log("user_id", user_id);
    try {
      const response = await client.get("/users/layouts", {
        params: {
          user_id: user_id,
        },
      });
      if (response.layout) {
        setSelectedUserDashboardLayout(JSON.parse(response.layout));
      } else {
        // the default widgets
        setSelectedUserDashboardLayout(AVAILABLE_WIDGETS(isAdmin()));
      }
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  const columns = [
    {
      field: "user_image",
      headerName: "Avatar",
      width: 100,
      renderCell: (params) => {
        const row = params.row || {};
        return avatarS3.AvatarS3({
          linkTo: "/app/profile/" + row.user_id,
          imageKey: row.user_image,
          imageIdentityID: row.user_imagekey,
        });
      },
      editable: false,
      sortable: false,
      align: "center",
    },
    {
      field: "user_displayname",
      headerName: "Name",
      minWidth: 170,
      flex: 1,
      editable: false,
    },
    {
      field: "last_active",
      headerName: "Last Active",
      type: "string",
      minWidth: 170,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      align: "right",
      headerAlign: "right",
      minWidth: 100,
      flex: 1,
      renderCell: (params) => {
        const row = params.row || {};
        return (
          <>
            <IconButton
              onClick={(event) => handleMenuOpen(event, row)}
              size="small"
            >
              <MoreHoriz />
            </IconButton>
            <Menu
              anchorEl={menuAnchorEl}
              open={
                Boolean(menuAnchorEl) && selectedRow?.user_id === row.user_id
              }
              onClose={handleMenuClose}
            >
              {row.active && row.last_active && row.last_active !== "N/A" && (
                <MenuItem
                  onClick={() => {
                    resetPassword(row.user_id);
                    handleMenuClose();
                  }}
                  disabled={resetingPassword}
                >
                  <ListItemIcon>
                    <Refresh fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {resetingPassword ? "Resetting..." : "Reset Password"}
                  </ListItemText>
                </MenuItem>
              )}

              {row.last_active === "N/A" && (
                <MenuItem
                  onClick={() => {
                    resendInvite(row.user_id);
                    handleMenuClose();
                  }}
                  disabled={resend}
                >
                  <ListItemIcon>
                    <Send fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>
                    {resend ? "Resending..." : "Resend Invite"}
                  </ListItemText>
                </MenuItem>
              )}

              <MenuItem
                onClick={() => {
                  setUserActiveStatus(row.user_id, !row.active);
                  setRows(
                    rows.map((r) => ({
                      ...r,
                      active: r.user_id === row.user_id ? !r.active : r.active,
                    }))
                  );
                  handleMenuClose();
                }}
              >
                <ListItemIcon>
                  {row.active ? (
                    <Lock fontSize="small" />
                  ) : (
                    <LockOpen fontSize="small" />
                  )}
                </ListItemIcon>
                <ListItemText>
                  {row.active ? "Deactivate" : "Activate"}
                </ListItemText>
              </MenuItem>

              <MenuItem
                onClick={() => {
                  toggleModal(row);
                  handleMenuClose();
                }}
                disabled={!exportEnabled}
              >
                <ListItemIcon>
                  <Visibility fontSize="small" />
                </ListItemIcon>
                <ListItemText>Access Control</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={() => {
                  openChangeTargetsModal(row);
                }}
                disabled={!exportEnabled}
              >
                <ListItemIcon>
                  <Target fontSize="small" />
                </ListItemIcon>
                <ListItemText>Change Targets</ListItemText>
              </MenuItem>
            </Menu>
          </>
        );
      },
    },
  ];

  useEffect(() => {
    getUsersApi
      .request({ mid: userInfo.mid, limit: 1000 })
      .then(({ results }) => {
        setUserList(results);
      });
    getTeamsApi.request({
      mid: userInfo.mid,
      limit: 1000,
    });
    const uniqueListSet = new Set();
    getListsApi
      .request({ mid: userInfo.mid, uid: userInfo.uid, limit: 1000 })
      .then(({ results }) => {
        results.forEach((item) => {
          const lists = {
            id: item.list_id,
            displayname: item.list_name,
            type: item.type,
          };
          uniqueListSet.add(lists);
        });
        setAllListsObjects(Array.from(uniqueListSet));
      });
    trackEvent(userInfo.uid, "PageView:Manager");
  }, []);

  useEffect(() => {
    let items = groupByKey(getTeamsApi.data, "id", { omitKey: false });
    //Change to usestore
    let userMetrics = getUsersApi.data.map((i) => {
      return {
        ...i,
        metrics: items[i.user_id] || [],
        active: i.active === 1,
        access: i.access,
      };
    });
    const newRows = userMetrics.map((i) => ({
      access: i.access,
      user_id: i.user_id,
      user_displayname: i.user_displayname,
      user_image: i.user_image,
      user_imagekey: i.user_identitykey,
      last_active:
        i.metrics.filter((x) => x.key === "last_active").length === 1
          ? i.metrics.filter((x) => x.key === "last_active")[0].value
          : "N/A",
      checkin_count:
        i.metrics.filter((x) => x.key === "checkin_count").length === 1
          ? i.metrics.filter((x) => x.key === "checkin_count")[0].value
          : "0",
      media_count:
        i.metrics.filter((x) => x.key === "media_count").length === 1
          ? i.metrics.filter((x) => x.key === "media_count")[0].value
          : "0",
      forms_count:
        i.metrics.filter((x) => x.key === "forms_count").length === 1
          ? i.metrics.filter((x) => x.key === "forms_count")[0].value
          : "0",
      active: i.active,
    }));
    setRows(newRows);
  }, [getTeamsApi.data, getUsersApi.data, userList]);

  const refreshData = () => {
    getUsersApi
      .request({ mid: userInfo.mid, limit: 1000 })
      .then(({ results }) => {
        setUserList(results);
      });
  };

  const emitGlobalExportEvent = (format) => {
    setExportLoading(true);
    const preset = "manager_view";
    const headers = [
      ...columns,
      { field: "active", headerName: "Active User", editable: false },
    ].reduce(
      (acc, col) =>
        col.field === "activate_deactivate"
          ? acc
          : [...acc, { field: col.field, headerName: col.headerName }],
      []
    );
    const rowsToExport = () => {
      if (selectedIDs.length > 0) {
        return rows.filter((row) => selectedIDs.includes(row.user_id));
      } else {
        return rows;
      }
    };
    if (format === "CSV") {
      exportDataCSV(headers, rowsToExport(), preset);
    } else if (format === "XLSX") {
      exportDataXLSX(headers, rowsToExport(), preset);
    } else {
      console.log("invalid file format");
      setExportLoading(false);
    }
    window.addEventListener("exportComplete", () => {
      setExportLoading(false);
    });

    setSelectFormatOpen(false);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleChange = (event) => {
    setInvite({
      ...invite,
      [event.target.name]: event.target.value,
    });
  };

  async function sendInvite() {
    setSend(true);
    try {
      const apiName = "backendGateway";
      const path = "/invites";
      const inviteBody = {
        invite_email: invite.email,
        manufacturer_id: userInfo.mid,
        team_id: userInfo.tid,
      };
      const myInit = {
        body: inviteBody,
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setAnchorEl(null);
    setSend(false);
  }

  async function resetPassword(user_id) {
    setResetingPassword(true);
    try {
      const apiName = "backendGateway";
      const path = "/invites/resetPassword";
      const myInit = {
        body: {
          user_id,
        },
        headers: {},
      };
      await API.post(apiName, path, myInit).then(() => {});
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setResetingPassword(false);
  }

  async function resendInvite(user_id) {
    setResend(true);
    try {
      const apiName = "backendGateway";
      const path = "/invites/resend";
      const myInit = {
        body: {
          user_id,
        },
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setResend(false);
  }

  const target = (value) => {
    console.log("selectedUserDashboardLayout", selectedUserDashboardLayout);
    return selectedUserDashboardLayout.find(
      (widget) => widget.component === "SalesTargets"
    ).data.salesTargets[value];
  };

  const handleEditChange = (field) => (event) => {
    setSelectedUserDashboardLayout((prevLayout) => {
      const updatedLayout = prevLayout.map((widget) => {
        if (widget.component === "SalesTargets") {
          return {
            ...widget,
            data: {
              ...widget.data,
              salesTargets: {
                ...widget.data.salesTargets,
                [field]: parseInt(event.target.value),
              },
            },
          };
        }
        return widget;
      });
      return updatedLayout;
    });
  };

  const closeTargetsModal = () => {
    setShowChangeTargetsModal(false);
    setSelectedUserDashboardLayout(null);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const handleSnackbarClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSaveConfig = async () => {
    try {
      const postReq = await client.post("/users/layouts", {
        user_id: activeUser.user_id,
        layout: selectedUserDashboardLayout,
      });
      setSnackbarOpen(true);

      console.log("postReq", postReq);
    } catch (err) {
      console.log("error saving config", err);
    }
    closeTargetsModal();
  };

  return (
    <Page
      sx={{
        backgroundColor: "common.white",
        minHeight: "100%",
        paddingBottom: 3,
        paddingTop: 3,
      }}
      title="Manager"
    >
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mx={1}
        my={2}
      >
        <Button
          aria-describedby={id}
          variant="contained"
          color="primary"
          onClick={handleClick}
          style={{
            margin: "5px",
          }}
        >
          Invite Team Member
        </Button>
        <Popover
          id={id}
          open={Boolean(anchorEl)}
          anchorEl={anchorEl}
          onClose={handleClose}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <Card>
            <CardContent>
              <Grid2 container spacing={3}>
                <Grid2 size={12}>
                  <TextField
                    fullWidth
                    label="Email"
                    name="email"
                    onChange={handleChange}
                    required
                    value={invite.email}
                    variant="outlined"
                  />
                </Grid2>
              </Grid2>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                disabled={send}
                onClick={sendInvite}
              >
                SEND
              </Button>
            </Box>
          </Card>
        </Popover>
        <Box>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: "10px" }}
            onClick={() => {
              selectedIDs.forEach((id) => {
                setUserActiveStatus(id, true);
              });
              setRows(
                rows.map((row) => {
                  return {
                    ...row,
                    active: selectedIDs.includes(row.user_id)
                      ? true
                      : row.active,
                  };
                })
              );
            }}
            disabled={selectedIDs.length === 0}
          >
            Activate Selected
          </Button>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: "10px" }}
            onClick={() => {
              selectedIDs.forEach((id) => {
                setUserActiveStatus(id, false);
              });
              setRows(
                rows.map((row) => {
                  return {
                    ...row,
                    active: selectedIDs.includes(row.user_id)
                      ? false
                      : row.active,
                  };
                })
              );
            }}
            disabled={selectedIDs.length === 0}
          >
            Deactivate Selected
          </Button>
        </Box>
        {!selectFormatOpen ? (
          <Button
            title="Export"
            size="small"
            startIcon={<CloudDownload />}
            variant="contained"
            onClick={() => setSelectFormatOpen(true)}
            disabled={exportLoading || !exportEnabled}
          >
            {selectedIDs.length > 0 ? "Export Selected" : "Export All"}
            {exportLoading ? <Loader /> : ""}
          </Button>
        ) : (
          <ButtonGroup style={{ marginTop: "20px" }}>
            <Button onClick={() => {}}>File Type:</Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => emitGlobalExportEvent("CSV")}
              type="button"
              disabled={!exportEnabled}
            >
              CSV
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => emitGlobalExportEvent("XLSX")}
              type="button"
              disabled={!exportEnabled}
            >
              XLSX
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => emitGlobalExportEvent("PDF")}
              type="button"
              disabled={!exportEnabled}
            >
              PDF
            </Button>
            <Button
              variant="outlined"
              style={{ color: "red", borderColor: "red" }}
              onClick={() => setSelectFormatOpen(!selectFormatOpen)}
              type="button"
              disabled={!exportEnabled}
            >
              Cancel
            </Button>
          </ButtonGroup>
        )}
      </Box>
      <div style={{ height: 800, width: "100%" }}>
        <div>
          {(getTeamsApi.loading || getUsersApi.loading) && (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "80px",
              }}
            >
              <CircularProgress />
            </div>
          )}
        </div>
        {!(getTeamsApi.loading && getUsersApi.loading) > 0 && (
          <div
            style={{ backgroundColor: "white", width: "95%", margin: "auto" }}
          >
            <DataGrid
              getRowId={(r) => r.user_id}
              rows={rows}
              columns={columns}
              pageSize={100}
              checkboxSelection
              disableSelectionOnClick
              onRowSelectionModelChange={(ids) => {
                setSelectedIDs(ids);
              }}
            />
          </div>
        )}
      </div>
      <div>
        <SettingsGridModal
          title={`User Settings`}
          open={showAccessModal}
          onClose={() => {
            setShowAccessModal(!showAccessModal);
            refreshData();
          }}
          userAccess={activeUser}
        />
      </div>
      <Modal
        open={showChangeTargetsModal}
        onClose={() => {
          setShowChangeTargetsModal(!showChangeTargetsModal);
        }}
        sx={{
          alignItems: "center",
          justifyContent: "center",
          display: "flex",
        }}
      >
        {!selectedUserDashboardLayout ? (
          <CircularProgress />
        ) : (
          <Box>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: 400,
                bgcolor: "background.paper",
                boxShadow: 24,
                p: 4,
                borderRadius: 2,
              }}
            >
              <Typography
                id="sales-totals-config-modal"
                variant="h6"
                component="h2"
                sx={{ mb: 3 }}
              >
                Configure Sales Targets
              </Typography>

              <TextField
                fullWidth
                label="Monthly Target"
                type="number"
                value={target("month")}
                onChange={handleEditChange("month")}
                margin="normal"
              />

              <Divider sx={{ my: 1 }} />

              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  label="Q1 Target"
                  type="number"
                  value={target("q1")}
                  onChange={handleEditChange("q1")}
                  margin="normal"
                  sx={{ flex: 1 }}
                />

                <TextField
                  label="Q2 Target"
                  type="number"
                  value={target("q2")}
                  onChange={handleEditChange("q2")}
                  margin="normal"
                  sx={{ flex: 1 }}
                />
              </Box>

              <Box sx={{ display: "flex", gap: 2 }}>
                <TextField
                  label="Q3 Target"
                  type="number"
                  value={target("q3")}
                  onChange={handleEditChange("q3")}
                  margin="normal"
                  sx={{ flex: 1 }}
                />

                <TextField
                  label="Q4 Target"
                  type="number"
                  value={target("q4")}
                  onChange={handleEditChange("q4")}
                  margin="normal"
                  sx={{ flex: 1 }}
                />
              </Box>

              <Divider sx={{ my: 1 }} />

              <TextField
                fullWidth
                label="Yearly Target"
                type="number"
                value={target("year")}
                onChange={handleEditChange("year")}
                margin="normal"
              />

              <Box
                sx={{
                  mt: 4,
                  display: "flex",
                  justifyContent: "flex-end",
                  gap: 2,
                }}
              >
                <Button onClick={closeTargetsModal} color="inherit">
                  Cancel
                </Button>
                <Button onClick={handleSaveConfig} variant="contained">
                  Save
                </Button>
              </Box>
            </Box>
          </Box>
        )}
      </Modal>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <Alert
          onClose={handleSnackbarClose}
          severity="success"
          variant="filled"
          sx={{ width: "100%" }}
        >
          Targets saved successfully!
        </Alert>
      </Snackbar>
    </Page>
  );
};

export default TeamManagerView;
