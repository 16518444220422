import React, { useState, useEffect } from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { Avatar, Box, Card, CardContent, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({ className, userInfo, ...rest }) => {
  const { id } = useParams();
  const [profile, setProfile] = useState([]);
  const [checkInCount, setCheckinCount] = useState(0);

  useEffect(() => {
    getCheckinCount();
    getUserData();
  }, [id]);

  async function getUserData() {
    try {
      const data = await API.get(
        "backendGateway",
        "/users/" + (id || userInfo.uid),
        {
          queryStringParameters: {
            mid: userInfo.mid,
            limit: 1000,
          },
        }
      );

      let profileData = data.results.map((item) => ({
        ...item,
        image_src: awsS3Image(item.user_image, item.user_identitykey, "0x100"),
      }));
      setProfile(profileData);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }
  async function getCheckinCount() {
    try {
      const data = await API.get("backendGateway", "/accounts/team/checkin", {
        queryStringParameters: {
          mid: userInfo.mid,
          uid: id,
          limit: 1000,
        },
      });
      //console.log("data from Lambda REST API: ", data);
      setCheckinCount(data.results.length);
    } catch (err) {
      console.log("error fetching data..", err);
    }
  }

  const classes = useStyles();
  return (
    <Card
      style={{
        display: "flex",
        height: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
      {...rest}
    >
      <CardContent>
        {profile.map((item, i) => (
          <Box
            alignItems="center"
            display="flex"
            flexDirection="column"
            key={item.user_id}
          >
            {item.user_image === "gg_default_image.png" && (
              <Avatar
                className={classes.avatar}
                src="/static/images/avatars/gg_default_image.png"
              />
            )}
            {item.user_image !== "gg_default_image.png" && (
              <Avatar className={classes.avatar} src={item.image_src} />
            )}
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h5"
              align="center"
            >
              {item.user_displayname}
            </Typography>
            <Typography color="textSecondary" variant="h6">
              {item.user_jobrole}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {`${item.user_city}, ${item.user_state}`}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              Account CheckIns: {checkInCount}
            </Typography>
          </Box>
        ))}
      </CardContent>
    </Card>
  );
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
