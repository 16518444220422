import React, { useState, useEffect } from "react";
import {
  Grid2,
  TableCell,
  TableBody,
  TableHead,
  TableContainer,
  IconButton,
  CircularProgress,
  Box,
  Button,
  Card,
  Typography,
  Collapse,
  Table,
  TableRow,
  Checkbox,
  Paper,
  Alert,
  AlertTitle,
} from "@mui/material";
import { DateTime } from "luxon";
import { Parser } from "json2csv";
import { API } from "aws-amplify";
import { trackEvent } from "../../../api/analytics";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";
import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterLuxon } from "@mui/x-date-pickers/AdapterLuxon";
import {
  CloudDownload,
  KeyboardArrowDown,
  KeyboardArrowUp,
  Search,
} from "@mui/icons-material";
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minWidth: "100%",
    padding: theme.spacing(3),
  },
  heading: {
    fontWeight: 600,
  },
  button: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const useRowStyles = makeStyles({
  root: {
    "& > *": {
      borderBottom: "unset",
    },
    button: {
      margin: "1px",
    },
  },
});
function Row(props) {
  const { row, index, selectAll, setPrintRows, printRows } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();
  const [localState, setLocalState] = useState(selectAll);

  return (
    <React.Fragment>
      <TableRow className={classes.root}>
        <TableCell>
          <Checkbox
            checked={localState}
            onChange={(e) => {
              setLocalState(e.target.checked);
              printRows[index] = e.target.checked;
              setPrintRows(printRows);
            }}
          />
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
          </IconButton>
        </TableCell>

        <TableCell align="left">
          {new Date(row.date).toISOString().split("T")[0]}
        </TableCell>
        <TableCell align="left">{row.accountID}</TableCell>
        <TableCell align="left">{row.salesRep}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Typography variant="h6" gutterBottom component="div">
                Commitment Details
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>SKU</TableCell>
                    <TableCell>Product</TableCell>
                    <TableCell align="left">Quantity/Cases</TableCell>
                    <TableCell align="left">Size</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row.lineItem.map((itemRow, i) => (
                    <TableRow key={itemRow.productName + i}>
                      <TableCell>{itemRow.sku}</TableCell>
                      <TableCell>{itemRow.productName}</TableCell>
                      <TableCell align="left">{itemRow.qtyCases}</TableCell>
                      <TableCell align="left">{itemRow.size}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const CommitmentListView = () => {
  const [rows, setRows] = useState([]);
  const [selectedValues, setSelectedValues] = useState(false);
  const [loading, setLoading] = useState(true);
  const [printRows, setPrintRows] = useState([]);
  const classes = useStyles();
  const [selectedFromDate, setSelectedFromDate] = useState(
    DateTime.now().minus({ days: 7 })
  );
  const [selectedToDate, setSelectedToDate] = useState(DateTime.now());
  const [showAlert, setShowAlert] = useState(false);
  const store = useStore();
  const { userInfo } = store;
  async function getOrderData() {
    setLoading(true);
    if (
      selectedFromDate &&
      selectedToDate != null &&
      selectedToDate > selectedFromDate
    ) {
      setShowAlert(false);
      setRows([]);
      try {
        const data = await API.get("backendGateway", "/orders", {
          queryStringParameters: {
            mid: userInfo.mid,
            limit: 1000,
            start: encodeURIComponent(selectedFromDate.toFormat("yyyy-MM-dd")),
            end: encodeURIComponent(selectedToDate.toFormat("yyyy-MM-dd")),
          },
        });
        //console.log("data from Lambda REST API: ", data);
        // setVisitList(data.results);
        formatResults(data.results);
      } catch (err) {
        console.log("error fetching data..", err);
      }
    } else {
      setShowAlert(true);
    }

    setLoading(false);
  }
  function formatResults(data) {
    let app = new Map();
    //setSelectedValues(Array(100).fill(false));
    data.forEach(function (arrayItem) {
      let temp = [];
      if (app.get(arrayItem.commitment_uuid)) {
        temp = [
          {
            salesRep: arrayItem.user_displayname,
            accountID: arrayItem.gg_dist_acc_id,
            created: arrayItem.created,
            productName: arrayItem.product_displayname,
            size: arrayItem.product_size,
            qtyCases: arrayItem.quantity,
            sku: arrayItem.distributor_sku,
          },
          ...app.get(arrayItem.commitment_uuid),
        ];
      } else {
        temp = [
          {
            salesRep: arrayItem.user_displayname,
            accountID: arrayItem.gg_dist_acc_id,
            created: arrayItem.created,
            productName: arrayItem.product_displayname,
            size: arrayItem.product_size,
            qtyCases: arrayItem.quantity,
            sku: arrayItem.distributor_sku,
          },
        ];
      }
      app.set(arrayItem.commitment_uuid, temp);
    });
    app.forEach(function (value, key) {
      const r = {
        order_id: key,
        date: value[0].created,
        accountID: value[0].accountID,
        salesRep: value[0].salesRep,
        lineItem: value,
      };
      rows.push(r);
      setRows(rows);
    });
    setPrintRows(new Array(rows.length).fill(false));
    //console.log(app);
    setSelectedValues(false);
    setOrdersList(app);
  }

  useEffect(() => {
    getOrderData();
    trackEvent(userInfo.uid, "PageView:D-Orders");
  }, []);
  const [orders, setOrdersList] = useState([]);
  const handleToDateChange = (luxonDate) => {
    const dateString = luxonDate;

    setSelectedToDate(dateString);
  };
  const handleFromDateChange = (luxonDate) => {
    const dateString = luxonDate;

    setSelectedFromDate(dateString);
  };
  const searchData = () => {
    getOrderData();
  };

  const rowsFlattened = (selectedRows) => {
    let flatRows = new Array();
    selectedRows.map((row) => (flatRows = flatRows.concat(row["lineItem"])));
    return flatRows;
  };
  const exportData = async () => {
    if (printRows.includes(true)) {
      //do the stuff
      const selectedRows = new Array();
      for (var i = 0; i < printRows.length; i++) {
        if (printRows[i] == true) {
          selectedRows.push(rows[i]);
        }
      }
      const data = rowsFlattened(selectedRows);
      const parser = new Parser();
      const csv = parser.parse(data);
      var downloadLink = document.createElement("a");
      var blob = new Blob(["\ufeff", csv]);
      var url = URL.createObjectURL(blob);
      downloadLink.href = url;
      downloadLink.download = "commitments.csv";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    } else {
      setShowAlert(true);
    }
  };
  return (
    <>
      <div align="center">
        {loading && (
          <div>
            {" "}
            <CircularProgress />{" "}
          </div>
        )}
      </div>
      <Grid2 container spacing={3} className={classes.root}>
        <Grid2 size={12}>
          <Card className={classes.button}>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
              <DatePicker
                label="START DATE"
                disableFuture
                value={selectedFromDate}
                onChange={(newDate) => setSelectedFromDate(newDate)}
              />
              <DatePicker
                label="END DATE"
                disableFuture
                value={selectedToDate}
                onChange={(newDate) => setSelectedToDate(newDate)}
              />
            </LocalizationProvider>
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              startIcon={<Search />}
              onClick={() => searchData()}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="secondary"
              className={classes.button}
              startIcon={<CloudDownload />}
              onClick={() => exportData()}
            >
              Export
            </Button>
          </Card>
          <Grid2 size={12}>
            {showAlert && (
              <Alert
                severity="info"
                onClose={() => {
                  setShowAlert(false);
                }}
              >
                <AlertTitle>Info</AlertTitle>
                Start And End Dates Must be Valid, and orders selected.
              </Alert>
            )}
          </Grid2>
        </Grid2>

        <Grid2 size={12}>
          <TableContainer component={Paper}>
            <Table aria-label="collapsible table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">
                    <Checkbox
                      inputProps={{ "aria-label": "Select All" }}
                      onChange={(e) => {
                        setSelectedValues(e.target.checked);
                        let arr = printRows.map(
                          (item) => (item = e.target.checked)
                        );
                        setPrintRows(arr);
                      }}
                    />
                    Select All
                  </TableCell>
                  <TableCell align="left">Date Created</TableCell>
                  <TableCell align="left">Account ID</TableCell>
                  <TableCell align="left">Rep. Name</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row, index) => (
                  <Row
                    key={row.order_id + selectedValues}
                    row={row}
                    index={index}
                    setPrintRows={setPrintRows}
                    printRows={printRows}
                    selectAll={selectedValues}
                  />
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid2>
      </Grid2>
    </>
  );
};

export default CommitmentListView;
