import React from "react";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Close as CloseIcon } from "@mui/icons-material";

export const SortableItem = ({ id, label, isPlaceholder }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({
      id,
    });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    padding: "8px 16px",
    border: "1px solid #ddd",
    borderRadius: "10px",
    background: "#fff",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    opacity: isPlaceholder ? 0.5 : 1,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <div
        style={{ display: "flex", flexDirection: "row", alignItems: "center" }}
      >
        <span
          style={{ cursor: "grab", marginRight: 5 }}
          {...attributes}
          {...listeners}
        >
          &#9776;
        </span>
        <div style={{ fontSize: 14 }}>{label}</div>
      </div>
    </div>
  );
};
