import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Amplify, Auth } from "aws-amplify";
import awsconfig from "../aws-exports";
import { Button } from "@mui/material";

Amplify.configure({
  ...awsconfig,
  aws_cloud_logic_custom: null,
  API: {
    endpoints: awsconfig["aws_cloud_logic_custom"].map((value) => {
      return {
        name: value.name,
        endpoint: value.endpoint,
        custom_header: async () => {
          const token = (await Auth.currentSession())
            .getIdToken()
            .getJwtToken();
          return {
            Authorization: `Bearer ${token}`,
          };
        },
      };
    }),
  },
});

const DeactivatedUserSplash = ({
  signOut,
  clearUserInfo,
  setUserDataLoaded,
}) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant",
    });
    document.documentElement.style.overflowY = "clip";
    return function free() {
      document.documentElement.style.overflowY = "auto";
    };
  }, []);
  return (
    <div className={`deactivatedsplash`}>
      <Helmet>
        <title>{"Account Deactivated"}</title>
      </Helmet>
      <div className="splash-inner">
        <img
          alt="Logo"
          className="header-logo"
          src="/static/images/logos/logo1.png"
          style={{ height: "200px", width: "auto" }}
        />
        <div className="splash-message error">
          This account has been deactivated.
          <br />
          Please contact your system administrator for assistance.
        </div>
        <Button
          color="primary"
          variant="contained"
          style={{ margin: "10px" }}
          onClick={() => {
            clearUserInfo();
            setUserDataLoaded(false);
            signOut();
          }}
        >
          {"Go back to sign in"}
        </Button>
      </div>
    </div>
  );
};

export default DeactivatedUserSplash;
