// STHeatMapNew.js
import React, {
  useState,
  useMemo,
  useCallback,
  useRef,
  useEffect,
} from "react";
import PropTypes from "prop-types";
import { MapContainer as Map, TileLayer } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import L from "leaflet";
import {
  CircularProgress,
  Modal,
  Box,
  Typography,
  Avatar,
} from "@mui/material";
import "leaflet/dist/leaflet.css";
import "react-leaflet-markercluster/dist/styles.min.css";
import AccountMarker from "./AccountMarker";
import { DateTime } from "luxon";
import { Button } from "@mui/material";
import { useNavigate } from "react-router";
import { DefaultAccountBottleById } from "../../../views/product/DefaultBottleSVG/getDefaultProductSVG";
import { random } from "lodash";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 600,
  borderRadius: "2px",
  backgroundColor: "white",
  p: 4,
};

const DefaultIcon = L.icon({
  iconUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-icon.png",
  shadowUrl:
    "https://cdnjs.cloudflare.com/ajax/libs/leaflet/1.7.1/images/marker-shadow.png",
});

L.Marker.prototype.options.icon = DefaultIcon;

const STHeatMapNew = ({ accounts, onlyShowTheseAccounts = [] }) => {
  const [selectedAccount, setSelectedAccount] = useState(null);
  const navigate = useNavigate();

  const handleMarkerClick = useCallback(
    (account_id) => {
      setSelectedAccount(accounts[account_id]);
    },
    [accounts]
  );

  const markers = useMemo(() => {
    if (onlyShowTheseAccounts.length > 0) {
      return onlyShowTheseAccounts.map((accountId) => (
        <AccountMarker
          key={accountId}
          accountId={accountId}
          coords={accounts[accountId]}
          handleMarkerClick={handleMarkerClick}
        />
      ));
    }

    return Object.entries(accounts).map(([accountId, coords]) => (
      <AccountMarker
        key={accountId}
        accountId={accountId}
        coords={coords}
        handleMarkerClick={handleMarkerClick}
      />
    ));
  }, [accounts, handleMarkerClick, onlyShowTheseAccounts]);

  return (
    <>
      <Map
        center={[39.5, -98.35]}
        zoom={5}
        style={{ maxHeight: 500, width: "100%", zIndex: "0" }}
        preferCanvas={true}
      >
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a>'
        />
        <MarkerClusterGroup
          chunkedLoading
          maxClusterRadius={50}
          showCoverageOnHover={false}
        >
          {markers}
        </MarkerClusterGroup>
      </Map>

      <Modal
        open={Boolean(selectedAccount)}
        onClose={() => setSelectedAccount(null)}
        aria-labelledby="account-details-title"
        aria-describedby="account-details-description"
      >
        <Box sx={modalStyle}>
          {selectedAccount ? (
            <>
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Typography
                  id="account-details-title"
                  variant="h6"
                  component="h2"
                >
                  {selectedAccount.name || "N/A"}
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    navigate(`/app/accountdetails/${selectedAccount.id}`);
                  }}
                >
                  Go to this Account
                </Button>
              </div>
              <Typography id="account-details-description" sx={{ mt: 2 }}>
                <strong>Address:</strong> {selectedAccount.address || "N/A"}{" "}
                {selectedAccount.account_address_2 || ""}
                <br />
                <strong>Region:</strong> {selectedAccount.region || "N/A"}
                <br />
                <strong>Premise Type:</strong>{" "}
                {selectedAccount.premiseType || "N/A"}
                <br />
                <strong style={{ marginTop: 20 }}>Products:</strong>
                <div style={{ maxHeight: "300px", overflowY: "scroll" }}>
                  {Array.isArray(selectedAccount.productsSold) ? (
                    selectedAccount.productsSold.map((product, index) => {
                      return (
                        <div
                          key={index}
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            borderBottom: "1px solid #C9C9C9",
                            marginBottom: 10,
                            paddingBottom: 5,
                          }}
                        >
                          {product.productUrl !== "" ? (
                            <Avatar
                              alt={product.productName}
                              src={product.productUrl}
                              style={{ background: "white", marginRight: 10 }}
                            />
                          ) : (
                            // TODO: This is a temporary solution to show a default bottle image, need to bring actual product_id into the data
                            <DefaultAccountBottleById
                              product_id={random(1, 1000)}
                              width={50}
                              height={50}
                            />
                          )}
                          <div>
                            {product.productName}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 20,
                                fontSize: 12,
                                fontWeight: "400",
                                color: "gray",
                              }}
                            >
                              <div>
                                Total Sold -
                                <div>
                                  {product?.totalSold !== null &&
                                  product?.totalSold !== undefined
                                    ? product.totalSold.toFixed(2)
                                    : "0.00"}
                                </div>
                              </div>
                              <div>
                                Last Sold -{" "}
                                {DateTime.fromJSDate(
                                  product.lastSoldDate
                                ).toLocaleString(DateTime.DATE_MED)}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    })
                  ) : (
                    <div>No products sold</div>
                  )}
                </div>
                <br />
                <strong>Total Sales:</strong>{" "}
                <div>
                  {selectedAccount?.totalSold !== null &&
                  selectedAccount?.totalSold !== undefined
                    ? selectedAccount.totalSold.toFixed(2)
                    : "0.00"}
                </div>
                <br />
              </Typography>
            </>
          ) : (
            <CircularProgress />
          )}
        </Box>
      </Modal>
    </>
  );
};

STHeatMapNew.propTypes = {
  accounts: PropTypes.objectOf(
    PropTypes.shape({
      lat: PropTypes.number,
      long: PropTypes.number,
      name: PropTypes.string.isRequired,
      address: PropTypes.string,
    })
  ).isRequired,
};

export default STHeatMapNew;
