export const columns = [
  {
    field: "prod_name",
    headerName: "Product",
    width: 200,
  },
  {
    field: "dist_name",
    headerName: "Distributor",
    width: 200,
  },
  {
    field: "units",
    headerName: "Units",
  },
  {
    field: "sum_units",
    headerName: "Totals",
  },
  {
    field: "premise",
    headerName: "Premise Type",
    width: 200,
  },
  {
    field: "sales_year",
    headerName: "Year",
  },
  {
    field: "acc_name",
    headerName: "Account",
    width: 200,
  },
  {
    field: "acc_id",
    headerName: "Acc #",
    width: 200,
  },
];

export const storedReports = [
  {
    id: 1,
    name: "Top Products",
    groups: ["prod_id"],
    orderBy: "units",
    orderDir: "desc",
    columns: ["prod_name", "units"],
    operator: "sum",
    field: "unit",
    desc: "Get units sold for a product in a given timeframe",
  },
  {
    id: 2,
    name: "Top Accounts",
    groups: ["acc_id"],
    orderBy: "units",
    orderDir: "desc",
    columns: ["acc_name", "units"],
    operator: "sum",
    field: "unit",
    desc: "Get units sold by an account in a given timeframe",
  },
  {
    id: 21,
    name: "Top Account Products",
    groups: ["acc_id", "prod_id"],
    orderBy: "units",
    orderDir: "desc",
    columns: ["acc_name", "prod_name", "sum_units", "acc_id"],
    operator: "sum",
    field: "unit",
    desc: "Get units sold of a product by an account in a given timeframe",
  },
  {
    id: 3,
    name: "Distributor YoY CS",
    groups: ["dist_id", "sales_year"],
    orderBy: "units",
    orderDir: "desc",
    columns: ["dist_name", "sales_year", "sum_units"],
    operator: "sum",
    field: "units",
    showEach: ["year"],
    start: "2022-01-01",
    end: "2024-12-31",
    desc: "Year on Year totals, including a grand total for all distributors. Sum of case eqv. (seems costly)",
  },
  {
    id: 4,
    name: "Account Sold (product) YoY",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: [],
    operator: "",
    field: "",
    desc: "Year on year count of unique accounts on sales for a brand or product",
  },
  {
    id: 5,
    name: "Account Sold x Distributor YoY",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: ["dist_name", "units_"],
    operator: "",
    field: "",
    desc: "Year on year count of unique accounts on sales for a distributor",
  },
  {
    id: 6,
    name: "Chain YoY By State",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: [],
    operator: "",
    field: "",
    desc: "Unit sales, year by year and grand total for chains, grouped by state",
  },
  {
    id: 7,
    name: "Growth of An Account",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: [],
    operator: "",
    field: "",
    desc: "Year on year totals for an account, with grand total",
  },
  {
    id: 8,
    name: "RAW",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: [],
    operator: "",
    field: "",
    desc: "Flat data, more likely for export that display",
  },
  {
    id: 9,
    name: "Multi Unit, Different Orientation -Premise YoY",
    groups: ["sales_year", "premise"], // used for MySQL grouping on the server
    orderBy: "sales_year",
    orderDir: "desc",
    columns: ["premise", "sales_year", "sum_units"], // used for display client side
    gridGroups: ["premise"],
    operator: "", // sum by default
    field: "", // units by default
    start: "2022-01-01",
    end: "2024-12-31",
    desc: "Total units by year, grouped by premise type",
  },
  {
    id: 10,
    name: "Stale Accounts",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: [],
    operator: "",
    field: "",
  },
  {
    id: 11,
    name: "Fresh New Accounts",
    groups: [],
    orderBy: "",
    orderDir: "desc",
    columns: [],
    operator: "",
    field: "",
  },
];

export const queryVariants = [
  {
    name: "1 brand",
    query: {
      brand: ["Soft Parade"],
    },
  },
  {
    name: "2 dists",
    query: {
      dist_id: [992, 1008],
    },
  },
  {
    name: "1 account",
    query: {
      acc_id: [2046828],
    },
  },
  {
    name: "2 accounts",
    query: {
      acc_id: [2046828, 2358078],
    },
  },
  {
    name: "3 dists, 2 brands",
    query: {
      dist_id: [1036, 1019, 1028],
      brand: ["Melt My Brain", "Pulsar"],
    },
  },
  {
    name: "1 state, 2 brands",
    query: {
      state_id: ["WI"],
      brand: ["Melt My Brain", "Pulsar"],
    },
  },
  {
    name: "2 states",
    query: {
      state_id: ["WI", "IL"],
    },
  },
];

export const newQuery = {
  select: {
    dimensions: ["dist_id", "premise_id", "prod_id"],
    // dimensions: ["dist_id", "premise_id", "prod_id", "repname", "zip"],
    measures: ["cases"],
  },
  filters: {
    dimensions: {
      dist_id: [1, 4, 6],
      premise_id: [1],
    },
    time: {
      granularity: "month",
      offset: 202501,
      periods: 2, /// 202411, 202412
    },
  },
  group_by: ["dist_id", "premise_id", "prod_id"],
  comparisons: {
    includeTotals: true,
    includeDifferences: true, //diff pct
    comparsionPeriod: true, // month nov-jan 2024/25,  nov-jan 23/24, //year 2023-2025
  },
};

export const rollUpRows = (rows) => {
  // Guard against rows being null, undefined, or not an array
  if (!Array.isArray(rows)) {
    return [];
  }

  return rows
    .filter((r) => r !== undefined && r !== null) // Filter out undefined/null elements first
    .filter((r) => r?.id !== "grandTotal")
    .map((row, index, arr) => {
      // blank redundant values
      let hide = [];
      if (index > 0) {
        const prev = arr[index - 1];
        Object.keys(row).forEach((r) => {
          if (row[r] === prev[r] && isNaN(row[r])) {
            hide.push(r);
          }
        });
      }
      return { id: index, ...row, hide };
    });
};

export const getRowClassName = (
  {
    columns,
    row,
    id,
    indexRelativeToCurrentPage,
    isFirstVisible,
    isLastVisible,
  },
  { select }
) => {
  let classes = [];

  if (row && typeof row.id === "string") {
    // Only apply depth classes to non-detail rows
    if (!row.id.startsWith("detail_")) {
      const underscores = row.id.match(/_/g) || [];
      // Subtract 1 from underscore count to get the "depth"
      const depth = underscores.length - 1;
      if (depth >= 0) {
        classes.push("depth-" + depth);
      }
    }
  }

  // select.dimensions might be undefined or not an array, so guard for that
  if (Array.isArray(select.dimensions) && row) {
    // Check if any select.dimensions field is null
    const isTotalsRow = select.dimensions.some((g) => row[g] === null);
    if (isTotalsRow) {
      classes.push("totals-row");
    }
  }

  return classes.join(" ");
};

export const getCellClassName = (params, requestBody) => {
  // Guard for missing params, row, or field
  if (!params || !params.row || !params.field) {
    return "";
  }

  // row.hide might not exist or might not be an array
  if (
    Array.isArray(params.row.hide) &&
    params.row.hide.includes(params.field) &&
    requestBody.comparisons.includeTotals
  ) {
    return "muted";
  }

  return "";
};

/**
 * helpers.js
 */

// 1) Returns a row class name (primarily used for group vs detail vs grandTotal).
export const getPreviewTableRowClassName = (params, requestBody) => {
  if (!requestBody?.select.dimensions?.length || !params.row?.id) return "";

  const rowId = params.row.id;
  // Detail row => starts with "detail_"
  if (rowId.startsWith("detail_")) {
    return "MuiDataGrid-row";
  }

  // Otherwise, it's a group row
  // Depth can be determined by # of underscores in the id + 1
  const depth = (rowId.match(/_/g) || []).length + 1;
  return `MuiDataGrid-row depth-${depth}`;
};

// 2) Returns a cell class name (handles style for group vs detail vs grandTotal).
export const getPreviewTableCellClassName = (params, requestBody) => {
  if (!requestBody?.select.dimensions?.length || !params.row?.id) return "";

  const rowId = params.row.id;
  const columnField = params.field;

  // If grand total row => apply "white-text" (or your custom style)
  if (rowId === "grandTotal") {
    return "MuiDataGrid-cell white-text";
  }

  // Is it a detail row? (e.g. "detail_123")
  const isDetailRow = rowId.startsWith("detail_");
  if (isDetailRow) {
    // Normal cell styling for detail
    return "MuiDataGrid-cell";
  }

  // Otherwise, it's a group row.
  // By default, you had logic to "mute" columns to the left of the group level.
  // We need to skip that logic for the 'totals' column so it doesn't get "muted".

  const groupByFields = requestBody.select.dimensions;
  const currentGroupIndex = groupByFields.indexOf(columnField);

  // Depth is # underscores in the ID + 1
  const depth = (rowId.match(/_/g) || []).length + 1;

  // If this column is in groupByFields and is to the left of the "current" group depth,
  // it gets "muted." BUT do NOT mute if it's the "totals" column!
  if (columnField !== "totals" && currentGroupIndex < depth - 1) {
    return "MuiDataGrid-cell muted";
  }

  // If not muted, then let's apply "white-text" for group rows.
  return "MuiDataGrid-cell white-text";
};
