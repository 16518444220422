import { useState } from "react";
import {
  FormControl,
  FormControlLabel,
  RadioGroup,
  Radio,
  FormHelperText,
} from "@mui/material";
import StLabel from "./StLabel";

const StRadio = ({
  required,
  label,
  error,
  helperText,
  options,
  answer = { value: [] },
  changeAnswer = () => {},
}) => {
  const initValue = answer.value.length > 0 ? answer.value[0] : null;
  const [value, setValue] = useState(initValue);

  const handleChange = (event) => {
    setValue(event.target.value);
    const matchingOption = options.find((o) => o.key === event.target.value);
    if (matchingOption) {
      if (!answer.value.includes(matchingOption.key)) {
        changeAnswer([matchingOption.key], matchingOption.text);
      }
    }
  };

  const [active, setActive] = useState(false);

  return (
    <FormControl fullWidth required={required} error={error}>
      <StLabel required={required} text={label} error={error} active={active} />
      <RadioGroup
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        aria-label={label}
        value={value}
        onChange={handleChange}
      >
        {options.map((option) => (
          <FormControlLabel
            key={option.key}
            control={<Radio />}
            label={option.text}
            value={option.key}
          />
        ))}
      </RadioGroup>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      {/* <p>{JSON.stringify(options)}</p>
      <p>{JSON.stringify(answer)}</p> */}
    </FormControl>
  );
};

export default StRadio;
