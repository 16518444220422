import APICallWrapper from "./APICallWrappers";
import client from "./client";

const getTeamProfile = async ({ mid, uid, limit }) => {
  const key = `getTeamProfile-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading team profile...",
    "Team profile loaded successfully.",
    "Failed to load team profile.",
    () =>
      client.get("/accounts/team/profile", { mid: mid, uid: uid, limit: limit })
  );
};

const postTeamList = async (profile) => {
  const key = `postTeamList-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Posting team list...",
    "Team list posted successfully.",
    "Failed to post team list.",
    () => client.post("/accounts/team/list", profile)
  );
};

const getTeamCheckin = async ({ mid, uid, limit }) => {
  const key = `getTeamCheckin-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading team check-in data...",
    "Team check-in data loaded successfully.",
    "Failed to load team check-in data.",
    () =>
      client.get("/accounts/team/checkin", { mid: mid, uid: uid, limit: limit })
  );
};

const getScorecardData = async () => {
  const key = `getScorecardData-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading scorecard data...",
    "Scorecard data loaded successfully.",
    "Failed to load scorecard data.",
    () => client.get("/teams/scorecard")
  );
};

const getTeamPerformance = async ({ mid, limit }) => {
  const key = `getTeamPerformance-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading team performance data...",
    "Team performance data loaded successfully.",
    "Failed to load team performance data.",
    () => client.get("/teams/manager", { mid: mid, limit: limit })
  );
};

const postTeamTarget = async (target) => {
  const key = `postTeamTarget-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Posting team target...",
    "Team target posted successfully.",
    "Failed to post team target.",
    () => client.post("/teams/targets", target)
  );
};

const getTeamTarget = async ({ mid, uid, limit }) => {
  const key = `getTeamTarget-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading team targets...",
    "Team targets loaded successfully.",
    "Failed to load team targets.",
    () => client.get("/teams/targets", { mid: mid, uid: uid, Limit: limit })
  );
};

const getTeamActivity = async (mid) => {
  const key = `getListOfAllData-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading team activity...",
    "Activity loaded successfully.",
    "Failed to load activity.",
    () => client.get("/teams/activities/withformid", { mid })
  );
};

export default {
  getTeamProfile,
  postTeamList,
  getTeamCheckin,
  getTeamPerformance,
  postTeamTarget,
  getTeamTarget,
  getScorecardData,
  getTeamActivity,
};
