import { useState } from "react";
import ReportsHistory from "../../components/reports/ReportsHistory";
import { Box, Container, Card, CardContent, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import Page from "../../components/Page";

const ReportHistoryView = ({}) => {
  const [reports, setReports] = useState([]);
  const useStyles = makeStyles((theme) => ({
    root: {
      backgroundColor: theme.palette.background.dark,
      minHeight: "100%",
      paddingBottom: theme.spacing(3),
      paddingTop: theme.spacing(3),
    },
    button: {
      margin: theme.spacing(1),
    },
  }));
  const classes = useStyles();

  return (
    <Page title="Report History">
      <Container className={classes.root}>
        <Card>
          <CardContent>
            <Box p={2} display="flex" justifyContent={"center"}>
              <Box>
                <Typography variant="h4" sx={{ mb: 2 }}>
                  Report History
                </Typography>
                <ReportsHistory
                  size="large"
                  {...{
                    reports,
                    setReports,
                  }}
                />
              </Box>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default ReportHistoryView;
