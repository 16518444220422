import React, { useState, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { Storage } from "aws-amplify";
import { MapContainer as Map, TileLayer, Marker, Tooltip } from "react-leaflet";
import MarkerClusterGroup from "react-leaflet-markercluster";
import { observer } from "mobx-react-lite";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import MapToolTipGetter from "../../../views/reports/ChartsView/MapToolTipGetter";
import MapAccountCard from "../../../views/reports/ChartsView/MapAccountCard";
import { useStore } from "../../../stores/StoreContext";
import { CircularProgress } from "@mui/material";
import statsAPI from "../../../api/stats";
import useQueryParamsBuilder from "../../megaFilter/useQueryParamsBuilder";
Storage.configure({ level: "protected" });

// Import marker icons
const onPremiseLessThan = "/static/images/icons/on_premise_lessthan.png";
const onPremiseMoreThan = "/static/images/icons/on_premise_morethan.png";
const onPremiseNever = "/static/images/icons/on_premise_never.png";
const offPremiseLessThan = "/static/images/icons/off_premise_lessthan.png";
const offPremiseMoreThan = "/static/images/icons/off_premise_morethan.png";
const offPremiseNever = "/static/images/icons/off_premise_never.png";
const otherLessThan = "/static/images/icons/other_lessthan.png";
const otherMoreThan = "/static/images/icons/other_morethan.png";
const otherNever = "/static/images/icons/other_never.png";

const initMapState = {
  // center of contiguous USA as default
  center: [39.5, -98.35],
  zoom: 5,
};

const makeAccountClusters = ({ accounts, radius, maxZoom }) => {
  return accounts.map((a) => ({
    type: "Feature",
    geometry: {
      type: "Point",
      coordinates: [
        parseFloat(a.account_latitude),
        parseFloat(a.account_longitude),
      ],
    },
    properties: {
      name: a.account_displayname,
      acc_id: a.acc_id,
      premiseType: a.premise_type,
      last_sale_date: a.account_last_sale_date,
    },
  }));
};

// Function to determine which icon to use
const getMarkerIcon = (premiseType, lastSaleDate) => {
  let iconUrl;

  // Determine premise type category
  const premiseCategory =
    premiseType === "ON-PREMISE"
      ? "on_premise"
      : premiseType === "OFF-PREMISE"
      ? "off_premise"
      : "other";

  // Determine sale date category
  let saleCategory;
  if (!lastSaleDate) {
    saleCategory = "never";
  } else {
    // Check if sale date is within 90 days
    const saleDate = new Date(lastSaleDate);
    const now = new Date();
    const daysDiff = Math.floor((now - saleDate) / (1000 * 60 * 60 * 24));
    saleCategory = daysDiff <= 90 ? "lessthan" : "morethan";
  }

  // Get the appropriate icon
  switch (premiseCategory) {
    case "on_premise":
      iconUrl =
        saleCategory === "lessthan"
          ? onPremiseLessThan
          : saleCategory === "morethan"
          ? onPremiseMoreThan
          : onPremiseNever;
      break;
    case "off_premise":
      iconUrl =
        saleCategory === "lessthan"
          ? offPremiseLessThan
          : saleCategory === "morethan"
          ? offPremiseMoreThan
          : offPremiseNever;
      break;
    default:
      iconUrl =
        saleCategory === "lessthan"
          ? otherLessThan
          : saleCategory === "morethan"
          ? otherMoreThan
          : otherNever;
  }

  return L.icon({
    iconUrl,
    iconSize: [30, 30],
    iconAnchor: [15, 30],
    popupAnchor: [0, -30],
  });
};

const STHeatMapCluster = observer(({ refresh }) => {
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(initMapState.zoom);
  const [center, setCenter] = useState(initMapState.center);
  const [accountHash, setAccountHash] = useState({});
  const [accs, setAccs] = useState([]);
  const [targetAccount, setTargetAccount] = useState(null);
  const { selectedQuantity, quantities, selectedDistributors } = useStore();

  const { queryParams } = useQueryParamsBuilder();

  const loadAccount = (acc_id) => {
    statsAPI
      .getAccountTopProducts({
        acc_id,
      })
      .then(({ results }) => {
        setAccountHash({
          ...accountHash,
          [acc_id]: results,
        });
      })
      .catch((error) => console.error(error));
  };
  const load = () => {
    statsAPI
      .getMapMarkers({ ...queryParams, dists: selectedDistributors })
      .then(({ results }) => {
        setAccs(
          results.filter((r) => r.account_latitude && r.account_longitude)
        );
      })
      .catch((error) => console.error(error));
  };

  const apiMarkers = useMemo(() => {
    return makeAccountClusters({ accounts: accs });
  }, [accs]);

  useEffect(() => {
    if (queryParams !== null) {
      load();
    }
  }, [queryParams]);

  const measurementUnit = useMemo(() => {
    const measurementUnit = {
      ...quantities.find((q) => q.value === selectedQuantity),
    };
    return measurementUnit;
  }, [quantities, selectedQuantity]);

  useEffect(() => {
    if (apiMarkers.length && map) {
      // return console.log(apiMarkers[0])
      const markerBox = apiMarkers.reduce(
        (acc, cur) => {
          const { coordinates } = cur.geometry;
          acc[0][0] = Math.min(coordinates[0], acc[0][0] || Infinity);
          acc[0][1] = Math.min(coordinates[1], acc[0][1] || Infinity);
          acc[1][0] = Math.max(coordinates[0], acc[1][0] || -Infinity);
          acc[1][1] = Math.max(coordinates[1], acc[1][1] || -Infinity);
          return acc;
        },
        [
          [null, null],
          [null, null],
        ]
      );
      const bounds = L.latLngBounds(markerBox);
      map.fitBounds(bounds);
    }
  }, [apiMarkers, map]);

  useEffect(() => {
    if (!map) return;
    const c = [map.getCenter().lat, map.getCenter().lng];
    const z = map.getZoom();
    map.invalidateSize();
    map.setView(c, z);
  }, [map, refresh]);

  useEffect(() => {
    if (!map) return;
    setCenter([39.5, -78.35]);
    setZoom(5);
    map.invalidateSize(true);
  }, [map]);

  return (
    <>
      {targetAccount !== null && (
        <MapAccountCard
          account={targetAccount}
          close={() => setTargetAccount(null)}
          measurementUnit={measurementUnit}
        />
      )}
      {apiMarkers.length > 0 ? (
        <div style={{ height: "380px", width: "100%" }}>
          <Map
            whenCreated={(mapInstance) => {
              setMap(mapInstance);
            }}
            zoomControl={false}
            center={center}
            zoom={zoom}
            style={{
              zIndex: "1",
              marginBottom: 10,
              minHeight: "100%",
              height: "100%",
            }}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MarkerClusterGroup>
              {apiMarkers.map((item) => {
                const position = [
                  item.geometry.coordinates[0],
                  item.geometry.coordinates[1],
                ];
                const icon = getMarkerIcon(
                  item.properties.premiseType,
                  item.properties.last_sale_date
                );

                return (
                  <Marker
                    key={item.properties.acc_id}
                    position={position}
                    icon={icon}
                    eventHandlers={{
                      click: () => {
                        setTargetAccount(item);
                      },
                    }}
                  >
                    <Tooltip
                      direction="auto"
                      permanent={false}
                      offset={[0, -30]}
                      opacity={1}
                      className="custom-tooltip"
                    >
                      <MapToolTipGetter
                        account={item}
                        measurementUnit={measurementUnit}
                        {...{
                          accountHash,
                          loadAccount,
                        }}
                      />
                    </Tooltip>
                  </Marker>
                );
              })}
            </MarkerClusterGroup>
          </Map>
        </div>
      ) : (
        <CircularProgress />
      )}
    </>
  );
});

STHeatMapCluster.propTypes = {
  className: PropTypes.string,
};

export default STHeatMapCluster;
