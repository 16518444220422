import React, { useState, useEffect } from "react";
import {
  DndContext,
  closestCenter,
  DragOverlay,
  useDroppable,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  arrayMove,
} from "@dnd-kit/sortable";
import { SortableItem } from "./SortableItem";
import { Typography } from "@mui/material";

// Main component definition
const GroupingSection = ({
  selectedFields,
  fieldGroups,
  requestBody,
  setRequestBody,
}) => {
  // Flag to ensure we only initialize once.
  const [groups, setGroups] = useState([]);
  const [activeItemId, setActiveItemId] = useState(null);

  const flattenedGroups = Object.values(fieldGroups)
    .map((f) => f.fields)
    .flat();
  useEffect(() => {
    const selected = requestBody.select.dimensions;
    if (!Array.isArray(selected)) {
      console.log(selected);
      return [];
    }

    // get current group as they would be used in the UI
    const newGroupResults = requestBody.select.dimensions.map((g) =>
      flattenedGroups.find((fg) => fg.value === g)
    );
    // update groups in UI if needed
    if (JSON.stringify(groups !== JSON.stringify(newGroupResults))) {
      setGroups(newGroupResults);
    }
  }, [requestBody, requestBody.select.dimensions, setGroups, fieldGroups]);

  const handleGroupByChanges = (groupByItems) => {
    // Map the fields to their groupIdMapping values
    const mappedCols = groupByItems.map((field) => field.value);
    if (JSON.stringify(mappedCols !== JSON.stringify(requestBody.group_by))) {
      setRequestBody({
        ...requestBody,
        select: {
          ...requestBody.select,
          dimensions: mappedCols,
        },
      });
    }
  };

  // =============================================================================
  // Drag End Handler
  // =============================================================================
  const handleDragEnd = (event) => {
    const { active, over } = event;
    setActiveItemId(null);
    if (!over) return;

    // Check if the dragged item is within the groups list.
    // If the drag ended within the same list and in groups, re-order.
    const oldIndex = groups.findIndex((field) => field.value === active.id);
    const newIndex = groups.findIndex((field) => field.value === over.id);
    const updatedList = arrayMove(groups, oldIndex, newIndex);
    handleGroupByChanges(updatedList);
  };

  // =============================================================================
  // Drag Start Handler: Track the active item’s ID.
  // =============================================================================
  const handleDragStart = (event) => {
    const { active } = event;
    setActiveItemId(active.id);
  };

  // =============================================================================
  // Delete Handler: Remove an item from groups and add it back to ungroups.
  // =============================================================================
  const handleDelete = (itemToDelete) => {
    setGroups((current) => {
      const newGroupByFields = current.filter(
        (item) => item.value !== itemToDelete.value
      );
      handleGroupByChanges(newGroupByFields);
      return newGroupByFields;
    });
  };

  // =============================================================================
  // Placeholder Component for empty droppable areas.
  // =============================================================================
  const Placeholder = ({ id }) => {
    const { setNodeRef } = useDroppable({ id });
    return (
      <div
        ref={setNodeRef}
        style={{
          padding: "8px 16px",
          border: "1px dashed #ccc",
          borderRadius: "8px",
          background: "#ECECEC",
          textAlign: "center",
          cursor: "pointer",
        }}
      >
        Drag items here
      </div>
    );
  };

  // =============================================================================
  // Clear All Handler: Clears all selected group-by fields.
  // =============================================================================
  const handleClearSelected = () => {
    setGroups([]);
    handleGroupByChanges([]);
  };

  // =============================================================================
  // Main Render
  // =============================================================================
  return (
    <DndContext
      collisionDetection={closestCenter}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
    >
      <div style={{ padding: "16px" }}>
        <Typography variant="subtitle1" sx={{ px: 1, pb: 1 }}>
          Drag and drop fields to sort
        </Typography>
        {/* Group By Section */}
        <div
          style={{
            marginBottom: "16px",
            background: "#ECECEC",
            borderRadius: 15,
            padding: 10,
          }}
        >
          <SortableContext
            items={
              groups.length > 0
                ? groups.map((field) => field.value)
                : ["groupByPlaceholder"]
            }
            strategy={verticalListSortingStrategy}
          >
            {groups.length > 0 ? (
              groups.map((field) => (
                <SortableItem
                  onDelete={() => handleDelete(field)}
                  key={field.value}
                  id={field.value}
                  label={field.label}
                  isPlaceholder={field.value === activeItemId}
                />
              ))
            ) : (
              <Placeholder id="groupByPlaceholder" />
            )}
          </SortableContext>
        </div>
      </div>

      {/* DragOverlay shows the dragged item */}
      <DragOverlay>
        {activeItemId ? (
          <div
            style={{
              padding: "8px 16px",
              border: "1px solid #ddd",
              borderRadius: "8px",
              background: "#fff",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <span>&#9776;</span>
            {groups.find((f) => f.value === activeItemId)?.label}
          </div>
        ) : null}
      </DragOverlay>
    </DndContext>
  );
};

export default GroupingSection;
