import React, { useState, useMemo } from "react";
import {
  Box,
  Button,
  Grid2,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  RadioGroup,
  ListItemText,
  Checkbox,
  FormControlLabel,
  Radio,
  Typography,
} from "@mui/material";
import { DateTime } from "luxon";
import YearMonthFilter from "../menu/YearMonthFilter";
import { minWidth, positions } from "@mui/system";

const selectStyle = {
  minWidth: 200,
};

const Toggler = ({ action, fraction }) => {
  const buttonText =
    fraction.selected === 0
      ? "Select All"
      : fraction.selected < fraction.available
      ? `${fraction.selected}/${fraction.available}`
      : "Select None";

  return (
    <Box
      sx={{
        position: "sticky",
        top: 0,
        backgroundColor: "white",
        padding: 1,
        zIndex: 1,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-around",
      }}
    >
      <Typography fontSize={24}>Toggle All </Typography>
      <Button onClick={action} variant="contained" size="small">
        {buttonText}
      </Button>
    </Box>
  );
};

const FilterMenu = ({
  distributors = [],
  brands = [],
  products = [],
  premiseTypes = [],
  unitOfMeasures = ["Case Eqv.", "Cases", "Units"],
  onApplyFilters,
  onResetFilters,
  showDates = true,
  showUnitOfMeasure = true,
  showAlternativeDate = false,
  defaultDateRange = { start: "", end: "" },
}) => {
  const [selectedDistributors, setSelectedDistributors] = useState([]);
  const [dateRange, setDateRange] = useState(defaultDateRange);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [selectedPremiseTypes, setSelectedPremiseTypes] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState("Case Eqv.");
  const [predefinedFilter, setPredefinedFilter] = useState("");
  const [dateFilterType, setDateFilterType] = useState("predefined");

  const isApplyDisabled = useMemo(() => {
    const { start, end } = dateRange;
    return !(
      start &&
      end &&
      selectedDistributors.length > 0 &&
      selectedPremiseTypes.length > 0
    );
  }, [dateRange, selectedDistributors, selectedPremiseTypes]);

  const calculatePredefinedDateRange = (filter) => {
    const now = DateTime.now();
    switch (filter) {
      case "1 Month":
        return {
          start: now.minus({ months: 1 }).toISODate(),
          end: now.toISODate(),
        };
      case "3 Months":
        return {
          start: now.minus({ months: 3 }).toISODate(),
          end: now.toISODate(),
        };
      case "QTD": {
        const startOfQuarter = now.startOf("quarter");
        return { start: startOfQuarter.toISODate(), end: now.toISODate() };
      }
      case "YTD": {
        const startOfYear = now.startOf("year");
        return { start: startOfYear.toISODate(), end: now.toISODate() };
      }
      case "12 Months":
        return {
          start: now.minus({ months: 12 }).toISODate(),
          end: now.toISODate(),
        };
      default:
        return { start: "", end: "" };
    }
  };

  const handlePredefinedFilterChange = (filter) => {
    setPredefinedFilter(filter);
    setDateRange(calculatePredefinedDateRange(filter));
  };

  const handleDateChange = (field, value) => {
    setPredefinedFilter("");
    setDateRange((prev) => ({ ...prev, [field]: value }));
  };

  const unitMapping = {
    "Case Eqv.": "cases_eqv",
    Cases: "cases",
    Units: "unit_sold",
  };

  const handleApplyFilters = () => {
    onApplyFilters({
      distributorId: selectedDistributors,
      dateRange: [dateRange.start, dateRange.end],
      selectedBrands,
      selectedProducts,
      premiseTypes: selectedPremiseTypes,
      quantityType: unitMapping[selectedUnit],
    });
  };

  const handleYearMonthChange = ([start, end]) => {
    if (start && end) {
      setDateRange({ start, end });
    }
  };

  const handleResetFilters = () => {
    setSelectedDistributors([]);
    setDateRange(defaultDateRange);
    setSelectedBrands([]);
    setSelectedProducts([]);
    setSelectedPremiseTypes([]);
    setSelectedUnit("Case Eqv.");
    setPredefinedFilter("");
    onResetFilters();
  };

  const toggleAll = ({ filterProp, makeActive = true }) => {
    const actions = {
      brands: {
        source: brands.map((p) => p.id),
        setter: setSelectedBrands,
      },
      premiseTypes: {
        source: premiseTypes,
        setter: setSelectedPremiseTypes,
      },
      distributors: {
        source: distributors.map((p) => p.id),
        setter: setSelectedDistributors,
      },
      products: {
        source: products.map((p) => p.id),
        setter: setSelectedProducts,
      },
    };
    if (actions[filterProp]) {
      if (makeActive) {
        actions[filterProp].setter(actions[filterProp].source);
      } else {
        actions[filterProp].setter([]);
      }
    }
  };

  return (
    <Grid2 container spacing={2} style={{ maxWidth: "90vw" }}>
      {/* Alternative date filter if needed */}
      {showAlternativeDate && (
        <Grid2 item>
          <YearMonthFilter onDateRangeChange={handleYearMonthChange} />
        </Grid2>
      )}

      {/* Predefined/custom date radio toggles */}
      {showDates && (
        <Grid2 item>
          <FormControl>
            <RadioGroup
              row
              value={dateFilterType}
              onChange={(e) => {
                setDateFilterType(e.target.value);
                if (e.target.value === "predefined") {
                  setDateRange(calculatePredefinedDateRange(predefinedFilter));
                } else {
                  setPredefinedFilter("");
                  setDateRange({ start: "", end: "" });
                }
              }}
            >
              <FormControlLabel
                value="predefined"
                control={<Radio />}
                label="Predefined Date Range"
              />
              <FormControlLabel
                value="custom"
                control={<Radio />}
                label="Custom Date Range"
              />
            </RadioGroup>
          </FormControl>
        </Grid2>
      )}

      {/* Predefined Date Range dropdown */}
      {showDates && dateFilterType === "predefined" && (
        <Grid2 item size={4}>
          <FormControl fullWidth>
            <InputLabel>Predefined Date Range</InputLabel>
            <Select
              value={predefinedFilter}
              onChange={(e) => handlePredefinedFilterChange(e.target.value)}
            >
              <MenuItem value="1 Month">1 Month</MenuItem>
              <MenuItem value="3 Months">3 Months</MenuItem>
              <MenuItem value="QTD">Quarter to Date (QTD)</MenuItem>
              <MenuItem value="YTD">Year to Date (YTD)</MenuItem>
              <MenuItem value="12 Months">Last 12 Months</MenuItem>
            </Select>
          </FormControl>
        </Grid2>
      )}

      {/* Custom date range pickers */}
      {showDates && dateFilterType === "custom" && (
        <Grid2 item>
          <Box sx={{ display: "flex", gap: 2 }}>
            <TextField
              label="Start Date"
              type="date"
              value={dateRange.start}
              onChange={(e) => handleDateChange("start", e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
            <TextField
              label="End Date"
              type="date"
              value={dateRange.end}
              onChange={(e) => handleDateChange("end", e.target.value)}
              InputLabelProps={{ shrink: true }}
              fullWidth
            />
          </Box>
        </Grid2>
      )}

      <Grid2 container spacing={2} flex={"wrap"}>
        {/* Distributors */}
        <Grid2 item size={4}>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Distributors</InputLabel>
              <Select
                style={selectStyle}
                multiple
                value={selectedDistributors}
                onChange={(e) => setSelectedDistributors(e.target.value)}
                renderValue={(selected) =>
                  distributors
                    .filter((d) => selected.includes(d.id))
                    .map((s) => s.name)
                    .join(", ")
                }
                sx={{ maxWidth: "300px" }}
              >
                <Toggler
                  action={() =>
                    toggleAll({
                      filterProp: "distributors",
                      makeActive:
                        selectedDistributors.length < distributors.length,
                    })
                  }
                  fraction={{
                    selected: selectedDistributors.length,
                    available: distributors.length,
                  }}
                />
                {distributors
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((distributor) => (
                    <MenuItem key={distributor.id} value={distributor.id}>
                      <Checkbox
                        checked={selectedDistributors.includes(distributor.id)}
                      />
                      <ListItemText primary={distributor.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid2>

        {/* Brands */}
        <Grid2 item size={4}>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Brands</InputLabel>
              <Select
                style={selectStyle}
                multiple
                value={selectedBrands}
                onChange={(e) => setSelectedBrands(e.target.value)}
                renderValue={(selected) =>
                  brands
                    .filter((b) => selected.includes(b.id))
                    .map((s) => s.name)
                    .join(", ")
                }
                sx={{ maxWidth: "300px" }}
              >
                <Toggler
                  action={() =>
                    toggleAll({
                      filterProp: "brands",
                      makeActive: selectedBrands.length < brands.length,
                    })
                  }
                  fraction={{
                    selected: selectedBrands.length,
                    available: brands.length,
                  }}
                />

                {brands
                  .slice()
                  .sort((a, b) => a.id.localeCompare(b.id))
                  .map((brand) => (
                    <MenuItem key={brand.id} value={brand.id}>
                      <Checkbox checked={selectedBrands.includes(brand.id)} />
                      <ListItemText primary={brand.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid2>

        {/* Products */}
        <Grid2 item size={4}>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Products</InputLabel>
              <Select
                style={selectStyle}
                multiple
                value={selectedProducts}
                onChange={(e) => setSelectedProducts(e.target.value)}
                renderValue={(selected) =>
                  products
                    .filter((p) => selected.includes(p.id))
                    .map((s) => s.name)
                    .join(", ")
                }
                sx={{ maxWidth: "300px" }}
              >
                <Toggler
                  action={() =>
                    toggleAll({
                      filterProp: "products",
                      makeActive: selectedProducts.length < products.length,
                    })
                  }
                  fraction={{
                    selected: selectedProducts.length,
                    available: products.length,
                  }}
                />
                {products
                  .slice()
                  .sort((a, b) => a.name.localeCompare(b.name))
                  .map((product) => (
                    <MenuItem key={product.id} value={product.id}>
                      <Checkbox
                        checked={selectedProducts.includes(product.id)}
                      />
                      <ListItemText primary={product.name} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid2>

        {/* Premise Types */}
        <Grid2 item size={4}>
          <Box display="flex" alignItems="center">
            <FormControl fullWidth>
              <InputLabel>Premise Types</InputLabel>
              <Select
                style={selectStyle}
                multiple
                value={selectedPremiseTypes}
                onChange={(e) => setSelectedPremiseTypes(e.target.value)}
                renderValue={(selected) => selected.join(", ")}
                sx={{ maxWidth: "300px" }}
              >
                <Toggler
                  action={() =>
                    toggleAll({
                      filterProp: "premiseTypes",
                      makeActive:
                        selectedPremiseTypes.length < premiseTypes.length,
                    })
                  }
                  fraction={{
                    selected: selectedPremiseTypes.length,
                    available: premiseTypes.length,
                  }}
                />
                {premiseTypes
                  .sort((a, b) => a.localeCompare(b))
                  .map((type) => (
                    <MenuItem key={type} value={type}>
                      <Checkbox checked={selectedPremiseTypes.includes(type)} />
                      <ListItemText primary={type} />
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
        </Grid2>

        {/* Unit of Measure */}
        {showUnitOfMeasure && (
          <Grid2 item>
            <FormControl fullWidth>
              <InputLabel>Unit of Measure</InputLabel>
              <Select
                style={selectStyle}
                value={selectedUnit}
                onChange={(e) => setSelectedUnit(e.target.value)}
              >
                {unitOfMeasures.map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {unit}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid2>
        )}
      </Grid2>
      {/* Action buttons */}
      <Grid2 item>
        <Box display="flex" justifyContent="flex-end" gap={2} pb={1}>
          <Button
            variant="contained"
            onClick={handleApplyFilters}
            disabled={isApplyDisabled}
          >
            Apply
          </Button>
          <Button variant="outlined" onClick={handleResetFilters}>
            Reset
          </Button>
        </Box>
      </Grid2>
    </Grid2>
  );
};

export default FilterMenu;
