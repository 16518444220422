import client from "./client";

const getStatsStoresDaily = ({ mid, usState, days, start, end, limit }) => {
  const endpoint = "/stats/stores/daily";
  const url = "http://localhost:3000/stats/stores/daily";
  const params = {
    mid: mid,
    usstate: usState,
    days: days,
    start: start,
    end: end,
    limit: limit,
  };
  Object.keys(params).forEach((key) =>
    url.searchParams.append(key, params[key])
  );
  return fetch(url).then((response) => response.json());
};
const getStatsDaily = ({ mid, usState, limit }) => {
  const endpoint = "/stats/daily";
  client.get(endpoint, { mid: mid, usstate: usState, limit: limit });
};
const getStatsMapChart = ({ mid, limit }) => {
  const endpoint = "/stats/map";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getStatsDataTableChart = ({ mid, limit }) => {
  const endpoint = "/stats/datatable";
  return client.get(endpoint, { mid: mid, limit: limit });
};
const getStatsGrowthChart = ({ mid, limit }) => {
  const endpoint = "/stats/businessgrowth";
  return client.get(endpoint, { mid: mid, limit: limit });
};
const getStatsGrowthSparkChart = ({ mid, limit }) => {
  const endpoint = "/stats/growthspark";
  return client.get(endpoint, { mid: mid, limit: limit });
};
const getStatsManufacturerSales = ({ mid, limit }) => {
  const endpoint = "/stats/manufacturer/sales";
  return client.get(endpoint, { mid: mid, limit: limit });
};

const getTopProducts = ({
  dists,
  brands,
  states,
  dateStart,
  dateEnd,
  timeRange,
  limit,
}) => {
  const endpoint = "/stats/top-products";
  return client.get(endpoint, {
    dists,
    brands,
    states,
    dateStart,
    dateEnd,
    timeRange,
    limit,
  });
};
const getProductTopAccounts = ({ prod_id, dists, dateStart, dateEnd }) => {
  const endpoint = "/stats/product/top-accounts";
  return client.get(endpoint, { prod_id, dists, dateStart, dateEnd });
};

const getTopAccounts = ({
  dists,
  brands,
  states,
  dateStart,
  dateEnd,
  timeRange,
}) => {
  const endpoint = "/stats/top-accounts";
  return client.get(endpoint, {
    dists,
    brands,
    states,
    dateStart,
    dateEnd,
    timeRange,
  });
};
const getAccountTopProducts = ({ acc_id, dists, dateStart, dateEnd }) => {
  const endpoint = "/stats/account/top-products";
  return client.get(endpoint, { acc_id, dists, dateStart, dateEnd });
};

const getSummary = ({ mid, limit, premiseTypes, dists, selectedQuantity }) => {
  const endpoint = "/stats/summary";
  return client.get(endpoint, {
    mid: mid,
    limit: limit,
    premiseTypes,
    dists,
    selectedQuantity,
  });
};
const getMapMarkers = ({
  dateStart,
  dateEnd,
  dists,
  brands,
  prods,
  states,
}) => {
  const endpoint = "/stats/map-markers";
  return client.get(endpoint, {
    dateStart,
    dateEnd,
    dists,
    brands,
    prods,
    states,
  });
};

const getRepTotals = () => {
  const endpoint = "/stats/rep-totals";
  return client.get(endpoint);
};

export default {
  getStatsStoresDaily,
  getStatsDaily,
  getStatsMapChart,
  getStatsDataTableChart,
  getStatsGrowthChart,
  getStatsGrowthSparkChart,
  getStatsManufacturerSales,
  getTopProducts,
  getProductTopAccounts,
  getTopAccounts,
  getAccountTopProducts,
  getSummary,
  getMapMarkers,
  getRepTotals,
};
