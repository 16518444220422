import React from "react";
import PropTypes from "prop-types";
import ListDetails from "./ListDetails";
import { Dialog } from "@mui/material";

const AccountListModal = (props) => {
  const {
    onClose,
    open,
    item,
    setItem,
    setData,
    allLists,
    adjustListsByRegion,
  } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Dialog onClose={handleClose} aria-labelledby="product-dialog" open={open}>
      <ListDetails
        item={item}
        setItem={setItem}
        allLists={allLists}
        setData={setData}
        onClose={handleClose}
        {...{
          adjustListsByRegion,
        }}
      />
    </Dialog>
  );
};

AccountListModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedValue: PropTypes.string.isRequired,
};
export default AccountListModal;
