// AccountMarker.js
import React from "react";
import { CircleMarker, Tooltip } from "react-leaflet";
import PropTypes from "prop-types";

const AccountMarker = React.memo(
  ({ accountId, coords, tooltipData, handleMarkerClick }) => {
    if (!coords.lat || !coords.long) return;

    return (
      <CircleMarker
        center={[coords.lat, coords.long]}
        radius={5}
        color="#3388ff"
        fillOpacity={0.8}
        eventHandlers={{
          click: () => handleMarkerClick(accountId),
        }}
      >
        <Tooltip
          key={accountId}
          direction="top"
          offset={[0, -10]}
          opacity={1}
          permanent={false}
        >
          {coords.name ? (
            <div style={{ padding: 10, paddingLeft: 20, paddingRight: 20 }}>
              <strong>{coords.name}</strong>
              <br />
              {coords.address || "No address"}
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </Tooltip>
      </CircleMarker>
    );
  },
  (prevProps, nextProps) => {
    return (
      prevProps.coords.lat === nextProps.coords.lat &&
      prevProps.coords.long === nextProps.coords.long &&
      prevProps.tooltipData === nextProps.tooltipData
    );
  }
);

AccountMarker.propTypes = {
  accountId: PropTypes.string.isRequired,
  coords: PropTypes.shape({
    lat: PropTypes.number.isRequired,
    long: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
    address: PropTypes.string,
  }).isRequired,
  handleMarkerClick: PropTypes.func.isRequired,
};

export default AccountMarker;
