import { useMemo } from "react";
import { Box, Chip } from "@mui/material";
import { condtionalRuleResults } from "../helpers";
import AnswerWrapper from "./AnswerWrapper";
import { answerToText } from "../helpers";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => {
  return {
    fieldWrapper: {
      width: "100%",
      padding: 20,
      // marginBottom: -5,
      boxShadow: "none",
    },
    nestedConditionalGroup: {
      borderLeft: "2px solid #99d",
      borderBottom: "2px solid #99d",
      borderRadius: 12,
      boxShadow: "none",
      paddingLeft: 4,
    },
  };
});

const ConditionalGroupRead = ({
  groups,
  answer,
  answers,
  parentFieldId,
  options = [],
  parent,
  parentLabel = "",
  answerFormat,
  noAnswerString,
  nested = false,
}) => {
  const singleGroups = useMemo(() => {
    return groups
      ? groups.filter((g) => {
          if (["forEachSelected", "forEachNotSelected"].includes(g.rule)) {
            return false;
          }
          const results = condtionalRuleResults(answer, g, options);
          if (!results[g.rule]) return false;

          // standard
          if (results[g.rule] && parentFieldId === g.parentFieldId) return true;

          return false;
        })
      : [];
  }, [groups, answer]);

  const classes = useStyles();

  return (
    <Box>
      {singleGroups.map((g) => (
        <Box
          key={g.id}
          className={
            nested ? classes.nestedConditionalGroup : classes.fieldWrapper
          }
        >
          <Box mt={1}>
            <Chip
              label={`${parent.label} - ${
                answerToText(answer, [parent], {
                  options: g.parentOptions,
                }) || "Negative or no answer"
              }`}
            />
          </Box>
          {g.fields.map((f) => (
            <AnswerWrapper
              key={f.id}
              field={f}
              parentLabel={`${parentLabel ? parentLabel + " / " : ""} ${
                parent.label
              } - ${
                answerToText(answer, [parent], {
                  options: g.parentOptions,
                }) || "Negative or no answer"
              }`}
              answer={answers.find((a) => a.name === f.field_name)}
              answers={answers}
              groups={groups}
              answerFormat={answerFormat}
              noAnswerString={noAnswerString}
              nested={true}
            />
          ))}
        </Box>
      ))}
    </Box>
  );
};

export default ConditionalGroupRead;
