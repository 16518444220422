import { Box, Dialog } from "@mui/material";
import { ResponseHeader } from "../../../../views/forms/responses";
import AnswersOnScreen from "./AnswerOnScreen";
import AnswerFileDisplay from "./AnswerFileDisplay";
import { useMemo, useState, useEffect } from "react";
import usersAPI from "../../../../api/users";
import formsAPI from "../../../../api/forms";
import { awsS3Image } from "../../../../helpers/awsS3Image";
const ListResponse = ({
  currentResponse,
  userInfo,
  accounts,
  forms,
  setForms,
}) => {
  const [userList, setUserList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chosenFile, setChosenFile] = useState(null);

  const loadMatchingForm = (uuid) => {
    setLoading(true);
    formsAPI
      .getFormVersions(uuid)
      .then(({ results }) => {
        const fo = Object.fromEntries(
          results.map((r) => {
            return [r.form_id, r];
          })
        );
        setForms({
          ...forms,
          ...fo,
        });
      })
      .catch((error) => console.log(error))
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (currentResponse?.form_id && !forms[currentResponse.form_id]) {
      loadMatchingForm(currentResponse.form_uuid);
    }
  }, [currentResponse]);

  useEffect(() => {
    if (userList.length === 0) {
      usersAPI
        .getUsers({
          mid: userInfo.manufacturer_id,
        })
        .then(({ results }) => {
          setUserList(results);
        });
    }
  }, [userList, setUserList, userInfo.manufacturer_id]);

  const { values: answers, attachedImages } = useMemo(() => {
    if (!currentResponse) return { values: [], attachedImages: [] };
    return JSON.parse(currentResponse.response_body);
  }, [currentResponse]);

  const account = useMemo(() => {
    if (currentResponse && currentResponse.account_id) {
      return accounts["_" + currentResponse.account_id?.toString()]
        ? accounts["_" + currentResponse.account_id?.toString()]
        : null;
    }
    return null;
  }, [currentResponse, accounts]);

  const user = useMemo(() => {
    return currentResponse && userList
      ? userList.find((u) => u.user_id === currentResponse?.user_id)
      : null;
  }, [currentResponse, userList]);
  const questions = useMemo(() => {
    if (!currentResponse) return [];
    const matchingForm = forms[currentResponse.form_id];
    if (matchingForm) {
      return JSON.parse(matchingForm.form_body);
    }
    return [];
  }, [currentResponse, forms]);

  return (
    <Box display="flex" alignItems={"center"} flexDirection={"column"}>
      {loading && <p>Loading</p>}
      {currentResponse && (
        <ResponseHeader
          distributorName={currentResponse.dist_displayname}
          user={user}
          account={account}
          response={currentResponse}
          editLink={`${currentResponse.response_id}/edit`}
        />
      )}
      <Box minWidth={"50%"}>
        <AnswersOnScreen answers={answers} questions={questions} />
      </Box>
      {attachedImages.length > 0 && (
        <Box display={"flex"}>
          {attachedImages.map((a, i) => (
            <AnswerFileDisplay file={a} key={i} setChosenFile={setChosenFile} />
          ))}
        </Box>
      )}
      <Dialog
        onClose={() => setChosenFile(null)}
        aria-labelledby="product-dialog"
        open={chosenFile !== null}
        maxWidth="lg"
      >
        <Box
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems={"center"}
          maxWidth={"90%"}
          maxHeight={"80%"}
          alignContent={"center"}
          p={1}
          mx={"auto"}
        >
          <Box>
            {chosenFile && (
              <img
                src={awsS3Image(
                  chosenFile.image_name,
                  chosenFile.identity_key,
                  "1000x0"
                )}
                style={{
                  width: "100%",
                }}
                alt=""
              />
            )}
          </Box>
        </Box>
      </Dialog>
    </Box>
  );
};

export default ListResponse;
