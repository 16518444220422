import { Box, Typography } from "@mui/material";

const SalesTargetsGauge = ({
  current,
  target,
  progress,
  timeRange,
  formatNumber,
}) => {
  // Arc properties
  const radius = 90;
  const centerX = 100;
  const centerY = 100;
  const halfCircumference = Math.PI * radius; // Half of full circle circumference

  // Calculate the angle (radians) - clamped at Math.PI (100%)
  const theta = Math.min(Math.PI, (progress / 100) * Math.PI);

  // Get ball position using parametric circle formula - clamped
  const ballX = centerX - radius * Math.cos(theta);
  const ballY = centerY - radius * Math.sin(theta); // Inverted because of SVG coordinate system

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" gutterBottom>
            Sales target
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatNumber(target)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="#007BFF" gutterBottom>
            Current
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatNumber(current)}
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="body2" color="#FF718B" gutterBottom>
            Remaining
          </Typography>
          <Typography variant="body1" gutterBottom>
            {formatNumber(Math.max(0, target - current))}
          </Typography>
        </Box>
      </Box>
      <Typography sx={{ fontSize: 10, mb: 1 }}>
        Units are in Cases Equivalence
      </Typography>
      <svg width="200" height="120" style={{ overflow: "visible" }}>
        {/* Define filter for drop shadow */}
        <defs>
          <filter id="dropShadow" x="-20%" y="-20%" width="140%" height="140%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="2" />
            <feOffset dx="1" dy="2" />
            <feComponentTransfer>
              <feFuncA type="linear" slope="0.3" />
            </feComponentTransfer>
            <feMerge>
              <feMergeNode />
              <feMergeNode in="SourceGraphic" />
            </feMerge>
          </filter>
        </defs>

        {/* Background arc (the entire semi‐circle) */}
        <path
          d="M10 100 A 90 90 0 0 1 190 100"
          stroke="#E5EAFC"
          strokeWidth="20"
          fill="none"
        />

        {/* Filled arc (progress portion) */}
        <path
          d="M10 100 A 90 90 0 0 1 190 100"
          stroke="#142F72"
          strokeWidth="20"
          fill="none"
          strokeDasharray={`${
            (progress / 100) * halfCircumference
          } ${halfCircumference}`}
          strokeLinecap="round"
        />

        {/* Ball at progress position */}
        <circle
          cx={ballX}
          cy={ballY}
          r="12.5"
          stroke="white"
          strokeWidth="2"
          fill="#142F72"
          filter="url(#dropShadow)"
        />

        {/* Progress percentage text */}
        <text
          x="50%"
          y="60%"
          textAnchor="middle"
          fill="#142F72"
          fontSize="20"
          fontWeight="bold"
        >
          {Math.round(progress)}%
        </text>
        <text x="50%" y="75%" textAnchor="middle" fill="#142F72" fontSize="12">
          Depletions: {current.toLocaleString()}
        </text>
      </svg>
      <Typography variant="body1" mt={2}>
        Target: {formatNumber(target)} &nbsp; | &nbsp; Current:{" "}
        {formatNumber(current)}
      </Typography>
    </>
  );
};

export default SalesTargetsGauge;
