import React, { useState, useEffect, useRef } from "react";
import { Storage, API } from "aws-amplify";
import PropTypes from "prop-types";
import { v4 as uuid } from "uuid";
import { nanoid } from "nanoid";
import Resizer from "react-image-file-resizer";
import {
  Box,
  IconButton,
  CardHeader,
  Button,
  Divider,
  Card,
  CardContent,
  ImageList,
  ImageListItem,
  ImageListItemBar,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";
import { AddAPhoto, DeleteForever } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
  },
  gridList: {
    // The key for a horizontal list is `flexWrap: 'nowrap'` and `overflowX: 'auto'`.
    flexWrap: "nowrap",
    transform: "translateZ(0)", // optional performance trick
  },
  title: {
    color: "#FFFFFF",
  },
  titleBar: {
    background:
      "linear-gradient(to top, rgba(0,0,0,0.7) 0%, " +
      "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
  },
  fullHeightCard: {
    height: "100%",
  },
  download: {
    backgroundColor: "#FFFFFF",
    color: "#000000",
    borderRadius: "50%",
    padding: "1px",
    borderWidth: "2px",
  },
}));

const Media = ({ className, ...rest }) => {
  const classes = useStyles();
  const { id } = useParams();
  const [loading, setLoading] = useState(false);
  const store = useStore();
  const { userInfo } = store;
  const [tileData, setTileData] = useState([]);
  const fileInput = useRef(null);

  useEffect(() => {
    getImageData();
  }, []);

  const getImageData = async () => {
    setLoading(true);
    try {
      const data = await API.get("backendGateway", "/accounts/images/" + id, {
        queryStringParameters: {
          mid: userInfo.mid,
          limit: 1000,
        },
      });
      const _data = data.results?.map((item) => ({
        ...item,
        image_src: awsS3Image(item.path, item.identity_key, "0x180"),
      }));
      setTileData(_data);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
  };

  const triggerFilePick = () => {
    if (fileInput.current) {
      fileInput.current.click();
    }
  };

  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        600,
        600,
        "PNG",
        80,
        0,
        (uri) => {
          resolve(uri);
        },
        "base64"
      );
    });

  const dataURIToBlob = (dataURI) => {
    const splitDataURI = dataURI.split(",");
    const byteString =
      splitDataURI[0].indexOf("base64") >= 0
        ? atob(splitDataURI[1])
        : decodeURI(splitDataURI[1]);
    const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

    const ia = new Uint8Array(byteString.length);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], { type: mimeString });
  };

  const changeHandler = async (event) => {
    const file = event.target.files[0];
    const image = await resizeFile(file);
    const resizedFile = dataURIToBlob(image);
    handleSubmission(file, resizedFile);
  };

  const handleSubmission = async (file, resizedFile) => {
    const filename = uuid();
    setLoading(true);
    try {
      await Storage.put(id + "/" + filename, resizedFile, {
        contentType: "image/*",
        level: "protected",
      });
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
    const tempUrl = URL.createObjectURL(file);
    const postObj = {
      temp: tempUrl || "temp",
      filename: filename,
      path: id + "/" + filename,
      identity_key: userInfo.user_identitykey,
      created: new Date(),
      user_id: userInfo.uid,
      account_id: id,
      manufacturer_id: userInfo.mid,
      image_src: awsS3Image(
        id + "/" + filename,
        userInfo.user_identitykey,
        "0x180"
      ),
    };
    setTileData([postObj, ...tileData]);
    postData(postObj);
    setLoading(false);
  };

  const postData = async (imgData) => {
    setLoading(true);
    try {
      const apiName = "backendGateway";
      const path = "/accounts/images";
      const myInit = {
        body: imgData,
        headers: {},
      };
      await API.post(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
  };

  const retrieveFile = async (key, identityId) => {
    const result = await Storage.get(key, {
      download: true,
      level: "protected",
      identityId,
    });
    downloadFile(result.Body);
  };

  const downloadFile = (blob) => {
    const filename = nanoid(5);
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = filename + ".jpeg";
    const clickHandler = () => {
      setTimeout(() => {
        URL.revokeObjectURL(url);
        a.removeEventListener("click", clickHandler);
      }, 150);
    };
    a.addEventListener("click", clickHandler, false);
    a.click();
    return a;
  };

  const deleteImage = async (mediaId) => {
    try {
      await API.del("backendGateway", "/accounts/images", {
        queryStringParameters: {
          account_media_id: mediaId,
        },
      });
      setTileData(tileData.filter((e) => e.account_media_id !== mediaId));
    } catch (err) {
      console.log("error fetching data..", err);
    }
  };

  return (
    <Card className={classes.fullHeightCard}>
      <CardHeader
        title="Media"
        action={
          <Box>
            <label htmlFor="icon-button-file">
              <Button
                aria-label="Add Media"
                startIcon={<AddAPhoto />}
                className="button-two"
                onClick={triggerFilePick}
              >
                Add Media
              </Button>
            </label>
            <input
              accept="image/*"
              type="file"
              id="icon-button-file"
              hidden
              ref={fileInput}
              onChange={changeHandler}
            />
          </Box>
        }
      />
      <Divider />
      <CardContent>
        {/* 
          Key changes:
           - Use `flexWrap: 'nowrap'` and `overflowX: 'auto'`.
           - Remove vertical overflow so the list scrolls left-to-right.
        */}
        <ImageList
          cols={tileData && tileData.length ? tileData.length : 1}
          className={classes.gridList}
          sx={{
            overflowX: "auto",
            height: 200, // optional fixed height if you like
          }}
        >
          {tileData.map((tile) => (
            <ImageListItem
              key={tile.filename}
              onClick={() => retrieveFile(tile.path, tile.identity_key)}
            >
              {tile.temp !== undefined ? (
                <img src={tile.temp} alt="Account Image" />
              ) : (
                <img
                  src={tile.image_src}
                  style={{ height: "200px", width: "300px" }}
                  alt=""
                />
              )}
              <ImageListItemBar
                title={new Date(tile.created).toDateString()}
                classes={{
                  root: classes.titleBar,
                  title: classes.title,
                }}
                actionIcon={
                  <IconButton
                    onClick={(event) => {
                      event.stopPropagation();
                      event.preventDefault();
                      deleteImage(tile.account_media_id);
                    }}
                  >
                    <DeleteForever className={classes.download} />
                  </IconButton>
                }
              />
            </ImageListItem>
          ))}
        </ImageList>
      </CardContent>
    </Card>
  );
};

Media.propTypes = {
  className: PropTypes.string,
};

export default Media;
