import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Link, useParams, useLocation } from "react-router-dom";

import { Container, Grid2, Breadcrumbs, Card } from "@mui/material";
import "react-pivottable/pivottable.css";

import { observer } from "mobx-react-lite";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import SalePivotTablePlain from "./SalePivotTablePlain";
import PresetSwitcher from "./PresetSwitcher";
import { PresetOptions, queryBuilder } from "./BakedPresetOptions";
import { useStore } from "../../../stores/StoreContext";
import { useDepletionsContainerContext } from "../../../stores/DepletionContextStore";
import { makeStyles } from "@mui/styles";
import { ChevronRight } from "@mui/icons-material";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
}));

const Dashboard = observer(() => {
  const classes = useStyles();

  const { region, country } = useParams();
  const [showMonths, setShowMonths] = useState(true);
  const [showComparison, setShowComparison] = useState(true);
  const [crumbs, setCrumbs] = useState([]);
  const q = useQuery();
  const queryPreset = q.get("preset");

  const {
    regions,
    userInfo,
    selectedStates,
    selectedBrands,
    selectedProducts,
    premiseTypes,
    allBrands,
    selectedDistributors,
    dateRange,
    manufacturer,
    setAllProducts,
    selectedQuantity,
    productSold,
    setProductSold,
    productJoiner,
  } = useStore();
  const { depletions: depletionList, globallyFiltered } =
    useDepletionsContainerContext();
  const preset = useMemo(() => {
    return queryPreset ? queryPreset : "account_product";
  }, [queryPreset]);
  useEffect(() => {
    if (regions && regions.length) {
      setCrumbs([
        {
          text: "Depletions",
          link: "/app/depletions/us",
        },
        {
          text: "US",
          link: "/app/depletions/us",
        },
      ]);
    }
  }, [region, regions]);

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Dashboard-Shelf");
  }, [userInfo.uid]);

  useEffect(() => {
    trackEvent(userInfo.uid, `ReportView:${preset}`);
  }, [userInfo.uid, preset]);

  const regionCodes = useMemo(() => {
    return regions
      .filter((r) => selectedStates.includes(r.long))
      .map((r) => r.code);
  }, [regions, selectedStates]);

  const prettyRegion = () => {
    const stateCount = selectedStates.length;
    if (stateCount > 3) {
      return `${stateCount} states`;
    }
    return selectedStates.toString();
  };
  return (
    <Page className={classes.root} title="Reports">
      <Container maxWidth="xl">
        <Breadcrumbs separator={<ChevronRight />}>
          {crumbs.map((c, index) => {
            return (
              <Link to={c.link} key={index} className="crumb-link">
                {c.text}
              </Link>
            );
          })}
        </Breadcrumbs>
        <Fragment>
          <PresetSwitcher
            country={country}
            region={region}
            preset={preset}
            presetOptions={PresetOptions}
            queryBuilder={queryBuilder}
            prettyRegion={prettyRegion}
          />

          <Grid2 container spacing={3} className={classes.pivotTableWrapper}>
            <Grid2 size={12} size={12}>
              <Card className={classes.fullHeightCard}>
                <SalePivotTablePlain
                  key={dateRange}
                  userInfo={userInfo}
                  manufacturer={manufacturer}
                  dateRange={dateRange}
                  allBrands={allBrands}
                  selectedStates={selectedStates}
                  selectedBrands={[...selectedBrands]}
                  selectedDistributors={[...selectedDistributors]}
                  selectedProducts={[...selectedProducts]}
                  premiseTypes={premiseTypes}
                  classes={classes}
                  preset={preset}
                  depletionList={depletionList}
                  globallyFiltered={globallyFiltered}
                  showMonths={showMonths}
                  setShowMonths={setShowMonths}
                  showComparison={showComparison}
                  setShowComparison={setShowComparison}
                  reportProducts={setAllProducts}
                  quantity={selectedQuantity.toUpperCase()}
                  productSold={productSold}
                  setProductSold={setProductSold}
                  productJoiner={productJoiner}
                />
              </Card>
            </Grid2>
          </Grid2>
        </Fragment>
      </Container>
    </Page>
  );
});

export default Dashboard;
