import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Dialog,
  Typography,
  TextField,
  Snackbar,
  Alert,
  DialogTitle,
} from "@mui/material";
import { useNavigate } from "react-router";
import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { File } from "react-feather";
import { awsS3Image } from "../../helpers/awsS3Image";
import { DateTime } from "luxon";
import { useStore } from "../../stores/StoreContext";
import StorageClient from "../../storage/client";
import useStorage from "../../hooks/useStorage";
import DeleteModal from "../deleteModal/DeleteModal";
import {
  CloudDownload,
  Delete,
  FileCopyOutlined,
  Panorama,
  PictureAsPdf,
} from "@mui/icons-material";
import FolderPicker from "./FolderPicker";
import marketingAPI from "../../api/marketing";

const FileDetailView = observer(({ file, folders }) => {
  console.log(file);
  const store = useStore();
  const { userList, setFiles, files } = store;
  const [fileType, setFileType] = useState("");
  const [mediaType, setMediaType] = useState("");
  const [userName, setUserName] = useState("");
  const s3Storage = useStorage(StorageClient.download);
  const removeS3Trash = useStorage(StorageClient.remove);
  const [toastOpen, setToastOpen] = useState(false);
  const [toastMessage, setToastMessage] = useState("");

  const [open, setOpen] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [showFolderCopy, setShowFolderCopy] = useState(false);
  const [newNickname, setNewNickname] = useState(
    file?.nickname || file?.file_name || ""
  );

  const navigate = useNavigate();

  useEffect(() => {
    if (userList && file) {
      let _name = userList?.find(
        (user) => user.user_id === file.user_id
      )?.user_displayname;
      setUserName(_name);
    }
  }, [userList, file]);
  const fileToMediaType = (file) => {
    const fileEnd = file.file_name.split(".").reverse()[0];
    const images = ["jpeg", "jpg", "png"];
    const videos = ["mp4", "m4v"];
    const pdfs = ["pdf"];
    let _mediaType = "generic";
    if (videos.includes(fileEnd)) _mediaType = "video";
    if (images.includes(fileEnd)) _mediaType = "image";
    if (pdfs.includes(fileEnd)) _mediaType = "pdf";
    setMediaType(_mediaType);
  };

  const copyImageUrlToClipboard = async (url) => {
    try {
      await navigator.clipboard.writeText(url);
      setToastMessage("Image URL copied to clipboard");
      setToastOpen(true);
    } catch (err) {
      console.error("Failed to copy: ", err);
      setToastMessage("Failed to copy image URL");
      setToastOpen(true);
    }
  };

  useEffect(() => {
    if (file) {
      let _ext = file.file_key.split(".").reverse()[0].toLowerCase();
      setFileType(_ext);

      fileToMediaType(file);
    }
  }, [file]);

  const fileIcon = (fileName) => {
    const ext = fileName.split(".").reverse()[0].toLowerCase();
    switch (ext) {
      case "png":
      case "jpeg":
      case "jpg":
        return (
          <img
            src={awsS3Image(file.file_key, file.identity_id, "80x80")}
            alt="fileimage"
          />
        );
      case "pdf":
        return <PictureAsPdf style={{ fontSize: "40", color: "black" }} />;
      default:
        return <File style={{ fontSize: "40", color: "black" }} />;
    }
  };

  const handleFullView = async () => {
    if (mediaType === "pdf") {
      let windowReference = window.open();
      s3Storage
        .getSignedUrl(file.file_key, file.identity_id, false)
        .then(function (url) {
          windowReference.location = url;
        });
    } else if (mediaType === "image" || mediaType === "video") {
      setOpen(true);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleDownload = async () => {
    setDownloadLoading(true);
    await s3Storage.download(
      file.file_key,
      file.identity_id,
      true,
      file.file_name
    );
    setDownloadLoading(false);
  };
  const handleConfirmDelete = async () => {
    setIsLoading(true);
    removeS3Trash.remove(file.file_key);
    let _result = await marketingAPI.deleteFile(file.file_id);
    setIsLoading(false);
    if (_result.status < 400) {
      navigate(-1);
      setDeleteMode(false);
      setFiles(files.filter((f) => f.file_id !== file.file_id));
    }
  };
  const handleEditNickname = async () => {
    try {
      await marketingAPI.patchFile({
        file_id: file.file_id,
        patch: {
          nickname: newNickname,
        },
      });

      setFiles(
        files.map((f) =>
          f.file_id === file.file_id ? { ...f, nickname: newNickname } : f
        )
      );
      setToastMessage("Nickname updated successfully");
      setToastOpen(true);
      setIsEditing(false);
    } catch (error) {
      setToastMessage("Failed to update nickname");
      setToastOpen(true);
    }
  };

  const handleCancelEdit = () => {
    setNewNickname(file.nickname || file.file_name);
    setIsEditing(false);
  };

  return (
    <>
      <Snackbar
        open={toastOpen}
        autoHideDuration={4000}
        onClose={() => setToastOpen(false)}
      >
        <Alert onClose={() => setToastOpen(false)} severity="success">
          {toastMessage}
        </Alert>
      </Snackbar>

      <Card style={{ marginBottom: "1rem" }}>
        <CardContent>
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <Box mr={3}>{fileIcon(file.file_key)}</Box>
            <Button
              color="primary"
              size="small"
              disabled={downloadLoading}
              variant="contained"
              onClick={handleDownload}
              startIcon={downloadLoading ? <></> : <CloudDownload />}
            >
              {downloadLoading ? <CircularProgress /> : "Download"}
            </Button>

            <Button
              variant="contained"
              size="small"
              color="primary"
              disabled={mediaType === "generic"}
              onClick={handleFullView}
              startIcon={downloadLoading ? <></> : <Panorama />}
            >
              View Original
            </Button>
            <Button
              color="primary"
              size="small"
              variant="contained"
              onClick={() => setDeleteMode(true)}
              startIcon={<Delete />}
            >
              Delete
            </Button>
          </Box>
          <Box display="flex" alignItems="center" mt={3}>
            <Typography>File Name :</Typography>
            {isEditing ? (
              <>
                <TextField
                  value={newNickname}
                  onChange={(e) => setNewNickname(e.target.value)}
                  variant="outlined"
                  size="small"
                  style={{ marginLeft: "0.5rem" }}
                />
                <Button
                  onClick={handleEditNickname}
                  color="primary"
                  variant="contained"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Save
                </Button>
                <Button
                  onClick={handleCancelEdit}
                  variant="contained"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <Typography
                  variant="body2"
                  style={{ marginLeft: "0.3rem", fontWeight: "600" }}
                >
                  {file.nickname || file.file_name}
                </Typography>
                <Button
                  onClick={() => setIsEditing(true)}
                  color="primary"
                  size="small"
                  style={{ marginLeft: "0.5rem" }}
                >
                  Edit
                </Button>
              </>
            )}
          </Box>
          <Box display="flex" alignItems="center" style={{ gap: "3px" }}>
            <Typography>Uploaded by </Typography>
            <Typography variant="body1" style={{ fontWeight: "600" }}>
              {" "}
              {userName}{" "}
            </Typography>
            <Typography> on </Typography>
            <Typography variant="body1" style={{ fontWeight: "600" }}>
              {DateTime.fromISO(file.created).toLocaleString(
                DateTime.DATETIME_MED
              )}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center">
            <Typography>File Type :</Typography>
            <Typography
              variant="body2"
              style={{ marginLeft: "0.3rem", fontWeight: "600" }}
            >
              {fileType.toUpperCase()}
            </Typography>
          </Box>
        </CardContent>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() =>
            copyImageUrlToClipboard(
              awsS3Image(file.file_key, file.identity_id, "0x0")
            )
          }
          startIcon={<FileCopyOutlined />}
          style={{ margin: 10, marginTop: 0 }}
        >
          Copy Image Address
        </Button>
        <Button
          color="primary"
          size="small"
          variant="contained"
          onClick={() => setShowFolderCopy(true)}
          style={{ margin: 10, marginTop: 0 }}
        >
          Copy to Folder
        </Button>
      </Card>

      <Dialog
        onClose={handleClose}
        aria-labelledby="product-dialog"
        open={open}
        PaperProps={{
          style: {
            width: "90%",
            padding: 10,
          },
        }}
      >
        <img
          alt="noimage"
          src={awsS3Image(file.file_key, file.identity_id, "0x0")}
          style={{ objectFit: "cover" }}
        />
      </Dialog>

      <Dialog onClose={() => setShowFolderCopy(false)} open={showFolderCopy}>
        <DialogTitle>Copy or Move File to Folder</DialogTitle>
        <FolderPicker
          folders={folders}
          file={file}
          {...{
            setFiles,
            files,
          }}
        />
      </Dialog>

      {deleteMode && (
        <DeleteModal
          title={`Delete ${file?.file_name}`}
          description={"Are you sure you want to Delete this file?"}
          deleteButtonLoading={isLoading}
          handleConfirmDelete={handleConfirmDelete}
          open={deleteMode}
          onClose={() => setDeleteMode(false)}
        />
      )}
    </>
  );
});

export default FileDetailView;
