import { useEffect, useState } from "react";
import { FormControl, Select, MenuItem, FormHelperText } from "@mui/material";
import styles from "../../styles";
import StLabel from "./StLabel";

const StSelect = ({
  options,
  label,
  required,
  error,
  helperText,
  answer,
  changeAnswer = () => {},
}) => {
  const [val, setVal] = useState(answer.value);
  const classes = styles();
  const selected = (value) => {
    const matchingLabel = options.find((o) => o.value === value);
    const newVal = matchingLabel ? matchingLabel.value : "";
    const newTextVal = matchingLabel ? matchingLabel.text : "";
    changeAnswer(newVal, newTextVal);
    setVal(value);
  };
  useEffect(() => {
    const matchingOption = options.find((o) => o.value === answer.value);
    if (matchingOption && val !== matchingOption.value) {
      setVal(matchingOption.value);
    } else {
      console.log([matchingOption, val, answer]);
      // bugfix related to display of CG fields and groups - wipe if nothing is valid
      if (typeof matchingOption === "undefined") {
        setVal("");
      }
    }
  }, [answer]);

  const [active, setActive] = useState(false);

  return (
    <FormControl fullWidth error={error} className={classes.select}>
      <StLabel required={required} text={label} error={error} active={active} />
      <Select
        onFocus={() => setActive(true)}
        onBlur={() => setActive(false)}
        value={val}
        {...{
          required,
        }}
        onChange={(e) => selected(e.target.value)}
        {...{
          error,
        }}
        MenuProps={{ disableScrollLock: true }}
      >
        {options.map((o, ind) => (
          <MenuItem key={ind} value={o.value}>
            {o.text}
          </MenuItem>
        ))}
      </Select>
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
    </FormControl>
  );
};

export default StSelect;
