import React from "react";
import { DataGridPremium as DataGrid } from "@mui/x-data-grid-premium";
import { DateTime } from "luxon";

const AccountsTable = ({
  rows,
  selectedRows,
  onRowClick,
  onSelectionModelChange,
  selectedQuantityLabel,
}) => {
  const columns = [
    { field: "name", headerName: "Name", width: 240 },
    { field: "address", headerName: "Address", width: 240 },
    { field: "region", headerName: "Region", width: 140 },
    { field: "zip", headerName: "Zip Code", width: 140 },
    { field: "premiseType", headerName: "Premise Type", width: 140 },
    {
      field: "lastSoldDate",
      headerName: "Last Sale Date",
      width: 140,
      valueGetter: (value, row) => {
        const v = row?.lastSoldDate;
        return v ? new Date(v) : "N/A";
      },
      valueFormatter: (value, row) => {
        return value && value !== "N/A"
          ? DateTime.fromJSDate(value).toLocaleString(DateTime.DATE_MED)
          : "N/A";
      },
    },
    {
      field: "totalSold",
      headerName: `Total Sold (${selectedQuantityLabel})`,
      width: 140,
      valueGetter: (value, row) => {
        const v = row?.totalSold;
        return v ? Number(v) : 0;
      },
      valueFormatter: (value, row) => {
        return value ? value.toFixed(2) : "N/A";
      },
    },
  ];

  return (
    <div style={{ height: 800, width: "100%" }}>
      <DataGrid
        rows={Array.isArray(rows) ? rows : Object.values(rows)}
        columns={columns}
        checkboxSelection
        onRowClick={onRowClick}
        onRowSelectionModelChange={onSelectionModelChange}
        rowSelectionModel={selectedRows}
        sx={{
          "& .MuiDataGrid-row:hover": {
            cursor: "pointer",
          },
        }}
      />
    </div>
  );
};

export default AccountsTable;
