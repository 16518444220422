import {
  Typography,
  Box,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import useApi from "../../../hooks/useApi";
import distributorsAPI from "../../../api/distributors";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useStore } from "../../../stores/StoreContext";

const ViewDistributors = ({ distributorDetails }) => {
  const store = useStore();
  const { userInfo } = store;
  const [dist, setDist] = useState(null);

  const fetchDistributorData = async () => {
    try {
      const response = await API.get(
        "backendGateway",
        `/manufacturers/${userInfo.mid}/distributors_data`
      );
      return response;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const data = await fetchDistributorData();
      if (data && Array.isArray(data)) {
        const distributor = data.find(
          (d) => d.dist_id === distributorDetails.dist_id
        );
        if (distributor) {
          setDist(distributor);
        } else {
          console.warn(
            `Distributor with id ${distributorDetails.dist_id} not found.`
          );
        }
      } else {
        console.warn(
          "Failed to fetch distributor data or data is not in expected format."
        );
      }
    };
    fetchData();
  }, [distributorDetails.dist_id]);

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const postDistributorDeactivate = useApi(
    distributorsAPI.postDistributorDeactivate
  );
  const handleToggleDeactivate = async () => {
    const newValue = !dist.is_deactivated;
    try {
      await postDistributorDeactivate.request({
        distributor_id: dist.dist_id,
        is_deactivated: newValue,
      });
    } catch (error) {
      console.error("Error toggling deactivate:", error);
    }
  };

  const handleConfirmDeactivate = () => {
    handleToggleDeactivate();
    setConfirmDialogOpen(false);
  };

  return (
    <>
      {dist ? (
        <>
          <Box mt={3}>
            <Typography variant="h6" component="h6">
              Name
            </Typography>
            <Typography variant="body1">{dist.dist_displayname}</Typography>
            <Button
              variant="outlined"
              size="small"
              onClick={() => setConfirmDialogOpen(true)}
            >
              {dist.is_deactivated ? "Reactivate" : "Deactivate"}
            </Button>
          </Box>
          <Dialog
            open={confirmDialogOpen}
            onClose={() => setConfirmDialogOpen(false)}
          >
            <DialogTitle>Confirm</DialogTitle>
            <DialogContent>
              <DialogContentText>
                {dist?.is_deactivated
                  ? `Are you sure you want to Reactivate "${dist?.dist_displayname}"?`
                  : `Are you sure you want to Deactivate "${dist?.dist_displayname}"?`}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => setConfirmDialogOpen(false)}
                color="primary"
              >
                Cancel
              </Button>
              <Button onClick={handleConfirmDeactivate} color="secondary">
                Confirm
              </Button>
            </DialogActions>
          </Dialog>
        </>
      ) : (
        <Typography variant="body1">Loading...</Typography>
      )}
    </>
  );
};
export default ViewDistributors;
