import { API } from "aws-amplify";

const apiName = "backendGateway"; //should be env variable
const get = async (endpoint, params) => {
  const response = await API.get(apiName, endpoint, {
    queryStringParameters: params,
  });
  if (response.status === 200) {
    return response;
  }
  return null;
};
const getBinary = async (endpoint, params) => {
  // Notice responseType
  const response = await API.get(apiName, endpoint, {
    queryStringParameters: params,
    responseType: "blob", // or 'arraybuffer'
  });

  // Now 'response' should be a Blob (if responseType='blob') or an object with { data: ArrayBuffer } if arraybuffer
  if (!response) {
    return null;
  }

  // if Amplify returns { data, status, headers }, do:
  // if (response.status === 200) return response.data;
  // else return null;

  // But if it directly returns a Blob, we can do:
  return response;
};
const post = async (endpoint, params, query = {}) => {
  const response = await API.post(apiName, endpoint, {
    body: params,
    queryStringParameters: query,
    headers: {},
  });
  if ([200, 201].includes(response.status)) {
    return response;
  }
  return { status: response.status, results: [] };
};

//201 Check?
const put = async (endpoint, params) => {
  const response = await API.put(apiName, endpoint, {
    body: params,
    headers: {},
  });
  if (response.status === 204) {
    return response;
  }
  return response;
};

const patch = async (endpoint, params) => {
  const response = await API.patch(apiName, endpoint, {
    body: params,
    headers: {},
  });
  if (response.status === 204) {
    return response;
  }
  return response;
};

const del = async (endpoint) => {
  const response = await API.del(apiName, endpoint, {
    headers: {},
  });
  if (response.status === 200) {
    return response;
  }
  return { status: response.status, results: [] };
};

export default {
  get,
  post,
  getBinary,
  put,
  patch,
  del,
};
