import { useEffect, useState, useMemo, useCallback } from "react";
import { object, string, number } from "yup";
import { Alert, Box, Button } from "@mui/material";
import { Link } from "react-router-dom";

import { useStore } from "../../../../stores/StoreContext";
import formsAPI from "../../../../api/forms";
import Form from "./Form";
import { normaliseAnswers, normaliseFields } from "../helpers";
import { fieldTypes } from "../fieldTypes";
import { withStyles } from "@mui/styles";

const SendButton = withStyles((theme) => ({
  root: {
    color: "white",
    backgroundColor: "#0c45a6",
    "&:hover": {
      backgroundColor: "#1152F3",
    },
  },
}))(Button);

const Respond = ({
  uuid,
  response_id = null,
  account_id = null,
  close = () => {},
  answers = [],
  setAnswers = () => {},
  allowArchived = false,
  form = null,
  setForm = () => {},
}) => {
  const flattenFormData = useCallback((formObj) => {
    if (
      formObj &&
      typeof formObj === "object" &&
      formObj.data &&
      typeof formObj.data === "object"
    ) {
      const { data, ...rest } = formObj;
      return { ...data, ...rest };
    }
    return formObj;
  }, []);

  const flattenedForm = useMemo(
    () => flattenFormData(form),
    [form, flattenFormData]
  );
  const [fields, setFields] = useState([]);
  const [saving, setSaving] = useState(false);
  const [errors, setErrors] = useState([]);
  const [successMessage, setSuccessMessage] = useState("");
  const { userInfo } = useStore();
  const reset = () => {
    setErrors([]);
    setAnswers([]);
    setSuccessMessage("");
  };

  const editMode = response_id ? true : false;

  const saveResponse = () => {
    setSaving(true);
    const { user_id, manufacturer_id } = userInfo;
    const payload = {
      form_uuid: uuid,
      response_body: {
        values: answers,
        attachedImages: [],
      },
      user_id,
      manufacturer_id,
      form_id: flattenedForm.form_id,
      account_id,
    };
    formsAPI
      .saveFormResponse(payload)
      .then((resp) => {
        setSuccessMessage(
          'Response sent. Click "Reset" to start a fresh response.'
        );
        setTimeout(() => {
          close();
        }, 1500);
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setSaving(false);
      });
  };

  const updateResponse = () => {
    setSaving(true);
    const { user_id, manufacturer_id } = userInfo;
    const payload = {
      form_uuid: uuid,
      form_id: flattenedForm.form_id,
      response_id,
      response_body: {
        values: answers,
        attachedImages: [],
      },
      user_id,
      manufacturer_id,
      account_id,
    };
    formsAPI
      .updateFormResponse(payload)
      .then(() => {
        setSuccessMessage("Response updated");
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setSaving(false);
      });
  };

  useEffect(() => {
    if (form?.form_body && !fields.length) {
      const rawFields = JSON.parse(form.form_body);
      const cleanedFields = normaliseFields(rawFields, fieldTypes);
      setFields(cleanedFields);
    }
    const getForm = (uuid) => {
      formsAPI.getForm(uuid).then(({ results }) => {
        const rawFields = JSON.parse(results.form_body);
        const cleanedFields = normaliseFields(rawFields, fieldTypes);
        setFields(cleanedFields);
        setForm(results);
      });
    };

    const loadResponse = ({ form_id, response_id }) => {
      console.log("load response");
      formsAPI
        .getFormResponse({
          form_id,
          response_id,
        })
        .then(({ results }) => {
          console.log("got response");
          const { form_id } = results;
          formsAPI
            .getFormVersionById({
              form_uuid: uuid,
              form_id,
              include_archived: 1,
            })
            .then(({ result }) => {
              console.log("got versioned form");
              setForm(result);
              const rawFields = JSON.parse(result.form_body);
              const cleanedFields = normaliseFields(rawFields, fieldTypes);
              setFields(cleanedFields);
            })
            .catch((error) => {
              console.error("Error fetching versioned form:", error);
            });

          const rAnswers = JSON.parse(results.response_body);
          if (rAnswers.values.length > 0) {
            const values = rAnswers.values;
            const legacyAnswerFound = values.find((v) => /(^{")/.test(v.value));
            if (legacyAnswerFound) {
              const normalised = normaliseAnswers(values);
              setAnswers(normalised);
            } else {
              setAnswers(values);
            }
          }
        })
        .catch((error) => {
          console.error("Error loading response:", error);
        });
    };
    if (uuid && response_id) {
      loadResponse({ form_id: uuid, response_id: response_id });
    } else if (uuid && form === null) {
      getForm(uuid);
    } else {
      console.log({
        form,
        uuid,
        response_id,
      });
    }
  }, [uuid, allowArchived, setAnswers]);

  /** create an object for easy use by getAnswer() */
  const answerObject = useMemo(() => {
    return fields.reduce((acc, field) => {
      return {
        ...acc,
        [field.field_name]: answers.find((a) => a.name === field.field_name),
      };
    }, {});
  }, [answers, fields]);
  /**
   * generate a yup testing schema from fields in DB
   */
  const validationSchema = useMemo(() => {
    const email = string().email();
    const num = number();
    const stringReq = string().required();
    const emailReq = string().email().required();
    const numberReq = number().required();
    const matchField = (field) => {
      const { required } = field;
      if (field.element === "TextInput" && field.variant === "email") {
        return required ? emailReq : email;
      }
      if (field.element === "NumberInput") {
        return required ? numberReq : num;
      }
      if (field.element === "Account") {
        return object().required();
      }
      return stringReq;
    };
    const rawObj = fields.reduce((acc, field) => {
      if (
        field.required ||
        field.element === "NumberInput" ||
        field.variant === "email"
      ) {
        return {
          ...acc,
          [field.field_name]: matchField(field),
        };
      }
      return acc;
    }, {});
    return object(rawObj);
  }, [fields]);
  /**
   * create a memoised object for yup to test
   */
  const validationTestObject = useMemo(() => {
    return answers.reduce((acc, answer) => {
      return {
        ...acc,
        [answer.name]: Array.isArray(answer.value)
          ? answer.value.join(",")
          : answer.value,
      };
    }, {});
  }, [answers]);
  const genSpecialMessage = (error, friendlyName) => {
    if (["optionality", "required"].includes(error.type)) {
      return `${friendlyName} is required`;
    }
    if (["typeError", "email"].includes(error.type)) {
      const desiredType = error?.params?.type || error.type;
      if (["Account"].includes(error.key)) {
        return friendlyName + " must have an option selected";
      }
      return friendlyName + " must be a valid " + desiredType;
    }
    return "";
  };
  /**
   * Combine fields with errors for custom labels
   * @param {Object[]} errors
   * @returns
   */
  const annotatedErrors = (errors) => {
    const fieldNames = fields.reduce((acc, field) => {
      return { ...acc, [field.field_name]: field.label };
    }, {});
    return errors.map((e) => ({
      ...e,
      specialMessage: genSpecialMessage(e, fieldNames[e.path]),
    }));
  };

  const validateForm = async () => {
    try {
      const res = await validationSchema.validate(validationTestObject, {
        abortEarly: false,
      });
      if (res) {
        setErrors([]);
      }
    } catch (e) {
      setErrors(annotatedErrors(e.inner));
    }
  };
  const reValidateForm = () => {
    if (errors.length) {
      validateForm();
    }
  };
  /**
   * Get answer matched to a field.field_name
   * @param {Object} field
   * @returns
   */
  const getAnswer = (field) => {
    const { field_name, value, custom_name } = field;
    if (answerObject[field_name]) {
      return answerObject[field_name];
    }
    if (["checkbox", "radio", "file"].includes(value)) {
      return {
        value: [],
        textValue: [],
        name: field_name,
        custom_name,
      };
    }
    return {
      value: "",
    };
  };

  const submit = (event) => {
    event.preventDefault();
    validationSchema
      .validate(validationTestObject, {
        abortEarly: false,
      })
      .then(() => saveResponse())
      .catch((e) => setErrors(annotatedErrors(e.inner)));
  };

  const update = (event) => {
    event.preventDefault();
    validationSchema
      .validate(validationTestObject, {
        abortEarly: false,
      })
      .then(() => updateResponse())
      .catch((e) => setErrors(annotatedErrors(e.inner)));
  };

  return (
    <Box p={3} width={"100%"}>
      {!form && (
        <div
          style={{ backgroundColor: "#FFFFFF", borderRadius: 5, padding: 40 }}
        >
          Loading...
        </div>
      )}
      <Box width={"100%"} display={"flex"}>
        <Box width={"100%"} flex={1}>
          {form && (
            <form
              onBlur={reValidateForm}
              onInput={reValidateForm}
              onSubmit={editMode ? update : submit}
            >
              <Form
                form={form}
                answers={answers}
                setAnswers={setAnswers}
                errors={errors}
                setErrors={setErrors}
                fields={fields}
                validateForm={validateForm}
                getAnswer={getAnswer}
              />

              <Box
                mr={2}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  backgroundColor: "#FFFFFF",
                  padding: 10,
                  width: "100%",
                  borderRadius: 4,
                  marginTop: 5,
                }}
              >
                <Box>
                  <Button
                    size="large"
                    onClick={reset}
                    disabled={saving || answers.length === 0}
                  >
                    Reset
                  </Button>
                </Box>
                <Box>
                  <SendButton
                    type="submit"
                    size="large"
                    onClick={editMode ? update : submit}
                    disabled={saving || answers.length === 0}
                    variant="contained"
                  >
                    {editMode ? "Update" : "Send"}
                  </SendButton>
                </Box>
              </Box>
              <Box my={2}>
                {errors.length > 0 && (
                  <Alert severity="error">
                    {errors.map((err, i) => (
                      <p key={i}>
                        {err.specialMessage}{" "}
                        {/* TODO: correct scrolling behaviour and re-add */}
                        {/* <a href={`#${err.path}`} onClick={scrollToFix}>
                          [Scroll to this field]
                        </a> */}
                      </p>
                    ))}
                  </Alert>
                )}
              </Box>
              <Box my={1}>
                {successMessage && (
                  <Alert severity="success">
                    {successMessage}{" "}
                    <Link to="/app/forms">Go back to Forms</Link>
                  </Alert>
                )}
              </Box>
            </form>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Respond;
