import { Container, Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import Page from "../../components/Page";
import { useStore } from "../../stores/StoreContext";
import StockPivotTablePlain from "../reports/PivotView/StockPivotTablePlain";

const Stock = () => {
  const { manufacturer, userInfo, dateRange } = useStore();
  const [usstate, setUsstate] = useState("");
  const [filterCol, setFilterCol] = useState([]);

  const updateFilterCol = (filter) => {
    const { col, value } = filter[0];
    if (filter.length === 1 && filter[0].value === "SHOWALL") {
      setFilterCol([]);
    } else {
      setFilterCol([{ col, value }]);
    }
  };

  useEffect(() => {
    console.log(manufacturer);
    const { state_codes } = manufacturer;
    if (state_codes.length > 0) {
      setUsstate(state_codes[0]);
    }
  }, [manufacturer]);

  return (
    <Page
      title="Stock"
      style={{ overflow: "visible", position: "relative", height: "100%" }}
    >
      <div id="m"></div>
      <Container maxWidth={false} style={{}}>
        <Box py={2}>
          {manufacturer.state_codes.map((s) => (
            <Button
              variant={usstate === s ? "contained" : "text"}
              onClick={() => setUsstate(s)}
            >
              {s}
            </Button>
          ))}
        </Box>
        {/* 
		&stock_rows=STORENUMBER%252bSTORE
		&stock_cols=PRODUCTNAME
		&stock_aggregatorName=Sum
		&stock_rendererName=Table%20Heatmap
		&stock_vals=AVAILABLESTOCK
		&dropDowns=PRODUCTNAME
		*/}
        {usstate && (
          <StockPivotTablePlain
            rows={["STORENUMBER", "STORE"]}
            cols={["PRODUCTNAME"]}
            usstate={usstate}
            userInfo={userInfo}
            aggregatorName={"Sum"}
            rendererName={"Table Heatmap"}
            dropDowns={["PRODUCTNAME"]}
            vals={["AVAILABLESTOCK"]}
            dateRange={dateRange}
            filterCol={filterCol}
            setFilterCol={updateFilterCol}
          />
        )}
      </Container>
    </Page>
  );
};

export default Stock;
