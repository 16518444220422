import { DateTime } from "luxon";

export const PresetOptions = [
  {
    id: 0,
    name: "Depletions",
    value: "14-day-sales",
    settings: {
      sales: {
        // attributes
        rows: ["REGION", "PRODUCTNAME"],
        // values
        cols: ["DATE"],
        // render name
        rendererName: "Table Heatmap",
        aggregatorName: "Sum",
        vals: ["QTYSOLD"],
      },
      stock: {
        rows: ["STORE"],
        cols: ["PRODUCTNAME"],
        rendererName: "Table",
        aggregatorName: "Sum",
        vals: ["AVAILABLESTOCK"],
      },
      dateRange: [],
      dropDowns: [
        {
          name: "Product",
          value: "PRODUCTNAME",
        },
      ],
    },
  },
  {
    id: 1,
    name: "Accounts Sold",
    value: "store-stock-sales",
    settings: {
      sales: {
        // attributes
        rows: ["STORENUMBER"],
        // values
        cols: ["DATE"],
        // render name
        rendererName: "Table Heatmap",
        aggregatorName: "Sum",
        vals: ["QTYSOLD"],
      },
      stock: {
        rows: ["STORE"],
        cols: ["PRODUCTNAME"],
        rendererName: "Table",
        aggregatorName: "Sum",
        vals: ["AVAILABLESTOCK"],
      },
      dateRange: [],
      dropDowns: [
        {
          label: "Store",
          value: "STORENUMBER",
        },
      ],
    },
  },
  {
    id: 3,
    name: "Territory",
    value: "sales-by-region",
    settings: {
      sales: {
        // attributes
        rows: ["REGION"],
        // values
        cols: ["DATE"],
        // render name
        rendererName: "Table Heatmap",
        aggregatorName: "Sum",
        vals: ["QTYSOLD"],
      },
      stock: {
        rows: ["STORE"],
        cols: ["PRODUCTNAME"],
        rendererName: "Table",
        aggregatorName: "Sum",
        vals: ["AVAILABLESTOCK"],
      },
      dateRange: [],
      dropDowns: [
        {
          name: "Region",
          value: "REGION",
        },
      ],
    },
  },
  {
    id: 4,
    name: "Stock",
    value: "stock",
    settings: {
      sales: {
        rows: [],
        cols: [],
      },
      stock: {
        rows: ["STORENUMBER", "STORE"],
        cols: ["PRODUCTNAME"],
        rendererName: "Table Heatmap",
        aggregatorName: "Sum",
        vals: ["AVAILABLESTOCK"],
      },
      dateRange: [],
      dropDowns: [
        {
          name: "Product",
          value: "PRODUCTNAME",
        },
      ],
    },
  },
];

const tableToQuery = (tableName, settings, id) => {
  const {
    rows,
    cols,
    rendererName = "Table",
    aggregatorName = "Count",
    vals = [],
  } = settings;
  let args = [`presetId=${id}`];
  if (rows.length) {
    args.push(`${tableName}_rows=${rows.join("%2b")}`);
  }
  if (cols.length) {
    args.push(`${tableName}_cols=${cols.join("%2b")}`);
  }
  args.push(`${tableName}_aggregatorName=${aggregatorName}`);
  args.push(`${tableName}_rendererName=${rendererName}`);
  if (vals.length) {
    args.push(`${tableName}_vals=${vals.join("+")}`);
  }
  return args;
};

export const queryBuilder = (preset) => {
  const { id, settings } = preset;
  const {
    sales,
    stock,
    dateRange,
    dropDowns = [],
    customise = false,
  } = settings;
  const queryBlock = [
    ...tableToQuery("sales", sales, id),
    ...tableToQuery("stock", stock, id),
  ];
  if (dateRange.length) {
    queryBlock.push(`dateRange=${dateRange[0]}_${dateRange[1]}`);
  }
  if (dropDowns.length) {
    queryBlock.push(`dropDowns=${dropDowns.map((dd) => dd.value).join("+")}`);
  }
  if (customise) {
    queryBlock.push(`customise=${customise}`);
  }
  const query = encodeURI(`?` + queryBlock.join("&"));
  return query;
};

export const dataFilter = (
  results,
  dateRange = [],
  filterCol1 = [],
  quantity,
  premise_type,
  selectedBrands,
  filterCol2 = []
) => {
  let filtered = [...results];
  // console.log({
  //   dateRange,
  //   filterCol1,
  // })
  // filter out date range
  // if (dateRange.length) {
  //   // filter the data based on date range
  //   // console.log(results[0], results[results.length - 1], dateRange)
  //   filtered = filtered.filter((r) => {
  //     const resultDate = DateTime.fromISO(r.DATE);
  //     const start = DateTime.fromISO(dateRange[0]);
  //     const end = DateTime.fromISO(dateRange[1]);
  //     return resultDate >= start && resultDate < end;
  //   });
  // } else {
  //   // console.log('No date range')
  // }

  // filter by column and value if present
  if (filterCol1.length && filterCol1[0].value) {
    filtered = filtered.filter((f) => {
      const { col, value } = filterCol1[0];
      // console.log(col, value, f)
      return f[col] === decodeURI(value);
    });
  }
  if (filterCol2.length && filterCol2[0].value) {
    filtered = filtered.filter((f) => {
      const { col, value } = filterCol2[0];
      return f[col] === decodeURI(value);
    });
  }
  filtered = filtered.filter((f) => {
    return premise_type == "ALL" || f.PREMISETYPE === premise_type;
  });
  if (selectedBrands && selectedBrands.length > 0) {
    console.log("FO", filtered[0], selectedBrands);
    filtered = filtered.filter((item) =>
      selectedBrands
        .map((sb) => sb.value)
        .join("")
        .toLowerCase()
        .includes(item.BRAND.toLowerCase())
    );
  }
  return filtered.map((f) => ({
    ...f,
    QTYSOLD: f[quantity],
    DATE: DateTime.fromISO(f.DATE).toUTC().toFormat("yyyy/MM/dd"),
  }));
};

export default {
  PresetOptions,
};
