import { Outlet } from "react-router-dom";
const FullOverlay = () => {
  return (
    <div
      style={{
        margin: "12px auto",
        width: "90vw",
        maxWidth: "1280px",
      }}
    >
      <Outlet />
    </div>
  );
};

export default FullOverlay;
