import client from "./client";
import APICallWrapper from "./APICallWrappers";

const getFolders = () => {
  const endpoint = "/storage/v2/folders";
  return client.get(endpoint);
};

const saveFolder = (folder) => {
  const endpoint = "/storage/v2/folders/";
  return client.post(endpoint, folder);
};

const patchFolder = ({ folder_id, patch }) => {
  const endpoint = "/storage/v2/folders/" + folder_id;
  return client.patch(endpoint, patch);
};

const deleteFolder = (folder_id) => {
  const endpoint = `/storage/v2/folders/${folder_id}`;
  return client.del(endpoint);
};

const getFiles = () => {
  const endpoint = "/storage/v2/files";
  return client.get(endpoint);
};

const saveFile = (file) => {
  const endpoint = "/storage/v2/files";
  const key = "saveFile-" + new Date();
  return APICallWrapper(
    key,
    "Saving file",
    "File saved",
    "Could not save file",
    () => client.post(endpoint, file)
  );
};

const patchFile = ({ file_id, patch }) => {
  const endpoint = "/storage/v2/files/" + file_id;
  return client.patch(endpoint, patch);
};

const deleteFile = (file_id) => {
  const endpoint = "/storage/v2/files/" + file_id;
  return client.del(endpoint);
};

// custom handlers to define specific messages, etc, for call wrapper
const copyFile = (file, folder) => {
  const endpoint = "/storage/v2/files";
  const key = "saveFile-" + new Date();
  const { file_name } = file;
  const { name } = folder;
  return APICallWrapper(
    key,
    `Copying ${file_name} to ${name}`,
    "Copy complete",
    "Could not copy file",
    () => client.post(endpoint, file)
  );
};

const moveFile = ({ file_id, patch }, folder, file_name) => {
  const endpoint = "/storage/v2/files/" + file_id;
  const key = "saveFile-" + new Date();
  const { name } = folder;
  return APICallWrapper(
    key,
    `Moving ${file_name} to ${name}`,
    "Move complete",
    "Could not move file",
    () => client.patch(endpoint, patch)
  );
};

export default {
  getFolders,
  saveFolder,
  patchFolder,
  deleteFolder,
  getFiles,
  deleteFile,
  saveFile,
  copyFile,
  moveFile,
  patchFile,
};
