import { Business, Inventory, LocalShipping } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";

import {
  AccountsFilter,
  DistributorsFilter,
  ProductsFilter,
  FieldDropdown,
  filterButtonSx,
} from "..";
import { useEffect } from "react";

const sectionHeaderStyle = {
  fontWeight: "bold",
  fontSize: 18,
  marginBottom: 20,
  component: "div",
};

/* =======================
   FieldsContent Component
   ======================= */
export const FieldsContent = ({
  fieldGroups,
  expandedSection,
  setExpandedSection,
  selectedFields,
  setSelectedFields,
  searchQueries,
  setSearchQueries,
  requestBody,
}) => {
  return (
    <>
      <Box sx={{ borderBottom: "1px solid #EAEAEA", mb: 2, pb: 3 }}>
        <Typography style={sectionHeaderStyle}>
          Select the fields you want in your report
        </Typography>
        {Object.keys(fieldGroups).map((groupKey) => (
          <FieldDropdown
            key={groupKey}
            groupKey={groupKey}
            fieldGroups={fieldGroups}
            expandedSection={expandedSection}
            selectedFields={selectedFields}
            setExpandedSection={setExpandedSection}
            searchQueries={searchQueries}
            setSearchQueries={setSearchQueries}
            setSelectedFields={setSelectedFields}
          />
        ))}
      </Box>
    </>
  );
};

/* =======================
   FiltersContent Component
   ======================= */
export const FiltersContent = ({
  selectedFilter,
  setSelectedFilter,
  selectedFields,
  requestBody,
  setRequestBody,
  filteringArrays,
  setFilteringArrays,
}) => {
  const handleDimensionFiltersChange = (filterType, values) => {
    setRequestBody((prev) => ({
      ...prev,
      filters: {
        ...prev.filters,
        dimensions: {
          ...prev.filters?.dimensions,
          [filterType]: values,
        },
      },
    }));
  };

  const renderSelectedFilterContent = () => {
    switch (selectedFilter) {
      case "accounts":
        return (
          <AccountsFilter
            selectedFields={selectedFields}
            filters={requestBody.filters?.dimensions?.accounts}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            filteringArrays={filteringArrays}
            setFilteringArrays={setFilteringArrays}
            onChange={(values) =>
              handleDimensionFiltersChange("accounts", values)
            }
          />
        );
      case "distributors":
        return (
          <DistributorsFilter
            selectedFields={selectedFields}
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            filteringArrays={filteringArrays}
            setFilteringArrays={setFilteringArrays}
            filters={requestBody.dimensions}
            onChange={(values) =>
              handleDimensionFiltersChange("distributors", values)
            }
          />
        );
      case "products":
        return (
          <ProductsFilter
            requestBody={requestBody}
            setRequestBody={setRequestBody}
            selectedFields={selectedFields}
            filters={requestBody.dimensions}
            filteringArrays={filteringArrays}
            setFilteringArrays={setFilteringArrays}
            onChange={(values) =>
              handleDimensionFiltersChange("products", values)
            }
          />
        );
      default:
        return null;
    }
  };

  return (
    <Box>
      <Typography style={sectionHeaderStyle}>
        Choose how you want your data filtered (optional)
      </Typography>
      <p>{}</p>

      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
        mb={2}
      >
        {[
          { icon: Business, value: "accounts", label: "Accounts" },
          { icon: LocalShipping, value: "distributors", label: "Distributors" },
          { icon: Inventory, value: "products", label: "Products" },
        ].map(({ icon: Icon, value, label }) => (
          <Box mb={1}>
            <Button
              key={value}
              variant="contained"
              startIcon={<Icon />}
              onClick={() => setSelectedFilter(value)}
              sx={filterButtonSx(selectedFilter === value)}
              fullWidth
            >
              {label}
            </Button>
          </Box>
        ))}
      </Box>

      {renderSelectedFilterContent()}
    </Box>
  );
};
