import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { Storage, API } from "aws-amplify";
import { useObserver } from "mobx-react-lite";
import {
  Avatar,
  Box,
  IconButton,
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
  Typography,
} from "@mui/material";
import { awsS3Image } from "../../../helpers/awsS3Image";
import { makeStyles } from "@mui/styles";
import { AddAPhoto } from "@mui/icons-material";
Storage.configure({ level: "protected" });

const useStyles = makeStyles(() => ({
  root: {},
  avatar: {
    height: 100,
    width: 100,
  },
}));

const Profile = ({
  className,
  profile,
  setUser,
  setUserInfo,
  userInfo,
  ...rest
}) => {
  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);
  const changeHandler = (event) => {
    handleSubmission(event.target.files[0]);
  };

  async function handleSubmission(file) {
    setLoading(true);
    try {
      await Storage.put(file.name, file, {
        contentType: "image/*", // contentType is optional
        level: "protected",
      });
    } catch (err) {
      console.log("Error uploading file: ", err);
    }
    profile = {
      ...profile,
      user_image: file.name,
      image_scr: awsS3Image(file.name, userInfo.user_identitykey, "0x100"),
    };
    setUser(profile);
    postData();
    setLoading(false);
  }

  async function postData() {
    setLoading(true);
    const update = {
      ...profile,
      user_identitykey: userInfo.user_identitykey,
    };
    setUser(update);
    setUserInfo(update);
    try {
      const apiName = "backendGateway";
      const path = "/users";
      const myInit = {
        // OPTIONAL
        body: update,
        headers: {}, // OPTIONAL
      };
      await API.put(apiName, path, myInit);
    } catch (err) {
      console.log("error fetching data..", err);
    }
    setLoading(false);
  }

  return useObserver(() => (
    <div>
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardContent>
          <CardHeader
            title=""
            action={
              <Box>
                <label htmlFor="icon-button-file">
                  <IconButton aria-label="schedule" component="span">
                    <AddAPhoto fontSize="default" />
                  </IconButton>
                </label>
                <input
                  accept="image/*"
                  type="file"
                  id="icon-button-file"
                  hidden
                  onChange={changeHandler}
                />
              </Box>
            }
          ></CardHeader>
          <Box alignItems="center" display="flex" flexDirection="column">
            {loading && (
              <div>
                {" "}
                <CircularProgress />{" "}
              </div>
            )}

            {profile.user_image === "gg_default_image.png" && (
              <Avatar
                className={classes.avatar}
                src="/static/images/avatars/gg_default_image.png"
              />
            )}

            {loading == false &&
              profile.user_image !== "gg_default_image.png" && (
                <Avatar
                  className={classes.avatar}
                  key={profile.user_image}
                  src={profile.image_scr}
                  alt=""
                />
              )}
            <Typography
              color="textPrimary"
              gutterBottom
              variant="h5"
              align="center"
            >
              {userInfo.user_displayname}
            </Typography>
            <Typography color="textSecondary" variant="h6">
              {userInfo.user_jobrole || ""}
            </Typography>
            <Typography color="textSecondary" variant="body1">
              {`${userInfo.user_city}, ${userInfo.user_state}`}
            </Typography>
          </Box>
        </CardContent>
      </Card>
    </div>
  ));
};

Profile.propTypes = {
  className: PropTypes.string,
};

export default Profile;
