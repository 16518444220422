import { DateTime } from "luxon";
import { getGridStringOperators } from "@mui/x-data-grid-pro";
const cleanNumber = (val) => {
  // return an integer unchanged
  if (Number.isInteger(val)) {
    return val;
  }
  // return a float capped to 2 decimal points
  if (typeof val === "number") {
    return val.toFixed(2);
  }
  // return non-numeric unchanged
  return val;
};

export const bottomRowId = 34567;

export const differedTotals = (totals) => {
  const processed = totals;
  const { total, previousTotal } = totals;
  const diff = total - previousTotal;
  processed.diff = Number.isInteger(Math.round(diff)) ? diff : 0;
  const percent =
    totals.previousTotal === 0 ? 0 : (totals.diff / totals.previousTotal) * 100;
  processed.percent = Number.isInteger(Math.round(percent)) ? percent : 0;
  return processed;
};

const customStringOperators = getGridStringOperators();
customStringOperators.push({
  ...customStringOperators[1],
  label: "not equals",
  value: "notEquals",
  getApplyFilterFn: (filterItem, column) => {
    if (
      !filterItem.columnField ||
      !filterItem.value ||
      !filterItem.operatorValue
    ) {
      return null;
    }
    return (cell) => {
      return cell.value.toLowerCase() != filterItem.value.toLowerCase();
    };
  },
});
customStringOperators.push({
  ...customStringOperators[1],
  label: "not contains",
  value: "notContains",
  getApplyFilterFn: (filterItem, column) => {
    if (
      !filterItem.columnField ||
      !filterItem.value ||
      !filterItem.operatorValue
    ) {
      return null;
    }
    return (cell) => {
      const reg = new RegExp(filterItem.value, "i");
      return !reg.test(cell.value);
    };
  },
});

export const regionProductReducer = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        DATE,
        PRODUCTNAME,
        STATE,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      DATE = DATE.substring(0, 7);
      const objectKey = [STATE, PRODUCTNAME].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQUIV_PREV += CASE_EQV;
          acc.list[objectKey].comparison_only = true;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          [DATE]: cur[quantity],
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: `Date${COMPARISON_DATA ? " Comparison" : ""}`,
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        BRAND: "",
        PRODUCTNAME: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 30,
          filterOperators: customStringOperators,
        },
        // {
        //   field: "CITY",
        //   headerName: "City",
        //   width: 130,
        //  filterOperators: customStringOperators,
        // },
        {
          field: "PRODUCTNAME",
          headerName: "Product",
          width: 280,
          filterOperators: customStringOperators,
        },
        {
          field: "BRAND",
          headerName: "Brand",
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};
export const regionBrandReducer = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        DATE,
        BRAND,
        STATE,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [STATE, BRAND].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          [DATE]: cur[quantity],
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: "Date" + (COMPARISON_DATA ? " Comparison" : ""),
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        BRAND: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 30,
          filterOperators: customStringOperators,
        },
        // {
        //   field: "CITY",
        //   headerName: "City",
        //   width: 130,
        //  filterOperators: customStringOperators,
        // },
        {
          field: "BRAND",
          headerName: "Brand",
          width: 200,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const accountProductReducer = (
  arr,
  quantity,
  { products, productSold, showMonths, showComparison }
) => {
  console.log(
    "Reducer run - account -> product - ",
    new Date(),
    " items: ",
    arr.length
  );
  const productIds = products.map((p) => p.product_id);
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        DATE,
        PRODUCTNAME,
        STORENUMBER,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        CASE_EQV,
        PRODUCT_ID,
        COMPARISON_DATA,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [STORENUMBER, PRODUCTNAME].join("_");
      if (products.length) {
        if (productSold && productIds.includes(PRODUCT_ID)) {
          if (acc.goodAccounts[STORENUMBER]) {
            acc.goodAccounts[STORENUMBER].add(PRODUCT_ID);
          } else {
            acc.goodAccounts[STORENUMBER] = new Set([PRODUCT_ID]);
          }
        }
        if (!productSold && productIds.includes(PRODUCT_ID)) {
          acc.removeAccounts.push(STORENUMBER);
        }
      }
      if (typeof acc.list[objectKey] !== "undefined") {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          [DATE]: cur[quantity],
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          goodIds: productIds.includes(PRODUCT_ID)
            ? new Set([PRODUCT_ID])
            : new Set(),
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: `Date${COMPARISON_DATA ? " Comparison" : ""}`,
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      removeAccounts: [],
      goodAccounts: {},
      columnsTotals: {
        id: bottomRowId,
        PRODUCTNAME: "",
        BRAND: "",
        STORENUMBER: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "STORENUMBER",
          headerName: "Account",
          width: 160,
          filterOperators: customStringOperators,
        },
        {
          field: "PRODUCTNAME",
          headerName: "Product",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "BRAND",
          headerName: "Brand",
          filterOperators: customStringOperators,
        },
        {
          field: "DISTRIBUTOR_NAME",
          headerName: "Distributor",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "PREMISETYPE",
          headerName: "Premise",
          valueFormatter: (params) => {
            if (params.value === "ON-PREMISE") {
              return "On";
            }
            if (params.value === "OFF-PREMISE") {
              return "Off";
            }
          },
        },
        {
          field: "CITY",
          headerName: "City",
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });

  if (products.length > 0 && !productSold) {
    tabled.list = tabled.list.filter(
      (a) => !tabled.removeAccounts.includes(a.STORENUMBER)
    );
  }
  if (products.length && productSold) {
    tabled.list = tabled.list.filter(
      (a) =>
        tabled.goodAccounts[a.STORENUMBER] &&
        tabled.goodAccounts[a.STORENUMBER].size >= products.length
    );
  }
  tabled.pinnedRows = {
    top: [],
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const accountBrandReducer = (
  arr,
  quantity,
  { products, productSold, showMonths, showComparison }
) => {
  console.log(arr[0]);
  const productIds = products.map((p) => p.product_id);
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        DATE,
        BRAND,
        STORENUMBER,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        CASE_EQV,
        PRODUCT_ID,
        COMPARISON_DATA,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [STORENUMBER, BRAND].join("_");
      if (products.length) {
        if (productSold && productIds.includes(PRODUCT_ID)) {
          if (acc.goodAccounts[STORENUMBER]) {
            acc.goodAccounts[STORENUMBER].add(PRODUCT_ID);
          } else {
            acc.goodAccounts[STORENUMBER] = new Set([PRODUCT_ID]);
          }
        }
        if (!productSold && productIds.includes(PRODUCT_ID)) {
          acc.removeAccounts.push(STORENUMBER);
        }
      }
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          [DATE]: cur[quantity],
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          goodIds: productIds.includes(PRODUCT_ID)
            ? new Set([PRODUCT_ID])
            : new Set(),
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: `Date${COMPARISON_DATA ? " Comparison" : ""}`,
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      removeAccounts: [],
      goodAccounts: {},
      columnsTotals: {
        id: bottomRowId,
        PRODUCTNAME: "",
        BRAND: "",
        STORENUMBER: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "STORENUMBER",
          headerName: "Account",
          width: 160,
          filterOperators: customStringOperators,
        },
        {
          field: "BRAND",
          headerName: "Brand",
          filterOperators: customStringOperators,
        },
        {
          field: "DISTRIBUTOR_NAME",
          headerName: "Distributor",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "PREMISETYPE",
          headerName: "Premise",
          valueFormatter: (params) => {
            if (params.value === "ON-PREMISE") {
              return "On";
            }
            if (params.value === "OFF-PREMISE") {
              return "Off";
            }
          },
        },
        {
          field: "CITY",
          headerName: "City",
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });

  if (products.length > 0 && !productSold) {
    tabled.list = tabled.list.filter(
      (a) => !tabled.removeAccounts.includes(a.STORENUMBER)
    );
  }
  if (products.length && productSold) {
    tabled.list = tabled.list.filter(
      (a) =>
        tabled.goodAccounts[a.STORENUMBER] &&
        tabled.goodAccounts[a.STORENUMBER].size >= products.length
    );
  }
  tabled.pinnedRows = {
    top: [],
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const brandTotals = (arr, quantity, { showMonths, showComparison }) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        BRAND,
        DATE,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      if (acc.list[BRAND]) {
        if (Object.hasOwn(acc.list[BRAND], DATE)) {
          acc.list[BRAND][DATE] += cur[quantity];
        } else {
          acc.list[BRAND][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[BRAND].previousTotal += cur[quantity];
          acc.list[BRAND].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[BRAND].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[BRAND].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[BRAND].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[BRAND].total += cur[quantity];
          acc.list[BRAND].UNIT_SOLD += UNIT_SOLD;
          acc.list[BRAND].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[BRAND].NINE_SOLD += NINE_SOLD;
          acc.list[BRAND].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[BRAND] = {
          ...cur,
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          [DATE]: cur[quantity],
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: `Date${COMPARISON_DATA ? " Comparison" : ""}`,
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        BRAND: "Column Totals",
      },
      columns: [
        {
          field: "BRAND",
          headerName: "Brand",
          width: 240,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const productTotals = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        PRODUCTNAME,
        DATE,
        NINE_SOLD,
        PHYSICAL_SOLD,
        UNIT_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      if (acc.list[PRODUCTNAME]) {
        if (acc.list[PRODUCTNAME][DATE]) {
          acc.list[PRODUCTNAME][DATE] += cur[quantity];
        } else {
          acc.list[PRODUCTNAME][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[PRODUCTNAME].previousTotal += cur[quantity];
          acc.list[PRODUCTNAME].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[PRODUCTNAME].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[PRODUCTNAME].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[PRODUCTNAME].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[PRODUCTNAME].total += cur[quantity];
          acc.list[PRODUCTNAME].UNIT_SOLD += UNIT_SOLD;
          acc.list[PRODUCTNAME].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[PRODUCTNAME].NINE_SOLD += NINE_SOLD;
          acc.list[PRODUCTNAME].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[PRODUCTNAME] = {
          ...cur,
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
          [DATE]: cur[quantity],
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: `Date${COMPARISON_DATA ? " Comparison" : ""}`,
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        PRODUCTNAME: "Column Totals",
      },
      columns: [
        {
          field: "PRODUCTNAME",
          headerName: "Product",
          width: 240,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const accountsSold = () => {};

export const brandAccountsSold = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let { BRAND, DATE, STORENUMBER, STATE, COMPARISON_DATA } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [BRAND, STATE].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE].add(STORENUMBER);
        } else {
          acc.list[objectKey][DATE] = new Set();
          acc.list[objectKey][DATE].add(STORENUMBER);
        }
        if (COMPARISON_DATA) acc.list[objectKey].prevFullList.add(STORENUMBER);
        else acc.list[objectKey].fullList.add(STORENUMBER);
      } else {
        let fullList = new Set();
        let prevFullList = new Set();
        if (COMPARISON_DATA) prevFullList.add(STORENUMBER);
        else fullList.add(STORENUMBER);
        acc.list[objectKey] = {
          ...cur,
          id: ind,
          [DATE]: new Set([STORENUMBER]),
          fullList,
          prevFullList,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: `Date${COMPARISON_DATA ? " Comparison" : ""}`,
          valueFormatter: (params) => (params.value ? params.value.size : 0),
          type: "number",
          [DATE]: new Set(STORENUMBER),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? acc.columnsTotals[DATE].add(STORENUMBER)
          : (acc.columnsTotals[DATE] = new Set([STORENUMBER]));
      }

      if (COMPARISON_DATA) acc.columnsTotals.prevFullList.add(STORENUMBER);
      else acc.columnsTotals.fullList.add(STORENUMBER);

      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        BRAND: "Unique Accounts in Month",
        fullList: new Set(),
        prevFullList: new Set(),
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "BRAND",
          headerName: "Brand",
          width: 240,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "fullList",
    headerName: "Unique",
    valueFormatter: (params) => (params.value ? params.value.size : 0),
    type: "number",
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.fullList.size - item.prevFullList.size;
      item.percent =
        item.prevFullList.size === 0
          ? 0
          : (item.diff / item.prevFullList.size) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "prevFullList",
      headerName: "Previous Yr Unique",
      type: "number",
      valueGetter: (params) => (params.value ? params.value.size : 0),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const productAccountsSold = (arr, { showMonths, showComparison }) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let { DATE, PRODUCTNAME, STORENUMBER, STATE, COMPARISON_DATA } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [PRODUCTNAME, STATE].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE].add(STORENUMBER);
        } else {
          acc.list[objectKey][DATE] = new Set();
          acc.list[objectKey][DATE].add(STORENUMBER);
        }
        if (COMPARISON_DATA) acc.list[objectKey].prevFullList.add(STORENUMBER);
        else acc.list[objectKey].fullList.add(STORENUMBER);
      } else {
        let fullList = new Set();
        let prevFullList = new Set();
        if (COMPARISON_DATA) prevFullList.add(STORENUMBER);
        else fullList.add(STORENUMBER);

        acc.list[objectKey] = {
          ...cur,
          id: ind,
          [DATE]: new Set([STORENUMBER]),
          fullList,
          prevFullList,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: "Date" + (COMPARISON_DATA ? " Comparison" : ""),
          valueFormatter: (params) => (params.value ? params.value.size : 0),
          type: "number",
          [DATE]: new Set(STORENUMBER),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? acc.columnsTotals[DATE].add(STORENUMBER)
          : (acc.columnsTotals[DATE] = new Set([STORENUMBER]));
      }

      if (COMPARISON_DATA) acc.columnsTotals.prevFullList.add(STORENUMBER);
      else acc.columnsTotals.fullList.add(STORENUMBER);

      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        fullList: new Set(),
        prevFullList: new Set(),
        PRODUCTNAME: "Unique Accounts in Month",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "PRODUCTNAME",
          headerName: "Product",
          width: 240,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "fullList",
    headerName: "Unique",
    type: "number",
    valueFormatter: (params) => (params.value ? params.value.size : 0),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.fullList.size - item.prevFullList.size;
      item.percent =
        item.prevFullList.size === 0
          ? 0
          : (item.diff / item.prevFullList.size) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "prevFullList",
      headerName: "Previous Yr Unique",
      type: "number",
      valueGetter: (params) => (params.value ? params.value.size : 0),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const distributorBrandTotals = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        BRAND,
        DATE,
        STATE,
        DISTRIBUTOR_NAME,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [BRAND, STATE, DISTRIBUTOR_NAME].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          id: ind,
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
          [DATE]: cur[quantity],
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: "Date" + (COMPARISON_DATA ? " Comparison" : ""),
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }

      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        DISTRIBUTOR_NAME: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "DISTRIBUTOR_NAME",
          headerName: "Distributor",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "BRAND",
          headerName: "Brand",
          width: 240,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const distributorProductTotals = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        DATE,
        PRODUCTNAME,
        STATE,
        DISTRIBUTOR_NAME,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [PRODUCTNAME, STATE, DISTRIBUTOR_NAME].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          id: ind,
          [DATE]: cur[quantity],
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: "Date" + (COMPARISON_DATA ? " Comparison" : ""),
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        DISTRIBUTOR_NAME: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "DISTRIBUTOR_NAME",
          headerName: "Distributor",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "PRODUCTNAME",
          headerName: "Product",
          width: 270,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const distributorTotals = (
  arr,
  quantity,
  { showMonths, showComparison }
) => {
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let {
        DATE,
        STATE,
        DISTRIBUTOR_NAME,
        UNIT_SOLD,
        PHYSICAL_SOLD,
        NINE_SOLD,
        COMPARISON_DATA,
        CASE_EQV,
      } = cur;
      // normalise all dates to month start
      DATE = DATE.substring(0, 7);
      const objectKey = [STATE, DISTRIBUTOR_NAME].join("_");
      if (acc.list[objectKey]) {
        if (acc.list[objectKey][DATE]) {
          acc.list[objectKey][DATE] += cur[quantity];
        } else {
          acc.list[objectKey][DATE] = cur[quantity];
        }
        if (COMPARISON_DATA) {
          acc.list[objectKey].previousTotal += cur[quantity];
          acc.list[objectKey].UNIT_SOLD_PREV += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD_PREV += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD_PREV += NINE_SOLD;
          acc.list[objectKey].CASE_EQV_PREV += CASE_EQV;
        } else {
          acc.list[objectKey].total += cur[quantity];
          acc.list[objectKey].UNIT_SOLD += UNIT_SOLD;
          acc.list[objectKey].PHYSICAL_SOLD += PHYSICAL_SOLD;
          acc.list[objectKey].NINE_SOLD += NINE_SOLD;
          acc.list[objectKey].CASE_EQV += CASE_EQV;
        }
      } else {
        acc.list[objectKey] = {
          ...cur,
          id: ind,
          [DATE]: cur[quantity],
          total: COMPARISON_DATA ? 0 : cur[quantity],
          previousTotal: COMPARISON_DATA ? cur[quantity] : 0,
          // needed for export.
          UNIT_SOLD: COMPARISON_DATA ? 0 : UNIT_SOLD,
          UNIT_SOLD_PREV: COMPARISON_DATA ? UNIT_SOLD : 0,
          PHYSICAL_SOLD: COMPARISON_DATA ? 0 : PHYSICAL_SOLD,
          PHYSICAL_SOLD_PREV: COMPARISON_DATA ? PHYSICAL_SOLD : 0,
          NINE_SOLD: COMPARISON_DATA ? 0 : NINE_SOLD,
          NINE_SOLD_PREV: COMPARISON_DATA ? NINE_SOLD : 0,
          CASE_EQV: COMPARISON_DATA ? 0 : CASE_EQV,
          CASE_EQV_PREV: COMPARISON_DATA ? CASE_EQV : 0,
        };
      }
      const shouldAddDateData =
        (showMonths && !COMPARISON_DATA) ||
        (showMonths && showComparison && COMPARISON_DATA);
      if (shouldAddDateData && !acc.columns.find((c) => c.field === DATE)) {
        acc.columns.push({
          field: DATE,
          headerName: DATE,
          description: "Date" + (COMPARISON_DATA ? " Comparison" : ""),
          type: "number",
          valueGetter: ({ value }) => cleanNumber(value),
        });
      }
      if (shouldAddDateData) {
        acc.columnsTotals[DATE]
          ? (acc.columnsTotals[DATE] += cur[quantity])
          : (acc.columnsTotals[DATE] = cur[quantity]);
      }
      if (!COMPARISON_DATA) {
        acc.columnsTotals.total
          ? (acc.columnsTotals.total += cur[quantity])
          : (acc.columnsTotals.total = cur[quantity]);
      } else {
        acc.columnsTotals.previousTotal
          ? (acc.columnsTotals.previousTotal += cur[quantity])
          : (acc.columnsTotals.previousTotal = cur[quantity]);
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        DISTRIBUTOR_NAME: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "DISTRIBUTOR_NAME",
          headerName: "Distributor",
          width: 240,
          filterOperators: customStringOperators,
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list);
  tabled.columns.push({
    field: "total",
    headerName: "Total",
    type: "number",
    valueGetter: ({ value }) => cleanNumber(value),
  });
  if (showComparison) {
    tabled.list = tabled.list.map((item) => {
      item.diff = item.total - item.previousTotal;
      item.percent =
        item.previousTotal === 0 ? 0 : (item.diff / item.previousTotal) * 100;
      return item;
    });
    tabled.columnsTotals = differedTotals(tabled.columnsTotals);
    tabled.columns.push({
      field: "previousTotal",
      headerName: "Previous Yr Total",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
    });
    tabled.columns.push({
      field: "diff",
      headerName: "Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Number(params.value).toFixed(2)
          : 0,
    });
    tabled.columns.push({
      field: "percent",
      headerName: "% Change",
      type: "number",
      valueGetter: ({ value }) => cleanNumber(value),
      valueFormatter: (params) =>
        params.value
          ? (Number(params.value) > 0 ? "+" : "") +
            Math.round(Number(params.value)) +
            "%"
          : "N/A",
    });
  }
  // sort date cols
  tabled.columns = tabled.columns
    .sort((a, b) => {
      if (!a.description || !b.description) {
        return 0;
      }
      if (a.description.includes("Date") && b.description.includes("Date")) {
        return a.field < b.field ? -1 : 1;
      }
      return 0;
    })
    // format dates
    .map((dc) => {
      if (!dc.description) {
        return dc;
      } else {
        return {
          ...dc,
          headerName: DateTime.fromISO(
            dc.headerName.replaceAll("/", "-")
          ).toFormat("LLL yy"),
        };
      }
    });
  tabled.pinnedRows = {
    bottom: [tabled.columnsTotals],
  };
  return tabled;
};

export const accountReorders = (
  arr,
  quantity,
  { products = [], productSold, dateRange }
) => {
  let total = 0;
  let lifetime_total = 0;
  const tabled = arr.reduce(
    (acc, cur, ind) => {
      let { STORENUMBER, TIMESTAMP } = cur;
      // normalise all dates to month start
      if (acc.list[STORENUMBER]) {
        acc.list[STORENUMBER].lifetime_orders.push(cur);
      } else {
        acc.list[STORENUMBER] = {
          ...cur,
          id: ind,
          lifetime_orders: [cur],
          orders: [],
        };
      }
      lifetime_total++;
      if (dateRange[0] <= TIMESTAMP && TIMESTAMP <= dateRange[1]) {
        acc.list[STORENUMBER].orders.push(cur);
        total++;
      }
      return acc;
    },
    {
      list: {},
      columnsTotals: {
        id: bottomRowId,
        DISTRIBUTOR_NAME: "Column Totals",
      },
      columns: [
        {
          field: "STATE",
          headerName: "ST",
          width: 40,
          filterOperators: customStringOperators,
        },
        {
          field: "STORENUMBER",
          headerName: "Account",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "CITY",
          headerName: "City",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "DISTRIBUTOR_NAME",
          headerName: "Distributor",
          width: 240,
          filterOperators: customStringOperators,
        },
        {
          field: "orders",
          headerName: "Orders",
          type: "number",
          valueGetter: (params) =>
            params.value?.hasOwnProperty("length")
              ? [...new Set(params.value.map((v) => v.DATE))].length
              : params.value || 0,
        },
        {
          field: "lifetime_orders",
          headerName: "Lifetime Orders",
          type: "number",
          valueGetter: (params) =>
            params.value?.hasOwnProperty("length")
              ? [...new Set(params.value.map((v) => v.DATE))].length
              : params.value || 0,
        },
        {
          field: "last_order",
          headerName: "Last Order",
          valueGetter: ({ row }) => {
            if (!row.orders?.length || row.skip) return "";
            const d = row.orders.sort((a, b) => {
              return a.DATE > b.DATE ? 1 : b.DATE > a.DATE ? -1 : 0;
            })[row.orders.length - 1].DATE;
            return DateTime.fromISO(d).toLocaleString();
          },
        },
        {
          field: "last_order_age",
          headerName: "Days Ago",
          width: 140,
          type: "number",
          valueGetter: ({ row }) => {
            if (!row.orders || row.skip) return "";
            const last = row.orders.sort((a, b) => {
              return a.DATE > b.DATE ? 1 : b.DATE > a.DATE ? -1 : 0;
            })[row.orders.length - 1];
            const dayDiff = DateTime.fromISO(last.DATE)
              .diffNow("days")
              .as("days");
            return Math.abs(Math.round(dayDiff));
          },
        },
      ],
    }
  );
  tabled.list = Object.values(tabled.list).filter((o) => o.orders.length > 0);
  if (products.length > 0) {
    const productIDs = products.map((p) => p.product_id);
    if (productSold) {
      // make sure that every product searched is in one order
      tabled.list = tabled.list.filter((a) => {
        const orderProductIDs = a.orders.map((o) => o.PRODUCT_ID);
        return (
          productIDs.filter((p) => orderProductIDs.includes(p)).length ===
          productIDs.length
        );
      });
    } else {
      tabled.list = tabled.list.filter(
        (a) =>
          a.orders.findIndex((o) => productIDs.includes(o.PRODUCT_ID)) === -1
      );
    }
  }
  tabled.columnsTotals = {
    id: bottomRowId,
    orders: total,
    skip: true,
    lifetime_orders: lifetime_total,
  };
  return tabled;
};
