import React from "react";
import RegionCompare from "../../../components/distributor/regions/RegionCompare";
import Page from "../../../components/Page";
import { useStore } from "../../../stores/StoreContext";

const DistributorRegionsView = () => {
  const {
    selectedDistributors,
    selectedBrands,
    selectedStates,
    completeDistBrandData,
    allBrands,
    allDistributors,
  } = useStore();

  return (
    <div>
      <Page style={{ padding: "24px" }}>
        <RegionCompare
          selectedDistributors={selectedDistributors}
          selectedBrands={selectedBrands}
          selectedStates={selectedStates}
          completeDistBrandData={completeDistBrandData}
          allBrands={allBrands}
          allDistributors={allDistributors}
        />
      </Page>
    </div>
  );
};

export default DistributorRegionsView;
