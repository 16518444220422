import { API } from "aws-amplify";
import client from "./client";
import APICallWrapper from "./APICallWrappers";

const apiName = "backendGateway"; //should be env variable

const getDistributors = async (manufacturer_id) => {
  const key = `getDistributors-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading distributors...",
    "Distributors loaded successfully.",
    "Failed to load distributors.",
    () => API.get(apiName, `/manufacturers/${manufacturer_id}/distributors`, {})
  );
};
const postDistributorDeactivate = async (deactivateDistributor) => {
  const key = `postDistributorDeactivate-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating Distributor...",
    "Distributor Updated successfully.",
    "Failed to update Distributor",
    () =>
      client.post(
        "/manufacturers/distributors/deactivate",
        deactivateDistributor
      )
  );
};
const getDistributorsInventory = async (manufacturer_id) => {
  const key = `getDistributorsInventory-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading distributors' inventory...",
    "Distributors' inventory loaded successfully.",
    "Failed to load distributors' inventory.",
    () => API.get(apiName, `/manufacturers/${manufacturer_id}/inventory`, {})
  );
};

const getDistributorsInventoryV2 = async (manufacturer_id) => {
  const key = `getDistributorsInventory-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading distributors' inventory...",
    "Distributors' inventory loaded successfully.",
    "Failed to load distributors' inventory.",
    () => API.get(apiName, `/manufacturers/${manufacturer_id}/inventoryv2`, {})
  );
};

const getDistributorsDataFreshness = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/data_freshness/quickfix`;
  return API.get(apiName, endpoint, {});
};

const getDistributorContacts = (manufacturer_id, distributor_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts`;
  return client.get(endpoint, {});
};
const deleteDistributorContact = async (
  manufacturer_id,
  distributor_id,
  contact_id
) => {
  const key = `deleteDistributorContact-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Deleting distributor contact...",
    "Distributor contact deleted successfully.",
    "Failed to delete distributor contact.",
    () =>
      API.del(
        apiName,
        `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts/${contact_id}`,
        {}
      )
  );
};

const updateDistributorContact = async (
  manufacturer_id,
  distributor_id,
  contact_id,
  data
) => {
  const key = `updateDistributorContact-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Updating distributor contact...",
    "Distributor contact updated successfully.",
    "Failed to update distributor contact.",
    () =>
      client.put(
        `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts/${contact_id}`,
        data
      )
  );
};

const addDistributorContact = async (manufacturer_id, distributor_id, data) => {
  const key = `addDistributorContact-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Adding distributor contact...",
    "Distributor contact added successfully.",
    "Failed to add distributor contact.",
    () =>
      client.post(
        `/manufacturers/${manufacturer_id}/distributors/${distributor_id}/contacts`,
        data
      )
  );
};

const getDistributorDetails = async (manufacturer_id, distributor_id) => {
  const key = `getDistributorDetails-${Date.now()}`;
  return await APICallWrapper(
    key,
    "Loading distributor details...",
    "Distributor details loaded successfully.",
    "Failed to load distributor details.",
    () =>
      client.get(
        `/manufacturers/${manufacturer_id}/distributors/${distributor_id}`,
        {}
      )
  );
};

const getDistributorComparison = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/distributors_and_brands`;
  return client.get(endpoint, {});
};

const getDistributorFinancialComparison = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/financial_report`;
  return client.get(endpoint, {});
};

const getExternalBrands = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/external_brands/cached`;
  return client.get(endpoint, {});
};

const getTopExternalBrands = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/top-external-brands`;
  return client.get(endpoint, {});
};
const getTopExternalBrandsPivot = (manufacturer_id) => {
  const endpoint = `/manufacturers/${manufacturer_id}/top-external-brands-pivot`;
  return client.get(endpoint, {});
};

const reportBuilderGetManufacturers = (manufacturer_id) => {
  const endpoint = "/manufacturers/report-builder-distributors";
  return client.get(endpoint, {});
};

const getDistributorsSalesTotals = (data) => {
  const endpoint = "/manufacturers/sales-totals";
  return client.get(endpoint, data);
};

export default {
  getDistributors,
  getDistributorsDataFreshness,
  getDistributorContacts,
  getDistributorsInventory,
  getDistributorsInventoryV2,
  deleteDistributorContact,
  updateDistributorContact,
  postDistributorDeactivate,
  addDistributorContact,
  getDistributorDetails,
  getDistributorComparison,
  getDistributorFinancialComparison,
  getExternalBrands,
  getTopExternalBrands,
  getTopExternalBrandsPivot,
  reportBuilderGetManufacturers,
  getDistributorsSalesTotals,
};
