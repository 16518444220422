import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Container,
  Typography,
  Drawer,
  IconButton,
  Button,
  Menu,
  MenuItem,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { observer } from "mobx-react-lite";
import Page from "../../../components/Page";
import { trackEvent } from "../../../api/analytics";
import { useStore } from "../../../stores/StoreContext";
import { makeStyles } from "@mui/styles";
import DashboardGrid from "../../../components/widgets/DashboardGrid"; // Draggable grid container

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: "#FFFFFF",
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(1),
  },
  infoSidebar: {
    width: 350,
    padding: theme.spacing(3),
    borderLeft: "1px solid #eaeaea",
  },
  infoHeader: {
    fontWeight: "bold",
    marginBottom: theme.spacing(2),
  },
  infoSection: {
    marginBottom: theme.spacing(3),
  },
  infoSectionTitle: {
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
  },
}));

const HomeView = observer(() => {
  const store = useStore();
  const { userInfo } = store;
  const { roles } = userInfo;
  const classes = useStyles();
  const [infoSidebarOpen, setInfoSidebarOpen] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);

  // Add state for widget menu
  const [addWidgetAnchor, setAddWidgetAnchor] = useState(null);
  const [hiddenWidgets, setHiddenWidgets] = useState([]);
  const gridRef = useRef(null);

  const isAdmin = () => {
    return roles.includes("ADMIN");
  };

  useEffect(() => {
    trackEvent(userInfo.uid, "PageView:Home");
  }, [userInfo.uid]);

  const toggleInfoSidebar = () => {
    setInfoSidebarOpen(!infoSidebarOpen);
  };

  const toggleEditMode = () => {
    setInfoSidebarOpen(false);
    setIsEditMode(!isEditMode);
  };

  // Open widget menu
  const handleAddWidgetClick = (event) => {
    setAddWidgetAnchor(event.currentTarget);
  };

  // Close widget menu
  const handleAddWidgetClose = () => {
    setAddWidgetAnchor(null);
  };

  // Add widget from menu
  const handleAddWidget = (widgetId) => {
    handleAddWidgetClose();
    if (gridRef.current) {
      gridRef.current.addWidget(widgetId);
    }
  };

  // Update hidden widgets list when it changes in DashboardGrid
  const updateHiddenWidgets = (widgets) => {
    setHiddenWidgets(widgets);
  };

  const InfoSidebar = () => (
    <Box className={classes.infoSidebar}>
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        mb={2}
      >
        <Typography variant="h6" className={classes.infoHeader}>
          Home Dashboard
        </Typography>
        <IconButton onClick={toggleInfoSidebar} size="small">
          <CloseIcon />
        </IconButton>
      </Box>

      <Typography variant="body2" paragraph>
        Home Dashboard displays widgets with important information about your
        environment.
      </Typography>

      <Box className={classes.infoSection}>
        <Typography variant="subtitle2" className={classes.infoSectionTitle}>
          Customize your Dashboard
        </Typography>
        <Typography variant="body2" paragraph>
          Add, remove, rearrange, and resize widgets to customize your
          dashboard. Changes you make do not affect others if you're using a
          shared account.
        </Typography>
      </Box>

      <Box className={classes.infoSection}>
        <Typography variant="subtitle2" className={classes.infoSectionTitle}>
          Add widgets
        </Typography>

        <Typography variant="body2" paragraph>
          Drag widgets from the available widgets section to your dashboard. You
          can also resize them by using the resize handle at the bottom right
          corner of each widget.
        </Typography>
      </Box>

      <Box className={classes.infoSection}>
        <Typography variant="subtitle2" className={classes.infoSectionTitle}>
          Rearrange widgets
        </Typography>
        <Typography variant="body2" paragraph>
          Drag and drop widgets to rearrange them on your dashboard. The layout
          will automatically adjust to accommodate the changes.
        </Typography>
      </Box>

      <Box className={classes.infoSection}>
        <Typography variant="subtitle2" className={classes.infoSectionTitle}>
          Resize widgets
        </Typography>
        <Typography variant="body2">
          Use the resize handle in the bottom corner of widgets to change their
          width.
        </Typography>
      </Box>
      <Box className={classes.infoSection}>
        <Button variant="contained" color="primary" onClick={toggleEditMode}>
          <EditIcon />
          Edit Layout
        </Button>
      </Box>
    </Box>
  );

  return (
    <Page className={classes.root} title="Home">
      <Container
        maxWidth="xl"
        className={classes.root}
        sx={{
          marginLeft: isEditMode ? 0 : "auto",
          marginRight: isEditMode ? "auto" : "auto",
          transition: "margin 0.3s ease",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            mb: 2,
          }}
        >
          <Box
            component="span"
            sx={{ display: "flex", alignItems: "baseline" }}
          >
            <Typography
              variant="h4"
              component="h2"
              sx={{ fontWeight: "bold", mr: 1 }}
            >
              Home Dashboard
            </Typography>
            <Box
              component="span"
              onClick={toggleInfoSidebar}
              sx={{
                display: "flex",
                alignItems: "center",
                cursor: "pointer",
                color: "primary.main",
              }}
            >
              <InfoOutlinedIcon fontSize="small" sx={{ mr: 0.5 }} />
              <Typography variant="caption" color="primary">
                Edit
              </Typography>
            </Box>
          </Box>
        </Box>

        <DashboardGrid
          toggleEditMode={toggleEditMode}
          isEditMode={isEditMode}
          ref={gridRef}
          onHiddenWidgetsChange={updateHiddenWidgets}
          isAdmin={isAdmin()}
        />

        {/* Add Widget Menu */}
        <Menu
          anchorEl={addWidgetAnchor}
          open={Boolean(addWidgetAnchor)}
          onClose={handleAddWidgetClose}
        >
          {hiddenWidgets.map((widget) => (
            <MenuItem
              key={widget.id}
              onClick={() => handleAddWidget(widget.id)}
            >
              {widget.title}
            </MenuItem>
          ))}
        </Menu>
      </Container>

      <Drawer
        anchor="right"
        open={infoSidebarOpen}
        onClose={toggleInfoSidebar}
        variant="temporary"
        elevation={1}
        PaperProps={{
          sx: {
            width: "350px",
            marginTop: "64px", // Adjust based on your app bar height
            height: "calc(100% - 64px)",
          },
        }}
      >
        <InfoSidebar />
      </Drawer>
    </Page>
  );
});

export default HomeView;
