import React from "react";
import { GlobalStyles as MuiGlobalStyles } from "@mui/material";

const GlobalStyles = () => {
  return (
    <MuiGlobalStyles
      styles={{
        "*": {
          boxSizing: "border-box",
          margin: 0,
          padding: 0,
        },
        html: {
          WebkitFontSmoothing: "antialiased",
          MozOsxFontSmoothing: "grayscale",
          minHeight: "100%",
          width: "100%",
          height: "100%",
        },
        body: {
          backgroundColor: "#f4f6f8",
          minHeight: "100%",
          height: "100%",
          width: "100%",
        },
        a: {
          textDecoration: "none",
        },
        "#root": {
          minHeight: "95vh",
          minWidth: "95vw",
          height: "100%",
        },
      }}
    />
  );
};

export default GlobalStyles;
