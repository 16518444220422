import { DataGridPremium, GridToolbarExport } from "@mui/x-data-grid-premium";
import { Link } from "react-router-dom";
import { useState } from "react";
import {
  Grid2,
  Card,
  CardContent,
  CardHeader,
  Typography,
} from "@mui/material";

const TopExternalBrandsList = ({ brands }) => {
  const columns = [
    {
      field: "brand",
      headerName: "Brand",
      width: 200,
    },
    {
      field: "supplier",
      headerName: "Supplier",
      width: 200,
    },
    {
      field: "num_dist",
      headerName: "# Distributors",
      width: 150,
    },
    {
      field: "distinctDistributorsNames",
      headerName: "Distinct Distributors Names",
      valueGetter: (value, row) => {
        const distributors = row?.distributors; // Safely access distributors
        if (Array.isArray(distributors)) {
          return distributors.map((x) => x.distributor_name).join(", "); // Join names with commas
        }
        return "";
      },
      flex: 1,
    },
  ];
  const aggregationFunctions = {
    distinctNames: {
      apply: (params) => {
        const uniqueValues = new Set(
          params.values.filter((v) => v !== null && v !== undefined)
        );
        return [...uniqueValues].join(", "); // Return distinct count
      },
      label: "Distinct Names",
    },
  };
  const [paginationModel, setPaginationModel] = useState({
    page: 0,
    pageSize: 50, // Enforces 50 rows per page
  });

  const [selectedBrand, setSelectedBrand] = useState(null);
  const handleRowSelectionChange = (selectionModel) => {
    if (selectionModel.length === 0) {
      // Unselect row
      setSelectedBrand(null);
    } else {
      // Find the selected row and update state
      const selectedRow = brands.find((row) => row.id === selectionModel[0]);
      setSelectedBrand(selectedRow || null);
    }
  };
  const handleRowClick = (params) => {
    const selectedRow = brands.find((row) => row.id === params.id);
    setSelectedBrand(selectedRow || null);
  };

  return (
    <Grid2 container spacing={1} className="teb-list">
      <Grid2 size={12}>
        <DataGridPremium
          columns={columns}
          rows={brands}
          pagination
          paginationMode="client"
          pageSizeOptions={[25, 50, 75, 100]}
          paginationModel={paginationModel}
          onPaginationModelChange={setPaginationModel}
          aggregationModel={{
            distinctDistributorsNames: "distinctNames",
          }}
          aggregationFunctions={aggregationFunctions}
          slots={{
            toolbar: GridToolbarExport,
          }}
          rowCount={brands.length} // Ensures total rows align with the data
          onRowClick={handleRowClick} // Row click event
          onRowSelectionModelChange={handleRowSelectionChange} // Handle selection
        />
      </Grid2>
      <Grid2 xs={6}>
        {selectedBrand && (
          <Card>
            <CardHeader title={selectedBrand.brand} />
            <CardContent>
              <Typography variant="h6">
                Supplier: {selectedBrand.supplier}
              </Typography>
              <Typography variant="subtitle1">Distributors</Typography>
              <ul style={{ paddingLeft: "1rem" }}>
                {selectedBrand.distributors.map((d) => (
                  <li key={d.distributor_id}>
                    <Link to={`/app/distributorsDetails/${d.distributor_id}`}>
                      {d.distributor_name}
                    </Link>
                  </li>
                ))}
              </ul>
            </CardContent>
          </Card>
        )}
      </Grid2>
    </Grid2>
  );
};

export default TopExternalBrandsList;
