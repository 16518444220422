import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { API } from "aws-amplify";
import { useStore } from "../../../stores/StoreContext";
import { observer } from "mobx-react-lite";
import {
  Button,
  Box,
  Divider,
  CardContent,
  Card,
  Grid2,
  List,
  ListItem,
  CardHeader,
  ListItemText,
  TextField,
  Typography,
  CircularProgress,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SaveLocationDialog from "../../../components/vendor/maps/LocationFinder/SaveLocationDialog";
import { makeStyles } from "@mui/styles";
import { Info } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  root: {
    overflowY: "auto",
    overflowX: "clip",
    textDecoration: "none",
  },
  formControl: {
    margin: theme.spacing(2),
    marginTop: 0,
  },
}));

const AccountSearch = observer(
  ({ className, setResults, handleClose, ...rest }) => {
    const classes = useStyles();
    const [itemToSave, setItemToSave] = useState(null);
    const [formOpen, setFormOpen] = useState(false);
    const [searching, setSearching] = useState(false);
    const [values, setValues] = useState({
      query: "",
    });
    const { userInfo, customers, setCustomers } = useStore();
    const [searchResults, setSearchResults] = useState([]);
    const {
      control,
      handleSubmit,
      reset,
      formState: { errors },
    } = useForm();
    useEffect(() => {
      reset(values);
    }, [values]);
    async function postSearchQuery(query) {
      setSearching(true);
      //console.log(list_id);
      if (query.length > 3) {
        try {
          const apiName = "backendGateway";
          const path = "/locations";
          const jsonBody = {
            query: query,
            localeCode: userInfo.mid === 565 ? "GBR" : "USA",
          };
          const myInit = {
            // OPTIONAL
            body: jsonBody,
            headers: {}, // OPTIONAL
          };
          const response = await API.post(apiName, path, myInit);
          setSearchResults(response.results);
          setSearching(false);
        } catch (err) {
          console.log("error fetching data..", err);
        }
      }
    }
    const handleChange = (event, value) => {
      setValues({
        ...values,
        [event.target.name]: event.target.value,
      });
    };
    const handleSave = () => {
      postSearchQuery(values.query);
    };

    return (
      <Box flexDirection="column">
        <form
          className={clsx(classes.root, className)}
          onSubmit={handleSubmit(handleSave)}
          {...rest}
        >
          <Card>
            <CardHeader title="Search Accounts" />
            <CardContent>
              <Grid2 container spacing={3}>
                <Grid2 size={12} size={12}>
                  <Controller
                    name="query"
                    defaultValue={values.query || ""}
                    control={control}
                    rules={{
                      required: true,
                      minLength: 10,
                    }}
                    render={({ field }) => (
                      <TextField
                        {...field}
                        fullWidth
                        label="Search by Name & Address"
                        name="query"
                        required
                        value={values.query}
                        variant="outlined"
                        onChange={handleChange}
                        error={errors.query ? true : false}
                        helperText={`${
                          errors.query?.type === "required"
                            ? "Query must be longer that 10"
                            : ""
                        }`}
                      />
                    )}
                  />
                  <div
                    style={{
                      marginTop: 20,
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <div>
                      <Info
                        style={{
                          color: "gray",
                          width: 15,
                          marginBottom: 15,
                          marginRight: 10,
                        }}
                      />
                    </div>
                    <p
                      style={{
                        textAlign: "center",
                        fontSize: 14,
                        fontWeight: 400,
                        color: "gray",
                      }}
                    >
                      Enter a new accounts name and address to search for
                      matches
                    </p>
                  </div>
                </Grid2>
              </Grid2>
            </CardContent>
            <Divider />
            <Box
              display="flex"
              justifyContent={
                searchResults.length ? "space-between" : "flex-end"
              }
              alignItems="center"
              p={2}
            >
              {searchResults.length > 0 && (
                <>
                  <Box>{searchResults.length} results</Box>
                  {/* <Button color="primary" variant="contained" onClick={saveAll}>
                    Autosave All
                  </Button> */}
                </>
              )}
              {searching ? (
                <CircularProgress />
              ) : (
                <Button color="primary" variant="contained" type="submit">
                  Search
                </Button>
              )}
            </Box>
          </Card>
        </form>

        <Box>
          <List
            style={{
              maxHeight: "60vh",
              overflowY: "auto",
            }}
          >
            {searchResults.map((item) => {
              const itemIsSavedAlready = customers.some(
                (customer) =>
                  customer.account_regioncode === item.Place.PostalCode
              );

              return (
                <ListItem>
                  <ListItemText
                    primary={
                      <Box display="flex">
                        <Typography
                          variant="h6"
                          style={{
                            color: "#263238",
                            flex: 7,
                            fontSize: "14px",
                            paddingRight: "8px",
                          }}
                        >
                          {item.Place.Label}
                        </Typography>
                        <div>
                          {itemIsSavedAlready ? (
                            <Button color="success" variant="contained">
                              Saved
                            </Button>
                          ) : (
                            <Button
                              variant="contained"
                              color="secondary"
                              fullWidth
                              onClick={() => {
                                setFormOpen(true);
                                setItemToSave(item.Place);
                              }}
                              style={{ flex: 1 }}
                            >
                              Save
                            </Button>
                          )}
                        </div>
                      </Box>
                    }
                  />
                </ListItem>
              );
            })}
          </List>
          <SaveLocationDialog
            location={itemToSave}
            open={formOpen}
            onClose={() => setFormOpen(false)}
            customers={customers}
            setCustomers={setCustomers}
          />
        </Box>
      </Box>
    );
  }
);
export default AccountSearch;
