import { Grid2, Typography } from "@mui/material";
import Page from "../../../components/Page";
import DistributorDataByMonth from "./distributorDataByMonth";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.light,
    minWidth: "100%",
    padding: theme.spacing(3),
  },
  heading: {
    fontWeight: 600,
  },
  button: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

const DistributorView = () => {
  const classes = useStyles();

  return (
    <Page className={classes.root} title="Distributors">
      <Grid2 container spacing={3} className={classes.root}>
        <Grid2 size={12}>
          <Typography gutterBottom variant="h5" component="h2">
            Distributors
          </Typography>
        </Grid2>
        <Grid2 size={12} size={12}>
          <DistributorDataByMonth />
        </Grid2>
      </Grid2>
    </Page>
  );
};
export default DistributorView;
