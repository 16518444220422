import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStore } from "../../stores/StoreContext";
import { CircularProgress, lighten, LinearProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Cancel, CheckCircle } from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  universalLoadingIndicator: {
    position: "fixed",
    bottom: "20px",
    left: "20px",
    width: "300px",
    zIndex: 2147483647,
    cursor: "pointer",
  },
  loaderWrapper: {
    position: "absolute",
    left: 0,
    width: "100%",
    transition: "all 0.3s ease-in-out",
  },
  loadingContainer: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    padding: "10px 15px",
    overflow: "hidden",
    transition: "all 0.5s ease-in-out",
  },
  circleContainer: {
    width: "70px",
    height: "70px",
    borderRadius: "35px",
    background: `linear-gradient(to bottom, ${lighten(
      "#1f3d9f",
      0.1
    )}, #1f3d9f)`,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition:
      "width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
  },
  rectangleContainer: {
    width: "100%",
    height: "70px",
    borderRadius: "35px",
    background: `linear-gradient(to bottom, ${lighten(
      "#1f3d9f",
      0.1
    )}, #1f3d9f)`,
    display: "flex",
    alignItems: "center",
    transition:
      "width 0.5s ease-in-out, height 0.5s ease-in-out, border-radius 0.5s ease-in-out",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.2)",
  },
  successContainer: {
    background: `linear-gradient(to bottom, ${lighten(
      "#4caf50",
      0.1
    )}, #4caf50)`,
  },
  errorContainer: {
    background: `linear-gradient(to bottom, ${lighten(
      "#f44336",
      0.1
    )}, #f44336)`,
  },
  iconContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "50px",
    height: "50px",
    flexShrink: 0,
  },
  circularProgress: {
    color: "#ffffff",
  },
  successIcon: {
    color: "#ffffff",
    fontSize: "30px",
  },
  errorIcon: {
    color: "#ffffff",
    fontSize: "30px",
  },
  textContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "10px",
  },
  messageText: {
    fontSize: "14px",
    color: "#ffffff",
    fontWeight: "500",
  },
  progressText: {
    fontSize: "14px",
    color: "#ffffff",
  },
  progressBar: {
    width: "200px",
    marginTop: "5px",
    height: "10px",
    borderRadius: "5px",
    backgroundColor: theme.palette.grey[300],
    "& .MuiLinearProgress-barColorPrimary": {
      backgroundColor: "#00FFFF",
    },
  },
}));

const UniversalLoadingIndicator = observer(() => {
  const classes = useStyles();
  const store = useStore();
  const { loaders } = store;
  const [expanded, setExpanded] = useState(false);

  const loadersArray = Array.from(loaders.values()).reverse().slice(0, 5);

  useEffect(() => {
    if (loadersArray.length === 0) {
      setExpanded(false);
    }
  }, [loadersArray]);

  const handleToggleExpand = () => {
    setExpanded(!expanded);
  };

  if (loadersArray.length === 0) return null;

  return (
    <div
      className={classes.universalLoadingIndicator}
      onClick={handleToggleExpand}
    >
      {loadersArray.map((loader, index) => {
        const zIndex = 2147483647 - index;
        const loaderHeight = 70;
        const gap = 5;

        const offsetY = expanded ? (loaderHeight + gap) * index : index * 5;

        return (
          <div
            key={index}
            className={classes.loaderWrapper}
            style={{
              bottom: `${offsetY}px`,
              zIndex: zIndex,
            }}
            role="alert"
            aria-live="assertive"
          >
            <div
              className={`${classes.loadingContainer} ${
                loader.loadingMessage ||
                loader.progress > 0 ||
                loader.successMessage ||
                loader.errorMessage
                  ? classes.rectangleContainer
                  : classes.circleContainer
              } ${loader.successMessage ? classes.successContainer : ""} ${
                loader.errorMessage ? classes.errorContainer : ""
              }`}
            >
              {loader.isLoading &&
                !loader.successMessage &&
                !loader.errorMessage && (
                  <div className={classes.iconContainer}>
                    <CircularProgress
                      size={30}
                      className={classes.circularProgress}
                    />
                  </div>
                )}

              {loader.successMessage && (
                <div className={classes.iconContainer}>
                  <CheckCircle className={classes.successIcon} />
                </div>
              )}

              {loader.errorMessage && (
                <div className={classes.iconContainer}>
                  <Cancel className={classes.errorIcon} />
                </div>
              )}

              {(loader.loadingMessage ||
                loader.successMessage ||
                loader.errorMessage ||
                loader.progress > 0) && (
                <div className={classes.textContainer}>
                  {loader.loadingMessage &&
                    !loader.errorMessage &&
                    !loader.successMessage && (
                      <span className={classes.messageText}>
                        {loader.loadingMessage}
                      </span>
                    )}
                  {loader.successMessage && (
                    <span className={classes.messageText}>
                      {loader.successMessage}
                    </span>
                  )}
                  {loader.errorMessage && (
                    <span className={classes.messageText}>
                      {loader.errorMessage}
                    </span>
                  )}
                  {loader.progress > 0 && (
                    <span className={classes.progressText}>
                      <LinearProgress
                        variant="determinate"
                        value={loader.progress}
                        className={classes.progressBar}
                      />
                      {loader.progress}%
                    </span>
                  )}
                </div>
              )}
            </div>
          </div>
        );
      })}
    </div>
  );
});

export default UniversalLoadingIndicator;
